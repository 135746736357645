// @flow

import React, { useState, useEffect } from 'react';
import { useStore } from '../../store';
import { log } from '../../utils/jsUtils';
import Downshift from 'downshift';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import SPBackend from '../../services/SPBackend';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/AutocompleteBuilding.Style';

type Props = {
  setBuilding: Function,
  classes: Object
}

const renderInput = _inputProps => {
  const {
    InputProps,
    classes,
    openMenu,
    ...other
  } = _inputProps;
  return (
    <TextField
      InputProps={{
        // inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput
        },
        ...InputProps
      }}
      onFocus={openMenu}
      {...other}
    />
  );
};

// eslint-disable-next-line react/no-multi-comp
const SuggestedList = (props) => {
  const {
    inputValue,
    user,
    getItemProps
  } = props;

  log('[SuggestedList] inputValue: ', inputValue);
  const value = inputValue.trim().toLowerCase();
  const [buildings, setBuildings] = useState([]);

  // log('[SuggestedList] itemProps: ', itemProps);
  useEffect(() => {
    if (user !== null) {
      log('[SPBackend.findBuildings]...');
      SPBackend.findBuildings(value, user)
        .then(response => {
          log('[findBuildings] response: ', response);
          const { data } = response;
          setBuildings(data.buildings);
      })
      .catch(err => {
        log('sentry error: ', err);
      });
    } else {
      setBuildings([]);
    }
    // return function cleanup() {

    // }
  }, [inputValue]);

  return (
    buildings.map((building) => {
      return (
        <MenuItem
          {...getItemProps({item: building})}
          component='div'
          key={building._id}
        >
          {`${building.Property}`}
        </MenuItem>
      );
    })
  );
};

// eslint-disable-next-line react/no-multi-comp
const AutocompleteBuilding = (props: Props) => {
  const {
    classes,
    setBuilding
  } = props;
  const [store, dispatch] = useStore();
  const {
    user,
    i18n
  } = store;
  return (
    <div>
      <Downshift 
        id='findBuilding'
        itemToString={item => item ? `${item.Property}` : ''}
        onSelect={(building) => setBuilding(building)}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          inputValue,
          isOpen,
          openMenu,
          selectedItem
        }) => (
          <div className={classes.container}>
            {renderInput({
              ref: 'test',
              fullWidth: true,
              classes,
              InputProps: getInputProps({
                placeholder: `Search a ${i18n.t('property')}`
              }),
              openMenu
            })}
            <div {...getMenuProps()}>
              {isOpen ? (
                <Paper
                  className={classes.paper}
                  square
                >
                  <SuggestedList
                    getItemProps={getItemProps}
                    inputValue={inputValue}
                    user={user}
                  />
                </Paper>
              ) : null}
            </div>
          </div>
        )}
      </Downshift>
    </div>
  );
};

export default withStyles(styles)(AutocompleteBuilding);