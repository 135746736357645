// @flow

import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import PublicFooter from './PublicFooter';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import logo from '../../assets/askneo-favicon.png';
import topImg from '../../assets/Banner_askneo-02.png';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/PublicWelcome.Style';

type Props = {
  classes: Object
};

const tutorialSteps = [
  {
    title: 'Get closer to your customers through texting',
    subtitle: 'Neo\'s texting assistant and shared inbox help you provide the most efficient and authentic customer experience.',
    img: topImg
  }
  // {
  //   title: 'Personalized feed',
  //   subtitle: 'Everything you need\nin the palm of your hand.',
  //   img: PicGetstarted2
  // },
  // {
  //   title: 'Easy & Transparent',
  //   subtitle: 'Create requests.\nCheck/update their status.\nGet notified.',
  //   img: PicGetstarted3
  // }
];
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ContentPublic = (props: Props) => {
  const { classes } = props;
  const [activeStep, setActiveStep] = useState(0);
  return (
    <Grid className={classes.root}
      item
      xs={12}
    >
      {/* <PublicHeader /> */}
      <Grid 
        className={classes.carouselContainer}
        item
      >
        <AutoPlaySwipeableViews
          enableMouseEvents
          index={activeStep}
          interval={4000}
          onChangeIndex={(activeStep) => setActiveStep(activeStep)}
        >
          {tutorialSteps.map((step, index) => (
            <Grid
              container
              direction={'column'}
              // className={classes.stepContainer}
              key={`step${index}`}
            >
              <Grid 
                className={classes.titlesCtr}
                item
              >
                <div className={classes.topLogo}>
                  <img src={logo}
width={100}
                  />
                </div>
                <Typography
                  align='center'
                  className={classes.title}
                  variant='h3'
                >
                  {step.title}
                </Typography>
                {step.subtitle.split('\n').map((i, key) => {
                  return (                    
                    <Typography
                      align='center'
                      className={classes.subtitle}
                      key={key}
                      variant='body1'
                    >
                      {i}
                    </Typography>
                  );
                })}
              </Grid>
              <Grid item
                xs={'auto'}
              >
                {
                  Math.abs(activeStep - index) <= 2 ? (
                    <img
                      alt={step.title}
                      className={classes.img}
                      src={step.img}
                    />
                  ) : null
                }
              </Grid>
            </Grid>
          ))}
        </AutoPlaySwipeableViews>
      </Grid>
    
      <PublicFooter />
    </Grid>
    
    );
};

export default withStyles(styles)(ContentPublic);