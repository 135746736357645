// @flow

const COL_COLOR_WIDTH = 60;
const COL_NAME_WIDTH = 200;
const COL_CUSTCNT_WIDTH = 200;
const COL_ACTION_WIDTH = 220;

const styles = (theme: Object) => ({
  tableContainer: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: '100%',
    maxWidth: 600,
    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1)
    }
  },
  customFeedTableHeadRow: {
    '& th': {
      cursor: 'pointer'
    }
  },
  colAction: {
    width: COL_ACTION_WIDTH
  },
  colorCell: {
    flex: '0 0 auto',
    flexBasis: COL_COLOR_WIDTH,
    maxWidth: COL_COLOR_WIDTH,
    cursor: 'pointer',
    height: 52,
    borderTopLeftRadius: 24,
    borderBottomLeftRadius: 24,
    borderBottom: 0,
    backgroundColor: 'white',
    '& span svg': {
      fontSize: '1.6rem'
    }
  },
  nameCell: {
    flex: '1 1 auto !important',
    minWidth: COL_NAME_WIDTH,
    maxWidth: '100% !important',
    paddingRight: theme.spacing(1),
    paddingLeft: `${theme.spacing(4)}px !important`,
    width: 'auto',
    overflowX: 'scroll'
  },
  custCountCell: {
    flex: '0 0 auto',
    maxWidth: COL_CUSTCNT_WIDTH,
    minWidth: COL_CUSTCNT_WIDTH
  },
  customTableStyles: {
    '& td': {
      cursor: 'default'
    },
    '& .underActionCell': {
      flex: '0 0 auto',
      minWidth: COL_ACTION_WIDTH ,
      maxWidth: COL_ACTION_WIDTH ,
      paddingRight: 0,
      paddingLeft: theme.spacing(1),
      width: 'auto'
    },
    '& .hover .underActionCell': {
      flex: '1 1 auto',
      minWidth: COL_ACTION_WIDTH - (theme.shape.borderRadius * 2 * 2) - theme.spacing(1),
      maxWidth: COL_ACTION_WIDTH - (theme.shape.borderRadius * 2 * 2) - theme.spacing(1),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 'auto'
    }
  },
  arrowIcon: {
    fontSize: '1.2rem',
    position: 'relative',
    top: 1,
    marginLeft: theme.spacing(0.375),
    color: '#66666a',
    verticalAlign: 'text-bottom'
  }
});

export default styles;
