const styles = (theme: Object) => ({
  backdrop: {
    backgroundColor: 'hsla(0,0%,85%, 75%)'
  },
  dialogRoot: {
    minHeight: '30vh',
    minWidth: 400,
    maxWidth: 600,
    width: '90vw',
    [theme.breakpoints.down('xs')]: {
      minWidth: '96vw',
      width: '75vh',
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5)
    }
  },
  title: {
    backgroundColor: theme.palette.primary.dark,
    '& *': {
      color: 'white',
      fontWeight: 800
    }
  },
  fullNameInput: {
    minWidth: '66%'
  },
  content: {
    minHeight: 200,
    overflowY: 'scroll',
    maxWidth: 'inherit',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    color: 'hsl(0,0%,40%)',
    fontSize: '1.1rem',
    '& *': {
      wordBreak: 'break-word'
    },
    '& h1, & h2, & h3, & h4, & h5': {
      marginBlockEnd: '0.4em'
    },
    '& p': {
      marginBlockStart: '0.2em'
    },
    '& blockquote': {
      margin: '0em 0px',
      padding: '4px 8px',
      borderLeft: '2px solid #ccc'
    }
  },
  actions: {
    paddingTop: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.border.color}`
  }
});

export default styles;