// @flow

import React from 'react';
import { useStore } from '../../store';

import LinearProgress from '@material-ui/core/LinearProgress';

const AppLoadingSpinner = () => {
  const [store] = useStore();
  const {
    isAppLoading,
    loadingModule
  } = store;

  const reducer = (isLoading, moduleKey) => !isLoading ? loadingModule[moduleKey] : true;

  return (<>
    {
      isAppLoading
      || (loadingModule && Object.keys(loadingModule).reduce(reducer, false))
      ? <LinearProgress />
      : null
    }
    </>
  );
};

export default AppLoadingSpinner;
