// @flow

const styles = (theme: Object) => ({
  preCtr: {
    ...theme.typography.body1,
    padding: '8px 16px'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  contentTitle: {
    marginTop: 40
  },
  toolbar: theme.layout.customProps.toolbar,

  requestPreviewCtrMobile: {
    [theme.breakpoints.down('sm')]: {
      position: 'sticky',
      backgroundColor: 'white',
      top: theme.layout.customProps.header.height*2,
      zIndex: 99,
      marginTop: -theme.spacing(1),
      width: '100vw',
      marginBottom: theme.spacing(2),
      marginLeft: 0
    }
  },
  tableRequestContent: {
    padding: 0,
    backgroundColor: 'white'
    // position: 'relative',
    // border: '2px solid blue'
  },
  requestPreviewCtr: {
    position: 'sticky',
    backgroundColor: 'white',
    top: theme.layout.customProps.header.height*2,
    zIndex: 99,
    marginTop: -theme.spacing(4),
    marginBottom: theme.spacing(3),
    marginLeft: 0,
    width: `calc(100vw - ${theme.layout.customProps.drawer.width}px)`,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1)
  },
  listRoot: {
    width: '100%',
    // backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('sm')]: {
      borderRadius: theme.shape.borderRadius / 2
    }
  },
  chatListRootCtr: {
    width: '100%'
  },
  chatListRoot: {
    marginTop: theme.spacing(1),
    marginBottom: 30,
    width: '100%'
    // backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    width: '100%',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0
    }
  },
  noLeftPadding: {
    paddingLeft: 0
  },
  requestHistoryEntryTitle: {
    position: 'relative',
    fontSize: '0.9rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
      maxWidth: 'calc(100vw - 120px)'
    }
  },
  requestHistoryEntryCtr: {
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      paddingLeft: theme.spacing(2)
    }
  },
  historyEntrySummary: {
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover .hover': {
      fontWeight: 700
    }
  },
  historyEntrySummaryTitle: {
    minWidth: 100,
    display: 'inline-block'
  },
  inline: {
    display: 'inline'
  },
  expansionPanel: {
    marginTop: 0,
    marginBottom: 0,
    boxShadow: '0px 2px 8px -1px rgba(0,0,0,0.2)',
    [theme.breakpoints.down('sm')]: {
      '&:first-child': {
        borderTopLeftRadius: theme.shape.borderRadius / 2,
        borderTopRightRadius: theme.shape.borderRadius / 2
      },
      '&:last-child': {
        borderBottomRightRadius: theme.shape.borderRadius / 2,
        borderBottomLeftRadius: theme.shape.borderRadius / 2
      }
    }
  },
  expansionPanelSummary: {
    position: 'relative',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    cursor: 'pointer',
    '& li': {
      width: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      '& li:last-child': {
        paddingRight: 0
      }
    }
  },
  expansionPanelSummaryRoot: {
    borderBottom: 0,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  ExpansionPanelDetailsRoot: {
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2)
    }
  },
  requestHistoryTimestamp: {
    fontSize: '0.9rem',
    margin: '8px 12px',
    [theme.breakpoints.down('sm')]: {
      color: 'hsl(0,0%,66%)',
      fontSize: '0.8rem',
      margin: '0px 0 8px 0'
    }
  },
  disabledExpansion: {
    cursor: 'default !important'
  },
  beforeChat: {
    marginBottom: theme.spacing(2)
  },
  chatListItem: {
    border: 0,
    backgroundColor: theme.layout.customProps.drawer.backgroundColor,
    borderRadius: 0,
    marginTop: -theme.spacing(1),
    marginBottom: -theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    whiteSpace: 'pre-wrap',
    '&.first': {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      paddingTop: theme.spacing(2)
    },
    '&.last': {
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      paddingBottom: theme.spacing(2)
    },
    '& svg': {
      position: 'relative',
      top: 1
    },
    [theme.breakpoints.down('sm')]: {
      '&.first': {
        borderTopLeftRadius: theme.shape.borderRadius / 2,
        borderTopRightRadius: theme.shape.borderRadius / 2
      },
      '&.last': {
        borderBottomLeftRadius: theme.shape.borderRadius / 2,
        borderBottomRightRadius: theme.shape.borderRadius / 2
      }
    }
  },
  statusIconsColors: theme.props.statusIconsColors,
  historyItemTime: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8125rem',
      maxWidth: 50,
      marginRight: theme.spacing(1)
    }
  },
  eventIcon: {
    color: theme.palette.primary.main
  },
  hTitle: {
    // TODO: to factor with thead style
    fontSize: '0.9rem',
    fontWeight: 600,
    color: 'hsl(0,0%,45%)',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  listSecondaryActionCtr: {
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'flex',
    right: 0,
    position: 'absolute',
    width: 'auto',
    marginRight: 40,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: 0,
      display: 'block',
      transform: 'none',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2)
    }
  },
  attachmentRowCtr: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    overflowX: 'scroll',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    '& > div > img': {
      display: 'block',
      width: 150,
      minWidth: 150,
      height: 150
    }
  },
  attachment: {
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      borderRadius: theme.shape.borderRadius / 2
    }
  },
  attachmentSmallCtr: {
    // position: 'absolute',
    right: 0,
    top: -25,
    display: 'flex',
    alignContent: 'flex-end',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: 0,
      marginBottom: theme.spacing(1.5),
      marginLeft: -theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  },
  attachmentSmall: {
    width: '35px !important',
    height: '35px !important',
    borderRadius: 4,
    margin: '0px 2px',
    padding: 0
  },
  unreadAnchorCtr: {
    position: 'fixed',
    bottom: 150,
    width: `calc(100vw - ${theme.layout.customProps.drawer.width}px - ${theme.spacing(3)*2}px)`,
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  unreadAnchorPopperContent: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    margin: '0 auto'
  },
  messagesArea: {
    overflowY: 'auto',
    height: `calc(100vh-${theme.layout.customProps.header.height * 2}px)`,
    maxHeight:`calc(100vh-${theme.layout.customProps.header.height * 2}px)`,
    marginBottom: theme.layout.customProps.header.height,
    
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      height: `calc(100vh-${theme.layout.customProps.header.height * 2}px)`,
      maxHeight: `calc(100vh-${theme.layout.customProps.header.height * 2}px)`,
      marginBottom: `calc(${theme.layout.customProps.header.height}px -  ${theme.spacing(2)}px)`
      // height: 470
    }
  },
  replyBox: {
    backgroundColor: 'white',
    height: 'auto',
    position: 'fixed',
    bottom: 0,
    width: `calc(100vw - ${theme.layout.customProps.drawer.width}px)`,
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    left: theme.layout.customProps.drawer.width,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'white',
      height: 'auto',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      marginTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      left: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: theme.spacing(1)
    }
  }
});

export default styles;