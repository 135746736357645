// @flow

const COL_REQUEST_MIN_WIDTH = 200;
const COL_REQUEST_WIDTH = 200;
const COL_TYPE_WIDTH = 250;
const COL_TAG_WIDTH = 300;
const COL_CUSTOMER_WIDTH = 250;
const COL_PROPERTY_WIDTH = 225;
const COL_UNIT_WIDTH = 150;
const COL_COUNT_WIDTH = 80;
const COL_STATUS_WIDTH = 150;
const COL_ASSIGNEE_WIDTH = 150;
const COL_DATE_WIDTH = 159;
const COL_ACTION_WIDTH = 220;
const ROW_CELL_BACKGROUND_COLOR = 'hsl(0,0%,99%)';

const styles = (theme: Object) => ({
  feedTableBody: {
    display: 'flex',
    flexDirection: 'column',
    width: `calc(100vw - ${theme.layout.customProps.drawer.width}px - ${theme.spacing(6)}px)`
  },
  status: {
    left: -16,
    width: theme.shape.borderRadius,
    height: theme.layout.listTable.rowHeight,
    position: 'absolute',
    top: -2,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    '&.pending': {
      backgroundColor: 'red'
    },
    '&.current': {
      backgroundColor: 'orange'
    },
    '&.finished': {
      backgroundColor: 'green'
    },
    '&.info': { // includes broadcast
      backgroundColor: 'blue'
    },
    '&.public': { // FIXME: deprecated?
      backgroundColor: 'gray'
    }
  },
  statusCell: {
    width: '1%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'table-cell',
    position: 'relative',
    width: '20px',
    backgroundColor: ROW_CELL_BACKGROUND_COLOR
  },
  customerNameCell: {
    display: 'flex',
    flex: '0 0 auto',
    minWidth: COL_CUSTOMER_WIDTH,
    maxWidth: COL_CUSTOMER_WIDTH,
    backgroundColor: ROW_CELL_BACKGROUND_COLOR
  },
  requestCell: {
    display: 'flex',
    flex: '0 0 auto',
    minWidth: COL_REQUEST_MIN_WIDTH,
    maxWidth: COL_REQUEST_MIN_WIDTH,
    backgroundColor: ROW_CELL_BACKGROUND_COLOR
  },
  tagCell: {
    display: 'flex',
    flex: '1 1',
    flexBasis: COL_TAG_WIDTH,
    minWidth: COL_TAG_WIDTH,
    backgroundColor: ROW_CELL_BACKGROUND_COLOR
  },
  countCell: {
    display: 'flex',
    flex: '0 0 auto',
    minWidth: COL_COUNT_WIDTH,
    maxWidth: COL_COUNT_WIDTH,
    backgroundColor: ROW_CELL_BACKGROUND_COLOR
  },
  propertyCell: {
    display: 'flex',
    flex: '0 0 auto',
    minWidth: COL_PROPERTY_WIDTH,
    maxWidth: COL_PROPERTY_WIDTH,
    backgroundColor: ROW_CELL_BACKGROUND_COLOR
  },
  unitCell: {
    display: 'flex',
    flex: '0 0 auto',
    minWidth: COL_UNIT_WIDTH,
    maxWidth: COL_UNIT_WIDTH,
    backgroundColor: ROW_CELL_BACKGROUND_COLOR
  },
  statusCell: {
    display: 'flex',
    flex: '0 0 auto',
    minWidth: COL_STATUS_WIDTH,
    maxWidth: COL_STATUS_WIDTH,
    backgroundColor: ROW_CELL_BACKGROUND_COLOR
  },
  dateCell: {
    display: 'flex',
    flex: '0 0 auto',
    paddingRight: 24,
    minWidth: COL_DATE_WIDTH,
    maxWidth: COL_DATE_WIDTH,
    backgroundColor: ROW_CELL_BACKGROUND_COLOR
  },
  assigneeCell: {
    display: 'flex',
    flex: '0 0 auto',
    minWidth: COL_ASSIGNEE_WIDTH,
    maxWidth: COL_ASSIGNEE_WIDTH,
    backgroundColor: ROW_CELL_BACKGROUND_COLOR
  },
  actionCell: {
    display: 'flex',
    flex: '0 0 auto',
    minWidth: COL_DATE_WIDTH,
    maxWidth: COL_DATE_WIDTH,
    paddingRight: 0,
    paddingLeft: 0,
    width: 'auto',
    marginLeft: 3
    // '& button': {
    //   backgroundColor: ROW_CELL_BACKGROUND_COLOR
    // }
  },
  broadcastActionCell: {
    marginLeft: 147,
    backgroundColor: ROW_CELL_BACKGROUND_COLOR
  },
  colRequest: {
    width: COL_REQUEST_WIDTH
  },
  colType: {
    width: COL_TYPE_WIDTH
  },
  colCustomerName: {
    width: COL_CUSTOMER_WIDTH
  },
  colProperty: {
    width: COL_PROPERTY_WIDTH
  },
  colUnit: {
    width: COL_UNIT_WIDTH
  },
  colCount: {
    width: COL_COUNT_WIDTH
  },
  colStatus: {
    width: COL_STATUS_WIDTH
  },
  colDate: {
    width: COL_DATE_WIDTH
  },
  colAssignee: {
    width: COL_ASSIGNEE_WIDTH
  },
  colAction: {
    width: COL_ACTION_WIDTH
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  resultsCount: {
    color: theme.palette.custom.backgroundTextInfo,
    fontSize: '0.8rem',
    // marginBottom: '1rem',
    paddingLeft: theme.spacing(4.75),
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
      marginBottom: 0,
      paddingLeft: theme.spacing(2.5)
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2)
    }
  },
  // feedTableEnter: {
  //   opacity: 0.01
  // },
  '@keyframes bounce': {
    'from, 20%, 53%, 80%, to': {
      transform: 'translate3d(0, 0, 0)'
    },
    '40%, 43%': {
      animationTimingFunction: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
      transform: 'translate3d(0, -30px, 0)'
    },
    '70%': {
      animationTimingFunction: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
      transform: 'translate3d(0, -15px, 0)'
    },
    '90%': {
      transform: 'translate3d(0, -4px, 0)'
    }
  },
  '@keyframes fadeInUp': {
    from: {
      opacity: 0,
      transform: 'translate3d(0, 100%, 0)'
    },
    to: {
      opacity: 1,
      transform: 'translate3d(0, 0, 0)'
    }
  },
  '@keyframes fadeInDown': {
    from: {
      opacity: 0,
      transform: 'translate3d(0, -100%, 0)'
    },
    to: {
      opacity: 1,
      transform: 'translate3d(0, 0, 0)'
    }
  },
  '@keyframes fadeIn': {
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  },
  feedTableAddedEnter: {
    opacity: 0
  },
  feedTableAddedEnterActive: {
    // opacity: 1,
    animationName: '$fadeInDown',
    animationDuration: '1s',
    animationIterationCount:'1',
    transformOrigin: 'center top'
  },
  feedTableUpdatedEnter: {
    opacity: 0
  },
  feedTableUpdatedEnterActive: {
    // opacity: 1,
    animationName: '$fadeInUp',
    animationDelay: '0.3s',
    animationDuration: '1s',
    animationIterationCount:'1',
    transformOrigin: 'center bottom'
  },
  feedTableExitActive: {
    opacity: 1
  },
  feedTablePushedinEnter: {
    opacity: 0
  },
  feedTablePushedinEnterActive: {
    animationName: '$fadeIn',
    animationDelay: '1s',
    animationDuration: '1.5s',
    animationFillMode: 'both',
    animationIterationCount: '1'
  },
  feedTableAppear: {
    opacity: 0.01
  },
  feedTableAppearActive: {
    opacity: 1,
    transition: 'opacity 500ms ease-in'
  },
  feedTableExit: {
    opacity: 1
  },
  unreadBadgeCtr: {
    marginLeft: theme.spacing(1.5)
  },
  unreadBadgeShowing: {
    marginRight: theme.spacing(1)
  },
  // feedTableExitActive: {
  //   opacity: 0.01,
  //   transition: 'opacity 300ms ease-in'
  // }
  unreadFilter: {
    text: 'underline',
    marginLeft: theme.spacing(1),
    cursor: 'pointer'
  },
  feedTableHeadRow: {
    display: 'flex',
    position: 'relative',
    height: 30,
    '& th': {
      fontSize: '0.8rem',
      fontWeight: 400,
      color: theme.palette.custom.backgroundTextInfo,
      padding: theme.spacing(1),
      borderBottom: 0,
      backgroundColor: 'transparent'
    }
  }
});

export default styles;