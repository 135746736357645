// @flow

const styles = (theme: Object) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 230,
    boxShadow: 'none',
    borderColor: 'hsl(0,0%,80%)',
    border: '1px solid',
    borderRadius: theme.shape.borderRadius,
    height: 38,
    [theme.breakpoints.down('sm')]: {
      width: 180
    }
  },
  input: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  iconButton: {
    padding: theme.spacing(1) + 2
  }
});

export default styles;