import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RedocStandalone } from 'redoc';
import SwaggerYaml from './swagger.yaml';
// import PageTemplate from 'layout/PageTemplate';

import './redoc.css';

const ApiDocs = () => {
  const [documentation, setDocumentation] = useState(null);

  useEffect(async() => {
    // const response = await MDPBackend.getApiDocumentation();
    console.log('SwaggerYaml: ', SwaggerYaml);
    // const parsedData = JSON.parse(SwaggerYaml).data;
    // const parsedDocument = JSON.parse(SwaggerYaml);
    // console.log('parsedDocument: ', parsedDocument);
    const file = await fetch(SwaggerYaml);
    const text = await file.text();
    console.log('[fetch] text:', text);
    setDocumentation(SwaggerYaml);
    // const parsedText = JSON.parse(text);
    // console.log('[fetch] parsedText:', parsedText);
      // .then(r => {
      //   console.log('[fetch] r: ', r);
      //   r.text();
      //   return r.text();
      //   })
      // .then(text => {
      //   console.log('[fetch] text decoded:', text);
      //   // [Actual file contents!]
      //   setDocumentation(text);
      // });
  }, []);

  return (
      <div style={{ marginTop: 67 }}>
        <Helmet>
          <title>Neo | API Docs</title>
        </Helmet>
        {documentation ? (
          <RedocStandalone specUrl={documentation} />
        ) : (
          'Loading...'
        )}
      </div>
  );
};

export default ApiDocs;
