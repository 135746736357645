
const styles = (theme: Object) => ({
  chipTag: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    marginRight: 2,
    height: 20
  },
  hover: {
    cursor: 'pointer',
    '&:hover': {
      filter: 'brightness(95%)'
    }
  },
  deleteIcon: {
    '&:hover': {
      color: `${theme.palette.primary.main} !important`
    }
  }
});

export default styles;