// @flow

import React, { useState } from 'react';

const ForwardRef = React.forwardRef(
  (props, ref) => <div {...props}
                    ref={ref}
                  >
    {props.children}
  </div>
);

export default ForwardRef;
