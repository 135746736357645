// @flow

import { phoneInputCss } from '../../../css/phoneInput';

const styles = (theme: Object) => ({
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    padding: 0
  },
  dialogFormCtr: {
    // height: 'auto',
    // maxHeight: 300,
    display: 'flex',
    flexDirection: 'column',
    '&.xlarge': {
      maxHeight: 500
    },
    '&.large': {
      maxHeight: 450
    }
  },
  grow: {
    flexGrow: 1
  },
  errorMessagesCtr: {
    position: 'absolute',
    left: 42,
    top: -24,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'flex-start',
    '&.one-line': {
      top: -24
    },
    '&.two-lines': {
      top: -40,
      backgroundColor: 'white'
    }
  },
  clickable: {
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  textFieldCaptionRight: {
    textAlign: 'right',
    color: theme.palette.primary.light,
    fontSize: '0.8rem',
    marginRight: theme.spacing(1),
    borderRadius: 0,
    boxShadow: 'none',
    background: 'white',
    bottom: 0,
    paddingTop: theme.spacing(1)
  },
  
  textFieldCaptionRight2: {
    textAlign: 'right',
    color: theme.palette.primary.light,
    fontSize: '0.8rem'
    // bottom: 0,
  },
  textFieldCaptionLeft: {
    textAlign: 'left',
    color: theme.palette.primary.light,
    fontSize: '0.8rem'
    // position: 'absolute',
    // bottom: 20,
    // left: 42
  },
  warning: {
    color: theme.palette.error.main,
    bottom: 60
  },
  error: {
    color: theme.palette.status.error.main,
    bottom: 60,
    paddingLeft: theme.spacing(3)
  },
  actionAttachImgBtn: {
    // backgroundColor: theme.palette.primary.lighter
    cursor:'pointer'
  },
  attachedImg: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    border: `2px solid ${theme.palette.primary.lighter}`,
    cursor: 'pointer',
    objectFit: 'cover',
    display: 'block',
    width: 100,
    minWidth: 100,
    height: 100
  }
});

export default styles;
