import { log } from '../utils/jsUtils';

const inspectletAppId = 1352851873;
const monitorEnv = 'none';

const initialize = () => {
  log('[inspectlet.init] process.env.REACT_APP_ENV: ', process.env.REACT_APP_ENV);
  if (process.env.REACT_APP_ENV === monitorEnv) {
    if (typeof window === 'undefined') {
      return false;
    }
    try {
      /* eslint-disable */
      // Begin Inspectlet Embed Code
      (function() {
        window.__insp = window.__insp || [];
        window.__insp.push(['wid', inspectletAppId]);
        function ldinsp(){ if(typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + `://cdn.inspectlet.com/inspectlet.js?wid=${inspectletAppId}&r=` + Math.floor(new Date().getTime()/3600000); var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x); };
        setTimeout(ldinsp, 100); document.readyState != "complete" ? (window.attachEvent ? window.attachEvent('onload', ldinsp) : window.addEventListener('load', ldinsp, false)) : ldinsp();
      })();
      // End Inspectlet Embed Code
      /* eslint-enable */
    } catch (e) {
      log('[Inspectlet] err: ', e);
    }
  }
};

const identify = (userEmail, retries = 4) => {
  log('[inspectlet.identify] process.env.REACT_APP_ENV: ', process.env.REACT_APP_ENV);
  if (process.env.REACT_APP_ENV === monitorEnv) {
    if (typeof window === 'undefined') {
      return false;
    }
    if (typeof window.__insp === 'undefined') {
      if (retries === 3) {
        // First time, initialize
        initialize();
      } else if (retries > 0) {
        // tries 3 times
        setTimeout(identify(userEmail, retries - 1), 300);
      } else {
        // Give up
        return false;
      }
    }
    window.__insp.push(['identify', userEmail]);
    window.__insp.push(['tagSession', `ENV:${process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'none'}`]);
  }
};

export default {
  initialize: initialize,
  identify: identify
};
