// @flow

import React, { useState, useEffect } from 'react';
import { useStore} from '../../store';
import classNames from 'classnames';
import { REF_STATUSES } from '../../models/Request';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/AddTaskDialog.Style';
import AutocompleteProperty from '../Properties/AutocompleteProperty';
import AutocompleteCommonProperty from '../Properties/AutocompleteCommonProperty';
import type { Unit } from '../../services/SPBackend';

type Props = {
  classes: Object,
  setOpen: Function,
  open: boolean,
  triggerAction: Function
};

// eslint-disable-next-line react/no-multi-comp
const AddTask = (props: Props) => {
  const {
    classes,
    setOpen,
    triggerAction,
    open
  } = props;

  const [store] = useStore();
  const {
    email,
    i18n,
    defaultUnit
  } = store;

  const [selectedUnit, setSelectedUnit] = useState(defaultUnit ? defaultUnit.RoomKey : null);
  const [selectedValue, setSelectedValue] = React.useState(null);
  
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  
  const emptyFormState = {
    User: email,
    Coords: 'FL_RN_R',
    Issue: '',
    RoomKey: defaultUnit ? defaultUnit.RoomKey : '',
    Status: REF_STATUSES.PENDING,
    Summary: ''
  };
  
  const [newRequest, setNewRequest] = useState(emptyFormState);
  useEffect(() => {
    if (defaultUnit !== null){
      setNewRequest({
        ...newRequest,
        RoomKey: defaultUnit.RoomKey
      });
      setSelectedUnit(defaultUnit);
    }
  }, [defaultUnit]);
  
  
  const emptyForm = () => {
    setNewRequest(emptyFormState);
  };

  return (
    <Dialog aria-labelledby='form-add-unit'
      onClose={() => setOpen(false)}
      open={open}
    >
    {
      newRequest 
      ?
      <form
        autoComplete='off'
        className={classNames(
          classes.dialogFormCtr,
          'xlarge'
        )}
        noValidate
        onSubmit={e => {
          e.preventDefault();
          triggerAction(newRequest, selectedUnit);
          emptyForm();
          setOpen(false);
        }}
      >
        <DialogTitle id='form-dialog-title'>{`Add a ${i18n.t('task')}`}</DialogTitle>
        <DialogContent className={classNames(classes.grow, classes.flexContainer)}>
          <div id='createTask'>
            <Input
              autoFocus
              classes={{
                root: classes.secondInput
              }}
              fullWidth
              id='task'
              label={`${i18n.t('Task')} Name`}
              margin='dense'
              onChange={e =>
                setNewRequest({
                  ...newRequest,
                  Issue: e.target.value
                })
              }
              placeholder={`${i18n.t('Task')} Name`}
              type='text'
              value={newRequest.Issue}
            />

          <TextField
              rows='4'
              fullWidth
              id='summary'
              label='Summary'
              margin='dense'
              multiline
              onChange={e =>
                setNewRequest({
                  ...newRequest,
                  Summary: e.target.value
                })
              }
              placeholder='Additional Info'
              type='text'
              value={newRequest.Summary}
            />
            {
              !defaultUnit
              ? <div>
                  <br/><br/>
                  <span>{`${i18n.t('Property')} Type`}</span>
                  <br/>
                  <FormControlLabel
                    color= {setSelectedValue === 'common' ? 'primary' : 'default'}
                    control={
                      <Radio
                        checked={selectedValue === 'common'}
                        color= 'primary'
                        inputProps={{ 'aria-label': 'Common' }}
                        name="radio-button-common"
                        onChange={handleChange}
                        value='common'  
                      />
                    }
                    label="Common Area"
                    labelPlacement="end"
                    value="common area"
                  />
                  <FormControlLabel
                    color= {setSelectedValue === 'unit' ? 'primary' : 'default'}
                    control={
                      <Radio
                        checked={selectedValue === 'unit'}
                        color= 'primary'
                        inputProps={{ 'aria-label': 'Unit' }}
                        name="radio-button-unit"
                        onChange={handleChange}
                        value='unit'  
                      />
                      }
                        label="Unit"
                        labelPlacement="end"
                        value="unit"
                  />
                  { selectedValue === 'unit' 
                  ?
                    <div className={classes.secondInput}>
                      <AutocompleteProperty 
                        setUnit={(unit: Unit)=> {
                          setSelectedUnit(unit);
                          setNewRequest({
                            ...newRequest,
                            RoomKey: unit.RoomKey
                          });
                        }}
                      />
                    </div>
                  : selectedValue === 'common'
                    ? 
                      <div className={classes.secondInput}>
                        <AutocompleteCommonProperty 
                          setUnit={(unit: Unit)=> {
                            setSelectedUnit(unit);
                            setNewRequest({
                              ...newRequest,
                              RoomKey: unit.RoomKey
                            });
                          }}
                        />
                      </div>
                    : null
                  }
              </div>
              : null
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            onClick={() => setOpen(false)}
          >
            {'Cancel'}
          </Button>
          
          <Button
            color='primary'
            disabled={!newRequest.RoomKey || !newRequest.Issue}
            type='submit'
            variant='contained'
          >
            {`Add ${i18n.t('task')}`}
          </Button>

        </DialogActions>
      </form>
    : null
    } 
    </Dialog>
  );
};

export default withStyles(styles)(AddTask);
