// @flow

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useStore } from '../../store';
import * as R from 'ramda';
import AssignTaskDialog from './AssignTasksDialog';
import SPBackend from '../../services/SPBackend';
import useInterval from '../../hooks/useInterval';
import { log } from '../../utils/jsUtils';
import MemberDrawer from '../MemberDrawer';
import RequestHeader from './RequestHeader';
import RequestContent from './RequestContent';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/MemberHome.Style';
import {
  formatPhoneNumberForBackend,
  validateEmail
} from '../../utils/jsUtils';
import type { Tenant } from '../../services/SPBackend';

type Props = {
  classes: Object
};

const RequestHome = (props: Props) => {
  const { classes } = props;
  let { requestId } = useParams();
  log('[RequestHome] requestId: ', requestId);

  // drawer state & controls
  const [mobileOpen, setMobileOpen] = useState(false);
  const [requestHistory, setRequestHistory] = useState(null);
  const [tenant, setTenant] = useState(null);
  const [isV2Format, setIsV2Format] = useState(null);
  const [request, setRequest] = useState(null);
  const [loadingRequest, setLoadingRequest] = useState(true);
  const [unit, setUnit] = useState(null);
  const [openAssignTaskDialog, setOpenAssignTaskDialog] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  const usehandleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const updateTaskCallback = (task) => (newTask) => {
    if (newTask !== null) {
      log('[updateTaskCallback] newTask: ', newTask);
      setRequest(newTask);
      setTenant(newTask.lookupTenant);
    }
    refreshRequestDetails();
  };

  const [store, dispatch] = useStore();
  const {
    user,
    i18n,
    isDemoMode
  } = store;

  const refreshRequestDetails = (successCallback) => {
    if (user !== null) {
      log('[refreshRequestDetails]...');
      setLoadingRequest(true);
      SPBackend.getRequestHistory(requestId, user)
      .then(response => {
        log('[getRequestHistory] data: ', response);
        const { task, unit, history, tenant, isV2Format } = response.data;
        setIsV2Format(isV2Format);
        setTenant(tenant);
        setRequest(task);
        setUnit(unit);
        const byDate = R.descend(R.prop('Date'));
        if (typeof history !== 'undefined' && history !== null) {
          const sortedHistory = R.sort(byDate, history);
          setRequestHistory(sortedHistory);
        }
        setLoadingRequest(false);
        if (typeof successCallback !== 'undefined' 
          && typeof successCallback === 'function') {
          successCallback();
        }
      })
      .catch(error => {
        log('[getRequestHistory] error', error);
        setLoadingRequest(false); 
      });
    }
  };

  useEffect(() => {
    log('[useEffect] refreshing request details...');
    refreshRequestDetails();
  }, [requestId, user]);

  const updateRequestStatus = (newStatus, callbackSetLoading) => () => {
    callbackSetLoading(prevState => !prevState);
    if (request !== null) {
      SPBackend.updateRequest(request._id, newStatus, user)
        .then(response => {
          const { data : updatedRequest } = response;
          log('[updated request]: ', updatedRequest);
          setRequest(updatedRequest);
          dispatch({
            type: 'ACTION_SET_SNACK_MSG', 
            snackMessage: {
              message: `${i18n.t('task')} updated.`,
              level: 'success',
              duration: 5000
            }
          });
          callbackSetLoading(prevState => !prevState);
          refreshRequestDetails();
        })
        .catch(err => {
          log('err: ', err);
        });
    }
  };

  // const handleEditTenant = (
  //   tenant: Tenant,
  //   moveTasks: boolean,
  //   callback: Function = null
  // ) => {
  //   const formattedNb = formatPhoneNumberForBackend(tenant.Phone);
  //   // TODO: validation logic to factor with addTenant in TenantsHeader and PropertyContent, and here.
  //   // validate email - if non empty
  //   if (tenant.Email !== '' && !validateEmail(tenant.Email)) {
  //     dispatch({
  //       type: 'ACTION_SET_SNACK_MSG',
  //       snackMessage: {
  //         message: 'Invalid email.',
  //         level: 'error',
  //         duration: 3000
  //       }
  //     });
  //     return;
  //   }
    
  //   if (!formattedNb) {
  //     dispatch({
  //       type: 'ACTION_SET_SNACK_MSG',
  //       snackMessage: {
  //         message: 'Invalid phone number.',
  //         level: 'error',
  //         duration: 3000
  //       }
  //     });
  //     return;
  //   }

  //   const payload = {
  //     ...tenant,
  //     Phone: formattedNb,
  //     moveTasks
  //   };

  //   dispatch({
  //     type: 'ACTION_SET_IS_LOADING_MODULE',
  //     module: 'editTenant',
  //     loading: true
  //   });
  //   SPBackend.editTenant(payload, user)
  //     .then(response => {
  //       log('[handleEditTenant] response: ', response);
  //       const { tenant: updatedTenant } = response.data;
  //       log('[editTenant] res: ', updatedTenant);
  //       if (callback) {
  //         callback();
  //       }
  //       // update request in store
  //       setTenant(updatedTenant);
  //       setRequest({
  //         ...request,
  //         lookupTenant: updatedTenant
  //       });
  //       dispatch({
  //         type: 'ACTION_UPDATE_REQUESTS', 
  //         requests: [{
  //           ...request,
  //           lookupTenant: updatedTenant
  //         }]
  //       });
  //       dispatch({
  //         type: 'ACTION_SET_SNACK_MSG',
  //         snackMessage: {
  //           message: `${i18n.t('Tenant')} updated`,
  //           level: 'success',
  //           duration: 3000
  //         }
  //       });
  //       dispatch({
  //         type: 'ACTION_SET_IS_LOADING_MODULE',
  //         module: 'editTenant',
  //         loading: false
  //       });
  //     })
  //     .catch(error => {
  //       log('[handleEditTenant] error', error);
  //       dispatch({
  //         type: 'ACTION_SET_SNACK_MSG',
  //         snackMessage: {
  //           message: error.message || 'An error occured. Please try again.',
  //           level: 'error',
  //           duration: 3000
  //         }
  //       });
  //       dispatch({
  //         type: 'ACTION_SET_IS_LOADING_MODULE',
  //         module: 'editTenant',
  //         loading: false
  //       });
  //     });
  // };

  const handleAssignTask = (tasks, assignedStaff) => {
    if (typeof request !== 'undefined' && request !== null) {
      log('[handleAssignTask] assignedStaff: ', assignedStaff);
      SPBackend.assignStaffToTasks(
        tasks.map(task => task._id),
        assignedStaff._id,
        user
      ).then(() => {
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: `${i18n.t('task')} assigned.`,
            level: 'success',
            duration: 5000
          }
        });
        refreshRequestDetails();
      })
      .catch(err => {
        log('err: ', err);
      });
    }
  };

  // look for unread messages within this task
  if (process.env.REACT_APP_FEED_AUTOREFRESH === 'true') {
    useInterval(() => {
      if (requestId
        && typeof user !== 'undefined'
        && user !== null) {
        log('[RequestHome] refreshing unread count...');
        SPBackend.getTaskUnreadCount(requestId, user)
        .then((response) => {
          log('[getTaskUnreadCount] response: ', response);
          setUnreadCount(response.data.UnreadCount);
        })
        .catch((error) => {
          log('[getTaskUnreadCount] error: ', error);
        });
      }
      log('[RequestHome] fpcusing on last element...');
      
    }, 5000);
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Neo | Conversation</title>
      </Helmet>
      <RequestHeader
        isDemoMode={isDemoMode}
        mobileOpen={mobileOpen} 
        refreshRequestDetails={refreshRequestDetails}
        request={request}
        setOpenAssignTaskDialog={setOpenAssignTaskDialog}
        unit={unit}
        updateRequestStatus={updateRequestStatus}
        usehandleDrawerToggle={usehandleDrawerToggle}
      />
      <MemberDrawer 
        mobileOpen={mobileOpen} 
        usehandleDrawerToggle={usehandleDrawerToggle}
      />
      <RequestContent
        isV2Format={isV2Format}
        loadingRequest={loadingRequest}
        refreshRequestDetails={refreshRequestDetails}
        request={request}
        requestHistory={requestHistory}
        setTenant={setTenant}
        tenant={tenant}
        unit={unit}
        unreadCount={unreadCount}
        updateTaskCallback={updateTaskCallback}
      /> 
      <AssignTaskDialog
        open={openAssignTaskDialog}
        setOpen={setOpenAssignTaskDialog}
        tasks={[request]}
        triggerAction={handleAssignTask}
      />
    </div>
  );
};

export default withStyles(styles)(RequestHome);