// @flow
// strongly inspired by https://github.com/jasonbellamy/react-mailto

import React from 'react';

export const toSearchString = (searchParams = {}) => {
  return Object.keys(searchParams).map(key =>
    `${key}=${encodeURIComponent(searchParams[key])}`
  ).join('&');

};

export const createMailtoLink = (email: ?string, headers: ?Object) => {
  let link = 'mailto:';
  if (typeof email !== 'undefined' && email !== null) {
    link += `${email}`;
  }
  if (headers) {
    link += `?${toSearchString(headers)}`;
  }
  return link;
};

type Props = {
  children: Object,
  email: ?string, // email address of the intended recipient.
  headers: ?Object, // any standard mail header fields. The most commonly-used of these are 'subject', 'cc', and 'body' (which is not a true header field, but allows you to specify a short content message for the new email).
  obfuscate: boolean, // show the email address in the status bar.
}

const Mailto = (props: Props) => {
  const { email, obfuscate, headers, children } = props;

  const renderLink = () => {
    return (
      <a
        href={createMailtoLink(email, headers)}
        onClick={(e) => e.stopPropagation()}
        target='_blank'
      >
        {children}
      </a>
    );
  };

  // eslint-disable-next-line react/no-multi-comp
  const renderObfuscatedLink = () => {
    return (
      <a 
        href='mailto:obfuscated' 
        onClick={handleClick}
        target='_blank'
      >
        {children}
      </a>
    );
  };

  const handleClick = (event) => {
    event.preventDefault();
    window.location.href = createMailtoLink(email, headers);
  };
  
  return (
    obfuscate
      ? renderObfuscatedLink()
      : renderLink()
  );

};

export default Mailto;