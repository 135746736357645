// @flow

import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/EditUnitDialog.Style';
import type { Unit } from '../../services/SPBackend';

type Props = {
  classes: Object,
  setOpen: Function,
  open: boolean,
  triggerAction: Function,
  unit: Unit
};

const EditUnit = (props: Props) => {
    const {
      classes,
      setOpen,
      open,
      triggerAction,
      unit
    } = props;
    const [changedUnit, setChangedUnit] = useState({
      Apt: '',
      Floor: ''
    });
    const [floorErrorMessage, setFloorErrorMessage] = useState('');
    const [aptErrorMessage, setAptErrorMessage] = useState('');

    useEffect(() => {
      if (unit!==null) {
        setChangedUnit({
          Floor: unit.Floor,
          Apt: unit.Apt
        });
      }
    }, [unit]);

    return (
      <Dialog
        aria-labelledby='form-add-unit'
        onClose={() => setOpen(false)}
        open={open}
      >
        <form
          autoComplete='off'
          className={classes.dialogFormCtr}
          noValidate
          onSubmit={(e) => { 
            e.preventDefault();

            setFloorErrorMessage('');
            setAptErrorMessage('');

            let floorIsValid = true;
            // eslint-disable-next-line no-undefined
            if (changedUnit.Floor === undefined || changedUnit.Floor === null ||
              changedUnit.Floor.length<=0) {
              setFloorErrorMessage('Please enter the floor');
              floorIsValid = false;
            }
            if (changedUnit.Apt.length <= 0) {
              setAptErrorMessage('Please enter the apartment name');
              return;
            }
            if (!floorIsValid) {
              return;
            }
            triggerAction(changedUnit); 
            setOpen(false); 
          }}
        >
            <DialogTitle id='form-dialog-title'>
            {'Edit this Unit'}
            </DialogTitle>

            <DialogContent className={classNames(classes.grow, classes.flexContainer)}>
              <Input
                  autoFocus
                  defaultValue={unit!==null ? unit.Floor : ''}
                  fullWidth
                  id='broadcastIssue'
                  label='Floor'
                  margin='dense'
                  onChange={(e) => setChangedUnit({
                    ...changedUnit,
                    Floor: e.target.value
                  })}
                  placeholder='Floor'
                  type='text'
              />
              <DialogContentText className={classes.error}>{ floorErrorMessage }</DialogContentText>
              <Input
                  classes={{
                    root: classes.secondInput
                  }}
                  defaultValue={unit!==null ? unit.Apt : ''}
                  fullWidth
                  id='broadcastIssue'
                  label='Apartment name (e.g. 2 East)'
                  margin='dense'
                  onChange={(e) => setChangedUnit({
                    ...changedUnit,
                    Apt: e.target.value
                  })}
                  placeholder='Apartment name (e.g. 2 East)'
                  type='text'
              />
              <DialogContentText className={classes.error}>{ aptErrorMessage } </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color='primary'
                onClick={() => {setFloorErrorMessage(''); setAptErrorMessage(''); setOpen(false);}}
              >
                {'Cancel'}
                </Button>
              <Button 
                color='primary'
                type='submit'
                variant='contained'
              >
                {'Finish Editing'}
                </Button>
            </DialogActions>
        </form>
      </Dialog>
    );
  };

export default withStyles(styles)(EditUnit);