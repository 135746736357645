// @flow

import React from 'react';
import classNames from 'classnames';
import { log, formatPhoneNumberForFrontend } from '../../utils/jsUtils';
import { useStore } from '../../store';

import Button from '@material-ui/core/Button';
// import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import RemoveIcon from '@material-ui/icons/DeleteOutline';

import combineStyles from '../../utils/stylesUtils';
import collapsibleListTableStyles from '../Styles/CommonCollapsibleListTable.Style';
import styles from './Styles/PropertyTenantsMgr.Style';
import { withStyles } from '@material-ui/core/styles';

import type { Tenant, User, Building, UnitAndTenants } from '../../services/SPBackend';

type Props = {
  unitAndTenants: UnitAndTenants,
  openAddTenant: Function,
  setOpenRemoveTenantFromUnitDialog: Function,
  selectTenant: Function,
  index: number,
  classes: Object
};

const PropertyTenantsMgr = (props: Props) => {
  const {
    classes,
    openAddTenant,
    setOpenRemoveTenantFromUnitDialog,
    selectTenant,
    index,
    unitAndTenants
  } = props;

  const [store] = useStore();
  const {
    i18n
  } = store;

  return (
    <Grid 
      className={classes.tableContent}
    >
      {
        unitAndTenants && unitAndTenants.tenants && unitAndTenants.tenants.length
        ? <Table className={classes.table}>
            <TableHead>
              <TableRow className={classNames(
                classes.tableRowCtr,
                classes.simpleTableHeadRow
              )}>
                <TableCell 
                  className={classes.fixedCell}
                  padding='none'
                >{i18n.t('Tenant')}</TableCell>
                <TableCell 
                  className={classes.fixedCell}
                  padding='none'
                >Email</TableCell>
                <TableCell
                  className={classes.growCell}
                >Phone</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {unitAndTenants.tenants.map((tenant, i) => (
                <TableRow 
                  className={classNames(
                    classes.tableRowCtr,
                    classes.simpleTableRow
                  )}
                  key={`tenant-${i}`}
                >
                  <TableCell
                    className={classNames(classes.cell, classes.fixedCell)}
                    padding='none'
                    scope="tenant"
                  >
                    {tenant.FirstName} {tenant.LastName}
                  </TableCell>
                  <TableCell
                    className={classNames(classes.cell, classes.fixedCell)}
                    padding='none'
                    scope="tenant"
                  >
                    {tenant.Email}
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes.cell,
                      classes.growCell
                    )}
                  >
                    {formatPhoneNumberForFrontend(tenant.Phone)}
                  </TableCell>
                  <TableCell
                    align='right'
                    className={classNames(
                      classes.noPadding, 
                      'actionCell',
                      'lastChild')}
                    >
                    <Tooltip 
                      aria-label={`Move this ${i18n.t('tenant')} out`}
                      title={`Move this ${i18n.t('tenant')} out`} 
                    >
                      <IconButton 
                        aria-label={`Move this ${i18n.t('tenant')} out` }
                        classes={{
                          root: classes.rowActionBtn
                        }}
                        onClick={(e) => {
                          selectTenant(tenant);
                          setOpenRemoveTenantFromUnitDialog(true);
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        : <div className={classes.basicMargin}>
          <Typography variant='body1'>{`No ${i18n.t('tenants')} found.`}</Typography>
        </div>
      }
      <Grid
        className={classes.rowActionBtn}
        item
      >
        <Button
          color='primary'
          onClick={() => openAddTenant(unitAndTenants)}
          variant='contained'
        >
          {`Add ${i18n.t('tenant')}`}
        </Button>
      </Grid>
    </Grid>
  );
};


const combinedStyles = combineStyles(
  collapsibleListTableStyles,
  styles
);

export default withStyles(combinedStyles)(PropertyTenantsMgr);