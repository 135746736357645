// @flow

import { phoneInputCss } from '../../../css/phoneInput';

const styles = (theme: Object) => ({
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  dialogFormCtr: {
    height: 'auto',
    // maxHeight: 300,
    display: 'flex',
    flexDirection: 'column',
    '&.xlarge': {
      maxHeight: 400
    },
    '&.large': {
      maxHeight: 350
    }
  },
  grow: {
    flexGrow: 1
  },
  secondInput: {
    marginTop: theme.spacing(2)
  },
  largeTextField: {
    '& label': {
      transform: 'translate(20px, 24px) scale(1)'
    },
    '& textarea': {
      padding: theme.spacing(1)
    }
  },
  messageTenantDialogTitle: {
    fontSize: '1rem',
    padding: '24px 24px 8px 24px'
  },
  textFieldCaptionRight: {
    textAlign: 'right',
    color: theme.palette.primary.light,
    fontSize: '0.8rem',
    position: 'absolute',
    bottom: 20,
    right: 42
  },
  warning: {
    color: theme.palette.error.main
  },
  error: {
    color: theme.palette.status.error.main
  },
  phoneInputCtr: phoneInputCss,
  colorPickerRainbow: {
    background: 'conic-gradient(red, yellow, lime, aqua, blue, magenta, red)',
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: theme.spacing(3),
    cursor: 'pointer',
    position: 'relative'
    // top: 6
  },
  popperCtr: {
    zIndex: 9999,
    top: '12px !important',
    left: '116px !important',
    [theme.breakpoints.down('xs')]: {
      left: '32px !important'
    }
  },
  margin: {
    marginTop: theme.spacing(3)
  }
});

export default styles;
