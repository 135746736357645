// @flow

import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';
import { useLocation } from 'react-router-dom';

import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import * as R from 'ramda';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';


import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/AddTenantDialog.Style';
import AutocompleteProperty from '../Properties/AutocompleteProperty';
import type { Tenant } from '../../services/SPBackend';
import TagRenderer from '../SharedComponents/TagRenderer';

type Props = {
  classes: Object,
  setOpen: Function,
  open: boolean,
  currTenant: Tenant,
  triggerAction: Function,
};

// eslint-disable-next-line react/no-multi-comp
const EditTenant = (props: Props) => {
  const {
    classes,
    currTenant,
    setOpen,
    open,
    triggerAction
  } = props;
  const [store] = useStore();
  const {
    i18n,
    defaultUnit,
    tags
  } = store;
  const location = useLocation();
  // log('[editTenant] location: ', location);
  const [loading, setLoading] = useState(false);
  const [tenant, setTenant] = useState({
    _id: null,
    FirstName: '',
    LastName: '',
    Phone: '',
    Email: '',
    Keys: defaultUnit ? [defaultUnit.RoomKey] : '',
    Tags: []
  });
  const [error, setError] = useState('');
  const [moveTasks,setMoveTasks]=useState(false);
  const [checked, setChecked]=useState(false);

  useEffect(() => {
    if (typeof currTenant !== 'undefined' && currTenant !== null) {
      setError('');
      setTenant({
        _id: currTenant._id,
        FirstName: currTenant.FirstName ? currTenant.FirstName : '',
        LastName: currTenant.LastName ? currTenant.LastName : '',
        Phone: currTenant.Phone ? `+${currTenant.Phone}` : '',
        Email: currTenant.Email ? currTenant.Email : '',
        Keys: defaultUnit ? [defaultUnit.RoomKey] : (currTenant.unitsDocs && typeof currTenant.Keys !== 'undefined' && currTenant.Keys !== null) ? currTenant.Keys[0] !== '' ? [currTenant.unitsDocs[0].RoomKey] : '' : '',
        Tags: currTenant.Tags ? currTenant.Tags : []
      });
    }
  }, [currTenant, open]);

  const callbackOnTagPicked = (params) => {
    log('[callbackOnTagPicked] currTenant: ', currTenant);
    log('[callbackOnTagPicked] tenant: ', tenant);
    log('[callbackOnTagPicked] params: ', params);
    setTenant({
      ...tenant,
      Tags: R.append(params._id, tenant.Tags)
    });
    log('[callbackOnTagPicked] params: ', params);
  };

  const handleCheckbox = (event) => {
    log('[editTenantDialog][handleCheckbox] checkbox value', event.target.checked);
    if (event.target.checked) {
      setChecked(true);
      setMoveTasks(true);
    } else {
      setChecked(false);
      log('[editTenantDialog][handleCheckbox] the checkbox is', event.target.checked );
      log('[editTenantDialog] NOT moving tasks to new unit!!!'); 
      setMoveTasks(false);
    }
  };

  return (
    <Dialog aria-labelledby='form-add-unit'
      onClose={() => setOpen(false)}
      open={open}
    >
      <form
        autoComplete='off'
        className={classNames(
          classes.dialogFormCtr,
          'xlarge'
        )}
        noValidate
        onSubmit={e => {
          e.preventDefault();
          setLoading(true);
          if (tenant.Keys !== null) {
            triggerAction(tenant, moveTasks, () => {
              setLoading(false);
              setOpen(false);
            });
          } else {
            setLoading(false);
            setError('Please select a unit from the dropdown');
          } 
        }}
      >
        <DialogTitle id='form-dialog-title'>{`Edit ${i18n.t('tenant')}`}</DialogTitle>
        <DialogContent className={classNames(classes.grow, classes.flexContainer)}>
          <div id='EditTenant'>
            <Input
              autoFocus
              classes={{
                root: classes.secondInput
              }}
              fullWidth
              id='firstName'
              label='Fist Name'
              margin='dense'
              onChange={e =>
                setTenant({
                  ...tenant,
                  FirstName: e.target.value
                })
              }
              placeholder='First name'
              type='text'
              value={tenant.FirstName}
            />
            <Input
              classes={{
                root: classes.secondInput
              }}
              fullWidth
              id='lastName'
              label='Last Name'
              margin='dense'
              onChange={e =>
                setTenant({
                  ...tenant,
                  LastName: e.target.value
                })
              }
              placeholder='Last name'
              type='text'
              value={tenant.LastName}
            />
            <Input
              classes={{
                root: classes.secondInput
              }}
              fullWidth
              id='email'
              label='Email'
              margin='dense'
              onChange={e =>
                setTenant({
                  ...tenant,
                  Email: e.target.value
                })
              }
              placeholder='Email'
              type='email'
              value={tenant.Email}
            />
            <PhoneInput
              className={classNames(classes.phoneInputCtr, 'defaultInput')}
              defaultCountry='US'
              onChange={e =>
                setTenant({
                  ...tenant,
                  Phone: e
                })
              }
              placeholder="Enter phone number"
              value={formatPhoneNumberIntl(tenant.Phone)}
            />
            {
              location.pathname === '/customers'
              ? <TagRenderer
                  callbackOnTagPicked={callbackOnTagPicked}
                  tagsIds={tenant.Tags}
                />
              : null
            }
            
            {/* {
              !defaultUnit
              ? <div className={classes.secondInput}>
                  <AutocompleteProperty 
                    inputTenant={currTenant}
                    setUnit={(unit) => {
                      log('[AutocompleteProperty] unit', unit);
                      setTenant({
                        ...tenant,
                        Keys: unit !== null ? [unit.RoomKey] : null
                      });
                      log('[setUnit] unit, tenant: ', unit, tenant);
                      // setNewUnit(unit.RoomKey);
                    }}
                  />
                  <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox checked={checked}
                                            onChange={handleCheckbox}
                                 />}
                        label="Move all tasks to the new unit"
                      />
                  </FormGroup>
                </div>
              : null
            } */}
          </div>
          <DialogContentText className={classes.error}>{ error }</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            onClick={(event) => {
              event.preventDefault();
              setOpen(false);
            }}
            type='button'
          >
            {'Cancel'}
          </Button>
          
          <Button
            color='primary'
            disabled={loading}
            type='submit'
            variant='contained'
          >
            {'Save'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(EditTenant);
