// @flow

import React, { useState } from 'react';
import classNames from 'classnames';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/AddStaffDialog.Style';
import AutocompleteBuilding from '../StaffMgt/AutocompleteBuilding';
import type { User, Building } from '../../services/SPBackend';

type Props = {
  handleAddPropertyAccess: Function,
  classes: Object,
  setOpen: Function,
  open: boolean,
  building: Building,
  selectedStaff: User
};

// eslint-disable-next-line react/no-multi-comp
const AddProperty = (props: Props) => {
  const {
    classes,
    setOpen,
    open,
    handleAddPropertyAccess,
    selectedStaff,
    building
  } = props;

  const [store] = useStore();
  const {
    i18n
  } = store;

  const [staff, setStaff] = useState({
    Email: '',
    Permissions: {
      Properties: []
    }
  });

  return (
    <Dialog aria-labelledby='form-add-Property-Access'
      onClose={() => setOpen(false)}
      open={open}
    >
      <form
        autoComplete='off'
        className={classNames(
          classes.dialogFormCtr,
          'xlarge'
        )}
        noValidate
        onSubmit={e => {
          e.preventDefault();
          handleAddPropertyAccess(staff);
          setOpen(false);
        }}
      >
        <DialogTitle id='form-dialog-title'>{`Add permission to a ${i18n.t('property')}`}</DialogTitle>
        <DialogContent className={classNames(classes.grow, classes.flexContainer)}>
          <div id='addPropertyAccess'>
            {
              !building
              ? <div className={classes.secondInput}>
                <AutocompleteBuilding
                  setBuilding={(building) => {
                    log('[setBuilding] selectedStaff, building: ', selectedStaff, building);
                    setStaff({
                      Email: selectedStaff.Email,
                      Permissions: {
                        Properties: [building.PropertyKey]
                      }
                    });
                    log('[setBuilding] staff: ', staff);
                  }}
                />
              </div>
              : null
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            onClick={() => {setOpen(false);log('[setBuilding] staff: ', staff);}}
          >
            {'Cancel'}
          </Button>
          <Button
            color='primary'
            onClick={() => setOpen(false)}
            type='submit'
            variant='contained'
          >
            {`Add ${i18n.t('Property')} Access`}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(AddProperty);
