// @flow

import React, { useState, useEffect, useCallback } from 'react';
import * as R from 'ramda';
import { useStore } from '../../store';
import { log } from '../../utils/jsUtils';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/BotSettingsContent.Style';
import listTableStyles from '../Styles/CommonListTable.Style';
import MemberStructuralStyles from '../Styles/CommonMemberStructuralStyles.Style';
import combineStyles from '../../utils/stylesUtils';

import KeywordDetailsDialog from './KeywordDetailsDialog';
import KeywordTriggersRow from './KeywordTriggersRow';
import BotSettingsScript from './BotSettingsScript';
import SPBackend from '../../services/SPBackend';

import type { BotSettings, BotSettingsScriptStep, Keyword } from '../../services/SPBackend';

type Props = {
  classes: Object
};

// eslint-disable-next-line react/no-multi-comp
const BotSettingsContent = (props: Props) => {
  const {
    classes
  } = props;

  const [value, setValue] = useState(0);
  const [keywords, setKeywords] = useState([]);
  const [openEditKeywordDialog, setOpenEditKeywordDialog] = useState(false);
  const [keyword, setKeyword] = useState(null); // Selected Keyword
  const [isNewKeyword, setIsNewKeyword] = useState(false);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [store, dispatch] = useStore();
  const {
    user
  } = store;

  const refreshKeywordTriggers = () => {
    if (user !== null){
      dispatch({
        type: 'ACTION_SET_IS_LOADING_MODULE',
        module: 'removeKeywordTrigger',
        loading: true
      });
      SPBackend.findKeywordTriggers(user)
      .then((response) => {
        const keywords = response.data;
        log('[BotSettingsContent].[findKeywordTriggers] error: ', keywords);
        setKeywords(keywords);
      })
      .catch(error => {
        log('[BotSettingsContent].[findKeywordTriggers] error: ', error);
      });
      dispatch({
        type: 'ACTION_SET_IS_LOADING_MODULE',
        module: 'removeKeywordTrigger',
        loading: false
      });
    }
  };

  // Fetch keyword Triggers
  useEffect(() => {
    if (user !== null) {
      log('useEffect, user changed?');
      refreshKeywordTriggers();
    }
  }, [user]);



  // eslint-disable-next-line react/no-multi-comp
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        aria-labelledby={`simple-tab-${index}`}
        className={classes.tabBody}
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        role="tabpanel"
        {...other}
      >
        {value === index && (
          <Box
            classes={{root: classes.boxRoot}}
            p={3}
          >
            <Typography component={'div'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    // eslint-disable-next-line react/forbid-prop-types
    index: PropTypes.any.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any.isRequired
  };
  
  const tabProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  };


  const removeKeywordTrigger = (keywordToDelete) => {
    if (user !== null ) {
      dispatch({
        type: 'ACTION_SET_IS_LOADING_MODULE',
        module: 'removeKeywordTrigger',
        loading: true
      });
      SPBackend.removeKeywordTrigger(keywordToDelete._id, user)
      .then(response => {
        log('[removeKeywordTrigger] response: ', response);
        const filterById = p => p._id !== keywordToDelete._id;
        setKeywords(prevProps => {
          const newProps = R.filter(filterById, prevProps);
          return newProps;
        });  
        // Show snack message
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: 'Trigger Removed',
            level: 'success',
            duration: 3000
          }
        });
        dispatch({
          type: 'ACTION_SET_IS_LOADING_MODULE',
          module: 'removeKeywordTrigger',
          loading: false
        });
      })
      .catch(error => {
        log('[removeKeywordTrigger] error: ', error);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: 'An error occured. Please try again.',
            level: 'error',
            duration: 3000
          }
        });
        dispatch({
          type: 'ACTION_SET_IS_LOADING_MODULE',
          module: 'removeKeywordTrigger',
          loading: false
        });
      });
    }
  };

  const handleEditKeyword = (keywordPayload: Keyword) => {
    if ( user !== null ){
      log('[handleAddNewKeyword] adding new keyword', keyword, isNewKeyword);
      dispatch({
        type: 'ACTION_SET_IS_LOADING_MODULE',
        module: 'removeKeywordTrigger',
        loading: true
      });
      SPBackend.editKeywordTrigger(keywordPayload, user, isNewKeyword)
      .then(response => {
        log('[handleAddNewKeyword] response: ', response.data);
        const keywords  = response.data;
        setKeywords(keywords);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG',
          snackMessage: {
            message: 'Trigger Updated',
            level: 'success',
            duration: 3000
          }
        });
        dispatch({
          type: 'ACTION_SET_IS_LOADING_MODULE',
          module: 'removeKeywordTrigger',
          loading: false
        });
      })
      .catch(error => {
        dispatch({
          type: 'ACTION_SET_SNACK_MSG',
          snackMessage: {
            message: error.message || 'An error occured. Please try again.',
            level: 'error',
            duration: 3000
          }
        });
        dispatch({
          type: 'ACTION_SET_IS_LOADING_MODULE',
          module: 'removeKeywordTrigger',
          loading: false
        });
        setKeyword(null);
      });
    }
  };

  const handleCloseKeywordDetailsDialog = () => {
    // re-enable credits auto-refresh
    log('[handleCloseKeywordDetailsDialog]...');
    dispatch({
      type: 'ACTION_SET_BILLING_LIVE',
      isBillingLive: true
    });
    // emptyForm(); 
    setOpenEditKeywordDialog(false);
  };

  const handleClickEditKeyword = (keyword) => {
    log('handleClickEditKeyword....', keyword);
    if (keyword) {
      setKeyword(keyword);
      // disable credits auto-refresh, to prevent auto-closing of popup
      dispatch({
        type: 'ACTION_SET_BILLING_LIVE',
        isBillingLive: false
      });
      log('[in timeout]', openEditKeywordDialog ? 'openEditKeywordDialog' : '!openEditKeywordDialog');
      setOpenEditKeywordDialog(true);
      setIsNewKeyword(false);
      log('out of timeout');
    }
  };
  // const handleClickEditKeyword = (e, _k) => {
  // };
  // log('[KeywordTriggersRow.render] openEditKeywordDialog: ', openEditKeywordDialog ? 'open' : '!open');
  const [isLoadingBotSettings, setIsLoadingBotSettings] = useState<boolean>(false);
  const [botScriptData, setBotScriptData] = useState(null);
  
  useEffect(() => {
    if (user !== null && !botScriptData) {
      log('loading Script... ');
      setIsLoadingBotSettings(true);
      SPBackend.getAccountBotSettings(user)
        .then((response) => {
          log('[getAccountBotSettings] bot settings: ', response.data);
          setBotScriptData(response.data);
          setIsLoadingBotSettings(false);
        })
        .catch((e) => {
          setIsLoadingBotSettings(false);
          log('[getAccountBotSettings] error: ', e);
        });
    }
  }, [user]);

  return (
    <Grid 
      className={classes.tableContent}
    >
      <div className={classes.toolbar} />
      <Paper 
        className={classes.tabsCtr}
        elevation={1}
      >
        <AppBar
          className={classes.appBar}
          color="default"
          position="static"
        >
          <Tabs aria-label="Neo The Bot" 
                indicatorColor='primary' 
                onChange={handleChange}
                textColor='primary'
                value={value}
          >
            <Tab label="CONVERSATION SCRIPT"
                  {...tabProps(0)} 
                  className={classes.tabBar}
            />
            <Tab label={'KEYWORDS'}
                {...tabProps(1)}
                className={classes.tabBar}
            />
          </Tabs>
        </AppBar>
        <TabPanel index={0} 
                  value={value}
        >
          <BotSettingsScript
            botScriptData={botScriptData}
            setBotScriptData={setBotScriptData}
          />
        </TabPanel>
        <TabPanel index={1} 
                value={value}
        >
          <KeywordTriggersRow keyword={keyword} 
                              keywords={keywords}
                              setIsNewKeyword={setIsNewKeyword}
                              setKeyword={handleClickEditKeyword}
                              setKeywords={setKeywords}
                              setOpenEditKeywordDialog={setOpenEditKeywordDialog}
          />
        </TabPanel>
      </Paper>
      <KeywordDetailsDialog 
        isNewKeyword={isNewKeyword}
        keyword={keyword}
        onClose={handleCloseKeywordDetailsDialog}
        open={openEditKeywordDialog}
        removeKeywordTrigger={removeKeywordTrigger}
        setKeyword={setKeyword}
        setOpen={setOpenEditKeywordDialog}
        triggerAction={handleEditKeyword}
      />
    </Grid>
  );
};

const combinedStyles = combineStyles(
  listTableStyles,
  MemberStructuralStyles,
  styles
);
export default withStyles(combinedStyles)(BotSettingsContent);