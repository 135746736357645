// @flow

const styles = (theme: Object) => ({
  root: {
    minHeight: '100vh',
    display: 'flex'
  },
  contentCtr: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    '& p': {
      fontSize: '0.9rem'
    }
  },
  ctaBtnAnchor: {
    marginBottom: theme.spacing(2),
    textDecoration: 'none'
  },
  ctaBtn: {
    marginTop: theme.spacing(2),
    '& span': {
      textDecoration: 'none'
    }
  },
  paragraph: {
    display: 'block',
    marginTop: theme.spacing(2)
  }
});

export default styles;
