// @flow

import React, { useEffect, Component } from 'react';
import { Switch, Route, useHistory, useLocation, Router } from 'react-router-dom';
// import { createBrowserHistory } from 'history';
import i18n from 'i18next';
import queryString from 'query-string';
import resources from '../sp-resources.json';
import * as R from 'ramda';
import { isMobile } from 'react-device-detect';

import PrivateRoute from './PrivateRoute';

import Inspectlet from '../services/inspectlet';
import GA from '../services/gAnalytics';
import { onAuthStateChanged, auth } from '../services/firebase';
import SPBackend from '../services/SPBackend';
import { getUserRole } from '../models/User';

// import HeaderPublic from "./HeaderPublic";
import { useStore } from '../store';
import { redirectBasedOnAccountStatus } from '../utils/pathUtils';
import { log } from '../utils/jsUtils';
import RoutesContainer from './RoutesContainer';
import MemberHome from './Feed/MemberHome';
import RequestHome from './Feed/RequestHome';
import PropertyHome from './Properties/PropertyHome';
import PropertiesHome from './Properties/PropertiesHome';
import StaffListHome from './StaffMgt/StaffListHome';
import TenantsHome from './Tenants/TenantsHome';
import AnalyticsHome from './Analytics/AnalyticsHome';
import PublicWelcome from './PublicCpts/PublicWelcome';
import PlansHome from './Billing/PlansHome';
import CheckoutHome from './Billing/CheckoutHome';
import BillingHome from './Billing/BillingHome';
import BotSettingsHome from './BotSettings/BotSettingsHome';
import StaffInvitationHome from './PublicCpts/StaffInvitationHome';
import TwilioNumberHome from './TwilioNumber/TwilioNumberHome';
import Login from './PublicCpts/Login';
import Signup from './PublicCpts/Signup';
import VerifyPhoneHome from './VerifyPhone/VerifyPhoneHome';
import TagsHome from './Tags/TagsHome';
import IntegrationsHome from './Integrations/IntegrationsHome';
import ReferralsHome from './Referrals/ReferralsHome';
import TOS from './PublicCpts/TOS';
import PrivacyPolicy from './PublicCpts/PrivacyPolicy';
import AuthorizeHome from './Auth/AuthorizeHome';
import Logout from './Logout';
import ApiDocs from './ApiDocs/index';
import InvalidUrl from './InvalidUrl';
import pathUtils from '../utils/pathUtils';

GA.initialize();
Inspectlet.initialize();

type Props = {
  location: Object
};

const SquarePlanApp = (props: Props) => {  
  const location = useLocation();
  const history = useHistory();
  log('[SquarePlanApp] history: ', history);

  const parsed = queryString.parse(location.search);
  // log('[SquarePlanApp] render parsed: ', parsed);
  const { planid: paramPlanId, ppId: pushPlayerId } = parsed;
  // TODO: store in backend this user's push player id
  // console.log('pushPlayerId: ', pushPlayerId);

  // Initial state
  const initialLoggedInState = () =>
    window.localStorage.getItem('loggedIn') === 'true' ? true : false;
  const initialState = {
    loggedIn: initialLoggedInState(),
    redirectToReferrer: false,
    email: '',
    token: ''
  };
  // log('[SquarePlanApp] initialState: ', initialState);
  const [store, dispatch] = useStore();
  const {
    user,
    loggedIn,
    billing: billingFromStore
  } = store;

  const dispatchLogout = () => {
    log('dispatchLogout pathname: ', history.location.pathname);
    const access = R.find(R.propEq('pathname', history.location.pathname))(pathUtils.pathAuth);
    log('dispatchLogout access: ', access);
    
    if (typeof access !== 'undefined'
      && access !== null
      && access.restricted) {
      window.localStorage.removeItem('loggedIn');
      dispatch({
        type: 'ACTION_LOGOUT'
      });
      dispatch({
        type: 'ACTION_SET_USER', 
        user: null
      });
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: 'Your secure session has expired. Please login again.',
          level: 'warning',
          duration: 5000
        }
      });
      history.push('/');
    }
  };
  
  // logic run each time a change found in logged in session
  useEffect(() => {
    log('[App componentDidMount] loggedIn: ', loggedIn ? 'true' : 'false');
    onAuthStateChanged(auth, fbUser => {
      log('[[[[[app]]]]] fbUser: ', fbUser, user);
      if (!fbUser) {
        log('[[[[[app]]]]] LOGOUT: ', user);
        dispatchLogout();
        
      } else if (fbUser && loggedIn) {
        log('[[[[[app]]]]] LOGIN auth state changed: ', fbUser);
        dispatch({
          type: 'ACTION_SET_USER', 
          user: fbUser
        });
        dispatch({
          type: 'ACTION_LOADING_ACCOUNT'
        });
        dispatch({
          type: 'ACTION_SET_IS_APP_LOADING',
          isAppLoading: true
        });
        SPBackend.getAccount(fbUser)
          .then(response => {
            log('[SquarePlanApp.getAccount] response: ', response);
            log('[SquarePlanApp.getAccount] billingFromStore: ', billingFromStore);
            const { data } = response;
            const { user, account, billing, tags, defaultUnit, defaultBuilding } = data;

            // set new push subscriber id for this user
            // console.log('[SquarePlanApp.getAccount] pushPlayerId: ', pushPlayerId);
            if (typeof pushPlayerId !== 'undefined'
              && pushPlayerId !== null
              && pushPlayerId !== '') {
              SPBackend.setPushSubscriberId(fbUser, pushPlayerId || '')
                .then((response) => {
                  // alert('push subscribed');
                  // console.log('[pushPlayerId] done: ', response);
                })
                .catch((error) => {
                  // console.log('[pushPlayerId] pushPlayerId: ', pushPlayerId);
                  // alert('push NOT subscribed: ', error.toString());
                  // alert('Problem subscribing your device for push notifications');
                });
            }
              
            // fillup store
            const storePayload = {
              userFullName: `${user.FirstName} ${user.LastName}`, 
              userRole: getUserRole(user),
              demoMode: !!user.IsDemoMode,
              isSetupPropertyDone: account.Keys && account.Keys.length > 0,
              billing
            };
            log('[SquarePlanApp.getAccount] storePayload: ', storePayload);
            dispatch({
              type: 'ACTION_SET_BILLING',
              billing: {
                ...billing
              }
            });
            dispatch({
              type: 'ACTION_SET_LOGGED_USER',
              user: user
            });
            dispatch({
              type: 'ACTION_SET_ACCOUNT_SETTINGS', 
              accountSettings: account.Settings
            });
            dispatch({
              type: 'ACTION_SET_ACCOUNT_USAGE', 
              accountUsage: account.Usage
            });
            if (location.pathname !== '/tags') {
              dispatch({
                type: 'ACTION_SET_TAGS', 
                tags: tags
              });
            }
            dispatch({
              type: 'ACTION_SET_ACCOUNTID', 
              accountId: account._id
            });
            dispatch({
              type: 'ACTION_SET_ACCOUNTS', 
              accounts: user.Accounts
            });
            dispatch({
              type: 'ACTION_SET_COMPANYNAME', 
              companyName: account.CompanyName
            });
            dispatch({
              type: 'ACTION_SET_USERFULLNAME', 
              userFullName: `${user.FirstName} ${user.LastName}`
            });
            dispatch({
              type: 'ACTION_SET_USERROLE', 
              userRole: getUserRole(user)
            });
            dispatch({
              type: 'ACTION_SET_DEMOMODE',
              demoMode: !!user.IsDemoMode
            });
            // take param into account
            if ( paramPlanId && paramPlanId !== '') {
              dispatch({
                type: 'ACTION_SET_BILLING_PLANID',
                planId: paramPlanId
              });
              billing.planId = paramPlanId;
            }
            dispatch({
              type: 'ACTION_SET_DEFAULT_UNIT',
              defaultUnit
            });
            dispatch({
              type: 'ACTION_SET_DEFAULT_BUILDING',
              defaultBuilding
            });
            if (typeof account.Agreement !== 'undefined') {
              storePayload.agreement = account.Agreement;
            }
            redirectBasedOnAccountStatus(history, {
              account,
              user,
              billing,
              defaultBuilding
            });
            
            log('[SquarePlanApp.getAccount] Subscription is active. Enjoy!');
            //  else if (typeof billing !== 'undefined'
            // && billing.planId
            // && billing.subscriptionStatus === 'active'
            // // && !storePayload.isSetupPropertyDone
            // ) {
              //   history.push('/member');
              // }
              dispatch({
                type: 'ACTION_LOADED_ACCOUNT',
                payload: storePayload
              });
              dispatch({
                type: 'ACTION_SET_IS_APP_LOADING',
                isAppLoading: false
              });
            GA.set({ userId: user._id });
            Inspectlet.identify(user.Email);
          })
          .catch(err => {
            log('[SquarePlanApp.getAccount] err: ', err);
            dispatchLogout();    
          });
        }
      });
    }, [loggedIn, pushPlayerId]);

  // runs once per page load
  useEffect(() => {
    log('i18n init...', resources);
    i18n
    .init({
      resources,
      lng: 'en'
    });
    dispatch({
      type: 'ACTION_SET_I18N',
      i18n
    });

  }, []);
  
  
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path='/'
        ><PublicWelcome /></Route>
        {/* <Route component={() => { 
          window.location.href = 'https://dev--k8ajkf1.us.auth0.com/authorize'; 
          return null;
        }}
path='/authorize'
        /> */}
        <Route
          exact
          path='/authorize'
        ><AuthorizeHome /></Route>
        <Route
          exact
          path='/tos'
        ><TOS /></Route>
        <Route
          exact
          path='/docs/api'
        ><ApiDocs /></Route>
        <Route
          exact
          path='/privacy'
        ><PrivacyPolicy /></Route>
        <Route
          exact
          path='/login'
        ><Login /></Route>
        <Route
          exact
          path='/login/:reset'
        ><Login /></Route>
        <Route
          exact
          path='/register'
        ><Signup /></Route>
        <Route
          exact
          path='/plans'
        ><PlansHome /></Route>
        <Route
          exact
          path='/checkout'
        ><CheckoutHome /></Route>
        <PrivateRoute
          exact
          forceLogout={false}
          isLoggedIn={initialLoggedInState()}
          path='/verifyPhone'
        ><VerifyPhoneHome /></PrivateRoute>
        <PrivateRoute
          exact
          forceLogout={false}
          isLoggedIn={initialLoggedInState()}
          path='/pickYourPhoneNumber'
        ><TwilioNumberHome /></PrivateRoute>
        <Route
          exact
          path='/staff/invitations/:inviteToken'
        ><StaffInvitationHome /></Route>
        <PrivateRoute
          exact
          forceLogout={false}
          isLoggedIn={initialLoggedInState()}
          path='/member'
        ><MemberHome /></PrivateRoute>
        <PrivateRoute
          exact
          forceLogout={false}
          isLoggedIn={initialLoggedInState()}
          path='/member/:requestId'
        ><RequestHome /></PrivateRoute>
        <PrivateRoute
          exact
          forceLogout={false}
          isLoggedIn={initialLoggedInState()}
          path='/neo'
        ><BotSettingsHome /></PrivateRoute>
        <PrivateRoute
          exact
          forceLogout={false}
          isLoggedIn={initialLoggedInState()}
          path='/analytics'
        ><AnalyticsHome /></PrivateRoute>
        {/* <PrivateRoute
          component={PropertiesHome}
          exact
          isLoggedIn={initialLoggedInState()}
          path='/properties'
        /> */}
        {/* <PrivateRoute
          component={PropertyHome}
          exact
          isLoggedIn={initialLoggedInState()}
          path='/property/:propertyKey'
        /> */}
        <PrivateRoute
          exact
          forceLogout={false}
          isLoggedIn={initialLoggedInState()}
          path='/staff'
        ><StaffListHome /></PrivateRoute>
        <PrivateRoute
          exact
          forceLogout={false}
          isLoggedIn={initialLoggedInState()}
          path='/customers'
        ><TenantsHome /></PrivateRoute>
        <PrivateRoute
          exact
          forceLogout={false}
          isLoggedIn={initialLoggedInState()}
          path='/billing'
        ><BillingHome /></PrivateRoute>
        <PrivateRoute
          exact
          forceLogout={false}
          isLoggedIn={initialLoggedInState()}
          path='/tags'
        ><TagsHome /></PrivateRoute>
        <PrivateRoute
          exact
          forceLogout={false}
          isLoggedIn={initialLoggedInState()}
          path='/integrations'
        ><IntegrationsHome /></PrivateRoute>
        <PrivateRoute
          exact
          forceLogout={false}
          isLoggedIn={initialLoggedInState()}
          path='/share'
        ><ReferralsHome /></PrivateRoute>
        <PrivateRoute
          exact
          forceLogout
          isLoggedIn={initialLoggedInState()}
          path='/logout'
        ><PublicWelcome /></PrivateRoute>
        <Route><InvalidUrl /></Route>
      </Switch>
    </Router>
  );
};

export default SquarePlanApp;
