// @flow

import React, { useState, useEffect } from 'react';
import { useStore } from '../../store';
import { useParams } from 'react-router-dom';
import * as R from 'ramda';
import SPBackend from '../../services/SPBackend';
import { log } from '../../utils/jsUtils';
import MemberDrawer from '../MemberDrawer';
import PropertyHeader from './PropertyHeader';
import PropertyContent from './PropertyContent';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/PropertyHome.Style';

import type { UnitAndTenants } from '../../services/SPBackend';

type Props = {
  classes: Object
};

const PropertyHome = (props: Props) => {
  const { classes } = props;
  let { propertyKey } = useParams();

  // drawer state & controls
  const [mobileOpen, setMobileOpen] = useState(false);
  const [property, setProperty] = useState({_id: propertyKey});
  const [unitsAndTenants, setUnitsAndTenants] = useState([]);
  
  const usehandleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [store, dispatch] = useStore();
  const {
    user,
    accountSettings,
    i18n
  } = store;

  const refreshPropertyDetails = () => {
    if (user !== null) {
      SPBackend.getBuilding(propertyKey, user)
      .then(response => {
        const {
          building,
          unitsAndTenants: ut
        } = response.data;
        log('[refreshPropertyDetails] building: ', building);
        setProperty(building);
        setUnitsAndTenants(ut);
      })
      .catch(error => {
        dispatch({
          type: 'ACTION_SET_SNACK_MSG',
          snackMessage: {
            message: `We are currently having problems showing your ${i18n.t('units')}. Please try reloading the page.`,
            level: 'success',
            duration: 3000
          }
        }); 
        log('[refreshPropertyDetails] error', error);      
      });
    }
  };

  const removeUnit = (roomKey) => {
    if (user !== null) {
      SPBackend.removeUnit(roomKey, user)
      .then(response => {
        log('[removeUnit] response: ', response);
        const filterByRoomKey = R.pathEq(['unit', 'RoomKey'], roomKey);
        log('[removeUnit] roomKey: ', roomKey);
        setUnitsAndTenants(prevProps => {
          const newProps = R.reject(filterByRoomKey, prevProps);
          log('[removeUnit] roomKey: ', newProps);
          return newProps;
        });  
        // Show snack message
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: 'Unit removed.',
            level: 'success',
            duration: 3000
          }
        });
        // dispatch({
        //   type: 'ACTION_SET_ACCOUNT_SETTINGS',
        //   accountSettings: {
        //     ...accountSettings,
        //     SetupRequired: {
        //       ...accountSettings.SetupRequired,
        //       Units: true
        //     }
        //   }
        // }); 
      })
      .catch(error => {
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            // TODO: Change?
            message: error.response ? error.response.data : 'An error occured. Please try again',
            level: 'error',
            duration: 3000
          }
        });
        log('[MemberHome.fetchFeed.getFeed] error: ', error);
      });
    }
  };
  
  const findIndex = (prevState: Array<UnitAndTenants>, changedUnit) => {
    for (let i = 0; i < prevState.length; i++){
      if (prevState[i].unit.RoomKey === changedUnit.RoomKey) return i;
    }

    return -1;
  };

  const editUnit = (unit) => {
    if (user !== null) {
      // The body isn't accped into the backend without removing the previous created or updated datetime.
      const roomKey = unit.RoomKey;
      const removedDtUnit = R.omit(['CreatedDt', 'UpdatedDt', 'RoomKey', 'AccountId'], unit);
      SPBackend.editUnit(removedDtUnit, roomKey, user)
      .then(response => {
        // TODO: setUnitsAndTenants is firing unnecessarily multiple times.
        setUnitsAndTenants((prevProps) => {
          const valIndex = findIndex(prevProps, unit);
          log('[setUnitsAndTenants] index: ', valIndex);
          prevProps[valIndex].unit = unit;
          return prevProps;
        });
        // Show snack message
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: 'Unit Changed.',
            level: 'success',
            duration: 3000
          }
        }); 
      })
      .catch(error => {
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: error.response ? error.response.data : 'An error occured. Please try again',
            level: 'error',
            duration: 3000
          }
        });
        log('[MemberHome.fetchFeed.getFeed] error: ', error);
      });
    }
  };

  useEffect(() => {
    refreshPropertyDetails();
  }, [propertyKey, user]);

  return (
    <div className={classes.root}>
      <PropertyHeader
        mobileOpen={mobileOpen} 
        property={property}
        setUnitsAndTenants={setUnitsAndTenants}
        usehandleDrawerToggle={usehandleDrawerToggle}
      />
      <MemberDrawer 
        mobileOpen={mobileOpen} 
        usehandleDrawerToggle={usehandleDrawerToggle}
      />
      <PropertyContent
        editUnit={editUnit}
        refreshPropertyDetails={refreshPropertyDetails}
        removeUnit={removeUnit}
        unitsAndTenants={unitsAndTenants}
      /> 
    </div>
  );
};

export default withStyles(styles)(PropertyHome);