// @flow

import { phoneInputCss } from '../../../css/phoneInput';

const styles = (theme: Object) => ({
  dialogCtr: {
    maxWidth: '100vw',
    width: 'auto',
    textAlign: 'center'
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    margin: theme.spacing(1)
  },
  dialogHeader: {
    margin: theme.spacing(1)
  },
  grow: {
    flexGrow: 1
  },
  itemHeader: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontWeight: 600
  },
  itemList: {
    '& p': {
      fontSize: '1rem',
      color: 'hsl(0,0%,80%)'
    }
  },
  itemRow: {
    display: 'flex'
  },
  itemDesc: {
    fontSize: '1rem',
    flexGrow: 1
  },
  itemPrice: {
    fontSize: '1.2rem',
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  captionText: {
    fontSize: '1rem',
    color: 'hsl(0,0%,50%)'
  },
  ccCaptionText: {
    marginTop: theme.spacing(2),
    fontSize: '1rem',
    color: 'hsl(0,0%,50%)'
  },
  checkoutContentHeader: {
    marginBottom: theme.spacing(2)
  },
  checkoutColCtr: {
    display: 'flex',
    flexDirection: 'column'
  },
  checkoutLineCtr: {
    display: 'flex',
    flexDirection: 'row'
  },
  bottomText: {
    marginBottom: theme.spacing(2)
  },
  reviewSectionCtr: {
    minWidth: 300,
    maxWidth: 400,
    textAlign: 'left',
    marginLeft: theme.spacing(3.5),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0
    },
    '& p': {
      // marginBottom: theme.spacing(1),
      fontSize: '1rem',
      justifyContent: 'left',
      alignItems: 'center',
      display: 'flex'
    },
    '& > div > p.price': {
      fontWeight: 700,
      marginTop: theme.spacing(1.5)
    },
    '& b': {
      color: theme.palette.primary.main
    }
  },
  spaceTop: {
    marginTop: theme.spacing(2)
  },
  pmRadioGroup: {
  },
  pmListItem: {
    border: '1px solid #CCC',
    height: 65,
    cursor: 'pointer',
    // marginBottom: theme.spacing(1),
    '&:last-child': {
      borderTop: 0
    },
    '&:first-child': {
      border: '1px solid #CCC'
    }
  },
  pmFormCtr: {
    width: '100%'
  },
  reviewItemCtr: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  reviewItem: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    cursor: 'default'
  },
  ctaBtnCtr: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'end'
    }
  }
});

export default styles;
