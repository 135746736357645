// @flow

import React from 'react';
import classNames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useStore } from '../../store';
import { log } from '../../utils/jsUtils';

import { goToRequest } from './feedUtils';

import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import combineStyles from '../../utils/stylesUtils';
import styles from '../Analytics/Styles/FeedDialog.Style'; // Very similar to the MemberContent.Style
// TODO: Consider putting this file in master Styles folder.
import FeedTableStyles from '../Feed/Styles/CommonFeedTable.Style'; 
import FeedMobileStyles from '../Feed/Styles/CommonFeedMobile.Style'; 
import { withStyles } from '@material-ui/core/styles';

import FeedRow from '../Feed/FeedRow';
import type { Task } from '../../services/SPBackend';

type Props = {
  classes: Object,
  markInProgress: Function,
  triggerAssignDialog: Function,
};

const FeedTable = (props:Props) => {
  const {
    classes,
    markInProgress,
    triggerAssignDialog
  } = props;

  const [store] = useStore();

  const {
    units,
    requests: tasks,
    i18n
  } = store;

  // const goToRequest = (requestId: number) => () => {
  //   log('goToRequest');
    
  //   history.push({
  //     pathname: `/member/${requestId}`
  //   });
  // };

  // console.log('[FeedTable][render] props: ', props);

  return ([
    <Hidden
      key='tableMobile'
      mdUp
    >
      <Grid
        className={classes.mobileFeedCtr}
        container
        direction='column'
      >
      {
        tasks.map((task: Task, i) => (
          <FeedRow
            goToRequest={goToRequest}
            key={`task-${i}`}
            markInProgress={markInProgress}
            request={task}
            triggerOpenAssignTaskDialog={triggerAssignDialog}
            units={units}
          />
        ))
      }
      </Grid>
    </Hidden>,
    <Hidden
      key='tableDesktop'
      smDown
    >
      <Table className={classes.table}>
      <colgroup>
        <col className={classes.colRequest} />
        <col className={classes.colCustomerName} />
        <col className={classes.colType} />
        <col className={classes.colRequest} />
        {/* <col className={classes.colStatus} /> */}
        <col className={classes.colAssignee} />
        <col className={classes.colDate} />
        <col classes={classes.colAction} />
      </colgroup>
      
      <TableHead className={classes.feedTableBody}>
        <TableRow className={classes.feedTableHeadRow}>
          <TableCell className={classes.customerNameCell}>
            <span style={{display: 'block', width: 30}} />
            {i18n.t('Customer')}
          </TableCell>
          <TableCell className={classes.tagCell}>{i18n.t('Tag')}</TableCell>
          <TableCell className={classes.requestCell}>
            {i18n.t('Subject')}
          </TableCell>
          <TableCell 
          align= 'left'
            className={classes.assigneeCell}
          >{i18n.t('Staff')}</TableCell>
          {/* <TableCell
            align='left'
            className={classes.statusCell}
          >{'Progress'}
          </TableCell> */}
          <TableCell
            align='right'
            className={classes.dateCell}
          ><span style={{position: 'relative', top: -2}}><ArrowDropDown /></span>{'Last Updated'}</TableCell>
          
          </TableRow>
      </TableHead>
      <TableBody classes={{
          root: classNames(classes.feedTableBody, classes.animations)
        }} 
        id='divToPrint'
      >
        {
          tasks.map((task: Task, i) => {
            return (
              <CSSTransition
                classNames={{
                  enter: task.animateAdded ? classes.feedTableAddedEnter : task.animateUpdated ? classes.feedTableUpdatedEnter : classes.feedTablePushedinEnter,
                  enterActive: task.animateAdded ? classes.feedTableAddedEnterActive : task.animateUpdated ? classes.feedTableUpdatedEnterActive : classes.feedTablePushedinEnterActive,
                  // enterActive: classes.feedTableEnterActive,
                  // exit: classes.feedTableExit,
                  exitActive: classes.feedTableExitActive
                }}
                in={task.animateUpdated || task.animatePushedin || task.animateAdded}
                key={`request-${i}`}
                onEntered={() => { delete task.animateUpdated; delete task.animatePushedin; delete task.animateAdded;}}
                timeout={task.animateAdded ? 1000 : task.animateUpdated ? 1300 : 2500} // timeout = sum(animationDelay + animationDuration) in styles
              >
                <FeedRow
                  goToRequest={goToRequest}
                  markInProgress={markInProgress}
                  request={task}
                  triggerOpenAssignTaskDialog={triggerAssignDialog}
                  units={units}
                />
              </CSSTransition>
            );
          })
        }
      </TableBody>
    </Table>
    </Hidden>
  ]);
};

const combinedStyles = combineStyles(
  FeedTableStyles,
  FeedMobileStyles,
  styles
);

export default withStyles(combinedStyles)(FeedTable);