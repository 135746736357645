
const styles = (theme: Object) => ({
  badgeCtr: {
    display: 'table',
    height: 10,
    width: 10,
    borderRadius: 10,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      width: 10,
      height: 10,
      display: 'inline-block',
      borderRadius: 10,
      backgroundColor: '#2196f3',
      marginRight: 4,
      position: 'relative',
      top: 1
    }
  }
});

export default styles;