// @flow

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import * as R from 'ramda';
import debounce from 'lodash.debounce';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';
import SPBackend from '../../services/SPBackend';

import TenantsHeader from './TenantsHeader';
import TenantsContent from './TenantsContent';
import MemberDrawer from '../MemberDrawer';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/TenantsHome.Style';

type Props = {
  classes: Object
};

const TenantsHome = (props: Props) => {
  const { classes } = props;
  
  const [store, dispatch] = useStore();
  const { 
    user,
    i18n,
    tenantsPageInfo
  } = store;

  const [mobileOpen, setMobileOpen] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [customerIdsSelected, setCustomerIdsSelected] = useState([]);
  const [tenantsNextPage, setTenantsNextPage] = useState(null);
  const [ countOfTenants, setCountOfTenants] = useState(0);

  const [searchTenantValue, setSearchValue] = useState('');

  const removeTenant = (tenant) => {
    if (user !== null) {
      dispatch({
        type: 'ACTION_SET_IS_LOADING_MODULE',
        module: 'removeTenant',
        loading: true
      });
      SPBackend.removeTenant(tenant._id, user)
      .then(response => {
        const filterById = p => p._id !== tenant._id;
        setTenants(prevProps => {
          const newProps = R.filter(filterById, prevProps);
          return newProps;
        });  
        log('[TenantsHome] tenants, response: ', tenants, response);
        // Show snack message
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message:`${i18n.t('Tenant')} Removed`,
            level: 'success',
            duration: 3000
          }
        });
        dispatch({
          type: 'ACTION_SET_IS_LOADING_MODULE',
          module: 'removeTenant',
          loading: false
        });
      })
      .catch(error => {
        log('[TenantsHome.fetchFeed.getFeed] error: ', error);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: 'An error occured. Please try again.',
            level: 'error',
            duration: 3000
          }
        });
        dispatch({
          type: 'ACTION_SET_IS_LOADING_MODULE',
          module: 'removeTenant',
          loading: false
        });
      });
    }
  };

  const usehandleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (user !== null) {
      log('[TenantsHome] page info', tenantsPageInfo);
      refreshTenants(searchTenantValue);
    }
  },[tenantsPageInfo.pageNum, tenantsPageInfo.sortOrder, searchTenantValue]);

  // Fetch tenants
  useEffect(() => {
    if (user !== null) {
      log('useEffect, user changed?');
      refreshTenants('');
    }
  }, [user]);

  const handleSetSearchInputValue = debounce(value => {
    setSearchValue(value);
  });

  /**
   * 
   * @param {*} prop 
   * @param {*} direction 
   * @param {*} tenantsToSet overrides the state list of tenants
   */
  const orderTenants = (order: number, byProp: Array<string>) => {
    log('[TenantsHome] orderby', order, byProp);
    if (typeof order !== 'undefined' && order !== null && order !== ''){
      dispatch({
        type: 'ACTION_SET_SORT_ORDER',
        sortOrder: order
      });
      dispatch({
        type: 'ACTION_SET_SORT_BY',
        sortBy: byProp
      });
    }
  };

  const refreshTenants = (searchVal: string) => {
    log('[refreshTenants]...');
    dispatch({
      type: 'ACTION_SET_IS_APP_LOADING',
      isAppLoading: true
    });
    const findTenantsPayload = {
      search: searchVal,
      tenantsPageInfo
    };
    SPBackend.findTenants(findTenantsPayload, user)
    .then((response) => {
      log('[CUSTOMERS]', response);
      const { tenants, tenantsNextPage, tenantsCount } = response.data;
      setTenants(tenants);
      setTenantsNextPage(tenantsNextPage);
      setCountOfTenants(tenantsCount);
      dispatch({
        type: 'ACTION_SET_IS_APP_LOADING',
        isAppLoading: false
      });
    })
    .catch(error => {
      log('[TenantsHome.fetchFeed.getFeed] error: ', error);
      dispatch({
        type: 'ACTION_SET_IS_APP_LOADING',
        isAppLoading: false
      });
    });
  };

  const updateTenants = (tenantIds, tag) => {
    log('[UPDATING TENANTS]', tenantIds);
    setTenants(prevTenants => {
      log('[PREV TENANT]', prevTenants);
      prevTenants.map(tenant => {
        if (tenantIds.includes(tenant._id)) {
          log('[SELECTED TENANT]', tenant);
          // eslint-disable-next-line no-unused-expressions
          tag ? tenant['Tags'] = R.union(tenant.Tags, [tag._id]) : tenant['Tags'] = null;
        }
      });
      return [
        ...prevTenants
      ];
    });
  };

  const isCustomerSelected = (id) => customerIdsSelected.indexOf(id) !== -1;

  const handleSelectCustomer = (event, customerId) => {
    const selectedIndex = customerIdsSelected.indexOf(customerId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(customerIdsSelected, customerId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(customerIdsSelected.slice(1));
    } else if (selectedIndex === customerIdsSelected.length - 1) {
      newSelected = newSelected.concat(customerIdsSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        customerIdsSelected.slice(0, selectedIndex),
        customerIdsSelected.slice(selectedIndex + 1),
      );
    }
    setCustomerIdsSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tenants.map((t) => t._id);
      setCustomerIdsSelected(newSelecteds);
      return;
    }
    setCustomerIdsSelected([]);
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Neo | Customers</title>
      </Helmet>
      <TenantsHeader
        customerIdsSelected={customerIdsSelected}
        // searchInputValue={searchTenantValue}
        handleSetSearchInputValue={handleSetSearchInputValue}
        mobileOpen={mobileOpen}
        orderTenants={orderTenants}
        refreshTenants={refreshTenants}
        searchTenantValue={searchTenantValue}
        setCountOfTenants={setCountOfTenants} 
        setTenants={setTenants}
        tenants={tenants}
        totalTenantsCount={countOfTenants}
        updateTenants={updateTenants}
        usehandleDrawerToggle={usehandleDrawerToggle}
      />
      <MemberDrawer 
        mobileOpen={mobileOpen} 
        usehandleDrawerToggle={usehandleDrawerToggle}
      />
      <TenantsContent 
        handleSelectAllClick={handleSelectAllClick}
        handleSelectCustomer={handleSelectCustomer}
        isCustomerSelected={isCustomerSelected}
        orderTenants={orderTenants}
        removeTenant={removeTenant}
        selectedRowsCount={customerIdsSelected.length}
        setCountOfTenants={setCountOfTenants}
        setTenants={setTenants}
        // sort={currentSort}
        tenants={tenants}
        tenantsNextPage={tenantsNextPage}
        totalTenantsCount={countOfTenants}
      /> 
    </div>
  );
};

export default withStyles(styles)(TenantsHome);
