// @flow

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import {
  log,
  formatPhoneNumberForFrontend,
  formatPhoneNumberForBackend
} from '../../utils/jsUtils';
import { useStore } from '../../store';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import InputLabel from '@material-ui/core/InputLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { withStyles } from '@material-ui/core/styles';
import SPBackend from '../../services/SPBackend';
import {ROLE_NAMES, getUserRole, getRolePower} from '../../models/User';
import styles from './Styles/EditStaffDialog.Style';
import type { User } from '../../services/SPBackend';

type Props = {
  classes: Object,
  setOpen: Function,
  open: boolean,
  triggerAction: Function,
  staff: User
};

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    elevation={0}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: 'white'
      }
    }
  }
}))(MenuItem);

// eslint-disable-next-line react/no-multi-comp
const EditStaff = (props: Props) => {
  const { classes, setOpen, open, triggerAction, staff } = props;

  const [store] = useStore();
  const {
    email,
    user,
    userRole
  } = store;

  // const [loggedUser, setloggedUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const [changedStaff, setChangedStaff] = useState({
    FirstName: '',
    LastName: '',
    Email: '',
    Phone: '',
    IsAdmin: false,
    IsManager: false,
    IsOwner: false
  });

  const [openDrop, setOpenDrop] = useState(false);

  // const getUserInAccount = () => {
  //   if (user !== null) {
  //     SPBackend.getCurrentUser(user)
  //     .then((response) => {
  //       const { data } = response;
  //       setloggedUser(data);
  //     })
  //     .catch((error) => {
  //       log('[TenantsHome.fetchFeed.getFeed] error: ', error);
  //     });
  //   }
  // };

  useEffect(() => {
    if (user !== null && staff !== null) {
      setChangedStaff((prevStaff) => {
        return {
          ...prevStaff,
          FirstName: staff ? staff.FirstName : '',
          LastName: staff ? staff.LastName : '',
          Email: staff ? staff.Email : '',
          Phone: staff ? formatPhoneNumberForFrontend(staff.Phone) : '',
          IsAdmin: staff ? staff.IsAdmin : false,
          IsManager: staff ? staff.IsManager : false,
          IsOwner: staff ? staff.IsOwner : false
        };
      });
      // getUserInAccount();
    }
  }, [staff]);

  // log('userRole: ', userRole);
  // log('staff: ', staff);
  return (
    <Dialog
      aria-labelledby='form-edit-staff'
      onClose={() => setOpen(false)}
      open={open}
    >
      <form
        autoComplete='off'
        className={classes.dialogFormCtr}
        noValidate
        onSubmit={e => {
          e.preventDefault();

          setErrorMessage('');

          triggerAction({
            ...changedStaff,
            Phone: formatPhoneNumberForBackend(changedStaff.Phone) || ''
          });
          setOpen(false);
        }}
      >
        <DialogTitle id='form-dialog-title'>{'Edit Staff Profile'}</DialogTitle>
        <DialogContent className={classNames(classes.grow, classes.flexContainer)}>
          <DialogContentText className={classes.error}>{ errorMessage }</DialogContentText>
          <div id="editStaff"> 
            <TextField
                autoFocus
                classes={{
                  root: classes.secondInput
                }}
                defaultValue={staff ? staff.FirstName : ''}
                fullWidth
                id='firstName'
                label='First Name'
                margin='none'
                onChange={e => {
                e.persist();
                setChangedStaff((prevStaff) => {
                    return {
                      ...prevStaff,
                      FirstName: e.target.value
                    };
                  });
                }}
                required
                type='text'
            />
            <TextField
              autoFocus
              classes={{
                root: classes.secondInput
              }}
              defaultValue={staff ? staff.LastName : ''}
              fullWidth
              id='lastName'
              label='Last Name'
              margin='none'
              onChange={e => {
                e.persist();
                setChangedStaff((prevStaff) => {
                  return {
                    ...prevStaff,
                    LastName: e.target.value
                  };
                });
              }}
              required
              type='text'
            />
            <TextField
              autoFocus
              classes={{
                root: classes.secondInput
              }}
              defaultValue={staff ? staff.Email : ''}
              disabled
              fullWidth
              id='email'
              label='Email'
              margin='none'
              onChange={e => {
                e.persist();
                setChangedStaff((prevStaff) => {
                  return {
                    ...prevStaff,
                    Email: e.target.value
                  };
                });
              }}
              required
              type='text'
            />
            <PhoneInput
              className={classNames(classes.phoneInputCtr, 'defaultInput')}
              defaultCountry='US'
              onChange={e =>
                setChangedStaff({
                  ...changedStaff,
                  Phone: e
                })
              }
              placeholder="Enter phone number"
              value={changedStaff ? formatPhoneNumberIntl(changedStaff.Phone) : ''}
            />
            <br/>  
            {
              staff
              && ((userRole === ROLE_NAMES.OWNER)
              || (userRole === ROLE_NAMES.ADMIN && !staff.IsOwner))
              ? <List disablePadding>
                <InputLabel htmlFor="demo-controlled-open-select" >
                    {'Role'}
                </InputLabel>
                <Card className={classes.card}>
                  <ListItem 
                    button
                    key={'Role'}
                    onClick={() => setOpenDrop(!openDrop)}
                  >
                    <ListItemText
                      primary={getUserRole(changedStaff)}
                    />
                    {openDrop ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                </Card>
                <Collapse
                  className={classes.userProfileSubMenuCollapse}
                  in={openDrop}
                  timeout="auto"
                  unmountOnExit
                >
                <Card className={classes.card}>
                {
                  staff
                  && !staff.IsOwner
                  && userRole === ROLE_NAMES.OWNER
                  ? <ListItem
                      button
                      key={'Owner'}
                    >
                      <ListItemText
                        classes={{
                          root: classes.itemTextCtr
                        }}
                        onClick={() => {
                          setChangedStaff((prev) => {
                            return {
                              ...prev,
                              IsOwner: true,
                              IsAdmin: false,
                              IsManager: false
                            };
                          });
                          setOpenDrop(false);
                        }}
                        primary={'Owner'}
                      />
                    </ListItem>
                  : null
                }
                {
                  staff
                  && !staff.IsAdmin
                  && (userRole === ROLE_NAMES.ADMIN
                  || userRole === ROLE_NAMES.OWNER)
                  ? <ListItem
                      button
                      key={'Admin'}
                    >
                      <ListItemText
                        classes={{
                          root: classes.itemTextCtr
                        }}
                        onClick={() => {
                          setChangedStaff((prev) => {
                            return {
                              ...prev,
                              IsOwner: false,
                              IsAdmin: true,
                              IsManager: false
                            };
                          });
                          setOpenDrop(false);
                        }}
                        primary={'Admin'}
                      />
                    </ListItem>
                    : null
                }
                  <ListItem
                    button
                    key={'Manager'}
                    onClick={() => {
                        setChangedStaff((prev) => {
                          return {
                            ...prev,
                            IsOwner: false,
                            IsAdmin: false,
                            IsManager: true
                          };
                        });
                      setOpenDrop(false);
                    }}
                  >
                    <ListItemText
                      classes={{
                        root: classes.itemTextCtr
                      }}
                      primary={'Manager'}
                    />
                  </ListItem>
                  <ListItem
                    button
                    key={'Staff'}
                    onClick={() => {
                      setChangedStaff((prev) => {
                        return {
                          ...prev,
                          IsOwner: false,
                          IsAdmin: false,
                          IsManager: false
                        };
                      });
                      setOpenDrop(false);
                    }}
                  >
                    <ListItemText
                      classes={{
                        root: classes.itemTextCtr
                      }}
                      primary={'Staff'}
                    />
                  </ListItem>
                </Card>
                </Collapse>
              </List>
              :null
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            onClick={() => setOpen(false)}
          >
            {'Cancel'}
          </Button>
          {
            staff && staff.Invite && staff.Invite.Status !== 'accepted' && moment().diff(staff.Invite.CreatedDt, 'days', true) > 1
            ? <Button
                color='primary'
                type='submit'
                variant='contained'
              >
                {'Save & Resend Invite'}
              </Button>
            : <Button
              color='primary'
              type='submit'
              variant='contained'
              >
              {'Save'}
            </Button>
          }
        </DialogActions>
       </form>
    </Dialog>
  );
};

export default withStyles(styles)(EditStaff);
