// @flow

import React, { useState } from 'react';
import classNames from 'classnames';
import { useStore } from '../../store';

import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';


// import AddProperty from './AddPropertyDialog';
import HeaderTopBar from '../HeaderTopBar';
import AppLoadingSpinner from '../SharedComponents/AppLoadingSpinner';

import { withStyles } from '@material-ui/core/styles';
import styles from '../Feed/Styles/MemberHeader.Style';

type Props = {
  classes: Object,
  mobileOpen: bool,
  usehandleDrawerToggle: Function
};


const ReferralsHeader = (props: Props) => {
  const {
    classes,
    usehandleDrawerToggle
  } = props;

  const [store, dispatch] = useStore();
  const {
    user,
    i18n
  } = store;

  return (
    <AppBar 
      className={classes.appBar}
      position="fixed"
    >
      <Toolbar
        className={classes.toolbar}
        disableGutters
      >
        <Grid 
          container
          direction='column'
        >
          <HeaderTopBar
            usehandleDrawerToggle={usehandleDrawerToggle}
          >
            <Typography 
              className={classes.pageTitle}
              variant='h3'
            >{i18n.t('Share Neo')}</Typography>
          </HeaderTopBar>
          <Grid
            className={classNames(
              classes.rowSub,
              classes.bottomBar
            )}
            item
          />
          <AppLoadingSpinner />
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(ReferralsHeader);
