import SPBackend from '../../services/SPBackend';
import { log } from '../../utils/jsUtils';
import type { Task, User } from '../../services/SPBackend';
/**
 * The funtions below apply to mostly to feed displays and are used repeatedly.
 */

export const handleAssignTasks = (currentTasks: Array<Task>, 
                                  assignedStaff: User, 
                                  user: User, 
                                  dispatch: Function) => {
  SPBackend.assignStaffToTasks(
    currentTasks.map(task => task._id),
    assignedStaff._id,
    user
  ).then(response => {
    // update assignee for each task in the feed
    log('[assignStaffToTasks] response: ', response);
    dispatch({
      type: 'ACTION_UPDATE_REQUESTS', 
      requests: response.data.requests
    });
    dispatch({
      type: 'ACTION_SET_SNACK_MSG', 
      snackMessage: {
        message: response.data.message,
        level: response.data.success ? 'success' : 'error',
        duration: 5000
      }
    });
  })
  .catch(err => {
    log('err: ', err);
  });
};

export const goToRequest = (requestId: number, history: Object) => () => {
  history.push({
    pathname: `/member/${requestId}`
  });
};
