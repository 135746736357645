// @flow

import { phoneInputCss } from '../../../css/phoneInput';

const styles = (theme:Object) => ({
  flexContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  card: {
    borderRadius: 0,
    width: 200
  },
  dialogFormCtr: {
    height: 600,
    maxHeight: '80vh',
    display: 'flex',
    flexDirection: 'column'
  },
  grow: {
    flexGrow: 1
  },
  secondInput: {
    marginTop: theme.spacing(1)
  },
  error: {
    color: theme.palette.error.main
  },
  userProfileSubMenuCollapse: {
    width: '20%'
  },
  phoneInputCtr: phoneInputCss
});

export default styles;