// @flow

const styles = (theme: Object) => ({
  flexContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  dialogFormCtr: {
    height: '80vh',
    maxHeight: 300,
    display: 'flex',
    flexDirection: 'column'
  },
  grow: {
    flexGrow: 1
  }

});

export default styles;
