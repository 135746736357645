// @flow

import { FOOTER_HEIGHT } from '../../PublicCpts/Styles/PublicFooter.Style';
const IMG_WIDTH = 350;
export const CHECKOUT_PAPER = {
  borderRadius: 0,
  margin: '0 auto',
  marginBottom: 50,
  minWidth: 400,
  maxWidth: 800,
  minHeight: 200,
  paddingBottom: 50
}

const styles = (theme: Object) => ({
  root: {
    minHeight: '100vh',
    display: 'flex'
  },
  contentCtr: {
    flexGrow: 1,
    marginTop: 70,
    textAlign: 'center'
  },
  yearlyToggle: {
    '& label': {
      flexWrap: 'nowrap'
    },
    margin: '0 auto',
    width: 'auto',
    display: 'inline-block'
  },
  paymentFormPaper: {
    ...CHECKOUT_PAPER,
    '& h2': {
      color: theme.palette.primary.main,
      fontSize: '2rem',
      paddingBottom: 0
    },
    '& h3': {
      fontSize: '1.8rem',
      fontWeight: 400
    },
    '& .discountedPrice h3': {
      fontSize: '1.8rem',
      fontWeight: 400,
      marginBottom: 0
    },
    '& .discountedPrice h4': {
      fontSize: '1rem',
      fontWeight: 400,
      marginTop: 0,
      color: 'hsl(0,0%,40%)'
    }
  },
  striked: {
    textDecoration: 'line-through'
  },
  featuresList: {
    textAlign: 'center'
  },
  featureItem: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.border.color}`,
    '&.last': {
      borderBottom: 0
    }
  },
  buy: {
    marginTop: theme.spacing(2)
  },
  topBuy: {
    marginTop: -theme.spacing(1),
    marginBottom: theme.spacing(3)
  }
});

export default styles;