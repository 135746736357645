// @flow

import React, { useState, useEffect } from 'react';
import { useStore } from '../../store';
import classNames from 'classnames';
import { log } from '../../utils/jsUtils';
import Downshift from 'downshift';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/AssignTasksDialog.Style';
import SPBackend from '../../services/SPBackend';
import type { Task } from '../../services/SPBackend';

type Props = {
  handleEditTenant: Function, // if prop missing, component switches in create tenant only mode.
  classes: Object,
  setOpen: Function,
  open: boolean,
  triggerAction: Function,
  tasks: Array<Task>, // the tasks to which the assignee will be assigned
};

const renderInput = (_inputProps) => {
  const {
    InputProps,
    classes,
    setSelectedAutocompleteVal,
    openMenu,
    ...other
  } = _inputProps;
  setSelectedAutocompleteVal(InputProps.value);
  return (
    <TextField
      InputProps={{
        // inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput
        },
        ...InputProps
      }}
      onFocus={openMenu}
      {...other}
    />
  );
};

// eslint-disable-next-line react/no-multi-comp
const SuggestedList = (props) => {
  const {
    inputValue,
    user,
    getItemProps
  } = props;
  const value = inputValue !== null ? inputValue.trim().toLowerCase() : '';

  const [users, setUsers ] = useState([]);
  const [isStaffLoading, setIsStaffLoading] = useState(true);

  useEffect(() => {
    if (user !== null) {
      setIsStaffLoading(true);
      SPBackend.findStaffMembers(value, user)
        .then(response => {
          const { data } = response;
          setUsers(data);
          setIsStaffLoading(false);
      })
      .catch(err => {
        log('findStaffMembers error: ', err);
        setIsStaffLoading(false);
      });
    } else {
      setUsers([]);
      setIsStaffLoading(false);
    }
    // return function cleanup() {

    // }
  }, [inputValue]);


  return (
    !users || users.length === 0
    ? <MenuItem
      component='div'
      key={'no-staff'}
      >
      {
        isStaffLoading
        ? 'Loading...'
        : 'No staff found.'
      }
    </MenuItem>
    : users.map((user) => {
        return (
          <MenuItem
            {...getItemProps({item: user})}
            component='div'
            key={user._id}
            // selected={isHighlighted}
          >
            {`${user.FirstName} ${user.LastName}`}
          </MenuItem>
        );
      })
  );
};

// eslint-disable-next-line react/no-multi-comp
const AssignTasks = (props: Props) => {
  const {
    classes,
    setOpen,
    open,
    triggerAction,
    tasks
  } = props;

  const [assignedStaff, setAssignedStaff] = useState(null);
  const [initialAssignedStaff, setInitialAssignedStaff] = useState(null);

  const [selectedAutocompleteVal, setSelectedAutocompleteVal] = useState(null);

  const [store, dispatch] = useStore();
  const {
    user,
    i18n
  } = store;

  useEffect(() => {
    if (tasks && tasks.length > 0) {
      let assignedUser = null;
      tasks.forEach((task) => {
        if (task
          && typeof task.Assignee !== 'undefined'
          && task.Assignee !== null
          && (
            assignedUser === null
            || assignedUser._id === task.Assignee._id
          )) {
            assignedUser = task.Assignee;
          } else {
            assignedUser = null;
          }
      });
      setInitialAssignedStaff(assignedUser);
    }
  });

  return (
    tasks && tasks.length > 0
    ? <Dialog aria-labelledby='form-assign-task'
      onClose={() => setOpen(false)}
      open={open}
      >
      <form
        autoComplete='off'
        className={classNames(
          classes.dialogFormCtr,
          'normal'
        )}
        noValidate
        onSubmit={e => {
          e.preventDefault();
          setOpen(false);
        }}
      >
        <DialogTitle id='form-dialog-title'>
          {
            tasks.length > 1
            ? `Assign these ${tasks.length} ${i18n.t('tasks')} to...`
            : `Assign this ${i18n.t('task')} to...`
          }
        </DialogTitle>
        <DialogContent className={classNames(classes.grow, classes.flexContainer)}>
          <div id='searchTenant'>
            {
              initialAssignedStaff
              ? <p>
                <b>{`${initialAssignedStaff.FirstName} ${initialAssignedStaff.LastName}`}</b> {'is assigned to '}
                {
                  tasks.length > 1
                  ? `these ${tasks.length} ${i18n.t('tasks')}.`
                  : `this ${i18n.t('task')}.`
                }
                <br /><br />{'Reassign to:'}
              </p>
              : null
            }
            <Downshift 
              id='downshift-simple'
              itemToString={item => item !== null ? `${item.FirstName} ${item.LastName}` : ''}
              onSelect={(selectedStaff) => setAssignedStaff(selectedStaff)}
            >
              {({
                getInputProps,
                getItemProps,
                getMenuProps,
                highlightedIndex,
                inputValue,
                isOpen,
                openMenu
              }) => (
                <div className={classes.container}>
                  {renderInput({
                    ref: 'test',
                    fullWidth: true,
                    classes,
                    InputProps: getInputProps({
                      placeholder: 'Search a staff member'
                    }),
                    openMenu,
                    setSelectedAutocompleteVal
                  })}
                  <div {...getMenuProps()}>
                    {isOpen ? (
                      <Paper
                        className={classes.paper}
                        square
                      >
                        <SuggestedList
                          getItemProps={getItemProps}
                          inputValue={inputValue}
                          user={user}
                        />

                        {/* {getSuggestions(inputValue, user).map((suggestion, index) =>
                          renderSuggestion({
                            suggestion,
                            index,
                            itemProps: getItemProps({ item: suggestion.label }),
                            highlightedIndex,
                            selectedItem
                          })
                        )} */}
                      </Paper>
                    ) : null}
                  </div>
                </div>
              )}
            </Downshift>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            onClick={() => setOpen(false)}
          >
            {'Cancel'}
          </Button>          
          <Button
            color='primary'
            onClick={() => {setOpen(false); triggerAction(tasks, assignedStaff, user, dispatch);}}
            variant='contained'
          >
            {'Assign'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
    : null
  );
};

export default withStyles(styles)(AssignTasks);
