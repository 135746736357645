import React, { Component} from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { log } from '../utils/jsUtils';
import { useStore } from '../store';

type Props = {
  forceLogout: boolean,
  exact: boolean,
  isLoggedIn: boolean,
  path: string
};

const PrivateRoute = (props: Props) => {
  const {
    forceLogout,
    exact,
    isLoggedIn,
    path,
    children
  } = props;
  const location = useLocation();
  const [store, dispatch] = useStore();
  if (forceLogout) {
    log('[PrivateRoute] forcelogout...');
    dispatch({ type: 'ACTION_LOGOUT' });
    window.localStorage.removeItem('loggedIn');
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('email');
    window.localStorage.removeItem('userRole');
    window.localStorage.removeItem('userFullName');
  }
  if (isLoggedIn) {
    return (
      <Route
        exact={exact}
        path={path} 
      >
        {children}
      </Route>
    );
  }
  log('[PrivateRoute] redirect to /login...');
  return (
    <Route 
      path={location} 
      render={() => <Redirect to={{
                        pathname: '/login',
                        state: { from: location }
                      }}
                    />}
    />
  );
};

export default PrivateRoute;
