// @flow

import { phoneInputCss } from '../../../css/phoneInput';

const styles = (theme: Object) => ({
  flexContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  dialogFormCtr: {
    height: '80vh',
    maxHeight: 450,
    display: 'flex',
    flexDirection: 'column'
  },
  grow: {
    flexGrow: 1
  },
  secondInput: {
    marginTop: theme.spacing(1)
  },
  error: {
    color: theme.palette.status.error.main
  },
  phoneInputCtr: {
    ...phoneInputCss,
    marginTop: 27
  },
  formControl: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    borderRadius: 0,
    marginTop: theme.spacing(1),
    '& ul': {
      borderRadius: 0
    }
  },
  channelFormControlCtr: {
    marginTop: theme.spacing(3)
  }
});

export default styles;
