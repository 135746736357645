import ReactGA from 'react-ga';

const monitorEnv = 'production';
const gaAppId = 'UA-137583012-1';

const initialize = () => {
  if (process.env.REACT_APP_ENV === monitorEnv) {
    ReactGA.initialize(gaAppId);
  }
};

const set = (options: Object) => {
  if (process.env.REACT_APP_ENV === monitorEnv) {
    ReactGA.set(options);
  }
};

const pageview = (args: any) => {
  if (process.env.REACT_APP_ENV === monitorEnv) {
    ReactGA.pageview(args);
  }
};

export default {
  initialize,
  set,
  pageview
};
