// @flow

import { FOOTER_HEIGHT } from './PublicFooter.Style';
const IMG_WIDTH = 350;
const TITLE_HEIGHT = 125;

const styles = (theme: Object) => ({
  root: {
    height: theme.layout.responsiveScreenHeight,
    width: '100vw',
    ...theme.layout.content
  },
  topLogo: {
    marginTop: theme.spacing(4),
    textAlign: 'center'
  },
  carouselContainer: {
    width: '100vw',
    margin: '0 auto',
    height: `calc(${theme.layout.responsiveScreenHeight} - ${FOOTER_HEIGHT}px)`,
    overflow: 'scroll',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2)
    }
  },
  stepContainer: {
    overflow: 'hidden'
  },
  titlesCtr: {
  },
  pushFooter: {
    height: `calc(${theme.layout.responsiveScreenHeight} - ${TITLE_HEIGHT}px - ${FOOTER_HEIGHT}px)`
  },
  img: {
    minHeight: '50vh',
    height: 600,
    margin: '0 auto',
    width: 'auto',
    display: 'block',
    // maxWidth: '80vw',
    overflow: 'hidden',
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
      maxWidth: `calc(100vw - ${theme.spacing() * 4}px)`,
      height: 'auto',
      minHeight: 100
    }
  },
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3)
  },
  subtitle: {
    margin: theme.spacing(1)
  }
});

export default styles;