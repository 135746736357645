// @flow

const styles = (theme: Object) => ({
  messageThread: {
    // position: 'relative',
    // top: 50,
    // height: '65vh',
    transformOrigin: '50% 50%',
    transform: 'rotate(180deg)',
    backgroundColor: 'white',
    maxWidth: '100vw'
  },
  messageLineCtr: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1)
    },
    backgroundColor: 'white'
  },
  messageLineCtrCustomer: {
    alignItems: 'flex-end'
  },
  messageLineCtrSupport: {
    alignItems: 'flex-start'
  },
  messageCtr: {
    boxShadow: 'none',
    width: 'fit-content',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transform: 'rotate(180deg)',
    [theme.breakpoints.down('xs')]: {
      maxWidth: `calc(100vw - ${theme.spacing(1)})`
    }
  },
  messageContent: {
    fontSize: '1rem',
    whiteSpace: 'break-spaces'
  },
  customerMsg: {
    backgroundColor: '#e0e0e0'
  },
  supportMsg: {
    backgroundColor: theme.palette.primary.lighter
  },
  msgCaption: {
    fontSize: '0.8rem',
    marginBottom: theme.spacing(2)
  },
  logCtr: {
    boxShadow: 'none',
    transform: 'rotate(180deg)',
    backgroundColor: 'transparent',
    textAlign: 'center',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '& p': {
      fontSize: '0.95rem'
    }
  },
  attachmentRowCtr: {
    // transform: 'rotate(180deg)',
    display: 'flex',
    // position: 'relative',
    flexWrap: 'nowrap',
    overflow: 'visible',
    // overflowX: 'scroll',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    '& > div > img': {
      display: 'block',
      width: 150,
      minWidth: 150,
      height: 150
    },
    '& .__react_modal_image__modal_container': {
      transform: 'rotate(180deg)',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '75vw',
      height: 50,
      [theme.breakpoints.down('sm')]: {
        transform: 'rotate(180deg)',
        position: '-webkit-sticky',
        position: 'sticky',
        zIndex: '9999',
        top: theme.layout.customProps.header.height,
        left: '50%',
        width: '75vw',
        height: 300
      }
    }
  },
  attachmentImgZoomPaper: {
    backgroundColor: 'transparent',
    filter: 'drop-shadow( 2px 1px 2px rgba(0, 0, 0, .1))',
    '&.MuiPaper-elevation24': {
      boxShadow: 'none'
    }
  },
  closeIcon: {
    color: 'white'
  },
  zoomCloseBtn: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  attachmentImgZoom: {
    width: '100%',
    height: 'auto'
  },
  attachment: {

    transform: 'rotate(180deg)',
    border: `2px solid ${theme.palette.primary.lighter}`,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    height: 150,
    width: 'auto',
    cursor: 'pointer',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      borderRadius: theme.shape.borderRadius / 2
    }
  },
  newMsgIndicatorCtr: {
    marginTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.error.main}`,
    width: '100%',
    transform: 'rotate(180deg)',
    '&:focus': {
      outline: 'none'
    }
  },
  newMsgIndicator: {
    margin: '0 auto',
    width: 'auto',
    padding: theme.spacing(1),
    textAlign: 'center',
    transform: `translate(0, -${theme.spacing(2.2)}px) scale(1)`,
    backgroundColor: theme.palette.background.default,
    display: 'table',
    color: theme.palette.error.main
  },
  messageCaptionCtr: {
    display: 'flex',
    // position: 'relative',
    transform: 'rotate(180deg)'
    // marginBottom: theme.spacing(1)
  },
  deliveryIconCtr: {
    marginLeft: theme.spacing(0.5),
    position: 'relative',
    top: -3
  },
  deliveryIconSending: {
    color: theme.palette.messageDeliveryStatuses.sending
  },
  deliveryIconDelivered: {
    color: theme.palette.messageDeliveryStatuses.delivered
  },
  deliveryIconFailed: {
    color: theme.palette.messageDeliveryStatuses.failed
  }
});

export default styles;
