// @flow

import { phoneInputCss } from '../../../css/phoneInput';

const styles = (theme: Object) => ({
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    margin: theme.spacing(1)
  },
  dialogHeader: {
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0px ${theme.spacing(1)}px`,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px 0px ${theme.spacing(3)}px`
  },
  grow: {
    flexGrow: 1
  },
  itemHeader: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontWeight: 600
  },
  itemList: {
    '& p': {
      fontSize: '1rem',
      color: 'hsl(0,0%,80%)'
    }
  },
  itemRow: {
    display: 'flex'
  },
  itemDesc: {
    fontSize: '1rem',
    flexGrow: 1
  },
  itemPrice: {
    fontSize: '1.2rem',
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  captionText: {
    fontSize: '1rem',
    color: 'hsl(0,0%,50%)'
  },
  ccCaptionText: {
    marginTop: theme.spacing(2),
    fontSize: '1rem',
    color: 'hsl(0,0%,50%)'
  }
});

export default styles;
