// @flow
/**
 * Contains Request-specific util methods
 */

// TODO: put the flow type of Request here.
import { log } from '../utils/jsUtils';
import type { Task, Unit } from '../services/SPBackend';
import * as R from 'ramda';

const REF_STATUSES = {
  PENDING: 'pending',
  CURRENT: 'current',
  FINISHED: 'finished',
  INFO: 'info',
  NOTE: 'note',
  TAG: 'tag',
  RENAME: 'rename'
};

const REF_CHANNELS = {
  SMS: 'sms',
  CALL: 'call',
  SQUAREP: 'web',
  CONSOLE: 'console'
};

const REF_ACTIONS = {
  SEND_NOTE: 'send_note',
  MARK_AS_PROGRESS: 'mark_as_progress',
  MARK_AS_DONE: 'mark_as_done',
  SHARE: 'share',
  SPLIT: 'split',
  MERGE: 'merge'
};

const translateStatusToHuman = {
  pending: 'not started',
  current: 'in progress',
  finished: 'done',
  info: 'sent'
};

const tagDefs = [{
  label: 'Rent & Lease',
  class: 'rent',
  regex: /(rent|sublet|lease)/ig
}, {
  label: 'Complaint',
  class: 'complaint',
  regex: /(squat|noise)/ig
}, {
  label: 'Moving',
  class: 'moving',
  regex: /(moving)/ig
}, {
  label: 'Other',
  class: 'other',
  regex: /(happy|holiday|customized)/ig
}, {
  label: 'Maintenance',
  class: 'maintenance',
  regex: null
}];

const getTag = (task: Task) => {
  var tag = null;
  try {
    for (let i = 0; i < tagDefs.length; i++) {  // get tag
      if (task.Issue.match(tagDefs[i].regex) !== null) {
        tag = tagDefs[i];
        throw 'labelMatch';
      }
    }
    if (task.Status === 'INFO') {
      tag = R.find(R.propEq('class', 'other'))(tagDefs);
    } else {
      // fallback to maintenance
      tag = R.find(R.propEq('class', 'maintenance'))(tagDefs);
    }  
    return (tag);
  } catch (e) {
    if (e !== 'labelMatch') throw e;
  }
  return (tag);
};

/**
 * 
 * @param {*} request 
 * @param {*} userFullName 
 * @param {*} requestLocation 
 */
const shareRequestEmailHeaders = (request, userFullName, requestLocation) => {
  return {
    subject: request !== null ? `${userFullName} shared a task with you` : null,
    body: request !== null 
      ? `Hi,\n\n\
We need your help taking care of this request:\n\n\
Request information:${request.Issue}\n\
Location: ${requestLocation}\n\n\
Please ​let us know when you're available.\n\n\
Best regards,\n${userFullName}`
      : null
  };
};

// TODO: move this to Unit model
const renderAddress = (unit: Unit) => {
  if (unit === null) return '';
  let res = '';
  res = unit.Property;
  if (unit.Floor != 'COMMON') {
    res += `, Floor ${unit.Floor}`;
  }
  if (unit.Apt !== 'COMMON') {
    res += `, Apt ${unit.Apt}`;
  }
  return res;
};

const renderRequestSummary = (request: Request) => {
  if (request === null) return '';
  let res = '';
  let infoSummary = request.Summary.split(';');
  for (let i = 0; i < infoSummary.length; i += 1) {
    res += `\n${infoSummary[i]}`;
  }
  return res;
};



const allowActionPerStatus = (action: string, status: string) => {
  log('[allowActionPerStatus] action, status: ', action, status);
  if (action === REF_ACTIONS.SEND_NOTE) {
    return [
      REF_STATUSES.PENDING,
      REF_STATUSES.CURRENT,
      REF_STATUSES.FINISHED
    ].includes(status.toLowerCase());
  } else if (action === REF_ACTIONS.MARK_AS_PROGRESS) {
    return [
      REF_STATUSES.PENDING,
      REF_STATUSES.CURRENT,
      REF_STATUSES.FINISHED
    ].includes(status.toLowerCase());
  } else if (action === REF_ACTIONS.MARK_AS_DONE) {
    return [
      REF_STATUSES.PENDING,
      REF_STATUSES.CURRENT,
      REF_STATUSES.FINISHED
    ].includes(status.toLowerCase());
  } else if (action === REF_ACTIONS.SHARE) {
    return [
      REF_STATUSES.PENDING,
      REF_STATUSES.CURRENT,
      REF_STATUSES.FINISHED
    ].includes(status.toLowerCase());
  } else if (action === REF_ACTIONS.SPLIT) {
    return [
      REF_STATUSES.PENDING,
      REF_STATUSES.CURRENT,
      REF_STATUSES.FINISHED
    ].includes(status.toLowerCase());
  } else if (action === REF_ACTIONS.MERGE) {
    return [
      REF_STATUSES.PENDING,
      REF_STATUSES.CURRENT,
      REF_STATUSES.FINISHED
    ].includes(status.toLowerCase());
  }
  return false;
};

export {
  REF_STATUSES,
  REF_ACTIONS,
  REF_CHANNELS,
  translateStatusToHuman,
  shareRequestEmailHeaders,
  renderAddress,
  renderRequestSummary,
  allowActionPerStatus,
  getTag
};
