// @flow

import React, { useState, useEffect } from 'react';
import { useStore } from '../../store';
import * as R from 'ramda';
import moment from 'moment';
import SPBackend from '../../services/SPBackend';
import { log, numberWithCommas } from '../../utils/jsUtils';

import CreditCardForm from '../SharedComponents/CreditCardForm';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Chip from '@material-ui/core/Chip';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import MemberDrawer from '../MemberDrawer';
import BillingHeader from './BillingHeader';
import StripeElementWrapper from './StripeElementWrapper';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import commonListTableStyles from '../Styles/CommonListTable.Style';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/BillingContent.Style';
import checkoutHomeStyles from './Styles/CheckoutHome.Style';
import signupStyles from '../PublicCpts/Styles/Signup.Style';
import MemberStructuralStyles from '../Styles/CommonMemberStructuralStyles.Style';
import ConfirmTopupPurchaseDialog from './ConfirmTopupPurchaseDialog';
import UpdatePaymentMethodDialog from './UpdatePaymentMethodDialog';
import combineStyles from '../../utils/stylesUtils';

import type { Plan } from '../../services/SPBackend';

type Props = {
  classes: Object
};

// eslint-disable-next-line react/no-multi-comp
const BillingContent = (props: Props) => {
  const { classes } = props;

  // drawer state & controls
  const [loading, setLoading] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [topupPlans, setTopupPlans] = useState([]);
  const [openConfirmTopupDialog, setOpenConfirmTopupDialog] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [planInfo, setPlanInfo] = useState({
    nickname: '',
    active: true,
    amount: 0,
    currency: 'usd',
    interval: 'month',
    interval_count: 0,
    product: ''
  });
  const [invoices, setInvoices] = useState([]);
  const [isConfirmTopupPurchaseLoading, setIsConfirmTopupPurchaseLoading] = useState(false);
  const [openUpdatePaymentMethodDialog, setOpenUpdatePaymentMethodDialog] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
  const [productInfo, setProductInfo] = useState({
    name: ''
  });
  const [subscription, setSubscription] = useState(null);
  const [discount, setDiscount] = useState(null);

  const handleConfirmTopupPurchase = () => {
    setIsConfirmTopupPurchaseLoading(true);
    log('[handleConfirmTopupPurchase]....');
    if (typeof selectedPlan !== 'undefined' && selectedPlan !== null) {
      SPBackend.purchaseTopupPlan(user, selectedPlan._id)
      .then(response => {
        log('[purchaseTopupPlan] response: ', response);
        const { data } = response;
        setOpenConfirmTopupDialog(false);
        setIsConfirmTopupPurchaseLoading(false);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: data.message,
            level: data.success,
            duration: 5000
          }
        });
      })
      .catch(err => {
        setLoading(false);
        setIsConfirmTopupPurchaseLoading(false);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: err.message,
            level: 'error',
            duration: 5000
          }
        });
      });
    } else {
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: 'Select a recharge to buy',
          level: 'error',
          duration: 5000
        }
      });
    }
  };

  const handleSelectPlanToPurchase = (plan) => {
    setSelectedPlan(plan);
    setOpenConfirmTopupDialog(true);
  };

  const [store, dispatch] = useStore();
  const {
    user,
    billing,
    accountUsage
  } = store;
  log('[render] accountUsage: ', accountUsage);

  const getPaymentMethods = () => {
    SPBackend.getPaymentMethods(user)
      .then(response => {
        log('[getPaymentMethods] response: ', response);
        const {
          paymentMethods,
          defaultPaymentMethod
        } = response.data;
        setPaymentMethods(paymentMethods);
        setDefaultPaymentMethod(defaultPaymentMethod);
      })
      .catch(e => {
        log('[getPaymentMethods] error: ', e);
      });
  };

  useEffect(() => {
    setLoading(true);
    if (user) {
      log('billing, user: ', billing, user);
      SPBackend.getPlanInfo(billing.subscriptionId, user)
      .then(response => {
        log('[getPlanInfo] response: ', response.data);
        setLoading(false);
        const { plan, product, discount, subscription, account } = response.data;
        setDiscount(discount);
        setPlanInfo(plan);
        setProductInfo(product);
        setSubscription(subscription);
        dispatch({
          type: 'ACTION_SET_BILLING', 
          billing: {
            customerId: account.Billing ? account.Billing.CustomerId : null,
            subscriptionId: subscription.id,
            subscriptionStatus: subscription.status
          }
        });
      })
      .catch(err => {
        setLoading(false);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: err.message,
            level: 'error',
            duration: 5000
          }
        });
      });

      SPBackend.getMembersTopupPlans(user)
      .then(response => {
        log('[getMembersTopupPlans] response: ', response.data);
        const { plans } = response.data;
        setTopupPlans(plans);
      })
      .catch(err => {
        setLoading(false);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: err.message,
            level: 'error',
            duration: 5000
          }
        });
      });

      SPBackend.getAccountInvoices(user)
      .then(response => {
        log('[getAccountInvoices] response: ', response);
        setInvoices(response.data);
      })
      .catch(e => {
        log('[getAccountInvoices] error: ', e);
      });

      getPaymentMethods();
    }
  }, [billing.subscriptionId]);

  const onPaymentMethodSelected = (pm) => {
    // support only 1 pm at a time, so this will be default
    setPaymentMethods([pm]);
    setDefaultPaymentMethod(pm);
  };

  return (
    <Grid
      className={classes.tableContent}
    >
      <div className={classes.toolbarHalf} />
      {
        loading
        ? <p>{'Loading your account...'}</p>
        : <Grid>
          {
            subscription && accountUsage && accountUsage.CurrentPeriod
            ? <div>
                <Card className={classes.cardCtr}>
                  <h2>Credits available: {Number.isNaN(Number(accountUsage.TotalCreditsBalance)) ? 0 : Number(accountUsage.TotalCreditsBalance).toLocaleString()}</h2>
                  <TableContainer component={Paper}>
                    <Table aria-label="Credits Breakdown"
                      classes={{root: classes.usageTable}}
                    >
                      <TableHead>
                        <TableRow className={classes.textBold}>
                          <TableCell>Source of Credits</TableCell>
                          <TableCell align="right">Quota</TableCell>
                          <TableCell align="right">Used</TableCell>
                          <TableCell align="right">Available</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={'credit-m'}>
                          <TableCell component="th"
                                     scope="row"
                          >
                            Membership plan
                          </TableCell>
                          <TableCell align="right">{Number.isNaN(Number(accountUsage.CurrentPeriod.Limit)) ? 0 : Number(accountUsage.CurrentPeriod.Limit).toLocaleString()}</TableCell>
                          <TableCell align="right">{Number.isNaN(Number(accountUsage.CurrentPeriod.CreditsUsed)) ? 0 : Number(accountUsage.CurrentPeriod.CreditsUsed).toLocaleString()}</TableCell>
                          <TableCell align="right">{Number.isNaN(Number(accountUsage.CurrentPeriod.Limit - accountUsage.CurrentPeriod.CreditsUsed)) ? 0 : Number(accountUsage.CurrentPeriod.Limit - accountUsage.CurrentPeriod.CreditsUsed).toLocaleString()}</TableCell>
                        </TableRow>
                        {accountUsage.Topups && accountUsage.Topups.map((t, tidx) => (
                          <TableRow key={`topup-${tidx}`}>
                            <TableCell component="th"
scope="row"
                            >
                              {`$${Number(t.AmountPaid / 100).toLocaleString()} on ${moment(t.CreatedDt).format('MM/DD/YY')} - Order #${t.Id}`}
                            </TableCell>
                            <TableCell align="right">{Number.isNaN(Number(t.Limit)) ? 0 : Number(t.Limit).toLocaleString()}</TableCell>
                            <TableCell align="right">{Number.isNaN(Number(t.CreditsUsed)) ? 0 : Number(t.CreditsUsed).toLocaleString()}</TableCell>
                            <TableCell align="right">{Number.isNaN(Number(t.Limit - t.CreditsUsed)) ? 0 : Number(t.Limit - t.CreditsUsed).toLocaleString()}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* <p>Period-to-date total usage: <b>{Number(accountUsage.CurrentPeriod.Usage)} message{accountUsage.CurrentPeriod.Usage > 1 ? 's' : ''}</b></p>
                  <p>Current period usage limit: <b>{accountUsage.CurrentPeriod.Limit} messages</b></p> */}
                  <p />
                </Card>
              </div>
            : null
          }
          <Card className={classes.cardCtr}>
            <h2>{'Membership plan'}</h2>
            {
              accountUsage && accountUsage.CurrentPeriod
              ? billing.subscriptionStatus === 'canceled'
                ? <p>No active subscription</p>
                : <>
                  <p>Current plan: {
                    planInfo.id === process.env.REACT_APP_ACCOUNT_DEFAULT_SIGNUP_PLAN
                    ? <b>Trial</b>
                    : <b>{`$${numberWithCommas(planInfo.amount / 100)}/${planInfo.interval}`}</b>
                  }</p>

                  {
                    subscription && accountUsage && accountUsage.CurrentPeriod
                    ? <p>Billing period: <b>{`${moment.unix(subscription.current_period_start).format('MMM D, YYYY')} -> ${moment.unix(subscription.current_period_end).format('MMM D, YYYY')}`}</b></p>
                    : null
                  }
                  {
                    accountUsage.CurrentPeriod
                    ? <p>Credits included in your plan: <b>{Number(accountUsage.CurrentPeriod.Limit).toLocaleString()}</b></p>
                    : null
                  }
                  {
                    discount && discount.object === 'discount'
                    ? <p>
                        {'Discount code used: '}<b>{discount.coupon.id}</b>
                      </p>
                    : null
                  }
                  {
                    subscription && planInfo.trial_period_days > 0 && subscription.trial_end !== null
                    ? <Grid item 
                            xs={12}
                      >
                        <p>
                          {
                            moment.unix(subscription.trial_end).isAfter(moment())
                            ? <span>
                                {'Your free trial lasts until '}
                                <b>{`${moment.unix(subscription.trial_end).format('MMMM D, YYYY [at] hA')}.`}</b>
                                <br />
                              </span>
                            : null
                          }
                        </p>
                        <p>{'For any changes to your account, please text us: +‭1 (917) 451-5515.'}</p>
                      </Grid>
                    : null
                  }
                </>
              : <p>Your account is still being setup, refresh this page in a few minutes.</p>
            }
            
            
          </Card>
          <Card className={classes.cardCtr}>
            <h2>Recharges</h2>
            <Typography variant='body1'>Add credits to your account on top of your subscription.
            <br />Credits are valid for 12 months.
            </Typography>
            <TableContainer classes={{ root: classes.topupTable }}
                            component={Paper}
                            elevation={2}
            >
            <Table
              aria-label="Recharges"
              
            >
              <TableHead>
                <TableRow className={classes.tableHeadRowCtr}>
                  <TableCell className={classes.col1}>{'Package'}</TableCell>
                  <TableCell className={classes.col2}>{'Credits'}</TableCell>
                  <TableCell className={classes.col3}>{'Price per Credit'}</TableCell>
                  <TableCell className={classes.col4}>{'Price'}</TableCell>
                  <TableCell className={classes.col5} />
                </TableRow>
              </TableHead>
              <TableBody>
              {
                topupPlans && topupPlans.map((topup: Plan, topupIdx) => (
                  <TableRow
                    key={`topup-${topupIdx}`}
                  >
                    <TableCell>
                      {topupIdx + 1}  
                    </TableCell>
                    <TableCell>
                      {numberWithCommas(topup.UsageLimit)}
                    </TableCell>
                    <TableCell>
                      ${numberWithCommas(Math.round((topup.Price * 1000) / topup.UsageLimit) / 1000)}
                    </TableCell>
                    <TableCell className={classes.priceCell}>
                      ${topup.Price}
                    </TableCell>
                    <TableCell className={classes.col5}>
                      <Button
                        color='primary'
                        onClick={() => handleSelectPlanToPurchase(topup)}
                        variant='contained'
                      >{'Buy'}</Button>
                    </TableCell>
                  </TableRow>
                ))
              }
              </TableBody>
            </Table>
            </TableContainer>
          </Card>
          <Card className={classes.cardCtr}>
            <Grid>
              <h2>{'Your payment details'}</h2>
              {
                paymentMethods && !R.isEmpty(paymentMethods)
                ? paymentMethods.map((pm, i) => {
                  return (
                    <div key={`pm-${i}`}><span className={classes.capitalize}>{pm.card.brand}</span> ({pm.card.last4}) - Expires {pm.card.exp_month}/{pm.card.exp_year}&nbsp;&nbsp;
                    {defaultPaymentMethod && pm.id === defaultPaymentMethod.id ? <Chip classes={{root: classes.defaultChip}}
label='default'
                                                                                 /> : null}</div>
                  );
                })
                : null
              }
              {
                !R.isEmpty(paymentMethods)
                ? <div
                  className={classes.link}
                  onClick={(e) => {
                    setOpenUpdatePaymentMethodDialog(true);
                  }}
                  ><br />{'Update payment method'}</div>
                : null
              }
            </Grid>
            {
              R.isEmpty(paymentMethods)
              ? <Grid>
                  <CreditCardForm 
                    onPaymentMethodSelected={onPaymentMethodSelected}
                  />
                </Grid>
              : null
            }
            
          </Card>
          <Card className={classes.cardCtr}>
            <h2>{'Invoices'}</h2>
            {
              invoices
              ? invoices.map((invoice, i) => (
                <Grid container
                      key={`invoice-${i}`}
                >
                  <Grid item
                        xs={7}
                  >{moment.unix(invoice.created).format('YYYY-MM-DD [-] MMMM')}</Grid>
                  <Grid item
                        xs={5}
                  ><a className={classes.downloadLink}
                                        href={invoice.pdf}
                   ><DownloadIcon /></a></Grid>
                </Grid>
              ))
              : null
            }
          </Card>
        </Grid>
      }
      
      <ConfirmTopupPurchaseDialog
        defaultPaymentMethod={defaultPaymentMethod}
        isActionLoading={isConfirmTopupPurchaseLoading}
        open={openConfirmTopupDialog}
        selectedPlan={selectedPlan}
        setOpen={setOpenConfirmTopupDialog}
        setSelectedPlan={setSelectedPlan}
        triggerAction={handleConfirmTopupPurchase}
      />
      <UpdatePaymentMethodDialog
        defaultPaymentMethod={defaultPaymentMethod}
        isActionLoading={isConfirmTopupPurchaseLoading}
        onPaymentMethodSelected={onPaymentMethodSelected}
        open={openUpdatePaymentMethodDialog}
        setOpen={setOpenUpdatePaymentMethodDialog}
        triggerAction={onPaymentMethodSelected}
      />
    </Grid>
  );
};

const combinedStyles2 = combineStyles(
  styles,
  commonListTableStyles,
  MemberStructuralStyles
);

export default withStyles(combinedStyles2)(BillingContent);