// @flow

const styles = (theme: Object) => ({
  root: {
    width: '100%',
    padding: '6px 0 7px',
    cursor: 'text'
  }
});

export default styles;
