// import { CHECKOUT_PAPER } from '../../Billing/PlansHome.Style';
import { phoneInputCss } from '../../../css/phoneInput';
const FORM_WIDTH = 450;

const styles = (theme: Object) => ({
  container: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      // maxWidth: '95vw',
      marginTop: theme.spacing(3),
      padding: `${theme.spacing(4)}px 0px`
    }
  },
  contentCtr: {
    flexGrow: 1,
    marginTop: 0,
    textAlign: 'center',
    margin: '0 auto',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '95vw',
      marginTop: theme.spacing(3),
      padding: `0px ${theme.spacing(4)}px`
    }
  },
  formPaper: {
    // ...CHECKOUT_PAPER,
    marginTop: 90,
    margin: '0 auto',
    maxWidth: 540,
    width: FORM_WIDTH + theme.spacing(3) * 3,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    '& p, & h2, & h3': {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5)
    },
    '& h2': {
      color: theme.palette.primary.main,
      fontSize: '2rem',
      paddingBottom: 0
    },
    '& h3': {
      // fontSize: '1.2rem',
      fontWeight: 600
    },
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      marginTop: 50,
      maxWidth: '90vw',
      minWidth: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  readonlyPhoneCtr: {
    display: 'flex',
    justifyContent: 'center'
  },
  readonlyPhone: {
    marginTop: theme.spacing(4)
  },
  phoneInputCtr: {
    ...phoneInputCss,
    paddingTop: '1.25rem',
    paddingBottom: '1.25rem',
    '& input': {
      fontSize: '1.2rem'
    }
  },
  phoneInputDisabled: {
    '& .react-phone-number-input__row input': {
      color: 'gray'
    }
  },
  signupBtn: {
    marginTop: theme.spacing(2),
    width: '100%',
    borderRadius: 4,
    fontWeight: 600,
    padding: `${theme.spacing(2.5)}px 30px`
  },
  codeInput: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    margin: '0 auto',
    '& input': {
      ...theme.typography.h3,
      fontFamily: theme.typography.fontFamily
    }
  },
  disabled: {
    pointerEvents: 'none',
    textDecoration: 'none',
    color: theme.layout.customProps.gray,
    cursor: 'default'
  }
});

export default styles;