// @flow

import React, {useState} from 'react';
import { useStore } from '../../store';

import { log } from '../../utils/jsUtils';
import classNames from 'classnames';
import { handleAssignTasks } from '../SharedComponents/feedUtils';
import * as R from 'ramda';

import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';

import FeedTable from '../SharedComponents/FeedTable';
import AssignTasksDialog from '../Feed/AssignTasksDialog';
import { translateStatusToHuman, REF_STATUSES } from '../../models/Request';
import SPBackend from '../../services/SPBackend';
import combineStyles from '../../utils/stylesUtils';
import styles from './Styles/FeedDialog.Style';
// TODO: Consider putting this file in master Styles folder.
import FeedTableStyles from '../Feed/Styles/CommonFeedTable.Style'; 

type Props = {
  classes: Object,
  open: boolean,
  setOpen: Function,
  slug: string,
  updateKPIs: Function
};

const FeedModal = (props: Props) => {
  const {
    classes,
    open, 
    setOpen,
    slug,
    updateKPIs
  } = props;

  const [store, dispatch] = useStore();
  const {
    i18n,
    units,
    user,
    requests: tasks
  } = store;

  const [selectedTasks, setSelectedTasks] = useState([]);
  const [openAssignTaskDialog, setOpenAssignTaskDialog] = useState(false);

  // TODO: to factor with MemberContent.updateRequest.
  const updateRequest = (taskId, newStatus, successMsg, callbackSetLoading) => {
    const updatedIndex = R.findIndex(R.propEq('_id', taskId))(tasks);
    
    // Uses asks for forgiveness methodology. Updates the status in the front end quickly
    // but changes it back if the request did not go through in the backend. 
    const oldStatus = tasks[updatedIndex].Status;
    if (tasks && updatedIndex >= 0) {
      tasks[updatedIndex].Status = newStatus;
    }
    dispatch({
      type: 'ACTION_SET_REQUESTS', 
      requests: tasks
    });
    SPBackend.updateRequest(taskId, newStatus, user)
      .then(response => {
        const { data : updatedRequest } = response;
        updateKPIs(updatedRequest, oldStatus, updatedIndex);
        log('[updated request]: ', updatedRequest);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: successMsg,
            level: 'success',
            duration: 5000
          }
        });
        callbackSetLoading(prevState => !prevState);
        
        if (tasks && updatedIndex >= 0) {
          // Set the udpated row to animate
          tasks[updatedIndex] = {
            ...updatedRequest,
            animateUpdated: true
          };
          // Set the previous row that will come down to replace it to fade in
          if (updatedIndex > 0) {
            for (let i = 0; i < updatedIndex; i += 1) {
              tasks[i].animatePushedin = true;
            }
          }
          log('tasks[updatedIndex]: ', tasks[updatedIndex]);
          log('tasks[updatedIndex]: ', tasks[updatedIndex]);
          // TODO: Might need to take this out of the store and make it a local state.
          const byDate = R.descend(R.path(['UpdatedDt']));
          dispatch({
            type: 'ACTION_SET_REQUESTS', 
            requests: R.sort(byDate, tasks)
          });
        }
      })
      .catch(err => {
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: `The ${i18n.t('task')} could not be updated. Please try again.`,
            level: 'error',
            duration: 5000
          }
        });
        log('[updateRequest] error: ', err);
      });
  };

  const markInProgress = (taskId, newStatus, callbackSetLoading) => (e) => {
    e.stopPropagation();
    callbackSetLoading(prevState => !prevState);
    updateRequest(taskId, newStatus, 'Request updated.', callbackSetLoading);
  };

  // TODO: to move to FeedTable.
  const triggerOpenAssignTaskDialog = (task) => (e) => {
    e.stopPropagation();
    setSelectedTasks([task]);
    setOpenAssignTaskDialog(true); 

    log('assignTask');
  };

  return (
    <Dialog
      aria-labelledby='form-feed'
      classes={{
        paperWidthSm: classes.dialogPaperWidthSm
      }}
      onClose={() => setOpen(false)}
      open={open}
    >
      <div className={classNames(classes.resultsCount, classes.marginTop)}>
        {slug === 'INQUIRIES' ? `${tasks.length} tasks total` : `${tasks.length} tasks marked ${translateStatusToHuman[REF_STATUSES[slug]]}`}
      </div>
        {
        tasks && tasks.length > 0 && units && units.length > 0
        ? <FeedTable 
            markInProgress={markInProgress}
            triggerAssignDialog={triggerOpenAssignTaskDialog}
          />
        : <div className={classes.resultsCount}>
            {'No results.'}
          </div>
        }
        {/* The calledFromActivity field exists for the handleAssignTask function */}
        <AssignTasksDialog 
          calledFromActivity={false}
          open={openAssignTaskDialog}
          setOpen={setOpenAssignTaskDialog}
          tasks={selectedTasks}
          triggerAction={handleAssignTasks}
        />
    </Dialog>
  );  
};

const combinedStyles = combineStyles(
  FeedTableStyles,
  styles
);

export default withStyles(combinedStyles)(FeedModal);