// @flow

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useStore } from '../../store';
import * as R from 'ramda';
import SPBackend from '../../services/SPBackend';
import { log } from '../../utils/jsUtils';
import MemberDrawer from '../MemberDrawer';
import BillingHeader from './BillingHeader';
import BillingContent from './BillingContent';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/BillingHome.Style';

type Props = {
  classes: Object
};

const BillingHome = (props: Props) => {
  const { classes } = props;

  // drawer state & controls
  const [mobileOpen, setMobileOpen] = useState(false);
  const usehandleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [store, dispatch] = useStore();
  const {
    user
  } = store;

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Neo | Billing</title>
      </Helmet>
      <BillingHeader
        mobileOpen={mobileOpen} 
        usehandleDrawerToggle={usehandleDrawerToggle}
      />
      <MemberDrawer 
        mobileOpen={mobileOpen} 
        usehandleDrawerToggle={usehandleDrawerToggle}
      />
      <BillingContent /> 
    </div>
  );
};

export default withStyles(styles)(BillingHome);