// @flow

import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import classNames from 'classnames';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';
import { TwitterPicker } from 'react-color';

import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/DeleteOutline';

import TagRenderer from '../SharedComponents/TagRenderer';
import ConfirmRemoveTagDialog from './ConfirmRemoveTagDialog';
import EditTagDialog from './EditTagDialog';
import ListTableRow from '../ListTableRow';
import TagColorPicker from '../SharedComponents/TagColorPicker';
import SPBackend from '../../services/SPBackend';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/TagsContent.Style';
import MemberStructuralStyles from '../Styles/CommonMemberStructuralStyles.Style';
import commonListTableStyles from '../Styles/CommonListTable.Style';
import combineStyles from '../../utils/stylesUtils';

import type { Tag } from '../../services/SPBackend';

type Props = {
  classes: Object
};

const TagsContent = (props: Props) => {
  const {
    classes
  } = props;

  const [hover, setHover] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [openConfirmRemoveTagDialog, setOpenConfirmRemoveTagDialog] = useState(false);
  const [openEditTagDialog, setOpenEditTagDialog] = useState(false);
  const [sortInfo, setSortInfo] = useState({
    Name: 1
  });
  const [sortedTags, setSortedTags] = useState([]);
  
  const [store, dispatch] = useStore();
  const {
    user,
    i18n,
    tags
  } = store;

  const toggleSortOrder = (prop: string) => {
    log('[toggleSortOrder] prop: ', prop);
    log('[toggleSortOrder] sortInfo', sortInfo);
    log('[toggleSortOrder] sortedTags', sortedTags);
    const newOrder = {};
    newOrder[prop] = sortInfo && typeof sortInfo[prop] !== 'undefined' ? -sortInfo[prop] : 1;
    log('[toggleSortOrder] newOrder', newOrder);
    setSortInfo(newOrder);
  };

  const applySortOrder = (tagsList) => {
    log('[applySortOrder] tagsList: ', tagsList);
    // order list
    // special ordering for on-the-fly calcs

    log('[applySortOrder] sortInfo: ', sortInfo);
    const [prop] = Object.keys(sortInfo);
    if (prop === 'Customers') {
      let byProp = R.ascend(R.compose(R.length, R.path([prop])));
      if (sortInfo[prop] === -1) {
        byProp = R.descend(R.compose(R.length, R.path([prop])));
      }
      const _sortedTags = R.sort(byProp, tagsList);
      return _sortedTags;
    }
    // basic ordering for other props
    let byProp = R.ascend(R.compose(R.toLower, R.path([prop])));
    if (sortInfo[prop] === -1) {
      byProp = R.descend(R.compose(R.toLower, R.path([prop])));
    }
    const _sortedTags = R.sort(byProp, tagsList);
    return _sortedTags;
  };

  useEffect(() => {
    if (tags !== null && !R.isEmpty(tags)) {
      log('[reloading tags]...', tags);
      setSortedTags(applySortOrder(tags));
    }
  }, [tags]);

  // auto-reorder list when sortInfo changes
  useEffect(() => {
    log('[useEffect] resorting list...');
    setSortedTags(applySortOrder(sortedTags));
  }, [sortInfo]);

  useEffect(() => {
    if (user !== null) {
      log('[loading tags]...');
      SPBackend.getTags('cust', user)
        .then((response) => {
          const { data } = response;
          log('[getTags] setSortedTags: ', data);
          setSortedTags(applySortOrder(data));
          log('[tags] response.data: ', data);
          dispatch({
            type: 'ACTION_SET_TAGS', 
            tags: data
          });
        })
        .catch(e => {
          log('[MemberHome.getTags] e: ', e);
        });
    }
  }, [user]);

  const handleEditTag = (tagProps) => {
    log('[handleEditTag] tagProps: ', tagProps);
    SPBackend.editTag({
      _id: selectedTag._id,
      ...R.pick(['_id', 'Name', 'Color'], tagProps)
    }, user)
      .then(response => {
        const { data } = response;
        const { tag: persistedTag } = data;
        dispatch({
          type: 'ACTION_UPDATE_TAG', 
          tag: tagProps
        });
        dispatch({
          type: 'ACTION_SET_IS_APP_LOADING',
          isAppLoading: false
        });
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: data.message,
            level: data.success,
            duration: 2000
          }
        });
      })
      .catch(e => {
        log('[handlehandleSetTag] e', e);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: e.message,
            level: 'error',
            duration: 2000
          }
        });
        dispatch({
          type: 'ACTION_SET_IS_APP_LOADING',
          isAppLoading: false
        });
      });
  };

  const archiveTag = (tag) => {
    SPBackend.archiveTag(tag._id, user)
      .then((response) => {
        const { data } = response;
        log('[tags] response.data: ', data);
        // remove tag from list
        dispatch({
          type: 'ACTION_REMOVE_TAG', 
          tag: tag
        });
        // Show snack message
        dispatch({
          type: 'ACTION_SET_SNACK_MSG',
          snackMessage: {
            message: data.message,
            level: 'success',
            duration: 3000
          }
        });
      })
      .catch(e => {
        log('[MemberHome.getTags] e: ', e);
        // Show snack message
        dispatch({
          type: 'ACTION_SET_SNACK_MSG',
          snackMessage: {
            message: 'An error occured. Please try again.',
            level: 'error',
            duration: 3000
          }
        });
      });
  };


  return (
    <>
      <div className={classes.tableContainer}>
        <div className={classes.toolbar}/>
        {/** MOBILE VERSION **/}
        <Hidden mdUp>
          <Grid
            className={classes.mobileTableHeader}
            item
          >
            <span onClick={() => toggleSortOrder('Name')}>
            {'Tag Name'}{
              sortInfo && sortInfo.Name === -1 
              ? <ArrowUpwardIcon className={classes.arrowIcon}/> 
              : null
            }
            {
              sortInfo && sortInfo.Name === 1 
              ? <ArrowDownwardIcon className={classes.arrowIcon}/>
              : null
            }
            </span>
          </Grid>
          {
            sortedTags && sortedTags.map((tag, tagIdx) => (
              <Grid
                className={classes.mobileFeedItem}
                item
                key={`$tenant-${tagIdx}`}
              >
                <Grid
                  className={classes.mobileTileCtr}
                  container
                  direction='column'
                  justifyContent='space-between'
                >
                  <Grid
                    className={classes.mobileRowItem}
                    item
                  >
                    <div className={classes.tileLeftColCtr}>
                      <Typography
                        className={classes.tileTitle}
                        variant='body1'
                      >
                        <TagRenderer
                          readonly
                          tagsIds={[tag._id]}
                        />
                      </Typography>
                      <Typography
                        className={classNames(classes.tileSubTitle, classes.tileSmallText, classes.tileNewLine)}
                        variant='body1'
                      >
                        {tag.Customers && tag.Customers.length > 0 ? `${tag.Customers.length} Customers` : ''}
                      </Typography>
                    </div>
                    <div className={classes.tileRightColCtr}>
                      <IconButton
                        aria-label={`Edit ${i18n.t('tag')}`}
                        classes={{
                          root: classes.tileActionBtn
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSelectedTag(tag);
                          setOpenEditTagDialog(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label={`Remove ${i18n.t('tag')}`}
                        classes={{
                          root: classes.tileActionBtn
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSelectedTag(tag);
                          setOpenConfirmRemoveTagDialog(true);
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            ))
          }
        </Hidden>
        {/** TABLET + DESKTOP VERSION **/}
        <Hidden smDown>
        <Table className={classes.table}>
          <TableHead className={classes.feedTableBody}>
            <TableRow className={classNames(classes.feedTableHeadRow, classes.customFeedTableHeadRow)}>
              <TableCell
                className={classes.nameCell}
                onClick={() => toggleSortOrder('Name')}
              >
              {'Tag Name'}{
                sortInfo && sortInfo.Name === -1 
                ? <ArrowUpwardIcon className={classes.arrowIcon}/> 
                : null
              }
              {
                sortInfo && sortInfo.Name === 1 
                ? <ArrowDownwardIcon className={classes.arrowIcon}/>
                : null
              }
              </TableCell>
              <TableCell
                className={classes.colAction}
                onClick={() => toggleSortOrder('Customers')}
              >
              {'Customers'}{
                sortInfo && sortInfo.Customers === -1 
                ? <ArrowUpwardIcon className={classes.arrowIcon}/> 
                : null
              }
              {
                sortInfo && sortInfo.Customers === 1 
                ? <ArrowDownwardIcon className={classes.arrowIcon}/>
                : null
              }
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            className={classNames(
              classes.feedTableBody,
              classes.customTableStyles
            )}
          >
          {
            sortedTags && sortedTags.map((tag, tagIdx) => (
              <ListTableRow
                className={classNames(
                  hover ? 'hover' : null,
                  classes.feedTableRow
                )}
                key={`tags-${tagIdx}`}
                onMouseEnter={(e) => {e.stopPropagation(); setHover(true);}}
                onMouseLeave={(e) => {e.stopPropagation(); setHover(false);}}
              >
                <TableCell
                  className={classNames(
                    classes.cell,
                    classes.nameCell
                  )}
                  component='td'
                  scope='row'
                >
                  <TagRenderer
                    readonly
                    tagsIds={[tag._id]}
                  />
                </TableCell>
                <TableCell
                  className={classNames(
                    classes.cell,
                    classes.custCountCell,
                    'lastChild',
                    'underActionCell',
                    'twoBtns'
                  )}
                  component='td'
                  scope='row'
                >
                  {tag.Customers && tag.Customers.length > 0 ? tag.Customers.length : '-'}
                </TableCell>
                <TableCell
                  align='right'
                  className={classNames(
                    classes.cell,
                    classes.actionCell,
                    'actionCell',
                    'lastChild'
                  )}
                >
                  <Tooltip
                    aria-label={`Remove ${i18n.t('tag')}`}
                    title={`Remove ${i18n.t('tag')}`}
                  >
                    <IconButton
                      aria-label={`Remove ${i18n.t('tag')}`}
                      classes={{
                        root: classes.rowActionBtn
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedTag(tag);
                        setOpenConfirmRemoveTagDialog(true);
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    aria-label={`Edit ${i18n.t('tag')}`}
                    title={`Edit ${i18n.t('tag')}`}
                  >
                    <IconButton
                      aria-label={`Edit ${i18n.t('tag')}`}
                      classes={{
                        root: classes.rowActionBtn
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedTag(tag);
                        setOpenEditTagDialog(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </ListTableRow>
            ))
          }
          </TableBody>
        </Table>
      </Hidden>
      </div>
      <EditTagDialog
        actionConfirm={handleEditTag}
        open={openEditTagDialog}
        setOpen={setOpenEditTagDialog}
        tag={selectedTag}
      />
      <ConfirmRemoveTagDialog
        actionConfirm={archiveTag}
        open={openConfirmRemoveTagDialog}
        setOpen={setOpenConfirmRemoveTagDialog}
        tag={selectedTag}
      />
    </>
  );
};

const combinedStyles = combineStyles(
  styles,
  MemberStructuralStyles,
  commonListTableStyles
);

export default withStyles(combinedStyles)(TagsContent);

