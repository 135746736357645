// @flow

import React from 'react';
import classNames from 'classnames';
import invert from 'invert-color';

import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';

import CloseIcon from '@material-ui/icons/Close';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/TagRenderer.Style';

import type { Tag } from '../../services/SPBackend';
type Props = {
  classes: Object,
  renderTags: Array<Tag>,
  callbackOnTagRemoved?: Function,
  handleOnTagClick?: Function
}
const TagReadonly = (props: Props) => {
  const {
    renderTags, // tags to render
    classes,
    callbackOnTagRemoved,
    handleOnTagClick
  } = props;

  const wrapInSpan = (label) => { // html2canvas will only read words in Chip if they are wrapped in a double span 
    const spanningLabel = <span><span>{label}</span></span>;
    return (spanningLabel);
  };

  return (
    <React.Fragment>
    {
      renderTags && renderTags.map((tag, index) => (
        tag
        ? <Chip
            className={classNames(
              handleOnTagClick ? classes.hover : null,
              classes.chipTag
            )}
            deleteIcon={<Tooltip title={'Remove tag'}><CloseIcon className={classes.deleteIcon} /></Tooltip>}
            key={`tag-${index}`}
            label={wrapInSpan(tag.Name)}
            onClick={handleOnTagClick}
            onDelete={callbackOnTagRemoved ? callbackOnTagRemoved(tag) : null}
            size="small"
            style={{
              color: invert(tag.Color, { black: '#080808', white: '#fafafa' }),
              background: tag.Color
            }}
          />
        : null
        ))
    }
    </React.Fragment>
  );
};

export default withStyles(styles)(TagReadonly);
