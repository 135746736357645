// @flow

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useStore } from '../../store';
import { log, formatPhoneNumberForFrontend } from '../../utils/jsUtils';
import * as R from 'ramda';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import RemoveIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import LinkIcon from '@material-ui/icons/Link';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/PropertiesContent.Style';
import MemberStructuralStyles from '../Styles/CommonMemberStructuralStyles.Style';
import commonListTableStyles from '../Styles/CommonListTable.Style';
import combineStyles from '../../utils/stylesUtils';
import { ROLE_POWER } from '../../models/User';

import ListTableRow from '../ListTableRow';
import RemoveProperty from './RemovePropertyDialog';
import EditPropertyDialog from './EditPropertyDialog';
import type { Building } from '../../services/SPBackend';
import RequestNewNumber from './RequestNewNumberDialog';
import SPBackend from '../../services/SPBackend';

type Props = {
  classes: Object,
  properties: Array<Building>,
  sortByAddress: Function,
  stats: Array<Object>,
  removeProperty: Function,
  editProperty: Function
};

const RenderStat = (props: Object) => {
  const {
    stats,
    propertyKey,
    statName
  } = props;
  const stat = R.find(R.propEq('propertyKey', propertyKey))(stats);
  return (
    <span>
      {
        stat
        ? stat[statName]
        : null
      }
    </span>
  );
};

// eslint-disable-next-line react/no-multi-comp
const RequestContent = (props: Props) => {
  const {
    classes,
    properties,
    sortByAddress,
    stats,
    removeProperty,
    editProperty
  } = props;
  const history = useHistory();
  const [store, dispatch] = useStore();
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] = useState(false);
  const [openConfirmEditDialog, setOpenConfirmEditDialog] = useState(false);
  const [openRequestNewNumberDialog, setOpenRequestNewNumberDialog] = useState(false);
  const [building, setBuilding] = useState(null);

  const {
    userRole,
    accountId,
    i18n,
    user
  } = store;

  const selectPropertyForRemoval = (property) => {
    setSelectedProperty(property);  
    setOpenConfirmRemoveDialog(true);  
  };

  const selectPropertyForEditing = (property) => {
    setSelectedProperty(property);
    setOpenConfirmEditDialog(true);
  };

  const handleRemoveProperty = () => {
    if (selectedProperty !== null) {
      removeProperty(selectedProperty.PropertyKey);
      setSelectedProperty(null);
    } else {
      // Show snack message
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: 'An error occured. Please try again.',
          level: 'error',
          duration: 3000
        }
      });  
    }
  };

  const handleRequestNewNumber = () => {
    log('[handleRequestNewNumber] new number requested', building);
    const payload = {
      Property: building.Property,
      PropertyKey: building.PropertyKey,
      AccountId: building.AccountId,
      RequesterEmail: user.email
    };
    SPBackend.sendEmailRequestForNewNumber(user, payload)
    .then((response) => {
      log('[handleRequestNewNumber]', response);
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: 'Request sent',
          level: 'success',
          duration: 3000
        }
      });
    })
    .catch(error => {
      log('[handleRequestNewNumber] error: ', error);
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: error.response ? error.response.data : 'An error occured. Please try again',
          level: 'error',
          duration: 5000
        }
      }); 
    });
  };

  const handleEditProperty = (property) => {
    if (selectedProperty !== null) {
      editProperty(selectedProperty.PropertyKey, property);
      setSelectedProperty(null);
    } else {
      // Show snack message
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: 'An error occured. Please try again.',
          level: 'error',
          duration: 3000
        }
      });  
    }
  };
    
  const goToProperty = (id: number) => () => {
    log('goToProperty');
    
    history.push({
      pathname: `/property/${id}`
    });
  };

  return (
    <Grid 
      className={classes.tableContent}
    >
    <div className={classes.toolbar} />
      <div className={classes.resultsCount}>&nbsp;&nbsp;&nbsp;&nbsp;
      {
        properties
        ? `${properties.length} ${i18n.t('properties')} found.`
        : `0 ${i18n.t('properties')} found.`
      }
      </div>
      <Table className={classes.table}>
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
          <col classes={classes.colAction} />
        </colgroup>
        <TableHead className={classes.feedTableBody}>
          <TableRow className={classes.feedTableHeadRow}>
            <TableCell 
              aria-label={'Address'}
              className={classNames(classes.addressCell)} 
              onClick={() => sortByAddress('Property')}
            >&nbsp;&nbsp;&nbsp;&nbsp;{'Address'}
            </TableCell>
            <TableCell className={classNames(classes.nbCell)}>{i18n.t('Units')}</TableCell>
            <TableCell className={classNames(classes.nbCell)}>{i18n.t('Tenants')}</TableCell>
            <TableCell className={classNames(classes.staffCell)}>{i18n.t('Staff')}</TableCell>
            <TableCell className={classNames(classes.nbCell)}>{'Web Bot'}</TableCell>
            <TableCell className={classNames(classes.colAction)}>{'SMS Bot'}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classNames(
            classes.feedTableBody,
            classes.customTableStyles
          )}
        >
        {
          properties.map((building: Building, bIndex) => {
            return (
              <ListTableRow
                handleRowClick={goToProperty(building.PropertyKey)}
                key={`building=${bIndex}`}
              >
                <TableCell
                  className={classNames(classes.cell, classes.addressCell)}
                  component="td"
                  scope="row"
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;{building.Property}
                </TableCell>
                <TableCell
                  className={classNames(classes.cell, classes.nbCell)}
                  component="td"
                  scope="row"
                >
                {
                  stats
                  ? <RenderStat
                      propertyKey={building.PropertyKey}
                      statName={'unitsCount'}
                      stats={stats}
                    />
                  : null
                }
                </TableCell>
                <TableCell
                  className={classNames(classes.cell, classes.nbCell)}
                  component="td"
                  scope="row"
                >
                {
                  stats
                  ? <RenderStat
                      propertyKey={building.PropertyKey}
                      statName={'tenantsCount'}
                      stats={stats}
                    />
                  : null
                }
                </TableCell>
                <TableCell
                  className={classNames(
                    classes.cell,
                    classes.staffCell
                  )}
                  component="td"
                  scope="row"
                >
                {
                  stats
                  ? <RenderStat
                      propertyKey={building.PropertyKey}
                      statName={'staffCount'}
                      stats={stats}
                    />
                  : null
                }
                </TableCell>
                <TableCell
                  className={classNames(
                    classes.cell,
                    classes.nbCell
                  )}
                  component="td"
                  scope="row"
                >
                {
                  building.PropertyKey
                  ? <a
                      href={`${process.env.REACT_APP_SQUAREP_URL}/r/${accountId}/${building.PropertyKey}`}
                      onClick={(e) => e.stopPropagation()}
                      target='blank'
                    ><LinkIcon /></a>
                  : null
                }
                </TableCell>
                <TableCell
                  className={classNames(
                    classes.cell,
                    'lastChild',
                    'underActionCell',
                    (ROLE_POWER[userRole] > 1) ? 'twoBtns' : null
                  )}
                  component="td"
                  scope="row"
                >
                  <div className={classes.textOverflow}>
                    {
                      building && building.Phone
                      ? formatPhoneNumberForFrontend(building.Phone)
                      : <Button color="primary"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setOpenRequestNewNumberDialog(true);
                                  log('requesting a new number ', building);
                                  setBuilding(building);
                                }}
                        >{'Activate Bot'}</Button>
                    }
                  </div>
                </TableCell>
                { ROLE_POWER[userRole] > 1
                ?
                <TableCell
                  align='right'
                  className={classNames(
                    classes.cell, 
                    classes.actionCell, 
                    'actionCell',
                    'lastChild')}
                >
                  <Tooltip 
                    aria-label={`Remove ${i18n.t('Property')}`}
                    title={`Remove ${i18n.t('Property')}`} 
                  >
                    <IconButton 
                      aria-label={`Remove ${i18n.t('Property')}`}
                      classes={{
                        root: classes.rowActionBtn
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        selectPropertyForRemoval(building);
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip 
                    aria-label={'Edit Name'}
                    title={'Edit Name'} 
                  >
                    <IconButton 
                      aria-label="Edit Name" 
                      classes={{
                        root: classes.rowActionBtn
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        selectPropertyForEditing(building);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                : null
                }
              </ListTableRow>
            );
          })
        }
        </TableBody>
      </Table>
      <RemoveProperty
        actionConfirm={handleRemoveProperty}
        open={openConfirmRemoveDialog}
        property={selectedProperty}
        setOpen={setOpenConfirmRemoveDialog}
      />
      <EditPropertyDialog
        open={openConfirmEditDialog}
        property={selectedProperty}
        setOpen={setOpenConfirmEditDialog}
        triggerAction={handleEditProperty}
      />
      <RequestNewNumber
        open={openRequestNewNumberDialog}
        setOpen={setOpenRequestNewNumberDialog}
        triggerAction={handleRequestNewNumber}
      />
    </Grid>
  );
};

const combinedStyles = combineStyles(
  commonListTableStyles,
  styles,
  MemberStructuralStyles
);
export default withStyles(combinedStyles)(RequestContent);