// @flow

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useStore } from '../../store';
import * as R from 'ramda';
import SPBackend from '../../services/SPBackend';
import { log } from '../../utils/jsUtils';
import MemberDrawer from '../MemberDrawer';
import StaffListHeader from './StaffListHeader';
import StaffListContent from './StaffListContent';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/StaffListHome.Style';

type Props = {
  classes: Object
};

const PropertyHome = (props: Props) => {
  const { classes } = props;

  // drawer state & controls
  const [mobileOpen, setMobileOpen] = useState(false);
  const [staffList, setStaffList] = useState([]);

  const usehandleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [store, dispatch] = useStore();
  const { user } = store;

  const removeStaff = (staff) => {
    log('[removeStaff] staff: ', staff);
    if (user !== null) {
      SPBackend.removeStaff(staff, user)
      .then(response => {
        refreshStaffList();
        log('[removeStaff] staff: ', staff);
        // Show snack message
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: 'Staff removed.',
            level: 'success',
            duration: 3000
          }
        });  
      })
      .catch(error => {
        log('[TenantsHome.fetchFeed.getFeed] error: ', error);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: error.response ? error.response.data : 'An error occured. Please try again',
            level: 'error',
            duration: 5000
          }
        }); 
      });
    }
  };

  const editStaff = (staff, changedStaff) => {
    log('[editStaff] staff: ', staff);
    if (user !== null) {
      SPBackend.editStaff(staff, changedStaff, user)
      .then((response) => {
        refreshStaffList();
        // Show Snack Message
        dispatch({
          type: 'ACTION_SET_SNACK_MSG',
          snackMessage: {
            message: 'Staff Updated',
            level: 'success',
            duration: 3000
          }
        });
      })
      .catch((error) => {
        log('[TenantsHome.fetchFeed.getFeed] error: ', error);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: error.response ? error.response.data : 'An error occured. Please try again',
            level: 'error',
            duration: 5000
          }
        });
      });
    }
  };

  useEffect(() => {
    if (user !== null) {
      refreshStaffList();
    }
  }, [user]);

  const refreshStaffList = () => {
    if (user !== null) {
      dispatch({
        type: 'ACTION_SET_IS_APP_LOADING',
        isAppLoading: true
      });
      SPBackend.getUsers(user)
        .then(response => {
          log('[refreshStaffList] data: ', response);
          setStaffList(response.data);
          dispatch({
            type: 'ACTION_SET_IS_APP_LOADING',
            isAppLoading: false
          });
        })
        .catch(error => {
          log('[refreshStaffList] error', error);
          dispatch({
            type: 'ACTION_SET_IS_APP_LOADING',
            isAppLoading: false
          });
        });
    }
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Neo | Staff</title>
      </Helmet>
      <StaffListHeader
        mobileOpen={mobileOpen}
        refreshStaffList={refreshStaffList}
        usehandleDrawerToggle={usehandleDrawerToggle}
      />
      <MemberDrawer 
        mobileOpen={mobileOpen}
        usehandleDrawerToggle={usehandleDrawerToggle}
      />
      <StaffListContent 
        editStaff={editStaff}
        refreshStaffList={refreshStaffList}
        removeStaff={removeStaff}
        staffList={staffList}
      />
    </div>
  );
};

export default withStyles(styles)(PropertyHome);
