/* eslint-disable react/jsx-sort-props */
// @flow

import React, { useState, useEffect } from 'react';
import { useStore } from '../store';
import { log } from '../utils/jsUtils';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import styles from './Styles/ShowSnackMsg.Style';

/**
 * TODO: eventually, save a list of accumulated messages to display in the store
 */


// eslint-disable-next-line react/display-name
const ShowSnackMsg = () => {
  const [isSnackOpen, showSnack] = useState(false);
  const [{message, level, duration}, setSnackData] = useState({
    message: '',
    level: 'success',
    duration: 3000});

  const [store] = useStore();

  const {
    snackMessage
  } = store;
    
  const closeSnack = () => {
    showSnack(false);
  };

  useEffect(() => {
    if (snackMessage !== null) {
      log('[ShowSnackMsg] snackMessage:', snackMessage);
      const { message, level, duration } = snackMessage;
      if (typeof message !== 'undefined' && message.length > 0) {
        log('[ShowSnackMsg] message, level, duration: ', message, level, duration);
        showSnack(true);
        setSnackData({
          message,
          level,
          duration
        });
      }
    }
  }, [snackMessage]);
  

  const styles = {
    success: {
      backgroundColor: 'green'
    },
    error: {
      backgroundColor: 'red'
    },
    info: {
      backgroundColor: 'blue'
    },
    warning: {
      backgroundColor: 'orange'
    },
    icon: {
      fontSize: 20
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: 16
    },
    message: {
      display: 'flex',
      alignItems: 'center'
    }
  };
  
  const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
  };
  const Icon = variantIcon[level];

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        autoHideDuration={duration}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        onClose={() => showSnack(false)}
        open={isSnackOpen}
      >
        <SnackbarContent
          style={styles[level]}
          aria-describedby="client-snackbar"
          message={
            <span 
              id="client-snackbar"
              style={styles.message}
            >
              {message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              style={styles.close}
              onClick={() => showSnack(false)}
            >
              <CloseIcon style={styles.icon} />
            </IconButton>
          ]}
        />
      </Snackbar>
    </div>
  );
};

export default ShowSnackMsg;