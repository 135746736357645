// @flow

import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { TwitterPicker } from 'react-color';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';

import Popper from '@material-ui/core/Popper';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TagReadonly from '../SharedComponents/TagReadonly';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/EditTagDialog.Style';
import type { Tag } from '../../services/SPBackend';

type Props = {
  classes: Object,
  setOpen: Function,
  open: boolean,
  actionConfirm: Function,
  tag: Tag
}

const EditTagDialog = (props: Props) => {
  const {
    classes,
    setOpen,
    open,
    actionConfirm,
    tag
  } = props;
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [editedTag, setEditedTag] = useState('');
  const inputEl = useRef(null);

  const [store, dispatch] = useStore();
  const {
    i18n
  } = store;

  useEffect(() => {
    log('[EditTagDialog] useEffect...');
    if (tag !== null) {
      log('[EditTagDialog] useEffect tag: ', tag);
      setEditedTag(tag);
    }
  }, [tag]);

  const handleColorPicked = (color, event) => {
    log('[handleColorPicked]... color: ', color);
    log('[handleColorPicked]... tag: ', tag);
    if (typeof tag != 'undefined' && tag !== null) {
      // toggle picker
      setEditedTag({
        ...editedTag,
        Color: color.hex
      });
      setDisplayColorPicker(false);
      setAnchorEl(null);
    }
  };

  const handleEditTagName = (e) => {
    setEditedTag({
      ...editedTag,
      Name: e.target.value
    });
  };
  
  const handleColorPickerClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    // inputEl.current.focus();
  };
  const openColorPicker = Boolean(anchorEl);
  const colorPickerId = openColorPicker ? 'simple-popper' : undefined;

  return (
    <div>
    {
      (editedTag !== null && typeof editedTag !== 'undefined')
      ? <Dialog
          aria-labelledby='form-add-property'
          disableEnforceFocus
          onClose={() => setOpen(false)}
          open={open}
        >
          <DialogTitle id='form-dialog-title'>
          {'Edit tag'}
          </DialogTitle>
          <DialogContent className={classNames(classes.grow, classes.flexContainer)}>
            <DialogContentText>
              {'You are editing this tag:'}<br />
            </DialogContentText>
            <div>
              <TagReadonly
                  readonly
                  renderTags={[editedTag]}
              />
            </div>
            <TextField
                className={classes.margin}
                id="input-with-icon-textfield"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div className={classes.tagCtr}>
                        <div 
                          className={classes.colorPickerRainbow}
                          onClick={handleColorPickerClick} 
                        />
                      </div>
                    </InputAdornment>
                  ),
                }}
                onChange={handleEditTagName}
                value={editedTag.Name}
            />
            <Popper anchorEl={anchorEl}
              className={classes.popperCtr}
              id={colorPickerId}
              open={openColorPicker}
              placement='bottom'
            >
              <TwitterPicker onChangeComplete={handleColorPicked} />
            </Popper>
          </DialogContent>
          <DialogActions>
            <Button 
              color='primary'
              onClick={() => setOpen(false)}
              type='button'
            >
              {'Cancel'}
              </Button>
            <Button 
              color='primary'
              onClick={() => { 
                actionConfirm(editedTag); 
                setOpen(false); 
                }
              }
              variant='contained'
            >
              {'Save changes'}
              </Button>
          </DialogActions>
        </Dialog>
      : null
    }
    </div>
  );
};

export default withStyles(styles)(EditTagDialog);