// @flow

import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useStore } from '../../store';
import { log } from '../../utils/jsUtils';
import classNames from 'classnames';
import moment from 'moment';
import * as R from 'ramda';
import {
  formatPhoneNumberForFrontend,
  formatPhoneNumberForBackend,
  validateEmail
} from '../../utils/jsUtils';
import modelUtils from '../../utils/modelUtils';

import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';

import EditIcon from '@material-ui/icons/Edit';

import UnreadBadge from '../SharedComponents/UnreadBadge';
import TagRenderer from '../SharedComponents/TagRenderer';
import EditTenantDialog from '../Tenants/EditTenantDialog';
import SPBackend from '../../services/SPBackend';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/MemberContent.Style';
import combineStyles from '../../utils/stylesUtils';
import commonFormElementsStyles from '../Styles/CommonFormElements.Style';
import listTableStyles from '../Styles/CommonListTable.Style';
import FeedTableStyles from './Styles/CommonFeedTable.Style';
import FeedMobileStyles from './Styles/CommonFeedMobile.Style';
import theme from '../../theme';
import { REF_STATUSES } from '../../models/Request';
import type { Task, Unit, Tag, Tenant } from '../../services/SPBackend';

type Props = {
  classes: Object,
  request: Task,
  key: string,
  markInProgress: Function,
  triggerOpenAssignTaskDialog: Function,
  goToRequest: Function,
  units: Array<Unit>,
  readonly: Boolean,
  updateTaskCallback: Function
}

const FeedRow = (props: Props) => {  
  const {
    classes,
    goToRequest,
    key,
    request,
    triggerOpenAssignTaskDialog,
    units,
    readonly,
    updateTaskCallback
  } = props;
  const history = useHistory();
  const [store, dispatch] = useStore();
  const {
    i18n,
    user,
    requests,
    isFeedLive
  } = store;

  const [hover, setHover] = useState(false);
  const [unit, setUnit] = useState(null);
  const [openEditTenantDialog, setOpenEditTenantDialog] = useState(false);
  const [isLoadingAssignTask, setLoadingAssignTask] = useState(false);
  
  // log('[FeedRow] updateTaskCallback: ', updateTaskCallback);


  const handleEditTenant = (
    tenant: Tenant,
    moveTasks: boolean,
    callback: Function = null
  ) => {
    const formattedNb = formatPhoneNumberForBackend(tenant.Phone);
    // TODO: validation logic to factor with addTenant in TenantsHeader and PropertyContent, and here.
    // validate email - if non empty
    if (tenant.Email !== '' && !validateEmail(tenant.Email)) {
      dispatch({
        type: 'ACTION_SET_SNACK_MSG',
        snackMessage: {
          message: 'Invalid email.',
          level: 'error',
          duration: 3000
        }
      });
      return;
    }
    
    if (!formattedNb) {
      dispatch({
        type: 'ACTION_SET_SNACK_MSG',
        snackMessage: {
          message: 'Invalid phone number.',
          level: 'error',
          duration: 3000
        }
      });
      return;
    }

    const payload = {
      ...tenant,
      Phone: formattedNb,
      moveTasks
    };

    dispatch({
      type: 'ACTION_SET_IS_LOADING_MODULE',
      module: 'editTenant',
      loading: true
    });
    SPBackend.editTenant(payload, user)
      .then(response => {
        log('[handleEditTenant] response: ', response);
        const { tenant: updatedTenant } = response.data;
        log('[editTenant] res: ', updatedTenant);
        if (callback) {
          callback({
            ...request,
            lookupTenant: updatedTenant
          });
        }
        if (typeof updateTaskCallback !== 'undefined') {
          updateTaskCallback({
            ...request,
            lookupTenant: updatedTenant
          });
        }
        // update request in store
        dispatch({
          type: 'ACTION_UPDATE_REQUESTS', 
          requests: [{
            ...request,
            lookupTenant: updatedTenant
          }]
        });
        dispatch({
          type: 'ACTION_SET_SNACK_MSG',
          snackMessage: {
            message: `${i18n.t('Tenant')} updated`,
            level: 'success',
            duration: 3000
          }
        });
        dispatch({
          type: 'ACTION_SET_IS_LOADING_MODULE',
          module: 'editTenant',
          loading: false
        });
      })
      .catch(error => {
        log('[handleEditTenant] error', error);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG',
          snackMessage: {
            message: error.message || 'An error occured. Please try again.',
            level: 'error',
            duration: 3000
          }
        });
        dispatch({
          type: 'ACTION_SET_IS_LOADING_MODULE',
          module: 'editTenant',
          loading: false
        });
      });
  };

  const handleRenameTask = (taskId) => (updatedText) => {
    dispatch({
      type: 'ACTION_SET_IS_APP_LOADING',
      isAppLoading: true
    });
    SPBackend.renameTask(
      taskId,
      updatedText,
      user
    ).then(response => {
      // update assignee for each task in the feed
      // log('[handleRenameTask] response: ', response);
      dispatch({
        type: 'ACTION_UPDATE_REQUESTS', 
        requests: [response.data.task]
      });
      // log('[FeedRow.handleRenameTask] updateTaskCallback: ', updateTaskCallback);
      if (typeof updateTaskCallback !== 'undefined') {
        updateTaskCallback(response.data.task);
      }
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: response.data.message,
          level: response.data.success ? 'success' : 'error',
          duration: 2000
        }
      });
      dispatch({
        type: 'ACTION_SET_IS_APP_LOADING',
        isAppLoading: false
      });
    })
    .catch(e => {
      log('[handleRenameTask] error: ', e);
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: 'Something went wrong. Please try again.',
          level: 'error',
          duration: 2000
        }
      });
      dispatch({
        type: 'ACTION_SET_IS_APP_LOADING',
        isAppLoading: false
      });
    });
  };

  useEffect(() => {
    if (units && request) {
      log('[useEffect] units, requests');
      const u = R.find(R.propEq('RoomKey', request.RoomKey))(units);
      if (typeof u !== 'undefined' && u !== null) {
        setUnit(u);
      } else {
        setUnit(null);
      }
    }
  }, [request, units]);

  const callbackOnTagPicked = (customerId: string, task: Task) => (tag: Tag) => {
    try {
      console.log('[callbackOnTagPicked] tagging... tag, customerId: ', tag, customerId);
      dispatch({
        type: 'ACTION_SET_IS_LOADING_MODULE',
        module: 'tagCustomer',
        loading: true
      });
      SPBackend.tagCustomers(tag._id, [customerId], user)
        .then((response) => {
          console.log('[callbackOnTagPicked] response: ', response);
          const { message, success } = response.data;
          // update all tasks that have this tenant.
          const isThisCustomer = task => task.TenantId === customerId;
          const tasks = R.filter(isThisCustomer, requests);
          log('[callbackOnTagPicked] tasks: ', tasks);
          const updatedTasks = [];
          let updatedTask = request;
          tasks.forEach((t) => {
            if (t._id === request._id) {
              updatedTask = {
                ...t,
                lookupTenant: {
                  ...t.lookupTenant,
                  Tags: typeof t.lookupTenant !== 'undefined' && typeof t.lookupTenant.Tags !== 'undefined' && typeof tag !== 'undefined' ? R.union(t.lookupTenant.Tags, [tag._id]) : [tag._id]
                }
              };
            }
            updatedTasks.push({
              ...t,
              lookupTenant: {
                ...t.lookupTenant,
                Tags: typeof t.lookupTenant !== 'undefined' && typeof t.lookupTenant.Tags !== 'undefined' && typeof tag !== 'undefined' ? R.union(t.lookupTenant.Tags, [tag._id]) : [tag._id]
              }
            });
          });
          if (typeof updateTaskCallback !== 'undefined') {
            updateTaskCallback(updatedTask);
          }
          dispatch({
            type: 'ACTION_UPDATE_REQUESTS', 
            requests: updatedTasks
          });
          dispatch({
            type: 'ACTION_SET_SNACK_MSG', 
            snackMessage: {
              message: message,
              level: success ? 'success' : 'error',
              duration: 5000
            }
          });
          dispatch({
            type: 'ACTION_SET_IS_LOADING_MODULE',
            module: 'tagCustomer',
            loading: false
          });
        })
        .catch((error) => {
          log('[callbackOnTagPicked] error: ', error);
          dispatch({
            type: 'ACTION_SET_SNACK_MSG', 
            snackMessage: {
              message: error.response ? error.response.data : 'An error occured. Please try again',
              level: 'error',
              duration: 5000
            }
          }); 
        });
    } catch (e) {
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: 'Please select a tag',
          level: 'error',
          duration: 5000
        }
      }); 
    }
  };

  const callbackOnTagRemoved = (customerId: string) => (tag: Tag) => () => {
    try {
      log('[callbackOnTagRemoved]... customerId, tag: ', customerId, tag);
      dispatch({
        type: 'ACTION_SET_IS_LOADING_MODULE',
        module: 'tagCustomer',
        loading: true
      });
      SPBackend.removeTagFromCustomers(tag._id, [customerId], user)
        .then((response) => {
          const removeTagFromTenant = (tags?: Array<Tag> = []) => {
            if (typeof tags === 'undefined' || R.isEmpty(tags)) {
              return [];
            }
            const otherTags = (t => t !== tag._id);
            return R.filter(otherTags, tags);
          };

          console.log('[callbackOnTagRemoved] response: ', response);
          const { message, success } = response.data;
          // update all tasks that have this tenant.
          const isThisCustomer = task => task.TenantId === customerId;
          const tasks = R.filter(isThisCustomer, requests);
          log('[callbackOnTagPicked] tasks: ', tasks);
          const updatedTasks = [];
          let updatedTask = request;
          tasks.forEach((t) => {
            if (t._id === request._id) {
              updatedTask = {
                ...t,
                lookupTenant: {
                  ...t.lookupTenant,
                  Tags: removeTagFromTenant(t.lookupTenant.Tags)
                }
              };
            }
            updatedTasks.push({
              ...t,
              lookupTenant: {
                ...t.lookupTenant,
                Tags: removeTagFromTenant(t.lookupTenant.Tags)
              }
            });
          });
          if (typeof updateTaskCallback !== 'undefined') {
            updateTaskCallback(updatedTask);
          }
          dispatch({
            type: 'ACTION_UPDATE_REQUESTS', 
            requests: updatedTasks
          });
          dispatch({
            type: 'ACTION_SET_SNACK_MSG', 
            snackMessage: {
              message: message,
              level: success ? 'success' : 'error',
              duration: 5000
            }
          }); 
          dispatch({
            type: 'ACTION_SET_IS_LOADING_MODULE',
            module: 'tagCustomer',
            loading: false
          });
        })
        .catch((error) => {
          log('[callbackOnTagRemoved] error: ', error);
          dispatch({
            type: 'ACTION_SET_SNACK_MSG', 
            snackMessage: {
              message: error.response ? error.response.data : 'An error occured. Please try again',
              level: 'error',
              duration: 5000
            }
          }); 
        });
    } catch (e) {
      log('[callbackOnTagRemoved] e: ', e);
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: 'Connection error. Please try again.',
          level: 'error',
          duration: 5000
        }
      }); 
    }
  };

  // stop live feed while editing a tag
  const callbackOnShowTagsPicker = () => {
    log('[callbackOnShowTagsPicker]...');
    // Stop auto-refreshing feed
    dispatch({
      type: 'ACTION_SET_FEED_LIVE',
      isFeedLive: false
    });
    dispatch({
      type: 'ACTION_SET_BILLING_LIVE',
      isBillingLive: false
    });
    // Stop auto-refreshing credits
    // TODO: To deprecate - replace with ACTION_SET_BILLING_LIVE
    dispatch({
      type: 'ACTION_SET_BILLING', 
      billing: {
        autorefresh: false
      }
    });
  };

  // resume live feed while editing a tag
  const callbackOnHideTagsPicker = () => {
    log('[callbackOnHideTagsPicker]...');
    dispatch({
      type: 'ACTION_SET_FEED_LIVE',
      isFeedLive: true
    });
    // restart auto-refreshing credits
    dispatch({
      type: 'ACTION_SET_BILLING', 
      billing: {
        autorefresh: true
      }
    });
  };

  const handleRowMouseEnter = (e) => {
    // log('[feedRow] mouseEnter');
    e.stopPropagation();
    e.preventDefault();
    if (!readonly && isFeedLive) {
      setHover(true);
    }
  };

  const handleRowMouseLeave = (e) => {
    // log('[feedRow] mouseLeave');
    e.stopPropagation();
    e.preventDefault();
    if (!readonly && isFeedLive) {
      setHover(false);
    }
  };

  // console.log('[FeedRow][render] props: ', props);

  return (
    [
      /** MOBILE VERSION **/
      <Hidden
        key='tableMobile'
        mdUp
      >
        <Grid
          className={classes.mobileFeedItem}
          item
          onClick={!readonly ? goToRequest(request._id, history) : null}
        >
          <Grid
            className={classes.mobileRowCtr}
            container
            direction='row'
            justifyContent="space-between"
          >
            <Grid
              className={classes.mobileLeftCol}
              item
            >
              <div className={classes.topTitleCtr}>
                
                  {
                    !readonly // only show unread badge in the feed, not in the task detail page.
                    && typeof request.UnreadCount === 'number' && request.UnreadCount > 0
                    ? <div className={classes.mobileIssueTitle}><UnreadBadge /></div>
                    : null
                  }
                
                <div className={classNames(classes.textOverflow, classes.editableName)}
                  onClick={(e) => {e.stopPropagation(); setOpenEditTenantDialog(true);}}
                >
                  { 
                    request.lookupTenant && request.lookupTenant.FirstName && request.lookupTenant.FirstName !== 'Guest' 
                    ? request.lookupTenant.LastName
                      ? request.lookupTenant.FirstName + ' ' + request.lookupTenant.LastName
                      : request.lookupTenant.FirstName
                    : formatPhoneNumberForFrontend(request.From) 
                  }
                  <span><EditIcon className={classes.editIcon} /></span>
                </div>
              </div>
              <div className={classes.mobileIssueDesc}>

              {
                  request.lookupTenant
                  ? <TagRenderer
                      callbackOnHideTagsPicker={callbackOnHideTagsPicker}
                      callbackOnShowTagsPicker={callbackOnShowTagsPicker}
                      callbackOnTagPicked={callbackOnTagPicked(request.lookupTenant._id, request)}
                      callbackOnTagRemoved={callbackOnTagRemoved(request.lookupTenant._id)}
                      tagsIds={request.lookupTenant.Tags}
                    />
                  : null
                }
              </div>
              {/* <div className={classes.mobileIssueDesc}>
              {
                unit !== null
                ? `${getPropertyAdressNFloor(unit)}, ${getApartmentUnit(unit)}`
                : null
              }
              </div> */}
              {/* <RequestStatusBar 
                hover={hover}
                status={request.Status}
              /> */}
            </Grid>
            <Grid
              className={classes.itemRightCol}
              item
            >
              <div className={classes.mobileTopRight}>
                {
                  request.UpdatedDt
                  ? moment().diff(request.UpdatedDt, 'days') > 30
                    ? moment(request.UpdatedDt).format('L')
                    : moment(request.UpdatedDt).from(moment())
                  : moment().diff(request.Date, 'days') > 30
                    ? moment(request.Date).format('L')
                    : moment(request.Date).from(moment())
                }
              </div>
              <div className={classes.rightColTitle}>
                {/* {
                  request.Status === 'INFO'
                  ? <CallMadeIcon className={classes.frequencyArrow}/>
                  : <CallReceivedIcon className={classes.frequencyArrow}/>
                } */}
                {/* <span>{request.RequestIds.length}</span> */}
                {
                  typeof request.Assignee !== 'undefined'
                  && request.Assignee !== null
                  && typeof request.Assignee._id !== 'undefined'
                  && typeof request.Assignee.FirstName !== 'undefined'
                  && typeof request.Assignee.LastName !== 'undefined'
                  ? 
                    <Tooltip
                      aria-label={`${request.Assignee.FirstName} ${request.Assignee.LastName}`}
                      title={`${request.Assignee.FirstName} ${request.Assignee.LastName}`} 
                    >
                      <Chip
                        className={classNames(
                          classes.assigneeChip,
                          classes.mobileAssigneeChip
                        )}
                        label= {`${request.Assignee.FirstName.charAt(0)}${request.Assignee.LastName.charAt(0)}`}
                      />
                    </Tooltip>
                  : null
                }
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>,
      /** DESKTOP VERSION **/
      <Hidden
        key='tableDesktop'
        smDown
      >
        <TableRow 
          className={classNames(
            classes.feedTableRow,
            // `${Math.floor(Math.random() * 2)  === 0 ? 'hover' : ''}`,
            hover ? 'hover' : null,
            !readonly ? classes.cursorPointer : null
          )}
          key={key}
          onClick={!readonly ? goToRequest(request._id, history) : null}
          onMouseEnter={handleRowMouseEnter}
          onMouseLeave={handleRowMouseLeave}
        >
          <TableCell
            className={classNames(classes.cell, classes.customerNameCell, classes.clickableCellMargin)}
            component="td" 
            scope="row"
          >
            <div
              className={classNames(
                classes.unreadBadgeCtr,
                !readonly && typeof request.UnreadCount === 'number' && request.UnreadCount > 0 ? classes.unreadBadgeShowing : null
              )}
            >
            {
              !readonly // only show unread badge in the feed, not in the task detail page.
              && typeof request.UnreadCount === 'number' && request.UnreadCount > 0
              ? <UnreadBadge />
              : <span style={{display: 'block', width: 18}} />
            }
            </div>
            <div
                className={classes.editTenantCtr}
                onClick={(e) => {e.stopPropagation(); setOpenEditTenantDialog(true);}}
            >
              <span
                className={classNames(
                  classes.textOverflow,
                  classes.messageHeader
                )}
              >
              { 
                request.lookupTenant && request.lookupTenant.FirstName && request.lookupTenant.FirstName !== 'Guest' 
                ? request.lookupTenant.LastName
                  ? request.lookupTenant.FirstName + ' ' + request.lookupTenant.LastName
                  : request.lookupTenant.FirstName
                : formatPhoneNumberForFrontend(request.From) 
              }
              </span>
              {
                hover || readonly
                ? <span><EditIcon className={classes.editIcon} /></span>
                : null
              }
            </div>
          </TableCell>
          <TableCell
            className={classNames(classes.cell, classes.tagCell, classes.clickableCellMargin)}
          >
            {
              request.lookupTenant
              ? <TagRenderer
                  callbackOnHideTagsPicker={callbackOnHideTagsPicker}
                  callbackOnShowTagsPicker={callbackOnShowTagsPicker}
                  callbackOnTagPicked={callbackOnTagPicked(request.lookupTenant._id, request)}
                  callbackOnTagRemoved={callbackOnTagRemoved(request.lookupTenant._id)}
                  tagsIds={request.lookupTenant.Tags}
                />
              : null
            }
          </TableCell>
          <TableCell
            align='left'
            className={classNames(classes.cell, classes.requestCell)}
          >
            <Tooltip 
              aria-label={request.Issue}
              classes={{tooltip: classes.taskNameTooltip}}
              placement='top'
              title={request.Issue && request.Issue.length > 50 ? request.Issue : ''}
            >
              <span className={classNames(
                classes.textOverflow,
                classes.messagePreview
              )}
              >
                {request.Issue}
              </span>
              {/* <ForwardRef
                style={{width: '100%', height: 32, maxWidth: 300}}
                onClick={(e) => e.stopPropagation()}>
                <TextEditableOnHover
                  classes={classes}
                  handleSaveAction={handleRenameTask(request._id)}
                  id={`task-name-${request._id}`}
                  text={request.Issue}
                />
              </ForwardRef> */}
            </Tooltip>
          </TableCell>
          {
              typeof request.Assignee !== 'undefined'
              && request.Assignee !== null
              && typeof request.Assignee._id !== 'undefined'
              && typeof request.Assignee.FirstName !== 'undefined'
              && typeof request.Assignee.LastName !== 'undefined'
              ? 
              <TableCell
                align='left'
                className={classNames(classes.cell, classes.assigneeCell)}
              >
                <span className={classes.textOverflow}>{`${request.Assignee.FirstName} ${request.Assignee.LastName}`}</span>
              </TableCell>
              : <TableCell
                align='left'
                className={classNames(classes.cell, classes.assigneeCell)}
                >
                <span className={classes.textOverflow}>{''}</span>
              </TableCell>
            }
          {/* <TableCell
            align='left'
            className={classNames(
              classes.cell, 
              classes.statusCell
            )}
          >
            <RequestStatusBar 
              hover={hover}
              status={request.Status}
            />
          </TableCell> */}
          <TableCell
            align='right'
            className={classNames(
              // 'underActionCell',
              // 'oneBtn',
              'hideOnRowHover',
              'lastChild',
              classes.cell, 
              classes.dateCell)}
          >
            {
              request.UpdatedDt
              ? moment().diff(request.UpdatedDt, 'days') > 1
                ? moment(request.UpdatedDt).format('MMM D')
                : moment(request.UpdatedDt).from(moment())
              : moment().diff(request.Date, 'days') > 1
                ? moment(request.Date).format('MMM D')
                : moment(request.Date).from(moment())
            }
          </TableCell>
          
          {
            !readonly
            ? <TableCell
              align='right'
              className={classNames(
                classes.cell, 
                classes.actionCell, 
                'actionCell',
                'lastChild'
                // request.Status.toLowerCase() === REF_STATUSES.INFO ? classes.broadcastActionCell : null
              )}
              >
                {
                  request.Status.toLowerCase() !== REF_STATUSES.INFO
                  ? <Tooltip 
                      aria-label={'Assign'}
                      title={'Assign'} 
                    >
                    {
                      isLoadingAssignTask
                      ? <CircularProgress
                        classes={{
                          root: classNames(
                            classes.iconSpinner,
                            classes.rowActionBtn
                          )
                        }}
                        onClick={(e) => e.stopPropagation()}
                        size={theme.layout.listTable.rowHeight}
                        />
                      : <IconButton 
                          aria-label="Assign" 
                          classes={{
                            root: classes.rowActionBtn
                          }}
                          onClick={!readonly ? triggerOpenAssignTaskDialog(request) : null}
                        >
                        <i className={classNames(classes.icon, 'icon-assign')} />
                      </IconButton>
                    }
                    </Tooltip>
                  : null
                }
              </TableCell>
            : null
          }
        </TableRow>
      </Hidden>,
      <EditTenantDialog
        currTenant={request.lookupTenant}
        key='editTenant'
        open={openEditTenantDialog}
        setOpen={setOpenEditTenantDialog}
        triggerAction={handleEditTenant}
      />
    ]
  );
};

const combinedStyles = combineStyles(
  listTableStyles,
  styles,
  FeedTableStyles,
  FeedMobileStyles,
  commonFormElementsStyles
);

export default withStyles(combinedStyles)(FeedRow);