// @flow

import React from 'react';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';

import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';

import HeaderTopBar from '../HeaderTopBar';

import { withStyles } from '@material-ui/core/styles';
import styles from '../Feed/Styles/MemberHeader.Style';


type Props = {
  classes: Object,
  mobileOpen: bool, 
  usehandleDrawerToggle: Function,
};


const BillingHeader = (props: Props) => {
  log('[BillingHeader] props: ', props);
  const {
    classes,
    usehandleDrawerToggle
  } = props;

  const [store] = useStore();
  const {
    user
  } = store;

  return (
    <AppBar 
      className={classes.appBar}
      position="fixed"
    >
      <Toolbar
        className={classes.toolbar}
        disableGutters
      >
        <Grid 
          container
          direction='column'
        >
          <HeaderTopBar
            usehandleDrawerToggle={usehandleDrawerToggle}
          >
            <Typography 
              className={classes.pageTitle}
              variant='h3'
            >
              {'Account > '}
              <a href='/billing'>{'Billing'}</a>
            </Typography>
          </HeaderTopBar>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(BillingHeader);
