// @flow

const styles = (theme: Object) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    backgroundColor: 'white'
  },
  setupMeeting: {
    minWidth: 400,
    minHeight: 300,
    zIndex: 99999,
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '1.2rem',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6)
  }
});

export default styles;