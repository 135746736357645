// @flow

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useParams, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import {
  log,
  isObjectEmpty,
  REGEX_PATTERNS,
  formatPhoneNumberForFrontend,
  formatPhoneNumberForBackend
} from '../../utils/jsUtils';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import SPBackend from '../../services/SPBackend';
import { useUserAuth } from '../Auth/UserSession';

import Logo from '../../assets/askneo-favicon.png';
import { withStyles } from '@material-ui/core/styles';
import combineStyles from '../../utils/stylesUtils';
import signupStyles from './Styles/Signup.Style';
import styles from './Styles/StaffInvitationHome.Style';

type Props = {
  classes: Object
}
const StaffInvitationHome = (props: Props) => {
  // form setup
  const { register, handleSubmit, errors, control, setValue, watch } = useForm({
    defaultValues: {
      FirstName: 'Loading...',
      LastName: 'Loading...',
      Email: 'Loading...',
      Phone: 'Loading...'
    }
  });

  const phone = watch('Phone', '');

  // path params
  const { classes } = props;
  const location = useLocation();
  let { inviteToken } = useParams();


  // auth hooks
  const {
    authenticate,
    email,
    setEmail,
    setPassword,
    loggedIn
  } = useUserAuth();

  // default redirect after successful login
  const { from } = location.state || { from: { pathname: '/member' } };

  // state vars
  const [submitBtnLabel, setSubmitBtnLabel] = useState('Accept invite');
  const [invite, setInvite] = useState({});
  const [companyName, setCompanyName] = useState('');
  const [serverError, setServerError] = useState('');
  const [registrationLoading, setRegistrationLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const acceptInvite = (data) => {
    setRegistrationLoading(true);
    setServerError('');
    console.log('accepting invite for data: ', data);
    const phoneNumber = data.phone;
    delete data.phone;
    SPBackend.acceptStaffInvite(inviteToken, {
      ...data,
      Email: email,
      Phone: formatPhoneNumberForBackend(phoneNumber)
    })
      .then((response) => {
        setSubmitBtnLabel('Welcome!');
        log('[acceptStaffInvite] response: ', response);
        authenticate();
        setRegistrationLoading(false);
      })
      .catch((e) => {
        log('[acceptStaffInvite] error: ', e);
        const { response } = e;
        if (typeof response !== 'undefined') {
          setServerError(response.data);
        } else {
          setServerError('Connection issue. Please try again or contact us if the issue persists.');
        }
        setRegistrationLoading(false);
      });
  };

  useEffect(() => {
    if (inviteToken) {
      setTimeout(() => {
        log('[getStaffInvite]...');
        SPBackend.getStaffInvite(inviteToken)
          .then((response) => {
            const { Invite, User, CompanyName } = response.data;
            log('[getStaffInvite] data: ', response.data);
            setInvite(Invite);
            setValue('FirstName', User.FirstName);
            setValue('LastName', User.LastName);
            setValue('Email', User.Email);
            setEmail(User.Email || '');
            log('[getStaffInvite] User.phone: ', User.Phone, formatPhoneNumberForFrontend(User.Phone));
            setValue('phone', formatPhoneNumberForFrontend(User.Phone));
            setCompanyName(CompanyName);
          })
          .catch((e) => {
            log('[getStaffInvite] error: ', JSON.stringify(e), e);
            const { response } = e;
            if (typeof response !== 'undefined') {
              setServerError(response.data);
            } else {
              setServerError('Connection issue. Please try again or contact us if the issue persists.');
            }
          });
        }, 400);
    }
  }, [inviteToken]);

  return (
    <Grid
      className={classes.contentCtr}
      container
    >
      <Helmet>
        <title>Neo | Invite Staff</title>
      </Helmet>
      {
        loggedIn ? <Redirect push
                            to={from}
                   /> : null
      }
      <Grid
        className={classes.headerLogo}
        item
        xs={12}
      ><a href="/">
        <img
          height={40}
          src={Logo}
        /></a></Grid>
      {
        isObjectEmpty(invite)
        ? serverError === ''
          ? <Grid
              item
              xs={12}
            >{'Loading your invitation...'}</Grid>
          : <Grid
              className={classes.errorLabel}
              item
              xs={12}
            >
              { serverError }
            </Grid>
        : <Grid container>
        <Grid item
            xs={12}
        >
            <Typography
              className={classes.formTitle}
              variant={'h4'}
            >{`Your invitation to join the team at ${companyName}`}</Typography>
            <Typography
              className={classes.formTitle}
              variant={'body2'}
            >{'Let\'s setup your access.'}</Typography>
          </Grid>
            
          <Grid item
            xs={12}
          >
          {
            <form onSubmit={handleSubmit(acceptInvite)}>
              <FormControl
                className={classNames(classes.newInputForm)}
                variant='outlined'
              >
                  <InputLabel htmlFor='FirstName'>{'First Name'}</InputLabel>
                  <OutlinedInput
                    className={classNames(classes.newFormElt)}
                    inputRef={register({ required: true })}
                    label='Outlined'
                    name='FirstName'
                    type='text'
                    variant='outlined'
                  />
                </FormControl>
                <div className={classes.errorLabel}>{ errors.FirstName && 'First name is required.' }</div>
                <FormControl
                  className={classNames(classes.newInputForm)}
                  variant='outlined'
                >
                  <InputLabel htmlFor='LastName'>{'Last Name'}</InputLabel>
                  <OutlinedInput
                    className={classNames(classes.newFormElt)}
                    inputRef={register({ required: true })}
                    name='LastName'
                    type='text'
                  />
                </FormControl>
                <div className={classes.errorLabel}>{ errors.LastName && 'Last name is required.' }</div>
                
                <FormControl
                  className={classNames(classes.newInputForm)}
                  variant='outlined'
                >
                  <InputLabel htmlFor='Email'>{'Email'}</InputLabel>
                  <OutlinedInput
                    autoComplete='Email'
                    className={classNames(classes.newFormElt)}
                    disabled={invite.Channel === 'email' ? true : false}
                    inputRef={register({ required: true, pattern: REGEX_PATTERNS.EMAIL })}
                    name='Email'
                    onChange={(e) => setEmail(e.target.value)}
                    type='Email'
                  />
                </FormControl>
                <div className={classes.errorLabel}>
                  { errors.Email && 'Valid Email is required.' }
                </div>

                <FormControl
                  className={classNames(classes.newInputForm, classes.phoneContainer)}
                  variant='outlined'
                >
                  {
                    phone !== ''
                    ? <label
                        className='MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-filled' 
                        data-shrink='true'
                        htmlFor='Phone'
                      >{'Phone'}</label>
                    : null
                  }
                  <Controller
                    control={control} 
                    name='phone'
                    render={({ onChange, value }) => (
                      <PhoneInput
                        className={classNames(classes.phoneInputCtr, 'defaultInput')}
                        defaultCountry='US'
                        disabled={invite.Channel === 'sms' ? true : false}
                        onChange={(e) => {
                          onChange(e);
                          
                        }}
                        placeholder='Phone number' 
                        value={value} 
                      />
                    )}
                    rules={{ required: true }}
                  />
                </FormControl>
                <div className={classes.errorLabel}>{ errors.phone && 'Phone number is required.' }</div>
    
                <FormControl
                  className={classNames(classes.newInputForm)}
                  variant='outlined'
                >
                  <InputLabel htmlFor='Password'>{'Password'}</InputLabel>
                  <OutlinedInput
                    className={classNames(classes.newFormElt)}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={e => e.preventDefault()}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    inputRef={register({ required: true, minLength: 6 })}
                    name='Password'
                    onChange={(e) => setPassword(e.target.value)}
                    type={showPassword ? 'text' : 'Password'}
                  />
                </FormControl>
                <div className={classes.errorLabel}>
                  { errors.Password && errors.Password.type === 'required' && 'Password is required.' }
                  { errors.Password && errors.Password.type === 'minLength' && 'Password must be at least 6 digits or characters.' }
                </div>
                
                <div className={classes.errorLabel}>
                  { errors.agreed && 'Agreement to Terms & Conditions is required.' }
                  { serverError }
                </div>
                {/* <FormHelperText>You can display an error</FormHelperText> */}
                <Button
                  className={classes.signupBtn}
                  color='primary'
                  disabled={registrationLoading || !isObjectEmpty(errors)}
                  type='submit'
                  variant='contained'
                >
                  {submitBtnLabel}
                </Button>
                <p>
                  {`For security reasons, each invite can only be accepted by the same ${invite.Channel === 'email' ? 'email' : 'phone number'} it was sent to.`}<br />
                  {`To register with another  ${invite.Channel === 'email' ? 'email' : 'phone number'}, ask your team admin to send a new invite.`}
                </p>
              </form>
            }
            </Grid>
          </Grid>
      }

    </Grid>
  );
};

const combinedStyles = combineStyles(
  signupStyles,
  styles
);

export default withStyles(combinedStyles)(StaffInvitationHome);