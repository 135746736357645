// @flow

const styles = (theme: Object) => ({
  toolbarHalf: theme.layout.customProps.toolbarHalf,
  toolbar: theme.layout.customProps.toolbar,
  capitalize: {
    textTransform: 'capitalize'
  }
});

export default styles;