// @flow

const COL_ADDRESS_WIDTH = 400;
const COL_EMAIL_WIDTH = 450;
const COL_PHONE_WIDTH = 200;
const COL_OTHER_WIDTH = 252;
const COL_PROP_WIDTH = 100;
const COL_TAGS_WIDTH = 100;
const COL_ACTION_WIDTH = 245;
const TABLE_MIN_WIDTH = 700;

const styles = (theme: Object) => ({
  colAction: {
    width: COL_ACTION_WIDTH
  },
  colProps: {
    width: COL_PROP_WIDTH
  },
  colTags: {
    width: COL_TAGS_WIDTH
  },
  addressCell: {
    flex: '1 1 auto',
    flexBasis: COL_ADDRESS_WIDTH,
    maxWidth: COL_ADDRESS_WIDTH,
    [theme.breakpoints.down('sm')]: {
      flexBasis: 400,
      maxWidth: 200,
      overflow: 'auto',
      overflowY: 'hidden'
    }
  },
  emailCell: {
    flex: '1 1 auto',
    flexBasis: COL_EMAIL_WIDTH,
    maxWidth: COL_EMAIL_WIDTH,
    [theme.breakpoints.down('sm')]: {
      // flex: '1 1 auto',
      flexBasis: 350,
      maxWidth:  190,
      overflowX: 'scroll',
      overflowY: 'hidden'
    }
  },
  phoneCell: {
    flex: '1 1 auto',
    flexBasis: COL_PHONE_WIDTH,
    [theme.breakpoints.down('sm')]:{
      flexBasis: 300,
      maxWidth: 200,
      overflowX: 'scroll',
      overflowY: 'hidden'
    }
  },
  otherCellHeader: {
    flex: '1 1 auto',
    flexBasis: COL_OTHER_WIDTH,
    maxWidth: COL_OTHER_WIDTH,
    [theme.breakpoints.down('sm')]:{
      flexBasis: 170
    }
  },
  otherCell: {
    flex: '1 1 auto',
    flexBasis: COL_OTHER_WIDTH,
    maxWidth: COL_OTHER_WIDTH,
    [theme.breakpoints.down('sm')]:{
      flexBasis: 100,
      overflowX: 'scroll',
      overflowY: 'hidden'
    }
  },
  propertiesCell: {
    flex: '0 0 auto',
    minWidth: COL_PROP_WIDTH,
    maxWidth: COL_PROP_WIDTH
  },
  tagsCell: {
    flex: '0 0 auto',
    minWidth: COL_TAGS_WIDTH,
    maxWidth: COL_TAGS_WIDTH
  },
  actionCell: {
    flex: '0 0 auto',
    minWidth: theme.shape.borderRadius,
    paddingRight: 0,
    paddingLeft: 0,
    width: 'auto'
  },
  simpleMessage: {
    transform: 'translate(7%,18%)'
    
  },
  staffTableContent:{
    flexGrow: 1,
    padding: theme.spacing(3),
    width: '100%',
    minWidth: TABLE_MIN_WIDTH,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      minWidth: 0
    }
  },
  customTableStyles: {
    '& .underActionCell': {
      flex: '0 0 auto',
      minWidth: COL_ACTION_WIDTH,
      maxWidth: COL_ACTION_WIDTH,
      paddingRight: 0,
      paddingLeft: 0,
      width: 'auto',
      [theme.breakpoints.down('sm')]:{
        minWidth: '90px'
      }
    },
    '& .hover .underActionCell.oneBtn': {
      flex: '1 1 auto',
      minWidth: COL_ACTION_WIDTH - (theme.layout.listTable.rowHeight * 1),
      maxWidth: COL_ACTION_WIDTH - (theme.layout.listTable.rowHeight * 1),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 'auto'
    },
    '& .hover .underActionCell.twoBtn': {
      flex: '1 1 auto',
      minWidth: COL_ACTION_WIDTH - (theme.layout.listTable.rowHeight * 2),
      maxWidth: COL_ACTION_WIDTH - (theme.layout.listTable.rowHeight * 2),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 'auto'
    }
  },
  inlineSpan: {
    marginLeft: theme.spacing(1),
    position: 'relative',
    top: 2
  },
  inlineBtn: {
    padding: 0,
    marginLeft: theme.spacing(1)
  }
});

export default styles;