// @flow

import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './css/index.css';
import './css/squareplan-embedded.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

const root = document.getElementById('root');

if (root != null) {
  render(
    <BrowserRouter>
      <App />
    </BrowserRouter>, 
    root 
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
