/* eslint-disable react/jsx-sort-props */
// @flow

import React, {useEffect, useState} from 'react';
import { useStore } from '../../store';
import moment from 'moment';
import { log } from '../../utils/jsUtils';
import SPBackend from '../../services/SPBackend';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/SimpleMsgDialog.Style';

type Props = {
  dialogTitle: Object,
  dialogContent: Object,
  dialogActions: Object,
  open: Boolean,
  classes: Object
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up"
ref={ref}
{...props}
         />;
});

// eslint-disable-next-line react/no-multi-comp
const SimpleMsgDialog = (props: Props) => {
  const {
    classes,
    dialogTitle,
    dialogContent,
    dialogActions,
    open
  } = props;

  return (
      <Dialog
        aria-labelledby='form-assign-task'
        BackdropProps={{
          classes: {
            root: classes.backdrop
          }
        }}
        classes={{
          paper: classes.dialogRoot
        }}
        open={open}
        TransitionComponent={Transition}
      >
        <DialogTitle
         className={classes.title}
        >{dialogTitle}</DialogTitle>
        
          <DialogContent
            className={classes.content}
          >
            {dialogContent}
          </DialogContent>
          {
            dialogActions
            ? <DialogActions
                className={classes.actions}
              >
                {dialogActions}
              </DialogActions>
            : null
          }
          
      </Dialog>
  );
};

export default withStyles(styles)(SimpleMsgDialog);
