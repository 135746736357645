// @flow

import React, { useState, useEffect } from 'react';
import { useStore } from '../../store';
import classNames from 'classnames';
import { TwitterPicker } from 'react-color';
import { log } from '../../utils/jsUtils';
import Downshift from 'downshift';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';

import Popper from '@material-ui/core/Popper';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import TagReadonly from '../SharedComponents/TagReadonly';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/AddTagDialog.Style';
import SPBackend from '../../services/SPBackend';
import AutocompleteProperty from '../Properties/AutocompleteProperty';
import type { Unit } from '../../services/SPBackend';

type Props = {
  handleEditTenant: Function, // if prop missing, component switches in create tenant only mode.
  classes: Object,
  setOpen: Function,
  open: boolean,
  triggerAction: Function,
  unit: Unit
};

// eslint-disable-next-line react/no-multi-comp
const AddTagDialog = (props: Props) => {
  const {
    classes,
    setOpen,
    open,
    triggerAction
  } = props;

  const [store] = useStore();
  const {
    i18n,
    user
  } = store;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tag, setTag] = useState({
    _id: null,
    Color: '#CCCCCC',
    Name: ''
  });
  const [newTag, setNewTag] = useState({
    Color: '#CCCCCC',
    Name: ''
  });


  const emptyForm = () => {
    setNewTag({
      ...newTag,
      Color: '#CCCCCC',
      Name: ''
    });
  };

  const handleColorPicked = (color, event) => {
    log('[handleColorPicked]... color: ', color);
    log('[handleColorPicked]... tag: ', tag);
    if (typeof tag != 'undefined' && tag !== null) {
      // toggle picker
      setNewTag({
        ...newTag,
        Color: color.hex
      });
      setDisplayColorPicker(false);
      setAnchorEl(null);
    }
  };

  const handleColorPickerClick = (tag, event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const openColorPicker = Boolean(anchorEl);
  const colorPickerId = openColorPicker ? 'simple-popper' : undefined;
  
  log('[render] newTag: ', newTag);
  return (
    <Dialog aria-labelledby='form-add-unit'
      disableEnforceFocus
      onClose={() => {setOpen(false); emptyForm();}}
      open={open}
    >
      <form
        autoComplete='off'
        className={classNames(
          classes.dialogFormCtr,
        )}
        noValidate
        onSubmit={e => {
          e.preventDefault();
          setLoading(true);
          triggerAction(newTag,
            () => {
              setLoading(false);
              setOpen(false);
              emptyForm();
            },
            () => {
              setLoading(false);
            }
          );
        }}
      >
        <DialogTitle id='form-dialog-title'>{`Add a new ${i18n.t('tag')}`}</DialogTitle>
        <DialogContent className={classNames(classes.grow, classes.flexContainer)}>

          {
            newTag && newTag.Name !== ''
            ? <div>
              {'Your new tag will look like this:'}<br />
              <TagReadonly
                readonly
                renderTags={[newTag]}
              />
            </div>
            : null
          }

          <div>
            <TextField
                className={classes.margin}
                id="input-with-icon-textfield"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div className={classes.tagCtr}>
                        <div 
                          className={classes.colorPickerRainbow}
                          onClick={(e) => handleColorPickerClick(newTag, e)} 
                        />
                      </div>
                    </InputAdornment>
                  ),
                }}
                label='Tag Name'
                onChange={(e) => setNewTag({
                  ...newTag,
                  Name: e.target.value
                })}
                value={newTag.Name}
            />
            <Popper anchorEl={anchorEl}
              className={classes.popperCtr}
              // disableAutoFocus
              // disableEnforceFocus
              id={colorPickerId}
              open={openColorPicker}
              placement='bottom'
            >
              <TwitterPicker onChangeComplete={handleColorPicked} />
            </Popper>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            onClick={(e) => {e.stopPropagation(); e.preventDefault(); setOpen(false); emptyForm();}}
          >
            {'Cancel'}
          </Button>
          <Button
            color='primary'
            disabled={loading}
            type='submit'
            variant='contained'
          >
            {`Add ${i18n.t('tag')}`}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(AddTagDialog);
