// @flow

const styles = (theme: Object) => ({
  textAreaCtr: {
    display: 'flex',
    flexDirection: 'column'
  },
  simpleBorder: {
    boxShadow: 'none',
    border: `1px solid ${theme.palette.border.color}`
  },
  toolbarCtr: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // borderTop: `1px solid ${theme.palette.border.color}`,
    borderBottom: `1px solid ${theme.palette.border.color}`,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    '& button': {
      textTransform: 'capitalize'
    }
  },
  grow: {
    flexGrow: 1
  },
  squareTheme: {
    borderRadius: 0
  },
  menuList: {
    zIndex: 9999
  },
  menuTitle: {
    color: 'hsl(0,0%,70%)',
    fontWeight: 600,
    fontSize: '0.9rem',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: 'transparent'

    }
  },
  toolbarMenuBtn: {
    width: 'fit-content',
    height: '2rem',
    borderRadius: 0,
    textTransform: 'capitalize',
    border: '1px solid #5c6bc0',
    borderRadius: 6,
    fontWeight: 600,
    padding: `${theme.spacing(0.4)}px ${theme.spacing(1)}px`
  },
  error: {
    color: theme.palette.status.error.main,
    '& *': {
      color: theme.palette.status.error.main
    }
  },
  previewZone: {
    whiteSpace: 'pre-wrap',
    width: '100%',
    height: 225,
    padding: `${theme.spacing(2.7)}px ${theme.spacing(2.6)}px`,
    fontSize: '1rem',
    color: theme.palette.custom.disabledText,
    border: `1px solid ${theme.palette.border.color}`,
    overflow: 'scroll'
  },
  largeTextField: {
    width: '100%',
    height: 225,
    '& *': {
      borderRadius: 0
    },
    '& label': {
      transform: 'translate(20px, 24px) scale(1)'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: 0
    },
    '& .MuiOutlinedInput-notchedOutline legend': {
      display: 'none'
    },
    '& textarea': {
      padding: theme.spacing(1)
    },
    '& label.MuiInputLabel-shrink': {
      display: 'none',
      transform: 'translate(12px, -6px) scale(0.75)',
      fontSize: '1rem'
    }
  },
  secondInput: {
    marginTop: theme.spacing(2),
    '& *': {
      zIndex: 1
    }
  },
  toggleNavButtons: {
    '& button': {
      borderRadius: 0,
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`
    },
    '& button:not(:last-child)': {
      borderLeft: '1px solid rgba(0,0,0,0.12) !important'
    }
  },
  navBtnDeselected: {
    color: 'rgba(0, 0, 0, 0.38)',
    backgroundColor: 'transparent',
    fontWeight: 600,
    padding: '0px 24px',
    borderTop: '1px solid rgba(0,0,0,0.12) !important',
    borderRight: '1px solid rgba(0,0,0,0.12) !important',
    borderLeft: 0,
    borderBottom: 0
  },
  navBtnSelected: {
    '& span': {
      color: theme.palette.custom.neoViolet.main
    },
    backgroundColor: `${theme.palette.custom.neoYellow.main} !important`,
    fontWeight: 600,
    padding: '0px 24px',
    borderBottom: 0,
    borderTop: '1px solid rgba(0,0,0,0.12) !important',
    borderRight: '1px solid rgba(0,0,0,0.12) !important',
    borderLeft: '1px solid rgba(0,0,0,0.12) !important',
    borderBottom: 0
    // border: '1px solid rgba(0, 0, 0, 0.23)',
    // backgroundColor: 'transparent'
  }
});

export default styles;
