// @flow
import { phoneInputCss } from '../../../css/phoneInput';

const styles = (theme: Object) => ({
  headerLogo: {
    marginTop: 30,
    marginBottom: 30,
    textAlign: 'center'
  },
  headerConnectLogo: {
    display: 'flex',
    marginTop: 30,
    marginBottom: 30,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paperCtr: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4)
  },
  spacedPlus: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  formErrorResponse: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    fontSize: '1rem'
  },
  formSubmitBtnErrorResponse: {
    color: theme.palette.error.main,
    marginBottom: -theme.spacing(1),
    marginTop: theme.spacing(2),
    textAlign: 'center',
    fontSize: '1rem'
  },
  connectContentCtr: {
    position: 'relative',
    maxWidth: 600,
    paddingTop: 30,
    paddingLeft: 20,
    paddingRight: 20,
    margin: '0 auto',
    textAlign: 'left',
    zIndex: 0
  },
  contentCtr: {
    position: 'relative',
    maxWidth: 540,
    paddingTop: 30,
    paddingLeft: 20,
    paddingRight: 20,
    margin: '0 auto',
    textAlign: 'left',
    zIndex: 0
  },
  formTitle: {
    textAlign: 'center',
    marginBottom: '3rem'
  },
  inputForm: {
    backgroundColor: 'white',
    borderRadius: 0,
    padding: `${theme.spacing(2)}px 30px`,
    minWidth: '100%',
    textAlign: 'left',
    margin: 0,
    fontSize: '1rem',
    '&::placeholder': {
      color: '#a3c2d9'
    }
  },
  newInputForm: {
    width: '100%',
    borderRadius: 4,
    margin: '4px 0px',
    backgroundColor: 'white',
    '& input': {
      // padding: `${theme.spacing(3)}px 30px`,
      paddingTop: '1.5rem',
      paddingBottom: '1.5rem',
      // minWidth: '100%',
      textAlign: 'left',
      margin: 0,
      border: 0
    },
    '& .MuiInputLabel-outlined': {
      // transform: 'translate(30px, 26px) scale(1)',
      color: 'hsl(0,0%,20%)',
      background: 'white',
      padding: 5,
      fontSize: '1rem'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      // transform: 'translate(30px, 8px) scale(0.75)'
      color: theme.palette.primary.dark
    }
  },
  phoneContainer: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '& .react-phone-number-input__phone': {
      fontSize: '1rem',
      borderBottom: '0px !important'
    },
    '& .react-phone-number-input__row': {
      padding: '2px 14px 16px 14px'
    }
  },
  phoneInputCtr: {
    ...phoneInputCss
    // '& .PhoneInputCountry': {
    //   paddingTop: 16,
    //   paddingBottom: 16,
    //   border: '1px solid rgba(0, 0, 0, 0.23)',
    //   borderRadius: 4,
    //   display: 'flex',
    //   flexDirection: 'row',
    //   paddingLeft: 20,
    //   paddingRight: 20
    // },
    // '& .react-phone-number-input__input': {
    //   borderBottom: 0,
    //   border: 'none',
    //   outline: 'none',
    //   padding: '6px 0 7px',
    //   fontSize: '1rem',
    //   transition: 'border 0.1s',
    //   fontFamily: 'Product Sans',
    //   borderRadius: 0
    // },
    // '& .react-phone-number-input__input::placeholder': {
    //   color: 'rgba(0, 0, 0, 0.87)'
    // }
  },
  formElt: {
    borderTop: 0,
    borderRight: '1px #B4CEE1 solid',
    borderLeft: '1px #B4CEE1 solid',
    borderBottom: '1px #B4CEE1 solid',
    outline: 0,
    '&:first-child': {
      borderTop: '1px #B4CEE1 solid'
    }
  },
  newFormElt: {
    width: '100%',
    outline: 0,
    borderRadius: 'inherit'
  },
  checkboxCtr: {
    marginLeft: 1,
    '& span': {
      background: 'transparent',
      margin: 0,
      fontSize: '0.9rem'
    }
  },
  checkbox: {
    '& span': {
      margin: '4px 4px 4px 4px'
    }
  },
  errorLabel: {
    marginLeft: theme.spacing(0),
    marginBottom: theme.spacing(1),
    color: '#f44336 !important',
    textAlign: 'left'
  },
  signin: {
    width: '100%',
    borderRadius: 4,
    fontWeight: 600,
    padding: `${theme.spacing(2)}px 30px`
  },
  signupBtn: {
    marginTop: theme.spacing(2),
    width: '100%',
    margin: 4,
    borderRadius: 4,
    fontWeight: 600,
    padding: `${theme.spacing(2.5)}px 30px`
  },
  helpLink: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    textDecoration: 'underline',
    fontSize: '0.8rem'
  },
  captchaCtr: {
    marginLeft: 4
  }
});
export default styles;