/* eslint-disable react/jsx-sort-props */
// @flow

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/UnreadBadge.Style';

type Props = {
  classes: Object
}

const UnreadBadge = (props: Props) => {
  const {classes} = props;

  return (
      <div className={classes.badgeCtr} />
  );
};

export default withStyles(styles)(UnreadBadge);
