// @flow

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import * as R from 'ramda';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';
import useInterval from '../../hooks/useInterval';
import SPBackend from '../../services/SPBackend';
import MemberHeader from './MemberHeader';
import MemberContent from './MemberContent';
import MemberDrawer from '../MemberDrawer';
import { calculateCreditsForMsg } from '../../models/Message';
import * as Papa from 'papaparse';


import styles from './Styles/MemberHome.Style';

type Props = {
  classes: Object
};

const MemberHome = (props: Props) => {
  const {classes} = props;

  // drawer state & controls
  const [mobileOpen, setMobileOpen] = useState(false);
  // last refresh date
  const [feedInitialized, setFeedInitialized] = useState(false);
  // refresh the feed
  const [refresh, setRefresh] = useState(false);
  // empty this lastRefreshDtt to fetch the complete filtered feed
  const [lastRefreshDtt, setLastRefreshDtt] = useState('');
  // onboarding meeting popup
  const [showOnboardingPopup, setShowOnboardingPopup] = useState(false);
  // TODO: move this to the store once we surface date range in the UI.
  // Any date range filter change should disable the realtime mode.
  // const [isRealtimeFeed, setRealtimeFeed] = useState(true);
  
  const usehandleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const [store, dispatch] = useStore();
  const {
    filterSelections,
    filterSearchText,
    isDemoMode,
    requests,
    refreshFullFeed,
    isFeedLive,
    user,
    accountSettings,
    accountUsage
  } = store;
  /**
   * Auto-refresh every 10s.
   * Comment this to remove auto-refresh mode.
   */
  if (process.env.REACT_APP_FEED_AUTOREFRESH === 'true') {
    useInterval(() => {
      if (filterSelections.pageNb === 0 && isFeedLive) {
        log('[MemberHome] refreshing feed...');
        setRefresh(!refresh);
      }
    }, 5000);
  }

  /**
   * manual refresh of the entire feed.
   */
  useEffect(() => {
    // log('[MemberHome.refreshFullFeed]... ', refreshFullFeed);
    if (feedInitialized) {
      log('[MemberHome.refreshFullFeed]... lastRefreshDtt: ', lastRefreshDtt);
      // Empty last refresh date and trigger a new refresh
      if (lastRefreshDtt !== '') {
        setLastRefreshDtt('');
      }
      setRefresh(!refresh);
      setFeedInitialized(false);
      log('[MemberHome.refreshFullFeed] filterSelections: ', filterSelections);
    }
  }, [refreshFullFeed]);

  // Fetch Feed
  // TODO: try fetching with https://github.com/CharlesStover/fetch-suspense
  useEffect(() => {
    if (typeof user !== 'undefined' && user !== null) {
      log('[MemberHome.fetchFeed] useEffect.., lastRefreshDtt, feedInitialized, refresh', lastRefreshDtt, feedInitialized, refresh);
      // set filter start date as previous in-session fetch
      let filters = {
        ...filterSelections,
        startDatetime: '',
        endDatetime: ''
      };
      if (lastRefreshDtt !== '') {
        filters = {
          ...filterSelections,
          startDatetime: lastRefreshDtt
        };
      }
      setLastRefreshDtt(moment().toISOString());
      log('[MemberHome.fetchFeed] useEffect... filters: ', filters);
      if (!feedInitialized) {
        // when a full refresh of the feed is requested, then we force the startDateTime to be empty.
        filters.startDatetime = '';
        // only show the spinner when we init the feed.
        dispatch({
          type: 'ACTION_SET_IS_APP_LOADING',
          isAppLoading: true
        });
        dispatch({
          type: 'ACTION_SET_IS_LOADING_MODULE',
          module: 'feed',
          loading: true
        });
      }

      // get tags
      SPBackend.getTags('simple', user)
        .then(response => {
          const { data } = response;
          log('[MemberHome.getTags] tags: ', data);
        })
        .catch(e => {
          log('[MemberHome.getTags] e: ', e);
        });

      log('[MemberHome.fetchFeed] filters, filterSearchText, user: ', filters, filterSearchText, user);
      SPBackend.getFilteredFeed(filters, filterSearchText, user)
        .then((response) => {
          log('[MemberHome.fetchFeed]  getFilteredFeed filters: ', filters);
          log('[MemberHome.fetchFeed]  getFilteredFeed filters: ', filters);
          log('[MemberHome.fetchFeed]  getFilteredFeed response: ', response.data);
          const { Requests, Units, totalRequests } = response.data;
          log('[MemberHome.fetchFeed] Units:', Units);
          if (Requests && Units) {
            const byDate = R.descend(R.path(['Request', 'UpdatedDt']));
            // Default sort requests by date desc
            if (!feedInitialized) {
              dispatch({
                type: 'ACTION_SET_REQUESTS', 
                requests: R.sort(byDate, Requests),
                totalRequests
              });
              dispatch({                           
                type: 'ACTION_SET_UNITS',
                units: Units
              });
              setFeedInitialized(true);

              if (typeof totalRequests === 'undefined' || totalRequests === 0) {
                // stop auto-refresh if there is no request at first initial fetch
                dispatch({
                  type: 'ACTION_SET_FEED_LIVE',
                  isFeedLive: false
                });
              }
            } else if (Requests !== null && Requests.length > 0) {
              log('[MemberHome.fetchFeed] sending ACTION_ADD_OR_UPDATE_REQUESTS requests: ', Requests);
              dispatch({
                type: 'ACTION_ADD_OR_UPDATE_REQUESTS', 
                requests: Requests,
                totalRequests
              });
            }
          }
          dispatch({
            type: 'ACTION_SET_IS_LOADING_MODULE',
            module: 'feed',
            loading: false
          });
          dispatch({
            type: 'ACTION_SET_IS_APP_LOADING',
            isAppLoading: false
          });
        })
        .catch(error => {
          log('[MemberHome.fetchFeed.getFeed] error: ', error);
          dispatch({
            type: 'ACTION_SET_IS_APP_LOADING',
            isAppLoading: false
          });
        });
      // log('[MemberHome] FilteredFeed: ', units);
    }
    
  }, [user, refresh]);

  // Fetch Filter Options
  useEffect(() => {
    if (user !== null) {
      // Fetch requests feed
      SPBackend.getFilterOptions(user)
      .then((response) => {
        const {propertyOptions, staffOptions, tenantOptions, tagOptions, unreadMessagesOnlyOptions} = response.data;
        log('[MemberHome.getFilterOptions] response: ', tagOptions);
        dispatch({
          type: 'ACTION_SET_FILTER_OPTIONS', 
          propertyOptions,
          staffOptions,
          tenantOptions,
          tagOptions,
          unreadMessagesOnlyOptions
        });
      })
      .catch(error => {
        log('[MemberHome.getFilterOptions] error: ', error);
        dispatch({
          type: 'ACTION_SET_IS_APP_LOADING',
          isAppLoading: false
        });
      });
    }
  }, [user]);

  const sendBroadcast = (toTenantsIds, message, successCallback, failureCallback) => {
    // log('[sendBroadcast] for properties: ', propertyKeys);
    log('[sendBroadcast] toTenantsIds: ', toTenantsIds);
    log('[sendBroadcast] message: ', message);
    
    // verify that credit balance is sufficient
    const creditsNeeded = calculateCreditsForMsg(message);
    log('[sendBroadcast] creditsNeeded: ', creditsNeeded);
    if (accountUsage.TotalCreditsBalance < toTenantsIds.length * creditsNeeded) {
      failureCallback(`You'll need ${toTenantsIds.length * creditsNeeded} credits to send this message.
<br />Visit <a href="/billing">Billing</a> to <b>add <u>${toTenantsIds.length * creditsNeeded - accountUsage.TotalCreditsBalance} credits</u></b> to your account.
<br />Pro tip: plan a few extra credits for the replies.`);
    } else {
      SPBackend.sendPMToTenants(
        toTenantsIds, 
        message,
        user)
        .then(response => {
          log('[sendBroadcast.sendPMToTenants] response: ', response);

          // TODO: retrieve the list of new tasks created and display them.
          // const { message, task: updatedTask } = response.data;
          // // update requests with the new request
          // requests.unshift(updatedTask);
          // dispatch({
          //   type: 'ACTION_SET_REQUESTS', 
          //   requests: requests
          // });

          // refresh full feed
          setLastRefreshDtt('');
          setRefresh(!refresh);
          
          const { data } = response;
          // Show snack message
          dispatch({
            type: 'ACTION_SET_SNACK_MSG', 
            snackMessage: {
              message: data.message,
              level: 'success',
              duration: 5000
            }
          });
          if (typeof successCallback !== 'undefined') {
            successCallback();
          }
        })
        .catch(err => {
          dispatch({
            type: 'ACTION_SET_SNACK_MSG', 
            snackMessage: {
              message: 'An error occured.',
              level: 'error',
              duration: 5000
            }
          });
          log('err', err);
          if (typeof failureCallback !== 'undefined') {
            failureCallback();
          }
        });
      }
  };

  const exportCSV = () => {
    log('[exportCSV.getRequestsForCSV] CSVFilter: ', filterSelections);
    const element = document.createElement('a');
    SPBackend.getRequestsForCSV({
      ...filterSelections,
      startDatetime: '',
      endDatetime: ''
    }, filterSearchText, user)
    .then((response) => {
      const csv = Papa.unparse(response);
      const file = new Blob([csv],{type: 'text/plain'});
      element.href = URL.createObjectURL(file);
      element.download = 'Askneo_Feed.csv';
      element.click();
    });
  };

  const handleCloseWelcomeDialog = () => {
    dispatch({
      type: 'ACTION_SET_ACCOUNT_SETTINGS',
      accountSettings: {
        ...accountSettings,
        SetupMeeting: {
          IsRequired: false
        }
      }
    });
    SPBackend.setAccountOnboardingSettings(false, user)
      .then((response) => {
        log('[setAccountOnboardingSettings] response: ', response);
      })
      .catch((e) => {
        log('[setAccountOnboardingSettings] e: ', e);
      });
    // TODO: update backend settings
    // TODO: update store with new account setting, which should auto-close the dialog forever.
  };

  return (
  <div className={classes.root}>
    <Helmet>
      <title>Neo | Feed</title>
    </Helmet>
      {/* <VerifyAgreement /> */}
      <MemberHeader
        isDemoMode={isDemoMode}
        mobileOpen={mobileOpen} 
        sendBroadcast={sendBroadcast}
        usehandleDrawerToggle={usehandleDrawerToggle}
      />
      <MemberDrawer 
        mobileOpen={mobileOpen} 
        usehandleDrawerToggle={usehandleDrawerToggle}
      />
      <MemberContent
        exportCSV={exportCSV}
      />      
    </div>
  );
};

export default withStyles(styles)(MemberHome);