// @flow

import React, { useState, useEffect, useMemo } from 'react';
import { useStore } from '../../store';
import * as R from 'ramda';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';


import SPBackend, { BOT_SETTINGS_EVENTS } from '../../services/SPBackend';
import { log } from '../../utils/jsUtils';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/BotSettingsScript.Style';

import type { BotSettings, BotSettingsScriptStep } from '../../services/SPBackend';

const StepMetadata = (props) => {
  const { scriptStep, classes, stepIndex } = props;
  return (
    <>
      <Typography className={classes.stepTitle}
component={'span'}
variant={'h6'}
      ><b>{`Step ${stepIndex}`}</b></Typography>
    </>
  );
};

type Props = {
  classes: Object
};

// eslint-disable-next-line react/no-multi-comp
const BotSettingsScript = (props: Props) => {
  const {
    classes,
    botScriptData,
    setBotScriptData
    // botSettings,
    // setBotSettings,
    // knownTenantScript,
    // setKnownTenantScript,
    // unknownTenantScript,
    // setUnknownTenantScript
  } = props;

  const [isBtnKnownLoading, setIsBtnKnownLoading] = useState(false);
  const [isBtnUnknownLoading, setIsBtnUnknownLoading] = useState(false);
  const [isKnownTenantScriptChanged, setIsKnownTenantScriptChanged] = useState(false);
  const [isUnknownTenantScriptChanged, setIsUnknownTenantScriptChanged] = useState(false);
  
  const [store, dispatch] = useStore();
  const {
    user,
    i18n
    // tabValue
  } = store;
  
  const [botSettings, setBotSettings] = useState<?BotSettings>(null);
  const [knownTenantScript, setKnownTenantScript] = useState<?Array<BotSettingsScriptStep>>(null);
  const [unknownTenantScript, setUnknownTenantScript] = useState<?Array<BotSettingsScriptStep>>(null);
  
  log('[render] isUnknownTenantScriptChanged: ', isUnknownTenantScriptChanged ? 'true' : 'false');

  useEffect(() => {
    if (typeof botScriptData !== 'undefined' 
      && botScriptData !== null
      && !isUnknownTenantScriptChanged) {
      log('botScriptData: ', botScriptData);
      log('isUnknownTenantScriptChanged: ', isUnknownTenantScriptChanged ? 'true' : 'false');
      setBotSettings(botScriptData);
      const {
        KnownTenant, UnknownTenant
      } = botScriptData.Script;
      setKnownTenantScript(KnownTenant);
      setUnknownTenantScript(UnknownTenant);
    }
  }, [botScriptData]);

  const handleSaveSettings = (nodeType = 'UnknownTenant') => (e) => {
    e.preventDefault();
    const newScriptSettings = {
      ...botSettings,
      Script: {
        ...botSettings.Script,
        [nodeType]: nodeType === 'UnknownTenant' ? unknownTenantScript : knownTenantScript
      }
    };
    log('[handleSaveSettings] newScriptSettings: ', newScriptSettings);
    if (nodeType === 'UnknownTenant') {
      setIsBtnUnknownLoading(true);
    } else {
      setIsBtnKnownLoading(true);
    }
    console.log('[handleStepChange] newScriptSettings: ', newScriptSettings);
    SPBackend.setAccountBotSettings(user, newScriptSettings.Script)
      .then((response) => {
        log('[setAccountBotSettings] nodeType, bot settings: ', nodeType, response.data);
        const { message, success } = response.data;
        
        setIsKnownTenantScriptChanged(false);
        setIsBtnKnownLoading(false);
        setBotSettings(newScriptSettings);
        setBotScriptData(newScriptSettings);
        // Show snack message
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: message,
            level: success,
            duration: 5000
          }
        });
      })
      .catch((e) => {
        if (nodeType === 'UnknownTenant') {
          setIsUnknownTenantScriptChanged(false);
          setIsBtnUnknownLoading(false);
        } else {
          setIsKnownTenantScriptChanged(false);
          setIsBtnKnownLoading(false);
        }
        log('[setAccountBotSettings] error: ', e);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: 'Something wrong occured and your changes were not saved. Please text us if this happens again.',
            level: 'error',
            duration: 5000
          }
        });
      });
  };

  const handleStepChange = (nodeType, scriptStep, stepIndex) => (e) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      log('[handleStepChange] stepIndex: ', stepIndex.toString());
      log('[handleStepChange] nodeType, scriptStep, val: ', nodeType, scriptStep, e.target.value);
      // const updatedBotSettings = botSettings;
      let script = '';
      if (nodeType === 'UnknownTenant') {
        script = unknownTenantScript;
        setIsUnknownTenantScriptChanged(true);
      } else {
        script = knownTenantScript;
        setIsKnownTenantScriptChanged(true);
      }

      let newArr = [...script];
      newArr[stepIndex] = {
        ...newArr[stepIndex],
        en: e.target.value
      };
      log('[handleStepChange] newArr: ', newArr);
      if (nodeType === 'UnknownTenant') {
        setUnknownTenantScript(newArr);
      } else {
        setKnownTenantScript(newArr);
      }
    } catch (e) {
      console.log('[handleStepChange] error: ', e);
    }
  };

  return (
    <div>
      {
          unknownTenantScript
          ? <Grid item 
                  xs={12}
            >
              <Typography component={'span'}
variant={'h3'}>{'Script for unknown numbers'}</Typography>
              <form onSubmit={handleSaveSettings('UnknownTenant')}>
              {
                unknownTenantScript
                && unknownTenantScript.map((scriptStep, stepIndex) => {
                  return (
                    <div key={`script-${stepIndex}`}>
                      <div>
                        {/* <span>With incoming message: </span> */}
                        {
                          scriptStep.triggerEvents
                          ? <span>{
                            scriptStep.triggerEvents.map((event,index) => (
                                event !== ''
                                // hide deprecated events
                                && event !== 'set_as_ticket_name'
                                && event !== 'create_ticket'
                                ? <Chip classes={{root: classes.chip}}
                                        key={`chip-${index}`}
                                        label={`${BOT_SETTINGS_EVENTS[event]}`}
                                  />
                                : <div
                                    key={`chip-${index}`}
                                    style={{height: 4, display: 'block'}}
                                  >&nbsp;</div>
                              ))
                            }</span>
                          : null
                        }
                        {/* <span><Chip classes={{root: classes.chip}} label={'Do nothing'} /></span> */}
                      </div>
                      <div
                        className={classes.textFieldCtr}
                        key={`unknown-step-${stepIndex}`}
                      >
                        <StepMetadata
                          scriptStep={scriptStep}
                          stepIndex={stepIndex + 1}
                          {...props}
                        />
                        <TextField
                          autoFocus
                          className={classes.textField}
                          fullWidth
                          label={'Reply'}
                          maxRows={5}
                          multiline
                          onChange={handleStepChange('UnknownTenant', scriptStep, stepIndex)}
                          type='text'
                          value={scriptStep.en}
                        />
                      </div>
                    </div>
                  );
                })
              }
              <Button 
                classes={{
                  root: classes.submitBtn
                }}
                color='primary'
                disabled={!isUnknownTenantScriptChanged || isBtnUnknownLoading}
                type='submit'
                variant='contained'
              >{'Save'}</Button>
              </form>
            </Grid>
          : null
        }
        {/* {
          knownTenantScript
          ? <Grid className={classes.knownScriptCtr} 
                  item
                  xs={12}
            >
              <Typography component={'span'} variant={'h3'}>{'Script for customers'}</Typography><br />
              <Typography component={'span'} variant={'body1'}>{`After a ${i18n.t('task')} is closed, customize what the bot says to greet this ${i18n.t('tenant')} back:`}</Typography>
              <form onSubmit={handleSaveSettings('KnownTenant')}>
              {
                knownTenantScript.map((scriptStep, stepIndex) => {
                  return (
                    <div
                      className={classes.textFieldCtr}
                      key={`known-step-${stepIndex}`}
                    >
                      <StepMetadata
                        scriptStep={scriptStep}
                        stepIndex={stepIndex}
                        {...props}
                      />
                      <TextField     
                        autoFocus              
                        className={classes.textField}
                        fullWidth
                        label={'Reply'}
                        multiline
                        onChange={handleStepChange('KnownTenant', scriptStep, stepIndex)}
                        maxRows={5}
                        type='text'
                        value={scriptStep.en}
                      />
                    </div>
                  );
                })
              }
              <Button 
                classes={{
                  root: classes.submitBtn
                }}
                color='primary'
                disabled={!isKnownTenantScriptChanged || isBtnKnownLoading} 
                type='submit' 
                variant='contained'
              >
                {'Save'}
              </Button>
              </form>
            </Grid>
          : null
        } */}
    </div>
  );
};

export default withStyles(styles)(BotSettingsScript);