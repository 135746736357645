// @flow

import React, { useState, useEffect, useCallback } from 'react';
import { useStore } from '../../store';
import classNames from 'classnames';
import { log } from '../../utils/jsUtils';
import * as R from 'ramda';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';

import EditIcon from '@material-ui/icons/Edit';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/BotSettingsContent.Style';

import SPBackend from '../../services/SPBackend';

import type { Keyword, Attachment } from '../../services/SPBackend';

type Props = {
  classes: Object,
  keywords: Array<Keyword>,
  setKeywords: Function
};

const KeywordTriggersRow = (props:Props) => {
  const {
    classes,
    keywords,
    setKeywords,
    setKeyword,
    keyword,
    setOpenEditKeywordDialog,
    setIsNewKeyword

  } = props;

  const [store, dispatch] = useStore();

  const {
    user,
    i18n
    // tabValue
  } = store;

  const [checkedState, setCheckedState] = useState(false);
  

  const handleSwitchChange = (event, keyword:Object) => {
    setCheckedState(event.target.checked);
    log('[handleSwitchChange] checkedState', event.target.checked, keyword);
    const keywordPaylaod = {
      ...keyword,
      IsActive: event.target.checked
    };
    log('[handleSwitchChange] keywordPaylaod', keywordPaylaod);
    dispatch({
      type: 'ACTION_SET_IS_LOADING_MODULE',
      module: 'removeKeywordTrigger',
      loading: true
    });
    SPBackend.editKeywordTrigger(keywordPaylaod, user, false)
    .then(response => {
      log('[handleAddNewKeyword] response: ', response.data);
      const keywords  = response.data;
      setKeywords(keywords);
      dispatch({
        type: 'ACTION_SET_SNACK_MSG',
        snackMessage: {
          message: 'Trigger Updated',
          level: 'success',
          duration: 3000
        }
      });
      dispatch({
        type: 'ACTION_SET_IS_LOADING_MODULE',
        module: 'removeKeywordTrigger',
        loading: false
      });
    })
    .catch(error => {
      dispatch({
        type: 'ACTION_SET_SNACK_MSG',
        snackMessage: {
          message: error.message || 'An error occured. Please try again.',
          level: 'error',
          duration: 3000
        }
      });
      dispatch({
        type: 'ACTION_SET_IS_LOADING_MODULE',
        module: 'removeKeywordTrigger',
        loading: false
      });
      setKeyword(null);
    });
  };


  return (
    <div>
    <Button 
      className={classes.submitBtn}
      color='primary'
      onClick={() => {
              setIsNewKeyword(true);
              setOpenEditKeywordDialog(true);
              log('adding new keyword: ');
            }}
      type='submit'
      variant='contained'
    >
      {'Add Keyword'}
    </Button>
    {
      keywords && keywords !== null && keywords.length > 0
      ?
      keywords.map((keyword, bIndex) => (
        <Paper
          className={classes.keywordRow}
          key={`keyword=${bIndex}`}
          square
        >
          <Grid
            container 
            wrap='nowrap'
          >
            <div className={classNames(
              classes.colSwitch
            )}
            >
              <Switch
                checked={keyword.IsActive}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                onChange={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleSwitchChange(e, keyword);
                  log('editing switch: ', keyword);
                }}
              />
            </div>
            {/* <div className={classNames(
              classes.colMsgIcon,
              !keyword.IsActive ? classes.keywordRowOFF : null
            )}>
              <MessageIcon />
            </div> */}
            <div className={classNames(
              classes.colKeyword,
              !keyword.IsActive ? classes.keywordRowOFF : null
              )}
            >
              <Typography className={classes.colKeywordContent}
                          variant={'body1'} 
              >
                {keyword.Keyword}
                {
                  !keyword.IsActive
                  ? <i>&nbsp;{'(Paused)'}</i>
                  : null
                }
              </Typography>
            </div>
            <div className={classNames(
              classes.colTimestamp,
              !keyword.IsActive ? classes.keywordRowOFF : null
              )}
            >
              {
                keyword.LastRunDt 
                ? moment().diff(keyword.LastRunDt , 'days') > 1
                  ? <Typography variant={'body1'}>
                    {`Last run on ${moment(keyword.LastRunDt ).format('L')}`}
                    </Typography>
                  : <Typography variant={'body1'}>
                    {`Last run ${moment(keyword.LastRunDt ).from(moment())}`}
                    </Typography>
                : ''
                  /* <Typography variant={'body1'}>
                    <i>{'Waiting ...'}</i>
                  </Typography> */
              }
            </div>
            <div className={classNames(
              classes.colEditIcon,
              !keyword.IsActive ? classes.keywordRowOFF : null
              )}
            >
              <IconButton 
                aria-label='Search'
                className={classes.iconButton}
                onClick={() => {setKeyword(keyword);}}
              >
                <EditIcon />
              </IconButton>
            </div>
          </Grid>
        </Paper>
      ))
    : null
    }
    </div>
    
  );
};

export default withStyles(styles)(KeywordTriggersRow);
