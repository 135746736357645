// @flow

import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { log, formatPhoneNumberForBackend, validateEmail } from '../../utils/jsUtils';
import modelUtils from '../../utils/modelUtils';
import { useStore } from '../../store';
import SPBackend, { NOTIFICATION_CHANNELS } from '../../services/SPBackend';
import * as R from 'ramda';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import RemoveIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';


import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/PropertyContent.Style';
import MemberStructuralStyles from '../Styles/CommonMemberStructuralStyles.Style';
import commonListTableStyles from '../Styles/CommonListTable.Style';
import combineStyles from '../../utils/stylesUtils';

import PropertyTenantsMgr from './PropertyTenantsMgr';
import ListTableRowExpandable from '../ListTableRowExpandable';
import RemoveUnitDialog from './RemoveUnitDialog';
import EditUnitDialog from './EditUnitDialog';
import AddTenantDialog from '../Tenants/AddTenantDialog';
import RemoveTenantFromUnitDialog from './RemoveTenantFromUnitDialog';
import { ROLE_POWER } from '../../models/User';

import ListTableRow from '../ListTableRow';
import type { User, Building, UnitAndTenants, Tenant } from '../../services/SPBackend';

type Props = {
  classes: Object,
  property: Building,
  unitsAndTenants: Array<UnitAndTenants>,
  removeUnit: Function,
  editUnit: Function,
  setUnitsAndTenants: Function,
  refreshPropertyDetails: Function
};

type CheckedUnit = {
  unitAndTenants: UnitAndTenants,
  expanded: boolean,
  index: number
};

const combinedStyles = combineStyles(
  commonListTableStyles,
  styles,
  MemberStructuralStyles
);

type StaffRowProps = {
  classes: Object,
  staff: User
};

const StaffRow = (props: StaffRowProps) => {
  const { classes, staff } = props;
  return (
    <ListTableRow>
      <TableCell
        className={classNames(
          classes.cell, classes.otherCell)}
        component="td"
        scope="row"
      >
        &nbsp;&nbsp;&nbsp;&nbsp;{`${staff.FirstName} ${staff.LastName}`} 
      </TableCell>
      <TableCell
        className={classNames(
          'lastChild', classes.cell, classes.mainCell
        )}
        component="td"
        scope="row"
      >
        {staff.Email}
      </TableCell>
    </ListTableRow>
  );
};

// eslint-disable-next-line no-unused-vars
const StyledStaffRow = withStyles(combinedStyles)(StaffRow);

// eslint-disable-next-line react/no-multi-comp
const PropertyContent = (props: Props) => {
  const {
    classes,
    unitsAndTenants,
    removeUnit,
    editUnit,
    refreshPropertyDetails
  } = props;

  const [store, dispatch] = useStore();
  const {
    user,
    userRole,
    accountSettings,
    i18n
  } = store;
  // "check" means expanded
  const [ checkedUnits: Array<CheckedUnit>, setCheckedUnits ] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] = useState(false);
  const [openConfirmEditDialog, setOpenConfirmEditDialog] = useState(false);
  const [openAddTenantDialog, setOpenAddTenantDialog] = useState(false);
  const [openRemoveTenantFromUnitDialog, setOpenRemoveTenantFromUnitDialog] = useState(false);
  
  const selectTenant = (tenant) => {
    setSelectedTenant(tenant);
  };

  useEffect(() => {
    // Initialize CheckedUnits
    log('[PropertyContent], unitsAndTenants, checkedUnits: ', unitsAndTenants, checkedUnits);
    if (
      unitsAndTenants 
      && unitsAndTenants.length > 0
    ) {
      if (checkedUnits.length === 0) {
        // initial state
        setCheckedUnits(Array.apply(null, Array(unitsAndTenants.length)).map((u, i) => {
          return {
            index: i,
            unitAndTenants: unitsAndTenants[i],
            expanded: false
          };
        }));
      } else {
        // initial state
        setCheckedUnits(prevState => Array
          .apply(
            null,
            Array(unitsAndTenants.length))
              .map((u, i) => {
          return {
            index: i,
            unitAndTenants: unitsAndTenants[i],
            expanded: typeof prevState[i] !== 'undefined' ? prevState[i].expanded : false
          };
        }));
      }
    }
  }, [unitsAndTenants]);
  
  const openAddTenant = (unitAndTenants: UnitAndTenants) => {
    log('[openAddTenant] unitAndTenants: ', unitAndTenants);
    setSelectedUnit(() => unitAndTenants.unit);
    setOpenAddTenantDialog(true); 
  };

  const handleAddTenant = (newTenant: Tenant, callback: Function) => {
    log('[handleAddTenant]... newTenant, selectedUnit: ', newTenant, selectedUnit);
    if (selectedUnit !== null) {
      const firstName = newTenant.FirstName;
      const lastName = newTenant.LastName;
      if (firstName == null || firstName.length <= 0 || 
        lastName == null || lastName.length <= 0) {
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: 'First name and last name are required.',
            level: 'error',
            duration: 3000
          }
        });
        return;
      }

      const formattedNb = {
        Phone: formatPhoneNumberForBackend(newTenant.Phone) || ''
      };
      // validate email - if non empty
      if (newTenant.Email !== '' && !validateEmail(newTenant.Email)) {
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: 'Invalid email.',
            level: 'error',
            duration: 3000
          }
        }); 
        return ;
      }
      if (modelUtils.getValAtPath(
        accountSettings,
        ['Tenants', 'ReceiveNotificationsBy'],
        NOTIFICATION_CHANNELS.SMS
      ) === NOTIFICATION_CHANNELS.EMAIL) {
        if (newTenant.Email === '') {
          dispatch({
            type: 'ACTION_SET_SNACK_MSG', 
            snackMessage: {
              message: 'A valid email is required.',
              level: 'error',
              duration: 3000
            }
          });
          return ;
        }
      } else {
        // if account settings isn't to send email, default to SMS
        if (!formattedNb) {
          dispatch({
            type: 'ACTION_SET_SNACK_MSG', 
            snackMessage: {
              message: 'Invalid phone number.',
              level: 'error',
              duration: 3000
            }
          });
          return ;
        }
      }

      const payload = {
        ...newTenant,
        ...formattedNb
      };
      log('[handleAddTenant] sending payload: ', payload);
      SPBackend.createTenant(payload, user)
      .then(response => {
        log('[handleAddTenant] response: ', response);
        // TODO: add tenant to list for this unit
        log('[createTenant] selectedUnit.RoomKey: ', selectedUnit.RoomKey);
        refreshPropertyDetails();
        callback();
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: `${i18n.t('Tenant')} created.`,
            level: 'success',
            duration: 3000
          }
        }); 
      })
      .catch(error => {
        // Show snack message
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: error.response ? error.response.data : 'An error occured. Please try again',
            level: 'error',
            duration: 3000
          }
        });  
      });
    } else {
      // Show snack message
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: 'An error occured. Please try again.',
          level: 'error',
          duration: 3000
        }
      });  
    }
  };

  const handleEditTenant = (tenant) => {
    log('[handleEditTenant]... tenant, selectedUnit: ', tenant, selectedUnit);
    if (tenant._id === null || selectedUnit === null) {
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: `Error: invalid ${i18n.t('tenant')} details.`,
          level: 'error',
          duration: 3000
        }
      });
      return ;
    }
    const assignedTenant = {
      ...R.pick([
        '_id'
      ], tenant),
      Keys: [selectedUnit.RoomKey]
    };
    SPBackend.editTenant(assignedTenant, user)
      .then(response => {
        log('[handleEditTenant] response: ', response);
        // TODO: add tenant to list for this unit
        log('[createTenant] selectedUnit.RoomKey: ', selectedUnit.RoomKey);
        refreshPropertyDetails();
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: `${i18n.t('Tenant')} moved.`,
            level: 'success',
            duration: 3000
          }
        }); 
      })
      .catch(error => {
        log('[handleEditTenant] error', error);   
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: error.response ? error.response.data : 'An error occured. Please try again',
            level: 'error',
            duration: 3000
          }
        });
      });
  };

  const handleRemoveTenantFromUnit = (tenant) => {
    log('[handleRemoveTenantFromUnit]... tenant, selectedUnit: ', tenant, selectedUnit);
    if (tenant._id === null) {
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: `Error: invalid ${i18n.t('tenant')} details.`,
          level: 'error',
          duration: 3000
        }
      });
      return ;
    }
    const assignedTenant = {
      ...R.pick([
        '_id'
      ], tenant),
      Keys: []
    };

    SPBackend.moveTenantOut(assignedTenant, user)
      .then(response => {
        log('[handleRemoveTenantFromUnit] response: ', response);

        refreshPropertyDetails();
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: `${i18n.t('Tenant')} moved out.`,
            level: 'success',
            duration: 3000
          }
        }); 
      })
      .catch(error => {
        log('[handleEditTenant] error', error);   
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: error.response ? error.response.data : 'An error occured. Please try again',
            level: 'error',
            duration: 3000
          }
        });
      });
  };

  const openRemoveUnitAndTenants = (obj) => {
    setSelectedUnit(obj.unit);
    setOpenConfirmRemoveDialog(true); 
  };

  const openEditUnitAndTenants = (obj) => {
    setSelectedUnit(obj.unit);
    setOpenConfirmEditDialog(true);
  };

  const handleRemoveUnit = () => {
    if (selectedUnit !== null) {
      removeUnit(selectedUnit.RoomKey);
      setSelectedUnit(null);
    } else {
      // Show snack message
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: `Select a ${i18n.t('unit')} to remove first.`,
          level: 'error',
          duration: 3000
        }
      });  
    }
  };

  const handleEditUnit = (changedUnit) => {
    if (selectedUnit !== null) {
      selectedUnit.Floor = changedUnit.Floor;
      selectedUnit.Apt = changedUnit.Apt;
      editUnit(selectedUnit);
      setSelectedUnit(null);
    } else {
      // Show snack message
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: `Select a ${i18n.t('unit')} to edit first.`,
          level: 'error',
          duration: 3000
        }
      });  
    }
  };
  
  // eslint-disable-next-line react/no-multi-comp
  const rowBody = (unitAndTenants, index) => {
    return (
      <div 
        key={`body${index}`}
      >
        <PropertyTenantsMgr
          index={index}
          openAddTenant={openAddTenant}
          selectTenant={selectTenant}
          setOpenRemoveTenantFromUnitDialog={setOpenRemoveTenantFromUnitDialog}
          unitAndTenants={unitAndTenants}
        />
      </div>
    );
  };

  const rowHeader = (unitAndTenants, index) => {
    const res = [
      <TableCell
        className={classNames(
          classes.cell, classes.floorCell)}
        component="td"
        key={`row${index}-1`}
        scope="row"
      >
        &nbsp;&nbsp;&nbsp;&nbsp;{unitAndTenants.unit.Floor}
      </TableCell>,
      <TableCell
        className={classNames(
          classes.cell, classes.otherCell
        )}
        component="td"
        key={`row${index}-2`}
        scope="row"
      >
        {unitAndTenants.unit.Apt}
      </TableCell>,
      <TableCell
        className={classNames(
          classes.cell, classes.mainCell
        )}
        component='td'
        key={`row${index}-3`}
        scope="row"
      >
        {unitAndTenants.tenants.length ? unitAndTenants.tenants.length : 'Vacant'}
      </TableCell>,
      <TableCell
        className={classNames(
          classes.cell,
          'lastChild',
          'underActionCell',
          (ROLE_POWER[userRole] > 1)? 'twoBtns' : null
        )}
        component="td"
        key={`row${index}-4`}
        scope="row"
      >
        <div className={classes.textOverflow} />
      </TableCell>
    ];

    if (ROLE_POWER[userRole] > 1) {
      res.push(<TableCell
        align='right'
        className={classNames(
          classes.cell, 
          classes.actionCell, 
          'actionCell',
          'lastChild')}
        key={`row${index}-5`}
      >
        <Tooltip 
          aria-label={`Remove ${i18n.t('Unit')}`}
          title={`Remove ${i18n.t('Unit')}`} 
        >
          <IconButton 
            aria-label={`Remove ${i18n.t('Unit')}` }
            classes={{
              root: classes.rowActionBtn
            }}
            onClick={(e) => {
              e.stopPropagation();
              log('removing unit: ', unitAndTenants.unit);
              openRemoveUnitAndTenants(unitAndTenants);
            }}
          >
            <RemoveIcon />
          </IconButton>
        </Tooltip>
        <Tooltip 
          aria-label={`Edit ${i18n.t('Unit')}`}
          title={`Edit ${i18n.t('Unit')}`} 
        >
          <IconButton 
            aria-label={`Edit ${i18n.t('Unit')}` }
            classes={{
              root: classes.rowActionBtn
            }}
            onClick={(e) => {
              e.stopPropagation();
              openEditUnitAndTenants(unitAndTenants);
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </TableCell>);
    }

    return res;
  };
  
  return (
    <Grid 
      className={classes.tableContent}
    >
      <div className={classes.toolbar} />
      <div className={classes.resultsCount}>&nbsp;&nbsp;&nbsp;&nbsp;
      {
        checkedUnits
        ? `${checkedUnits.length} ${i18n.t('units')} found.`
        : `0 ${i18n.t('units')} found.`
      }
      </div>
      {
        !checkedUnits || checkedUnits.length === 0
        ? <Typography variant={'body1'}>
            {`No ${i18n.t('units')} yet in this ${i18n.t('property')}.`}
          </Typography>
        : <Table className={classes.table}>
            <colgroup>
              <col />
              <col />
              <col />
              <col classes={classes.colAction} />
            </colgroup>
            <TableHead className={classes.feedTableBody}>
              <TableRow className={classes.feedTableHeadRow}>
                <TableCell className={classNames(classes.floorCell)}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{'Floor'}
                </TableCell>
                <TableCell className={classNames(classes.otherCell)}>{i18n.t('Apt')}</TableCell>
                <TableCell 
                  className={classNames(classes.mainCell)}
                >
                  {'Occupants'}
                </TableCell>
                <TableCell className={classNames(classes.colAction)}>{''}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classNames(
                classes.feedTableBody,
                classes.customTableStyles
              )}
            >
            {
              checkedUnits.map((checkedUnit: CheckedUnit, bIndex) => {
                const { expanded, unitAndTenants } = checkedUnit;
                log('[insideMap] expanded: ', expanded);
                return (
                  <ListTableRowExpandable
                    cols={5}
                    // handleRowClick={checkUnit(checkedUnit)}
                    key={`building-${bIndex}`}
                  >
                    {
                      [
                        rowHeader(unitAndTenants, bIndex),
                        rowBody(unitAndTenants, bIndex)
                      ]
                    }
                  </ListTableRowExpandable>
                );
              })
            }
            </TableBody>
          </Table>
      }
      <RemoveUnitDialog
        actionConfirm={handleRemoveUnit}
        open={openConfirmRemoveDialog}
        setOpen={setOpenConfirmRemoveDialog}
        unit={selectedUnit}
      />
      <AddTenantDialog
        handleEditTenant={handleEditTenant}
        open={openAddTenantDialog}
        setOpen={setOpenAddTenantDialog}
        triggerAction={handleAddTenant}
        unit={selectedUnit}
      />
      <EditUnitDialog
        open={openConfirmEditDialog}
        setOpen={setOpenConfirmEditDialog}
        triggerAction={handleEditUnit}
        unit={selectedUnit}
      />
      <RemoveTenantFromUnitDialog
        actionConfirm={handleRemoveTenantFromUnit}
        open={openRemoveTenantFromUnitDialog}
        setOpen={setOpenRemoveTenantFromUnitDialog}
        tenant={selectedTenant}
      />
    </Grid>
  );
};

export default withStyles(combinedStyles)(PropertyContent);