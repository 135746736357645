// @flow

import React, { useState } from 'react';
import classNames from 'classnames';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';

import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import BroadcastIcon from '@material-ui/icons/TextsmsOutlined';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/AddPropertyDialog.Style';

import type { Building } from '../../services/SPBackend';

type Props = {
  classes: Object,
  setOpen: Function,
  open: boolean,
  triggerAction: Function,
  property: Building
}

const EditProperty = (props: Props) => {
  const {
    classes,
    setOpen,
    open,
    triggerAction,
    property
  } = props;
  const [changedProperty, setChangedProperty] = useState({Property: ''});

  const [store] = useStore();
  const {
    i18n
  } = store;

  return (
    <Dialog
      aria-labelledby='form-edit-property'
      onClose={() => setOpen(false)}
      open={open}
    >
      <form
        autoComplete='off'
        className={classes.dialogFormCtr}
        noValidate
        onSubmit={(e) => { e.preventDefault(); triggerAction(changedProperty); setOpen(false); }}
      >
          <DialogTitle id='form-dialog-title'>
          {`Edit ${i18n.t('property')}`}
          </DialogTitle>
          <DialogContent className={classNames(classes.grow, classes.flexContainer)}>
            <DialogContentText className={classNames(classes.grow)}>
              {'Remember to type in the full description.'}
            </DialogContentText>
            <Input
                autoFocus
                defaultValue={property!==null ? property.Property : ''}
                fullWidth
                id='broadcastIssue'
                label={i18n.t('Property')}
                margin='dense'
                onChange={(e) => setChangedProperty({Property: e.target.value})}
                type='text'
            />
          </DialogContent>
          <DialogActions>
            <Button color='primary'
              onClick={() => setOpen(false)}
            >
              {'Cancel'}
              </Button>
            <Button 
              color='primary'
              onClick={() => { triggerAction(changedProperty); setOpen(false); }}
              variant='contained'
            >
              {'Edit'}
              </Button>
          </DialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(EditProperty);
