// @flow

const rowShadowAtRest = '0px 2px 8px -1px rgba(0,0,0,0.2)';
const ITEM_HEIGHT = 'auto';
const SUBTITLE_SIZE = '0.80rem';

const styles = (theme: Object) => ({
  mobileFeedCtr: {
    padding: theme.spacing(0.5)
  },
  mobileFeedItem: {
    borderRadius: theme.shape.borderRadius / 2,
    // position: 'sticky',
    // top: theme.layout.customProps.header.height*2,
    boxShadow: rowShadowAtRest,
    height: ITEM_HEIGHT,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    '&.hover': {
      boxShadow: '0px 2px 8px -1px rgba(0,0,0,0.8)'
    }
  },
  rightColTitle: {
    position: 'relative',
    top: -2
  },
  mobileRowCtr: {
    flexWrap: 'nowrap',
    height: 45,
    marginBottom: theme.spacing(1)
  },
  mobileLeftCol: {
    maxWidth: '66vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100vw - 140px)'
    }
  },
  topTitleCtr: {
    display: 'flex',
    flexDirection: 'row'
  },
  mobileTopRight: {
    color: 'hsl(0,0%,50%)',
    // marginTop: theme.spacing(0.33),
    marginBottom: theme.spacing(1),
    fontSize: SUBTITLE_SIZE,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  itemRightCol: {
    textAlign: 'right',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    '& *': {
      textAlign: 'right'
    }
  },
  frequencyArrow: {
    fontSize: '1.05rem',
    position: 'relative',
    top: 3,
    color: theme.palette.primary.main
  }
});

export default styles;