// @flow

import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import { log } from '../utils/jsUtils';

import ListTableRow from './ListTableRow';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Collapse from '@material-ui/core/Collapse';

import combineStyles from '../utils/stylesUtils';
import { withStyles } from '@material-ui/core/styles';
import collapsibleListTableStyles from './Styles/CommonCollapsibleListTable.Style';

type Props = {
  classes: Object,
  children: Object,
  rowClasses: Object,
  key: string,
  readonly: boolean,
  cols: number, // totals nb of columns in this table
  handleRowClick: Function
}

const ListTableRowExpandable = (props: Props) => {
  const {
    classes,
    children,
    key,
    cols
  } = props;
  const [ expanded, setExpanded ] = useState(false);
  log('[ListTableRowExpandable.render] expanded: ', expanded);
  return ([
      <ListTableRow
        handleRowClick={() => setExpanded(!expanded)}
        key={`${key}-header`}
        rowClasses={expanded ? classes.rowExpanded : classes.rowCollapsed}
      >
        {children[0]}
      </ListTableRow>
    ,
      <TableRow 
        className={classNames(
          classes.expandedTableRow,
          expanded ? 'expanded': 'collapsed'
        )}
        key={`${key}-body`}
      >
        <TableCell 
          classes={{
            root: classes.expandableCtrCell
          }}
          colSpan={cols}
        >
          <Collapse
            in={expanded}
          >
            <Grid 
              container
              direction='column'
              spacing={2}
            >
              <Grid item>
                {children[1]}
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
  ]);
};


const combinedStyles = combineStyles(
  collapsibleListTableStyles
);

export default withStyles(combinedStyles)(ListTableRowExpandable);