// @flow

import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useStore } from '../../store';
import ContentfulProvider from '../../services/contentful';
import { useQuery } from 'urql';

import BasicHeader from './BasicHeader';
import ContentfulPricingSection from './ContentfulPricingSection';

import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Check from '@material-ui/icons/Check';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/PlansHome.Style';

type Props = {
  classes: Object
};


const PlansHome = (props: Props) => {
  const { classes } = props;
  const history = useHistory();
  const [store, dispatch] = useStore();
  const {
    billing
  } = store;
  const [isPeriodYearly, setIsPeriodYearly] = useState(true);
  const { planId } = billing;
  // console.log('[PlansHome] planId: ', planId);
  if (planId) {
    history.push(`/checkout?planid=${planId}`);
  }

  const [result, reexecuteQuery] = useQuery({
    query: `query ($id: String!) {
      marketingPage(id: $id) {
        slug
        browserTabTitle
        columnSectionsCollection {
          items {
            __typename
            ... on PricingSection {
              pricingTable
            }
          }
        }
      }
    }`,
    variables: {
      id: '3I8FEkk3gADqUYzOw2wmxk' // Marketing Page that contains the Pricing Section.
    }
  });
  const { data, fetching, error } = result;
  // console.log('[PlansHome] fetching, error: ', fetching, error, data);
  if (typeof data !== 'undefined') {
    // console.log('[PlansHome] data.marketingPage.columnSectionsCollection: ', data.marketingPage.columnSectionsCollection);
  }

  const selectPlan = (planId) => {
    dispatch({                           
      type: 'ACTION_SET_BILLING_PLANID',
      planId: planId
    });
    history.push(`/checkout?planid=${planId}`);
  };

  return (
    <Grid
      className={classes.root}
      container
      direction='column'
    >
      <Helmet>
        <title>Neo | Plans</title>
      </Helmet>
      <BasicHeader title='Choose a plan' />
      <Grid className={classes.contentCtr}
        item
        xs={12}
      >
      {
        !fetching && !error && data
        ? data.marketingPage.columnSectionsCollection.items.map((section, sectionIdx) => {
          return (
            section.__typename === 'PricingSection'
            ? <ContentfulPricingSection 
                key={`section-${sectionIdx}`}
                pricingTable={section.pricingTable}
                section={section} 
                selectPlan={selectPlan}
              />
            : null
          );
        })
        : null
      }
      </Grid>
    </Grid>
  );
};

// eslint-disable-next-line react/no-multi-comp
const PlansHomeWrapper = (props) => (
  <ContentfulProvider>
    <PlansHome {...props} />
  </ContentfulProvider>
);

export default withStyles(styles)(PlansHomeWrapper);