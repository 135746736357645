// @flow

import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/TOS.Style';

const PrivacyPolicy = (props) => {
  const { classes } = props;

  return (
    <Container className={classes.container}>
      <Typography variant='h3' align='center'>
      Askneo.io – Privacy Policy
      </Typography>
      <p><i>Last Updated: July 20, 2020</i></p>
      <p>
      Askneo.io is an online platform that enables you (“Customer Service Provider”) to communicate directly with your valued customers (“Users”) through text messaging via Platform-provided Contact Information, and with assistance from the Ask Neo bot, for customer service purposes(the “Service”). User. The Service is offered through the Askneo.io interface (the “Platform”). 
      </p>
      <p>This Privacy Policy (the “Policy”) explains our privacy practices for the Service. It describes how we collect personal information from the you and your Users, how we use it and share it and the rights and options available to you with respect to your information.</p>
      <p>This policy is integrated into our Terms of Service (the "Terms") and constitutes an integral part of the Terms. </p>
      <Typography variant='h4'>
        In short…
      </Typography>
      <p>The following summary is provided to you for your convenience only. It does not substitute the full Policy.</p>
<ul>
<li>Using the Information We Collect. We use the information we collect mainly to operate the Service, improve and customize it, and develop new services. </li>
<li>Sharing the Information We Collect. We may share your information in order to operate, maintain and enhance the Service. </li>
<li>Aggregated Information. We may use the information we collect from you and your Users to create de-identified, statistical or aggregated information, and make it available to others for any purpose.</li>
<li>Retention. We retain the information you provide us directly and the information your Users provide you while using our Services for as long as we deem necessary for the purpose it was first obtained.</li>
<li>Security. We implement measures to reduce the risks of damage and unauthorized access or use of information, but they do not provide absolute information security.</li>
<li>Children Privacy. As stated in the Terms, you must ensure that all of your Users who use our Services be at least 18 years old before allowing them to use the Service. We do not knowingly or intentionally collect information from anyone who is under 18 years of age and are not responsible for the use of our Services by individuals under the 18 years of age.  </li>
<li>Changes to the Policy. We may change this Policy, by putting you on notice of such changes. Your continued use of the Service after the changes take effect indicates your acceptance of the amended Policy. </li>
</ul>

<Typography variant='h4'>INFORMATION WE COLLECT</Typography>
<p>Registration Information. If you register to the Service, we will collect:</p>
<ul>
<li>Your username and password on the Service</li>
<li>Your contact information, including your name, phone, email address and payment information</li>
<li>Any information you provide in the course of registration about your Service and billing preferences.</li>
</ul>

<p>Analytics Data. We may use third party analytics tools to collect information about you or your Users use of the Service and create statistics from such information. For example, we may record the frequency and scope of your use of the Service, the duration of your messaging sessions with your Users , and the interactions you make with the Service’s interface. </p>
<p>Device Information. We may collect information about your personal computer or mobile device that you use to access the Service, including its model, operating system, unique device identifiers, mobile network information and the Internet Protocol (IP) address through which you accessed the Service. In addition, we may collect information about your Users personal computer or mobile device that they use to interact with the Service, including its model, operating system, unique device identifiers, mobile network information and the Internet Protocol (IP) address through which they interacted with the Service.</p>
<p>Email Address. The email address you provide as part of the registration process may be used to send you information and updates pertaining to the Service, in addition to occasional news updates, related product or service information, etc. If at any time you would like to unsubscribe from receiving certain types of emails, an unsubscribe option shall be provided at the bottom of each email.</p>
<p>Please note that use of the Service is subject to having a valid email address, which will be used for password recovery and for sending updates about changes to this Privacy Policy. Unsubscribing from our email lists if present will not block these two types of messages which will continue to be available until your account is cancelled</p>
<p>Other information: we can also collect your precise geolocation (latitude and longitude) through various means, depending on the service and device you’re using, including GPS, Bluetooth or Wi-Fi connections. The collection of your geolocation may occur in the background even when you aren’t using the services. We can also collect your Users’ precise geolocation (latitude and longitude) through various means, depending on the service and device they are using, including GPS, Bluetooth or Wi-Fi connections. The collection of your Users geolocation may occur in the background even when they aren’t actively interacting with the services. </p>

<p>Information we receive from others: In addition to the information you provide us directly, we receive information about you from others, including: Other Customer Service Providers and your Users. Users may provide information about you as they use our services. For instance, we may collect information about you from Users if they contact us directly regarding service disruptions. </p>
<ul>
  <li>Other Partners: We may receive information about you from our partners, for instance where Askneo.io ads are published on a partner’s websites and platforms (in which case they may pass along details on a campaign’s success). </li>
</ul>

<Typography variant='h4'>CHILDREN'S PRIVACY</Typography>
<p>The Service is prohibited for children under the age of 18. Furthermore, we do not knowingly or intentionally collect information from children who are under 18 years of age. As stated in the Terms, you must be at least 18 years old to use the Service. We are also not responsible for the use of our Services by individuals under the 18 years of age as result of your inability to ensure that only Users that are at least 18 years old use the Service.</p>

<Typography variant='h4'>USE OF COLLECTED INFORMATION</Typography>
<p>We may use the information we collect for the following purposes:</p>
<ul>
<li>Operating the Service and providing their features and functionalities;</li>
<li>Billing you for the fees that apply to your use of the Service, if any;</li>
<li>Sending you updates, notifications, and other communications related to the Service;</li>
<li>Improving and customizing the Service and developing new services;</li>
<li>Collecting statistical and aggregated information about Customer Service Providers’ and Users’ experience within the Service;</li>
<li>Providing you and your Users with support, handling complaints and contacting you when we believe it is necessary;</li>
<li>Enforcing the Terms and this Policy and preventing misuse of the Service by you or your Users;</li>
<li>Complying with any applicable law and assisting law enforcement agencies and competent authorities, if we believe it is necessary or justified; and </li>
<li>Taking any action in any case of dispute involving you or your Users, with respect or in relation to the Service.</li>
</ul>

<Typography variant='h4'>SHARING INFORMATION COLLECTED</Typography>
<p>We may share the information outlined in this Policy, with others, in any of the following instances: </p>
<ul>
<li>With Users that you instruct us to share your information;</li>
<li>With our contractors and service providers, for data analysis and processing, bug reporting and handling, and in order to operate, maintain and enhance the Service;</li>
<li>If you have breached the Terms, abused your rights to use the Service, or violated any applicable law. Your or your Users personally identifiable information may be shared with competent authorities and with any third party, if we believe it is necessary or justified;</li>
<li>If the operation of the Service is organized within a different framework, or through another legal structure or entity (such as due to a merger or acquisition);</li>
<li>We may share personally identifiable information with our corporate group entities, but their use of such information must comply with the Policy.</li>
</ul>
<p>Cookies. Cookies are small files that a site or its service provider transfer to the hard drive of a computer through a web browser (if the computer settings allow) that enable the site's or service provider's systems to recognize the browser and capture and remember certain information. We may use cookies to understand and save your preferences for future visits in order to streamline the Service processes and to compile aggregate data about Website interaction and Service usage if applicable so that we can offer an enhanced experience and improved tools in the future, including the ability to improve Website navigation and Platform usability.</p>

<Typography variant='h4'>AGGREGATED INFORMATION</Typography>
<p>We may use the information we collect, as outlined above, to compile anonymized or aggregated information. We may share, sell or otherwise communicate and make available such anonymized or aggregated information to any other third party, at our sole discretion. However, we will not knowingly or intentionally share information that can be reasonably used to reveal your or your Users’ identity or the identity of anyone tracked through the Service, without consent. </p>

<Typography variant='h4'>INFORMATION SECURITY</Typography>
<p>We work hard to protect you from unauthorized access to or alteration, disclosure or destruction of your and your Users’ personal information. As with all technology companies, although we take steps to secure your information, we do not promise, and you should not expect, that your or your Users’ personal information will always remain secure. We implement measures to reduce the risks of damage, loss of information and unauthorized access or use of information. These measures do not provide absolute information security. Therefore, it is not guaranteed and you cannot expect that the Service will be immune from information security risks.</p>
<p>We may suspend your use of all or part of the services without notice if we suspect or detect any breach of security. If you believe that your account or information is no longer secure, please notify us immediately.</p>

<Typography variant='h4'>DATA RETENTION </Typography>
<p>We retain personally identifiable information for as long as we deem necessary for the purposes set forth above. We may delete information from our systems, without notice to you, once we deem it is no longer necessary for the purposes set forth above.</p>

<Typography variant='h4'>CHANGES TO THIS PRIVACY POLICY</Typography>
<p>Because we are always looking for new and innovative ways to help you build meaningful connections, this policy may change over time. We will notify you before any material changes take effect so that you have time to review the changes. Your continued use of the Service constitutes your consent to the amended Policy. If you do not consent to the amended Policy, we may terminate your account and block your access to, and use of, the Service. </p>

<p>If you would like to exercise any of your CCPA rights as described above, you should email us at lio@askneo.io</p>
<p>We may ask you for additional information to confirm your or your Users’ identity and for security purposes, before disclosing the personal data requested to you, by using a two or three points of data verification process, depending on the type of information you require. If you have a password protected account with us, we may ask to verify your request through the account’s existing authentication methods. If you request that we disclose or delete your information, we will require you to re-authenticate with your account.</p>

<Typography variant='h4'>Please note that we do not sell your data and we have not done so in the preceding 12 months.</Typography>

<Typography variant='h4'>CONTACT US</Typography>
<p>At any time, you may contact us with any question, request, comment or complaint that you may have with respect to the Service or these Terms, at <a href="mailto:lio@askneo.io">lio@askneo.io</a>.</p>
<br />
<p>Effective date of the policy: July 20, 2020</p>
    </Container>
  );
};

export default withStyles(styles)(PrivacyPolicy);
