// @flow

import React, { useState } from 'react';
import classNames from 'classnames';
import { log, formatPhoneNumberForBackend, validateEmail } from '../../utils/jsUtils';
import modelUtils from '../../utils/modelUtils';
import { useStore } from '../../store';

import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import GroupIcon from '@material-ui/icons/LocalOffer';
import PublishIcon from '@material-ui/icons/Publish';

// import AddProperty from './AddPropertyDialog';
import FilterSearchBox from '../FilterSearchBox';
import HeaderTopBar from '../HeaderTopBar';
import SPBackend, { NOTIFICATION_CHANNELS } from '../../services/SPBackend';
import AddTenantDialog from './AddTenantDialog';
import AppLoadingSpinner from '../SharedComponents/AppLoadingSpinner';
import TagCustomersPicker from '../Feed/TagCustomersPicker';

import { withStyles } from '@material-ui/core/styles';
import styles from '../Feed/Styles/MemberHeader.Style';

import type { Tenant } from '../../services/SPBackend';

type Props = {
  classes: Object,
  mobileOpen: bool,
  customerIdsSelected: Array<string>,
  usehandleDrawerToggle: Function,
  orderTenants: Function,
  handleSetSearchInputValue: Function,
  tenants: Array<Tenant>,
  setTenants: Function,
  refreshTenants: Function,
  updateTenants: Function,
  totalTenantsCount: number,
  setCountOfTenants: Function,
  searchInputValue: string,
  searchTenantValue: string
};


const TenantsHeader = (props: Props) => {
  const {
    classes,
    customerIdsSelected,
    orderTenants,
    handleSetSearchInputValue,
    tenants,
    usehandleDrawerToggle,
    refreshTenants,
    setTenants,
    updateTenants,
    totalTenantsCount,
    setCountOfTenants,
    searchInputValue,
    searchTenantValue
  } = props;
  const [openAddTenantDialog, setOpenAddTenantDialog] = useState(false);
  const [openTagPicker, setOpenTagPicker] = useState(false);

  const tagPickerAnchorRef = React.useRef();

  const [store, dispatch] = useStore();
  const {
    user,
    accountSettings,
    i18n,
    tenantEnableUpload
  } = store;

  const handleAddTenant = (tenant: Tenant, callbackSuccess: Function, callbackFailure: Function) => {
    log('[handleAddTenant] tenant, user: ', tenant, user);

    const formattedNb = {
      Phone: formatPhoneNumberForBackend(tenant.Phone) || ''
    };
    // validate email - if non empty
    if (tenant.Email !== '' && !validateEmail(tenant.Email)) {
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: 'Invalid email.',
          level: 'error',
          duration: 3000
        }
      }); 
      return ;
    }
    if (modelUtils.getValAtPath(
      accountSettings,
      ['Tenants', 'ReceiveNotificationsBy'],
      NOTIFICATION_CHANNELS.SMS
    ) === NOTIFICATION_CHANNELS.EMAIL) {
      if (tenant.Email === '') {
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: 'A valid email is required.',
            level: 'error',
            duration: 3000
          }
        });
        return ;
      }
    } else {
      // if account settings isn't to send email, default to SMS
      if (!formattedNb) {
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: 'Invalid phone number.',
            level: 'error',
            duration: 3000
          }
        });
        return ;
      }
    }

    const payload = {
      ...tenant,
      ...formattedNb
    };
    
    dispatch({
      type: 'ACTION_SET_IS_LOADING_MODULE',
      module: 'addCustomer',
      loading: true
    });
    SPBackend.createTenant(payload, user)
      .then((response) => {
        const {data: newTenant} = response;
        log('[createTenant] res: ', newTenant);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: `${i18n.t('Tenant')} added!`,
            level: 'success',
            duration: 5000
          }
        }); 
        dispatch({
          type: 'ACTION_SET_ACCOUNT_SETTINGS',
          accountSettings: {
            ...accountSettings,
            SetupRequired: {
              ...accountSettings.SetupRequired,
              Tenants: false
            }
          }
        });
        dispatch({
          type: 'ACTION_SET_IS_LOADING_MODULE',
          module: 'addCustomer',
          loading: false
        });
        setCountOfTenants(totalTenantsCount+1);
        setTenants((prev) => {
          return [newTenant, ...prev];
        });
        if (typeof callbackSuccess !== 'undefined') {
          callbackSuccess();
        }
      })
      .catch(error => {
        log('[handleAddTenant.createBuilding] error: ', error);
        if (typeof callbackFailure !== 'undefined') {
          callbackFailure();
        }
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: error.response ? error.response.data : 'An error occured. Please try again',
            level: 'error',
            duration: 5000
          }
        }); 
        dispatch({
          type: 'ACTION_SET_IS_LOADING_MODULE',
          module: 'addCustomer',
          loading: false
        });
      });
  };

  const handleEnableUpload = () => {
    dispatch({
      type: 'ACTION_SET_TENANTS_ENABLE_UPLOAD',
      tenantEnableUpload: !tenantEnableUpload
    });
  };

  const handleTagSelected = (tag) => {
    console.log('tagging...', tag, customerIdsSelected);
    updateTenants(customerIdsSelected, tag);
    dispatch({
      type: 'ACTION_SET_IS_LOADING_MODULE',
      module: 'tagCustomer',
      loading: true
    });
    SPBackend.tagCustomers(tag._id, customerIdsSelected, user)
      .then((response) => {
        console.log('[handleTagSelected.tagCustomers] response: ', response);
        const { message, success } = response.data;
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: message,
            level: success ? 'success' : 'error',
            duration: 5000
          }
        }); 
        dispatch({
          type: 'ACTION_SET_IS_LOADING_MODULE',
          module: 'tagCustomer',
          loading: false
        });
      })
      .catch((error) => {
        log('[handleTagSelected.tagCustomers] error: ', error);
        updateTenants(customerIdsSelected, null);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: error.response ? error.response.data : 'An error occured. Please try again',
            level: 'error',
            duration: 5000
          }
        }); 
      });
  };

  return (
    <AppBar 
      className={classes.appBar}
      position="fixed"
    >
      <Toolbar
        className={classes.toolbar}
        disableGutters
      >
        <Grid 
          container
          direction='column'
        >
          <HeaderTopBar
            usehandleDrawerToggle={usehandleDrawerToggle}
          >
            <Typography 
              className={classes.pageTitle}
              variant='h3'
            >{i18n.t('Tenants')}</Typography>
          </HeaderTopBar>
          <Grid
            className={classNames(
              classes.rowSub,
              classes.bottomBar
            )}
            item
          >
            <Grid className={classes.rowSubTypoCtr}
container
            >
              {
                customerIdsSelected && customerIdsSelected.length > 0
                ? <>
                  <Typography 
                    className={classNames(
                      classes.pageTitle,
                      classes.subHeaderCaption
                    )}
                    variant='h5'
                  >{customerIdsSelected.length} selected:</Typography>
                    <div 
                      className={classNames(
                        classes.toggleContainer
                      )}
                    >
                      <Tooltip 
                        aria-label={`Tag ${customerIdsSelected.length} ${i18n.t('tenants')}`}
                        title={`Tag ${customerIdsSelected.length} ${i18n.t('tenants')}`} 
                      >
                        <Button
                          className={classes.navButton}
                          onClick={() => setOpenTagPicker(!openTagPicker)}
                          ref={tagPickerAnchorRef}
                        >
                          <GroupIcon />
                          <div>{'Tag'}</div>
                        </Button>
                      </Tooltip>
                      <TagCustomersPicker
                        anchorEl={tagPickerAnchorRef.current}
                        open={openTagPicker}
                        setOpen={setOpenTagPicker}
                        tagActionCallback={handleTagSelected}
                      />
                    </div>
                  </>
                : <>
                  <div 
                    className={classNames(
                      classes.toggleContainer
                    )}
                  >
                    <Tooltip 
                      aria-label={`Add new ${i18n.t('tenant')}`}
                      title={`Add new ${i18n.t('tenant')}`} 
                    >
                      <Button
                        className={classes.navButton}
                        onClick={() => setOpenAddTenantDialog(true)}
                      >
                        <i className={'icon-add'} />
                        <div>{i18n.t('Tenant')}</div>
                      </Button>
                    </Tooltip>
                  </div>
                  <div 
                    className={classNames(
                      classes.toggleContainer
                    )}
                  >
                    <Tooltip 
                      aria-label={`Upload ${i18n.t('tenants')}`}
                      title={`Upload ${i18n.t('tenants')}`} 
                    >
                      <Button
                        className={classes.navButton}
                        onClick={handleEnableUpload}
                      >
                        <PublishIcon />
                        <div>{'Upload'}</div>
                      </Button>
                    </Tooltip>
                  </div>
                </>
              }
              <div className={classes.grow} />
              <FilterSearchBox
                  classes={{
                    root: classes.customerSearchBox
                  }}
                  handleSetSearchInputValue={handleSetSearchInputValue}
                  placeholderValue='Search by name or phone'
                  searchInputValue={searchTenantValue}
              />
            </Grid>
          </Grid>
          <AppLoadingSpinner />
        </Grid>
      </Toolbar>
      <AddTenantDialog
        open={openAddTenantDialog}
        setOpen={setOpenAddTenantDialog}
        triggerAction={handleAddTenant}
      />
    </AppBar>
  );
};

export default withStyles(styles)(TenantsHeader);
