// @flow

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';
import SPBackend from '../../services/SPBackend';

import TagsHeader from './TagsHeader';
import TagsContent from './TagsContent';
import MemberDrawer from '../MemberDrawer';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/TagsHome.Style';

type Props = {
  classes: Object
};

const TagsHome = (props: Props) => {
  const {
    classes
  } = props;
  
  const [store, dispatch] = useStore();
  const { 
    user,
    i18n,
    tenantsPageInfo,
    tags
  } = store;

  const [mobileOpen, setMobileOpen] = useState(false);

  const usehandleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Neo | Tags</title>
      </Helmet>
      <TagsHeader
        mobileOpen={mobileOpen}
        tags={tags} 
        usehandleDrawerToggle={usehandleDrawerToggle}
      />
      
      <MemberDrawer 
        mobileOpen={mobileOpen} 
        usehandleDrawerToggle={usehandleDrawerToggle}
      />

      <TagsContent />
    </div>
  );
};

export default withStyles(styles)(TagsHome);

