// @flow

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useStore } from '../../store';
import * as R from 'ramda';
import SPBackend from '../../services/SPBackend';
import { log } from '../../utils/jsUtils';
import MemberDrawer from '../MemberDrawer';
import BotSettingsHeader from './BotSettingsHeader';
import BotSettingsContent from './BotSettingsContent';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/BotSettingsHome.Style';

type Props = {
  classes: Object
};

const BotSettingsHome = (props: Props) => {
  const { classes } = props;

  // drawer state & controls
  const [mobileOpen, setMobileOpen] = useState(false);

  const usehandleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [store] = useStore();
  const {
  } = store;

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Neo | Bot</title>
      </Helmet>
      <BotSettingsHeader
        mobileOpen={mobileOpen} 
        usehandleDrawerToggle={usehandleDrawerToggle}
      />
      <MemberDrawer 
        mobileOpen={mobileOpen} 
        usehandleDrawerToggle={usehandleDrawerToggle}
      />
      <BotSettingsContent /> 
    </div>
  );
};

export default withStyles(styles)(BotSettingsHome);