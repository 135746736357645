// @flow

import React, { useState, useEffect, useRef } from 'react';
import { Liquid } from 'liquidjs';
import classNames from 'classnames';

import { log } from '../../utils/jsUtils';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import InfoIcon from '@material-ui/icons/Info';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/LiquidMessageEditor.Style';

import type { Tenant } from '../../services/SPBackend';

type Props = {
  classes: Object,
  recipients: Array<Tenant>
}

const engine = new Liquid();

const LiquidMessageEditor = (props: Props) => {
  const {
    recipients,
    message,
    setMessage,
    emptyErrorMsg,
    isSending,
    classes
  } = props;
  const textareaRef = useRef();
  const btn1AnchorRef = useRef();

  const [previewToggleBtnValue, setPreviewToggleBtnValue] = useState('editor');
  const [msgPreview, setMsgPreview] = useState('');
  const [isMsgPreviewError, setIsMsgPreviewError] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [refocusOnNewCursorPosition, setRefocusOnNewCursorPosition] = useState(0);
  const [openCustomerOptions, setOpenCustomerOptions] = useState(false);
  
  const handlePreviewToggleBtns = (e, newSelection) => {
    log('[handlePreviewToggleBtns] newSelection: ', newSelection);
    setPreviewToggleBtnValue(newSelection);
  };

  useEffect(() => {
    const renderPreview = async() => {
      try {
        const [recipient] = recipients;
        log('recipient: ', recipient);
        const output = await engine.parseAndRender(message, recipient);
        log('output: ', output);
        setMsgPreview(output);
        setIsMsgPreviewError(false);
      } catch (e) {
        log('[liquid render error] ', e);
        log('[liquid render error] e.message', e.message);
        setMsgPreview(e.message);
        setIsMsgPreviewError(true);
      }
    };
    renderPreview();
  }, [recipients, message]);

  const handleCursorPositionChange = (e) => {
    log('[handleCursorPositionChange]...');
    e.stopPropagation();
    e.preventDefault();
    log('[handleTextChange] new cursor Position: ', e.target.selectionStart);
    setCursorPosition(e.target.selectionStart);
  };

  const handleTextChange = (e) => {
    log('[handleTextChange]...');
    emptyErrorMsg();
    if (message.length <= 1600) {
      setMessage(e.target.value);
      handleCursorPositionChange(e);
    }
  };

  useEffect(() => {
    if (refocusOnNewCursorPosition > 0) {
      textareaRef.current.selectionStart = cursorPosition;
      textareaRef.current.selectionEnd = cursorPosition;
      log('textareaRef.current2: ', textareaRef.current.selectionStart);
      textareaRef.current.focus();

    }
  }, [refocusOnNewCursorPosition]);

  const insertVariable = (varName) => {
    const textBeforeCursorPosition = message.substring(0, cursorPosition);
    const textAfterCursorPosition = message.substring(cursorPosition, message.length);
    log('[insertVariable] cursorPosition: ', cursorPosition);
    log('[insertVariable] textBeforeCursorPosition: ', textBeforeCursorPosition);
    log('[insertVariable] textAfterCursorPosition: ', textAfterCursorPosition);
      
    setMessage(`${textBeforeCursorPosition}{{${varName}}}${textAfterCursorPosition}`);
    setCursorPosition(cursorPosition + varName.length + 4);
    setRefocusOnNewCursorPosition(refocusOnNewCursorPosition + 1);
  };

  const handleToggleCustomerOptions = (force = null) => {
    if (force !==null) {
      setOpenCustomerOptions(force);  
    }
    setOpenCustomerOptions(!openCustomerOptions);
  };


  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenCustomerOptions(false);
    }
  }

  return (
    <div id='MessageTenantDialog'>
      <ToggleButtonGroup
        classes={{root: classNames(
          classes.toggleNavButtons,
          classes.secondInput)
        }}
        color="primary"
        exclusive
        onChange={handlePreviewToggleBtns}
        value={previewToggleBtnValue}
      >
        <ToggleButton 
          classes={{root: classes.navBtnDeselected, selected: classes.navBtnSelected}} 
          value="editor"
        >Editor</ToggleButton>
        <ToggleButton 
          classes={{
            root: classNames(
              classes.navBtnDeselected,
              isMsgPreviewError ? classes.error: null
            ),
            selected: classes.navBtnSelected}} 
          value="preview"
        >Preview</ToggleButton>
      </ToggleButtonGroup>
      {/* <div className={classes.toolbar}>
              <Button onClick={() => insertVariable('FirstName')}>FirstName</Button>
      </div> */}
      <div className={classes.inputCtr}>
      {
        previewToggleBtnValue === 'preview'
        ? <div className={classes.previewZone}>
            {
              isMsgPreviewError
              ? <span className={classes.error}>Syntax error: {msgPreview}</span>
              : msgPreview
            }
          </div>
        : <div className={classes.EditZone}>
            <Paper classes={{root: classNames(classes.textAreaCtr, classes.squareTheme, classes.simpleBorder)}}
              component="div"
            >
              <div className={classes.toolbarCtr}>
                <Tooltip
                  placement='top-end'
                  title={'Insert a dynamic field'}
                >
                  <Button
                    aria-controls={openCustomerOptions ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    className={classes.toolbarMenuBtn}
                    endIcon={<ArrowDropDownIcon />}
                    onClick={handleToggleCustomerOptions}
                    ref={btn1AnchorRef}
                  >
                    Dynamic Content
                  </Button>
                </Tooltip>
                <div className={classes.grow} />
                {/* <IconButton aria-label="mergetags">
                  <InfoIcon />
                </IconButton> */}
              </div>
              <Popper anchorEl={btn1AnchorRef.current}
                      className={classNames(classes.menuList, classes.squareTheme)}
                      disablePortal
                      // role={undefined}

                      open={openCustomerOptions}
                      transition
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
                  >
                    <Paper classes={{root: classNames(classes.squareTheme)}}>
                      <ClickAwayListener onClickAway={() => handleToggleCustomerOptions(false)}>
                        <MenuList autoFocusItem={openCustomerOptions}
                                  id="menu-list-grow"
                                  onKeyDown={handleListKeyDown}
                        >
                          <MenuItem className={classes.menuTitle}>Customer Info</MenuItem>
                          <MenuItem onClick={() => {handleToggleCustomerOptions(false); insertVariable('FirstName');}}>First Name</MenuItem>
                          <MenuItem onClick={() => {handleToggleCustomerOptions(false); insertVariable('LastName');}}>Last Name</MenuItem>
                          <MenuItem onClick={() => {handleToggleCustomerOptions(false); insertVariable('Email');}}>Email Address</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              <InputBase
                className={classNames(classes.largeTextField, classes.squareTheme)}
                id='message'
                inputProps={{ 'aria-label': 'search google maps' }}
                inputRef={textareaRef}
                label='Message'
                multiline
                onBlur={handleCursorPositionChange}
                onChange={handleTextChange}
                onClick={handleCursorPositionChange}
                placeholder="Search Google Maps"
                placeholder='Write your text message here'
                readonly={isSending}
                rows={10}
                rows={10}
                value={message}
              />
              {/* <IconButton aria-label="search"
className={classes.iconButton}
type="submit"
              >
                <SearchIcon />
              </IconButton>
              <Divider className={classes.divider}
orientation="vertical"
              />
              <IconButton aria-label="directions"
className={classes.iconButton}
color="primary"
              >
                <DirectionsIcon />
              </IconButton> */}
            </Paper>
            
            {/* <TextField
              classes={{
                root: classNames(
                  classes.largeTextField
                )
              }}
              fullWidth
              id='message'
              inputRef={textareaRef}
              multiline
              variant='outlined'
            /> */}
          </div>
      }
      </div>
    </div>
  );
};

export default withStyles(styles)(LiquidMessageEditor);
