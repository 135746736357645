// @flow

const styles = (theme: Object) => ({
  flexContainer: {
    display: 'flex',
    flexDirection: 'column'
  }
});

export default styles;
