// @flow

// import { initializeApp } from 'firebase/app';
// import { auth as fbAuth } from 'firebase';
// import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import 'firebase/compat/auth';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { log } from '../utils/jsUtils';

// TODO: once register works, replace this by env-specific vars.
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_AUTH_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_AUTH_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_AUTH_PROJECTID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_AUTH_MESSAGING_SENDER_ID
};

const firebaseApp = initializeApp(config);
const auth = getAuth(firebaseApp);
// export const onAuthStateChanged = onAuthStateChanged;

// we use setFreshIdToken to set asynchronously the newtoken in the state.
const getNewIdToken = async(currentUser: Object): Promise<?string> => {
  log('[firebase.getNewIdToken] currentUser: ', currentUser);
  if (typeof currentUser !== 'undefined' && currentUser !== null) {
    return currentUser.getIdToken()
      .then(token => {
        if (process.env.NODE_ENV === 'development') {
          log('[firebase] new tokenId: ', token);
        }
        return token;
      })
      .catch(err => {
        log('[firebase.getNewIdToken] error: ', err);
        return err.message;
      });
  } else {
    return new Promise((resolve, reject) => resolve(null));
  }
};

export {
  auth,
  onAuthStateChanged,
  getNewIdToken
};