// @flow

import React, { useState } from 'react';
import classNames from 'classnames';
import { log } from '../../utils/jsUtils';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/AddUnitDialog.Style';

type Props = {
  classes: Object,
  setOpen: Function,
  open: boolean,
  triggerAction: Function
}

const AddUnit = (props: Props) => {
  const {
    classes,
    setOpen,
    open,
    triggerAction
  } = props;
  const [unit, setUnit] = useState({
    Apt: '',
    Floor: ''
  });
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <Dialog
      aria-labelledby='form-add-unit'
      onClose={() => setOpen(false)}
      open={open}
    >
      <form
        autoComplete='off'
        className={classes.dialogFormCtr}
        onSubmit={(e) => { 
          e.preventDefault();

          // Sets error state back to blank before rechecking.
          setErrorMessage('');
          
          if (unit.Floor.length <= 0 || unit.Apt.length <= 0) {
            setErrorMessage('Please fill in all the attributes.');
          } else {
            triggerAction(unit); 
            setOpen(false); 
          }
        }}
        noValidate
      >
          <DialogTitle id='form-dialog-title'>
          {'Add a unit'}
          </DialogTitle>
          <DialogContent className={classNames(classes.grow, classes.flexContainer)}>
          <DialogContentText className={classNames(classes.error)}>{ errorMessage }</DialogContentText>
            <Input
                autoFocus
                fullWidth
                id='broadcastIssue'
                label='Floor'
                margin='dense'
                onChange={(e) => setUnit({
                  ...unit,
                  Floor: e.target.value
                  })
                }
                placeholder='Floor'
                type='text'
                value={unit.Floor}
            />
            <Input
                classes={{
                  root: classes.secondInput
                }}
                fullWidth
                id='broadcastIssue'
                label='Apartment name (e.g. 2 East)'
                margin='dense'
                onChange={(e) => setUnit({
                  ...unit,
                  Apt: e.target.value
                })}
                placeholder='Apartment name (e.g. 2 East)'
                type='text'
                value={unit.Apt}
            />
          </DialogContent>
          <DialogActions>
            <Button color='primary'
              onClick={() => {setErrorMessage(''); setOpen(false);}}
            >
              {'Cancel'}
              </Button>
            <Button 
              color='primary'
              type='submit'
              variant='contained'
            >
              {'Add'}
              </Button>
          </DialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(AddUnit);