// @flow

import React, { useEffect, useRef, useState } from 'react';
import { useStore } from '../../store';
import clsx from 'clsx';

import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';

import { log } from '../../utils/jsUtils';
import RequestHistoryEntry from './RequestHistoryEntry';
import TaskV2Activity from './TaskV2Activity';
import FeedRow from './FeedRow';
import SendReplyBox from '../Tenants/SendReplyBox';

import { calculateCreditsForMsg } from '../../models/Message';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/RequestContent.Style';
import listTableStyles from '../Styles/CommonListTable.Style';
import FeedTableStyles from './Styles/CommonFeedTable.Style';
import combineStyles from '../../utils/stylesUtils';
import SPBackend from '../../services/SPBackend';

import type { ChatEntry, Task, Unit, Tenant } from '../../services/SPBackend';

type Props = {
  classes: Object,
  requestHistory: Array<ChatEntry>,
  isV2Format: boolean,
  request: Task,
  tenant: Tenant,
  setTenant: Function,
  unit: Unit,
  loadingRequest: boolean,
  updateTaskCallback: Function,
  refreshRequestDetails: Function
};



const AlwaysScrollToBottom = (props) => {
  const {
    setScrollRef,
    refreshScrollDown
  } = props;

  log('[AlwaysScrollToBottom]...');
  const elementRef = useRef();
  const scrollToBottom = () => {
    setTimeout(() => {
      elementRef.current.scrollIntoView();
    }, 300);
  };
  useEffect(() => {
    setScrollRef();
    scrollToBottom();
  }, []);
  useEffect(() => {
    log('[useEffect] refresh scroll down...');
    setScrollRef();
    scrollToBottom();
  }, [refreshScrollDown]);
  return <div ref={elementRef} />;
};

// eslint-disable-next-line react/no-multi-comp
const RequestContent = (props: Props) => {
  const [store, dispatch] = useStore();
  const {
    classes,
    requestHistory,
    isV2Format,
    tenant,
    setTenant,
    unreadCount,
    request,
    unit,
    loadingRequest,
    updateTaskCallback,
    refreshRequestDetails
  } = props;

  const [scrollRef, setScrollRef] = useState(null);
  const [refreshScrollDown, setRefreshScrollDown] = useState(true);

  const {
    user,
    i18n,
    accountUsage
  } = store;

  
  const handleSendReply = (tenantIds, messagePayload, successCallback, failureCallback) => {
    log('[handleSendReply][sendPMToTenant] tenantIds, message: ', tenantIds, messagePayload);
    log('[handleSendReply][sendPMToTenant] msg line split: ', messagePayload.message.split('\n'));
    // verify that credit balance is sufficient
    const creditsNeeded = calculateCreditsForMsg(messagePayload);
    log('[sendBroadcast] creditsNeeded: ', creditsNeeded);
    const [tenantId] = tenantIds;
    if (accountUsage.TotalCreditsBalance < creditsNeeded) {
      failureCallback('Insufficient credits. Visit <a href="/billing">Billing</a> to recharge your account or upgrade your plan');
    } else {
      if (user !== null) {
        SPBackend.sendPMToTenant(tenantId, request._id, messagePayload, user)
          .then((response) => {
            const { data } = response;
            log('[handleSendReply][sendPMToTenant] tenantId, response: ', tenantId, response);
            dispatch({
              type: 'ACTION_SET_SNACK_MSG', 
              snackMessage: {
                message: data.message,
                level: data.success,
                duration: 3000
              }
            });
            refreshRequestDetails(() => {
              setTimeout(() => {
                setRefreshScrollDown(!refreshScrollDown);
              }, 300);
            });
            successCallback();
            // setTimeout(() => {
            //   console.log('scrolling down...', scrollRef);
            //   if (scrollRef !== null && typeof scrollRef !== 'undefined' ) {
            //     scrollRef.current.scrollIntoView();
            //   }
            // }, 300);
          })
          .catch(error => {
            log('[handleSendReply][sendPMToTenant] error: ', error);
            dispatch({
              type: 'ACTION_SET_SNACK_MSG', 
              snackMessage: {
                message: 'An error occured. Please try again.',
                level: 'error',
                duration: 3000
              }
            });
            failureCallback();  
          });
      }
    }
  };

  log('[RequestContent] props: ', props);
  return (
    <Grid 
      // className={classes.tableContent}
      classes= {{
        root: classes.tableRequestContent
      }}
    >
      <div className={classes.toolbar} />
      {
        requestHistory && request
        ? <div>
          {
            request
            ? [
              /** MOBILE VERSION **/
              <Hidden
                key='preview-mobile'
                mdUp
              >
                <FeedRow
                  classes={{
                    mobileFeedItem: classes.requestPreviewCtrMobile
                  }}
                  key={'request'}
                  readonly
                  request={request}
                  setTenant={setTenant}
                  units={unit ? [unit] : null}
                  updateTaskCallback={updateTaskCallback(request)}
                />
              </Hidden>,
              /** DESKTOP VERSION **/
              <Hidden
                key='preview-desktop'
                smDown
              >
                <div className={classes.requestPreviewCtr}>
                  <Table className={classes.table}>
                    <colgroup>
                      <col className={classes.colRequest} />
                      <col className={classes.colCustomerName} />
                      <col className={classes.colType} />
                      <col className={classes.colRequest} />
                      {/* <col className={classes.colStatus} /> */}
                      <col className={classes.colAssignee} />
                      <col className={classes.colDate} />
                      <col classes={classes.colAction} />
                    </colgroup>
                    <TableHead className={classes.feedTableBody}>
                      <TableRow className={classes.feedTableHeadRow}>
                        <TableCell className={classes.customerNameCell}>
                          <span style={{display: 'block', width: 30}} />
                          {i18n.t('Customer')}
                        </TableCell>
                        <TableCell className={classes.tagCell}>{i18n.t('Tag')}</TableCell>
                        <TableCell className={classes.requestCell}>
                          {i18n.t('Subject')}
                        </TableCell>
                        <TableCell 
                        align= 'left'
                          className={classes.assigneeCell}
                        >{i18n.t('Staff')}</TableCell>
                        {/* <TableCell
                          align='left'
                          className={classes.statusCell}
                        >{'Progress'}
                        </TableCell> */}
                        <TableCell
                          align='right'
                          className={classes.dateCell}
                        >{'Last Updated'}</TableCell>
                        
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.feedTableBody}>
                      <FeedRow
                        key={'request'}
                        readonly
                        request={request}
                        setTenant={setTenant}
                        units={unit ? [unit] : null}
                        updateTaskCallback={updateTaskCallback(request)}
                      />
                    </TableBody>
                  </Table>
                </div>
              </Hidden>
            ]
            : <Grid className={classes.content}
                item
                xs={12}
              >{'Loading...'}</Grid>
          }
          <div className={classes.messagesArea}>
          {
            isV2Format
            ? <TaskV2Activity
                history={requestHistory}
                task={request}
                tenant={tenant}
              />
            : <List className={classes.listRoot}>
              {
                requestHistory.map((chat, chatIndex) => {
                  return (
                    <RequestHistoryEntry 
                      chat={chat}
                      firstEntry={chatIndex == (requestHistory.length - 1)}
                      key={`entry-${chatIndex}`}
                      summary={chat.Summary || chat.Comment}
                    />
                        
                    );
                })
              }
              </List>
          }
          <AlwaysScrollToBottom refreshScrollDown={refreshScrollDown}
setScrollRef={setScrollRef}
          />
          <div className={classes.replyBox}>
          <SendReplyBox  
              tenant={tenant}
              triggerAction={handleSendReply}
          />
          </div>
          </div>
          {
            unreadCount > 0
            ? <div className={classes.unreadAnchorCtr}>
              <Paper
                className={classes.unreadAnchorPopperContent}
                onClick={refreshRequestDetails}
              >
                {`${unreadCount} unread message${unreadCount > 1 ? 's' : ''}. Click to read.`}
              </Paper>
            </div>
            : null
          }
        </div>
        : loadingRequest
          ? <Grid className={classes.content}
              item
              xs={12}
            >{'Loading...'}</Grid>
          : <div>{'Request not found.'}</div>
      }
    </Grid>
    
  );
};

const combinedStyles = combineStyles(
  listTableStyles,
  styles,
  FeedTableStyles
);
export default withStyles(combinedStyles)(RequestContent);