// @flow

import React, { useState, useEffect } from 'react';
import { useStore } from '../../store';

import { log } from '../../utils/jsUtils';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ZapierImg from '../../assets/zapier.png';

import { withStyles } from '@material-ui/core/styles';
import MemberStructuralStyles from '../Styles/CommonMemberStructuralStyles.Style';
import styles from './Styles/IntegrationsContent.Style';
import combineStyles from '../../utils/stylesUtils';

type Props = {
  classes: Object
};

function EmbedZapierScript() {
  useEffect(() => {
    const widget = document.createElement('script');
    widget.type = 'text/javascript';
    widget.async = true;
    // widget.src = 'https://zapier.com/apps/embed/widget.js?services=mailchimp&html_id=zapier-widget';
    widget.src = 'https://zapier.com/apps/embed/widget.js?services=askneo&html_id=zapier-widget';
    document.body.appendChild(widget);
  }, []);

  return <div id="zapier-widget" />;
}

// eslint-disable-next-line react/no-multi-comp
const IntegrationsContent = (props: Props) => {
  const { classes } = props;

  return (
    <Grid item>
      <div className={classes.toolbarHalf} />
      <Grid className={classes.contentCtr}
            item
            xs={12}
      >
        <a className={classes.ctaBtnAnchor}
          href='https://zapier.com/apps/askneo/integrations'
          target='_blank'
        ><Button
          className={classes.ctaBtn}
          color='secondary'
          endIcon={<OpenInNewIcon />}
          startIcon={<img height={24}
                          src={ZapierImg}
                     />}
          variant='contained'
        >
          Connect Askneo to 4000+ apps with Zapier
          </Button></a>
        <Typography variant='h3'><br />Ready-to-use automations</Typography>
        <EmbedZapierScript />

      </Grid>
    </Grid>
  );
};

const combinedStyles = combineStyles(
  styles,
  MemberStructuralStyles
);

export default withStyles(combinedStyles)(IntegrationsContent);