import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useStore } from '../../store';

import { log } from '../../utils/jsUtils';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SPBackend from '../../services/SPBackend';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import styles from './Styles/TenantsFileDrop.Style';
import { withStyles } from '@material-ui/core/styles';

// //
import { Importer, ImporterField } from 'react-csv-importer';
// include the widget CSS file whichever way your bundler supports it
import 'react-csv-importer/dist/index.css';


type Props = {
  classes: Object
};

const baseStyle = {
  flex: '0 1 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '10% 20%',
  margin: '10px',
  borderWidth: 0,
  borderRadius: 0,
  borderColor: '#eeeeee',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const TenantsDropzone = (props: Props) => {
  const { classes } = props;

  const [store, dispatch] = useStore();
  const { user, companyName, userFullName, accountSettings } = store;
  console.log('[TenantsDropzone] accountSettings: ', accountSettings);
  const [fileDropSuccess, setFileDropSuccess] = useState(false);
  const [acceptedFile, setAcceptedFile] = useState('');

  const handleHideDropZone = () => {
    dispatch({
      type: 'ACTION_SET_TENANTS_ENABLE_UPLOAD',
      tenantEnableUpload: false
    });
  };
  
  useEffect(() => {
    if (!acceptedFile) return;
    dispatch({
      type: 'ACTION_SET_IS_APP_LOADING',
      isAppLoading: true
    });
    SPBackend.uploadTenantFile(acceptedFile, user)
      .then(() => {
        dispatch({
          type: 'ACTION_SET_IS_APP_LOADING',
          isAppLoading: false
        });
        setFileDropSuccess(true);
      })
      .catch(err => {
        // log('[handleUploadTenantFile] error: ', err.response);
        dispatch({
          type: 'ACTION_SET_IS_APP_LOADING',
          isAppLoading: false
        });
        log(err);
        if (err.response.status === 413) {
          dispatch({
            type: 'ACTION_SET_SNACK_MSG',
            snackMessage: {
              message: 'File exceeds maximum size (5MB).',
              level: 'error',
              duration: 5000
            }
          });
        } else {
          dispatch({
            type: 'ACTION_SET_SNACK_MSG',
            snackMessage: {
              message: 'Something went wrong with customer file upload.',
              level: 'error',
              duration: 5000
            }
          });
        }
      });
  }, [acceptedFile]);

  const onDrop = useCallback(addedFile => {
    console.log('[handleUploadTenantFile]', addedFile[0].size);
    if (addedFile[0].size > 5000000) {
      dispatch({
        type: 'ACTION_SET_SNACK_MSG',
        snackMessage: {
          message: 'File exceeds maximum size (5MB).',
          level: 'error',
          duration: 5000
        }
      });
    }
    setAcceptedFile(addedFile[0]);
  });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ 
    multiple: false,
    onDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  // //
  const prepMyAppForIncomingData = (props) => {
    log('[CSV][prepMyAppForIncomingData] props: ', props);
  };

  const goToMyAppNextPage = (props) => {
    log('[CSV][goToMyAppNextPage] props: ', props);
  };

  const showMyAppToastNotification = (props) => {
    log('[CSV][showMyAppToastNotification] props: ', props);
  };

  const myAppMethod = async(row) => {
    log('[CSV][myAppMethod] row: ', row);
    // Validate format

    // TODO call backend to upsert each row
    try {
      const res = await SPBackend.upsertTenant(row, user);
      return res;
    } catch (e) {
      log('[myAppMethod] error: ', e);
    }
  };

  const enUSImporterLocale = {
    general: {
        goToPreviousStepTooltip: 'Go to previous step'
    },
    fileStep: {
        initialDragDropPrompt: 'Drag-and-drop CSV file here. Make sure to include these columns at a minimum: First name, phone number (formatted with international prefix like this: 17182937112)',
        activeDragDropPrompt: 'Drop CSV file here...',
        getImportError: (message) => `Import error: ${message}`,
        getDataFormatError: (message) => `Please check data formatting: ${message}`,
        goBackButton: 'Go Back',
        nextButton: 'Choose columns',
        rawFileContentsHeading: 'Raw File Contents',
        previewImportHeading: 'Preview Import',
        dataHasHeadersCheckbox: 'Data has headers',
        previewLoadingStatus: 'Loading preview...'
    },
    fieldsStep: {
        stepSubtitle: 'Select Columns',
        requiredFieldsError: 'Please assign all required fields',
        nextButton: 'Import',
        dragSourceAreaCaption: 'Columns to import',
        getDragSourcePageIndicator: (currentPage, pageCount) => `Page ${currentPage} of ${pageCount}`,
        getDragSourceActiveStatus: (columnCode) => `Assigning column ${columnCode}`,
        nextColumnsTooltip: 'Show next columns',
        previousColumnsTooltip: 'Show previous columns',
        clearAssignmentTooltip: 'Clear column assignment',
        selectColumnTooltip: 'Select column for assignment',
        unselectColumnTooltip: 'Unselect column',
        dragTargetAreaCaption: 'Target fields',
        getDragTargetOptionalCaption: (field) => `${field} (optional)`,
        getDragTargetRequiredCaption: (field) => `${field} (required)`,
        dragTargetPlaceholder: 'Drag column here',
        getDragTargetAssignTooltip: (columnCode) => `Assign column ${columnCode}`,
        dragTargetClearTooltip: 'Clear column assignment',
        columnCardDummyHeader: 'Unassigned field',
        getColumnCardHeader: (code) => `Column ${code}`
    },
    progressStep: {
        stepSubtitle: 'Import',
        uploadMoreButton: 'Upload More',
        finishButton: 'Finish',
        statusError: 'Could not import',
        statusComplete: 'Complete',
        statusPending: 'Importing...',
        processedRowsLabel: 'Processed rows:'
    }
};

  return (
    <div>
    {
      !accountSettings.EnableCustomersSelfUpload && fileDropSuccess 
      ? <div className={classes.confirmationBox}>
          <Typography
            className={classes.fileHeader}
            variant='h5'
          >{'Got it!' }
          </Typography>
          <Typography className={classes.fileDescription}
variant='body1'
          >
            <br /> {'If you just shared your customers\' list with us, please allow up to 24h for us to sync your account with the new changes.'}
            <br />{'Notifying current customers is key to start engaging with them by text, and a great way to remind them of your business in a personal way!'}
          </Typography>
          <Typography className={classes.fileDescription}
variant='body1'
          >
            {'Read our recommendations on how to provide the best customer experience through texting: '}
            <a href="https://askneo.io/blog/how-texting-can-help-build-trust-with-customers/"
                      rel="noopener noreferrer"
                      target="_blank"
            > {'Go to our blog post!'}
            </a>
          </Typography>
          <Button
            className={classes.confirmationBtn}
            onClick={handleHideDropZone}
            variant='outlined' 
          >{'Got it!'}</Button>
        </div>
      : null
    }
    {
      !accountSettings.EnableCustomersSelfUpload && !fileDropSuccess
      ? <div className={classes.boxRow}>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />

            <div className={classes.innerbox}>
              <Grid className={classes.center}>
                <Typography
                  className={classes.fileHeader}
                  variant='h5'
                >
                  {'Drag and drop / upload your customers\' list here with name & phone number.'}
                </Typography>
                <CloudUploadIcon className = {classes.uploadIcon} />
              </Grid>
              <Grid className={classes.left}>
                <Typography
                  className={classes.fileHeader}
                  variant='subtitle1'
                >
                  <br />
                  {'Formats accepted: Excel(xls,...), CSV'}
                  <br />
                  <br />
                  {
                    'Give us up to 24h to sync with your account and, you can then notify your customers by broadcasting a message such as:'
                  }
                  <br />
                  <i>{`"Hello! This is ${userFullName} with ${companyName}. We just enabled text messaging for our customers! So save our number and feel free to send us texts and images anytime! We\'ll text you back right here :)"`}</i>
                </Typography>
              </Grid>
            </div>
          </div>
        </div>
      : null
    }
    {
      accountSettings.EnableCustomersSelfUpload
      ? <div className={classes.box}>
        {/* Self service cpt */}
        <div className={classNames(classes.boxRowExperimental, classes.boxRow)}>
          <h1>Experimental</h1>
          <h2>Upload your customers in a CSV and have them processed in real time.</h2>
          <Importer
            assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
            locale={enUSImporterLocale} // optional, lets user choose to upload another file when import is complete
            onClose={({ file, preview, fields, columnFields }) => {
              // optional, if this is specified the user will see a "Finish" button after import is done,
              // which will call this when clicked
              goToMyAppNextPage();
            }}
            onComplete={({ file, preview, fields, columnFields }) => {
              // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
              showMyAppToastNotification();
            }}
            onStart={({ file, preview, fields, columnFields }) => {
              // optional, invoked when user has mapped columns and started import
              prepMyAppForIncomingData();
            }}
            processChunk={async(rows, { startIndex }) => {
              // required, may be called several times
              // receives a list of parsed objects based on defined fields and user column mapping;
              // (if this callback returns a promise, the widget will wait for it before parsing more data)
              for (const row of rows) {
                await myAppMethod(row);
              }
            }}
            restartable={false}

            // CSV options passed directly to PapaParse if specified:
            // delimiter={...}
            // newline={...}
            // quoteChar={...}
            // escapeChar={...}
            // comments={...}
            // skipEmptyLines={...}
            // delimitersToGuess={...}
            // chunkSize={...} // defaults to 10000
            // encoding={...} // defaults to utf-8, see FileReader API
          >
            <ImporterField label="First Name"
              name="FirstName"
            />
            <ImporterField label="Last Name"
              name="LastName"
              optional
            />
            <ImporterField label="Phone"
              name="Phone"
            />
            <ImporterField label="Email"
              name="Email"
              optional
            />
            <ImporterField label="Tags (add multiple tags like this: tag1;tag2)"
              name="Tags"
              optional
            />
          </Importer>

        </div>
      </div>
      : null
    }
    </div>
  );
};

export default withStyles(styles)(TenantsDropzone);
