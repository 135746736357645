// @flow

import { log } from './jsUtils';

const pathAuth = [
  {
    pathname: '/member',
    restricted: true
  },
  {
    pathname: '/properties',
    restricted: true
  },
  {
    pathname: '/property',
    restricted: true
  },
  {
    pathname: '/',
    restricted: false
  },
  {
    pathname: '/login',
    restricted: false
  },
  {
    pathname: '/register',
    restricted: false
  }
];

export const redirectBasedOnAccountStatus = (history, store) => {
  const {
    account,
    user,
    billing,
    defaultBuilding
  } = store;
  const prevPathname = history.location.pathname;
  log('[predirectBasedOnAccountStatus] prevPathname: ', prevPathname);
  if (prevPathname === 'authorize') {
    return;
  }
  log('[redirectBasedOnAccountStatus] user: ', user);
  log('[redirectBasedOnAccountStatus] billing: ', billing);
  if (!user.IsPhoneVerified) {
    log('[redirectBasedOnAccountStatus] redirect to /verifyPhone');
    history.push('/verifyPhone');
  } else if (typeof billing === 'undefined' || !billing.planId) {
    // TODO: not sure if this makes sense.
    // TODO: add a way for the user to logout of the plans screen, if he wants to navigate public screens...
    history.push('/plans');
  // } else if (typeof billing !== 'undefined'
  //   && billing.planId
  //   && billing.subscriptionStatus !== 'active'
  //   && billing.subscriptionStatus !== 'trialing'
  //   && billing.subscriptionStatus !== 'past_due') {
  //   history.push('/checkout');
  } else if ( defaultBuilding && (typeof defaultBuilding.Phone === 'undefined' || defaultBuilding.Phone === null || defaultBuilding.Phone === '')) {
    log('becore history.push');
    history.push('/pickYourPhoneNumber');
  } else if (prevPathname === '/verifyPhone') {
    history.push('/member');
  }
};

export default {
  pathAuth: pathAuth
};