// @flow

import React, { useState } from 'react';
import classNames from 'classnames';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';
import red from '@material-ui/core/colors/red';

import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import BroadcastIcon from '@material-ui/icons/TextsmsOutlined';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/AddPropertyDialog.Style';
import type { Building } from '../../services/SPBackend';

type Props = {
  classes: Object,
  setOpen: Function,
  open: boolean,
  actionConfirm: Function,
  property: Building
}

const RemoveProperty = (props: Props) => {
  const {
    classes,
    setOpen,
    open,
    actionConfirm,
    property
  } = props;

  const [store] = useStore();
  const {
    i18n
  } = store;
  return (
    <div>
    {
      property !== null
      ? <Dialog
          aria-labelledby='form-add-property'
          onClose={() => setOpen(false)}
          open={open}
        >
          <DialogTitle id='form-dialog-title'>
          {'HEADS UP!'}
          </DialogTitle>
          <DialogContent className={classNames(classes.grow, classes.flexContainer)}>
            <DialogContentText>
              {`You are about to remove this ${i18n.t('property')} from your account:`}<br />
              <b style={{color: red[600]}}>{property.Property}</b><br /><br />
              {'Are you sure?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color='primary'
              onClick={() => setOpen(false)}
            >
              {'Cancel'}
              </Button>
            <Button 
              color='primary'
              onClick={() => { actionConfirm(); setOpen(false); }}
              variant='contained'
            >
              {'Remove'}
              </Button>
          </DialogActions>
        </Dialog>
      : null
    }
    </div>
  );
};

export default withStyles(styles)(RemoveProperty);