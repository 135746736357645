// @flow

import React from 'react';
import classNames from 'classnames';
import red from '@material-ui/core/colors/red';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/AddTenantDialog.Style';
import type { Tenant } from '../../services/SPBackend';

type Props = {
  classes: Object,
  setOpen: Function,
  open: boolean,
  actionConfirm: Function,
  tenant: Tenant
}

const RemoveTenantDialog = (props: Props) => {
  const {
    classes,
    setOpen,
    open,
    actionConfirm,
    tenant
  } = props;
  const [store] = useStore();
  const {
    i18n
  } = store;

  // log('[RemoveTenantDialog] open: ', open);
  return (
    <div>
    {
      (tenant !== null && typeof tenant !== 'undefined')
      ? <Dialog
          aria-labelledby='form-add-property'
          onClose={() => setOpen(false)}
          open={open}
        >
          <DialogTitle id='form-dialog-title'>
          {'HEADS UP!'}
          </DialogTitle>
          <DialogContent className={classNames(classes.grow, classes.flexContainer)}>
            <DialogContentText>
              {`You are about to remove this ${i18n.t('tenant')} and all related ${i18n.t('tasks')} from your account:`}<br />
              <b style={{color: red[600]}}>{`${tenant.FirstName} ${tenant.LastName}`}</b><br /><br />
              {'Are you sure?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button 
              color='primary'
              onClick={() => setOpen(false)}
              type='button'
            >
              {'Cancel'}
              </Button>
            <Button 
              color='primary'
              onClick={() => { 
                actionConfirm(); 
                setOpen(false); 
                }
              }
              variant='contained'
            >
              {'Remove'}
              </Button>
          </DialogActions>
        </Dialog>
      : null
    }
    </div>
  );
};

export default withStyles(styles)(RemoveTenantDialog);