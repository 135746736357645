// @flow

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from '../store';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { withStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import UserIcon from '@material-ui/icons/AccountCircle';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import SPBackend from '../services/SPBackend';
import { log } from '../utils/jsUtils';

import Logout from './Logout';
import classNames from 'classnames';
import styles from './Feed/Styles/MemberHeader.Style';
import {getUserRole, ROLE_NAMES} from '../models/User';

type Props = {
  classes: Object,
  isOnboading?: bool, // user is still onboarding, limit options.
  coloring?: 'light'
}

const UserProfileMenu = (props: Props) => {
  const { 
    classes,
    coloring,
    isOnboading
  } = props;
  const [store, dispatch] = useStore();
  const [isUserOpen, setUserOpen] = useState(false);
  const [loadingAccountSwitch, setLoadingAccountSwitch] = useState(false);

  const handleClickAway = () => {
    setUserOpen(false);
  };

  const {
    userFullName,
    user,
    accounts,
    userRole,
    companyName,
    accountId
  } = store;
  // console.log('[UserProfileMenu] accounts: ', accounts);
  
  const switchAccount = (accountId) => async() => {
    setLoadingAccountSwitch(true);
    try {
      const { data } = await SPBackend.switchAccount(user, accountId);
      log('[switchOrganization] data: ', data);

      if (data.message === 'ok') {
        log('[switchOrganization] switching!!');
        window.location.href = '/member';
      } else {
        setLoadingAccountSwitch(false);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: data.message,
            level: 'error',
            duration: 5000
          }
        });
      }
    } catch (error) {
      log('[switchOrganization] error: ', error);
      setLoadingAccountSwitch(false);
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: 'Something went wrong.',
          level: 'success',
          duration: 5000
        }
      });
    }
  };

  return (
    <div className={classNames(
      classes.userProfileSubMenuCtr,
      coloring
    )}
    >
      <List disablePadding>
      <ClickAwayListener onClickAway={handleClickAway}>
        <ListItem
          button
          classes={{
            root: classes.listItem
          }}
          key={'UserAccount'}
          onClick={() => setUserOpen(!isUserOpen)}
        >
          <ListItemText
            primary={userFullName}
            secondary={<React.Fragment>
              <span>{`@${companyName}`}</span>
              <br />
              {
                process.env.REACT_APP_ENVF !== 'env.production'
                ? `${accountId}`
                : null
              }
            </React.Fragment>}
          />
          {/* {isUserOpen ? <ExpandLess /> : <ExpandMore />} */}
          <ListItemIcon className={classes.iconRoot}><UserIcon /></ListItemIcon>
        </ListItem>
        </ClickAwayListener>

        <Collapse
          className={classNames(
            classes.userProfileSubMenuCollapse,
            coloring
          )}
          in={isUserOpen}
          timeout="auto"
          unmountOnExit
        >
          <Logout>
            <ListItem
              button
              className={classes.subListItem}
              key={'Logout'}
            >
              <ListItemText
                classes={{
                  root: classes.itemTextCtr
                }}
                primary={'Logout'}
              />
              <ListItemIcon className={classes.iconRoot}>
                <LogoutIcon />
              </ListItemIcon>
            </ListItem>
          </Logout>
          <Divider />
        {
          accounts && accounts.length > 1
          ? [
            <ListSubheader
              className={classes.switchActHeader}
              key='accountheader'
            >{'Switch Account'}</ListSubheader>,
            ...accounts.map((act, actIdx) => (
              act._id !== accountId
              ? <ListItem
                  button
                  className={classes.subListItem}
                  key={`account-${actIdx}`}
                  onClick={switchAccount(act._id)}
                >
                  <ListItemText
                    classes={{
                      root: classes.itemTextCtr
                    }}
                    primary={`@${act.CompanyName}`}
                  />
                  <ListItemIcon className={classes.iconRoot}><UserIcon /></ListItemIcon>
                </ListItem>
              : null
            ))
          ]
          : null
        }
        {/* {
          !isOnboading
          && userRole === ROLE_NAMES.OWNER
          ? <Link 
              to='/billing'
            >
              <ListItem
                button
                className={classes.listItem}
                key={'Billing'}
              >
                <ListItemText
                  classes={{
                    root: classes.itemTextCtr
                  }}
                  primary={'Billing'}
                />
                <ListItemIcon className={classes.iconRoot}>
                  <CreditCardIcon />
                </ListItemIcon>
              </ListItem>
            </Link>
            : null
        } */}
          
        </Collapse>
      </List>
    </div>
  );
};

export default withStyles(styles)(UserProfileMenu);
