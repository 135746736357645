// @flow

const MENU_ITEM_HEIGHT = 48;
const styles = (theme: Object) => ({
  maintenance: {
    backgroundColor: theme.palette.tags.maintenance.main
  },
  rent: {
    backgroundColor: theme.palette.tags.rent.main
  },
  complaint: {
    backgroundColor: theme.palette.tags.complaint.main
  },
  moving: {
    backgroundColor: theme.palette.tags.moving.main
  },
  other: {
    backgroundColor: theme.palette.tags.other.main
  },
  hover: {
    opacity: 0.75
  },
  tagContentCtr: {
    height: 20,
    alignItems: 'flex-end'
  },
  menuRoot: {
    // top: `${theme.layout.customProps.header.height * 2}px !important`,
    marginTop: 48,
    border: `1px solid ${theme.palette.border.color}`,
    borderRadius: theme.spacing(0.5),
    backgroundColor: 'white'
  },
  menuList: {
    maxHeight: MENU_ITEM_HEIGHT * 4.5
  },
  subMenuListRoot: {
    paddingTop: 0,
    paddingBottom: 0,
    '& li': {
      // color: 'white'
    }
  },
  subListItem: {
    minHeight: 48,
    fontSize: '1rem',
    paddingLeft: theme.spacing(2.75),
    paddingRight: theme.spacing(2.75)
  },
  subListItemAddNew: {
    borderTop: `1px solid ${theme.palette.border.color}`,
    minHeight: 48,
    fontSize: '1rem',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: 'hsl(0,0%,97%)' // theme.palette.primary.lighter, 
    // color: 'hsl(0,0%,25%)'
  },
  colorPickerIcon: {
    cursor: 'pointer',
    position: 'relative',
    top: 2
  },
  saveIcon: {
    padding: 0,
    margin: 0,
    '& svg': {
      fontSize: '1.4rem'
    }
  }
});

export default styles;