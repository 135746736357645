// @flow

import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';
import SPBackend from '../../services/SPBackend';

import PropertiesHeader from './PropertiesHeader';
import PropertiesContent from './PropertiesContent';
import MemberDrawer from '../MemberDrawer';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/PropertiesHome.Style';

import type { Building } from '../../services/SPBackend';

type Props = {
  classes: Object
};

const PropertiesHome = (props: Props) => {
  const { classes } = props;
  
  const [store, dispatch] = useStore();
  const { 
    user,
    accountSettings,
    i18n
  } = store;

  const [mobileOpen, setMobileOpen] = useState(false);
  const [properties, setProperties] = useState([]);
  const [propertiesStats, setPropertiesStats] = useState([]);
  const [ascendOrder, setAscendOrder] = useState(true);

  const removeProperty = (propertyKey) => {
    if (user !== null) {
      SPBackend.removeBuilding(propertyKey, user)
      .then(response => {
        const filterById = p => p.PropertyKey !== propertyKey;
        setProperties(prevProps => {
          const newProps = R.filter(filterById, prevProps);
          return newProps;
        });  
        // Show snack message
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: `${i18n.t('Property')} removed.`,
            level: 'success',
            duration: 3000
          }
        });  
        // dispatch({
        //   type: 'ACTION_SET_ACCOUNT_SETTINGS',
        //   accountSettings: {
        //     ...accountSettings,
        //     SetupRequired: {
        //       ...accountSettings.SetupRequired,
        //       Properties: true
        //     }
        //   }
        // });
      })
      .catch(error => {
        log('[PropertiesHome.fetchFeed.getFeed] error: ', error);
      });
    }
  };

  // Replace position.
  const findIndex = (prevProps: Array<empty>, changedPropertyKey) => {
    let i = 0;
    for (i = 0; i<prevProps.length; i++) {
      if (prevProps[i].PropertyKey === changedPropertyKey) return i;
    }
    return -1;
  };

  const editProperty = (propertyKey, changedProperty) => {
    if (user !== null) {
      SPBackend.editBuilding(propertyKey, changedProperty, user)
        .then(response => {
          const findId = (p) => p.PropertyKey === propertyKey;
          setProperties(prevProps => {
            const valIndex = findIndex(prevProps, propertyKey);
            const changedBuilding = R.find(findId, prevProps);
            changedBuilding.Property = changedProperty.Property;
            const newProps = R.update(valIndex, changedBuilding, prevProps);
            return newProps;
          });
          // Show snack message
          dispatch({
            type: 'ACTION_SET_SNACK_MSG', 
            snackMessage: {
              message: `${i18n.t('Property')} Edited.`,
              level: 'success',
              duration: 3000
            }
          });  
        })
      .catch(error => {
        log('[PropertiesHome.fetchFeed.getFeed] error: ', error);
      });
    }
  };

  const sortByAddress = () => {
    if (ascendOrder) {
      setProperties(R.sortWith([R.ascend(R.prop('Property'))]));
    } else {
      setProperties(R.sortWith([R.descend(R.prop('Property'))]));
    }
    setAscendOrder(!ascendOrder);
  };

  const usehandleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Fetch Buildings/Properties
  useEffect(() => {
    if (user !== null) {
      dispatch({
        type: 'ACTION_SET_IS_APP_LOADING',
        isAppLoading: true
      });
      SPBackend.getBuildings(user)
      .then((response) => {
        const {
          buildings,
          stats
        } = response.data;
        setProperties(buildings);
        setPropertiesStats(stats);
        dispatch({
          type: 'ACTION_SET_IS_APP_LOADING',
          isAppLoading: false
        });
      })
      .catch(error => {
        log('[PropertiesHome.fetchFeed.getFeed] error: ', error);
        dispatch({
          type: 'ACTION_SET_IS_APP_LOADING',
          isAppLoading: false
        });
      });
    }
  }, [user]);

  return (
    <div className={classes.root}>
      <PropertiesHeader
        mobileOpen={mobileOpen} 
        setProperties={setProperties}
        usehandleDrawerToggle={usehandleDrawerToggle}
      />
      <MemberDrawer 
        mobileOpen={mobileOpen} 
        usehandleDrawerToggle={usehandleDrawerToggle}
      />
      <PropertiesContent 
        properties={properties}
        sortByAddress={sortByAddress}
        stats={propertiesStats}
        removeProperty={removeProperty}
        editProperty={editProperty}
      /> 
    </div>
  );
};

export default withStyles(styles)(PropertiesHome);
