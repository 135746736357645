// @flow

const styles = (theme: Object) => ({
  tabsCtr: {
    borderRadius: 0,
    maxWidth: 700
  },
  cardCtr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: 600,
    borderRadius: 0
  },
  textFieldCtr: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    border: `2px solid ${theme.palette.primary.lighter}`
  },
  textField: {
    marginTop: theme.spacing(1)
  },
  stepTitle: {
    marginBottom: theme.spacing(1)
  },
  chip: {
    marginRight: theme.spacing(0.5)
  },
  submitBtn: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  tabBody: {
    minHeight: 600
  },
  boxRoot: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  },
  tabBar: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(3)}px`
  },
  keywordRow: {
    position: 'relative',
    maxWidth: 800,
    minWidth: 500,
    height: 50,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: '2px',
    marginTop: '2px',
    [theme.breakpoints.down('sm')]: {
      minWidth: 0
    }
  },
  keywordRowOFF: {
    opacity: '40%'
  },
  appBar: {
    backgroundColor: theme.palette.background.paper
  },
  colKeywordContent: {
    paddingTop: theme.spacing(0.5),
    fontWeight: 700,
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      overflow: 'scroll',
      display: 'flex',
      flexWrap: 'nowrap'
    }
  },
  colMsgIcon: {
    paddingTop: theme.spacing(1),
    width: 50
  },
  colKeyword: {
    paddingTop: theme.spacing(1),
    width: 300,
    flexGrow: 1
  },
  colTimestamp: {
    paddingTop: theme.spacing(2),
    width: 200,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  colSwitch: {
    width: 60,
    marginTop: theme.spacing(0.5) + 1,
    marginRight: theme.spacing(1)
  },
  colEditIcon: {
    paddingTop: 1,
    width: 50,
    textAlign: 'right',
    paddingRight: theme.spacing(2)
  },
  betaBadge:{
    fontWeight: 700,
    color: theme.palette.custom.neoViolet.main,
    backgroundColor: theme.palette.custom.neoYellow.main,
    transform: 'translate(50%, -55%) scale(0.8)'
  }
});

export default styles;