// @flow
import { green } from '@material-ui/core/colors';

const styles = (theme: Object) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    '&:focus, &:hover': {
      outline: 'none'
    }
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  fabProgress: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    color: theme.palette.primary.light,
    position: 'absolute',
    top: -4,
    left: -4,
    zIndex: 1
  }
});

export default styles;