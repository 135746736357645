// @flow

import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import classNames from 'classnames';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';

import LiquidMessageEditor from './LiquidMessageEditor';
import RemovableModalImage from '../SharedComponents/RemovableModalImage';
import ImageFileDrop from '../SharedComponents/ImageFileDrop';
import ForwardRef from '../SharedComponents/ForwardRef';
import TagRenderer from '../SharedComponents/TagRenderer';
import SPBackend from '../../services/SPBackend';
import FilterSelect from '../FilterSelect';
import { calculateCreditsForMsg, submitSendMessage } from '../../models/Message';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/MessageTenantDialog.Style';

import type { Tenant } from '../../services/SPBackend';

type Props = {
  classes: Object,
  showToFilters: Boolean, // if false: component acts as PM. if true: component acts as broadcast
  setOpen: Function,
  open: boolean,
  tenant: Tenant,
  triggerAction: Function,
};

// eslint-disable-next-line react/no-multi-comp
const MessageTenantDialog = (props: Props) => {
  const {
    classes,
    showToFilters,
    tenant,
    setOpen,
    open,
    triggerAction
  } = props;
  const [store, dispatch] = useStore();
  const {
    i18n,
    filterOptions,
    user,
    billing,
    accountUsage
  } = store;

  const errorMsgInitState = {
    function: 'upgrade', // 'upgrade'|'recharge'|''
    msg: ''
  };

  const [message, setMessage] = useState('');
  const [selectedTagOptions, setSelectedTagOptions] = useState([]);
  const [toTenants, setToTenants] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [errorMsg, setErrorMsg] = useState(errorMsgInitState);
  const [creditsUsed, setCreditsUsed] = useState(0);

  const refreshRecipientsCount = (selection: ?Array<Object> = null) => {
    SPBackend.findTenantsIds(
      selection !== null ? selection.map(t => t.value) : [],
      user
    ).then((response) => {
      log('[handleFilterChange] response: ', response);
      const { data } = response;
      const { tenants } = data;
      setToTenants(tenants);
    }).catch((e) => {
      log('[handleFilterChange] error: ', e);
    });
  };
  
  const handleFilterChange = (
    filterName: 'propertyOptions' | 'staffOptions' | 'tenantOptions' | 'tagOptions', 
    selection: Array<?{value: String, label: String}>) => {
    log('[handleFilterChange] selection: ', selection);
    setSelectedTagOptions(selection);
    // fetch new tenants list
    refreshRecipientsCount(selection);
  };

  useEffect(() => {
    if (open && showToFilters) {
      refreshRecipientsCount();
    }
  }, [open]);
  
  useEffect(() => {
    if (typeof message !== 'undefined' && message !== null) {
      setCreditsUsed(calculateCreditsForMsg({message: message, attachment: attachment}));
    }
  }, [message, attachment]);

  useEffect(() => {
    if (typeof accountUsage !== 'undefined' && accountUsage.TotalCreditsBalance > 0 && billing.subscriptionStatus !== 'canceled') {
      emptyErrorMsg();
    }
  }, [accountUsage.TotalCreditsBalance, billing.subscriptionStatus]);
  
  const handleSetAttachment = (url) => {
    log('[handleSetAttachment] url: ', url);
    setAttachment(url);
  };

  const removeAttachment = (attachment) => (e) => {
    e.stopPropagation();
    setAttachment(null);
  };

  const emptyForm = () => {
    setMessage('');
    setSelectedTagOptions([]);
    setToTenants([]);
    setAttachment(null);
  };

  const emptyErrorMsg = () => {
    setErrorMsg(errorMsgInitState);
    setError('');
    setIsSending(false);
  };

  const resetForm = () => {
    setOpen(false);
    setSelectedTagOptions([]);
    setToTenants([]);
    setErrorMsg(errorMsgInitState);
    setCreditsUsed(0);
  };

  const openUpgradePlan = () => {
    log('[openUpgradePlan]...');
    setIsSending(false);
    dispatch({
      type: 'ACTION_SET_UPGRADE_POPUP',
      open: true
    });
  };

  const openRechargeAccount = () => {
    log('[openRechargeAccount]...');
    setIsSending(false);
    dispatch({
      type: 'ACTION_SET_RECHARGE_POPUP',
      open: true
    });
  };

  return (
    <Dialog
      onClose={() => resetForm()}
      open={open}
    >
    {/* Broadcast */}
    {
      showToFilters && filterOptions
      ? <form
          autoComplete='off'
          className={classNames(
            classes.dialogFormCtr,
            'xlarge'
          )}
          noValidate
          onSubmit={e => {
            e.preventDefault();

            const _props = {
              message,
              attachments: [attachment],
              setErrorMsg,
              setIsSending,
              toTenantsIds: toTenants.map(t => t._id),
              setOpenDialog: setOpen,
              triggerAction,
              emptyForm,
              billing,
              accountUsage,
              dispatch
            };

            log('[sendPM] _props: ', _props);
            submitSendMessage(_props);
          }}
        >
          <DialogTitle
            classes={{
              root: classes.messageTenantDialogTitle
            }}
            disableTypography
            id='form-dialog-title'
          >
            <Grid
              container
              direction='row'
              justifyContent="space-between"
            >
              <Grid item>
                {'To: '}
                {
                  !selectedTagOptions || selectedTagOptions.length == 0
                  ? 'all customers'
                  : selectedTagOptions.map((t, i) => <span key={`t-${i}`}>
                    <TagRenderer
                      readonly
                      tagsIds={[t.value]}
                    />{' '}</span>)
                }
              </Grid>
              <Grid className={classes.recipientsCountLabel}
                    item
              >
                {
                  toTenants
                  ? `(${toTenants.length} recipients)`
                  : null
                }
              </Grid>
            </Grid>
            <div className={classes.filterContainer}>
              <ForwardRef>
                <FilterSelect
                  className={classNames(classes.filterBox, classes.relative)}
                  maxWidth='100%'
                  onChangeCallback={response => {handleFilterChange('tagOptions', response); emptyErrorMsg();}}
                  options={filterOptions.tagOptions}
                  placeholder={`Select recipients by ${i18n.t('tags')}`}
                  position='last'
                  width='100%'
                />
              </ForwardRef>
            </div>
          </DialogTitle>
          {/* // TODO: factor content zone */}
          <DialogContent className={classNames(classes.grow, classes.flexContainer)}>
            
            <LiquidMessageEditor
              emptyErrorMsg={emptyErrorMsg}
              isSending={isSending}
              message={message}
              recipients={toTenants}
              setMessage={setMessage}
            />

            {
              process.env.REACT_APP_CHARS_NB_PER_CREDIT
              ? <Tooltip 
                aria-label={`${creditsUsed} ${creditsUsed > 1 ? 'Credits' : 'Credit'}`}
                className={classes.errorTooltip}
                placement='top-end'
                title={`${creditsUsed} ${creditsUsed > 1 ? 'Credits' : 'Credit'}`}
                >
                <ForwardRef>
                  {
                    errorMsg && !R.isEmpty(errorMsg.msg)
                    ? <div className={classes.errorMessagesCtr}>
                        <div className={classNames(
                          !attachment ? classes.textFieldCaptionLeft : classes.textFieldCaptionRight2, 
                          classes.warning)} 
                          dangerouslySetInnerHTML={{__html: errorMsg.msg}}
                        />
                        {
                          errorMsg.function === 'upgrade'
                          ? <div className={classNames(
                            !attachment ? classes.textFieldCaptionLeft : classes.textFieldCaptionRight2, 
                            classes.warning)}
                            >
                            <a className={classes.clickable}
                              onClick={openRechargeAccount}
                            >Click here</a> to activate your account.
                          </div>
                          : null
                        }
                        {
                          errorMsg.function === 'recharge'
                          ? <div className={classNames(
                            !attachment ? classes.textFieldCaptionLeft : classes.textFieldCaptionRight2, 
                            classes.warning)}
                            > 
                            <a className={classes.clickable}
                                onClick={openRechargeAccount}
                            >Click here</a> to recharge your account.</div>
                          : null
                        }
                      </div>
                    : <div>&nbsp;</div>
                  }
                  <div className={classNames(
                      classes.textFieldCaptionRight,
                      message.length >= 1600 ? classes.warning : null
                    )}
                  >
                    {message.length >= 1600 ? '1600 characters max per message.' : null}
                    {message.length > 0 && message.length < 1600 ? `${process.env.REACT_APP_CHARS_NB_PER_CREDIT - (message.length % process.env.REACT_APP_CHARS_NB_PER_CREDIT)} / ${creditsUsed}` : <span>&nbsp;</span>}
                  </div>
                </ForwardRef>
              </Tooltip>
              : null
            }
            {
              error
              ? <DialogContentText className={classes.error}>{ error }</DialogContentText>
              : null
            }
            {
              attachment && attachment.Url !== ''
              ? <RemovableModalImage
                handleClickOnRemove={removeAttachment(attachment)}
                largeImageUrl={attachment.Url}
                smallImageUrl={attachment.Url}
                />
              : null
            }
          </DialogContent>
          <DialogActions classes={{root: classes.actionsCtr}}>
            <div>
              <ImageFileDrop
                setAttachment={handleSetAttachment}
              >
                <Tooltip 
                  aria-label={'Attach an image'}
                  placement='top'
                  title={'Attach an image'}
                >
                  <Fab
                    aria-label='Attach an image'
                    className={classes.actionAttachImgBtn}
                    color='secondary'
                  >
                    <ImageOutlinedIcon />
                  </Fab>
                </Tooltip>
              </ImageFileDrop>
            </div>
            <div>
              <Button
                color='primary'
                onClick={(event) => {
                  event.preventDefault();
                  emptyForm();
                  setOpen(false);
                }}
                type='button'
              >
                {'Cancel'}
              </Button>
              
              <Button
                color='primary'
                disabled={isSending}
                type='submit'
                variant='contained'
              >
                {'Send'}
              </Button>
            </div>
          </DialogActions>
        </form>
      : null
    }
    {/* Send message to individual tenant */}
    {
      tenant && open
      ? <form
          autoComplete='off'
          className={classNames(
            classes.dialogFormCtr,
            'xlarge'
          )}
          noValidate
          onSubmit={e => {
            e.preventDefault();
            const _props = {
              message,
              attachments: [attachment],
              setErrorMsg,
              setIsSending,
              toTenantsIds: [tenant._id],
              setOpenDialog: setOpen,
              triggerAction,
              emptyForm,
              billing,
              accountUsage,
              dispatch
            };
            log('[sendPM] _props: ', _props);
            submitSendMessage(_props);
            
            // TODO: before calling triggerAction, move logic to check creditsNeeded here and call recharge popup before proceeding. Return nothing for now. User will press send btn again after recharging.

            
          }}
        >
          <DialogTitle
            classes={{
              root: classes.messageTenantDialogTitle
            }}
            disableTypography
            id='form-dialog-title'
          >{'To: '}<b>{`${tenant.FirstName} ${tenant.LastName}`}</b></DialogTitle>
          <DialogContent className={classNames(classes.grow, classes.flexContainer)}>
            <div id='MessageTenantDialog'>
              <TextField
                autoFocus
                classes={{
                  root: classNames(
                    classes.secondInput,
                    classes.largeTextField
                  )
                }}
                fullWidth
                id='message'
                label='Message'
                multiline
                onChange={e => {emptyErrorMsg(); setMessage(e.target.value);}}
                placeholder='Write your message here'
                readonly={isSending}
                rows={10}
                value={message}
                variant='outlined'
              />
            </div>
            {
              process.env.REACT_APP_CHARS_NB_PER_CREDIT
              ? <Tooltip 
                aria-label={`${creditsUsed} ${creditsUsed > 1 ? 'Credits' : 'Credit'}`}
                className={classes.errorTooltip}
                placement='top-end'
                title={`${creditsUsed} ${creditsUsed > 1 ? 'Credits' : 'Credit'}`}
                >
                <ForwardRef>
                  {errorMsg && !R.isEmpty(errorMsg.msg)
                  ? <div className={classes.errorMessagesCtr}>
                    <div className={classNames(
                      !attachment ? classes.textFieldCaptionLeft : classes.textFieldCaptionRight2, 
                      classes.warning)} 
                      dangerouslySetInnerHTML={{__html: errorMsg.msg}}
                    />
                    {
                      errorMsg.function === 'upgrade'
                      ? <div className={classNames(
                        !attachment ? classes.textFieldCaptionLeft : classes.textFieldCaptionRight2, 
                        classes.warning)}
                        >
                        <a className={classes.clickable}
                          onClick={openRechargeAccount}
                        >Click here</a> to activate your account.
                      </div>
                      : null
                    }
                    {
                      errorMsg.function === 'recharge'
                      ? <div className={classNames(
                        !attachment ? classes.textFieldCaptionLeft : classes.textFieldCaptionRight2, 
                        classes.warning)}
                        > 
                        <a className={classes.clickable}
                          onClick={openRechargeAccount}
                        >Click here</a> to recharge your account.</div>
                      : null
                    }
                  </div>
                  : <div>&nbsp;</div>}
                  
                  <div className={classNames(
                    classes.textFieldCaptionRight,
                    message.length >= 1600 ? classes.warning : null
                  )}
                  >
                    {message.length >= 1600 ? '1600 characters max per message.' : null}
                    {message.length > 0 && message.length < 1600 
                      ? `${process.env.REACT_APP_CHARS_NB_PER_CREDIT - (message.length % process.env.REACT_APP_CHARS_NB_PER_CREDIT)} / ${creditsUsed}`
                      : <span>&nbsp;</span>}
                  </div>
                </ForwardRef>
              </Tooltip>
              : <div>&nbsp;</div>
            }
            {/* <div className={classNames(
              classes.textFieldCaptionRight,
              message.length >= 1600 ? classes.warning : null
            )}
            >
              {message.length > 0 ? `${message.length}/1600` : <span>&nbsp;</span>}
            </div> */}
            {
              error
              ? <DialogContentText className={classes.error}>{ error }</DialogContentText>
              : null
            }
            {
              attachment && attachment.Url !== ''
              ? <RemovableModalImage
                  handleClickOnRemove={removeAttachment(attachment)}
                  largeImageUrl={attachment.Url}
                  smallImageUrl={attachment.Url}
                />
              : null
            }
          </DialogContent>
          <DialogActions classes={{root: classes.actionsCtr}}>
            <div>
              <ImageFileDrop
                setAttachment={handleSetAttachment}
              >
                <Tooltip 
                  aria-label={'Attach an image'}
                  placement='top'
                  title={'Attach an image'}
                >
                  <Fab
                    aria-label='Attach an image'
                    className={classes.actionAttachImgBtn}
                    color='secondary'
                  >
                    <ImageOutlinedIcon />
                  </Fab>
                </Tooltip>
              </ImageFileDrop>
            </div>
            <div>
              <Button
                color='primary'
                onClick={(event) => {
                  event.preventDefault();
                  emptyForm();
                  setOpen(false);
                }}
                type='button'
              >
                {'Cancel'}
              </Button>
              
              <Button
                color='primary'
                disabled={isSending}
                type='submit'
                variant='contained'
              >
                {'Send'}
              </Button>
            </div>
          </DialogActions>
        </form>
      : null
    }
    </Dialog>
  );
};

export default withStyles(styles)(MessageTenantDialog);
