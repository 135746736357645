// @flow

import {
  NOTIFICATION_CHANNELS
} from '../services/SPBackend';

import type {
  Task,
  Unit,
  Tag,
  RequestsFilterOptions,
  RequestsFilterSelections,
  AccountSettings,
  AccountUsage,
  Building,
  User,
  Keyword
} from '../services/SPBackend';

// Building the initial State
// TODO: to move to its own file
const storageLoggedIn = () => 
  window.localStorage.getItem('loggedIn') === 'true' ? true : false;

const storageEmail = () => 
  window.localStorage.getItem('email');

const storageUserRole = () => 
  window.localStorage.getItem('userRole');

const storageUserFullName = () => 
  window.localStorage.getItem('userFullName');

const storageToken = () => 
  window.localStorage.getItem('ppp');

export type State = {
  loggedIn: bool, // FIXME: to deprecate? Does token render this useless?
  token: string,
  email: string,
  isDemoMode: boolean,
  userRole: string,
  userFullName: string,
  user: Object, // firebase session user
  loggedUser: ?User, // user from our database
  isSetupPropertyDone: boolean,
  refreshPlanInfoIndex: number,
  billing: {
    autorefresh: boolean,
    planId: ?string,
    subscriptionId: ?string,
    subscriptionStatus: string
  },
  accountId: string,
  accounts?: Array<{
    _id: string,
    CompanyName: string,
    Email: string,
    IsManager: boolean,
    IsAdmin: boolean,
    IsOwner: boolean
  }>,
  companyName: string,
  accountSettings: AccountSettings,
  accountUsage: AccountUsage,
  defaultUnit: ?Unit, // only !== null if account has 1 unit only.
  defaultBuilding: ?Building,
  filterSearchText: string,
  requests: Array<Task>,
  tags: Array<Tag>,
  totalRequests: number,
  units: Array<Unit>,
  filterOptions: RequestsFilterOptions,
  filterSelections: RequestsFilterSelections,
  refreshFullFeed: boolean,
  isFeedLive: boolean,
  isBillingLive: boolean,
  snackMessage: {
    message: string,
    level: 'success' | 'error' | 'info' | 'warning',
    duration: ?number
  },
  exportCSVRequests: boolean,
  printRequests: boolean,
  numberOfDays: number, // TODO: to replace with start & end dates
  agreement: {
    NeedsAgreementSignature: boolean,
    Language: string
  },
  loadingModule: {
    feed: boolean,
    accountSettings: boolean,
    phoneNumbers: boolean
  },
  loadingAccount: boolean,
  isAppLoading: boolean,
  // statusAlerts: StatusAlerts,
  keywords: Array<Keyword>,
  isAppLoading: boolean,
  i18n: Function,
  tenantEnableUpload: boolean,
  subaccountSID: string,
  tenantsPageInfo: {
    pageNum: number,
    pageSize: number,
    sortOrder: number,
    sortBy: Array<string>
  },
  openPopups: {
    upgradePlan: false,
    rechargeAccount: false
  }
  // onboarding: {
  //   currentStep: number, // 0|1|2|3
  //   isComplete: boolean,
  //   email: string // if this is ever different that the logged user's email, we log everybody out with a warning, asking to login again
  //   }
  // meant to have the same lifespan is an active dialog. Once the dialog is closed, these lists are emptied.
  // requestsInDialog: Array<Request>, 
  // unitsInDialog: Array<Unit>,
}

const initialState: State = {
  loggedIn: storageLoggedIn(),
  user: null, // firebase user
  loggedUser: null, // our own database user
  isSetupPropertyDone: false,
  refreshPlanInfoIndex: 0,
  billing: {
    autorefresh: true,
    planId: null, // pre-selected plan - used by pricing page. To deprecate?
    subscriptionId: null, // actual subscription id.
    subscriptionStatus: 'na'
  },
  accountId: '',
  accounts: [],
  companyName: '',
  accountSettings: {
    Tenants: {
      ReceiveNotificationsBy: NOTIFICATION_CHANNELS.SMS
    },
    Users: {
      ReceiveNotificationsBy: NOTIFICATION_CHANNELS.EMAIL
    },
    SetupRequired: {
      Properties: false,
      // Units: false,
      Tenants: false
    },
    SetupMeeting: {
      IsRequired: false
    },
    Twilio: {
      Status: '',
      SubaccountSID: ''
    },
    EnableCustomersSelfUpload: false
  },
  accountUsage: {
    CurrentPeriod: {
      Limit: 0,
      CreditsUsed: 0,
      StartDt: null
    },
    TotalCreditsBalance: 0
  },
  defaultUnit: null,
  defaultBuilding: null,
  email: storageEmail(),
  isDemoMode: false,
  userRole: storageUserRole(),
  userFullName: storageUserFullName(),
  token: storageToken(), // Firebase User idToken
  filterSearchText: '',
  requests: [], // one page of requests
  tags: [],
  totalRequests: 0, // total requests matching the current filterSelections
  units: [],
  filterOptions: {
    propertyOptions: [],
    staffOptions: [],
    tenantOptions: [],
    tagOptions: [],
    unreadMessagesOnlyOptions: []
  },
  filterSelections: {
    propertyOptions: [],
    staffOptions: [],
    tenantOptions: [],
    tagOptions:[],
    unreadMessagesOnlyOptions: [],
    startDatetime: '',
    endDatetime: '',
    pageNb: 0,
    itemsPerPage: 50 // make sure this matches with backend
  },
  refreshFullFeed: false, // reset entire feed
  isFeedLive: true, // poll backend for new incoming tasks 
  isBillingLive: true,
  snackMessage: { // TODO: refactor this as an array to pile up messages
    message: '',
    level: 'success',
    duration: null
  },
  exportCSVRequests: false,
  printRequests: false,
  numberOfDays: 30,
  agreement: {
    NeedsAgreementSignature: false,
    Language: ''
  },
  loadingModule: {
    feed: false,
    accountSettings: false,
    phoneNumbers: false
  },
  loadingAccount: true,
  isAppLoading: true,
  // statusAlerts: {
  //   Tenants: [],
  //   Properties: [],
  //   Staff: []
  // },
  keywords: [],
  isAppLoading: true,
  i18n: {
    t: str => str
  },
  tenantEnableUpload: false,
  subaccountSID: '',
  tenantsPageInfo: {
    pageNum: 1,
    pageSize: 100,
    sortOrder: 1,
    sortBy: ['FirstName','LastName']
  },
  openPopups: {
    upgradePlan: false,
    rechargeAccount: false
  }
};
export default initialState;