const styles = (theme: Object) => ({
  attachedImg: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    border: `2px solid ${theme.palette.primary.lighter}`,
    cursor: 'pointer',
    objectFit: 'cover',
    display: 'block',
    width: 'auto',
    // minWidth: 100,
    height: 150,
    [theme.breakpoints.down('sm')]: {
      borderRadius: theme.shape.borderRadius / 2
    }

    // transform: 'rotate(180deg)',
    // border: `2px solid ${theme.palette.primary.lighter}`,
    // padding: theme.spacing(2),
    // borderRadius: theme.shape.borderRadius,
    // marginBottom: theme.spacing(1),
    // height: 150,
    // width: 'auto',
    // cursor: 'pointer',
    // objectFit: 'cover',
    // [theme.breakpoints.down('sm')]: {
    //   borderRadius: theme.shape.borderRadius / 2
    // }
  },
  zoomImg: {
    backgroundColor: 'white',
    borderRadius: theme.shape.borderRadius,
    border: 0,
    cursor: 'pointer',
    display: 'block',
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  },
  modalImageCtr: {
    display: 'inline-block',
    width: 'auto'
  },
  RemovableModalImageCtr: {
    display: 'block',
    position: 'relative'
  },
  closeIcon: {
    display: 'block',
    position: 'relative',
    zIndex: 999,
    top: 12,
    left: 'calc(100% - 12px)',
    cursor: 'pointer'
  },
  closeModalIcon: {
    display: 'block',
    position: 'absolute',
    zIndex: 999,
    top: -16,
    right: -16,
    cursor: 'pointer',
    color: 'black',
    background: 'white',
    borderRadius: 24
  },
  modalPaper: {
    position: 'absolute',
    width: 'auto',
    boxShadow: theme.shadows[5],
    top: '10%',
    bottom: '10%',
    right: '20%',
    left: '20%',
    borderRadius: theme.shape.borderRadius,
    '&:focus-visible': {
      outline: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      top: '5%',
      bottom: '5%',
      right: '5%',
      left: '5%'
    }
  }
});

export default styles;