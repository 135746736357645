// @flow

const styles = (theme: Object) => ({
  flexContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  dialogFormCtr: {
    height: '80vh',
    maxHeight: 300,
    display: 'flex',
    flexDirection: 'column',
    '&.xlarge': {
      maxHeight: 400
    },
    '&.large': {
      maxHeight: 350
    }
  },
  grow: {
    flexGrow: 1
  },
  secondInput: {
    marginTop: theme.spacing(2)
  },
  error: {
    color: theme.palette.status.error.main
  }
});

export default styles;
