// @flow
import grey from '@material-ui/core/colors/grey';

const styles = (theme: Object) => ({

  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexAlign: 'flex-start',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '& a': {
      textDecoration: 'none'
    }
  },
  itemTextCtr: {
    padding: '0 0'
  },
  grow: {
    flexGrow: 1
  },
  toolbarHeight: {
    height: theme.layout.customProps.header.height * 2,
    marginBottom: 0
  },
  
  anchorTopRight: {
    transform: 'translate( 20%, -90%)'
  },
  drawer: {
    width: theme.layout.customProps.drawer.width,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
      display: 'block'
    }
  },
  menuLink: {
    textDecoration: 'none'
  },
  drawerPaper: {
    width: theme.layout.customProps.drawer.width
  },
  toolbar: {
    ...theme.mixins.toolbar,
    minHeight: `${theme.layout.customProps.header.height * 2}px !important`,
    [theme.breakpoints.down('xs')]: {
      minHeight: 'auto'
    }
    // marginTop: theme.spacing(3)
  },
  center: {
    textAlign: 'center',
    margin: '0 auto'
  },
  noPadding: {
    paddingLeft: 0,
    paddingRight: 0
  },
  footer: {
    textAlign: 'center',
    '& *': {
      color: theme.layout.customProps.gray
    }
  },
  feedbackBtn: {
    margin: theme.spacing(1)
  },
  input: {
    display: 'none'
  },
  topCompanyIcon: {
    '& svg': {
      fontSize: 40,
      margin: '0 auto'
    }
  },
  disabledItem: {
    '& *': {
      opacity: 0.75
    }
  },
  upgradeBtn: {
    marginTop: theme.spacing(0.5),
    textTransform: 'none'
  },
  rechargeBtn: {
    marginTop: theme.spacing(0.5),
    textTransform: 'none',
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
      backgroundColor: '#EAEAEA'
    }
  },
  upgradeItem: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2)
    },
    '& p': {
      fontSize: '0.75rem',
      textAlign: 'center',
      lineHeight: '0.9rem',
      marginTop: theme.spacing(0.75),
      cursor: 'auto'
    }
  },
  subListItem: {
    minHeight: 60,
    fontSize: '1rem',
    paddingLeft: theme.spacing(2.75),
    paddingRight: theme.spacing(2.75)
  },
  listItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    '& div > span': {
      color: 'white'
    },
    '&:hover': {
      cursor: 'pointer'
    },
    [theme.breakpoints.down('sm')]: {
      height: theme.layout.customProps.toolbar.height
    }
  },
  listItemSub: {
    paddingBottom: theme.spacing(3),
    justifyContent: 'center',
    '& *': {
      color: 'white'
    }
  },
  listItemIcon: {
    display: 'inline-block',
    minWidth: 0
  },
  menuRoot: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    left: `${theme.layout.customProps.drawer.width}px !important`
  },
  menuItem:{
    height: 50
  },
  subMenuListRoot: {
    paddingTop: 0,
    paddingBottom: 0,
    '& li': {
      color: 'white'
    }
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    '& *': {
      color: 'white'
    }
  },
  flexItem1: {
    flexGrow: 1,
    width: '100%',
    whiteSpace: 'nowrap'
  },
  flexItem2: {
    marginLeft: -4,
    width: 24,
    paddingRight: theme.spacing(0.5)
  },
  selected: {
    backgroundColor: theme.palette.custom.neoYellow.main,
    color: theme.palette.custom.neoViolet.dark,
    '&:hover': {
      backgroundColor: `${theme.palette.custom.neoYellow.dark} !important`
    },
    '& div > span': {
      color: theme.palette.custom.neoViolet.dark,
      fontWeight: 700
    }
  }
});

export default styles;