// @flow

const COL_TENANT_WIDTH = 250;
const COL_ADDRESS_WIDTH = 300;
const COL_CHECKBOX_WIDTH = 44;
const COL_OTHER_WIDTH = 200;
const COL_PHONE_WIDTH = 175;
const COL_TAG_WIDTH = 300;
const COL_APT_WIDTH = 150;
const COL_EMAIL_WIDTH = 150;
const COL_ACTION_WIDTH = 220;
const MOBILE_COL_TENANT_WIDTH = 300;

const styles = (theme: Object) => ({
  checkboxIcon: {
    padding: 0,
    color: theme.palette.border.color,
    fontSize: '1.6rem',
    '&:hover': {
      color: theme.palette.primary.light
    }
  },
  table: {
    display: 'table'
  },
  accordionRoot: {
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2)
  },
  accordionSummaryRoot:{
    [theme.breakpoints.down('sm')]:{
      fontSize: '0.5rem'
    }
  },
  selected: {
    color: theme.palette.primary.light
  },
  colAction: {
    width: COL_ACTION_WIDTH
  },
  checkboxHeadCell: {
    display: 'inline-table',
    padding: '0px 8px',
    marginRight: 0,
    '& > span > svg': {
      fontSize: '1.6rem'
    }
  },
  customerTableContent: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: '100%',
    minWidth: 1100,
    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
      padding: theme.spacing(2)
    }
  },
  checkboxCell: {
    flex: '0 0 auto',
    flexBasis: COL_CHECKBOX_WIDTH,
    maxWidth: COL_CHECKBOX_WIDTH,
    cursor: 'pointer',
    padding: `${theme.spacing(1.7)}px ${theme.spacing(1.5)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    height: 52,
    background: 'white',
    borderTopLeftRadius: 24,
    borderBottomLeftRadius: 24,
    borderBottom: 0,
    '& span svg': {
      fontSize: '1.6rem'
    }
  },
  tenantCell: {
    flex: '0 0 auto',
    flexBasis: COL_OTHER_WIDTH,
    maxWidth: COL_TENANT_WIDTH,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 auto',
      flexBasis: COL_OTHER_WIDTH,
      maxWidth: MOBILE_COL_TENANT_WIDTH,
      cursor: 'pointer',
      overflowX: 'scroll'
    }
  },
  aptCell: {
    flex: '0 0 auto',
    minWidth: COL_APT_WIDTH,
    maxWidth: COL_APT_WIDTH,
    overflowX: 'scroll'
  },
  dateCell: {
    flex: '1 1 auto !important',
    minWidth: COL_EMAIL_WIDTH,
    maxWidth: '100% !important',
    paddingRight: 0,
    paddingLeft: 0,
    width: 'auto',
    overflowX: 'scroll',
    cursor: 'pointer'
  },
  addressCell: {
    flex: '1 1 auto',
    flexBasis: COL_ADDRESS_WIDTH,
    overflowX: 'scroll'
  },
  phoneCell: {
    flex: '0 0 auto',
    minWidth: COL_PHONE_WIDTH,
    maxWidth: COL_PHONE_WIDTH,
    overflowX: 'scroll'
  },
  tagCell: {
    flex: '0 0 auto',
    minWidth: COL_TAG_WIDTH,
    maxWidth: COL_TAG_WIDTH,
    overflowX: 'scroll'
  },
  actionCell: {
    flex: '0 0 auto',
    minWidth: theme.shape.borderRadius,
    paddingRight: 0,
    paddingLeft: 0,
    width: 'auto',
    overflowX: 'scroll'
  },
  customTableStyles: {
    '& .underActionCell': {
      flex: '0 0 auto',
      minWidth: COL_ACTION_WIDTH ,
      maxWidth: COL_ACTION_WIDTH ,
      paddingRight: 0,
      paddingLeft: theme.spacing(1),
      width: 'auto'
    },
    '& .hover .underActionCell.twoBtns': {
      flex: '1 1 auto',
      minWidth: COL_ACTION_WIDTH - (theme.shape.borderRadius * 2 * 2),
      maxWidth: COL_ACTION_WIDTH - (theme.shape.borderRadius * 2 * 2),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 'auto'
    }
  },
  expansionPanelSummaryRoot: {
    minHeight: '48px !important',
    height: 48,
    marginTop: '1rem'
  },
  resultsCount: {
    color: theme.palette.custom.backgroundTextInfo,
    fontSize: '0.8rem',
    marginBottom: theme.spacing(1),
    paddingTop:theme.spacing(1),
    paddingLeft: theme.spacing(2.5),
    [theme.breakpoints.down('sm')]: {
      paddingTop:0,
      paddingBottom:0,
      marginBottom: 0,
      paddingLeft: theme.spacing(2),
      fontSize: '0.8rem'
    }
  },
  heading: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2.5),
    paddingBottom: theme.spacing(0)
  },
  heading2: {
    paddingTop: theme.spacing(4)
  },
  expansionPanelDetails: {
    paddingBottom: theme.spacing(1)
  },
  anchorTopRight: {
    transform: 'translate( 100%, -30%)'
  },
  arrowIcon: {
    fontSize: '1.2rem',
    position: 'relative',
    top: 1,
    marginLeft: theme.spacing(0.375),
    color: '#66666a',
    verticalAlign: 'text-bottom'
  }
});

export default styles;