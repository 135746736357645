// @flow

const styles = (theme: Object) => ({
  colorPickerRainbow: {
    background: 'conic-gradient(red, yellow, lime, aqua, blue, magenta, red)',
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: theme.spacing(2),
    cursor: 'pointer',
    position: 'relative',
    top: -2,
    left: 2
  }
});

export default styles;