// @flow

const FILTER_BAR_HEIGHT = 38;

const styles = (theme: Object) => ({
  topHeaderContainer: {
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },
  row: {
    ...theme.mixins.toolbar
  },
  rowSub: {
    ...theme.mixins.toolbar,
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: theme.layout.customProps.toolbar.height,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  rowSubContainer: {
    flexShrink: 0,
    flexWrap: 'nowrap',
    width: 'auto',
    alignItems: 'center'
  },
  rowSubTypoCtr: {
    alignItems: 'center'
  },
  subHeaderCaption: {
    fontSize: '1.1rem'
  },
  pageTitle: {
    marginRight: theme.spacing(4),
    color: 'white', // theme.palette.custom.neoYellow.main,
    textShadow: '0px 0 4px hsl(0deg 0% 0% / 12%)',
    '&.light': {
      color: theme.palette.primary.main,
      background: 'white'
    },
    '& a': {
      textDecoration: 'none',
      color: 'inherit'
    },
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap',
      overflowX: 'scroll',
      maxWidth: '40vw'
    }
  },
  menuButton: {
    color: 'white',
    textShadow: '0px 0 4px hsl(0deg 0% 0% / 12%)',
    width: '1.2rem',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    height: 50,
    width: 50,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  appBar: {
    boxShadow: 'none',
    marginLeft: theme.layout.customProps.drawer.width,
    zIndex: theme.zIndex.drawer + 1,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${theme.layout.customProps.drawer.width}px + 1px)`
    }
  },
  appBarFullscreen: {
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% + 1px)'
    }
  },
  toolbarRoot: {
    paddingLeft: 0,
    paddingRight: 0
  },
  topBar: {
    background: theme.palette.primary.light,
    color: theme.palette.primary.light,
    paddingRight: 0,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: 0,
      width: '100%'
    },
    '&.light': {
      background: 'white'
    }
  },
  bottomBar: {
    background: theme.palette.primary.lighter,
    color: theme.palette.primary.light,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: -theme.spacing(1),
      width: `calc(100% + ${theme.spacing(1)}px)`
    }
  },
  bottomBarContainer: {
    flexWrap: 'nowrap',
    display: 'flex',
    overflowX: 'auto'
  },
  filterContainer: {
    // border: `1px solid ${
    //   theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)'
    // }`,
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    background: 'white',
    height: FILTER_BAR_HEIGHT
  },
  filterBox: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      '&.first': {
        marginLeft: 0
      },
      '&.last': {
        borderRight: 0
      }
    }
  },
  searchBox: {
    border: 0,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 160,
      '&.first': {
        marginLeft: 0
      }
    }
  },
  customersSearchBox: {
    border: 0,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 150,
      '&.first': {
        marginLeft: 0
      }
    }
  },
  toggleContainer: {
    height: theme.layout.customProps.toolbar.height,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: 0,
    background: 'transparent',
    '& *': {
      display: 'flex',
      flexDirection: 'column',
      color: 'white',
      fontSize: '0.9rem',
      textDecoration: 'none'
    },
    '& i': {
      height: 28,
      // paddingTop: theme.spacing(2),
      fontSize: '1.6rem',
      filter: 'drop-shadow( 2px 1px 2px rgba(0, 0, 0, .1))'
    },
    '& svg': {
      height: 28,
      // paddingTop: theme.spacing(2),
      fontSize: '1.8rem',
      filter: 'drop-shadow( 2px 1px 2px rgba(0, 0, 0, .1))'
    }
  },
  buttonGroup: {
    background: 'white',
    boxShadow: 'none',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${
      theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)'
    }`
    // '&$disabled': {
    //   border: `1px solid ${theme.palette.action.disabled}`,
    // }
  },
  button: {
    color: 'rgba(0, 0, 0, 0.65)',
    height: 36,
    padding: '6px 12px',
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderRadius: 0,
    '&:first-child': {
      marginLeft: 0,
      borderRightWidth: 1
    },
    '&:last-child': {
      // padding: '6px 12px 6px 8px'
    }
  },
  dividerRight: {
    marginRight: theme.spacing(8)
  },
  navButton: {
    height: theme.layout.customProps.toolbar.height,
    minWidth: theme.layout.customProps.toolbar.height,
    textTransform: 'none',
    textShadow: '0px 0 4px hsl(0deg 0% 0% / 12%)',
    padding: `10px ${theme.spacing(1)}px 0 ${theme.spacing(1)}px`,
    borderRadius: 0,
    '& *': {
      whiteSpace: 'nowrap'
    }
  },
  buttonIconText: {
    marginLeft: theme.spacing(1)
  },
  userProfileSubMenuCtr: {
    height: '100%',
    minWidth: 120,
    right: 0,
    '& a': {
      textDecoration: 'none'
    },
    '& *': {
      color: 'white'
    },
    '&.light *': {
      color: theme.palette.primary.main
    }
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: theme.layout.customProps.toolbar.height,
    textAlign: 'right',
    '& p': {
      fontSize: '0.8rem'
    }
  },
  subListItem: {
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: theme.layout.customProps.toolbar.height,
    textAlign: 'left',
    '& p': {
      fontSize: '0.8rem'
    }
  },
  switchActHeader: {
    color: '#d5d9eb',
    height: theme.layout.customProps.header.height,
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center'
  },
  userProfileSubMenuCollapse: {
    position: 'absolute',
    backgroundColor: theme.palette.primary.light,
    zIndex: 2,
    width: '100%',
    '&.light': {
      backgroundColor: theme.palette.background.default
    }
  },
  userFullName: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  userIcon: {
    marginLeft: theme.spacing(1),
    marginRight: 0
  },
  itemTextCtr: {
    padding: '0 0',
    minWidth: 40
  },
  menuRoot: {
    // position: 'relative',
    borderRadius: 0,
    backgroundColor: theme.palette.background.default,
    // top: `${theme.layout.customProps.drawer.width}px !important`,
    width: `${theme.layout.customProps.drawer.width * 2}px`,
    top: `${theme.layout.customProps.toolbar.height * 2}px !important`
  },
  subMenuListRoot: {
    height: 'auto',
    paddingTop: 0,
    paddingBottom: 0
  },
  menuListItem: {
    maxHeight: theme.layout.customProps.toolbar.height,
    padding: 20,
    '&:hover': {
      cursor: 'pointer'
    },
    display: 'block'
  },
  iconRoot: {
    minWidth: 0,
    marginLeft: theme.spacing(1)
  }
});

export default styles;