// @flow

import { phoneInputCss } from '../../../css/phoneInput';

const styles = (theme: Object) => ({
  headerLogo: {
    marginTop: 30,
    marginBottom: 30,
    textAlign: 'center'
  },
  contentCtr: {
    position: 'relative',
    maxWidth: 540,
    paddingTop: 30,
    paddingLeft: 20,
    paddingRight: 20,
    margin: '0 auto',
    textAlign: 'center',
    zIndex: 0
  },
  formTitle: {
    textAlign: 'center',
    marginBottom: '3rem'
  },
  phoneInputCtr:  {
    ...phoneInputCss
  }
});
export default styles;