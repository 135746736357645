// @flow

import React, { useState } from 'react';
import classNames from 'classnames';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';

import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import AddProperty from './AddPropertyDialog';
import HeaderTopBar from '../HeaderTopBar';
import SPBackend from '../../services/SPBackend';
import AppLoadingSpinner from '../SharedComponents/AppLoadingSpinner';

import { withStyles } from '@material-ui/core/styles';
import styles from '../Feed/Styles/MemberHeader.Style';

type Props = {
  classes: Object,
  mobileOpen: bool, 
  usehandleDrawerToggle: Function,
  setProperties: Function
};


const PropertiesHeader = (props: Props) => {
  log('[PropertiesHeader] props: ', props);
  const {
    classes,
    setProperties,
    usehandleDrawerToggle
  } = props;
  const [openAddPropertyDialog, setOpenAddPropertyDialog] = useState(false);

  const [store, dispatch] = useStore();
  const {
    user,
    accountSettings,
    i18n
  } = store;

  const handleAddProperty = (address: string) => {
    log('[handleAddProperty] address, user: ', address, user);
    const newBuilding = {
      Property: address
    };
    SPBackend.createBuilding(newBuilding, user)
      .then((response) => {
        const {data: newBuilding} = response;
        log('[createBuilding] res: ', newBuilding);
        dispatch({
          type: 'ACTION_SET_ACCOUNT_SETTINGS',
          accountSettings: {
            ...accountSettings,
            SetupRequired: {
              ...accountSettings.SetupRequired,
              Properties: false
            }
          }
        });
        setProperties(prevState => {
          return [
            newBuilding,
            ...prevState
          ];
        });
      })
      .catch(error => {
        log('[handleAddProperty.createBuilding] error: ', error);
      });
  };

  return (
    <AppBar 
      className={classes.appBar}
      position="fixed"
    >
      <Toolbar
        className={classes.toolbar}
        disableGutters
      >
        <Grid 
          container
          direction='column'
        >
          <HeaderTopBar
            usehandleDrawerToggle={usehandleDrawerToggle}
          >
            <Typography 
              className={classes.pageTitle}
              variant='h3'>{i18n.t('Properties')}</Typography>
          </HeaderTopBar>
          <Grid
            className={classNames(
              classes.rowSub,
              classes.bottomBar
            )}
            item
          >
            <Grid container>
              <div 
                className={classNames(
                  classes.toggleContainer,
                  classes.dividerRight
                )}
              >
                <Tooltip 
                  aria-label={`Add new ${i18n.t('property')}`}
                  title={`Add new ${i18n.t('property')}`} 
                >
                  <Button
                    className={classes.navButton}
                    onClick={() => setOpenAddPropertyDialog(true)}
                  >
                    <i className={'icon-add'} />
                    <div>{i18n.t('Property')}</div>
                  </Button>
                </Tooltip>
              </div>
              <div className={classes.grow} />
            </Grid>
          </Grid>
          <AppLoadingSpinner />
        </Grid>
      </Toolbar>
      <AddProperty
        open={openAddPropertyDialog}
        setOpen={setOpenAddPropertyDialog}
        triggerAction={handleAddProperty} 
      />
    </AppBar>
  );
};

export default withStyles(styles)(PropertiesHeader);
