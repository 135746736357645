// @flow

import React from 'react';
import classNames from 'classnames';

import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';

import HeaderTopBar from '../HeaderTopBar';

import { withStyles } from '@material-ui/core/styles';
import styles from '../Feed/Styles/MemberHeader.Style';


type Props = {
  classes: Object,
  mobileOpen: bool, 
  title: string,
  usehandleDrawerToggle: Function,
};

const BasicHeader = (props: Props) => {
  // log('[BasicHeader] props: ', props);
  const {
    classes,
    usehandleDrawerToggle,
    title
  } = props;

  return (
    <AppBar 
      className={classes.appBarFullscreen}
      position="fixed"
    >
      <Toolbar
        classes={{
          root: classes.toolbarRoot
        }}

        disableGutters
      >
        <Grid 
          container
          direction='column'
        >
          <HeaderTopBar
            coloring='light'
            isOnboading
            usehandleDrawerToggle={usehandleDrawerToggle}
          >
            <Typography 
              className={classNames(classes.pageTitle, 'light')}
              variant='h3'
            >{title}</Typography>
          </HeaderTopBar>
          
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(BasicHeader);
