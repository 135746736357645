// @flow

import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { useStore } from '../../store';
import FilterSelect from '../FilterSelect';
import { log } from '../../utils/jsUtils';

import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';

import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';

import ImageFileDrop from '../SharedComponents/ImageFileDrop';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/Broadcast.Style';

type Props = {
  classes: Object,
  scope: 'request' | 'all',
  triggerAction: Function
}

const RequestHeader = (props: Props) => {
  const { classes, scope, triggerAction } = props;
  const [open, setOpen] = useState(false);
  const [issue, setIssue] = useState('');
  const [summary, setSummary] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [propertySelected, setPropertySelected] = useState([]);
  
  const [store] = useStore();
  const {
    filterOptions,
    i18n,
    defaultUnit
  } = store;

  const handleFilterChange = (
      filterName: 'propertyOptions', 
      selection: Array<?{value: String, label: String}>) => {
    log(`[handleFilterChange] filterSelections[${filterName}] = `, selection);
    setPropertySelected(selection.map(s => typeof s !== 'undefined' && s !== null ? s.value : null));
  };

  useEffect(() => {
    if (defaultUnit) {
      setPropertySelected([defaultUnit.PropertyKey]);
    }
  }, [defaultUnit]);

  const emptyForm = () => {
    setPropertySelected('');
    setSummary('');
    setAttachment('');
    setPropertySelected([]);
  };

  const handleSetAttachment = (url) => {
    log('[handleSetAttachment] url: ', url);
    setAttachment(url);
  };

  return (
    <div className={classes.relative}>
      <Button
        className={classes.navButton}
        onClick={() => setOpen(true)}
        value='Broadcast'
      >
        <i className={'icon-sent'} />
        <div>
        <Hidden smDown>
          {
            scope === 'request'
            ? i18n.t('Reply')
            : 'Broadcast'
          }
        </Hidden>
        <Hidden mdUp>
          {
            scope === 'request'
            ? i18n.t('Reply')
            : 'Broadcast'
          }
        </Hidden>
        </div>
      </Button>
      <Dialog
        aria-labelledby='form-dialog-title'
        onClose={() => setOpen(false)}
        open={open}
      >
        <form
          autoComplete='off'
          className={classes.dialogFormCtr}
          noValidate>
            <DialogTitle id='form-dialog-title'>
            {
              scope === 'request'
              ? i18n.t('Reply')
              : 'Broadcast'
            }
            </DialogTitle>
            <DialogContent className={classNames(classes.grow, classes.flexContainer)}>
            {
              scope === 'request'
              ? <DialogContentText className={classNames(classes.grow)}>
                {`${i18n.t('Send a message')} back to this ${i18n.t('tenant')}.`}
              </DialogContentText>
              : <div className={classNames(!defaultUnit ? classes.grow : null)}>
                <DialogContentText>
                {`Send a broadcast by SMS to all your ${i18n.t('tenants')}.`}
                </DialogContentText>
                
                {
                  !defaultUnit
                  ? <div className={classes.filterContainer}>
                    <FilterSelect
                      className={classNames(classes.filterBox, classes.relative)}
                      maxWidth='100%'
                      onChangeCallback={response => handleFilterChange('propertyOptions', response)}
                      options={filterOptions.propertyOptions}
                      placeholder={`${i18n.t('Properties')} to notify`}
                      position='last'
                      width='100%'
                    />
                  </div>
                  : null
                }
              </div>
            }
            {
              scope !== 'request'
              ? <TextField
                autoFocus
                fullWidth
                id='broadcastIssue'
                label='Subject'
                margin='dense'
                onChange={(e) => setIssue(e.target.value)}
                type='text'
                value={issue}
                />
              : null
            }
              <TextField
                fullWidth
                id='broadcastSummary'
                label='Message'
                margin='dense'
                multiline
                onChange={(e) => setSummary(e.target.value)}
                rows='6'
                type='text'
                value={summary}
              />
            {
              attachment && attachment.Url !== ''
              ? <img src={attachment.Url} width='100' height='100' className={classes.attachedImg} />
              : null
            }
            </DialogContent>
            <DialogActions classes={{root: classes.actionsCtr}}>
              <div>
                <ImageFileDrop
                  setAttachment={handleSetAttachment}
                >
                  <Fab
                    aria-label='Attach an image'
                    className={classes.actionAttachImgBtn}
                    color='secondary'
                  >
                    <ImageOutlinedIcon />
                  </Fab>
                </ImageFileDrop>
              </div>
              <div>
                <Button color='primary'
                  onClick={() => setOpen(false)}
                >
                  {'Cancel'}
                  </Button>
                <Button
                  color='primary'
                  disabled={!summary || summary.length === 0}
                  onClick={() => { triggerAction(summary, issue, propertySelected); emptyForm(); setOpen(false); }}
                  variant='contained'
                >
                  {'Send'}
                  </Button>
              </div>
            </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(RequestHeader);
