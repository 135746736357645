// @flow

// Wrapper around the actual Stripe <*Element>, so that it can be used as `inputComponent`
// for Material UI's <Input>. Also adds some styling.
import React, { forwardRef } from 'react';
import * as R from 'ramda';
import { log } from '../../utils/jsUtils';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/StripeInput.Style';

type Props = {
  classes: Object,
  component: Function,
  inputRef: Function
}

const style = {
  color: '#202020',
  fontFamily: 'Product Sans, Open Sans, sans-serif',
  fontSmoothing: 'antialiased',
  '::placeholder': {
    color: '#202020'
  }
};

// const ComponentRef = React.forwardRef((props, ref) => (
//   <Component {...props} innerRef={ref} />
// ))
const StripeInput = forwardRef((props: Props, ref) => {
  const {
    classes,
    component: Component,
    inputRef,
    ...other
  } = props;

  const elementRef = React.useRef();

  React.useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus
  }));

  log('other: ', other);
  const pickedOther = R.pick(
    ['className',
      'disabled',
      'id',
      'onBlur',
      'onFocus',
      'onChange',
      'placeholder',
      'type',
      'value',
      'placeholder'],
    other
  );
  log('pickedOther: ', pickedOther);
  return (
    <Component
      onReady={element => (elementRef.current = element)}
      style={{base: style}}
      {...pickedOther}
    />
  );
});
StripeInput.displayName = 'StripeInput';

export default withStyles(styles)(StripeInput);
