// @flow

import teal from '@material-ui/core/colors/teal';

export const FOOTER_HEIGHT = 70;

const styles = (theme: Object) => ({
  root: {
  },
  cta: {
    height: FOOTER_HEIGHT,
    width: '100%',
    borderRadius: 0,
    '& span': {
      color: 'white',
      fontSize: '1.2rem',
      fontWeight: 600
    }
  },
  signup: {
    background: theme.palette.primary.main
  },
  signin: {
    background: theme.palette.primary.light
  }
});

export default styles;