// @flow

import React, { useState } from 'react';
import classNames from 'classnames';
import { useStore } from '../../store';
import { truncateString } from '../../utils/jsUtils';

import Hidden from '@material-ui/core/Hidden';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';

import { calculateCreditsForMsg } from '../../models/Message';
import { log } from '../../utils/jsUtils';
import UserProfileMenu from '../UserProfileMenu';
import ForwardRef from '../SharedComponents/ForwardRef';
import Broadcast from './Broadcast';
import Mailto from '../Mailto';
import {
  shareRequestEmailHeaders,
  renderAddress,
  allowActionPerStatus,
  REF_ACTIONS
} from '../../models/Request';
import MessageTenantDialog from '../Tenants/MessageTenantDialog';
import SPBackend from '../../services/SPBackend';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/MemberHeader.Style';

import type { Task, Unit } from '../../services/SPBackend';

type Props = {
  classes: Object,
  isDemoMode: bool,
  mobileOpen: bool, 
  usehandleDrawerToggle: Function,
  request: Task,
  unit: Unit,
  refreshRequestDetails: Function,
  updateRequestStatus: Function,
  setOpenAssignTaskDialog: Function
};

const RequestHeader = (props: Props) => {
  // log('[RequestHeader] props: ', props);
  const {
    classes,
    usehandleDrawerToggle,
    request,
    refreshRequestDetails,
    setOpenAssignTaskDialog
  } = props;
  // const [isLoadingMarkAsCurrent, setLoadingMarkAsCurrent] = useState(false);
  // const [isLoadingMarkAsFinished, setLoadingMarkAsFinished] = useState(false);
  // const [openPrivateMessageDialog, setOpenPrivateMessageDialog] = useState(false);
  // const ref = React.createRef();
  const [store, dispatch] = useStore();
  const {
    user,
    i18n,
    accountUsage
  } = store;
  log('[RequestHeader] request: ', request);

//   const handleSendReply = (tenant, messagePayload, successCallback, failureCallback) => {
//     log('[handleSendReply][sendPMToTenant] tenant, message: ', tenant, messagePayload);
//     log('[handleSendReply][sendPMToTenant] msg line split: ', messagePayload.message.split('\n'));
//     // verify that credit balance is sufficient
//     const creditsNeeded = calculateCreditsForMsg(messagePayload);
//     log('[sendBroadcast] creditsNeeded: ', creditsNeeded);
//     if (accountUsage.TotalCreditsBalance < creditsNeeded) {
//       failureCallback(`Not enough credits to send this message.
// <br />Visit <a href="/billing">Billing</a> to <b>add a minimum of <u>${creditsNeeded - accountUsage.TotalCreditsBalance} credits</u></b> to your account.
// <br />${creditsNeeded} credits total will be used to send this message.`);
//     } else {
//       if (user !== null) {
//         SPBackend.sendPMToTenant(tenant._id, request._id, messagePayload, user)
//           .then((response) => {
//             const { data } = response;
//             log('[handleSendReply][sendPMToTenant] response: ', tenant, response);
//             dispatch({
//               type: 'ACTION_SET_SNACK_MSG', 
//               snackMessage: {
//                 message: data.message,
//                 level: data.success,
//                 duration: 3000
//               }
//             });
//             refreshRequestDetails();
//             successCallback();
//           })
//           .catch(error => {
//             log('[handleSendReply][sendPMToTenant] error: ', error);
//             dispatch({
//               type: 'ACTION_SET_SNACK_MSG', 
//               snackMessage: {
//                 message: 'An error occured. Please try again.',
//                 level: 'error',
//                 duration: 3000
//               }
//             });
//             failureCallback();  
//           });
//       }
//     }
//   };

  return (
    <AppBar 
      className={classes.appBar}
      position="fixed"
    >
      <Toolbar
        className={classes.toolbar}
        disableGutters
      >
        <Grid 
          container
          direction='column'
        >
          <Grid 
            className={classNames(
              classes.rowSub,
              classes.topBar
            )}
            item
          >
        
            <Grid
              className={classes.rowSubContainer}
              container
              justifyContent='flex-start'
            >
              <IconButton
                aria-label="Open drawer"
                className={classes.menuButton}
                color="inherit"
                onClick={usehandleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid> 
            <Grid item>
              <Typography 
                className={classes.pageTitle}
                variant='h3'
              >
                <Hidden mdUp>
                  {request ? truncateString(request.Issue) : null}
                </Hidden>
                <Hidden smDown>
                  <a href='/member'>{i18n.t('Activity')}</a>{` > ${request ? truncateString(request.Issue) : ''}`}
                </Hidden>
              </Typography>
            </Grid>  
            <div className={classes.grow} />
            <UserProfileMenu />         

          </Grid>
          <Grid
            className={classNames(
              classes.rowSub,
              classes.bottomBar
            )}
            item
          >
            <Grid
              className={classes.bottomBarContainer}
              container
            >
            {/* {
              request && allowActionPerStatus(REF_ACTIONS.SEND_NOTE, request.Status)
              ? <div 
                  className={classNames(
                    classes.toggleContainer,
                    classes.dividerRight
                  )}
                >
                  <Tooltip
                    aria-label={i18n.t('Send a reply')}
                    title={i18n.t('Send a reply')}
                  >
                    <IconButton
                      aria-label={'Send a reply'}
                      className={classes.navButton}
                      onClick={e => {
                        e.stopPropagation();
                        setOpenPrivateMessageDialog(true);
                      }}
                    >
                      <i className={'icon-sent'} />
                      <div>{'Reply'}</div>
                    </IconButton>
                    {/* <ForwardRef>
                      <Broadcast
                        scope='request'
                        triggerAction={sendNote}
                      />
                    </ForwardRef> */}
                  {/* </Tooltip>
                </div>
              : null
            } */} 

            {/* {
              request && allowActionPerStatus(REF_ACTIONS.MARK_AS_PROGRESS, request.Status)
              ? <div 
                  className={classNames(
                    classes.toggleContainer
                    )}
                >
                  <Tooltip 
                    aria-label={'Mark as "in Progress"'}
                    title={'Mark as "in Progress"'} 
                  >
                    <ForwardRef>
                    <Button
                      className={classes.navButton}
                      onClick={updateRequestStatus('CURRENT', setLoadingMarkAsCurrent)}
                      value="In Progress"
                    >
                    {
                      isLoadingMarkAsCurrent
                      ? <CircularProgress
                        onClick={(e) => e.stopPropagation()}
                        size={24}
                        />
                      : <i className={'icon-progress'} />
                    }
                      <div>{'In progress'}</div>
                    </Button>
                    </ForwardRef>
                  </Tooltip>
                </div>
              : null
            }  
            {
              request && allowActionPerStatus(REF_ACTIONS.MARK_AS_DONE, request.Status)
              ? <div 
                  className={classNames(
                    classes.toggleContainer
                    )}
                >
                  <Tooltip 
                    aria-label={'Mark as "Done"'}
                    title={'Mark as "Done"'} 
                  >
                    <ForwardRef>
                    <Button
                      className={classes.navButton}
                      onClick={updateRequestStatus('FINISHED', setLoadingMarkAsFinished)}
                      value="Done"
                    >
                    {
                      isLoadingMarkAsFinished
                      ? <CircularProgress
                        onClick={(e) => e.stopPropagation()}
                        size={24}
                        />
                      : <i className={'icon-finished'} />
                    }
                      <div>{'Done'}</div>
                    </Button>
                    </ForwardRef>
                  </Tooltip>
                </div>
              : null
            } */}
            
              {
                request
                && allowActionPerStatus(REF_ACTIONS.SHARE, request.Status)
                ? <div 
                    className={classNames(
                      classes.toggleContainer
                      )}
                  >
                    <Tooltip 
                      aria-label={'Assign'}
                      title={'Assign'} 
                    >
                    <ForwardRef>
                      <Button
                        className={classes.navButton}
                        onClick={() => setOpenAssignTaskDialog(true)}
                      >
                        <i className={'icon-assign'} />
                        <div>{'Assign'}</div>
                      </Button>
                      </ForwardRef>
                    </Tooltip>
                  </div>
                : null
              }
              {/* {
                request
                && allowActionPerStatus(REF_ACTIONS.SHARE, request.Status)
                ? <div 
                    className={classNames(
                      classes.toggleContainer,
                      classes.dividerRight
                    )}
                  >
                    <Mailto 
                      email={null}
                      headers={shareRequestEmailHeaders(request, userFullName, renderAddress(unit))}
                      obfuscate={false}
                    >
                      <Tooltip 
                        aria-label={'Share Request'}
                        title={'Share Request'} 
                      >
                        <ForwardRef>
                        <Button
                          className={
                            classes.navButton}
                          value="Share"
                        >
                          <i className={'icon-share'} />
                          <div>{'Share'}</div>
                        </Button>
                        </ForwardRef>
                      </Tooltip>
                    </Mailto>
                  </div>
                : null
              } */}
              
              {/* {
                request 
                && allowActionPerStatus(REF_ACTIONS.MERGE, request.Status)
                && isDemoMode
                ? <div 
                    className={classNames(
                      classes.toggleContainer
                    )}
                  >
                    <Tooltip 
                      aria-label={'Merge'}
                      title={'Merge'} 
                    >
                      <ForwardRef>
                      <Button
                        className={classes.navButton}
                      >
                        <i className={'icon-merge'} />
                        <div>{'Merge'}</div>
                      </Button>
                      </ForwardRef>
                    </Tooltip>
                  </div>
                : null
              }
              
              {
                request 
                && allowActionPerStatus(REF_ACTIONS.SPLIT, request.Status)
                && isDemoMode
                ? <div 
                    className={classNames(
                      classes.toggleContainer
                    )}
                  >
                    <Tooltip 
                      aria-label={'Split'}
                      title={'Split'} 
                    >
                      <ForwardRef>
                      <Button
                        className={classes.navButton}
                      >
                        <i className={'icon-split'} />
                        <div>{'Split'}</div>
                      </Button>
                      </ForwardRef>
                    </Tooltip>
                  </div>
                : null
              } */}

              <div className={classes.grow} />
              
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      {/* <MessageTenantDialog
        open={openPrivateMessageDialog}
        setOpen={setOpenPrivateMessageDialog}
        tenant={request ? request.lookupTenant : null}
        triggerAction={handleSendReply}
      /> */}
    </AppBar>
  );
};

export default withStyles(styles)(RequestHeader);
