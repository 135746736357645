// @flow

import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import { useStore } from '../../store';
import * as R from 'ramda';

import Grid from '@material-ui/core/Grid';
import Chart from 'chart.js';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';


import { withStyles } from '@material-ui/core/styles';
import MemberStructuralStyles from '../Styles/CommonMemberStructuralStyles.Style';
import styles from './Styles/AnalyticsContent.Style';
import Hidden from '@material-ui/core/Hidden';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import ListTableRow from '../ListTableRow';
import FeedDialog from './FeedDialog';

import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

import SPBackend from '../../services/SPBackend';
import type { KPIs, Task } from '../../services/SPBackend';
import { log } from '../../utils/jsUtils';
import combineStyles from '../../utils/stylesUtils';

const moment = require('moment');
type Props = {
  classes: Object
};

const AnalyticsContent = (props: Props) => {
  const { classes } = props;
  const [store, dispatch] = useStore();

  const [kpis, setKpis] = useState([]);
  const [datesRange, setDatesRange] = useState(['', '']);
  const [hoursRange, setHoursRange] = useState(['', '']);
  const [datesLoaded, setDatesLoaded] = useState(false);
  const [properties, setProperties] = useState([]);
  const [currentChart, setCurrentChart] = useState(null);
  
  const [openFeedModal, setOpenFeedModal] = useState(false);
  const [currentSlug, setCurrentSlug] = useState('');

  const {
    user,
    numberOfDays,
    i18n
  } = store;

  // Fetch KPIs
  useEffect(() => {
    if (user !== null) {
      const kpis = SPBackend.getMainAnalyticsKPIs(user, numberOfDays);
      const graph = SPBackend.getGraph(user, numberOfDays);
      const progressBars = SPBackend.getAnalyticsProperties(user, numberOfDays);
      kpis
        .then(response => {
          log('[getMainAnalyticsKPIs], response: ', response);
          const { data } = response;
          log('[getMainAnalyticsKPIs], kpis: ', data.kpis);
          log('[AnalyticsContent] units: ', data.Units);
          const { kpis, Units } = data;
          setKpis(kpis);
          // TODO: Move this to the state.
          dispatch({
            type: 'ACTION_SET_UNITS',
            units: Units
          });
        })
        .catch(error => {
          log('[getMainAnalyticsKPIs] error: ', error);
        });

      graph
      .then(response => {
        log('[getGraph], response: ', response);
        const { data } = response;
        log('[getGraph] data', data);
        const daysList = data.map(elt => elt.day);
        const hoursList = [];
        const currentHour = moment();
        const nextHour = moment().add(1, 'hours');
        for (let time = currentHour.subtract(1, 'days'); time.isBefore(nextHour); time.add(1, 'hours')) {
          hoursList.push(time.format('h a'));
        }
        setHoursRange([
          hoursList[0],
          hoursList[hoursList.length - 1]
        ]);
        setDatesLoaded(true);
        setDatesRange([
          daysList[0],
          daysList[daysList.length - 1]
        ]);
        const countList = data.map(elt => elt.count);
        if (currentChart !== null) {
          currentChart.destroy();
        }
        const ctx = document.getElementById('myChart').getContext('2d');
        const myChart = new Chart(ctx, {
          type: (numberOfDays === 1) ? 'bar' : 'line',
          data: {
            labels: (numberOfDays === 1) ? hoursList : daysList,
            datasets: [
              {
                label: ' Total Tasks',
                fill: 'origin',
                data: countList,
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                pointStyle: 'circle',
                borderWidth: 2,
                hitRadius: 30,
                pointHoverRadius: 5,
                pointRadius: 0
              }
            ]
          },
          options: {
            legend: {
              display: false
            },
            title: {
              fontFamily: 'Product Sans',
              display: true,
              fontSize: 14,
              text: `Tasks over the past ${numberOfDays > 1 ? numberOfDays + ' days' : 'day'}`
            },
            responsive: true,
            labels: {
              fontFamily: 'Product Sans',
              fontSize: 12,
              boxWidth: 12,
              usePointStyle: true
            },
            layout: {
              padding: {
                left: 48,
                right: 48,
                top: 48,
                bottom: 48
              }
            },
            tooltips: {
              callbacks: {
                label: function(tooltipItem, data) {
                    const label = data.datasets[tooltipItem.datasetIndex].label || '';
                    const count = Math.round(tooltipItem.yLabel * 100) / 100;
                    return `${label}: ${count}`;
                }
              }
            },
            scales: {
              xAxes: [
                {
                display: true,
                gridLines: {
                  display: false
              }
              }
            ],
              yAxes: [
                {
                  display: false,
                  ticks: {
                    display: false,
                    beginAtZero: true
                  }
                }
              ]
            }
          }
        });
        setCurrentChart(myChart);
      })
      .catch(error => {
        log('[getGraph] error: ', error);
      });  

      progressBars
      .then(response => {
        log('[getAnalyticsProperties] response: ', response);
        const { data } = response;
        log('[getAnalyticsProperties] data: ', data);
        setProperties(data);
      })
      .catch(error => {
        log('[getAnalyticsProperties] error: ', error);
      });
    }
  }, [user, numberOfDays]);

  const handleOpenFeedDialog = (kpi: KPIs) => {
    setCurrentSlug(kpi.slug);
    dispatch({
      type: 'ACTION_SET_REQUESTS', 
      requests: kpi.tasks
    });
    setOpenFeedModal(true);
  };

  const updateKPIs = (updatedTask: Task, oldStatus: string) => {
    log('[updateKPIs]... updatedTask, oldStatus: ', updatedTask, oldStatus, kpis);
    setKpis((prevKPIs) => {
      const newKPIs = prevKPIs.map((kpi: KPIs) => {
        if (kpi.slug === oldStatus) {
          const index = R.findIndex(R.propEq('_id', updatedTask._id), kpi.tasks);
          log('[updateKPIs] found index to remove: ', index, kpi);
          const newArray = R.remove(index, 1, kpi.tasks);
          kpi.tasks = newArray;
          kpi.count--;
        } else if (kpi.slug === updatedTask.Status) {
          log('[updateKPIs] found kpi to insert: ', updatedTask, kpi);
          const newArray = kpi.tasks;
          newArray.unshift(updatedTask);
          kpi.tasks = newArray;
          kpi.count++;
        }
        return kpi;
      });
      log('[updateKPIs] newKPIs: ', newKPIs);
      return newKPIs;
    });
    log('[updateKPIs] state kpis updated: ', kpis);
  };

  const [startHour, endHour] = hoursRange;
  const [startDt, endDt] = datesRange;
  return (
    <Grid 
      className={classes.root}
    >
      <div className={classes.toolbar} />
      
        <Grid
          className={classes.kpiSpacing}
          container
        >
          <Grid
            item
            xs={12}
          >
            {
              datesLoaded
              ? <Typography
                  className={classes.legend}
                  variant='body1'
                >{`Showing past ${numberOfDays > 1 ? numberOfDays + ' days' : 'day'}: ${numberOfDays > 1 ? startDt : startHour} to ${numberOfDays > 1 ? endDt : endHour}.`}</Typography>
              : <Typography
                  className={classes.legend}
                  variant='body1'
                >{'Loading...'}</Typography>
            }
          </Grid>
          {kpis.map((kpi, index) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <Grid
                className={classes.kpiItem}
                item
                key={`card-${index}`}
                md={3}
                xs={12}
              >
                <Card
                  className={classNames(
                    classes.Cards,
                    kpi.slug === 'INQUIRIES' ? classes.inquiries : null,
                    kpi.slug === 'PENDING' ? classes.pending : null,
                    kpi.slug === 'CURRENT' ? classes.current : null,
                    kpi.slug === 'FINISHED' ? classes.finished : null
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenFeedDialog(kpi);
                  }}
                >
                  <CardContent className={classes.kpiCount}>{kpi.count}</CardContent>
                  <CardActions className={classes.kpiCardAction}>
                    <label className={classes.cardLabels}>{kpi.label}</label>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
          <Grid
            item
            xs={12}
          >
            <Hidden
              key='chart-mobile'
              mdUp
            >
              <canvas
                height='100px'
                id='myChart'
                width='100vw'
              />
            </Hidden>
            <Hidden
              key='chart-desktop'
              smDown
            >
              <canvas
                height='250px'
                id='myChart'
                width='400px'
              />
            </Hidden>
        </Grid>
        
        <Grid className = {classes.tableContent}>
        <h3 style = {{color: '#5b5b5b'}}>{`${i18n.t('Properties')} Status`}</h3>
        <div >
        {
          <Table className={classes.table}>
          <colgroup>
            <col />
            <col />
            <col />
          </colgroup>
          <TableBody className={classNames(
              classes.feedTableBody,
              classes.customTableStyles
            )}
          >
            {
              properties.map((properties, pIndex) => {
                return (
                  <ListTableRow key={`building=${pIndex}`}>
                    <TableCell className = {classNames(classes.cell, classes.addressCell)}
                              component="td"
                              scope="property"
                    >
                    <div>
                      <div>{properties.propertyName}</div>
                      <div className = {classes.unitsCount}> {`${properties.unitCount} Units`}</div>
                    </div>

                    </TableCell>
                    <TableCell className = {classNames(classes.cell, classes.nbCell)}
                              component="td"
                              scope="property"
                    >
                    <div className={classes.totalRequestsColumn}>{`${properties.totalRequestCount} Requests`}</div>

                    </TableCell>
                    <TableCell className = {classes.cell}
                              component="td"
                              scope="property"
                    >
                    <div className={classes.progressBars}>
                      <Tooltip className = {classes.toolTip}
                              placement="top"
                              title={`Requests finished: ${properties.finishedCount}`}
                              // eslint-disable-next-line react/jsx-sort-props
                              TransitionComponent={Zoom}
                      >
                        <div className = {classes.finishedRequestsStyles} 
                              style = {{width: ((properties.finishedCount / properties.totalRequestCount) * 100) ? Math.round(((properties.finishedCount / properties.totalRequestCount) * 100)) * 2 : 0,
                                        borderTopLeftRadius: 25,
                                        borderBottomLeftRadius: 25,
                                        borderTopRightRadius: (properties.inProgressCount === 0 && properties.notStartedCount === 0) ? 25 : 0,
                                        borderBottomRightRadius: (properties.inProgressCount === 0 && properties.notStartedCount === 0) ? 25 : 0}}
                        />
                      </Tooltip>
                      <Tooltip placement = "top"
                              title = {`Requests in progress: ${properties.inProgressCount}`}
                              // eslint-disable-next-line react/jsx-sort-props
                              TransitionComponent={Zoom}
                      > 
                      <div className = {classes.inProgressRequestsStyles} 
                          style = {{width: ((properties.inProgressCount / properties.totalRequestCount) * 100) ? Math.round(((properties.inProgressCount / properties.totalRequestCount) * 100)) * 2 : 0,
                                    borderTopLeftRadius: properties.finishedCount === 0 ? 25 : 0,
                                    borderBottomLeftRadius: properties.finishedCount === 0 ? 25 : 0,
                                    borderTopRightRadius: properties.notStartedCount === 0 ? 25 : 0,
                                    borderBottomRightRadius: properties.notStartedCount === 0 ? 25 : 0
                                    }}
                      />
                      </Tooltip> 
                      <Tooltip placement = 'top'
                              title = {`Requests not started: ${properties.notStartedCount}`}
                              // eslint-disable-next-line react/jsx-sort-props
                              TransitionComponent={Zoom} 
                      >
                      <div className = {classes.notStartedRequestsStyles}
                          style = {{width: ((properties.notStartedCount / properties.totalRequestCount) * 100) ? Math.round(((properties.notStartedCount / properties.totalRequestCount) * 100)) * 2 : 0,
                                    borderTopRightRadius: 25,
                                    borderBottomRightRadius: 25,
                                    borderTopLeftRadius: (properties.inProgressCount === 0 && properties.finishedCount === 0) ? 25: 0,
                                    borderBottomLeftRadius: (properties.inProgressCount === 0 && properties.finishedCount === 0) ? 25: 0
                                    }}
                      />
                      </Tooltip>
                    </div>
                    </TableCell>
                </ListTableRow>
                );
              })
            }
          </TableBody>
        </Table>
        }
        </div>
      </Grid>  
    </Grid>
    <FeedDialog
      open={openFeedModal}
      setOpen={setOpenFeedModal}
      slug={currentSlug}
      updateKPIs={updateKPIs}
    />
  </Grid>
    
  );
};

const combinedStyles = combineStyles(
  MemberStructuralStyles,
  styles
);

export default withStyles(combinedStyles)(AnalyticsContent);
