// @flow

import React from 'react';
import {useState} from 'react';
import classNames from 'classnames';
import { log } from '../../utils/jsUtils';

import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import AssignIcon from '@material-ui/icons/Share';
import Zoom from '@material-ui/core/Zoom';

import HeaderTopBar from '../HeaderTopBar';

import { withStyles } from '@material-ui/core/styles';
import styles from '../Feed/Styles/MemberHeader.Style';
import { useStore } from '../../store';


type Props = {
  classes: Object,
  mobileOpen: bool, 
  usehandleDrawerToggle: Function,
};

const AnalyticsHeader = (props: Props) => {
  log('[AnalyticsHeader] props: ', props);
  const {
    classes,
    usehandleDrawerToggle
  } = props;

  const [store, dispatch] = useStore();
  const [openDateRangeFilterOptions, setOpenDateRangeFilterOptions] = useState(null);

  const {
    isDemoMode
  } = store;

  const handleNumberOfDaysClick = (days: number) => {
    dispatch({
      type: 'ACTION_SET_NUMBER_OF_DAYS',
      numberOfDays: days
    });
    setOpenDateRangeFilterOptions(null);
  };

  return (
    <AppBar 
      className={classes.appBar}
      position="fixed"
    >
      <Toolbar
        classes={{
          root: classes.toolbarRoot
        }}
        disableGutters
      >
        <Grid 
          container
          direction='column'
        >
          <HeaderTopBar
            usehandleDrawerToggle={usehandleDrawerToggle}
          >
            <Typography 
              className={classes.pageTitle}
              variant='h3'
            >{'Analytics'}</Typography>
          </HeaderTopBar>
          <Grid
            className={classNames(
              classes.rowSub,
              classes.bottomBar
            )}
            item
          >
            <Grid
              className={classes.bottomBarContainer}
              container
            >
              {
                isDemoMode
                ? <div 
                  className={classNames(
                    classes.toggleContainer,
                    classes.dividerRight
                  )}
                  >
                  <Tooltip 
                    aria-label={'Create a report'}
                    title={'Create a report'} 
                  >
                    <Button
                      className={classes.navButton}
                    >
                      <i className={'icon-add'} />
                      <div>{'Report'}</div>
                    </Button>
                  </Tooltip>
                </div>
                : null
              }
              {
                isDemoMode
                ? <div 
                    className={classNames(
                      classes.toggleContainer
                    )}
                  >
                  <Tooltip 
                    aria-label={'Assign'}
                    title={'Assign'} 
                  >
                    <Button
                      className={classes.navButton}
                    >
                      <i className={'icon-assign'} />
                      <div>{'Assign'}</div>
                    </Button>
                  </Tooltip>
                </div>
                : null
              }
              
              {
                isDemoMode
                ? <div 
                    className={classNames(
                      classes.toggleContainer
                    )}
                  >
                    <Tooltip 
                      aria-label={'Share'}
                      title={'Share'} 
                    >
                      <Button
                        className={classes.navButton}
                      >
                        <AssignIcon fontSize="small" />
                        <div>{'Share'}</div>
                      </Button>
                    </Tooltip>
                  </div>
                : null
              }

              <div className={classes.grow} />
                <div 
                  className={classNames(
                    classes.toggleContainer
                  )}
                >
                  <Tooltip title = 'Filter by date range'>
                    <Button
                      aria-controls="menu-list-grow"
                      aria-haspopup="true"
                      className={classes.navButton}
                      onClick={(e) => setOpenDateRangeFilterOptions(e.currentTarget)}
                    > 
                      <i className='icon-calendar' />
                      <div>{'Filter'}</div>
                    </Button>
                  </Tooltip>
                  <Menu
                    anchorEl={openDateRangeFilterOptions}
                    classes={{
                      paper: classes.menuRoot
                    }}
                    disableAutoFocusItem
                    id="header-date-range-filter"
                    key='2'
                    // eslint-disable-next-line react/jsx-sort-props
                    MenuListProps={{
                      classes: {
                        root: classes.subMenuListRoot
                      }
                    }}
                    onClose={() => setOpenDateRangeFilterOptions(null)}
                    open={Boolean(openDateRangeFilterOptions)}
                  >
                    <MenuItem
                      className={classes.menuListItem}
                      disableGutters
                      onClick={() => handleNumberOfDaysClick(1)}
                      selected={false}
                    >
                      {'Yesterday'}
                    </MenuItem>
                    <MenuItem
                      className={classes.menuListItem}
                      disableGutters
                      onClick={() => handleNumberOfDaysClick(7)}
                      selected={false}
                    >
                      {'Last Week'}
                    </MenuItem>
                    <MenuItem
                      className={classes.menuListItem}
                      disableGutters
                      onClick={() => handleNumberOfDaysClick(30)}
                      selected={false}
                    >
                      {'Last Month'}
                    </MenuItem>
                    <MenuItem
                      className={classes.menuListItem}
                      disableGutters
                      onClick={() => handleNumberOfDaysClick(90)}
                      selected={false}
                    >
                      {'Last Quarter'}
                    </MenuItem>
                    <MenuItem
                      className={classes.menuListItem}
                      disableGutters
                      onClick={() => handleNumberOfDaysClick(365)}
                      selected={false}
                    >
                      {'Last Year'}
                    </MenuItem>
                  </Menu>
              </div>
              {
                isDemoMode
                ? <div 
                  className={classNames(
                    classes.toggleContainer
                  )}
                  >
                  <Tooltip 
                    aria-label={'Help'}
                    title={'Help'} 
                  >
                    <Button
                      className={classes.navButton}
                    >
                      <i className='icon-help' />
                      <div>{'Help'}</div>
                    </Button>
                  </Tooltip>
                </div>
                : null
              }
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(AnalyticsHeader);
