// @flow

const styles = (theme: Object) => ({
  cardCtr: {
    marginTop: theme.spacing(2),
    maxWidth: 700,
    borderRadius: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  normal: {
    fontWeight: 400
  },
  downloadLink: {
    color: theme.palette.primary.dark
  },
  addCardCancel: {
    position: 'relative',
    textDecoration: 'none',
    color: 'black',
    fontSize: '0.8rem',
    marginLeft: 10,
    top: 4
  },
  sectionTitleLegend: {
    marginTop: -theme.spacing(1.5),
    color: theme.palette.custom.backgroundTextInfo
  },
  topupTable: {
    // tableLayout: 'fixed',
    marginTop: theme.spacing(2),
    width: '100%',
    overflow: 'scroll'
  },
  col1: {
    minWidth: 100
  },
  col2: {
    minWidth: 100
  },
  col3: {
    minWidth: 160
  },
  col4: {
    minWidth: 100
  },
  col5: {
    textAlign: 'right'
  },
  priceCell: {
    fontSize: '1rem',
    fontWeight: '600',
    color: theme.palette.primary.main
  },
  tableHeadRowCtr: {
    '& th': {
      paddingBottom: theme.spacing(0.5),
      fontSize: '0.9rem',
      color: 'hsl(0,0%,50%)',
      fontWeight: 400
    }
  },
  topupDetails: {
    color: theme.palette.custom.backgroundTextInfo
  },
  textBold: {
    '& th': {
      fontWeight: 600
    }
  },
  usageTable: {
    display: 'table !important',
    '& th:first-child, & td:first-child': {
      paddingLeft: theme.spacing(4)
    },
    '& th:last-child, & td:last-child': {
      paddingRight: theme.spacing(4)
    }
  },
  highlightCredits: {
    fontSize: '1.4rem'
  },
  defaultChip: {
    color: 'black'
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer'
  }
});

export default styles;