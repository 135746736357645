// @flow 

import React, {useEffect} from 'react';
import { useStore } from '../store';
// import type {IContextStore} from '../store/makeStore';
import { log } from '../utils/jsUtils';
import { useHistory } from 'react-router-dom';

const Logout = ({children}) => {
  const [store, dispatch] = useStore();
  const history = useHistory();
  // log('[logout] props: ', props);
  
  // const { children, history } = props;

  useEffect(() => {
    log('[Logout effect] useEffect - loggedIn: ', store);
    const {loggedIn} = store;
    if (!loggedIn) {
      window.localStorage.removeItem('loggedIn');
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('email');
      window.localStorage.removeItem('userRole');
      window.localStorage.removeItem('userFullName');
      log('[Logout effect] redirecting to /');
      history.push('/');
    }
  }, [store.loggedIn]);

  return (
    <div>
      {
        store.loggedIn 
        ? <div 
            onClick={() => dispatch({ type: 'ACTION_LOGOUT' })}
          >
            {children}
          </div>
        : null
      }
    </div>
  );
};

export default Logout;