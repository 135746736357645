// @flow

const styles = (theme: Object) => ({
  processLegend: {
    textAlign: 'center'
  },
  dialogContentCtr: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    position: 'relative'
  },
  arrowIcon:{
    position: 'relative',
    fontSize: 52,
    opacity:  '25%'
  },
  textField: {
    '& label.MuiInputLabel-shrink': {
      transform: 'translate(24px, -5px) scale(0.75)'
    }
  },
  textFieldCaptionRight: {
    textAlign: 'right',
    color: theme.palette.primary.light,
    fontSize: '0.8rem'
  },
  iconButton:{
    float: 'left'
  },
  errorLabel: {
    marginLeft: theme.spacing(1),
    color: '#f44336 !important'
  },
  tagCtr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  colorPickerRainbow: {
    background: 'conic-gradient(red, yellow, lime, aqua, blue, magenta, red)',
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: theme.spacing(3),
    cursor: 'pointer',
    position: 'relative',
    top: 6
  },
  popperCtr: {
    zIndex: 9999,
    top: '12px !important',
    left: '116px !important',
    [theme.breakpoints.down('xs')]: {
      left: '32px !important'
    }
  },
  margin: {
    marginTop: theme.spacing(3)
  }
});
  
export default styles;