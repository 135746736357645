// @flow

import React, { useState, useEffect } from 'react';
import { useStore } from '../../store';
import * as R from 'ramda';
import classNames from 'classnames';
import invert from 'invert-color';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { log } from '../../utils/jsUtils';
import TagReadonly from './TagReadonly';
import TagCustomersPicker from '../Feed/TagCustomersPicker';
import ForwardRef from '../SharedComponents/ForwardRef';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { withStyles } from '@material-ui/core/styles';
import styles from '../Feed/Styles/RequestTypeTag.Style';

import type { Tag } from '../../services/SPBackend';

type Props = {
  callbackOnTagPicked: Function,
  callbackOnShowTagsPicker: Function,
  callbackOnHideTagsPicker: Function,
  callbackOnTagRemoved?: Function,
  classes: Object,
  renderTags: Array<Tag> // list of tags to render
}
const TagEditable = (props: Props) => {
  const {
    classes,
    callbackOnTagPicked,
    callbackOnShowTagsPicker,
    callbackOnHideTagsPicker,
    callbackOnTagRemoved,
    renderTags
  } = props;

  const [store, dispatch] = useStore();
  const [targetForSubmenu, setTargetForSubmenu] = useState(null);
  const [showTagsPicker, setShowTagsPicker] = useState(false);

  const tagPickerAnchorRef = React.useRef();

  const wrapInSpan = (label) => { // html2canvas will only read words in Chip if they are wrapped in a double span 
    const spanningLabel = <span><span>{label}</span></span>;
    return (spanningLabel);
  };

  type TagEditableRenderProps = {
    classes: Object,
    tagsIds: Array<string>
  }

  const toggleTagPicker = (e) => {
    e.stopPropagation();
    log('[toggleTagPicker]... callbackOnShowTagsPicker: ', typeof callbackOnShowTagsPicker);
    if (!showTagsPicker) {
      setTargetForSubmenu(e.currentTarget);
    }
    setShowTagsPicker(!showTagsPicker);
    if (!showTagsPicker) {
      callbackOnShowTagsPicker();
    } else {
      callbackOnHideTagsPicker();
    }
  };

  return (
      <Grid 
        className={classes.tagContentCtr}
        container
        direction='row'
        ref={tagPickerAnchorRef}
        wrap='nowrap'
      >
        <Grid className={classes.tagReadonlyCtr}
              item
        >
        {
          renderTags && renderTags.length > 0
          ? [<TagReadonly
              callbackOnTagRemoved={callbackOnTagRemoved}
              handleOnTagClick={toggleTagPicker}
              key='1'
              renderTags={renderTags}
             />,
            <IconButton aria-label='Add new tag'
              className={classes.addIcon}
              component='span'
              key='2'
              onClick={toggleTagPicker}
              size='small'
            >
              <AddCircleOutlineIcon className={classes.addBtn}/>
            </IconButton>
          ]
          : <TagReadonly 
              handleOnTagClick={toggleTagPicker}
              renderTags={[{
                Name: 'Add tag',
                Color: '#EEE'
              }]}
            />
        }
          <TagCustomersPicker
            anchorEl={targetForSubmenu}
            callbackOnHideTagsPicker={callbackOnHideTagsPicker}
            callbackOnShowTagsPicker={callbackOnShowTagsPicker}
            open={showTagsPicker}
            setOpen={setShowTagsPicker}
            tagActionCallback={callbackOnTagPicked}
          />
        </Grid>
      </Grid>
  );
};

export default withStyles(styles)(TagEditable);
