// @flow

// A Wrapper for the <FormControl>, <InputLabel>, <Error> and the Stripe <*Element>.
// Similar to Material UI's <TextField>. Handles focused, empty and error state
// to correctly show the floating label and error messages etc.
import React, { useState, useMemo, useRef } from 'react';
import { log } from '../../utils/jsUtils';
import { FormControl } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import ForwardRef from '../SharedComponents/ForwardRef';
import StripeInput from './StripeInput';
import { withStyles } from '@material-ui/core/styles';
import signupStyles from '../PublicCpts/Styles/Signup.Style';
import combineStyles from '../../utils/stylesUtils';
import styles from './Styles/StripeElementWrapper.Style';
import {prop} from 'ramda';
// import Errors from './Errors';

type Props = {
  component: Function,
  label: string,
  id: string,
  ref: Object
}
type State = {
  focused: boolean,
  empty: boolean,
  error: boolean,
  value: Object
}


const RenderStripeInput = React.forwardRef<Props, Object>((props: Props, ref: Object) => (
  <StripeInput
    ref={ref}
    {...props}
  />
));
RenderStripeInput.displayName = 'RenderStripeInput';


// eslint-disable-next-line react/no-multi-comp
const StripeElementWrapper = React.forwardRef<Props, Object>((props: Props, ref: Object) => {
  const [state, setState] = useState({
    focused: false,
    empty: true,
    error: false,
    value: {}
  });
  
  const handleBlur = () => {
    setState({...state, focused: false});
  };

  const handleFocus = () => {
    setState({ ...state, focused: true });
  };

  const handleChange = changeObj => {
    log('[StripeElementWrapper] handlechange: ', changeObj);
    setState({
      ...state,
      error: changeObj.error,
      empty: changeObj.empty,
      value: {
        ...state.value,
        ...changeObj.value
      }
    });
  };

  const { component, label, id, classes } = props;
  const { focused, empty, error } = state;

  log('[StripeEltWrapper] ref created: ', ref);

  return (
    <div ref={ref} >
      <FormControl
        className={classes.newInputForm}
        style={{marginTop: 8}}
        fullWidth
        margin="normal"
      >
      <InputLabel
        error={!!error}
        focused={focused}
        shrink={focused || !empty}
      >
                  {label}
      </InputLabel>
      {/* <Component
        id={id}
        onReady={element => (ref.current = element)}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        placeholder=''
        ref={ref}
      /> */}
      <OutlinedInput
        className={classes.newFormElt}
        id={id}
        fullWidth
        inputComponent={StripeInput}
        inputProps={{ component }}
        inputRef={ref}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        placeholder=''
        ref={ref}
        variant='outlined'
      />
      </FormControl>
      {/* redundant with parent call. {error && <div className={classes.errorLabel}>{error.message}</div>} */}
    </div>
  );
});

const combinedStyles = combineStyles(
  signupStyles,
  // styles
);

export default withStyles(combinedStyles)(StripeElementWrapper);
