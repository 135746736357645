/* eslint-disable react/no-multi-comp */
// @flow

import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import queryString from 'query-string';
import { useStore } from '../../store';
import { log } from '../../utils/jsUtils';
import Logo from '../../assets/askneo-favicon.png';
import { useForm, Controller } from 'react-hook-form';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';

import { useUserAuth } from '../Auth/UserSession';
import styles from '../PublicCpts/Styles/Signup.Style';
import SPBackend from '../../services/SPBackend';

type Props = {
  classes: Object,
  location: Object,
};
const AuthorizeHome = (props: Props) => {
  const { classes } = props;
  const location = useLocation();
  const [store, dispatch] = useStore();
  // look for the plan id in url
  const parsed = queryString.parse(location.search);
  // log('parsed: ', parsed);
  // log('window.location.origin: ', window.location.origin);

  const { register, handleSubmit, errors, control } = useForm();

  const {
    token,
    user
  } = store;

  const {
  authenticate,
  handleResetPwd,
  email,
  setEmail,
  password, 
  setPassword,
  loggedIn
} = useUserAuth();

  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [authError, setAuthError] = useState('');
  const [appClient, setAppClient] = useState({
    Name: '',
    LogoUrl: ''
  });

  useEffect(() => {
    if (parsed.secret !== '' || parsed.client_id !== '') {
      log('[page load] parsed client/secret FOUND!: ', parsed.secret, parsed.client_id);
      // TODO: check DB to load app & logo to give access to
      SPBackend.getAppClient(parsed.client_id, parsed.secret)
        .then((response) => {
          log('[getAppClient] response.data: ', response.data);
          setAppClient(response.data);
        })
        .catch((e) => {
          dispatch({
            type: 'ACTION_SET_SNACK_MSG', 
            snackMessage: {
              message: 'App not found. Try reconnecting.',
              level: 'error',
              duration: 5000
            }
          });
        });
    } else {
      console.log('[page load] parsed: ', parsed);
    }
  }, []);
  
  const formSubmit = (registration) => {
    setIsLoading(true);
    log('[formSubmit] registration: ', registration);
    if (parsed.redirect_uri !== null && typeof parsed.redirect_uri === 'string') {
      // TODO: firebase auth & pass on firebase user token id
      authenticate(null, registration.email, registration.password);
    }
  };

  useEffect(() => {
    if (token && token !== '' && token.length > 0 && user !== null) {
      log('[idToken found] ', token);
      log('[idToken found] user', user);
      // get the custom token for this user
      SPBackend.getCustomIdToken(user.uid, user)
        .then((response) => {
          console.log('[getCustomIdToken] response: ', response);
          const { customToken } = response.data;
          console.log('[getCustomIdToken] customToken: ', customToken);
          const redirect_uri = `${parsed.redirect_uri}?code=${customToken}&state=${parsed.state}&audience=${parsed.audience}&connection=${parsed.connection}&response_type=${parsed.response_type}`;
          log('[authenticate] redirecting with code: redirect_uri', redirect_uri);
          window.location.assign(redirect_uri);
        })
        .catch((e) => {
          console.log('[getCustomIdToken] error: ', e);
        });
    }
  }, [token, user]);


  const onFieldChange = () => {
    setAuthError('');
  };
  // match({ history, routes, location: request.url }, (err, redirectLocation, props) => {
  //   console.log(redirectLocation);
  // });

  return (
    <Grid
      className={classes.connectContentCtr}
      container
    >
        <Grid className={classes.headerLogo}
item
xs={12}
        >
          <img height={40}
              src={Logo}
          />
        </Grid>
        {
          appClient && appClient.Name !== ''
          ? <Paper classes={{root: classes.paperCtr}}
              square
            >
              <Grid className={classes.headerConnectLogo}
                item
                xs={12}
              >
                <img height={40}
                  src={appClient.LogoUrl}
                />
                <AddIcon className={classes.spacedPlus} />
                <img height={40}
                  src={Logo}
                />
              </Grid>
              <Grid item
                xs={12}
              >
                <Typography className={classes.formTitle}
                  variant={'h6'}
                >Connect <b>{appClient.Name}</b> to your Askneo account</Typography>
                <form onSubmit={handleSubmit(formSubmit)}>
                  <FormControl
                    className={classNames(classes.newInputForm)}
                    variant='outlined'
                  >
                    <InputLabel htmlFor='firstName'>{'Email'}</InputLabel>
                    <OutlinedInput
                      className={classNames(classes.newFormElt)}
                      inputRef={register({ required: true })}
                      label='Outlined'
                      name='email'
                      onChange={onFieldChange}
                      type='email'
                      variant='outlined'
                    />
                    <div className={classes.errorLabel}>
                      { errors.email && 'Email is required.' }
                    </div>
                  </FormControl>
                  <FormControl
                    className={classNames(classes.newInputForm)}
                    variant='outlined'
                  >
                    <InputLabel htmlFor='firstName'>{'Password'}</InputLabel>
                    <OutlinedInput
                      className={classNames(classes.newFormElt)}
                      inputRef={register({ required: true })}
                      label='Outlined'
                      name='password'
                      onChange={onFieldChange}
                      type='password'
                      variant='outlined'
                    />
                  <div className={classes.errorLabel}>
                    { errors.password && 'Password is required.' }
                  </div>
                  </FormControl>
                  <Button
                    className={classes.signin}
                    color="primary"
                    disabled={isLoading}
                    type="submit"
                    variant='contained'
                  >
                    {'Sign in'}
                  </Button>
                </form>
              </Grid>
            </Paper>
          : null
        }
      </Grid>
  );
};

export default withStyles(styles)(AuthorizeHome);
