// @flow

import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import styles from './Styles/FilterSearchBox.Style';
type Props = {
  classes: Object,
  handleSetSearchInputValue: Function,
  searchInputValue: String,
  placeholderValue: string
};

const FilterSearchBox = (props: Props) => {
  const { classes, handleSetSearchInputValue, searchInputValue, placeholderValue } = props;
  const [value, setValue] = useState(searchInputValue);
  
  const handleChangeValue = (e => {
    setValue(e.target.value);
    handleSetSearchInputValue(e.target.value);
  });

  return (
    <Paper className={classes.root}
      elevation={1}
    >
      <InputBase 
        className={classes.input}
        onChange={handleChangeValue}
        placeholder={ placeholderValue ? placeholderValue : 'Search...'}        
        value={value}
      />
      <IconButton 
        aria-label='Search'
        className={classes.iconButton}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default withStyles(styles)(FilterSearchBox);