// @flow

const styles = (theme: Object) => ({
  dialogPaperWidthSm: {
    maxWidth: 650,
    padding: '10px 16px 16px 16px',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 8px 8px 8px'
    }
  },
  marginTop: {
    marginTop: theme.spacing(1)
  }
});

export default styles;