// @flow

const MOBILE_ITEM_HEIGHT = 'auto';
const TILE_ACTION_BTN_WIDTH = 28;
const TABLE_MIN_WIDTH = 800;
const rowShadowAtRest = '0px 2px 8px -1px rgba(0,0,0,0.2)';
const ROW_SPACING = 0.5; // 
const ROW_CELL_BACKGROUND_COLOR = 'hsl(0,0%,97%)';

const styles = (theme: Object) => ({
  tableContent: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: '100%',
    minWidth: TABLE_MIN_WIDTH,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      minWidth: 'auto'
    }
  },
  table: {
    // backgroundColor: 'red',
    tableLayout: 'fixed',
    borderCollapse: 'separate',
    borderSpacing: 0,
    padding: 0,
    width: '100%',
    display: 'block',
    whiteSpace: 'nowrap'
  },
  feedTableBody: {
    display: 'flex',
    flexDirection: 'column',
    width: `calc(100vw - ${theme.layout.customProps.drawer.width}px - ${theme.spacing(6)}px)`,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      width: `calc(100vw - ${theme.layout.customProps.drawer.width}px - ${theme.spacing(6)}px)`,
      minWidth: '100%'
    }
  },
  feedTableHeadRow: {
    display: 'flex',
    position: 'relative',
    height: 30,
    marginBottom: theme.spacing(1),
    '& th': {
      fontSize: '0.9rem',
      fontWeight: 600,
      padding: theme.spacing(1),
      borderBottom: 0,
      backgroundColor: 'transparent'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      position: 'relative',
      height: 30,
      marginBottom: theme.spacing(1),
      '& th': {
        fontSize: '0.9rem',
        fontWeight: 600,
        padding: theme.spacing(1),
        borderBottom: 0,
        backgroundColor: 'transparent'
      }
    }
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  textOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('xs')]: {
      overflow: 'scroll'
    }
  },
  hoverOpacity: {
    opacity: 0.2,
    '&.hover': {
      opacity: 1
    }
  },
  requestCheckbox: {
    marginLeft: 0,
    height: 10
  },
  cell: {
    display: 'flex',
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    flex: '0 0 auto',
    lineHeight: theme.typography.body2.lineHeight,
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    border: 'none',
    borderTop: 0, // theme.props.MuiButtonBase.outlined.border,
    borderBottom: 0, // theme.props.MuiButtonBase.outlined.border,
    background: 'white',
    zIndex: 0,
    '&:first-child': {
      borderLeft: 0, // theme.props.MuiButtonBase.outlined.border,
      borderTopLeftRadius: theme.shape.borderRadius, 
      borderBottomLeftRadius: theme.shape.borderRadius
    },
    '&.lastChild': {
      borderRight: 0, // theme.props.MuiButtonBase.outlined.border,
      borderBottomRightRadius: theme.shape.borderRadius, 
      borderTopRightRadius: theme.shape.borderRadius
    }
  },
  clickableCellMargin: {
    padding: `0px ${theme.spacing(1)}px !important`,
    cursor: 'default'
  },
  feedTableRow: {
    display: 'flex',
    flexGrow: '1 1 auto',
    zIndex: 2,
    margin: `${theme.spacing(ROW_SPACING)}px 0`,
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    boxShadow: rowShadowAtRest,
    background: ROW_CELL_BACKGROUND_COLOR,
    '&.hover': {
      boxShadow: '0px 2px 8px -1px rgba(0,0,0,0.8)'
      // border: `${hoverRowBorderSize}px solid hsl(0,0%,60%)`
    },
    '&.hover td:first-child': {
      // borderLeft: hoverRowBorderSize, // theme.props.MuiButtonBase.outlined.border,
      borderTopLeftRadius: theme.shape.borderRadius, 
      borderBottomLeftRadius: theme.shape.borderRadius
      // borderColor: theme.palette.grey['300'],
      // borderStyle: 'solid',
      // borderTop: hoverRowBorderSize,
      // borderBottom: hoverRowBorderSize
    },
    '&.hover td': {
      // borderColor: theme.palette.grey['300'],
      // borderStyle: 'solid',
      borderLeft: 0,
      borderRight: 0
      // borderTop: hoverRowBorderSize,
      // borderBottom: hoverRowBorderSize
    },
    '&.hover td:last-child': {
      // borderRight: hoverRowBorderSize, // theme.props.MuiButtonBase.outlined.border,
      borderBottomRightRadius: theme.shape.borderRadius, 
      borderTopRightRadius: theme.shape.borderRadius
      // borderColor: theme.palette.grey['300'],
      // borderStyle: 'solid',
      // borderTop: hoverRowBorderSize,
      // borderBottom: hoverRowBorderSize,
    },
    '&.hover td.actionCell': {
      padding: 0,
      display: 'flex',
      marginLeft: 0,
      // backgroundColor: theme.palette.primary.lighter,
      backgroundColor: 'hsl(0,0%,99%)',
      '&:first-child': {
        borderLeft: 0, // theme.props.MuiButtonBase.outlined.border,
        borderTopLeftRadius: theme.shape.borderRadius, 
        borderBottomLeftRadius: theme.shape.borderRadius
      },
      '&.lastChild': {
        borderRight: 0, // theme.props.MuiButtonBase.outlined.border,
        borderBottomRightRadius: theme.shape.borderRadius, 
        borderTopRightRadius: theme.shape.borderRadius
      },
      '& *': {
        color: 'white'
      }
    },
    '&.hover td.underActionCell.lastChild': {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0
    },
    '& td.actionCell': {
      display: 'none'
    },
    '&.hover td.hideOnRowHover': {
      display: 'none'
    },
    '&.hover .hoverOpacity': {
      opacity: 1
    },
    [theme.breakpoints.down('sm')]: {
      boxShadow: rowShadowAtRest,
      borderRadius: theme.shape.borderRadius,
      '&.hover td:last-child': {
        borderBottomRightRadius: theme.shape.borderRadius / 2,
        borderTopRightRadius: theme.shape.borderRadius / 2
      }
    }
  },
  rowExpanded: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    zIndex: 0,
    '&.hover': {
      boxShadow: rowShadowAtRest
    },
    '& td, & td:first-child, & td:last-child, &.hover td, &.hover td:first-child, &.hover td:last-child': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
      // backgroundColor: 'hsl(0, 0%, 99%)'
    },
    '& .lastChild': {
      borderBottomRightRadius: 0
    }
  },
  rowCollapsed: {

  },
  expandedTableRow: {
    width: 'auto',
    display: 'flex',
    zIndex: 1,
    transition: 'transform 0.3s ease-out',
    transition: 'height 0.3s ease-out',
    transform:'scaleY(1)',
    transformOrigin:'top',
    marginTop: -theme.spacing(ROW_SPACING),
    background: 'white',
    marginLeft: 0,
    marginRight: 0,
    boxShadow: '0px 2px 8px -1px rgba(0,0,0,0.2)',
    marginBottom: 0,
    borderBottom: 0,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    '& td': {
      borderBottom: 0
    },
    '&.expanded': {
      height: 'auto',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2)
    },
    '&.collapsed': {
      transform:'scaleY(0)',
      display: 'none'
    }
  },
  rowActionBtn: {
    borderRadius: 0,
    height: theme.layout.listTable.rowHeight,
    width: theme.layout.listTable.rowHeight,
    minWidth: theme.layout.listTable.rowHeight,
    backgroundColor: '#bbdefb'
  },
  simpleTableHeadRow: {
    height: 36
  },
  nakedTable: { // https://material-ui.com/components/tables/#simple-table

  },
  nakedTableHeadRow: {
    '& th': {
      fontWeight: 700
    }
  },
  nakedTableBodyRow: {
    '&:last-child td, &:last-child th': {
      borderBottom: 0
    }
  },
  // MOBILE STYLES
  mobileFeedItem: {
    borderRadius: theme.shape.borderRadius / 2,
    // position: 'sticky',
    // top: theme.layout.customProps.header.height*2,
    boxShadow: rowShadowAtRest,
    height: MOBILE_ITEM_HEIGHT,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    '&.hover': {
      boxShadow: '0px 2px 8px -1px rgba(0,0,0,0.8)'
    }
  },
  mobileTableHeader: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    fontSize: '0.8rem'
  },
  mobileTileCtr: {
    flexWrap: 'nowrap',
    minHeight: 45,
    marginBottom: 0,
    '& p': {
      fontSize: '0.9rem'
    }
  },
  mobileRowItem: {
    flexWrap: 'nowrap',
    marginTop: 0,
    marginBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  tileLeftColCtr: {

  },
  tileRightColCtr: {

  },
  tileTitle: {

  },
  tileSubTitle: {
    color: 'hsl(0,0%,50%)'
  },
  tileSmallText: {
    fontSize: '0.8rem'
  },
  tileNewLine: {
    marginTop: theme.spacing(0.5)
  },
  tileFooter: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5)
  },
  tileActionBtn: {
    margin: theme.spacing(0.25),
    borderRadius: TILE_ACTION_BTN_WIDTH,
    height: TILE_ACTION_BTN_WIDTH,
    width: TILE_ACTION_BTN_WIDTH,
    minWidth: TILE_ACTION_BTN_WIDTH,
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '& i, & *': {
    fontSize: '1rem'
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  }
});

export default styles;