// @flow

import React, { useState } from 'react';
import classNames from 'classnames';
// import ModalImage from '../../custom_libs/react-modal-image';
import { log } from '../../utils/jsUtils';

import Modal from '@material-ui/core/Modal';

import CancelIcon from '@material-ui/icons/Cancel';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/RemovableModalImage.Style';

type ModalImageProps = {
  classes :Object,
  alt: string,
  invert: boolean,
  large: string, // url
  small: string // url
}
const ModalImage = withStyles(styles)((props: ModalImageProps) => {
  const {
    classes,
    alt,
    large,
    small,
    invert,
    open,
    handleClickOnRemove,
    handleOpen,
    handleClose
  } = props;



  return (
    <div className={classes.modalImageCtr}>

      {
        handleClickOnRemove
        ? <CancelIcon
          className={classes.closeIcon}
          onClick={handleClickOnRemove}
          />
        : null
      }
      {
        invert
        ? <img 
          className={classNames(
            classes.attachedImg
          )}
          onClick={handleOpen}
          src={small}
          style={{transform: invert ? 'rotate(180deg)' : 'none'}}
          />
        : <img 
          className={classNames(
            classes.attachedImg
          )}
          onClick={handleOpen}
          src={small}
          />
      }
      
      <Modal
        onClose={handleClose}
        open={open}
      >
        <div className={classes.modalPaper}>
          <CancelIcon
            className={classes.closeModalIcon}
            onClick={handleClose}
          />
          <img 
            className={classes.zoomImg}
            onClick={handleOpen}
            src={large}
          />
        </div>
      </Modal>
    </div>
  );
});

type Props = {
  handleClickOnRemove: Function,
  containerKey: string,
  largeImageUrl: string,
  smallImageUrl: string,
  invert: boolean,
  classes: Object
}
const RemovableModalImage = (props: Props) => {
  const {
    classes,
    handleClickOnRemove,
    largeImageUrl,
    smallImageUrl,
    previewStyles,
    invert
  } = props;

  log('[previewStyles]: ', previewStyles);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.RemovableModalImageCtr}>
      <ModalImage
        alt={'Attachment'}
        className={classes.attachedImg}
        handleClickOnRemove={handleClickOnRemove}
        handleClose={handleClose}
        handleOpen={handleOpen}
        invert={invert}
        large={largeImageUrl}
        open={open}
        small={smallImageUrl}
      />
    </div>
  );
};

export default withStyles(styles)(RemovableModalImage);
