// @flow

import { parsePhoneNumberFromString } from 'libphonenumber-js';
import * as R from 'ramda';

export const log = (...args: any[]) => {
  if (process.env.REACT_APP_ENV !== 'production') {
    console.log(...args.map(a => a || ''));
  }
};

export const formatPhoneNumberForFrontend = (nb: string): ?string => {
  if (typeof nb !== 'undefined') {
    // log('[formatPhoneNumberForFrontend] incoming number', typeof nb);
    if (nb.charAt(0) !== '+') {
      nb = '+' + nb;
    }
    // log('[formatPhoneNumberForFrontend] formatPhoneNumberForFrontend', nb);
    // const parsed = parsePhoneNumberFromString(`${nb}`);
    const parsed = parsePhoneNumberFromString(nb);
    // log('[formatPhoneNumberForFrontend] formatPhoneNumberForFrontend', parsed);
    if (typeof parsed === 'undefined') {
      return 'Invalid number.';
    }
    // log('[formatPhoneNumberForFrontend] formatPhoneNumberForFrontend', parsed.formatInternational());
    return parsed.formatInternational();
  }
};

/** 
 * cleanup phone nb to fit backend's format: 11231231234 
 * */
export const formatPhoneNumberForBackend = (nb: ?string = '') => {
  const phoneNumber = parsePhoneNumberFromString(nb);
  if (!phoneNumber) {
    return null;
  }
  return `${phoneNumber.countryCallingCode}${phoneNumber.nationalNumber}`;
};

export const truncateString = (s: string = '', maxLength: number = 50) => {
  if (!s) return null;
  const showDots = s.length > maxLength;
  return `${s.substring(0, maxLength)}${showDots ? '...' : ''}`;
};

export const REGEX_PATTERNS = {
  EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
};

/**
 * validate email format
 * @returns boolean
 */
export const validateEmail = (email: ?string = '') => {
  // eslint-disable-next-line max-len
  const re = REGEX_PATTERNS.EMAIL;
  // console.log('[validateEmail] re: ', re);
  // console.log('[validateEmail] re.test(String(email): ', re.test(String(email)));
  return re.test(String(email).toLowerCase());
};

/**
 * https://stackoverflow.com/a/59787784/2779653
 * @returns true if object is empty. Crashes if object null or undefined
 */
export const isObjectEmpty = (obj: Object) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false;
    }
  }
  return true;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};