/* eslint-disable react/no-multi-comp */
// @flow

import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import {Elements, CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import classNames from 'classnames';
import { useStore } from '../../store';
import { log } from '../../utils/jsUtils';

import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';

import StripeElementWrapper from '../Billing/StripeElementWrapper';
import SPBackend from '../../services/SPBackend';

import { withStyles } from '@material-ui/core/styles';
import billingStyles from '../Billing/Styles/BillingContent.Style';
import styles from './Styles/CreditCardForm.Style';
import signupStyles from '../PublicCpts/Styles/Signup.Style';
import combineStyles from '../../utils/stylesUtils';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

type Props = {
  classes: Object,
  getPaymentMethods: Function,
  onPaymentMethodSelected: Function,
  onCancelClicked?: Function
}
const ccForm = (props: Props) => {
  const { classes,
    getPaymentMethods,
    onPaymentMethodSelected,
    onCancelClicked } = props;
  const [store, dispatch] = useStore();
  const {
    user
  } = store;

  const [paymentLoading, setPaymentLoading] = useState(false);
  const [cardholderName, setCardholderName] = useState('');
  const [chargeError, setChargeError] = useState('');
  

  const stripe = useStripe();
  const elements = useElements();
  
  const handleSubmit = async(ev) => {
    // create new payment method & set it as default.
    try {
      ev.preventDefault();
      setPaymentLoading(true);
      log('submit payment!', ev);


      const cardElement = elements.getElement('card');
      // cardElement.mount('#card-elt-id');
      log('[handleSubmit] elements(cardNumber): ', cardElement);
      let pmResult = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: cardholderName
        }
      });
      log('[handleSubmit] after createPaymentMethod pmResult: ', pmResult);
      const { paymentMethod, error: pmError } = pmResult;
      if (pmError) {
        log('Stripe paymentMethod error: ', pmError);
        if (pmError.code === 'parameter_invalid_empty') {
          throw Error('All fields are required.');
        }
        throw Error(pmError.message);
      }
      SPBackend.updatePaymentMethod(paymentMethod, user)
        .then((response) => {
          onPaymentMethodSelected(paymentMethod);
          setChargeError('');
          log('charge response: ', response);
          setPaymentLoading(false);
          dispatch({
            type: 'ACTION_SET_SNACK_MSG', 
            snackMessage: {
              message: 'Payment details updated!',
              level: 'success',
              duration: 3000
            }
          });
          getPaymentMethods();
        })
        .catch(error => {
          log('[handleSubmit] charge error.response: ', error.response);
          setPaymentLoading(false);
          const { data } = error.response;
          setChargeError(data);
        });

      // TODO: once done, detach the current payment method:
      // https://stripe.com/docs/api/payment_methods/detach


    } catch (e) {
      log('[handleSubmit] e.name: ', e.name);
      if (e.name === 'IntegrationError') {
        setChargeError('Missing card information');
      } else {
        log('[handleSubmit] error caught: ', e.name, e);
        setChargeError(e.message);
      }
      setPaymentLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
    <Grid container>
      <Grid item 
            xs={12}
      >
        <Typography className={classes.formLabel} 
                    variant='body1'
        >{'Cardholder full name'}</Typography>
        <FormControl
          className={classNames(classes.newInputForm)}
          variant="outlined"
        >
          <OutlinedInput
            className={classNames(classes.newFormElt, classes.checkoutInputForm)}
            id='cardholderName'
            margin='dense'
            name='cardholderName'
            onChange={event => setCardholderName(event.target.value)}
            placeholder='Cardholder full name'
            type='text'
            value={cardholderName}
          />
        </FormControl>
      </Grid>
      <Grid item 
            xs={12}
      >
        <Typography className={classes.formLabel} 
                    variant='body1'
        >{'Card information'}</Typography>
        <StripeElementWrapper component={CardElement} 
                              id='card-elt-id' 
                              label=''
        />
      </Grid>
      {
        chargeError !== ''
        ? <Grid
            className={classes.errorLabel}
            item
            xs={12}
          >{chargeError}</Grid>
        : null
      }
      {/* <Grid item xs={7}>
        <StripeElementWrapper label="Expiry (MM / YY)" component={CardExpiryElement} />
      </Grid>
      <Grid item xs={5}>
        <StripeElementWrapper label="CVC" component={CardCVCElement} />
      </Grid> */}
      <div className={classes.actionsCtr}>
      {
        !R.isNil(onCancelClicked)
        ? <Button
            color='primary'
            onClick={(event) => {
              event.preventDefault();
              onCancelClicked();
            }}
            type='button'
          >
            {'Cancel'}
          </Button>
        : null
      }
      <Button
        color='primary'
        disabled={paymentLoading}
        style={{marginTop: 16}}
        type='submit'
        variant='contained'
      >{!paymentLoading ? 'Save' : 'Loading'}</Button>
      </div>
    </Grid>
    </form>
  );
};

const combinedStyles = combineStyles(
  signupStyles,
  styles,
  billingStyles
);
const CreditCardInputForm = withStyles(combinedStyles)(ccForm);

const CreditCardForm = (props) => {
  const {
    onPaymentMethodSelected,
    onCancelClicked
  } = props;
  
  const [store, dispatch] = useStore();
  const {
    user
  } = store;

  const getPaymentMethods = () => {
    SPBackend.getPaymentMethods(user)
      .then(response => {
        log('[CreditCardForm.getPaymentMethods] response: ', response);
        const {
          paymentMethods,
          defaultPaymentMethod
        } = response.data;
      })
      .catch(e => {
        log('[CreditCardForm.getPaymentMethods] error: ', e);
      });
  };

  return (
      <Elements stripe={stripePromise}>
        <CreditCardInputForm
          getPaymentMethods={getPaymentMethods}
          onCancelClicked={onCancelClicked}
          onPaymentMethodSelected={onPaymentMethodSelected}
        />
      </Elements>
  );
};

export default withStyles(styles)(CreditCardForm);
