import React from 'react';
import { useStore } from '../../store';

import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';

import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import Tooltip from '@material-ui/core/Tooltip';

import { withStyles } from '@material-ui/core/styles';
import commonListTableStyles from '../Styles/CommonListTable.Style';
import styles from '../Feed/Styles/MemberContent.Style';
import combineStyles from '../../utils/stylesUtils';

type Props = {
  classes: Object,
  firstPage: Array<Object>,
  nextPage: Array<Object>,
  pageInfo: Object,
  totalCount: number
};

const Paginator = (props: Props) => {
  const {
    classes,
    firstPage,
    nextPage,
    pageInfo,

    totalCount
  } = props;

  const [store, dispatch] = useStore();

  const changePage = (convoPageNum: number) => {
    dispatch({
      type: 'ACTION_SET_PAGE_NB',
      pageNum: convoPageNum
    });
  };

  return (
  <Grid 
    className={classes.paginateFooter}
    container
  >
    <Grid 
    className={classes.pagePrev}
    item 
    xs={1}
    >
      {
        firstPage && pageInfo.pageNum > 1
        ? <div>
            <Tooltip 
                aria-label={'Fist page'}
                title={'Jump to first page'} 
              >
              <Fab
                aria-label="lastPage"
                className={classes.fab}
                onClick={() => changePage(1)}
                size="small"
              >
                <SkipPreviousIcon />
              </Fab>
              </Tooltip>
              <Tooltip 
            aria-label={'Previous page'}
            title={'Previous Page'}
              >
            <Fab
              aria-label="Previous"
              className={classes.fab}
              onClick={() => changePage(pageInfo.pageNum - 1)}
              size="small"
            >
              <ArrowLeftIcon style={{ fontSize: '28px'}}/>
            </Fab>
          </Tooltip>
          </div>
        : null
      }
    </Grid>
    <Grid
      className={classes.pageNumber}
      item 
      xs={10} 
    >
      <Button
        className={classes.button}
        disabled
      >{`Page ${pageInfo.pageNum}/${Math.ceil(totalCount/pageInfo.pageSize)}`}</Button>
    </Grid>

    <Grid 
    className={classes.pageNext}
    item 
    xs={1} 
    >
      
    {
      nextPage && nextPage.length > 0
      ? <div>
          <Tooltip 
            aria-label={'Next page'}
            title={'Next Page'} 
          >
          <Fab
            aria-label="Previous"
            className={classes.fab}
            onClick={() => changePage(pageInfo.pageNum + 1)}
            size="small"
          >
            <ArrowRightIcon style={{ fontSize: '28px'}}/>
          </Fab>
          </Tooltip>
          <Tooltip 
            aria-label={'Jump to last page'}
            title={'Jump to last page '} 
          >
          <Fab
            aria-label="lastPage"
            className={classes.fab}
            onClick={() => changePage(Math.ceil(totalCount/pageInfo.pageSize))}
            size="small"
          >
            <SkipNextIcon/>
          </Fab>
          </Tooltip>
        </div>
      : null
    }
        
    </Grid>
  </Grid>
  );
};

const combinedStyles = combineStyles(
  commonListTableStyles,
  styles
);

export default withStyles(combinedStyles)(Paginator);

