// @flow

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { log } from '../../utils/jsUtils';

import IntegrationsHeader from './IntegrationsHeader';
import IntegrationsContent from './IntegrationsContent';
import MemberDrawer from '../MemberDrawer';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/IntegrationsHome.Style';

type Props = {
  classes: Object
};

const IntegrationsHome = (props: Props) => {
  const {
    classes
  } = props;

  const [mobileOpen, setMobileOpen] = useState(false);

  const usehandleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Neo | Integrations</title>
      </Helmet>
      <IntegrationsHeader
        mobileOpen={mobileOpen}
        usehandleDrawerToggle={usehandleDrawerToggle}
      />
      
      <MemberDrawer 
        mobileOpen={mobileOpen} 
        usehandleDrawerToggle={usehandleDrawerToggle}
      />

      <IntegrationsContent />
    </div>
  );
};

export default withStyles(styles)(IntegrationsHome);

