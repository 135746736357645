// @flow
const COL_ADDRESS_WIDTH = 200;
const COL_NB_WIDTH = 100;
const TABLE_MIN_WIDTH = 500;
const COL_ACTION_WIDTH = 100;

const styles = (theme: Object) => ({

  root: {
    justifyContent: 'center',
    padding: theme.spacing(3),
    flexGrow: 1,
    maxWidth: 1000,
    minWidth: 900,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100vw',
      padding: theme.spacing(1),
      minWidth: 'auto'
    }
  },

  finishedRequestsStyles: {
    backgroundColor: '#9fc556',
    bordeTopLeftRadius: 100,
    width: 50,
    height: 10
  },

  inProgressRequestsStyles: {
    backgroundColor: '#efc132',
    width: 50,
    height: 10  
  },

  notStartedRequestsStyles: {
    backgroundColor: '#cd4d52',
    height: 10
  },

  toolbar: theme.layout.customProps.toolbar,

  feedTableBody: {
    display: 'block'
  },  

  tableContent: {
    flexGrow: 1,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(1),
    width: '100%',
    minWidth: TABLE_MIN_WIDTH,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingLeft: 0,
      minWidth: '90vw',
      width: '90vw'
    }
  },  

  table: {
    tableLayout: 'fixed',
    borderCollapse: 'separate',
    borderSpacing: 0,
    padding: 0,
    width: '100%',
    display: 'block',
    whiteSpace: 'nowrap'
  },

  customTableStyles: {
    '& .underActionCell': {
      flex: '0 0 auto',
      minWidth: COL_ACTION_WIDTH,
      maxWidth: COL_ACTION_WIDTH,
      paddingRight: 0,
      paddingLeft: 0,
      width: 'auto'
    },
    '& .hover .underActionCell.twoBtns': {
      flex: '1 1 auto',
      minWidth: 0,
      maxWidth: COL_ACTION_WIDTH - (theme.shape.borderRadius * 2 * 2),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 'auto'
    }
  },

  nbCell: {
    flex: '0 0 auto',
    minWidth: COL_NB_WIDTH,
    maxWidth: COL_NB_WIDTH,
    [theme.breakpoints.down('sm')]: {
      minWidth: COL_NB_WIDTH / 2,
      maxWidth: COL_NB_WIDTH / 2
    }
  },

  cell: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    border: 'none',
    borderTop: 0, // theme.props.MuiButtonBase.outlined.border,
    borderBottom: 0, // theme.props.MuiButtonBase.outlined.border,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5)
    }
  },

  addressCell: {
    flex: '1 1 auto',
    flexBasis: COL_ADDRESS_WIDTH,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1)
    }
  },

  toolTip: {
    fontSize: '2em',
    color: 'red'
  },

  legend: {
    color: theme.palette.custom.backgroundTextInfo,
    fontSize: '0.8rem'
  },
  kpiSpacing: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0
    }
  },
  kpiItem: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(0.5)}px 0px`,
      padding: 0
    }
  },
  inquiries: {
    color: theme.palette.custom.backgroundTextInfo
  },
  
  pending: {
    color: '#f73138'
  },
  current: {
    color: 'orange'
  },
  finished: {
    color: '#94d813'
  },
  Cards: {
    width: 'auto',
    height: 'auto',
    fontSize: 40,
    fontWeight: 'bold',
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    '&:hover': {
      boxShadow: '0px 2px 8px -1px rgba(0,0,0,0.8)',
      cursor: 'pointer'
    }
  },
  kpiCount: {
    fontSize: 40,
    fontWeight: 'bold',
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center'
  },
  kpiCardAction: {
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center'

  },
  kpiCardButtons: {
    fontFamily: 'Sans-serif',
    fontWeight: 'bold',
    color: theme.palette.custom.backgroundTextInfo
  },
  cardLabels: {
    color: theme.palette.custom.backgroundTextInfo,
    fontSize: 18

  },
  graphCard: {
    minHeight: 300,
    padding: theme.spacing(3)
  },
  Graph: {
    stroke: '#5894f0',
    strokeWidth: 1,
    fill: '#d3e6f8'

  },

  dateButtons: {
    height: theme.layout.customProps.toolbar.height,
    minWidth: theme.layout.customProps.toolbar.height,
    textTransform: 'none',
    padding: `10px ${theme.spacing(1)}px 0 ${theme.spacing(1)}px`,
    borderRadius: 0,
    '& *': {
      whiteSpace: 'nowrap'
    }
  },
  
  unitsCount: {
    fontSize: '0.8rem',
     color: theme.palette.custom.backgroundTextInfo
  },

  totalRequestsColumn: {
    fontSize: '0.8rem',
    color: theme.palette.custom.backgroundTextInfo
  },

  progressBars: {
    flex: 1,
    flexDirection: 'row',
    width: 200,
    height: 10,
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignContent: 'center',
    display: 'flex',
    backgroundColor: '#d0d2d3',
    borderRadius: 25,
    [theme.breakpoints.down('sm')]: {
      width: 100
    }
  }
});

export default styles;
