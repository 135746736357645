// @flow

import { phoneInputCss } from '../../../css/phoneInput';

const styles = (theme: Object) => ({
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  dialogFormCtr: {
    height: 'auto',
    // maxHeight: 300,
    display: 'flex',
    flexDirection: 'column',
    '&.xlarge': {
      maxHeight: 600
    },
    '&.large': {
      maxHeight: 550
    }
  },
  filterContainer: {
    marginTop: theme.spacing(1),
    border: `1px solid ${
      theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)'
    }`,
    display: 'flex',
    // borderRadius: theme.shape.borderRadius,
    '& > div': {
      width: '100%'
    },
    zIndex: 9999,
    '& *': {
      zIndex: 9999
    }
  },
  relative: {
    position: 'relative'
  },
  grow: {
    flexGrow: 1
  },
  secondInput: {
    marginTop: theme.spacing(2),
    '& *': {
      zIndex: 1
    }
  },
  errorTooltip: {
    height: 64,
    marginTop: theme.spacing(1)
  },
  largeTextField: {
    '& *': {
      borderRadius: 0
    },
    '& label': {
      transform: 'translate(20px, 24px) scale(1)'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: 0
    },
    '& .MuiOutlinedInput-notchedOutline legend': {
      display: 'none'
    },
    '& textarea': {
      padding: theme.spacing(1)
    },
    '& label.MuiInputLabel-shrink': {
      display: 'none',
      transform: 'translate(12px, -6px) scale(0.75)',
      fontSize: '1rem'
    }
  },
  messageTenantDialogTitle: {
    fontSize: '1rem',
    padding: '24px 24px 8px 24px'
  },
  errorTooltip: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between'
  },
  textFieldCaptionRight: {
    textAlign: 'right',
    color: theme.palette.primary.light,
    fontSize: '0.8rem',
    marginRight: theme.spacing(2)
  },
  textFieldCaptionRight2: {
    textAlign: 'right',
    color: theme.palette.primary.light,
    fontSize: '0.8rem',
    position: 'absolute',
    bottom: 0,
    right: 42
  },
  textFieldCaptionLeft: {
    textAlign: 'left',
    color: theme.palette.primary.light,
    fontSize: '0.8rem',
    position: 'relative',
    left: 20,
    // height: 64,
    width: '100%'
  },
  warning: {
    color: theme.palette.error.main,
    fontSize: '0.9rem'
  },
  clickable: {
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  error: {
    color: theme.palette.status.error.main
  },
  phoneInputCtr: phoneInputCss,
  recipientsCountLabel: {
    color: theme.palette.custom.backgroundTextInfo,
    fontSize: '0.9rem'
  },
  actionsCtr: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px 24px 0px 24px',
    paddingTop: 0
  },
  actionAttachImgBtn: {
    // backgroundColor: theme.palette.primary.lighter
  },
  attachedImg: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    border: `2px solid ${theme.palette.primary.lighter}`,
    cursor: 'pointer',
    objectFit: 'cover',
    display: 'block',
    width: 100,
    minWidth: 100,
    height: 100

  },
  errorMessagesCtr: {
    flexDirection: 'column'
  }
});

export default styles;
