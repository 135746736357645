// @flow

import axios from 'axios';
import { getNewIdToken } from './firebase';
import { log } from '../utils/jsUtils';

const serverURL = process.env.REACT_APP_BACKEND_SERVER_URL
  ? process.env.REACT_APP_BACKEND_SERVER_URL
  : '';
const serverBuildingsURL = process.env.REACT_APP_BACKEND_BUILDINGS_SERVER_URL
  ? process.env.REACT_APP_BACKEND_BUILDINGS_SERVER_URL
  : '';
  const serverTenantsURL = process.env.REACT_APP_BACKEND_TENANTS_SERVER_URL
  ? process.env.REACT_APP_BACKEND_TENANTS_SERVER_URL
  : '';
  const serverUnitsURL = process.env.REACT_APP_BACKEND_UNITS_SERVER_URL
  ? process.env.REACT_APP_BACKEND_UNITS_SERVER_URL
  : '';
  const serverAccountsURL = process.env.REACT_APP_BACKEND_ACCOUNTS_SERVER_URL
  ? process.env.REACT_APP_BACKEND_ACCOUNTS_SERVER_URL
  : '';
  const serverTasksURL = process.env.REACT_APP_BACKEND_TASKS_SERVER_URL
  ? process.env.REACT_APP_BACKEND_TASKS_SERVER_URL
  : '';
  const serverTagsURL = process.env.REACT_APP_BACKEND_TAGS_SERVER_URL
  ? process.env.REACT_APP_BACKEND_TAGS_SERVER_URL
  : '';

export const NOTIFICATION_CHANNELS = {
  SMS: 'sms',
  EMAIL: 'email',
  NONE: 'none'
};

export const BOT_SETTINGS_EVENTS = {
  'set_as_customer_name': 'Neo saves this reply as the customer name',
  'set_as_ticket_name': '',
  'create_ticket': 'Saves conversation'
};

export type AccountSettings = {
  Tenants: {
    ReceiveNotificationsBy: string
  },
  Users: {
    ReceiveNotificationsBy: string
  },
  SetupRequired?: {
    Properties: boolean,
    Units?: boolean,
    Tenants: boolean
  },
  SetupMeeting: {
    ScheduledDate?: string,
    IsRequired: boolean
  },
  Twilio: {
    Status: 'active'|'suspended',
    SubaccountSID: string
  },
  EnableCustomersSelfUpload?: boolean
}

type TopupUsage = {
  Id: string,
  Limit: number,
  CreditsUsed: number,
  StripeInvoiceId: string,
  CreatedDt: string,
  AmountPaid: number
}
export type AccountUsage = {
  CurrentPeriod?: {
    Limit: number,
    StartDt: ?string,
    CreditsUsed: number
  },
  Topups?: Array<TopupUsage>,
  TotalCreditsBalance: number
}

type AccountBilling = {
  planId: string,
  subscriptionStatus: String
}

type AxiosResponse = {
  data: Object
}

type Agreement = {
  NeedsAgreementSignature: boolean,
  Language: string
};

export type Account = {
  _id: string,
  FirstName: string,
  LastName: string,
  Email: string,
  Verified: bool,
  CountryCode: string,
  Phone: string,
  AcctType: string,
  Keys: Array<string>,
  ProfilePic?: string,
  Token?: string,
  Agreement: Agreement,
  Settings: AccountSettings,
  Usage: AccountUsage,
  Billing: AccountBilling,
  IsOwnerPhoneVerified?: boolean
}

export type Keyword = {
  _id: string,
  AccountId: string,
  Keyword: string,
	Response: string,
  ResponseAttachments: Array<Attachment>,
	CreatedDt: string,
	UpdatedDt?: string,
	IsActive: string,
	LastRunDt?: string
}

export type Tag = {
  _id?: string,
  Name: string,
  Color: string
}

export type Task = {
  From: string,
  To: string,
  Coords: string,
  RequestIds: Array<Object>,
  Date: string,
  UpdatedDt: string,
  FirstName: string,
  _id: string,
  Issue: string,
  LastName: string,
  RoomKey: ?string, // broadcasts don't have RoomKey
  Status: string,
  Summary: string,
  Attachments?: Array<Attachment>,
  User: string,
  TenantId: string,
  Tags: Array<Tag>,
  UnreadCount?: number,
  LastStaffReadDt?: string,
  lookupTenant?: Tenant
}

export type Attachment = {
  Url: string,
  MimeType: string
}

export type Building = {
  _id: string,
  Property: string, // TODO: change name to PropertyName
  PropertyKey: string,
  CountryCode: number,
  Phone: ?string,
  Personal: Object,
  Limits: Object,
  NotifyAllOnRequestSolved: bool,
  Details: Object
}

export type Staff = {
  Email: String,
  FirstName: String,
  LastName: String
}

export type AccountResponse = {
  data: {
    account: Account,
    user: User,
    billing: AccountBilling,
    tags: Array<Tag>,
    defaultUnit: Unit,
    defaultBuilding: Building
  }
};

export type RequestResponse = {
  data: {
    Requests: Array<Task>,
    Units: Array<Unit>
  }
};

export type RequestsFilterOptions = {
  propertyOptions: Array<?{value: String, label: String}>,
  staffOptions: Array<?{value: String, label: String}>,
  tenantOptions: Array<?{value: String, label: String}>,
  tagOptions: Array<?{value: String, label: String}>,
  unreadMessagesOnlyOptions: Array<?{value: String, label: String}>,
}
export type RequestsFilterSelections = {
  propertyOptions: Array<?{value: String, label: String}>,
  staffOptions: Array<?{value: String, label: String}>,
  tenantOptions: Array<?{value: String, label: String}>,
  tagOptions: Array<?{value: String, label: String}>,
  unreadMessagesOnlyOptions: Array<?{value: String, label: String}>,
  startDatetime: string, // ISO timestamp
  endDatetime: string, // ISO timestamp
  pageNb: number,
  itemsPerPage: number
}

const authenticatedAxios = (user, baseURL = serverURL) => {
  return getNewIdToken(user)
    .then((freshIdToken: ?string) => {
      // Create instance
      const defaultOptions = {
        baseURL: baseURL,
        headers: {
          'Content-Type': 'application/json'
        }
      };
      let instance = axios.create(defaultOptions);
      // Set the AUTH token for any request
      // log('[SPBackend.authenticatedAxios] freshIdToken: ', freshIdToken);
      instance.defaults.headers.common['Authorization'] = `Bearer ${freshIdToken}`;
      return instance;
    });
};

const publicAxios = (url = '') => {
  const baseUrl = url !== '' ? url : serverURL;
  // Create instance
  const defaultOptions = {
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return axios.create(defaultOptions);
};

const fetchPhoneNumbers = (user: Object, searchPayload: Object): Promise<Object> => {
  log('phoneNumbers being fetched', searchPayload);
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.post(`${serverAccountsURL}/fetchPhoneNumbers`, searchPayload);
    });
};

const buyPhoneNumber = (user: Object, buyNumberPayload: Object): Promise<Object> => {
  log('number being bought payload', buyNumberPayload);
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.post(`${serverAccountsURL}/buyPhoneNumber`, buyNumberPayload);
    });
};

const sendCodeToVerifyNb = (user: Object, phoneToVerify: string): Promise<Object> => {
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.post(`${serverAccountsURL}/sendCodeToVerifyNb`, {phoneToVerify});
    });
};

const verifyPhoneNbCode = (user: Object, phoneToVerify: string, code: string): Promise<Object> => {
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.post(`${serverAccountsURL}/verifyPhoneNbCode`, {
        phoneToVerify,
        code
      });
    });
};

export type Plan = {
  UsageLimit: number,
  Price: number
}

const getMembersTopupPlans = (user: Object): Promise<{data: {plans: Array<Plan>}}> => {
  return authenticatedAxios(user, serverAccountsURL)
    .then(authAxios => {
      return authAxios.get('/getMembersTopupPlans');
    });
};

const purchaseTopupPlan = (user: Object, planId: string, paymentMethod?: Object): Promise<Object> => {
  return authenticatedAxios(user, serverAccountsURL)
    .then(authAxios => {
      return authAxios.post('/purchaseTopupPlan', {
        planId,
        paymentMethod
      });
    });
};

const getReferralLink = (user: Object): Promise<Object> => {
  return authenticatedAxios(user, serverAccountsURL)
    .then(authAxios => {
      return authAxios.get('/referralLink');
    });
};

const logReferralLinkVisit = (referralCode: string): Promise<Object> => {
  const _publicAxios = publicAxios(serverAccountsURL);
  return _publicAxios.post(`/referralLink/visit/${referralCode}`);
};

const getAppClient = (clientId: string, clientSecret: string): Promise<Object> => {
  log('[getAppClient].... ', serverAccountsURL);
  const _publicAxios = publicAxios(serverAccountsURL);
  return _publicAxios.get(`/appClient/${clientId}/${clientSecret}`);
};

const getCustomIdToken = (uid: string, user: Object): Promise<Object> => {
  log('[getCustomIdToken].... ', serverURL);
  return authenticatedAxios(user, serverAccountsURL)
    .then(authAxios => {
      return authAxios.post('/auth/custom-token', {
        uid
      });
    });
};

const getAccount = (user: Object): Promise<AccountResponse> => {
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.get('/account');
    });
};

const switchAccount = (user: Object, accountId: string): Promise<{data: { message: string }}> => {
  return authenticatedAxios(user, serverAccountsURL)
    .then(authAxios => {
      return authAxios.post('/account/switch', { accountId });
    });
};

const setPushSubscriberId = (user: Object, pushPlayerId: string): Promise<AccountResponse> => {
  return authenticatedAxios(user, serverAccountsURL)
    .then(authAxios => {
      return authAxios.post('/user/push/subscribe', {
        pushPlayerId
      });
    });
};

const setAccountOnboardingSettings = (isSetupMeetingRequired: boolean, user: Object):Promise<Object> => {
  return authenticatedAxios(user, serverAccountsURL)
    .then(authAxios => {
      return authAxios.post('/settings/onboarding', {
        SetupMeeting: isSetupMeetingRequired
      });
    });
};

type RegistrationForm = {
  firstName: string,
  lastName: string,
  companyName: string,
  email: string,
  password: string
}

const registerNewAccount = (registration: RegistrationForm): Promise<AccountResponse> => {
  const _publicAxios = publicAxios(serverAccountsURL);
  return _publicAxios.post('/register', registration);
};

// planId can be subscriptionId also.
const getPlanInfo = (planId: ?string, user: Object):Promise<Object> => {
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.get(`${serverAccountsURL}/plan/${planId}`);
    });
};

const getCouponInfo = (couponId: string, user: Object):Promise<Object> => {
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.get(`${serverAccountsURL}/coupon/${couponId}`);
    });
};

const getAccountInvoices = (user: Object):Promise<Object> => {
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.get(`${serverAccountsURL}/invoices`);
    });
};

const getPaymentMethods = (user: Object):Promise<Object> => {
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.get(`${serverAccountsURL}/payment-methods`);
    });
};

const updatePaymentMethod = (paymentMethod: Object, user: Object):Promise<Object> => {
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.post(`${serverAccountsURL}/payment-method`, { paymentMethod });
    });
};

type PaymentPayload = {
  paymentMethod: Object,
  planId: string,
  coupon?: string
}
const chargePlan = (paymentPayload: PaymentPayload, user: Object):Promise<Object> => {
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.post(`${serverAccountsURL}/charge`, paymentPayload);
    });
};
const subscribeWithoutPayment = (user: Object):Promise<Object> => {
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.post(`${serverAccountsURL}/subscribe-no-pay`);
    });
};

const getFilteredFeed = (options: RequestsFilterOptions, filterSearchText: string, user: Object): Promise<FeedPromise> => {
  return authenticatedAxios(user, serverTasksURL)
    .then(authAxios => {
      return authAxios.post('/feed', {
        ...options,
        filterSearchText
      });
    });
};

const verifyCaptcha = (captchaResponsePayload: Object): Promise<Object> => {
  log('response recieved', captchaResponsePayload);
  const _publicAxios = publicAxios(serverAccountsURL);
  return _publicAxios.post('/verify_captcha', captchaResponsePayload);
};

// eslint-disable-next-line max-len
const getRequestsForCSV = (options: RequestsFilterOptions, filterSearchText: string, user: Object): 
Promise<{data: Array<TaskForCSV>}> => {
  log('[getRequestsForCSV]...', options);
  return authenticatedAxios(user, serverTasksURL)
    .then(authAxios => {
      return authAxios.post('/feed/CSV', {
        ...options,
        filterSearchText
      });
    });
};

const uploadConversationImage = (file: Object, user: Object): Promise<{
  data: {
    success: boolean,
    message: string,
    attachment: Attachment
  }
}> => {

  return getBase64fromFile(file)
    .then((base64Data) => {
      return authenticatedAxios(user, serverTasksURL)
      .then(authAxios => {
        return authAxios.post('/upload/image', {
          base64String: base64Data.body,
          name: file.name,
          type: file.type,
          size: file.size

        });
      });
    });
};

const getTags = (param: 'simple'|'cust' = 'simple', user: Object): Promise<Object> => {
  return authenticatedAxios(user, serverTagsURL)
    .then(authAxios => {
      return authAxios.get(`/tags/${param}`);
    });
};

type setTagPostBody = {
  Name: string,
  Color: string,
  TaskId?: string
}
type editTagPostBody = {
  Name?: string,
  Color?: string,
  _id: string
}

const setTag = (tag: setTagPostBody, user: Object): Promise<Object> => {
  return authenticatedAxios(user, serverTagsURL)
    .then(authAxios => {
      return authAxios.post('/tags', tag);
    });
};

const editTag = (tag: editTagPostBody, user: Object): Promise<Object> => {
  return authenticatedAxios(user, serverTagsURL)
    .then(authAxios => {
      return authAxios.put('/tags', tag);
    });
};

const tagCustomers = (tagId: string, customerIds: Array<string>, user: Object): Promise<Object> => {
  return authenticatedAxios(user, serverTagsURL)
    .then(authAxios => {
      return authAxios.post('/tags/customers', {
        tagId,
        customerIds
      });
    });
};

const removeTagFromCustomers = (tagId: string, customerIds: Array<string>, user: Object): Promise<Object> => {
  return authenticatedAxios(user, serverTagsURL)
    .then(authAxios => {
      return authAxios.post('/tags/customers/remove', {
        tagId,
        customerIds
      });
    });
};

const archiveTag = (tagId: string, user: Object): Promise<Object> => {
  return authenticatedAxios(user, serverTagsURL)
    .then(authAxios => {
      return authAxios.delete(`/tags/delete/${tagId}`);
    });
};


export type FeedPromise = {
  data: {
    Requests: Array<Task>,
    totalRequests: number,
    Units: Array<Unit>
  }
}

export type TaskForCSV = {
  Subject: string,
  Tag: string,
  'Property & Floor': string,
  Unit: string,
  Frequency: string,
  Progress: string,
  'Last Update': string,
  Assignee: string
}

const getFilterOptions = (user: Object): Promise<Array<RequestsFilterOptions>> => {
  log('[getFilterOptions]...');
  return authenticatedAxios(user, serverTasksURL)
    .then(authAxios => {
      return authAxios.get('/filteroptions');
    });
};

export type AptsResponse = {
  data: {
    [roomName: string]: {
      RoomKey: string,
      Tenants: Array<Object>
    }
  }
}

const getApartments = (email: string, password: string, propertyKey: string): 
Promise<AptsResponse> => {
  return axios.get(`/api/custom?USER=${email}&PASS=${password}&Flag=GetAptsInBuilding&Key=${propertyKey}`);
};

const getUnit = (email: string, password: string, unitKey: string): Promise<UnitData> => {
  return axios.get(`/api/unit/${unitKey}/?USER=${email}&PASS=${password}`);
};

const findUnits = (
  searchVal: string,
  user: Object
): Promise<UnitsResponse> => {
return authenticatedAxios(user, serverUnitsURL)
  .then(authAxios => {
    return authAxios.get(`units?search=${searchVal ? searchVal : ''}`);
  });
};

const findCommonUnits = (
  searchVal: string,
  user: Object
): Promise<UnitsResponse> => {
return authenticatedAxios(user, serverUnitsURL)
  .then(authAxios => {
    return authAxios.get(`units/common?search=${searchVal ? searchVal : ''}`);
  });
};

const createRequest = (newRequest: Object, user: Object): Promise<string> => {
  return authenticatedAxios(user, serverTasksURL)
    .then(authAxios => {
      return authAxios.post('/request/create/', newRequest);
    });
};

const getRequest = (requestId: string, user: Object): Promise<GetRequestRespons> => {
  return authenticatedAxios(user, serverTasksURL)
    .then(authAxios => {
      return authAxios.get(`/request/${requestId}`);
    });
};

const renameTask = (taskId: string, newTaskName: string, user: User): Promise<{data: Request}> => {
  return authenticatedAxios(user, serverTasksURL)
  .then(authAxios => {
    return authAxios.put(`/tasks/rename/${taskId}`, {
      Issue: newTaskName
    });
  });
};

const updateRequest = (requestId: string, newStatus: string, user: Object): 
Promise<UpdateRequestResponse> => {
  return authenticatedAxios(user, serverTasksURL)
    .then(authAxios => {
      return authAxios.put(`/request/${requestId}`, {
        Status: newStatus
      });
    });
};

const sendRequestNote = (requestId: string, message: string, user: Object): 
Promise<RequestResponse> => {
  return authenticatedAxios(user, serverTasksURL)
    .then(authAxios => {
      return authAxios.post(`/note/${requestId}`, {
        Comment: message
      });
    });
};

const getRequestHistory = (taskId: string, user: Object): Promise<RequestHistoryData> => {
  return authenticatedAxios(user, serverTasksURL)
    .then(authAxios => {
      return authAxios.get(`/requesthist/${taskId}`);
    });
};

const getTaskUnreadCount = (taskId: string, user: Object): Promise<{data: {UnreadCount: number}}> => {
  return authenticatedAxios(user, serverTasksURL)
    .then(authAxios => {
      return authAxios.get(`/task/unreadcnt/${taskId}`);
    });
};

const findBuildings = (
  searchVal: string,
  user: Object
): Promise<Array<Building>> => {
  return authenticatedAxios(user, serverBuildingsURL)
    .then(authAxios => {
      return authAxios.get(`buildings/select?search=${searchVal ? searchVal : ''}`);
    });
  };

const getBuildings = (user: Object): Promise<PropertiesData> => {
  return authenticatedAxios(user, serverBuildingsURL)
    .then(authAxios => {
      return authAxios.get('buildings');
    });
};

const getBuilding = (buildingKey: string, user: Object): Promise<BuildingResponse> => {
  return authenticatedAxios(user, serverBuildingsURL)
    .then(authAxios => {
      return authAxios.get(`building/${buildingKey}`);
    });
};

const createBuilding = (newBuilding: Object, user: Object): Promise<BuildingResponse> => {
  log('[createBuilding] user: ', user);
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.post(`${serverBuildingsURL}/building`, newBuilding);
    });
};

const removeBuilding = (propertyKey: string, user: Object): Promise<BuildingResponse> => {
  log('[createBuilding] user: ', user);
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.delete(`${serverBuildingsURL}/building/${propertyKey}`);
    });
};

const sendEmailRequestForNewNumber = (user: Object, payload: Object): Promise<string> => {
  log('[sendEmailRequestForNewNumber] building: ', payload);
  return authenticatedAxios(user, serverBuildingsURL)
    .then(authAxios => {
      return authAxios.post('/sendEmailRequestForNewNumber', payload);
    });
};

const getAccountBotSettings = (user: Object): Promise<{data: Object}> => {
  return authenticatedAxios(user, serverAccountsURL)
    .then(authAxios => {
      return authAxios.get('settings/bot');
    });
};

export type BotSettingsScriptStep = {
  en: string,
  queryType: string,
  triggerEvents?: Array<string>
}

export type BotSettingsScript = {
  // AccountId: string,
  // BuildingPhone: string,
  // Script: {
    KnownTenant: Array<BotSettingsScriptStep>,
    UnknownTenant: Array<BotSettingsScriptStep>
  // }
};

const setAccountBotSettings = (user: Object, payload: BotSettingsScript): Promise<{data: Object}> => {
  log('[setAccountBotSettings] payload: ', payload);
  return authenticatedAxios(user, serverAccountsURL)
    .then(authAxios => {
      return authAxios.post('settings/bot', payload);
    });
};

const editBuilding = (propertyKey: string, changedPropertyName: string, user:Object): 
Promise<BuildingResponse> => {
  log('[editBuilding] user: ', user);
  return authenticatedAxios(user, serverBuildingsURL)
    .then(authAxios => {
      // TODO: Use R.pick to remove propertyKey
      // log("[editBuilding] changedPropertyName: ", changedPropertyName);
      return authAxios.put(`/building/${propertyKey}`, changedPropertyName);
    });
};

const createUnit = (newUnit: Object, user: Object): Promise<UnitResponse> => {
  return authenticatedAxios(user, serverUnitsURL)
    .then(authAxios => {
      return authAxios.post('unit', newUnit);
    });
};

const removeUnit = (roomKey: string, user: Object): Promise<UnitResponse> => {
  return authenticatedAxios(user, serverUnitsURL)
    .then(authAxios => {
      return authAxios.delete(`unit/${roomKey}`);
    });
};

const editUnit = (unit: Unit, roomKey:string, user: Object): Promise<UnitResponse> => {
  return authenticatedAxios(user, serverUnitsURL)
    .then(authAxios => {
      log('[editUnit] roomKey: ', roomKey);
      return authAxios.put(`unit/${roomKey}`, unit);
    });
};

const createTenant = (
    tenant: Tenant,
    user: Object
  ): Promise<TenantResponse> => {
  return authenticatedAxios(user, serverTenantsURL)
    .then(authAxios => {
      return authAxios.post('dashboard/tenant', tenant);
    });
};

const upsertTenant = (
  row: {FirstName: string, LastName?: string, Phone: string, Email: string, Tags: string},
  user: Object
): Promise<TenantResponse> => {
  log('[upsertTenant] row, user: ', row, user);
return authenticatedAxios(user, serverTenantsURL)
  .then(authAxios => {
    return authAxios.post('dashboard/tenant/upsert', row);
  });
};

const editTenant = (
  payload: Tenant,
  user: Object
): Promise<TenantResponse> => {
log('[editTenant] payload: ', payload);
return authenticatedAxios(user, serverTenantsURL)
  .then(authAxios => {
    return authAxios.put('dashboard/tenant', payload);
  });
};

const moveTenantOut = (
  payload: Tenant,
  user: Object
): Promise<TenantResponse> => {
log('[moveTenantOut] payload: ', payload);
return authenticatedAxios(user, serverTenantsURL)
  .then(authAxios => {
    return authAxios.put('dashboard/tenant/moveout', payload);
  });
};

const removeTenant = (
  id: string,
  user: Object
): Promise<string> => {
log('[removeTenant] tenant: ', id);
return authenticatedAxios(user, serverTenantsURL)
  .then(authAxios => {
    return authAxios.delete(`dashboard/tenant/${id}`);
  });
};

/**
 * 
 * @param {*} tenantId 
 * @param {*} taskId if not empty, assigns this message specifically to this task id.
 * @param {*} messagePayload 
 * @param {*} user 
 */
const sendPMToTenant = (
  tenantId: string,
  taskId?: string = '',
  messagePayload: {message: string, attachment?: Attachment},
  user: Object
): Promise<AxiosResponse> => {
log('[sendPMToTenant] tenantId: ', tenantId);
return authenticatedAxios(user, serverTenantsURL)
  .then(authAxios => {
    return authAxios.post('dashboard/tenants/send-private-message', {
      tenantId,
      taskId,
      messagePayload
    });
  });
};

const sendPMToTenants = (
  tenantIds: Array<string>,
  messagePayload: {message: string, attachment?: Attachment},
  user: Object
): Promise<AxiosResponse> => {
log('[sendPMToTenant] tenantIds: ', tenantIds);
return authenticatedAxios(user, serverTenantsURL)
  .then(authAxios => {
    return authAxios.post('dashboard/tenants/pm', {
      tenantIds,
      messagePayload
    });
  });
};

const findTenants = (findTenantsPayload: Object, user: Object): Promise<TenantsResponse> => {
  log('findTenants:', findTenantsPayload);
  return authenticatedAxios(user, serverTenantsURL)
    .then(authAxios => {
      return authAxios.post('dashboard/tenants/findTenants',findTenantsPayload);
    });
};

const findTenantsIds = (
  tagIds: Array<string>,
  user: Object
): Promise<{data: { tenants: Array<Tenant>} }> => {
return authenticatedAxios(user, serverTenantsURL)
  .then(authAxios => {
    return authAxios.post('dashboard/tenantsIds', {
      tagIds
    });
  });
};

const getBase64fromFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      console.log('getBase64fromFile success.');
      const spliced = reader.result.split(',');
      const header = spliced[0];
      spliced.shift();
      resolve({
        header: header,
        body: spliced.join('')
      });
    };
    reader.onerror = (err) => {
      console.log('getBase64fromFile failed.');
      reject(err);
    };
  });
};

const uploadTenantFile = (
  file: Object,
  user: Object
): Promise<string> => {
  return getBase64fromFile(file)
    .then((base64Data) => {
      return authenticatedAxios(user, serverTenantsURL)
      .then(authAxios => {
        return authAxios.post('dashboard/tenants', {
          base64String: base64Data.body,
          name: file.name,
          type: file.type,
          size: file.size

        });
      });
    });
};

const findKeywordTriggers = (user: Object):Promise<Object> => {
  log('[findKeywordTriggers] user', user.email);
  return authenticatedAxios(user, serverAccountsURL)
    .then(authAxios => {
      return authAxios.get('/findKeywordTriggers');
    });
};

const editKeywordTrigger = (keywordPayload: Keyword, user: Object, isNewKeyword: boolean):Promise<Object> => {
  log('[editKeywordTrigger] keywordPayload', keywordPayload);
  return authenticatedAxios(user, serverAccountsURL)
    .then(authAxios => {
      return authAxios.post(`/editKeywordTrigger?isNewKeyword=${isNewKeyword.toString()}`, keywordPayload);
    });
};

const removeKeywordTrigger = (
  id: string,
  user: Object
): Promise<string> => {
log('[removeKeywordTrigger] keywordId: ', id);
return authenticatedAxios(user, serverAccountsURL)
  .then(authAxios => {
    return authAxios.delete(`dashboard/keywordTrigger/${id}`);
  });
};

const findStaffMembers = (searchVal: string, user: Object): Promise<UsersViewResponse> => {
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.get(`${serverURL}/staff/find?search=${searchVal}`);
    });
};

const assignStaffToTasks = (
  TaskIds: Array<string>,
  AssigneeId: string,
  user: Object): Promise<AssignStaffToTasksResponse> => {
  const payload = {
    TaskIds,
    AssigneeId
  };
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.post(`${serverURL}/staff/assigntasks`, payload);
    });
};

const getStaffInvite = (inviteToken: string): Promise<Object> => {
  const _publicAxios = publicAxios(serverURL);
  return _publicAxios.get(`/staff/invite/${inviteToken}`);
};

const acceptStaffInvite = (inviteToken: string, payload: Object): Promise<Object> => {
  const _publicAxios = publicAxios(serverURL);
  return _publicAxios.post(`/staff/invite/${inviteToken}`, payload);
};

const getUsers = (user: Object): Promise<UsersViewResponse> => {
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.get(`${serverURL}/users`);
    });
};

const getCurrentUser = ( user: Object): Promise<UsersViewResponse> => {
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.get(`${serverURL}/user`);
    });
};

const addUserPermission = (payload: User, user: Object): Promise<string> => {
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.put(`${serverURL}/user`, payload);
    });
};

const removeUserPermission = (payload: User, user: Object): Promise<string> => {
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.put(`${serverURL}/user/removeAccess`, payload);
    });
};

const createStaff = (
  payload: User,
  user: Object
): Promise<Object> => {
log('[createStaff] payload: ', payload);
return authenticatedAxios(user)
  .then(authAxios => {
    return authAxios.post(`${serverURL}/user`, payload);
  });
};

type Signature = {
  FullName: string,
  Agreed: boolean,
  Agreement: string,
  SignedDt: string
}

const setAgreementSignature = (payload: Signature, user: Object) => {
  return authenticatedAxios(user).then(authAxios => {
    return authAxios.post(`${serverURL}/agreement/signature`, payload);
  });
};

const getAnalyticsProperties = (user: Object, days: number): Promise<PropertiesData> => {
  log(`${serverURL}/analytics/properties`);
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.get(`${serverURL}/analytics/properties/${days}`);
    });
  };

  // const getAnalyticsUnits = (user: Object): Promise<PropertiesData> => {
  //   log(`${serverURL}/analytics/units`);
  //   return authenticatedAxios(user)
  //     .then(authAxios => {
  //       return authAxios.get(`${serverURL}/analytics/units`);
  //     });
  //   };


const getMainAnalyticsKPIs = (user: Object, days: number): Promise<KPIsResponse> => {
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.get(`${serverURL}/analytics/kpis/${days}`, user);
    });
  };

const getGraph = (user: Object, days: number): Promise<GraphResponse> => {
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.get(`${serverURL}/analytics/graph/${days}`, user);
    });
  };

type KPIsResponse = {
  data: Object
}

export type KPIs = {
  slug: string,
  label: string,
  count: number,
  tasks: Array<Task>
}

type GraphResponse = {
  data: Array<Graph>
}

export type Graph = {
  date: string,
  count: number,
  day: string
}
const removeStaff = (staff: User, user: Object): Promise<string> => {
  log('[removeStaff] staff: ', staff);
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.delete(`${serverURL}/user/${staff._id}`);
    });
};

const editStaff = (staff: User, changedStaff: Object, user: Object): Promise<string> => {
  log('SPBackend [editStaff] staff: ', changedStaff);
  return authenticatedAxios(user)
    .then(authAxios => {
      return authAxios.put(`${serverURL}/user/${staff._id}`, changedStaff);
    });
};

type UsersResponse = {
  data: Array<User>
}

type UsersViewResponse = {
  data: Array<UserView>
};

export type UserView = {
  user: User,
  permissions: PermissionListView
}

export type PermissionListView = Array<PermissionView>

export type PermissionView = {
  property: Building,
  units: Array<Unit>,
  floors: Array<string>
}

export type Permissions = {
  Properties: Array<string>, // TODO: change name to PropertyKeys
  Floors: Array<{
    PropertyKey: string,
    Floors: Array<string>
  }>,
  Units: Array<{
    PropertyKey: string,
    Units: Array<string>
  }>
};

export type Invite = {
  Status: 'accepted' | 'created',
  CreatedDt: string,
  Token: string,
  From: string,
  To: string,
  Channel: 'email' | 'sms'
}

export type User = {
  _id: string,
  FirstName: string,
  LastName: string,
  Email: string,
  Phone: string,
  IsManager: boolean,
  IsAdmin: boolean,
  IsOwner: boolean,
  Permissions: Permissions,
  IsDemoMode: boolean,
  Invite?: Invite,
  CreatedDt: string,
  Accounts?: {
    _id: string,
    CompanyName: string,
    Email: string,
    IsManager: boolean,
    IsAdmin: boolean,
    IsOwner: boolean
  }
};

export type PropertiesData = {
  data: {
    buildings: Array<Building>,
    stats: Array<{
      unitsCount: number,
      tenantsCount: number,
      staffCount: number
    }>
  }
};

type UnitResponse = {
  data: Unit
};

export type UnitAndTenants = {
  unit: Unit, 
  tenants: Array<Tenant>
};

type BuildingResponse = {
  data: {
    building: Building,
    unitsAndTenants: Array<UnitAndTenants>
  }
}

type StatusAlerts = {
  Tenants? : Array<Tenant>,
  Staff? : Array<Staff>,
  Properties? : Array<Building>
}  

type TenantResponse = {
  data :{
    tenant:Tenant
  }
}

type TenantsResponse = {
  data: Array<Tenant>
}

export type Tenant = {
  _id: string,
  FirstName: string,
  LastName: string,
  Phone: ?string,
  Email?: ?string,
  Keys: Array<string>,
  unitsDocs?: Array<Unit>,
  Tags?: Array<string>
}

export type SendBroadcastResponse = {
  data: {
    message: string,
    count: number,
    task: Task
  }
}

export type SimpleMessageResponse = {
  data: {
    message: string,
    success: boolean
  }
}
export type AssignStaffToTasks = SimpleMessageResponse & {
  data: {
    updatedRequests: Array<Task>
  }
}

export type GetReqeustRespons = {
  data: {
    request: Task,
    unit: Unit
  }
}

export type UpdateRequestResponse = {
  data: Task
};

export type UnitData = {
  data: {
    Message: string,
    Unit: Unit,
    Error: bool
  }
}

type UnitsResponse = {
  data: Array<Unit>
}

export type Unit = {
  _id: string,
  RoomKey: string,
  Property: string,
  PropertyKey: string,
  Floor: string,
  Apt: string
}

export type ChatEntry = {
  id: string,
  RequestID: number,
  EntryID: number,
  User: string,
  FirstName: string,
  LastName: string,
  Date: string,
  Status: string,
  Summary: ?string,
  Comment: string
}

export type RequestHistory = {
    history: Array<ChatEntry>,
    task: Task,
    unit: Unit,
    tenant: Tenant,
    isV2Format: boolean
}

export type RequestHistoryData = {
  data: RequestHistory
}

export type RequestWrap = {
  Apt: string,
  Floor: string,
  Building: ?Building, // decorated by the frontend to optimize render
  Request: Task
}

const initSPBackend = () => {  
  return {
    // Accounts
    getAccount,
    switchAccount,
    setPushSubscriberId,
    setAccountOnboardingSettings,
    registerNewAccount,
    getPlanInfo,
    getCouponInfo,
    getAccountInvoices,
    getPaymentMethods,
    updatePaymentMethod,
    chargePlan,
    editKeywordTrigger,
    findKeywordTriggers,
    removeKeywordTrigger,
    sendEmailRequestForNewNumber,
    getAccountBotSettings,
    setAccountBotSettings,
    fetchPhoneNumbers,
    buyPhoneNumber,
    sendCodeToVerifyNb,
    verifyPhoneNbCode,
    getMembersTopupPlans,
    purchaseTopupPlan,
    getReferralLink,
    logReferralLinkVisit,
    getAppClient,
    getCustomIdToken,
    // tasks
    getFilteredFeed,
    getRequestsForCSV,
    uploadConversationImage,
    // tags
    getTags,
    setTag,
    editTag,
    tagCustomers,
    removeTagFromCustomers,
    archiveTag,
    // moveTasksToUnit,
    // buildings
    getBuildings,
    getBuilding,
    createBuilding,
    removeBuilding,
    editBuilding,
    // units
    getApartments,
    getUnit,
    findUnits,
    findCommonUnits,
    getRequestHistory,
    getTaskUnreadCount,
    getRequest,
    createRequest,
    getFilterOptions,
    renameTask,
    updateRequest,
    sendRequestNote,
    createUnit,
    removeUnit,
    editUnit,
    // tenants
    createTenant,
    upsertTenant,
    // findUnconfirmedTenants,
    findTenants,
    findTenantsIds,
    editTenant,
    moveTenantOut,
    removeTenant,
    sendPMToTenant,
    sendPMToTenants,
    uploadTenantFile,
    // users
    getStaffInvite,
    acceptStaffInvite,
    getUsers,
    getCurrentUser,
    findStaffMembers,
    assignStaffToTasks,
    getMainAnalyticsKPIs,
    // getMainKPIsTasks,
    getGraph,
    findBuildings,
    addUserPermission,
    removeUserPermission,
    createStaff,
    removeStaff,
    editStaff,
    getAnalyticsProperties,
    setAgreementSignature,
    verifyCaptcha
  };
};

export default initSPBackend();