// @flow

import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import classNames from 'classnames';
import invert from 'invert-color';

import { useStore } from '../../store';

import Chip from '@material-ui/core/Chip';

import { log } from '../../utils/jsUtils';
import TagEditable from './TagEditable';
import TagReadonly from './TagReadonly';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/TagRenderer.Style';

import type { Tag } from '../../services/SPBackend';

type Props = {
  classes: Object,
  callbackOnTagPicked?: Function,
  callbackOnShowTagsPicker?: Function,
  callbackOnHideTagsPicker?: Function,
  callbackOnTagRemoved?: Function,
  tagsIds: Array<string>,
  readonly?: boolean
}
const TagRenderer = (props: Props) => {
  const {
    callbackOnTagPicked,
    callbackOnShowTagsPicker,
    callbackOnHideTagsPicker,
    callbackOnTagRemoved,
    tagsIds, // tags to render
    readonly,
    classes
  } = props;
  const [store, dispatch] = useStore();
  const {
    tags
  } = store;

  const [renderTags, setRenderTags] = useState([]);
  
  useEffect(() => {
    // console.log('[TaskTypeTag] tags or task changed. anchor top: ', !targetForSubmenu ? 'empty' : targetForSubmenu.getBoundingClientRect().top);
    if (typeof tagsIds !== 'undefined'
      && typeof tags !== 'undefined'
      && tags) {

      // We use reduce to prevent adding undefined elements in the returned array.
      // https://stackoverflow.com/questions/24806772/how-to-skip-over-an-element-in-map
      setRenderTags(tagsIds.reduce((result, tagId) => {
        const tag = R.find(R.propEq('_id', tagId))(tags);
        // log('[TagRenderer] tId: ', tag, tagsIds);
        if (typeof tag !== 'undefined') {
          result.push(tag);
        }
        return result;
      }, []));
    } else {
      setRenderTags([]);
    }
  }, [tags, tagsIds]);

  const wrapInSpan = (label) => { // html2canvas will only read words in Chip if they are wrapped in a double span 
    const spanningLabel = <span><span>{label}</span></span>;
    return (spanningLabel);
  };

  return (
    <React.Fragment>
    {
      readonly
      ? <TagReadonly
          renderTags={renderTags} 
        />
    :   <TagEditable
          callbackOnHideTagsPicker={callbackOnHideTagsPicker}
          callbackOnShowTagsPicker={callbackOnShowTagsPicker}
          callbackOnTagPicked={callbackOnTagPicked}
          callbackOnTagRemoved={callbackOnTagRemoved}
          renderTags={renderTags}
        />
    }
    </React.Fragment>
  );
};

export default withStyles(styles)(TagRenderer);
