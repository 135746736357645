/* eslint-disable react/no-multi-comp */
// @flow
import React, { useState } from 'react';
import * as R from 'ramda';
import classNames from 'classnames';
import { log } from '../../utils/jsUtils';
import Testimonial1 from '../../assets/testimonial-eitan-katz.png';
import Testimonial2 from '../../assets/testimonial-ilan-sadon.png';
import Testimonial3 from '../../assets/testimonial-maribeth1.png';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { Typography, Hidden } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import CheckIcon from '@material-ui/icons/Check';

import { makeStyles } from '@material-ui/core/styles';
import { styles } from './ContentfulPricingSection.Style';

type Props = {
  selectPlan: Function,
  pricingTable: {
    enableMonthYearSwitch: boolean,
    showOnlyMobilePlans: boolean,
    plans: Array<{
      id: string,
      title: string,
      ctaLabel: string,
      subTitle: String,
      monthly: string,
      yearly: string,
      price: string, // used if !enableMonthYearSwitch
      linkMonthly: string,
      linkYearly: string,
      link: string, // used if !enableMonthYearSwitch
      planId: string
    }>,
    features: Array<{
      label: string,
      plans: Array<string>
    }>,
    plansMobile: Array<{
      planId: string,
      features: Array<{
        label: string,
        description: string
      }>
    }>
  }
}

const ContentfuRenderPlanDesc = (props: Props) => {
  const {
    checkoutFlow
  } = props;
  const {
    isPeriodYearly,
    planDef,
    planIndex,
    plan,
    pricingTable
  } = checkoutFlow;
  console.log('[ContentfuRenderPlanDesc] render: pricingTable: ', pricingTable);
  // console.log('[ContentfuRenderPlanDesc] render: isPeriodYearly, planDef, plan, pricingTablePlans', isPeriodYearly,
  // planDef,
  // plan,
  // pricingTable);
  const useStyles = makeStyles(styles({
    plansNb: 1
  }));
  const classes = useStyles();


  return (
    <>
          {
            pricingTable && !pricingTable.showOnlyMobilePlans
            ? <Paper
              className={classes.desktopPlanContainer}
              elevation={4}
              >
              {/* Desktop view or comparison grid */}
              <Hidden only={['xs']}>
                  <Grid container
                        direction='row'
                  >
                    <Grid
                      className={classes.leftColItem}
                      item
                    >
                      <Grid className={classes.featuresList}
                        container
                      >
                        <Grid className={classes.upgradeFeatureHeader}
                              item
                        />
                        {pricingTable.features.map((feature, featureIndex) => (
                          <Grid
                            className={classNames(
                              classes.gridCell,
                              classes.featureDesc,
                              feature.isHeader ? classes.emphasisDescr : null,
                              pricingTable.features.length === featureIndex + 1
                                ? 'bottom'
                                : null
                            )}
                            item
                            key={`feature-${featureIndex}`}
                          >
                            {feature.label}
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid
                      className={classes.planColItem}
                      item
                    >
                      <Grid className={classes.featuresList}
                        container
                      >
                        <Grid className={classes.upgradeFeatureHeaderRight}
                          item
                        >
                          <Typography
                            className={classes.planTitle}
                            variant='h2'
                          >
                            {plan.title}
                          </Typography>
                          {!pricingTable.enableMonthYearSwitch ? (
                            <Typography
                              className={classNames(
                                classes.planPrice,
                                plan.yearly === -1
                                  ? classes.planPricePAYG
                                  : null
                              )}
                              variant='h3'
                            >
                              {plan.yearly === -1
                                ? 'Pay-as-you-go'
                                : `$${plan.yearly}`}
                            </Typography>
                          ) : isPeriodYearly ? (
                            <div className={'discountedPrice'}>
                              <Typography
                                className={classNames(
                                  classes.planPrice,
                                  plan.yearly === -1
                                    ? classes.planPricePAYG
                                    : null
                                )}
                                variant='h3'
                              >
                                {plan.yearly === -1
                                  ? 'Pay-as-you-go'
                                  : `$${plan.yearly}/mo`}
                              </Typography>
                              {plan.monthly !== -1 ? (
                                <Typography
                                  className={classes.striked}
                                  variant='h4'
                                >
                                  {`$${plan.monthly}`}/mo
                                </Typography>
                              ) : (
                                <Typography
                                  className={classes.striked}
                                  variant='h4'
                                >
                                  &nbsp;
                                </Typography>
                              )}
                            </div>
                          ) : (
                            <div className={'discountedPrice'}>
                              <Typography
                                className={classNames(
                                  classes.planPrice,
                                  plan.monthly === -1
                                    ? classes.planPricePAYG
                                    : null
                                )}
                                variant='h3'
                              >
                                {plan.monthly === -1
                                  ? 'Pay-as-you-go'
                                  : `$${plan.monthly}/mo`}
                              </Typography>
                              <Typography
                                className={classes.hiddenSpace}
                                variant='h4'
                              >
                                -
                              </Typography>
                            </div>
                          )}
                        </Grid>
                        {pricingTable.features.map(
                          (feature, featureIndex) => (
                            <Grid
                              className={classNames(
                                classes.gridCell,
                                classes.featureItem,
                                pricingTable.features.length === featureIndex + 1 ? 'bottom' : null,
                                feature.isHeader ? classes.emphasisDescr : null
                              )}
                              item
                              key={`item-${featureIndex}`}
                            >
                              {!feature.plans ? null : feature.plans[
                                  planIndex
                                ] === 'checked' ? (
                                <FontAwesomeIcon
                                  className={classes.checkIcon}
                                  icon={faCheck}
                                />
                              ) : (
                                <pre>{feature.plans[planIndex]}</pre>
                              )}
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Hidden>


              {/* Mobile view */}
              <Hidden only={['sm', 'md', 'lg', 'xl']}>
                {
                  pricingTable.plansMobile.map((plan, planIndex) => {
                    const planDef = R.find(R.propEq('id', plan.planId))(pricingTable.plans);
                    return (
                      <div key={planIndex}>{
                        planDef
                        ? <Grid
                            className={classes.featuresList}
                            container
                            key={`mobile-plan-${planIndex}`}
                          >
                            <Grid className={classes.featureHeader}
item
                            >
                              <Typography variant='h2'>
                                {planDef.title}
                              </Typography>
                              {
                                isPeriodYearly ?
                                <div className={'discountedPrice'}>
                                  <Typography variant='h3'>{`$${planDef.yearly}`}/mo</Typography>
                                  <Typography className={classes.striked}
variant='h4'
                                  >{`$${planDef.monthly}`}/mo</Typography>
                                </div>
                                : 
                                <div>
                                  <Typography variant='h3'>{`$${planDef.monthly}`}/mo</Typography>
                                  <Typography className={classes.hidden}
variant='h4'
                                  >-</Typography>
                                </div>
                              }
                              {/* </a> */}
                            </Grid>
                            {
                              plan.features.map((feature, featureIndex) => (
                                <Grid
                                  className={classNames(
                                    classes.gridCell,
                                    featureIndex ? classes.featureDesc : classes.topFeatureDesc,
                                    plan.features.length === featureIndex + 1 ? 'bottom' : null
                                  )}
                                  item
                                  key={`mobile-plan${planIndex}-feature${featureIndex}`}
                                >
                                  {feature.label}
                                </Grid>
                              ))
                            }
                          </Grid>
                        : null
                      }</div>
                    );
                  })
                }
              </Hidden>
            </Paper>
            : null
          }

          {/* New mobile only mode */}
          {
            pricingTable && pricingTable.showOnlyMobilePlans
            ? <div className={classes.mobileOnlyPricingGridContainer}>
            {
              pricingTable.plansMobile.map((plan, planIndex) => {
                const planDef = R.find(R.propEq('id', plan.planId))(pricingTable.plans);
                return (
                  <Paper
                    className={classes.mobileOnlyGridPaper}
                    elevation={4}
                    key={planIndex}
                  >
                  {
                    planDef
                    ? <Grid
                        className={classes.featuresList}
                        container
                        key={`mobile-plan-${planIndex}`}
                      >
                        <Grid className={classes.mobileOnlyFeatureHeader}
item
                        >
                          <Typography variant='h2'>
                            {planDef.title}
                          </Typography>
                          {
                            planDef.subTitle
                            ? <Typography variant='h3'>
                                {planDef.subTitle}
                              </Typography>
                            : null
                          }
                        </Grid>
                        {
                          plan.features.map((feature, featureIndex) => (
                            <Grid
                              className={classNames(
                                classes.mobileOnlyGridCell,
                                featureIndex ? classes.featureDesc : classes.topFeatureDesc,
                                plan.features.length === featureIndex + 1 ? 'bottom' : null
                              )}
                              item
                              key={`mobile-plan${planIndex}-feature${featureIndex}`}
                            >
                              <CheckCircleOutlineIcon />
                              <Typography
                                className={classes.mobileOnlyFeature}
                                variant='body1'
                              >
                                {feature.label}
                              </Typography>
                            </Grid>
                          ))
                        }
                      </Grid>
                    : null
                  }
                  </Paper>
                );
              })
            }
            </div>
            : null
          }
    </>
  );
};

export default ContentfuRenderPlanDesc;
