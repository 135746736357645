import React, {useEffect, useState} from 'react';
import { useStore } from '../../store';
import { log } from '../../utils/jsUtils';

import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';

import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { withStyles } from '@material-ui/core/styles';
import commonListTableStyles from '../Styles/CommonListTable.Style';
import styles from '../Feed/Styles/MemberContent.Style';
import combineStyles from '../../utils/stylesUtils';

type Props = {
  classes: Object
};

const PaginateFooter = (props: Props) => {
  const {
    classes
  } = props;

  const [store, dispatch] = useStore();

  const {
    totalRequests,
    filterSelections
  } = store;

  // log('filterSelections: ', filterSelections);
  // log('totalRequests: ', totalRequests);
  const changePage = (pageNb: number) => {
    dispatch({
      type: 'ACTION_SET_FILTER_FEED_PAGE_NB',
      pageNb
    });
  };

  return (
  <Grid 
    className={classes.paginateFooter}
    container
  >
    <Grid 
    className={classes.pagePrev}
    item
    >
      {
        filterSelections.pageNb > 0
        ? <Fab
            aria-label="Previous"
            className={classes.fab}
            onClick={() => changePage(filterSelections.pageNb - 1)}
            size="small"
          >
            <ArrowLeftIcon />
          </Fab>
        : null
      }
    </Grid>
    <Grid
      className={classes.pageNumber}
      item
    >
      <Button
        className={classes.button}
        disabled
      >{`Page ${filterSelections.pageNb + 1} / ${totalRequests > 0 ? Math.ceil(totalRequests / filterSelections.itemsPerPage) : 0}`}</Button>
    </Grid>
    <Grid 
    className={classes.pageNext}
    item
    >
      {
        filterSelections.pageNb + 1 < Math.ceil(totalRequests / filterSelections.itemsPerPage)
        ? <Fab
            aria-label="Previous"
            className={classes.fab}
            onClick={() => changePage(filterSelections.pageNb + 1)}
            size="small"
          >
            <ArrowRightIcon />
          </Fab>
        : null
      }
    </Grid>
  </Grid>
  );
};

const combinedStyles = combineStyles(
  commonListTableStyles,
  styles
);

export default withStyles(combinedStyles)(PaginateFooter);

