// @flow

import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { TwitterPicker } from 'react-color';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/TagColorPicker.Styles';

const ColorPicker = (props) => {
  const {
    handleColorPicked,
    color,
    displayColorPicker,
    setDisplayColorPicker,
    classes
  } = props;

  const popover = {
    position: 'fixed',
    zIndex: '9999'
  };
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px'
  };
  // console.log('[TagColorPicker][render] color: ', color);
  return (
    <div>

      {
        !color || color === '#CCCCCC'
        ? <div
          className={classNames(classes.colorPickerRainbow)} 
          onClick={() => setDisplayColorPicker(!displayColorPicker)}
          style={{
            color: color
          }}
          />
        : <FiberManualRecordIcon 
            onClick={() => setDisplayColorPicker(!displayColorPicker)}
            style={{
              color: color
            }}
          />
      }
      
      {/* <button >Pick Color</button> */}
      { displayColorPicker ? <div style={popover}>
        <div onClick={() => setDisplayColorPicker(false)}
          style={cover}
        />
        <TwitterPicker onChangeComplete={handleColorPicked} />
      </div> : null }
    </div>
  );
};

export default withStyles(styles)(ColorPicker);