// @flow

import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';
import { useForm } from 'react-hook-form';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';

import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';

import ImageFileDrop from '../SharedComponents/ImageFileDrop';
import RemovableModalImage from '../SharedComponents/RemovableModalImage';
import RemoveKeywordTriggerDialog from './RemoveKeywordTriggerDialog';
import { calculateCreditsForMsg } from '../../models/Message';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/KeywordDetailsDialog.Style';

import ForwardRef from '../SharedComponents/ForwardRef';

type Props = {
  isNewKeyword: boolean,
  keyword: Object,
  setKeyword: Function,
  removeKeywordTrigger: Function,
  classes: Object,
  setOpen: Function,
  open: boolean,
  triggerAction: Function
};

// eslint-disable-next-line react/no-multi-comp
const KeywordDetailsDialog = (props: Props) => {
  const {
    keyword,
    isNewKeyword,
    classes,
    setKeyword,
    setOpen,
    onClose,
    removeKeywordTrigger,
    open,
    triggerAction
  } = props;
  const [store, dispatch] = useStore();
  const [attachment, setAttachment] = useState(null);
  const [creditsUsed, setCreditsUsed] = useState(0);
  const [openDeleteKeywordDialog, setOpenDeleteKeywordDialog] = useState(false);
 
  const { register, errors } = useForm();
  // const [error, setError] = useState('');

  // log('[KeywordDetails] keyword from botsettingscontent: ', keyword, open ? 'open' : '!open');

  const emptyForm = () => {
    setKeyword();
    setAttachment(null);
  };

  const handleRemoveKeywordTrigger = () => {
    if (keyword !== null) {
      log('[handleRemoveKeywordTrigger]');
      removeKeywordTrigger(keyword);
      setKeyword(null);
      setOpen(false);
    } else {
      // Show snack message
      dispatch({
        type: 'ACTION_SET_SNACK_MSG',
        snackMessage: {
          message: 'An error occured. Please try again.',
          level: 'error',
          duration: 3000
        }
      });
    }
  };

  const handleSetAttachment = (url) => {
    log('[handleSetAttachment] url: ', url);
    setAttachment(url);
    setKeyword({
      ...keyword,
      ResponseAttachments: [url]
    });
  };

  useEffect(() => {
    if (open) {
      if (typeof keyword !== 'undefined' && keyword !== null) {
        log('[credits used] keyword: ', keyword);
        const [att] = typeof keyword.ResponseAttachments !== 'undefined' && keyword.ResponseAttachments !== null && keyword.ResponseAttachments.length > 0 ? keyword.ResponseAttachments : [null];
        const cu = calculateCreditsForMsg({message: keyword.Response, attachment: att});
        log('[credits used] cu: ', cu);
        setCreditsUsed(cu);
      }
    }
  }, [keyword]);
  
  // FIXME: for now this only supports 1 attachment. So the attachment argument isn't used...
  const removeAttachment = (attachment) => (e) => {
    log('removing attachment: ', attachment);
    e.stopPropagation();
    setAttachment(null);
    setKeyword({
      ...keyword,
      ResponseAttachments: []
    });
  };

  

  log('errors', errors);
  return (
    <Dialog aria-labelledby='form-add-unit'
      classes={{paper: classes.dialogPaperCtr}}
      onClose={onClose}
      open={open}
    >
      <form
        autoComplete='off'
        className={classNames(
          classes.dialogFormCtr,
          'xlarge'
        )}
        onSubmit={e => {
          e.preventDefault();
          log('[KeywordDetails] saving keyword', keyword);
          triggerAction({
            ...keyword,
            ResponseAttachments: attachment !== null ? [attachment] : []
          }, attachment);
          emptyForm();
          setOpen(false);
        }}
      >
        {/* <DialogTitle id='form-dialog-title'>{'SMS Keyword Trigger Settings'}</DialogTitle> */}
        <DialogContent className={classNames(classes.dialogContentCtr)}>
          <div className={classes.processLegend}>
            <Typography variant='h3'>{'If a customer'}</Typography>
          </div>
          {/* <div className={classes.processLegend}>
            <AccountCircleOutlinedIcon className={classes.arrowIcon}/>
          </div> */}
          <div className={classes.processLegend}>
          <Typography variant='h3'>{'sends this keyword:'}</Typography>
          </div>
          <TextField
            autoFocus
            className={classes.textField}
            fullWidth
            id='keywordName'
            inputRef={register({ required: true })}
            label='Type keyword'
            margin='dense'
            multiline
            name='keyword'
            onChange={(e) => 
              setKeyword({
                ...keyword,
                Keyword: e.target.value
                })
            }
            required
            type='text'
            value={keyword && keyword !== null ? keyword.Keyword : ''}
            variant='outlined'
          />
          <div className={classes.errorLabel}>{ errors.keyword && 'Keyword is required.' }</div>
          <div className={classes.processLegend}>
            <ArrowDownwardRoundedIcon className={classes.arrowIcon}/>
          </div>
          <div className={classes.processLegend}>
            <Typography variant='h3'>{'then Neo replies:'}</Typography>
          </div>
          <TextField
            className={classes.textField}
            fullWidth
            id='keywordResponse'
            inputRef={register({ required: true })}
            label='Type a reply'
            margin='dense'
            multiline
            name='response'
            onChange={(e) => 
              setKeyword({
                ...keyword,
                Response: e.target.value
                })
            }
            required
            rows={5}
            type='text'
            value={keyword && keyword !== null? keyword.Response : ''}
            variant='outlined'
          />
          <div className={classes.errorLabel}>{ errors.response && 'Response is required.' }</div>
          {
            process.env.REACT_APP_CHARS_NB_PER_CREDIT && keyword && keyword.Response
            ? <Tooltip 
              aria-label={`${creditsUsed} ${creditsUsed > 1 ? 'Credits' : 'Credit'}`}
              className={classes.errorTooltip}
              placement='top-end'
              title={`${creditsUsed} ${creditsUsed > 1 ? 'Credits' : 'Credit'}`}
              >
              <ForwardRef>
                <div className={classNames(
                    classes.textFieldCaptionRight,
                    keyword.Response.length >= 1600 ? classes.warning : null
                  )}
                >
                  {keyword.Response.length >= 1600 ? '1600 characters max per message.' : null}
                  {keyword.Response.length > 0 && keyword.Response.length < 1600 ? `${process.env.REACT_APP_CHARS_NB_PER_CREDIT - (keyword.Response.length % process.env.REACT_APP_CHARS_NB_PER_CREDIT)} / ${creditsUsed}` : <span>&nbsp;</span>}
                </div>
              </ForwardRef>
            </Tooltip>
            : null
          }
          
          {
            attachment && attachment.Url !== ''
            ? <RemovableModalImage
                handleClickOnRemove={removeAttachment(attachment)}
                largeImageUrl={attachment.Url}
                smallImageUrl={attachment.Url}
              />
            : keyword && keyword.ResponseAttachments && keyword.ResponseAttachments.length > 0
              ? keyword.ResponseAttachments.map((att, attIdx) => (
                <RemovableModalImage
                  handleClickOnRemove={removeAttachment(att)}
                  key={`attachment-${attIdx}`}
                  largeImageUrl={att.Url}
                  smallImageUrl={att.Url}
                />
              ))
              : null
          }
          </DialogContent>
        <DialogActions classes={{root: classes.actionsCtr}}>
          <div>
            <ImageFileDrop
              setAttachment={handleSetAttachment}
            >
              <Tooltip 
                aria-label={'Attach an image'}
                placement='top'
                title={'Attach an image'}
              >
                <Fab
                  aria-label='Attach an image'
                  className={classes.actionAttachImgBtn}
                  color='secondary'
                >
                  <ImageOutlinedIcon />
                </Fab>
              </Tooltip>
            </ImageFileDrop>
          </div>
          <div className={classes.ctaCtr}>
            {
              isNewKeyword === false
              ? 
                <IconButton 
                    aria-label='delete'
                    className={classes.iconButton}
                    onClick={e => {
                      e.stopPropagation();
                      setOpenDeleteKeywordDialog(true);
                      log('editing keyword: ', keyword);
                    }}
                >
                  <DeleteIcon />
                </IconButton>
              : null
            }
            <Button
              color='primary'
              onClick={(event) => {
                event.preventDefault();
                emptyForm();
                setOpen(false);
              }}
              type='button'
            >
              {'Cancel'}
            </Button>
            <Button
              className={classes.smallMarginLeft}
              color='primary'
              type='submit'
              variant='contained'
            >
              {'Save'}
            </Button>
          </div>
        </DialogActions>
      </form>
      <RemoveKeywordTriggerDialog
            actionConfirm={handleRemoveKeywordTrigger}
            keyword={keyword}
            open={openDeleteKeywordDialog}
            setOpen={setOpenDeleteKeywordDialog}
      />
    </Dialog>
    
  );
};

export default withStyles(styles)(React.memo(KeywordDetailsDialog));
