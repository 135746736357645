// @flow

import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useForm } from 'react-hook-form';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/AddTenantDialog.Style';
import AutocompleteProperty from '../Properties/AutocompleteProperty';
import type { Tenant } from '../../services/SPBackend';

type Props = {
  classes: Object,
  setOpen: Function,
  open: boolean,
  currTenant: Tenant,
  triggerAction: Function
};

// eslint-disable-next-line react/no-multi-comp
const ConfirmTenant = (props: Props) => {
  const {
    classes,
    currTenant,
    setOpen,
    open,
    triggerAction
  } = props;
  const [store] = useStore();
  const {
    defaultUnit
  } = store;
  const { errors } = useForm();
  

  const [tenant, setTenant] = useState({
    _id: null,
    FirstName: '',
    LastName: '',
    Phone: '',
    Email: '',
    Keys: defaultUnit ? [defaultUnit.RoomKey] : ''
  });
  const [error, setError] = useState('');
  // log('[ConfirmTenantDialog.render], defaultUnit: ', defaultUnit);
  // log('[ConfirmTenantDialog.render], tenant: ', tenant);

  useEffect(() => {
    if (typeof currTenant !== 'undefined' && currTenant !== null) {
      setError('');
      setTenant({
        _id: currTenant._id,
        FirstName: currTenant.FirstName ? currTenant.FirstName : '',
        LastName: currTenant.LastName ? currTenant.LastName : '',
        Phone: currTenant.Phone ? `+${currTenant.Phone}` : '',
        Email: currTenant.Email ? currTenant.Email : '',
        Keys: defaultUnit
          ? [defaultUnit.RoomKey]
          : (currTenant.unitsDocs && typeof currTenant.Keys !== 'undefined' && currTenant.Keys !== null)
            ? currTenant.Keys[0] !== ''
              ? [currTenant.unitsDocs[0].RoomKey]
              : ''
            : ''
      });
    }
  }, [currTenant, open]);

  return (
    <Dialog aria-labelledby='form-add-unit'
      onClose={() => setOpen(false)}
      open={open}
    >
      <form
        autoComplete='off'
        className={classNames(
          classes.dialogFormCtr,
          'xlarge'
        )}
        onSubmit={e => {
          e.preventDefault();
          if (tenant.Keys !== null) {
            triggerAction(tenant, true);
          setOpen(false);
          // setRefresh(true);
          } else {
            setError('Please select a unit from the dropdown');
          } 
        }}
      >
        <DialogTitle id='form-dialog-title'>{'Add to directory'}</DialogTitle>
        <DialogContent className={classNames(classes.grow, classes.flexContainer)}>
          <div id='ConfirmTenant'>
            <Input
              autoFocus
              classes={{
                root: classes.secondInput
              }}
              fullWidth
              id='firstName'
              label='Fist Name'
              margin='dense'
              onChange={e =>
                setTenant({
                  ...tenant,
                  FirstName: e.target.value
                })
              }
              placeholder='First name'
              type='text'
              value={tenant.FirstName}
            />
            <Input
              classes={{
                root: classes.secondInput
              }}
              fullWidth
              id='lastName'
              label='Last Name'
              margin='dense'
              onChange={e =>
                setTenant({
                  ...tenant,
                  LastName: e.target.value
                })
              }
              placeholder='Last name'
              type='text'
              value={tenant.LastName}
            />
            <Input
              classes={{
                root: classes.secondInput
              }}
              fullWidth
              id='email'
              label='Email'
              margin='dense'
              onChange={e =>
                setTenant({
                  ...tenant,
                  Email: e.target.value
                })
              }
              placeholder='Email'
              type='email'
              value={tenant.Email}
            />
            <PhoneInput
              className={classNames(classes.phoneInputCtr, 'defaultInput')}
              defaultCountry='US'
              onChange={e =>
                setTenant({
                  ...tenant,
                  Phone: e
                })
              }
              placeholder="Enter phone number"
              value={formatPhoneNumberIntl(tenant.Phone)}
            />
            {/* {
              !defaultUnit
              ? <div className={classes.secondInput}>
                  <AutocompleteProperty 
                    inputTenant={currTenant}
                    setUnit={(unit) => {
                      log('[AutocompleteProperty] unit', unit);
                      setTenant({
                        ...tenant,
                        Keys: unit !== null ? [unit.RoomKey] : null
                      });
                      log('[setUnit] unit, tenant: ', unit, tenant);
                    }}
                  />
                <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox checked
                                            disabled
                                 />}
                      label="Move all tasks to the new unit"
                      />
                  </FormGroup>
                  <div className={classes.errorLabel}>{ errors.unit && 'Unit is required.' }</div>
                </div>
              : null
            } */}
          </div>
          <DialogContentText className={classes.error}>{ error }</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            onClick={(event) => {
              event.preventDefault();
              setOpen(false);
            }}
            type='button'
          >
            {'Cancel'}
          </Button>
          
          <Button
            color='primary'
            disabled={tenant.Keys === ''}
            type='submit'
            variant='contained'
          >
             {'Add'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(ConfirmTenant);
