// @flow

import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import classNames from 'classnames';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';
import { TwitterPicker } from 'react-color';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';

import FileCopy from '@material-ui/icons/FileCopy';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/DeleteOutline';

import TagRenderer from '../SharedComponents/TagRenderer';
import SPBackend from '../../services/SPBackend';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/ReferralsContent.Style';

type Props = {
  classes: Object
};

const ReferralsContent = (props: Props) => {
  const {
    classes
  } = props;
  const [referralShortUrl, setReferralShortUrl] = useState('');
  const [referralAccount, setReferralAccount] = useState(null);
  const [referralLanguage, setReferralLanguage] = useState('');
  const [store, dispatch] = useStore();
  const {
    user,
    i18n,
    tags
  } = store;

  const loadCampaign = () => {
    window.VL.load(process.env.REACT_APP_VIRAL_LOOPS_CAMPAIGN_ID, { autoLoadWidgets: !0 });
  };

  const setupWidgets = () => {
    const campaign = window.VL.instances[process.env.REACT_APP_VIRAL_LOOPS_CAMPAIGN_ID];

    if (!campaign) return;
    const widgets = campaign.widgets.getAll();

    if (typeof widgets !== 'undefined' && widgets.length > 0) {
      widgets.map(widget => widget.show());
    }
  };

  useEffect(() => {
    if (user !== null) {
      SPBackend.getReferralLink(user)
        .then((response) => {
          log('[getReferralLink] response: ', response);
          const { referralAccount } = response.data;
          setReferralAccount(referralAccount);
          setReferralShortUrl(`https://${referralAccount.ReferralShortUrl}`);
          setReferralLanguage(`Hey, I'm excited to share an invite to Neo: the most human customer experience ever made.
I love the product, and I think you will too!

Just click here to sign up for a free trial: https://${referralAccount.ReferralShortUrl}

With my invite, you skip the waiting list, and you'll get a free trial instantly to text with your audience and try all the features 🙂`
          );
        })
        .catch((e) => {
          log('[getReferralLink] e: ', e);
          dispatch({
            type: 'ACTION_SET_SNACK_MSG', 
            snackMessage: {
              message: 'Problem retrieving your link. Contact us if this error persists',
              level: 'error',
              duration: 5000
            }
          });

        });
    }
  }, [user]);

  const onCopy = () => {
    log('[onCopy] copied!');
    dispatch({
      type: 'ACTION_SET_SNACK_MSG', 
      snackMessage: {
        message: 'Copied to your clipboard!',
        level: 'success',
        duration: 2000
      }
    });
  };

  log('[render] referralAccount: ', referralAccount);

  return (
    <div className={classes.tableContainer}>
      <div className={classes.toolbar}/>

      <div className={classes.kpiCtr}> 
        <Paper 
          classes={{root: classes.kpiDiv}}
          elevation={2}
        >
          <Typography className={classes.kpiCaption}
            variant='body1'
          >Visited links</Typography>
          <Typography className={classes.kpiNb}
            variant='h1'
          >{referralAccount ? referralAccount.VisitsCount : 0}</Typography>
        </Paper>
        <Paper 
          classes={{root: classes.kpiDiv}}
          elevation={2}
        >
          <Typography className={classes.kpiCaption}
            variant='body1'
          >Subscribed</Typography>
          <Typography className={classes.kpiNb}
            variant='h1'
          >{referralAccount && referralAccount.Referees ? referralAccount.Referees.length : 0}</Typography>
        </Paper>
        <Paper 
          classes={{root: classes.kpiDiv}}
          elevation={2}
        >
          <Typography className={classes.kpiCaption}
            variant='body1'
          >Total earned</Typography>
          <Typography className={classes.kpiNb}
            variant='h1'
          >$0</Typography>
        </Paper>
      </div>

      <div className={classes.blockquoteCtr}>
        <blockquote className='tweet-this'>
        <p>Neo is now invite-only, so you decide who should benefit from Neo next!</p>
        <p>Share invites to Neo by SMS through the invite link and get a 20% commission in credits or cash.</p>
        {/* <div className='author' >
          <div className='authorLio' />
          <div>Lio Slama, CEO</div>
        </div> */}
        </blockquote>
      </div>
      <div className={classes.refPageCtr}>
      
      <Typography variant='h4'>Share invite & link</Typography>
      <form className={classes.referralLanguageTextForm}>
        <TextField classes={{root: classes.referralLanguageTextField}}
          id="outlined-basic"
          multiline
          onChange={(e) => setReferralLanguage(e.target.value)}
          rows={8}
          value={referralLanguage}
          variant="outlined"
        />
        <CopyToClipboard
          onCopy={onCopy}
          text={referralLanguage}
        >
          <IconButton
            aria-label="Copy link"
            edge="end"
            // onClick={handleClickShowPassword}
            // onMouseDown={handleMouseDownPassword}
          >
            <FileCopy />
          </IconButton>
        </CopyToClipboard>
      </form>

      <Typography variant='h4'>Share link</Typography>

      <FormControl className={classNames(classes.textField)}
        variant="filled"
      >
        {/* <InputLabel htmlFor="filled-adornment-password">Link to share</InputLabel> */}
        <OutlinedInput
          classes={{
            root: classes.outlinedLinkUrl
          }}
          endAdornment={
            <InputAdornment position="end">
            <CopyToClipboard
              onCopy={onCopy}
              text={referralShortUrl}
            >
              <IconButton
                aria-label="Copy link"
                edge="end"
                // onClick={handleClickShowPassword}
                // onMouseDown={handleMouseDownPassword}
              >
                <FileCopy />
              </IconButton>
            </CopyToClipboard>
            </InputAdornment>
          }
          id="filled-adornment-password"
          readOnly
          type={'text'}
          value={referralShortUrl}
        />
      </FormControl>

      </div>
    </div>
  );
};

export default withStyles(styles)(ReferralsContent);

