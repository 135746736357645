// @flow

import type { State } from '../store/initialState';
import { log } from '../utils/jsUtils';
import * as R from 'ramda';
import type { Task } from '../services/SPBackend';

const byDate = R.descend(R.path(['UpdatedDt']));
const byName = R.ascend(R.compose(R.toLower, R.path(['Name'])));

const reducers = (state: State, action: Object) => {
  log('[reducer]: ', action);
  
  log(`[reducer] ${action.type}`, state, action);
  switch (action.type) {
    case 'ACTION_REFRESH_PLAN_INFO':
      return {
        ...state,
        refreshPlanInfoIndex: action.count
      };
    case 'ACTION_SET_DEFAULT_UNIT':
      return {
        ...state,
        defaultUnit: action.defaultUnit
      };
    // case 'ACTION_UPDATE_STATUS_ALERTS':
    //   return {
    //     ...state,
    //     statusAlerts: {
    //       ...state.statusAlerts,
    //       ...action.statusAlerts
    //     }
    //   };
    case 'ACTION_SET_IS_LOADING_MODULE':
      // TODO: refactor: keep only this loading reducer and remove all others.
      const newLoadingNode = {};
      newLoadingNode[action.module] = action.loading;
      return {
        ...state,
        loadingModule: {
          ...state.loadingModule,
          ...newLoadingNode
        }
      };
    case 'ACTION_SET_IS_APP_LOADING':
      return {
        ...state,
        isAppLoading: action.isAppLoading
      };
    case 'ACTION_SET_ACCOUNT_SETTINGS':
      try {
        const settings = R.pick([
          'Tenants',
          'Users',
          'SetupMeeting',
          'SetupRequired',
          'Twilio',
          'EnableCustomersSelfUpload'], action.accountSettings);
        return {
          ...state,
          accountSettings: settings
        };
      } catch (e) {
        return state;
      }
    case 'ACTION_SET_ACCOUNT_USAGE':
      try {
        return {
          ...state,
          accountUsage: action.accountUsage
        };
      } catch (e) {
        return state;
      }
    case 'ACTION_LOGIN':
      return {
        ...state,
        loggedIn: true,
        email: action.email,
        token: action.token
      };
    case 'ACTION_SET_LOGGED_USER':
      return {
        ...state,
        loggedUser: action.user
      };
    case 'ACTION_LOGOUT':
      return {
        ...state,
        loggedIn: false,
        email: '',
        token: ''
      };
    case 'ACTION_LOADING_ACCOUNT':
      return {
        ...state,
        loadingAccount: true
      };
    case 'ACTION_LOADED_ACCOUNT':
      return {
        ...state,
        loadingAccount: false,
        ...action.payload
      };
    case 'ACTION_SET_BILLING_PLANID':
      return {
        ...state,
        billing: {
          ...state.billing,
          planId: action.planId
        }
      };
    case 'ACTION_SET_BILLING':
      return {
        ...state,
        billing: {
          ...state.billing,
          ...action.billing
        }
      };
    case 'ACTION_SET_USER':
      return {
        ...state,
        user: action.user
      };
    case 'ACTION_SET_USERFULLNAME':
      return {
        ...state,
        userFullName: action.userFullName
      };
    case 'ACTION_SET_COMPANYNAME':
      return {
        ...state,
        companyName: action.companyName
      };
    case 'ACTION_SET_USERROLE':
      return {
        ...state,
        userRole: action.userRole
      };
    case 'ACTION_SET_DEMOMODE':
      return {
        ...state,
        isDemoMode: action.demoMode
      };
    case 'ACTION_SET_AGREEMENT':
      return {
        ...state,
        agreement: action.agreement
      };
    case 'ACTION_SET_ID_TOKEN':
      return {
        ...state,
        token: action.token
      };
    case 'ACTION_SET_REQUESTS':
      let totalR = {};
      if (typeof action.totalRequests !== 'undefined') {
        totalR.totalRequests = action.totalRequests;
      }
      return {
        ...state,
        requests: action.requests,
        ...totalR
      };
    case 'ACTION_UPDATE_REQUESTS':
      log('[ACTION_UPDATE_REQUESTS] action.requests: ', action.requests);
      try {
        const incomingRequests = R.clone(action.requests);
        // finds requests to update, and update them
        // TODO: reorder the list to put the latest updates at the top
        let updatedIndex = -1;
        const updatedRequests: Array<Task> = state.requests.map((request: Task, index: number) => {
          // find this request among the incoming action requests
          const newReqIndex = R.findIndex(R.propEq('_id', request._id))(incomingRequests);
          // log('[ACTION_UPDATE_REQUESTS] newReqIndex: ', newReqIndex, request._id);
          if (newReqIndex !== -1) {
            log('[ACTION_UPDATE_REQUESTS] found index to update:', newReqIndex);
            log('[ACTION_UPDATE_REQUESTS] request to replace:', request);
            // show the action request instead of the state request
            const newReq = incomingRequests[newReqIndex];
            // save time: remove the old request from the incoming list
            incomingRequests.splice(newReqIndex, 1);
            // Set the udpated row to animate
            updatedIndex = index;
            newReq.animateUpdated = true;
            log('[ACTION_UPDATE_REQUESTS] newReq:', newReq);
            // return the new request instead
            return newReq;
          }
          return request;
        });
        // Set the previous row that will come down to replace it to fade in
        if (updatedIndex > 0) {
          for (let i = 0; i < updatedIndex; i += 1) {
            updatedRequests[i].animatePushedin = true;
          }
        }
        return {
          ...state,
          requests: R.sort(byDate, updatedRequests)
        };
      } catch (e) {
        log('[ACTION_UPDATE_REQUESTS] error: ', e);
        return state;
      }
    case 'ACTION_ADD_OR_UPDATE_REQUESTS':
      // prepend the new ones AND UPDATE existing ones
      const newRequests: Array<Task> = state.requests.map((request: Task, index: number) => {
        const newReqIndex = R.findIndex(R.propEq('_id', request._id))(action.requests);
        if (newReqIndex !== -1) {
          // UPDATE: remove the existing task, insert new one in its place
          const newReq = action.requests[newReqIndex];
          action.requests.splice(newReqIndex, 1);
          return newReq;
        }
        return request;
      });
      if (action.requests.length > 0) {
        // INSERT : prepend to the list the remaining tasks
        newRequests.unshift(...action.requests.map(r => ({
          ...r,
          animateAdded: true
        })));
      }
      
      const sortedRequests = R.sort(byDate, newRequests);
      return {
        ...state,
        requests: sortedRequests,
        totalRequests: state.totalRequests + action.requests.length
      };
    case 'ACTION_SET_UNITS':
      return {
        ...state,
        units: action.units
      };
    case 'ACTION_ADD_UNITS':
      return {
        ...state,
        units: [...state.units, ...action.units]
      };
    case 'ACTION_SET_FEED_LIVE':
      return { // if live: autorefresh
        ...state,
        isFeedLive: action.isFeedLive
      };
    case 'ACTION_SET_BILLING_LIVE':
      return { // if live: autorefresh
        ...state,
        isBillingLive: action.isBillingLive
      };
    case 'ACTION_SET_FILTER_SEARCH_TEXT':
      return {
        ...state,
        filterSearchText: action.filterSearchText,
        refreshFullFeed: !state.refreshFullFeed // trigger a feed refresh
      };
    case 'ACTION_SET_FILTER_OPTIONS':
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          propertyOptions: action.propertyOptions,
          staffOptions: action.staffOptions,
          tenantOptions: action.tenantOptions,
          tagOptions: action.tagOptions,
          unreadMessagesOnlyOptions : action.unreadMessagesOnlyOptions
        }
      };
    case 'ACTION_SET_FILTER_FEED_PAGE_NB':
      return {
        ...state,
        filterSelections: {
          ...state.filterSelections,
          pageNb: action.pageNb
        },
        refreshFullFeed: !state.refreshFullFeed
      };
    case 'ACTION_SET_FILTER_SELECTION':
      return {
        ...state,
        filterSelections: action.filterSelections,
        refreshFullFeed: !state.refreshFullFeed // trigger a feed refresh
      };
    case 'ACTION_SET_USER':
      return {
        ...state,
        user: action.user
      };
    case 'ACTION_SET_ACCOUNTID':
      return {
        ...state,
        accountId: action.accountId
      };
    case 'ACTION_SET_ACCOUNTS':
      return {
        ...state,
        accounts: action.accounts
      };
    case 'ACTION_ADD_TAG':
      const newTags = state.tags.reduce((result, tag) => {
        if (tag.Name !== action.tag.Name) {
          result.push(tag);
        }
        return result;
      }, [action.tag]);
      const sorted = R.sort(byName, newTags);
      return {
        ...state,
        tags: sorted
      };
    case 'ACTION_REMOVE_TAG':
      const remainingTags = state.tags.reduce((result, tag) => {
        if (tag.Name !== action.tag.Name) {
          result.push(tag);
        }
        return result;
      }, []);
      const remainingTagsSorted = R.sort(byName, remainingTags);
      return {
        ...state,
        tags: remainingTagsSorted
      };
    case 'ACTION_UPDATE_TAG':
      const updatedTags = state.tags.reduce((result, tag) => {
        if (tag._id !== action.tag._id) {
          result.push(tag);
        } else {
          result.push(action.tag);
        }
        return result;
      }, []);
      const updatedTagsSorted = R.sort(byName, updatedTags);
      return {
        ...state,
        tags: updatedTagsSorted
      };
    case 'ACTION_SET_TAGS':
      return {
        ...state,
        tags: R.sort(byName, action.tags)
      };
    case 'ACTION_SET_USERFULLNAME':
      return {
        ...state,
        userFullName: action.userFullName
      };
    case 'ACTION_SET_USERROLE':
      return {
        ...state,
        userRole: action.userRole
      };
    case 'ACTION_SET_DEMOMODE':
      return {
        ...state,
        isDemoMode: action.demoMode
      };
    case 'ACTION_SET_AGREEMENT':
      return {
        ...state,
        agreement: action.agreement
      };
    case 'ACTION_SET_SNACK_MSG':
      return {
        ...state,
        snackMessage: action.snackMessage
      };
    case 'ACTION_SET_EXPORT_CSV_REQUESTS':
      return {
        ...state,
        exportCSVRequests: action.exportCSVRequests
      };
    case 'ACTION_SET_PRINT_REQUESTS':
      return {
        ...state,
        printRequests: action.printRequests
      };
    case 'ACTION_SET_NUMBER_OF_DAYS':
      return {
        ...state,
        numberOfDays: action.numberOfDays
      };
    case 'ACTION_SET_I18N':
      return {
        ...state,
        i18n: action.i18n
      };
    case 'ACTION_SET_TENANTS_ENABLE_UPLOAD':
      return {
        ...state,
        tenantEnableUpload: action.tenantEnableUpload
      };
    case 'ACTION_SET_DEFAULT_BUILDING':
      return {
        ...state,
        defaultBuilding: action.defaultBuilding 
      };
    case 'ACTION_SET_DEFAULT_BUILDING_PHONE':
      return {
        ...state,
        defaultBuilding: {
          ...state.defaultBuilding,
          Phone: action.Phone
        }
      };
    case 'ACTION_SET_PAGE_NB':
      return {
        ...state,
        tenantsPageInfo: {
          ...state.tenantsPageInfo,
          pageNum: action.pageNum
        }
      };
    case 'ACTION_SET_SORT_ORDER':
      return {
        ...state,
        tenantsPageInfo: {
          ...state.tenantsPageInfo,
          sortOrder: action.sortOrder
        }
      };
    case 'ACTION_SET_SORT_BY':
      return {
        ...state,
        tenantsPageInfo: {
          ...state.tenantsPageInfo,
          sortBy: action.sortBy
        }
      };
    case 'ACTION_SET_TENANTS':
    return {
      ...state,
      tenants: action.tenants
    };
    case 'ACTION_UPDATE_KEYWORD_TRIGGERS':
      return {
        ...state,
        keywords: action.keywords
      };
    case 'ACTION_SET_UPGRADE_POPUP':
      return {
        ...state,
        openPopups: {
          ...state.openPopups,
          upgradePlan: action.open
        }
      };
    case 'ACTION_SET_RECHARGE_POPUP':
      return {
        ...state,
        openPopups: {
          ...state.openPopups,
          rechargeAccount: action.open
        }
      };
    default:
      log('[reducer] default - ERROR? or return state?');
      throw new Error();
  }
};

export default reducers;