// @flow

import React,{ useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';

import { log, capitalizeFirstLetter } from '../../utils/jsUtils';
import { useStore } from '../../store';
import RemovableModalImage from '../SharedComponents/RemovableModalImage';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/TaskV2Activity.Style';


const TaskV2Activity = (props: Props) => {
  const {
    classes,
    history,
    tenant
  } = props;
  const [store] = useStore();
  const {
    i18n
  } = store;

  const [openActiveZoom, setOpenActiveZoom] = useState(false);
  const [activeZoomUrl, setActiveZoomUrl] = useState('');

  const handleOpenZoomAttachment = (attachmentUrl) => () => {
    setActiveZoomUrl(attachmentUrl);
    setOpenActiveZoom(true);
  };
  const handleCloseZoomAttachment = () => {
    setOpenActiveZoom(false);
    setActiveZoomUrl('');
  };

  // eslint-disable-next-line react/no-multi-comp
  const AttachmentZoomDialog = (props) => {
    const {
      open,
      activeZoomUrl,
      onClose
    } = props;

    return (
      <Dialog aria-labelledby="attachment-zoom"
        classes={{
          paper: classes.attachmentImgZoomPaper
        }}
        onClose={() => onClose()}
        open={open}
      >

        <IconButton
          aria-label="Close"
          className={classes.zoomCloseBtn}
          color="inherit"
          key="close"
          onClick={onClose}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
        <img className={classes.attachmentImgZoom}
src={activeZoomUrl}
        />
      </Dialog>
    );
  };
  
  return (
    <>
    {/* {
      tenant
      ? <Typography variant='h6'>
        {
          tenant.FirstName === 'Guest'
          ? <div className={classes.senderName}>
              <b>
              {`${tenant.FirstName} (${formatPhoneNumberForFrontend(tenant.Phone)})`}
              </b>
            </div>
          : <b>
            {`${tenant.FirstName} ${tenant.LastName}`}
            </b>
        }
        </Typography>
      : null
    } */}
    {
      tenant
      ? <div className={classes.messageThread}>
          {
            history
            ? history.map((entry, entryIdx) => (
              <div key={entryIdx}>
              {
                entry.type === 'unread'
                ? <div className={classes.newMsgIndicatorCtr}>
                  <Typography
                    className={classes.newMsgIndicator}
                    variant='body1'
                  >{'NEW'}</Typography>
                  </div>
                : null
              }
              
              {
                entry.type === 'message'
                ? <div 
                    className={classNames(
                      classes.messageLineCtr,
                      entry.SenderPersona === 'customer' 
                        ? classes.messageLineCtrCustomer
                        : classes.messageLineCtrSupport
                      )
                    }
                    key={`history-${entryIdx}`}
                  >
                    <div className={classes.messageCaptionCtr}>
                      <Typography
                        className={classes.msgCaption}
                        component='p'
                        variant='body1'
                      >
                        {
                          entry.SenderUserFullName && !entry.SenderUserFullName.startsWith('undefined')
                          ? `${entry.SenderUserFullName.trim()}, `
                          : `${capitalizeFirstLetter(entry.SenderPersona)}, `
                        }
                        {
                          moment().diff(entry.CreatedDt, 'days') > 7
                          ? moment(entry.CreatedDt).format('L')
                          : moment(entry.CreatedDt).from(moment())
                        }
                      </Typography>
                      <div className={classes.deliveryIconCtr}>
                      {
                        entry.SMSStatus
                        ? entry.SMSStatus.MessageStatus === 'delivered'
                          ? <Tooltip title={'Message delivered'}>
                              <DoneIcon className={classes.deliveryIconDelivered}/>
                            </Tooltip>
                          : entry.SMSStatus.MessageStatus === 'undelivered'
                            || entry.SMSStatus.MessageStatus === 'failed'
                            ? <Tooltip title={'Message delivery failed'}>
                                <SmsFailedIcon className={classes.deliveryIconFailed}/>
                              </Tooltip>
                            : entry.SMSStatus.MessageStatus === 'error'
                              ? <Tooltip title={entry.SMSStatus.ErrorMessage}>
                                <SmsFailedIcon className={classes.deliveryIconFailed} />
                              </Tooltip>
                              : <Tooltip title={'Sending...'}>
                                <DoneIcon className={classes.deliveryIconSending} />
                              </Tooltip>
                        : null
                      }
                      </div>
                    </div>

                    {
                      entry && entry.Attachments && entry.Attachments.length > 0
                      ? <Grid
                          className={classNames(
                            classes.attachmentRowCtr
                          )}
                          item
                        >
                        {
                          entry.Attachments.map((attachment, attIndex) => {
                            return (<>
                              <RemovableModalImage 
                                invert
                                key={`img-${entry._id}-${attIndex}`}
                                largeImageUrl={attachment.Url}
                                smallImageUrl={attachment.Url}
                              />
                              {/* <img 
                                
                                key={`img-${entry._id}-${attIndex}`}
                                onClick={handleOpenZoomAttachment(attachment.Url)}
                                src={attachment.Url}
                              /> */}</>
                            );
                          })
                        }
                        </Grid>
                      : null
                    }
                    <Paper
                      className={classNames(
                        classes.messageCtr,
                        entry.SenderPersona === 'customer' ? classes.customerMsg : classes.supportMsg
                      )}
                    >
                      <Typography
                        className={classes.messageContent}
                        component='p'
                        variant='h6'
                      >
                        {entry.Message}
                      </Typography>
                    </Paper>
                  </div>
                : null
              }
              {
                entry.type === 'log'
                ? <Paper
                    className={classes.logCtr}
                    key={`history-${entryIdx}`}
                  >
                    {/* <Typography variant="h6" component="h1">
                      {entry.Message}
                    </Typography> */}
                    <Typography>
                      {`${entry.Comment} `}  
                      {
                        moment().diff(entry.CreatedDt, 'days') > 30
                        ? moment(entry.CreatedDt).format('L')
                        : moment(entry.CreatedDt).from(moment())
                      }
                    </Typography>
                  </Paper>
                : null
              }
              </div> 
              ))
            : null
          }
        </div>
      : <Typography style={{transform: 'rotate(180deg)'}}
          variant='h6'
        >
        <b>
          {`${i18n.t('Tenant')} has been removed.`}
        </b>
      </Typography>
    }
    </>
  );
};

export default withStyles(styles)(TaskV2Activity);