// @flow

const COL_ADDRESS_WIDTH = 300;
const COL_OTHER_WIDTH = 150;
const COL_NB_WIDTH = 80;
const COL_STAFF_WIDTH = 125;
const COL_ACTION_WIDTH = 225;

const styles = (theme: Object) => ({
  colAction: {
    width: COL_ACTION_WIDTH
  },
  addressCell: {
    flex: '1 1 auto',
    flexBasis: COL_ADDRESS_WIDTH,
    cursor: 'pointer'
  },
  nbCell: {
    flex: '0 0 auto',
    minWidth: COL_NB_WIDTH,
    maxWidth: COL_NB_WIDTH
  },
  staffCell: {
    flex: '0 0 auto',
    minWidth: COL_STAFF_WIDTH,
    maxWidth: COL_STAFF_WIDTH
  },
  webBotCell: {
    flex: '0 0 auto',
    justifyContent: 'center',
    minWidth: COL_STAFF_WIDTH,
    maxWidth: COL_STAFF_WIDTH
  },
  otherCell: {
    flex: '0 0 auto',
    minWidth: COL_OTHER_WIDTH,
    maxWidth: COL_OTHER_WIDTH
  },
  actionCell: {
    flex: '0 0 auto',
    minWidth: theme.shape.borderRadius,
    paddingRight: 0,
    paddingLeft: 0,
    width: 'auto'
  },
  customTableStyles: {
    '& .underActionCell': {
      flex: '0 0 auto',
      minWidth: COL_ACTION_WIDTH,
      maxWidth: COL_ACTION_WIDTH,
      paddingRight: 0,
      paddingLeft: 0,
      width: 'auto'
    },
    '& .hover .underActionCell.twoBtns': {
      flex: '1 1 auto',
      minWidth: 0,
      maxWidth: COL_ACTION_WIDTH - (theme.shape.borderRadius * 2 * 2),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 'auto'
    }
  },
  resultsCount: {
    color: '#5b5b5b',
    fontSize: '0.8rem',
    marginBottom: '1rem',
    paddingLeft: theme.spacing(1)
  }
});

export default styles;