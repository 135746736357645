// @flow

import React, { useState, useEffect } from 'react';
import { useStore } from '../../store';
import * as R from 'ramda';
import classNames from 'classnames';
import { log } from '../../utils/jsUtils';
import Downshift from 'downshift';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/AddTenantDialog.Style';
import SPBackend from '../../services/SPBackend';
import TagRenderer from '../SharedComponents/TagRenderer';
import AutocompleteProperty from '../Properties/AutocompleteProperty';
import type { Unit } from '../../services/SPBackend';

type Props = {
  handleEditTenant: Function, // if prop missing, component switches in create tenant only mode.
  classes: Object,
  setOpen: Function,
  open: boolean,
  triggerAction: Function,
  unit: Unit
};

const CREATE_NEW_TENANT = 'Create new tenant';

const renderInput = (_inputProps) => {
  const {
    InputProps,
    classes,
    setSelectedAutocompleteVal,
    openMenu,
    ...other
  } = _inputProps;

  setSelectedAutocompleteVal(InputProps.value);
  return (
    <TextField
      InputProps={{
        // inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput
        },
        ...InputProps
      }}
      onFocus={openMenu}
      {...other}
    />
  );
};

// eslint-disable-next-line react/no-multi-comp
const SuggestedList = (props) => {
  const {
    inputValue,
    user,
    getItemProps,
    i18n
  } = props;
  log('[SuggestedList] inputValue: ', inputValue);
  const value = inputValue.trim().toLowerCase();
  const [store] = useStore();
  const {
    tenantsPageInfo
  } = store;

  const [tenants, setTenants ] = useState([]);
  const [tenantsNextPage, setTenantsNextPage] = useState(null);
  const [ countOfTenants, setCountOfTenants] = useState(0);

  // \\log('[SuggestedList] itemProps: ', itemProps);
  useEffect(() => {
    if (user !== null && value.length >= 0) {
      log('[SPBackend.findTenants]...');
      const findTenantsPayload = {
        search: value,
        tenantsPageInfo
      };
      SPBackend.findTenants(findTenantsPayload, user)
        .then(response => {
          log('[findTenants] response: ', response);
          const { tenants, tenantsNextPage, tenantsCount } = response.data;
          setTenants(tenants);
          setTenantsNextPage(tenantsNextPage);
          setCountOfTenants(tenantsCount);
      })
      .catch(err => {
        log('sentry error: ', err);
      });
    } else {
      setTenants([]);
    }
    // return function cleanup() {

    // }
  }, [inputValue]);


  return (
    !tenants || tenants.length === 0
    ? <MenuItem
        {...getItemProps({item: i18n.t(CREATE_NEW_TENANT)})}
        component='div'
        key={'createTenant'}
      >
        {i18n.t(CREATE_NEW_TENANT)}
      </MenuItem>
    : [
        tenants.map((tenant) => {
          return (
            <MenuItem
              {...getItemProps({item: tenant})}
              component='div'
              key={tenant._id}
              // selected={isHighlighted}
            >
              {`${tenant.FirstName} ${tenant.LastName}`}
            </MenuItem>
          );
        }),
        <MenuItem
          {...getItemProps({item: i18n.t(CREATE_NEW_TENANT)})}
          component='div'
          key={'createTenant'}
        >
          {i18n.t(CREATE_NEW_TENANT)}
        </MenuItem>
      ]
  );
};

// eslint-disable-next-line react/no-multi-comp
const AddTenant = (props: Props) => {
  const {
    classes,
    setOpen,
    open,
    triggerAction,
    handleEditTenant,
    unit
  } = props;

  const [store] = useStore();
  const {
    i18n,
    user,
    defaultUnit,
    tags
  } = store;

  const [loading, setLoading] = useState(false);
  const [tenant, setTenant] = useState({
    _id: null,
    FirstName: '',
    LastName: '',
    Phone: '',
    Email: '',
    Tags: []
  });
  const [newTenant, setNewTenant] = useState({
    FirstName: '',
    LastName: '',
    Phone: '',
    Email: '',
    // Keys: defaultUnit ? [defaultUnit.RoomKey] : unit ? [unit.RoomKey] : [],
    Tags: []
  });

  const [selectedAutocompleteVal, setSelectedAutocompleteVal] = useState(null);
  const [showCreateTenantForm, setShowCreateTenantForm] = useState(false);


  useEffect(() => {
    log('[effect.selectedAutocompleteVal]: ', selectedAutocompleteVal);
    if (selectedAutocompleteVal === (i18n.t(CREATE_NEW_TENANT))
      || typeof handleEditTenant === 'undefined') {
      setShowCreateTenantForm(true);
    } else {
      setShowCreateTenantForm(false);
    }
  }, [selectedAutocompleteVal]);

  useEffect(() => {
    if (defaultUnit) {
      setNewTenant({
        ...newTenant
        // Keys: defaultUnit ? [defaultUnit.RoomKey] : []
      });
    } else {
      setNewTenant({
        ...newTenant
        // Keys: unit ? [unit.RoomKey] : []
      });
    }
  }, [unit, defaultUnit]);

  const emptyForm = () => {
    setNewTenant({
      ...newTenant,
      FirstName: '',
      LastName: '',
      Phone: '',
      Email: '',
      Tags: []
    });
  };

  const callbackOnTagPicked = (params) => {
    log('[callbackOnTagPicked] params: ', params);
    setNewTenant({
      ...newTenant,
      Tags: R.append(params._id, newTenant.Tags)
    });
  };

  return (
    <Dialog aria-labelledby='form-add-unit'
      onClose={() => {setOpen(false); emptyForm();}}
      open={open}
    >
      <form
        autoComplete='off'
        className={classNames(
          classes.dialogFormCtr,
          !unit ? 'xlarge' : showCreateTenantForm ? 'large' : 'normal'
        )}
        noValidate
        onSubmit={e => {
          e.preventDefault();
          setLoading(true);
          triggerAction(newTenant,
            () => {
              setLoading(false);
              setOpen(false);
              emptyForm();
            },
            () => {
              setLoading(false);
            }
          );
        }}
      >
        <DialogTitle id='form-dialog-title'>{`Add a ${i18n.t('tenant')}`}</DialogTitle>
        <DialogContent className={classNames(classes.grow, classes.flexContainer)}>
          {
            typeof handleEditTenant !== 'undefined'
            ? <div id='searchTenant'>
              <Downshift 
                id='downshift-simple'
                itemToString={item => item && item !== (i18n.t(CREATE_NEW_TENANT)) ? 
                `${item.FirstName} ${item.LastName}` : i18n.t(CREATE_NEW_TENANT)}
                onSelect={(selectedTenant) => setTenant(selectedTenant)}
              >
                {({
                  getInputProps,
                  getItemProps,
                  getMenuProps,
                  highlightedIndex,
                  inputValue,
                  isOpen,
                  openMenu
                }) => (
                  <div className={classes.container}>
                    {renderInput({
                      ref: 'test',
                      fullWidth: true,
                      classes,
                      InputProps: getInputProps({
                        placeholder: `Search ${i18n.t('a tenant')}`
                      }),
                      openMenu,
                      setSelectedAutocompleteVal
                    })}
                    <div {...getMenuProps()}>
                      {isOpen ? (
                        <Paper
                          className={classes.paper}
                          square
                        >
                          <SuggestedList
                            getItemProps={getItemProps}
                            i18n={i18n}
                            inputValue={inputValue}
                            user={user}
                          />

                          {/* {getSuggestions(inputValue, user).map((suggestion, index) =>
                            renderSuggestion({
                              suggestion,
                              index,
                              itemProps: getItemProps({ item: suggestion.label }),
                              highlightedIndex,
                              selectedItem
                            })
                          )} */}
                        </Paper>
                      ) : null}
                    </div>
                  </div>
                )}
              </Downshift>
            </div>
            : null
          }
          {showCreateTenantForm ? (
            <div id='createTenant'>
              <Input
                autoFocus
                classes={{
                  root: classes.secondInput
                }}
                fullWidth
                id='firstName'
                label='First Name'
                margin='dense'
                onChange={e =>
                  setNewTenant({
                    ...newTenant,
                    FirstName: e.target.value
                  })
                }
                placeholder='First name'
                type='text'
                value={newTenant.FirstName}
              />
              <Input
                classes={{
                  root: classes.secondInput
                }}
                fullWidth
                id='lastName'
                label='Last Name'
                margin='dense'
                onChange={e =>
                  setNewTenant({
                    ...newTenant,
                    LastName: e.target.value
                  })
                }
                placeholder='Last name'
                type='text'
                value={newTenant.LastName}
              />
              <Input
                classes={{
                  root: classes.secondInput
                }}
                fullWidth
                id='email'
                label='Email'
                margin='dense'
                onChange={e =>
                  setNewTenant({
                    ...newTenant,
                    Email: e.target.value
                  })
                }
                placeholder='Email'
                type='email'
                value={newTenant.Email}
              />
              <PhoneInput
                className={classNames(classes.phoneInputCtr, 'defaultInput')}
                defaultCountry='US'
                onChange={e =>
                  setNewTenant({
                    ...newTenant,
                    Phone: e
                  })
                }
                placeholder="Enter phone number"
                value={formatPhoneNumberIntl(newTenant.Phone)}
              />

              <TagRenderer
                // callbackOnHideTagsPicker={callbackOnHideTagsPicker}
                // callbackOnShowTagsPicker={callbackOnShowTagsPicker}
                callbackOnTagPicked={callbackOnTagPicked}
                // callbackOnTagRemoved={callbackOnTagRemoved(request.lookupTenant._id)}
                tagsIds={newTenant.Tags}
              />

              {
                !defaultUnit && !unit
                ? <div className={classes.secondInput}>
                  <AutocompleteProperty 
                    setUnit={(unit:Unit)=> {
                      setNewTenant({
                        ...newTenant
                        // Keys: [unit.RoomKey]
                      });
                    }}
                  />
                </div>
                : null
              }
            </div>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            onClick={() => {setOpen(false); emptyForm();}}
          >
            {'Cancel'}
          </Button>
          
          {
            showCreateTenantForm 
            ? <Button
                color='primary'
                disabled={loading}
                type='submit'
                variant='contained'
              >
                {`Add ${i18n.t('tenant')}`}
              </Button>
            : <Button
                color='primary'
                onClick={() => {setOpen(false); handleEditTenant(tenant); emptyForm();}}
                variant='contained'
              >
                {`Assign ${i18n.t('tenant')}`}
              </Button>
          }
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(AddTenant);
