// @flow

const COL_GROW_WIDTH = 200;
const COL_FIXED_WIDTH = 350;

const styles = (theme: Object) => ({
  basicMargin: {
    marginLeft: theme.spacing(2)
  },
  growCell: {
    flex: '1 1 auto',
    flexBasis: COL_GROW_WIDTH
  },
  fixedCell: {
    flex: '0 0 auto',
    minWidth: COL_FIXED_WIDTH,
    maxWidth: COL_FIXED_WIDTH
  },
  expandable: {
    position: 'relative',
    paddingLeft: 20,
    cursor: 'pointer',
    '& i': {
      position: 'absolute',
      fontSize: '1.1rem',
      left: -6,
      top: -4
    },
    '&.expanded i': {
      transition: 'transform 0.5s',
      transform: 'rotate(180deg)'
    }
  }
});

export default styles;
