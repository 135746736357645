// @flow

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { log } from '../utils/jsUtils';
import { withStyles } from '@material-ui/core/styles';
import theme from '../theme';

const styles = {
  placeholderSpan: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  emptyPlaceholderSpan: {
    // paddingLeft: '8px',
    color: 'hsl(0, 0%, 60%)'
  },
  valueContainer: {
    display: 'flex !important',
    alignItems: 'center'
  }
};

const customStyles = (position, width, maxWidth) => ({
  option: (provided, state) => ({
    ...provided,
    fontSize: '0.9rem',
    color: 'black',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem'
    }
  }),
  container: (provided, state) => ({
    // background: 'green',
    '&:first-child': {
      paddingLeft: 8
    }
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: '0.9rem',
    color: 'hsl(0,0%,60%)',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem'
    }
  }),
  groupHeading: (provided, state) => ({
    ...provided
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none'
  }),
  control: (styles, state) => ({
    ...styles,
    fontSize: '0.9rem',
    width: width,
    maxWidth: maxWidth,
    borderWidth: 0,
    // none of react-select's styles are passed to <Control />
    boxShadow: 'none',
    borderColor: 'transparent',
    '&:hover, &:active, &:focus': {
      borderColor: 'transparent',
      borderWidth: 0,
      boxShadow: 'none'
    },
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem'
    }
  }),
  input: (styles) => ({
    ...styles,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }),
  clearIndicator:  (provided, state) => ({
    ...provided,
    padding: '0 0'
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    height: '100%',
    paddingLeft: 0,
    borderRight: position !== 'last' ? `2px solid ${theme.palette.primary.light}` : ''
  }),
  menu: (styles) => ({ // options container
    ...styles,
    backgroundColor: 'white',
    minWidth: width,
    width: 'auto',
    maxWidth: '80vw'
  }),
  menuList: (styles) => ({
    ...styles,
    zIndex: '99'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
  valueContainer: (styles) => ({
    display: 'block',
    paddingLeft: 8
  })
});

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <input checked={props.isSelected}
          onChange={() => null}
          type="checkbox"           
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
    );
};

// eslint-disable-next-line react/no-multi-comp
const MultiValue = props => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

// eslint-disable-next-line react/no-multi-comp
const ValueContainer = withStyles(styles)(({ children, ...props }) => { 
  const { classes } = props; 
  return (
    <components.ValueContainer {...props}
      className={classes.valueContainer}
    >
      { 
        props
        && props.selectProps
        && props.selectProps.value 
        && props.selectProps.value.length > 0
        ? <span><span className={classes.placeholderSpan}>{props.selectProps.placeholder} </span>({props.selectProps.value.length})</span>
        : <span className={classes.emptyPlaceholderSpan}>{props.selectProps.placeholder}</span>
      }
      {children[1]}
    </components.ValueContainer>
  );
});

// eslint-disable-next-line react/no-multi-comp
const FilterSelect = (props) => {
  const {
    options,
    onChangeCallback,
    position,
    width = 160,
    maxWidth = '29vw',
    ...otherProps
  } = props;
  
  return (
    <Select
      backspaceRemovesValue={false}
      closeMenuOnSelect={false}
      components={{ 
        Option,
        MultiValue,
        ValueContainer }}
      hideSelectedOptions={false}
      isMulti
      onChange={e => onChangeCallback(e)}
      options={options}
      styles={customStyles(position, width, maxWidth)}
      {...otherProps}
    />
  );
};

export default withStyles(styles)(FilterSelect);
