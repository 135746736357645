// @flow

import React from 'react';
import { useStore } from '../../store';
import { log } from '../../utils/jsUtils';
import moment from 'moment';
import classNames from 'classnames';
// import ModalImage from '../../custom_libs/react-modal-image';
import Linkify from 'react-linkify';

import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallReceivedIcon from '@material-ui/icons/CallReceived';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/RequestContent.Style';
import RemovableModalImage from '../SharedComponents/RemovableModalImage';

import { REF_STATUSES, REF_CHANNELS, translateStatusToHuman } from '../../models/Request';

type PropsSummary = {
  classes: Object,
  firstEntry: boolean,
  summary: Object,
  chat: Object,
  chatIndex: number,
  key: string
}

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    borderBottom: '1px solid rgba(0,0,0,.125)',
    marginBottom: -1,
    minHeight: 30,
    '&$expanded': {
      minHeight: 56,
      borderBottom: 0
    }
  },
  content: {
    margin: '4px 0',
    '&$expanded': {
      margin: '4px 0'
    }
  },
  expanded: {}
})(props => <MuiExpansionPanelSummary {...props} />);

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

const _translateToHuman = (source) => {
  // log('[_translateToHuman] source: ', source);
  if (source === REF_CHANNELS.SMS) {
    return 'texted';
  } else if (source === REF_CHANNELS.CALL) {
    return 'called';
  } else if (source === REF_CHANNELS.SQUAREP) {
    return 'chatted on the Web';
  } else if (source === REF_CHANNELS.CONSOLE) {
    return 'used the console';
  }
};

type PropsTrl = {
  classes: Object,
  firstEntry: boolean,
  summary: Object,
  chat: Object
};
const TranslateReqSourceToHuman = (props: PropsTrl) => {
  const {
    classes,
    chat,
    firstEntry,
    summary
  } = props;

  const [store] = useStore();
  const {
    i18n
  } = store;

  let sourceTrl = null;
  if (typeof summary !== 'undefined' && summary !== null) {
    const sourcePattern = 'Source: ';
    try {
      summary.split(';').forEach((line) => {
        // log('[translateReqSourceToHuman] line: ', line);
        if (line.includes(sourcePattern)) {
          const source = line.replace(sourcePattern, '').trim().toLowerCase();
          // log('[translateReqSourceToHuman] source: ', source);
          sourceTrl = _translateToHuman(source);
        }
      });
    } catch (e) {
      log(e);
    }
  }
  // log('[translateReqSourceToHuman] firstEntry: ', firstEntry);
  // log('[translateReqSourceToHuman] resulting source: ', sourceTrl);
  return (
    <ListItem
      alignItems="flex-start"
      className={classNames(classes.listItem, classes.noLeftPadding)}
    >
      <Hidden smDown>
        {
          sourceTrl
            ? <CallReceivedIcon className={classes.eventIcon} />
            : <CallMadeIcon className={classes.eventIcon} />
        }
      </Hidden>
      <ListItemText
        primary={
          <React.Fragment>
            <div className={classes.requestHistoryEntryCtr}>
              <Typography
                className={classes.requestHistoryEntryTitle}
                color="textPrimary"
                component="span"
              >
                {
                  sourceTrl
                    ? firstEntry
                      ? <span>{chat.FirstName} {chat.LastName} <b>{sourceTrl}</b> {'to submit this request.'} <i className={classNames('icon-notstarted', classes.statusIconsColors, 'pending')} /></span>
                      : <span>{chat.FirstName} {chat.LastName} <b>{sourceTrl}</b> {'for a similar request.'} <i className={classNames('icon-notstarted', classes.statusIconsColors, 'pending')} /></span>
                    : chat.Status.toLowerCase() === REF_STATUSES.CURRENT
                      ? <span>{chat.FirstName} {chat.LastName} <b>{'marked'}</b> {'this request as'} <b>{translateStatusToHuman[REF_STATUSES.CURRENT]}</b> <i className={classNames('icon-progress', classes.statusIconsColors, 'current')} /></span>
                      : chat.Status.toLowerCase() === REF_STATUSES.NOTE
                        ? <span>{chat.FirstName} {chat.LastName} <b>{'sent'}</b> {`${i18n.t('a reply')}.`} <i className={classNames('icon-sent', classes.statusIconsColors, 'info')} /></span>
                        : chat.Status.toLowerCase() === REF_STATUSES.RENAME
                          ? <span>{chat.FirstName} {chat.LastName} <b>{'renamed'}</b>{' this request to '}<b>{chat.Comment}.</b></span>
                          : chat.Status.toLowerCase() === REF_STATUSES.TAG
                            ? <span>{chat.FirstName} {chat.LastName} <b>{'tagged'}</b>{' this request as '}<b>{chat.Comment}</b></span>
                            : chat.Status.toLowerCase() === REF_STATUSES.INFO
                              ? <span>{chat.FirstName} {chat.LastName} <b>{'sent'}</b> {'a broadcast.'} <i className={classNames('icon-sent', classes.statusIconsColors, 'info')} /></span>
                              : chat.Status.toLowerCase() === REF_STATUSES.FINISHED
                                ? <span>{chat.FirstName} {chat.LastName} <b>{'marked'}</b> {'this request as'} <b>{translateStatusToHuman[REF_STATUSES.FINISHED]}</b> <i className={classNames('icon-finished', classes.statusIconsColors, 'finished')} /></span>
                                : <span>{chat.FirstName} {chat.LastName} <b>{'marked'}</b> {'this request as'} <b>{chat.Status.toLowerCase()}.</b></span>
                }
              </Typography>

            </div>
          </React.Fragment>
        }
      />
      <ListItemSecondaryAction
        classes={{
          root: classes.listSecondaryActionCtr
        }}
      >
        {
          chat && chat.Attachments && chat.Attachments.length > 0
          ? <div className={classes.attachmentSmallCtr}>
          {
            chat.Attachments.map((attachment, attIndex) => {
              return (
                <img
                  alt=''
                  className={classNames(classes.attachment, classes.attachmentSmall)}
                  key={`att-${attIndex}`}
                  src={attachment.Url}
                />
              );
            })
          }
          </div>
          : null
        }
        {
          moment().diff(chat.Date, 'days') > 3
            ?
            <Typography
              className={classes.requestHistoryTimestamp}
              color="textPrimary"
              component="div"
            >{moment(chat.Date).format('MMMM Do YYYY, h:mm:ss a')}</Typography>
            :
            <Typography
              className={classes.requestHistoryTimestamp}
              color="textPrimary"
              component="div"
            >{moment(chat.Date).fromNow()}</Typography>
        }
      </ListItemSecondaryAction>
    </ListItem>

  );
};

const StyledTranslateReqSourceToHuman = withStyles(styles)(TranslateReqSourceToHuman);

// eslint-disable-next-line react/no-multi-comp
const RequestHistoryEntry = (props: PropsSummary) => {
  const { summary, chat, classes, firstEntry, key } = props;
  log('[RequestHistoryEntry] summary: ', summary);
  log('[RequestHistoryEntry] chat: ', chat);
  return (
    <ExpansionPanel
      className={classes.expansionPanel}
      key={key}
    >
      <ExpansionPanelSummary 
        className={classNames(summary ? null : classes.disabledExpansion)}
        classes={{
          root: classes.expansionPanelSummaryRoot,
          content: classes.expansionPanelSummary
        }}
        expandIcon={summary ? <ExpandMoreIcon /> : null}
      >
        <StyledTranslateReqSourceToHuman
          chat={chat}
          firstEntry={firstEntry}
          summary={summary}
        />
      </ExpansionPanelSummary>
      {
        summary && summary.length > 0
        ? <ExpansionPanelDetails
            classes={{
              root: classes.ExpansionPanelDetailsRoot
            }}
          >
          <Grid container >
            <Grid 
              className={classes.chatListRootCtr}
              item 
            >
              <List className={classes.chatListRoot}>
              {
                summary.split(';').map((line, lineIndex) => {
                  const dialogPattern = 'Dialog';
                  const botPattern = 'Bot: ';
                  const userPattern = 'User: ';
                  if (line.includes(dialogPattern)) {
                    /* top margin between chat transcript and header metadata */
                    return line && line.length > 0
                      ? <ListItem 
                          alignItems="flex-start"
                          className={classes.beforeChat}
                          dense
                          disableGutters
                          key={`chat-${chat.id}-${lineIndex}`}
                        >
                        {
                          chat && chat.Attachments && chat.Attachments.length > 0
                          ? <Grid
                            className={classes.attachmentRowCtr}
                            item
                            >
                            {
                              chat.Attachments.map((attachment, attIndex) => {
                                return (
                                  <div>
                                  <RemovableModalImage 
                                    key={`att-${attIndex}`}
                                    largeImageUrl={attachment.Url}
                                    smallImageUrl={attachment.Url}
                                  />
                                  </div>
                                );
                              })
                            }
                            </Grid>
                          : null
                        }
                        </ListItem>
                      : null;
                  } else if (line.toLowerCase().includes(botPattern.toLowerCase()) || line.toLowerCase().includes(userPattern.toLowerCase())) {
                    /* Chat dialog */
                    const isBotSplit = line.split(botPattern);
                    const isUserSplit = line.split(userPattern);
                    return (
                      <ListItem 
                        alignItems="flex-start"
                        className={classNames(
                          classes.chatListItem,
                          'chatListItem',
                          summary.split(';')[lineIndex - 1].includes(dialogPattern)
                            ? 'first'
                            : (!summary.split(';')[lineIndex + 1].includes(botPattern)
                              && !summary.split(';')[lineIndex + 1].includes(userPattern))
                              ? 'last'
                              : null
                        )}
                        dense
                        key={`chat-${chat.id}-${lineIndex}-header`}
                      >
                        <ListItemText
                          classes={{
                            root: classes.historyEntrySummary
                          }}
                          primary={
                            <React.Fragment>
                              <Typography
                                color="textPrimary"
                                component="span"
                              >
                                <span style={{fontWeight: 'bold'}}>
                                {
                                  isBotSplit.length > 1
                                  ? 'Bot'
                                  : 'User'
                                }
                                </span>
                              </Typography>
                              {/* {moment(chat.Date).format('MMMM Do YYYY, h:mm:ss a')} */}
                            </React.Fragment>
                          }
                          secondary={
                            isBotSplit.length > 1
                              ? isBotSplit[1]
                              : <Linkify properties={{target: '_blank'}}>{isUserSplit[1]}</Linkify>
                          }
                        />
                      </ListItem>
                    );
                  } else {
                    /* Top Transcript Summary (metadata) */
                    return line && line.length > 0
                    ? <ListItem 
                        alignItems="flex-start"
                        classes={{
                          root: classNames(classes.historyEntrySummary, 'hover')
                        }}
                        dense
                        disableGutters
                        key={`chat-${chat.id}-${lineIndex}-transcript`}
                      >
                      <ListItemText
                          primary={
                            chat.Status.toLowerCase() === REF_STATUSES.NOTE
                            || chat.Status.toLowerCase() === REF_STATUSES.RENAME
                            ? <pre
                                className={classNames(
                                  classes.preCtr,
                                  classes.chatListItem,
                                  'chatListItem',
                                  'first',
                                  'last'
                                )}
                                key={`chat-${chat.id}-${lineIndex}-0`}
                              >
                                {line}
                              </pre>
                            : <span>
                              <span 
                                className={classNames('hover', classes.historyEntrySummaryTitle)}
                                key={`chat-${chat.id}-${lineIndex}-0`}
                              >
                                {line.split(':') ? line.split(':')[0] : null}
                              </span>
                              <Linkify properties={{target: '_blank'}}>
                                <span 
                                  key={`chat-${chat.id}-${lineIndex}-1`}
                                >
                                  {line.split(':').slice(1) ? line.split(':').slice(1).join(':') : null}
                                </span>
                              </Linkify>
                            </span>
                          }
                      />
                    </ListItem>
                    : null;
                  }
                }
              )
            }
                            
            </List>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
      : null
    }
  </ExpansionPanel>
  );
};

export default withStyles(styles)(RequestHistoryEntry);