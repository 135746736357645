// @flow

import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/PublicFooter.Style';

type Props = {
  classes: Object
};

const HomePublic = (props: Props) => {
  const { classes } = props;
  const history = useHistory();
  const redirectTo = (url) => () => {
    history.push(url);
  };

  return (
    <Grid
      className={classes.root}
      container
      direction={'row'}
    >
      {/* <Grid
        align='center'
        className={classNames(classes.cta, classes.register)}
        item
        xs={6}
      >
        <Button
          className={classNames(classes.cta, classes.register)}
          color="primary"
          onClick={redirectTo('/register')}
        >
          {'Join'}
        </Button>
      </Grid> */}
      <Grid
        align='center'
        className={classNames(classes.cta, classes.signin)}
        item
        xs={12}
      >
        <Button 
          className={classNames(classes.cta, classes.signin)}
          color="primary"
          onClick={redirectTo('/login')}
        >
          {'Sign in'}
        </Button>
        
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(HomePublic);