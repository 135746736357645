// @flow

import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import classNames from 'classnames';
import { useStore } from '../../store';
import {
  log,
  formatPhoneNumberForBackend,
  formatPhoneNumberForFrontend,
  validateEmail
} from '../../utils/jsUtils';
import modelUtils from '../../utils/modelUtils';
import TenantsDropzone from './TenantsFileDrop';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Hidden from '@material-ui/core/Hidden';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBox';
import RemoveIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { calculateCreditsForMsg } from '../../models/Message';
import TagRenderer from '../SharedComponents/TagRenderer';
import ListTableRow from '../ListTableRow';
import MessageTenantDialog from './MessageTenantDialog';
import RemoveTenantDialog from './RemoveTenantDialog';
import EditTenantDialog from './EditTenantDialog';
import ConfirmTenant from './ConfirmTenantDialog';
import SPBackend, { NOTIFICATION_CHANNELS } from '../../services/SPBackend';
import Paginator from '../SharedComponents/Paginator';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/TenantsContent.Style';
import MemberStructuralStyles from '../Styles/CommonMemberStructuralStyles.Style';
import commonListTableStyles from '../Styles/CommonListTable.Style';
import combineStyles from '../../utils/stylesUtils';

import type { Tenant, Unit, Tag } from '../../services/SPBackend';
type Props = {
  classes: Object,
  tenants: Array<Tenant>,
  selectedRowsCount: number,
  isCustomerSelected: Function,
  handleSelectAllClick: Function,
  handleSelectCustomer: Function,
  removeTenant: Function,
  setTenants: Function,
  orderTenants: Function,
  // sort: Object,
  tenantsNextPage: Array<Tenant>,
  totalTenantsCount: number,
  setCountOfTenants: Function
};

// eslint-disable-next-line react/no-multi-comp
const TenantsContent = (props: Props) => {
  const {
    classes,
    tenants,
    removeTenant,
    setTenants,
    orderTenants,
    // sort,
    selectedRowsCount,
    isCustomerSelected,
    handleSelectAllClick,
    handleSelectCustomer,
    tenantsNextPage,
    totalTenantsCount,
    setCountOfTenants
  } = props;
  const [store, dispatch] = useStore();
  const [hover, setHover] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] = useState(false);
  const [openPrivateMessageDialog, setOpenPrivateMessageDialog] = useState(false);
  const [openEditTenantDialog, setOpenEditTenantDialog] = useState(false);
  const [sortTenantsByProp, setSortTenantsByProp] = useState('Name');

  const handleRemoveTenant = () => {
    if (selectedTenant !== null) {
      removeTenant(selectedTenant);
      setSelectedTenant(null);
    } else {
      // Show snack message
      dispatch({
        type: 'ACTION_SET_SNACK_MSG',
        snackMessage: {
          message: 'An error occured. Please try again.',
          level: 'error',
          duration: 3000
        }
      });
    }
  };
  
  const {
    user,
    tags,
    accountSettings,
    i18n,
    defaultUnit,
    loadingAccount,
    isAppLoading,
    tenantEnableUpload,
    tenantsPageInfo,
    accountUsage
  } = store;

  const handleSendPMToTenant = (tenantIds, messagePayload, callback, failureCallback) => {
    log('[tenantsContent][sendPMToTenant] tenantIds, message: ', tenantIds, messagePayload);
    const [tenantId] = tenantIds;
    log('[tenantsContent][sendPMToTenant] tenantId: ', tenantId);
    SPBackend.sendPMToTenant(tenantId, '', messagePayload, user)
      .then((response) => {
        const { data } = response;
        log('[tenantsContent][sendPMToTenant] tenantId, response: ', tenantId, response);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: data.message,
            level: data.success,
            duration: 3000
          }
        });
        callback();
      })
      .catch(error => {
        log('[tenantsContent][sendPMToTenant] error: ', error);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: 'An error occured. Please try again.',
            level: 'error',
            duration: 3000
          }
        });
        failureCallback();  
      });
  };

  const handleEditTenant = (
    tenant: Tenant,
    moveTasks: boolean,
    callback: Function = null
  ) => {
    if (selectedTenant !== null) {
      setSelectedTenant(tenant);
    } else {
      // Show snack message
      dispatch({
        type: 'ACTION_SET_SNACK_MSG',
        snackMessage: {
          message: 'An error occured. Please try again.',
          level: 'error',
          duration: 3000
        }
      });
    }

    const formattedNb = formatPhoneNumberForBackend(tenant.Phone);
    // TODO: validation logic to factor with addTenant in TenantsHeader and PropertyContent, and here.
    // validate email - if non empty
    if (tenant.Email !== '' && !validateEmail(tenant.Email)) {
      dispatch({
        type: 'ACTION_SET_SNACK_MSG',
        snackMessage: {
          message: 'Invalid email.',
          level: 'error',
          duration: 3000
        }
      });
      return;
    }
    if (
      modelUtils.getValAtPath(
        accountSettings,
        ['Tenants', 'ReceiveNotificationsBy'],
        NOTIFICATION_CHANNELS.SMS
      ) === NOTIFICATION_CHANNELS.EMAIL
    ) {
      if (tenant.Email === '') {
        dispatch({
          type: 'ACTION_SET_SNACK_MSG',
          snackMessage: {
            message: 'A valid email is required.',
            level: 'error',
            duration: 3000
          }
        });
        return;
      }
    } else {
      // if account settings isn't to send email, default to SMS
      if (!formattedNb) {
        dispatch({
          type: 'ACTION_SET_SNACK_MSG',
          snackMessage: {
            message: 'Invalid phone number.',
            level: 'error',
            duration: 3000
          }
        });
        return;
      }
    }

    const payload = {
      ...tenant,
      Phone: formattedNb,
      moveTasks
    };

    dispatch({
      type: 'ACTION_SET_IS_LOADING_MODULE',
      module: 'editTenant',
      loading: true
    });
    SPBackend.editTenant(payload, user)
      .then(response => {
        log('[handleEditTenant] response: ', response);
        const { tenant: updatedTenant } = response.data;
        log('[editTenant] res: ', updatedTenant);
        if (callback) {
          callback();
        }
        dispatch({
          type: 'ACTION_SET_SNACK_MSG',
          snackMessage: {
            message: `${i18n.t('Tenant')} updated`,
            level: 'success',
            duration: 3000
          }
        });
        dispatch({
          type: 'ACTION_SET_IS_LOADING_MODULE',
          module: 'editTenant',
          loading: false
        });
        let tenantExists = false;
        for (let i = 0; i < tenants.length; i++) {
          // update the tenants on the page
          if (typeof tenants[i] !== 'undefined' && tenants[i]._id === updatedTenant._id) {
            tenantExists = true;
            tenants[i] = updatedTenant;
            log('[UpdateTenantsAfter] res: ', updatedTenant, tenants[i]);
          }
        }
        if (!tenantExists) {
          tenants.push(updatedTenant);
        }
        log(
          '[unitsDocsOfTenant] res ',
          updatedTenant ? updatedTenant.unitsDocs : 'empty updatedTenant'
        );
        setTenants(prevState => {
          return [...prevState];
        });
        setCountOfTenants(totalTenantsCount+1);
      })
      .catch(error => {
        log('[handleEditTenant] error', error);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG',
          snackMessage: {
            message: error.message || 'An error occured. Please try again.',
            level: 'error',
            duration: 3000
          }
        });
        dispatch({
          type: 'ACTION_SET_IS_LOADING_MODULE',
          module: 'editTenant',
          loading: false
        });
      });
    setSelectedTenant(null);
  };

  const callbackOnTagPicked = (customerId: string) => (tag: Tag) => {
    try {
      console.log('[callbackOnTagPicked] tagging... tag, customerId: ', tag, customerId);
      dispatch({
        type: 'ACTION_SET_IS_LOADING_MODULE',
        module: 'tagCustomer',
        loading: true
      });
      SPBackend.tagCustomers(tag._id, [customerId], user)
        .then((response) => {
          console.log('[callbackOnTagPicked] response: ', response);
          const { message, success } = response.data;
          // update tenants state with new tag
          setTenants((prevTenants) => {
            log('[callbackOnTagPicked] prev tenants: ', prevTenants);
            const clone = R.clone(prevTenants);
            const tenantToUpdateIndex = R.findIndex(R.propEq('_id', customerId))(clone);
            log('[callbackOnTagPicked] tenantToUpdateIndex: ', tenantToUpdateIndex, clone[tenantToUpdateIndex]);
            console.log('[callbackOnTagPicked] tag._id: ', tag._id);
            if (tenantToUpdateIndex !== -1) {
              const union = R.union(clone[tenantToUpdateIndex].Tags, [tag._id]);
              log('[callbackOnTagPicked] union: ', union);
              // FIXME: for now we just replace. later we'll have to allow to add multiple tags here
              // combine lists of tags to ensure no duplicates
              clone[tenantToUpdateIndex].Tags = union;
            }
            return clone;
          });
          dispatch({
            type: 'ACTION_SET_SNACK_MSG', 
            snackMessage: {
              message: message,
              level: success ? 'success' : 'error',
              duration: 5000
            }
          }); 
          dispatch({
            type: 'ACTION_SET_IS_LOADING_MODULE',
            module: 'tagCustomer',
            loading: false
          });
        })
        .catch((error) => {
          log('[callbackOnTagPicked] error: ', error);
          dispatch({
            type: 'ACTION_SET_SNACK_MSG', 
            snackMessage: {
              message: error.response ? error.response.data : 'An error occured. Please try again',
              level: 'error',
              duration: 5000
            }
          }); 
        });
    } catch (e) {
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: 'Please select a tag',
          level: 'error',
          duration: 5000
        }
      }); 
    }
  };

  const callbackOnTagRemoved = (customerId: string) => (tag: Tag) => () => {
    try {
      log('[callbackOnTagRemoved]... customerId, tag: ', customerId, tag);
      dispatch({
        type: 'ACTION_SET_IS_LOADING_MODULE',
        module: 'tagCustomer',
        loading: true
      });
      SPBackend.removeTagFromCustomers(tag._id, [customerId], user)
        .then((response) => {
          console.log('[callbackOnTagRemoved] response: ', response);
          const { message, success } = response.data;
          // update tenants state with new tag
          setTenants((prevTenants) => {
            log('[setTenant] prev tenants: ', prevTenants);
            const clone = R.clone(prevTenants);
            const tenantIndex = R.findIndex(R.propEq('_id', customerId))(clone);
            log('[setTenant] tenantIndex: ', tenantIndex, clone[tenantIndex]);
            if (tenantIndex !== -1) {
              const otherTags = (t => t !== tag._id);
              clone[tenantIndex].Tags = R.filter(otherTags, clone[tenantIndex].Tags);
            }
            return clone;
          });
          dispatch({
            type: 'ACTION_SET_SNACK_MSG', 
            snackMessage: {
              message: message,
              level: success ? 'success' : 'error',
              duration: 5000
            }
          }); 
          dispatch({
            type: 'ACTION_SET_IS_LOADING_MODULE',
            module: 'tagCustomer',
            loading: false
          });
        })
        .catch((error) => {
          log('[callbackOnTagRemoved] error: ', error);
          dispatch({
            type: 'ACTION_SET_SNACK_MSG', 
            snackMessage: {
              message: error.response ? error.response.data : 'An error occured. Please try again',
              level: 'error',
              duration: 5000
            }
          });
          dispatch({
            type: 'ACTION_SET_IS_LOADING_MODULE',
            module: 'tagCustomer',
            loading: false
          });
        });
    } catch (e) {
      log('[callbackOnTagRemoved] e: ', e);
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: 'Connection error. Please try again.',
          level: 'error',
          duration: 5000
        }
      });
      dispatch({
        type: 'ACTION_SET_IS_LOADING_MODULE',
        module: 'tagCustomer',
        loading: false
      });
    }
  };

  return (
    <Grid 
      className={classes.customerTableContent}
    >
      <div className={classes.toolbar}/>
      {
        tenantEnableUpload
        ? <TenantsDropzone />
        : null
      }
      {
        (!tenants || tenants.length === 0) && !loadingAccount && !isAppLoading
        ? <TenantsDropzone />
        : null
      }
          
          <div>
            <div className={classes.resultsCount}>
              {
                tenants && selectedRowsCount > 0
                ? <span>&nbsp;</span> // ${selectedRowsCount} ${i18n.t(`${selectedRowsCount > 1 ? 'tenants' : 'tenant'}`)} selected.`
                : tenants
                  ?  tenants.length <= totalTenantsCount
                    ? null
                    : `Showing ${tenants.length} of ${totalTenantsCount} ${i18n.t('tenants')}`
                  : `0 ${i18n.t('tenants')} found.`}
            </div>
            {
              !loadingAccount && tenants
              ? <>
                  {/** MOBILE VERSION **/}
                  <Hidden mdUp>
                  <div 
                    className={classes.resultsCount}
                    onClick={() => {
                      setSortTenantsByProp('Name');
                      if (tenantsPageInfo && tenantsPageInfo.sortOrder && tenantsPageInfo.sortOrder !== null && tenantsPageInfo.sortOrder !== ''){
                        if (tenantsPageInfo.sortOrder === -1){
                            orderTenants(1,['FirstName','LastName']);
                          } else {
                            orderTenants(-1,['FirstName','LastName']);
                          }
                        }
                    }}
                  >
                    {i18n.t('Tenant')}
                    {
                      sortTenantsByProp && sortTenantsByProp === 'Name' &&tenantsPageInfo.sortOrder === -1
                        ? <ArrowUpwardIcon className={classes.arrowIcon}/> 
                        : null
                    }
                    {
                      sortTenantsByProp && sortTenantsByProp === 'Name' && tenantsPageInfo.sortOrder === 1 
                        ? <ArrowDownwardIcon className={classes.arrowIcon}/>
                        : null
                    }
                  </div>
                  {tenants &&
                    tenants.map((tenant: Tenant, tIndex) => {
                      const isItemSelected = isCustomerSelected(tenant._id);
                      if (!tenant) {
                        return <div>{'Tenant not found'}</div>;
                      }
                      return (
                        <Grid
                          className={classes.mobileFeedItem}
                          item
                          key={`$tenant-${tIndex}`}
                        >
                          <Grid
                            className={classes.mobileTileCtr}
                            container
                            direction='column'
                            justifyContent='space-between'
                          >
                            <Grid
                              className={classes.mobileRowItem}
                              item
                            >
                              <div className={classes.tileLeftColCtr}>
                                <Typography
                                  className={classes.tileTitle}
                                  variant='body1'
                                >
                                  {tenant.FirstName} {tenant.LastName}
                                </Typography>
                                <Typography
                                  className={classes.tileSubTitle}
                                  variant='body1'
                                >
                                  {formatPhoneNumberForFrontend(tenant.Phone)}
                                </Typography>
                              </div>
                              <div className={classes.tileRightColCtr}>
                                <IconButton
                                  aria-label={'Send a message'}
                                  classes={{
                                    root: classes.tileActionBtn
                                  }}
                                  onClick={e => {
                                    e.stopPropagation();
                                    setSelectedTenant(tenant);
                                    setOpenPrivateMessageDialog(true);
                                  }}
                                >
                                  <i className={'icon-sent'} />
                                </IconButton>
                                <IconButton
                                  aria-label={`Edit ${i18n.t('tenant')}`}
                                  classes={{
                                    root: classes.tileActionBtn
                                  }}
                                  onClick={e => {
                                    e.stopPropagation();
                                    setSelectedTenant(tenant);
                                    setOpenEditTenantDialog(true);
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  aria-label={`Remove ${i18n.t('tenant')}`}
                                  classes={{
                                    root: classes.tileActionBtn
                                  }}
                                  onClick={e => {
                                    e.stopPropagation();
                                    setSelectedTenant(tenant);
                                    setOpenConfirmRemoveDialog(true);
                                  }}
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </div>
                            </Grid>
                            <Grid
                              className={classNames(classes.mobileRowItem, classes.tileFooter)}
                              item
                              justifyContent="space-between"
                            >
                              <TagRenderer
                                callbackOnTagPicked={callbackOnTagPicked(tenant._id)}
                                callbackOnTagRemoved={callbackOnTagRemoved(tenant._id)}
                                readonly={false}
                                tagsIds={tenant.Tags}
                                // handleRemoveTag={handleRemoveTag(tenant._id)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })
                  }
                  </Hidden>
                  {/** TABLET + DESKTOP VERSION **/}
                  <Hidden smDown>
                    <Table className={classes.table}>
                      <colgroup>
                        <col />
                        <col />
                        {!defaultUnit ? <col /> : null}
                        {!defaultUnit ? <col /> : null}
                        <col />
                        <col classes={classes.colAction} />
                      </colgroup>
                      <TableHead className={classes.feedTableBody}>
                        <TableRow className={classes.feedTableHeadRow}>
                          <TableCell className={classes.checkboxCell}>
                            <Checkbox
                              checked={tenants.length > 0 && selectedRowsCount === tenants.length}
                              classes={{
                                root: classes.checkboxHeadCell
                              }}
                              color='primary'
                              indeterminate={selectedRowsCount > 0 && selectedRowsCount < tenants.length}
                              inputProps={{ 'aria-label': `select all ${i18n.t('Tenant')}` }}
                              onChange={handleSelectAllClick}
                              size='medium'
                            />
                          </TableCell>
                          <TableCell
                            className={classNames(classes.tenantCell, classes.clickableCellMargin)}
                            onClick={() => {
                              setSortTenantsByProp('Name');
                              if (tenantsPageInfo && tenantsPageInfo.sortOrder && tenantsPageInfo.sortOrder !== null && tenantsPageInfo.sortOrder !== ''){
                                if (tenantsPageInfo.sortOrder === -1){
                                    orderTenants(1,['FirstName','LastName']);
                                  } else {
                                    orderTenants(-1,['FirstName','LastName']);
                                  }
                                }
                            }}
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {i18n.t('Tenant')}
                              {
                                sortTenantsByProp && sortTenantsByProp === 'Name' &&tenantsPageInfo.sortOrder === -1
                                  ? <ArrowUpwardIcon className={classes.arrowIcon}/> 
                                  : null
                              }
                              {
                                sortTenantsByProp && sortTenantsByProp === 'Name' && tenantsPageInfo.sortOrder === 1 
                                  ? <ArrowDownwardIcon className={classes.arrowIcon}/>
                                  : null
                              }
                          </TableCell>
                          <TableCell className={classNames(classes.tagCell)}>
                            {i18n.t('Tag')}
                          </TableCell>
                          {!defaultUnit ? (
                            <TableCell className={classNames(classes.addressCell)}>
                              {'Address'}
                            </TableCell>
                          ) : null}
                          {!defaultUnit ? (
                            <TableCell className={classNames(classes.aptCell)}>
                              {i18n.t('Apt')}
                            </TableCell>
                          ) : null}
                          <TableCell
                            className={classNames(classes.phoneCell)}
                          >
                            {'Phone'}
                          </TableCell>
                          <TableCell className={classNames(classes.phoneCell)}>
                            {'Email'}
                          </TableCell>
                          <TableCell
                            className={classNames(classes.dateCell)}
                            onClick={() => {
                              setSortTenantsByProp('Date');
                              if (tenantsPageInfo && tenantsPageInfo.sortOrder && tenantsPageInfo.sortOrder !== null && tenantsPageInfo.sortOrder !== ''){
                                if (tenantsPageInfo.sortOrder === -1){
                                    orderTenants(1,['CreatedDt']);
                                  } else {
                                    orderTenants(-1,['CreatedDt']);
                                  }
                                }
                            }}
                          >
                            {'Date Added'}
              
                                  { tenantsPageInfo.sortOrder === -1 && sortTenantsByProp && sortTenantsByProp === 'Date'
                                      ? <ArrowUpwardIcon className={classes.arrowIcon}/> 
                                      : null
                                    }
                                    {
                                      tenantsPageInfo.sortOrder === 1 && sortTenantsByProp && sortTenantsByProp === 'Date'
                                      ? <ArrowDownwardIcon className={classes.arrowIcon}/>
                                      : null
                                    }

                          </TableCell>
                          <TableCell 
                            className={classNames(classes.colAction)}
                          >
                            {''}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody
                        className={classNames(
                          classes.feedTableBody,
                          classes.customTableStyles
                        )}
                      >
                        {tenants &&
                          tenants.map((tenant: Tenant, bIndex) => {
                            const isItemSelected = isCustomerSelected(tenant._id);
                            if (!tenant) {
                              return <div>{'Tenant not found'}</div>;
                            }
                            return (
                              <ListTableRow
                                className={classNames(
                                  hover ? 'hover' : null,
                                  classes.feedTableRow
                                )}
                                key={`tenant=${bIndex}`}
                                onMouseEnter={(e) => {e.stopPropagation(); setHover(true);}}
                                onMouseLeave={(e) => {e.stopPropagation(); setHover(false);}}
                              >
                                <TableCell className={classes.checkboxCell}>
                                  <Checkbox
                                    checked={isItemSelected}
                                    classes={{
                                      root: classNames(
                                        classes.checkboxIcon,
                                        isItemSelected ? classes.selected : null
                                      )
                                    }}
                                    color='primary'
                                    onChange={(e) => handleSelectCustomer(e, tenant._id)}
                                    size='medium'
                                  />
                                </TableCell>
                                <TableCell
                                  className={classNames(
                                    classes.cell,
                                    classes.tenantCell
                                  )}
                                  component='td'
                                  scope='row'
                                >
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  {`${tenant.FirstName} ${tenant.LastName}`}
                                </TableCell>
                                <TableCell
                                  className={classNames(
                                    classes.cell,
                                    classes.tagCell
                                  )}
                                  component='td'
                                  scope='row'
                                >
                                  <TagRenderer
                                    callbackOnTagPicked={callbackOnTagPicked(tenant._id)}
                                    callbackOnTagRemoved={callbackOnTagRemoved(tenant._id)}
                                    readonly={false}
                                    tagsIds={tenant.Tags}
                                    // handleRemoveTag={handleRemoveTag(tenant._id)}
                                  />
                                </TableCell>
                                <TableCell
                                  className={classNames(
                                    classes.cell,
                                    classes.phoneCell
                                  )}
                                  component='td'
                                  scope='row'
                                >
                                  {formatPhoneNumberForFrontend(tenant.Phone)}
                                </TableCell>
                                <TableCell
                                  className={classNames(
                                    classes.cell,
                                    classes.phoneCell
                                    
                                  )}
                                  component='td'
                                  scope='row'
                                >
                                  {tenant.Email}
                                </TableCell>
                                <TableCell
                                  className={classNames(
                                    classes.cell,
                                    classes.dateCell,
                                    'lastChild',
                                    'underActionCell',
                                    'twoBtns'
                                  )}
                                  component='td'
                                  scope='row'
                                >
                                  {
                                    moment().diff(tenant.CreatedDt, 'days') > 30
                                      ? moment(tenant.CreatedDt).format('L')
                                      : moment(tenant.CreatedDt).from(moment())
                                  
                                  }
                                </TableCell>
                                <TableCell
                                  align='right'
                                  className={classNames(
                                    classes.cell,
                                    classes.actionCell,
                                    'actionCell',
                                    'lastChild'
                                  )}
                                >
                                  <Tooltip
                                    aria-label={`Remove ${i18n.t('tenant')}`}
                                    title={`Remove ${i18n.t('tenant')}`}
                                  >
                                    <IconButton
                                      aria-label={`Remove ${i18n.t('tenant')}`}
                                      classes={{
                                        root: classes.rowActionBtn
                                      }}
                                      onClick={e => {
                                        e.stopPropagation();
                                        setSelectedTenant(tenant);
                                        setOpenConfirmRemoveDialog(true);
                                      }}
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip
                                    aria-label={`Edit ${i18n.t('tenant')}`}
                                    title={`Edit ${i18n.t('tenant')}`}
                                  >
                                    <IconButton
                                      aria-label={`Edit ${i18n.t('tenant')}`}
                                      classes={{
                                        root: classes.rowActionBtn
                                      }}
                                      onClick={e => {
                                        e.stopPropagation();
                                        setSelectedTenant(tenant);
                                        setOpenEditTenantDialog(true);
                                      }}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip
                                    aria-label={'Send a message'}
                                    title={'Send a message'}
                                  >
                                    <IconButton
                                      aria-label={'Send a message'}
                                      classes={{
                                        root: classes.rowActionBtn
                                      }}
                                      onClick={e => {
                                        e.stopPropagation();
                                        setSelectedTenant(tenant);
                                        setOpenPrivateMessageDialog(true);
                                      }}
                                    >
                                      <i className={'icon-sent'} />
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </ListTableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </Hidden>
                </>
              : null
              }
          </div>
          <MessageTenantDialog
            open={openPrivateMessageDialog}
            setOpen={setOpenPrivateMessageDialog}
            tenant={selectedTenant}
            triggerAction={handleSendPMToTenant}
          />
          <RemoveTenantDialog
            actionConfirm={handleRemoveTenant}
            open={openConfirmRemoveDialog}
            setOpen={setOpenConfirmRemoveDialog}
            tenant={selectedTenant}
          />
          <EditTenantDialog
            currTenant={selectedTenant}
            open={openEditTenantDialog}
            setOpen={setOpenEditTenantDialog}
            triggerAction={handleEditTenant}
          />
        <Paginator 
          firstPage={tenants}
          nextPage={tenantsNextPage}
          pageInfo={tenantsPageInfo}
          totalCount={totalTenantsCount}
        />
    </Grid>
  );
};

const combinedStyles = combineStyles(
  commonListTableStyles,
  styles,
  MemberStructuralStyles
);

export default withStyles(combinedStyles)(TenantsContent);
