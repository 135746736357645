import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useStore } from '../../store';
import classNames from 'classnames';
import { log } from '../../utils/jsUtils';

import { useDropzone } from 'react-dropzone';

import SPBackend from '../../services/SPBackend';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './Styles/ImageFileDrop.Style';
import { withStyles } from '@material-ui/core/styles';

type Props = {
  classes: Object,
  setAttachment: Function,
  children: Object
};

// const baseStyle = {
  // flex: '0 1 auto',
  // display: 'flex',
  // flexDirection: 'column',
  // alignItems: 'center',
  // padding: '10% 20%',
  // margin: '10px',
  // borderWidth: 0,
  // borderRadius: 0,
  // borderColor: '#eeeeee',
  // backgroundColor: '#fafafa',
  // color: '#bdbdbd',
  // outline: 'none',
  // transition: 'border .24s ease-in-out'
// };

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const ImageFileDrop = (props: Props) => {
  const { classes, children, setAttachment } = props;

  const [store, dispatch] = useStore();
  const {
    user,
    companyName,
    loadingModule,
    userFullName,
    tenantEnableUpload
  } = store;
  const [fileDropSuccess, setFileDropSuccess] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [acceptedFile, setAcceptedFile] = useState('');

  const handleHideDropZone = () => {
    dispatch({
      type: 'ACTION_SET_TENANTS_ENABLE_UPLOAD',
      tenantEnableUpload: false
    });
  };

  useEffect(() => {
    if (!acceptedFile) return;
    setUploading(true);
    SPBackend.uploadConversationImage(acceptedFile, user)
      .then(response => {
        setUploading(false);
        const { data } = response;
        log('[SPBackend.uploadConversationImage] response: ', data);
        setFileDropSuccess(true);
        if (typeof setAttachment !== 'undefined') {
          setAttachment(data.attachment);
        }
      })
      .catch(err => {
        setUploading(false);
        log('[uploadConversationImage] error: ', err.response);
        log(err);
        if (typeof err.response !== 'undefined' && err.response.status === 413) {
          dispatch({
            type: 'ACTION_SET_SNACK_MSG',
            snackMessage: {
              message: 'File exceeds maximum size (5MB).',
              level: 'error',
              duration: 5000
            }
          });
        } else {
          dispatch({
            type: 'ACTION_SET_SNACK_MSG',
            snackMessage: {
              message: 'Upload failed.',
              level: 'error',
              duration: 5000
            }
          });
        }
      });
  }, [acceptedFile]);

  const onDrop = useCallback(addedFile => {
    console.log('[handleUploadTenantFile]', addedFile[0].size);
    if (addedFile[0].size > 5000000) {
      dispatch({
        type: 'ACTION_SET_SNACK_MSG',
        snackMessage: {
          message: 'File exceeds maximum size (5MB).',
          level: 'error',
          duration: 5000
        }
      });
    }
    setAcceptedFile(addedFile[0]);
  });

  const buttonClassname = classNames({
    [classes.buttonSuccess]: 'success',
  });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    multiple: false,
    onDrop
  });

  const style = useMemo(
    () => ({
      // ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div className={classes.root}>
      {/* {uploading ? (
        <div>{'uploaded!'}</div>
      ) : ( */}
        <div {...getRootProps({ style })} className={classes.wrapper}>
          <input {...getInputProps()} />
          {children}
          {uploading && <CircularProgress size={56} className={classes.fabProgress} />}
        </div>
      {/* )} */}
    </div>
  );
};

export default withStyles(styles)(ImageFileDrop);
