// @flow
import { CHECKOUT_PAPER } from '../../Billing/Styles/PlansHome.Style';

const styles = (theme: Object) => ({
  root: {
    minHeight: '100vh',
    display: 'flex'
  },
  header: {
    marginRight: theme.spacing(4),
    color: 'red',
    textShadow: '0px 0 4px hsl(0deg 0% 0% / 12%)',
    '&.light': {
      color: theme.palette.primary.main,
      background: 'red'
    },
    '& a': {
      textDecoration: 'none',
      color: 'inherit'
    },
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap',
      overflowX: 'scroll',
      maxWidth: '50vw'
    }
  },
  gridPaper: {
    ...theme.squareplan.window.width,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginBottom: 50,
    minHeight: 200,
    marginLeft: 50,
    marginLeft: theme.spacing(2)
  },
  welcomeText: {
    marginBottom: theme.spacing(3), 
    fontSize: '1rem'
  },
  tableCell: {
    borderBottom: 'none', 
    fontSize: '1rem'
  },
  headerCell:{
    borderBottom: 'none', 
    backgroundColor: 'white', 
    fontSize: '1.25rem'
  },
  inputCtr: {
    textAlign: 'left'
  },
  countryDropdown: {
    marginTop: theme.spacing(5),
    marginLeft: 20,
    width: 'auto'
  },
  localitySearchBox: {
    width: 700 + theme.spacing(4) * 3,
    marginTop: theme.spacing(1),
    marginLeft: 20,
    marginRight: 20,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '84vw',
      marginTop: theme.spacing(3),
      padding: `${theme.spacing(4)}px 0px`,
      fontSize: '16px'
    }
  },
  areaCodeSearchBox: {
    width: 700 + theme.spacing(4) * 3,
    marginLeft: theme.spacing(2)
  },
  contentCtr: {
    flexGrow: 1,
    marginTop: 50,
    textAlign: 'center',
    maxWidth: 900,
    margin: '0 auto',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '95vw',
      marginTop: theme.spacing(3),
      padding: `${theme.spacing(4)}px 0px`
    }
  },
  tableCtr: {
    width: 800,
    textAlign: 'center',
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '84vw'
    }
  },
  paperText: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginLeft: theme.spacing(2)
  },
  table: {
    maxWidth: 800,
    maxHeight: 440, 
    overflow: 'auto',
    borderBottom: 'none',
    marginLeft: '20px',
    marginRight: '20px',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '84vw'
    }
  },
  actionButtons: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  formPaper: {
    ...CHECKOUT_PAPER,
    width: 450 + theme.spacing(3) * 3,
    marginLeft: '500px',
    paddingLeft: 500,
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    '& p, & h2, & h3': {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5)
    },
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80vw',
      minWidth: 0,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  }
});

export default styles;