// @flow

const styles = (theme: Object) => ({
  container: {
    maxWidth: 600,
    margin: '0 auto',
    fontSize: '1.1rem',
    paddingBottom: theme.spacing(5),
    '& h3': {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(2)
    },
    '& h4': {
      fontWeight: 600,
      fontSize: '1.3rem',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1.5)
    },
    '& h5': {
      fontWeight: 600,
      fontSize: '1rem',
      marginTop: theme.spacing(2),
      // marginBottom: theme.spacing(0.5)
    },
    '& p': {
      fontSize: '0.95rem',
      marginTop: '0.2rem',
      marginBottom: '0.5rem'
    },
    '& ul': {
      'padding-inline-start': '19px',
      fontSize: 'inherit'
    },
    '& li': {
      fontSize: '0.95rem'
    }
  }
});
export default styles;