/* eslint-disable no-dupe-keys */
import theme from '../theme';

const phoneInputCss = {
  marginTop: 16,
  display: 'flex',
  position: 'relative',
  background: 'white',
  fontSize: '1rem',
  '& .PhoneInputInput': {
    /* The phone number input stretches to fill all empty space */
    'flex': '1',
    /* The phone number input should shrink
       to make room for the extension input */
    'min-width': '0',
    fontFamily: ['Product Sans', 'sans-serif'].join(',')
  },
  '&.outlineInput': {
    // equivalent to Input.variant === 'outline'
    paddingLeft: theme.spacing(2),
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '&.PhoneInput--focus': {
      border: '2px solid #428bc0'
    },
    '& .PhoneInputInput': {
      border: 0,
      fontSize: '1rem'
    },
    '&.PhoneInput--focus input': {
      outline: 0,
      borderRadius: theme.spacing(1)
    }
  },
  '&.defaultInput': {
    // equivalent to Input.variant === 'default'
    paddingLeft: 0,
    paddingBottom: theme.spacing(1),
    border: 0,
    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
    outline: 0,
    '&:hover': {
      borderBottom: '2px solid rgba(0, 0, 0, 0.8)'
    },
    '& .PhoneInputInput': {
      border: 0
    },
    '&.PhoneInput--focus': {
      borderBottom: '2px solid #428bc0'
    },
    '&.PhoneInput--focus input': {
      outline: 0,
      border: 0
    }
  },
  '&': {
    '--PhoneInput-color--focus': '#03b2cb',
    '--PhoneInputInternationalIconPhone-opacity': '0.8',
    '--PhoneInputInternationalIconGlobe-opacity': '0.65',
    '--PhoneInputCountrySelect-marginRight': '0.35em',
    '--PhoneInputCountrySelectArrow-width': '0.3em',
    '--PhoneInputCountrySelectArrow-marginLeft': 'var(--PhoneInputCountrySelect-marginRight)',
    '--PhoneInputCountrySelectArrow-borderWidth': '1px',
    '--PhoneInputCountrySelectArrow-opacity': '0.45',
    '--PhoneInputCountrySelectArrow-color': 'inherit',
    '--PhoneInputCountrySelectArrow-color--focus': 'var(--PhoneInput-color--focus)',
    '--PhoneInputCountrySelectArrow-transform': 'rotate(45deg)',
    '--PhoneInputCountryFlag-aspectRatio': '1.5',
    '--PhoneInputCountryFlag-height': '1em'
    // '--PhoneInputCountryFlag-borderWidth': '1px',
    // '--PhoneInputCountryFlag-borderColor': 'rgba(0,0,0,0.5)',
    // '--PhoneInputCountryFlag-borderColor--focus': 'var(--PhoneInput-color--focus)',
    // '--PhoneInputCountryFlag-backgroundColor--loading': 'rgba(0,0,0,0.1)'
  },
  
  '& .PhoneInput': {
    /* This is done to stretch the contents of this component. */
    'display': 'flex',
    'align-items': 'center'
  },
  
  '& .PhoneInputCountryIcon': {
    'width': 'calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio))',
    'height': 'var(--PhoneInputCountryFlag-height)'
  },
  
  '& .PhoneInputCountryIcon--square': {
    'width': 'var(--PhoneInputCountryFlag-height)'
  },
  
  '& .PhoneInputCountryIcon--border': {
    /* Removed `background-color` because when an `<img/>` was still loading
       it would show a dark gray rectangle. */
    /* For some reason the `<img/>` is not stretched to 100% width and height
       and sometime there can be seen white pixels of the background at top and bottom. */
    'background-color': 'var(--PhoneInputCountryFlag-backgroundColor--loading)',
    /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
    /* For some reason the `<img/>` is not stretched to 100% width and height
       and sometime there can be seen white pixels of the background at top and bottom,
       so an additional "inset" border is added. */
    'box-shadow': '0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor), inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor)'
  },
  
  '& .PhoneInputCountryIconImg': {
    /* Fixes weird vertical space above the flag icon. */
    /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
    'display': 'block',
    /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
       Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
    'width': '100%',
    'height': '100%'
  },
  
  '& .PhoneInputInternationalIconPhone': {
    'opacity': 'var(--PhoneInputInternationalIconPhone-opacity)'
  },
  
  '& .PhoneInputInternationalIconGlobe': {
    'opacity': 'var(--PhoneInputInternationalIconGlobe-opacity)'
  },
  
  /* Styling native country `<select/>`. */
  
  
  '& .PhoneInputCountry .PhoneInputCountrySelect': {
    'position': 'absolute',
    'top': '0',
    'left': '0',
    'height': '100%',
    'width': '100%',
    'z-index': '1',
    'border': '0',
    'opacity': '0',
    'cursor': 'pointer'
  },
  
  '& .PhoneInputCountrySelect[disabled]': {
    'cursor': 'default'
  },
  
  '& .PhoneInputCountrySelectArrow': {
    'display': 'block',
    'content': '',
    'width': 'var(--PhoneInputCountrySelectArrow-width)',
    'height': 'var(--PhoneInputCountrySelectArrow-width)',
    'margin-left': 'var(--PhoneInputCountrySelectArrow-marginLeft)',
    'border-style': 'solid',
    'border-color': 'var(--PhoneInputCountrySelectArrow-color)',
    'border-top-width': '0',
    'border-bottom-width': 'var(--PhoneInputCountrySelectArrow-borderWidth)',
    'border-left-width': '0',
    'border-right-width': 'var(--PhoneInputCountrySelectArrow-borderWidth)',
    'transform': 'var(--PhoneInputCountrySelectArrow-transform)',
    'opacity': 'var(--PhoneInputCountrySelectArrow-opacity)'
  },
  
  '& .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow': {
    'opacity': '1',
    'color': 'var(--PhoneInputCountrySelectArrow-color--focus)'
  },
  
  '& .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border': {
    'box-shadow': '0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus), inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus)'
  },
  
  '& .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe': {
    'opacity': '1',
    'color': 'var(--PhoneInputCountrySelectArrow-color--focus)'
  },
  '& .PhoneInputCountry': {
    // 'backgroundColor': 'red'
    'position': 'relative !important',
    'align-self': 'stretch',
    'display': 'flex',
    'align-items': 'center',
    'margin-right': 'var(--PhoneInputCountrySelect-marginRight)'
  }
  
};

export {
  phoneInputCss
};
