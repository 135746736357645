/* eslint-disable no-magic-numbers */
// @flow

import React, {useEffect, useState} from 'react';
import { useStore } from '../../store';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

import SPBackend from '../../services/SPBackend';
import { log, formatPhoneNumberForFrontend } from '../../utils/jsUtils';

import AssignTaskDialog from './AssignTasksDialog';
import { handleAssignTasks } from '../SharedComponents/feedUtils';
import FeedTable from '../SharedComponents/FeedTable';
import PaginatorFooter from '../SharedComponents/PaginateFooter';
import commonListTableStyles from '../Styles/CommonListTable.Style';
import styles from './Styles/MemberContent.Style';
import FeedTableStyles from './Styles/CommonFeedTable.Style';
import combineStyles from '../../utils/stylesUtils';
import Mailto from '../Mailto';

type Props = {
  classes: Object,
  exportCSV: Function,
  loadingModule: Object
};

const feedbackEmailHeaders = {
  subject: 'Feedback on askneo.io console'
};

const generatePDF = (toPrint, length) => {
  log('[generatePDF] length: ', length);
  const feed = document.getElementById(toPrint);
  // log('[generatePDF] feed.clientWidth: ', feed.clientWidth); // image with
  // log('[generatePDF] feed.clientHeight: ', feed.clientHeight); // image with
  const LETTER_HEIGHT = 612;
  const pageHeight = LETTER_HEIGHT * 1.5; // pixels for a typical letter page height
  const pageWidth = 792 * 1.5;
  const requestRowHeight = 110; // magic hat...
  const NB_OF_ROWS_PER_PAGE = 12;
  // html2canvas internal ratio: feed.clientWidth = canvas.width * ratio.
  const HTML_TO_CANVAS_RATIO = 0.556;
  let position = 10; // pixel position within page, starting from the top
  const pageFixedRowHeight = pageHeight / NB_OF_ROWS_PER_PAGE;
  const MAGIC_ZOOM = 0.31;
  const secondPageYOffset = pageFixedRowHeight + 2;
  log('[generatePDF] pageFixedRowHeight: ', pageFixedRowHeight);
  log('[generatePDF] HTML_TO_CANVAS_RATIO: ', HTML_TO_CANVAS_RATIO);
  if (feed){
    html2canvas(feed, {
      windowWidth: pageWidth,
      windowHeight: feed.clientHeight
    })
    .then((canvas) => {
      log('[generatePDF] canvas.width: ', canvas.width); // image with
      log('[generatePDF] canvas.height: ', canvas.height);
      const canvasFixedRowsPerPage = canvas.height * MAGIC_ZOOM / length;
      log('[generatePDF] canvasFixedRowsPerPage: ', canvasFixedRowsPerPage);
      const zoomRatioForFixedHeight = canvasFixedRowsPerPage / requestRowHeight;//  pageWidth / feed.clientWidth;
      log('[generatePDF] zoomRatioForFixedHeight: ', zoomRatioForFixedHeight);
      const canvasHeightPerPage = canvasFixedRowsPerPage * NB_OF_ROWS_PER_PAGE;
      log('[generatePDF] canvasHeightPerPage: ', canvasHeightPerPage);
      let canvasHeightRemaining = canvas.height * MAGIC_ZOOM;
      const pdf = new jsPDF(
        'landscape',
        'px',
        'letter',
        true); // landscape mode.
      const imgData = canvas.toDataURL('\image/jpg');
      let pageIndex = 0;
      // log('[generatePDF] last canvasSizeForOnePage: ', canvasHeightPerPage);
      while (canvasHeightRemaining > canvasHeightPerPage) {
        log('[generatePDF] canvasHeightRemaining, position: ', canvasHeightRemaining, position);
        pdf.addImage(imgData, 'JPEG', 0, 
        position, canvas.width * MAGIC_ZOOM, canvas.height * MAGIC_ZOOM, 
        'undefined', 'Fast');
        canvasHeightRemaining -= canvasHeightPerPage;
        position -= canvasHeightPerPage - pageFixedRowHeight; // top padding for each new page.
        // offset once after first page
        log('[generatePDF] position: ', position);
        if (canvasHeightRemaining > canvasHeightPerPage) {
          pdf.addPage();
        }
        pageIndex += 1;
        if (pageIndex > 0) {
          log('[generatePDF] pageIndex, secondPageYOffset: ', pageIndex, secondPageYOffset);
          position -= secondPageYOffset;
        }
      }
      if (canvasHeightRemaining < canvasHeightPerPage) {
        if (pageIndex > 0) {
          pdf.addPage();
        }
        log('[generatePDF] last canvasHeightRemaining: ', canvasHeightRemaining);
        pdf.addImage(imgData, 'JPEG', 0, 
        position, canvas.width * MAGIC_ZOOM, canvas.height * MAGIC_ZOOM, 
        'undefined', 'Fast');
      }
      pdf.save('Askneo_Request_Feed.pdf');
    });
  }
};

const ContentMember = (props: Props) => {  
  const {classes, exportCSV} = props;
  const [store, dispatch] = useStore();
  const [filteredRequests, setFilteredRequests] = useState([]); // TODO: useless. to remove.
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [openAssignTaskDialog, setOpenAssignTaskDialog] = useState(false);
  const [selectedTagOptions, setSelectedTagOptions] = useState([]);

  const [showUnreadState, setShowUnreadState] = useState(false);

  const handleShowUnreadChange = (event) => {
    setShowUnreadState(event.target.checked);
    handleFilterChange(
      'unreadMessagesOnlyOptions', 
      [{ label: 'Unread Messages',
        value: event.target.checked === true ? 'true' : 'false'
      }]
    );
  };
  
  const {
    units,
    requests,
    user,
    filterSelections,
    totalRequests,
    filterSearchText,
    exportCSVRequests,
    printRequests,
    accountSettings,
    isAppLoading,
    loadingAccount,
    loadingModule,
    defaultBuilding,
    i18n,
    filterOptions
  } = store;
  
  // listens to print signal
  useEffect(() => {
    if (printRequests === true
        && typeof filteredRequests !== 'undefined'
        && filteredRequests !== null) {
      generatePDF('divToPrint', filteredRequests.length);
      dispatch({
        type: 'ACTION_SET_PRINT_REQUESTS', 
        printRequests: false
      });
    }
  }, [printRequests]);

  // apply filters & searches when they change
  useEffect(() => {
    if (typeof requests !== 'undefined') {
      setFilteredRequests(requests);
    }
  }, [requests, requests.length, filterSearchText]);

    // listens to export signal
    useEffect(() => {
      if (exportCSVRequests === true
          && typeof filteredRequests !== 'undefined'
          && filteredRequests !== null) {
            exportCSV();
        dispatch({
          type: 'ACTION_SET_EXPORT_CSV_REQUESTS', 
          exportCSVRequests: false
        });
      }
    }, [exportCSVRequests]);

  // TODO: to factor with FeedDialog.updateRequest.
  const updateRequest = (requestId, newStatus, successMsg, callbackSetLoading) => {
    const updatedIndex = R.findIndex(R.propEq('_id', requestId))(requests);
    log('updatedIndex: ', updatedIndex);
    log('requests[updatedIndex]: ', requests[updatedIndex]);
    const oldStatus = requests[updatedIndex].Status;
    if (requests && updatedIndex >= 0) {
      // Display the new status immediately. Will be rolled back if backend fails
      requests[updatedIndex].Status = newStatus;
    }
    dispatch({
      type: 'ACTION_SET_REQUESTS', 
      requests: requests
    });

    SPBackend.updateRequest(requestId, newStatus, user)
      .then(response => {
        const { data : updatedRequest } = response;
        log('[updated request]: ', updatedRequest);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: successMsg,
            level: 'success',
            duration: 5000
          }
        });
        callbackSetLoading(prevState => !prevState);
        
        if (requests && updatedIndex >= 0) {
          // Set the udpated row to animate
          requests[updatedIndex] = {
            ...updatedRequest,
            animateUpdated: true
          };
          // Set the previous row that will come down to replace it to fade in
          if (updatedIndex > 0) {
            for (let i = 0; i < updatedIndex; i += 1) {
              requests[i].animatePushedin = true;
            }
          }
          log('requests[updatedIndex]: ', requests[updatedIndex]);
          log('requests[updatedIndex]: ', requests[updatedIndex]);
          // TODO: Might need to take this out of the store and make it a local state.
          const byDate = R.descend(R.path(['UpdatedDt']));
          dispatch({
            type: 'ACTION_SET_REQUESTS', 
            requests: R.sort(byDate, requests)
          });
        }
      })
      .catch(err => {
        log('err: ', err);
        if (requests && updatedIndex >= 0) {
          // Display the new status immediately. Will be rolled back if backend fails
          requests[updatedIndex].Status = oldStatus;
        }
        dispatch({
          type: 'ACTION_SET_REQUESTS', 
          requests: requests
        });
        callbackSetLoading(prevState => !prevState);
      });
  };
  
  const markInProgress = (requestId, newStatus, callbackSetLoading) => (e) => {
    e.stopPropagation();
    callbackSetLoading(prevState => !prevState);
    updateRequest(requestId, newStatus, 'Request updated.', callbackSetLoading);
  };

  const triggerOpenAssignTaskDialog = (task) => (e) => {
    e.stopPropagation();
    setSelectedTasks([task]);
    setOpenAssignTaskDialog(true); 
  };

  const handleFilterChange = (filterName: 'propertyOptions' | 'staffOptions' | 'tenantOptions' | 'tagOptions' | 'unreadMessagesOnlyOptions', selection: Array<?{value: string, label: string}>) => {
    log('[MemberContent.handleFilterChange] selection',selection);
    selection = selection.filter(select => select.value !== '' && select.label !== '');
    log('[MemberHeader.handleFilterChange] selection after filter',selection);
    filterSelections[filterName] = selection;
    log('[MemberHeader.handleFilterChange] filterSelections',filterSelections);
    dispatch({
      type: 'ACTION_SET_FILTER_SELECTION',
      filterSelections: filterSelections
    });
    dispatch({
      type: 'ACTION_SET_IS_APP_LOADING',
      isAppLoading: true
    });
  };
  
  return (
    <Grid 
      className={classes.tableContent}
    >
     <div className={classes.toolbar}/>
     {
       loadingModule.accountSettings
       ? null
       : accountSettings.Twilio && accountSettings.Twilio.Status === 'suspended'
          ? <div className={classes.errorCtr}>
            <Alert severity="error">
              {'Your account is currently suspended.'}
              <br />
              {'Please text us: +1 (917) 451-5515‬.'}
              <br />
              <span>
                {'Alternatively, email: '}<Mailto
                email={'lio@askneo.io'}
                headers={feedbackEmailHeaders}
                obfuscate={false}
                                          ><span>{'lio@askneo.io'}</span>
                </Mailto>
              </span>
            </Alert>
          </div>
          : null
     }
     { 
      defaultBuilding && defaultBuilding.Phone && formatPhoneNumberForFrontend(defaultBuilding.Phone)
      ? [<Typography className={classes.phoneNb}
                      key='ph-number'
                      variant='h5'
         >{`Your phone number: ${formatPhoneNumberForFrontend(defaultBuilding.Phone)}`}</Typography>,
        <Typography className={classes.phoneNbMobile}
                      key='ph-number-mobile'
                      variant='h5'
        >Your number: {formatPhoneNumberForFrontend(defaultBuilding.Phone)}</Typography>
      ]
      : null
     }
     {
      isAppLoading || loadingModule.accountSettings || loadingModule.feed
      ? null
      : (
        filteredRequests.length === 0
        && filterSelections.propertyOptions.length === 0
        && filterSelections.staffOptions.length === 0
        && filterSelections.tenantOptions.length === 0
        && filterSelections.tagOptions.length === 0
        && filterSelections.unreadMessagesOnlyOptions.length === 0)
        ? <div className={classes.blockquoteCtr}>
        <blockquote className='tweet-this'>
        <h3>Welcome to Neo!</h3>
        <p>We know you worked really hard to get where you are today, so you derserve all the credit.
        <br />In fact, 100 credits. For free, for 7 days.<br /></p>

<p><b>The best way to get started</b><br />
1. <a href='/customers'>Upload</a> a customer list (first and last name, phone number, tags)<br />
2. Send a Broadcast (offer, promotion, question, etc)<br />
3. Respond to inquiries and close deals!</p>

<p><b>For your information</b><br />
1 credit = 1 SMS up to 80 characters<br />
5 credits = 1 image up to 5MB
<br />(sent or received)</p>

<p>Excited about SMS automation? <a href='https://bit.ly/39c6mGo'>Check this video</a> :)</p>
<p>Oh and one more thing: <a href='https://bit.ly/3sTC0Qo'>book an onboarding meeting here</a> and we'll help you get results in no time!</p>

        </blockquote>
      </div>
        : null
     }
      
      {
        !isAppLoading && !loadingModule.feed
        && filteredRequests && filteredRequests.length > 0 && units && units.length > 0
        ? <div>
            <div className={classes.pageInfoCtr}>
              <div className={classes.resultsCount}>
                {
                  filterSearchText !== ''
                  ? `${filteredRequests.length} results in page ${filterSelections.pageNb + 1}`
                  : `${filteredRequests.length} of ${totalRequests} Results`
                }
              </div> 
              <div className={classes.filterButton}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showUnreadState}
                    color="primary"
                    name="checked"
                    onChange={handleShowUnreadChange}
                  />
                }
                label="Show unread"
              />
            </div>
          </div>
            <FeedTable
              markInProgress={markInProgress}
              triggerAssignDialog={triggerOpenAssignTaskDialog}
            />
          </div>
        : null
      }
      {
        !isAppLoading && !loadingModule.feed
        && filteredRequests.length === 0
        && (
          filterSelections.propertyOptions.length > 0
          || filterSelections.staffOptions.length > 0
          || filterSelections.tenantOptions.length > 0
          || filterSelections.tagOptions.length > 0
          || filterSelections.unreadMessagesOnlyOptions.length > 0
        )
        ? <div className={classes.noResultsCtr}>
            <div className={classes.pageInfoCtrNoResults}>
              <div className={classes.filterButton}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showUnreadState}
                      color="primary"
                      name="checked"
                      onChange={handleShowUnreadChange}
                    />
                  }
                  label="Show unread"
                />
              </div>
            </div>
            <div className={classes.infoText}>
              <SearchRoundedIcon className={classes.searchIcon}/>
              <Typography variant='h5'>{'No results found.'}</Typography>
            </div>
          </div>
        : null
      }
      {
        filteredRequests && filteredRequests.length > 0 || filterSearchText !== ''
        ? <PaginatorFooter />
        : null
      }
        {/* The calledFromActivity field exists for the handleAssignTask function */}
        <AssignTaskDialog
          calledFromActivity
          open={openAssignTaskDialog}
          setOpen={setOpenAssignTaskDialog}
          tasks={selectedTasks}
          triggerAction={handleAssignTasks}
        /> 
    </Grid>
  );
};


const combinedStyles = combineStyles(
  commonListTableStyles,
  styles,
  FeedTableStyles
);
export default withStyles(combinedStyles)(ContentMember);