const styles = (theme: Object) => ({
  dialogCtr: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    width: '70vh',
    marginRight: theme.spacing(2)
  },
  lastChildAccordionRoot: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)'
  },
  grow: {
    flexGrow: 1
  },
  secondInput: {
    marginTop: theme.spacing(2)
  },
  accordionContainer: {
    marginTop: theme.spacing(1)
  },
  title: {
    marginTop: theme.spacing(4),
    fontWeight: '600'
  },
  footer: {
      marginTop: theme.spacing(2)
  },
  btnCtrl: {
      marginLeft: theme.spacing(2)
  }
});

export default styles;
