// @flow
import templateThemes from '../../../theme';

const styles = (theme: Object) => ({
  flexContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  dialogFormCtr: {
    height: '80vh',
    maxHeight: 300,
    display: 'flex',
    flexDirection: 'column'
  },
  grow: {
    flexGrow: 1
  },
  secondInput: {
    marginTop: theme.spacing(2)
  },
  error: {
    color: templateThemes.palette.status.error.main
  }
});

export default styles;
