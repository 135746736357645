// @flow

import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import classNames from 'classnames';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Fab from '@material-ui/core/Fab';

import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import SendIcon from '@material-ui/icons/Send';

import RemovableModalImage from '../SharedComponents/RemovableModalImage';
import ImageFileDrop from '../SharedComponents/ImageFileDrop';
import ForwardRef from '../SharedComponents/ForwardRef';
import { calculateCreditsForMsg, submitSendMessage } from '../../models/Message';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/SendReplyBox.Style';

import type { Tenant } from '../../services/SPBackend';

type Props = {
  classes: Object,
  tenant: Tenant,
  triggerAction: Function,
};

// eslint-disable-next-line react/no-multi-comp
const SendReplyBox = (props: Props) => {
  const {
    classes,
    tenant,
    triggerAction
  } = props;
  const [store, dispatch] = useStore();
  const {
    billing,
    accountUsage
  } = store;

  const errorMsgInitState = {
    function: 'upgrade', // 'upgrade'|'recharge'|''
    msg: ''
  };

  const [message, setMessage] = useState('');
  const [selectedTagOptions, setSelectedTagOptions] = useState([]);
  const [toTenantsIds, setToTenantsIds] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [errorMsg, setErrorMsg] = useState(errorMsgInitState);
  const [creditsUsed, setCreditsUsed] = useState(0);

  useEffect(() => {
    if (typeof message !== 'undefined' && message !== null) {
      setCreditsUsed(calculateCreditsForMsg({message: message, attachment: attachment}));
    }
  }, [message]);

  const handleSetAttachment = (url) => {
    log('[handleSetAttachment] url: ', url);
    setAttachment(url);
  };

  const emptyForm = () => {
    setMessage('');
    setSelectedTagOptions([]);
    setToTenantsIds([]);
    // TODO: Finish refactoring ErrorMsg everywhere
    setErrorMsg(errorMsgInitState);
    setAttachment(null);
    setCreditsUsed(0);
  };
  
  const emptyErrorMsg = () => {
    setErrorMsg(errorMsgInitState);
    setError('');
    setIsSending(false);
  };

  const openUpgradePlan = () => {
    log('[openUpgradePlan]...');
    setIsSending(false);
    dispatch({
      type: 'ACTION_SET_UPGRADE_POPUP',
      open: true
    });
  };

  const removeAttachment = (attachment) => (e) => {
    e.stopPropagation();
    setAttachment(null);
  };

  const openRechargeAccount = () => {
    log('[openRechargeAccount]...');
    setIsSending(false);
    dispatch({
      type: 'ACTION_SET_RECHARGE_POPUP',
      open: true
    });
  };

  return (
    <div>
    {
      tenant
      ? <form
          autoComplete='off'
          className={classNames(
            classes.dialogFormCtr
          )}
          noValidate
          onSubmit={e => {
            e.preventDefault();

            const _props = {
              message,
              attachments: [attachment],
              setErrorMsg,
              setIsSending,
              toTenantsIds: [tenant._id],
              // setOpenDialog: setOpen,
              triggerAction,
              emptyForm,
              billing,
              accountUsage,
              dispatch
            };
            log('[sendPM] _props: ', _props);
            submitSendMessage(_props);


            // setIsSending(true);
            // if (tenant !== null && message !== '') {
            //   triggerAction(
            //     tenant,
            //     {
            //       message,
            //       attachment
            //     },
            //     () => {
            //       log('[triggerAction] done!');
            //       emptyForm();
            //       setIsSending(false);
            //     },
            //     (errorMsg) => {
            //       setIsSending(false);
            //       setErrorMsg(errorMsg);
            //     });
            // } else {
            //   setError('Cannot send an empty message.');
            //   setIsSending(false);
            // } 
          }}
        >
        {
              attachment && attachment.Url !== ''
              ? <RemovableModalImage
                handleClickOnRemove={removeAttachment(attachment)}
                largeImageUrl={attachment.Url}
                smallImageUrl={attachment.Url}
                />
              : null
            }
          <div 
          className={classNames(classes.grow, classes.flexContainer)}
          >
          <div className={classes.error}>{ error }</div>
            <div id='SendReplyBox'>
            <OutlinedInput
              autoFocus
              endAdornment={
                <InputAdornment position="end">
                  {
                    process.env.REACT_APP_CHARS_NB_PER_CREDIT
                    ? <Tooltip 
                      aria-label={`${creditsUsed} ${creditsUsed > 1 ? 'Credits' : 'Credit'}`}
                      placement='top-end'
                      style={{height: 32}}
                      title={`${creditsUsed} ${creditsUsed > 1 ? 'Credits' : 'Credit'}`}
                      >
                      <ForwardRef>
                      {
                        errorMsg && errorMsg.msg
                        ? <div className={classNames(classes.errorMessagesCtr,
                            errorMsg.function !== '' ? 'two-lines' : 'one-line')}
                          >
                            <div className={classNames(
                              !attachment ? classes.textFieldCaptionLeft : classes.textFieldCaptionRight2, 
                              classes.warning)} 
                              dangerouslySetInnerHTML={{__html: errorMsg.msg}}
                            />
                            {
                              errorMsg.function === 'upgrade'
                              ? <div className={classNames(
                                !attachment ? classes.textFieldCaptionLeft : classes.textFieldCaptionRight2, 
                                classes.warning)}
                                >
                                <a className={classes.clickable}
                                  onClick={openRechargeAccount}
                                >Click here</a> to activate your account.
                              </div>
                              : null
                            }
                            {
                              errorMsg.function === 'recharge'
                              ? <div className={classNames(
                                !attachment ? classes.textFieldCaptionLeft : classes.textFieldCaptionRight2, 
                                classes.warning)}
                                > 
                                <a className={classes.clickable}
                                  onClick={openRechargeAccount}
                                >Click here</a> to recharge your account.</div>
                              : null
                            }
                          </div>
                      : <div>&nbsp;</div>}
                      </ForwardRef>
                    </Tooltip>
                    : null
                  }
                  <Button
                    color='primary'
                    disabled={isSending}
                    type='submit'
                    variant='contained'
                  >
                    <SendIcon />
                  </Button>
                  
                </InputAdornment>
              }
              fullWidth
              id="message"
              multiline
              onChange={e => { 
                                setMessage(e.target.value);
                                emptyErrorMsg();
                                setError('');
                              }}
              placeholder={'Send a reply'}
              startAdornment={
                <InputAdornment >
                  <ImageFileDrop
                    setAttachment={handleSetAttachment}
                  >
                    <Tooltip 
                      aria-label={'Attach an image'}
                      placement='top'
                      title={'Attach an image'}
                    >
                      
                        <ImageOutlinedIcon className={classes.actionAttachImgBtn}/>
                    </Tooltip>
                  </ImageFileDrop>
                </InputAdornment>
              }
                  type={'text'}
              value={message}
            />
            </div>
            
            
          </div>
        </form>
      : null
    }
    </div>
  );
};

export default withStyles(styles)(SendReplyBox);
