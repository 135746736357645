// @flow

import React, { useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useStore } from '../../store';
import { log, formatPhoneNumberForFrontend } from '../../utils/jsUtils';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// import * as R from 'ramda';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import FileCopy from '@material-ui/icons/FileCopy';
import RemoveIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import Hidden from '@material-ui/core/Hidden';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/StaffListContent.Style';
import MemberStructuralStyles from '../Styles/CommonMemberStructuralStyles.Style';
import commonListTableStyles from '../Styles/CommonListTable.Style';
import combineStyles from '../../utils/stylesUtils';

import AddPropertyAccessDialog from './AddPropertyAccessDialog';
import ListTableRow from '../ListTableRow';
import RemovePropertyAccessDialog from './RemovePropertyAccessDialog';
// import ListTableRow from '../ListTableRow';
import { getUserRole, getRolePower, ROLE_POWER } from '../../models/User';
import RemoveStaff from './RemoveStaffDialog';
import EditStaffDialog from './EditStaffDialog';
import SPBackend from '../../services/SPBackend';

import type { User, UserView } from '../../services/SPBackend';


type Props = {
  classes: Object,
  staffList: Array<UserView>,
  removeStaff: Function,
  editStaff: Function,
  refreshStaffList: Function
};

// eslint-disable-next-line react/no-multi-comp
const StaffListContent = (props: Props) => {
  const {
    classes,
    staffList,
    removeStaff,
    editStaff,
    refreshStaffList
  } = props;

  const [store, dispatch] = useStore();
  const {
    user,
    userRole,
    email,
    i18n,
    loadingAccount
  } = store;

  const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [openAddPropertyAccessDialog, setOpenAddPropertyAccessDialog] = useState(false);
  const [openConfirmEditDialog, setOpenConfirmEditDialog] = useState(false);
  const [openRemovePropertyAccessDialog, setOpenRemovePropertyAccessDialog] = useState(false);
  const [selectRemoveBuildingAccess, setSelectRemoveBuildingAccess] = useState(null);

  const selectStaff = (staff) => {
    setSelectedStaff(staff);
  };

  const handleRemoveStaff = () => {
    if (selectedStaff !== null) {
      removeStaff(selectedStaff);
      setSelectedStaff(null);
    } else {
      // TODO: Changed the message.
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: 'An error occured. Please try again.',
          level: 'error',
          duration: 3000
        }
      });  
    }
  };

  const handleEditStaff = (changedStaff:Object) => {
    if (selectedStaff !== null) {
      editStaff(selectedStaff, changedStaff);
      setSelectedStaff(false);
    } else {
      // TODO: Changed the message.
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: 'An error occured. Please try again.',
          level: 'error',
          duration: 3000
        }
      });
    }
  };
  
  const handleAddPropertyAccess = (staff: User) => {
    log('[handleAddPropertyAccess] staff, user ', staff, user);
    SPBackend.addUserPermission(staff, user)
      .then((response) => {
        log('[handleAddPropertyAccess]', response);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: 'Access Granted',
            level: 'success',
            duration: 3000
          }
        });
        refreshStaffList();
      })
      .catch(error => {
        log('[handleAddPropertyAccess.addUserPermission] error: ', error);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: error.response ? error.response.data : 'An error occured. Please try again',
            level: 'error',
            duration: 5000
          }
        }); 
      });
  };

  const handleRemovePropertyAccess = (staff: User) => {
    log('[handleRemovePropertyAccess] staff, user ', staff, user);
    SPBackend.removeUserPermission(staff, user)
      .then((response) => {
        log('[handleRemovePropertyAccess]', response);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: 'Access removed',
            level: 'success',
            duration: 3000
          }
        });
        refreshStaffList();
      })
      .catch(error => {
        log('[handleRemovePropertyAccess.removeUserPermission] error: ', error);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: error.response ? error.response.data : 'An error occured. Please try again',
            level: 'error',
            duration: 5000
          }
        }); 
      });
  };

  const onCopy = () => {
    dispatch({
      type: 'ACTION_SET_SNACK_MSG', 
      snackMessage: {
        message: 'Copied to your clipboard!',
        level: 'success',
        duration: 2000
      }
    });
  };
  
  return (
   staffList
   ?
      <Grid 
        className={classes.staffTableContent}
      >
        <div className={classes.toolbar} />
        <div>
            {
              !loadingAccount
              ? <>
                  {/** MOBILE VERSION **/}
                  <Hidden mdUp>
                    {
                      staffList &&
                      staffList.map((staff: UserView, sIndex) => {
                        if (!staff.user) {
                          return <div>{'Staff not found'}</div>;
                        }
                        return (
                          <Grid
                            className={classes.mobileFeedItem}
                            item
                            key={`$tenant-${sIndex}`}
                          >
                            <Grid
                              className={classes.mobileTileCtr}
                              container
                              direction='column'
                              justifyContent='space-between'
                            >
                              <Grid
                                className={classes.mobileRowItem}
                                item
                              >
                                <div className={classes.tileLeftColCtr}>
                                <Typography
                                  className={classes.tileTitle}
                                  variant='body1'
                                >
                                  {staff.user.FirstName} {staff.user.LastName}
                                </Typography>
                                <Typography
                                  className={classes.tileSubTitle}
                                  variant='body1'
                                >
                                  {staff.user.Email}
                                </Typography>
                                <Typography
                                  className={classes.tileSubTitle}
                                  variant='body1'
                                >
                                  {formatPhoneNumberForFrontend(staff.user.Phone)}
                                </Typography>
                                <Typography
                                  className={classes.tileSubTitle}
                                  variant='body1'
                                >
                                  {getUserRole(staff.user)}
                                </Typography>
                                </div>
                                <div className={classes.tileRightColCtr}>
                                  {
                                    (((ROLE_POWER[userRole] > getRolePower(staff.user)) && (email !== staff.user.Email))
                                      || (ROLE_POWER[userRole] === 3))
                                    ? <IconButton 
                                        aria-label="Edit User" 
                                        classes={{
                                        root: classes.tileActionBtn
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          log('Editing User: ', staff.user);
                                          selectStaff(staff.user);
                                          setOpenConfirmEditDialog(true);
                                        }}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                      : null
                                  }
                                  { 
                                    ((email !== staff.user.Email)
                                    || (ROLE_POWER[userRole] === 3 && email !== staff.user.Email))
                                    ? <Tooltip 
                                      aria-label={'Remove Staff'}
                                      title={'Remove Staff'} 
                                      >
                                      <IconButton 
                                        aria-label="Remove Staff" 
                                        classes={{
                                          root: classes.tileActionBtn
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          log('removing User: ', staff.user);
                                          selectStaff(staff.user);
                                          setOpenConfirmRemoveDialog(true);
                                        }}
                                      >
                                        <RemoveIcon />
                                      </IconButton>
                                    </Tooltip>
                                    : null
                                  }
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })
                    }
                  </Hidden>
                  {/** TABLET + DESKTOP VERSION **/}
                  <Hidden smDown>
                    <Table className={classes.table}>
                      <colgroup>
                        <col classes={classes.addressCell}/>
                        <col classes={classes.emailCell}/>
                        <col classes={classes.phoneCell}/>
                        <col classes={classes.otherCell}/>
                      </colgroup>
                      <TableHead className={classes.feedTableBody}>
                        <TableRow className={classes.feedTableHeadRow}>
                        <TableCell className={classNames(classes.addressCell)}>&nbsp;&nbsp;&nbsp;&nbsp;{'Full Name'}</TableCell>
                        <TableCell className={classNames(classes.emailCell)}>{'Email'}</TableCell>
                        <TableCell className={classNames(classes.phoneCell)}>{'Phone'}</TableCell>
                        <TableCell className={classNames(classes.otherCellHeader)}>{'Role'}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody
                        className={classNames(
                          classes.feedTableBody,
                          classes.customTableStyles
                        )}
                      >
                        {staffList &&
                          staffList.map((staff: UserView, bIndex) => {
                            if (!staff.user) {
                              return <div>{'Staff not found'}</div>;
                            }
                            return (
                              <ListTableRow
                                // handleRowClick={goToProperty(tenant.PropertyKey)}
                                key={`tenant=${bIndex}`}
                              >
                                <TableCell
                                  className={classNames(classes.cell, classes.addressCell)}
                                  component='td'
                                  scope='row'
                                >
                                  &nbsp;&nbsp;&nbsp;&nbsp;{`${staff.user.FirstName} ${staff.user.LastName}`}
                                  {
                                    typeof staff.user !== 'undefined'
                                    && typeof staff.user.Invite !== 'undefined'
                                    && staff.user.Invite.Status !== 'accepted'
                                    ? <span className={classes.inlineSpan}>
                                      {
                                        moment().diff(staff.user.Invite.CreatedDt, 'days', true) > 1
                                        ? '(Invite expired.)'
                                        : <span>
                                        {`(Invited by ${staff.user.Invite.Channel === 'email' ? 'Email' : 'SMS'})`}
                                        <CopyToClipboard
                                          onCopy={onCopy}
                                          text={`https://app.askneo.io/staff/invitations/${staff.user.Invite.Token}`}
                                        >
                                          <Tooltip title='Copy Invite Link'>
                                            <IconButton
                                              aria-label="Copy Invite link"
                                              classes={{root: classes.inlineBtn}}
                                              edge="end"
                                            >
                                              <FileCopy />
                                            </IconButton>
                                          </Tooltip>
                                        </CopyToClipboard>
                                          </span>
                                      }
                                    </span>
                                    : null
                                  }
                                </TableCell>
                                <TableCell
                                  className={classNames(classes.cell, classes.emailCell)}
                                  component="td"
                                  scope="row"
                                >
                                  {typeof staff.user.Email !== 'undefined' && staff.user.Email !== '' ? staff.user.Email : ''}
                                </TableCell>
                                <TableCell
                                  className={classNames(classes.cell, classes.phoneCell)}
                                  component='td'
                                  scope='row'
                                >
                                  {typeof staff.user.Phone !== 'undefined' && staff.user.Phone !== '' ? formatPhoneNumberForFrontend(staff.user.Phone) : ''}
                                </TableCell>
                                <TableCell
                                  className={classNames(
                                    classes.cell,
                                    classes.otherCell,
                                    'lastChild',
                                    'underActionCell',
                                    ((email !== staff.user.Email)
                                    || (ROLE_POWER[userRole] === 3 && email !== staff.user.Email))
                                    ? 'twoBtn'
                                    : email === staff.user.Email
                                      ? 'oneBtn'
                                      : null
                                  )}
                                  component='td'
                                  scope='row'
                                >
                                  {getUserRole(staff.user)}
                                </TableCell>
                                <TableCell
                                  align='right'
                                  className={classNames(
                                    classes.cell, 
                                    classes.actionCell, 
                                    'actionCell',
                                    'lastChild')}
                                >
                                { 
                                  ((email !== staff.user.Email)
                                  || (ROLE_POWER[userRole] === 3 && email !== staff.user.Email))
                                  ? <Tooltip 
                                    aria-label={'Remove Staff'}
                                    title={'Remove Staff'} 
                                    >
                                    <IconButton 
                                      aria-label="Remove Staff" 
                                      classes={{
                                        root: classes.rowActionBtn
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        log('removing User: ', staff.user);
                                        selectStaff(staff.user);
                                        setOpenConfirmRemoveDialog(true);
                                      }}
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                  </Tooltip>
                                  : null
                                }
                                {
                                  (((ROLE_POWER[userRole] > getRolePower(staff.user)) && (email !== staff.user.Email))
                                    || (ROLE_POWER[userRole] === 3))
                                  ? <Tooltip 
                                      aria-label={'Edit Staff'}
                                      title={'Edit Staff'} 
                                    >
                                      <IconButton 
                                        aria-label="Edit User" 
                                        classes={{
                                          root: classes.rowActionBtn
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          log('Editing User: ', staff.user);
                                          selectStaff(staff.user);
                                          setOpenConfirmEditDialog(true);
                                        }}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  : null
                                }
                                </TableCell>
                              </ListTableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </Hidden>
                </>
              : null
              }
            </div>
        <EditStaffDialog 
          open={openConfirmEditDialog}
          setOpen={setOpenConfirmEditDialog}
          staff={selectedStaff}
          triggerAction={handleEditStaff}
        />
        <RemoveStaff
          actionConfirm={handleRemoveStaff}
          open={openConfirmRemoveDialog}
          setOpen={setOpenConfirmRemoveDialog}
          staff={selectedStaff}
        />
        <AddPropertyAccessDialog
          handleAddPropertyAccess={handleAddPropertyAccess}
          open={openAddPropertyAccessDialog}
          selectedStaff={selectedStaff}
          setOpen={setOpenAddPropertyAccessDialog}
        />
        <RemovePropertyAccessDialog
          building={selectRemoveBuildingAccess}
          handleRemovePropertyAccess={handleRemovePropertyAccess}
          open={openRemovePropertyAccessDialog}
          selectedStaff={selectedStaff}
          setOpen={setOpenRemovePropertyAccessDialog}
        />
      </Grid>
    : 
      <div className={classes.simpleMessage}>
      <Typography variant='h3' >{'This is your staff directory'}</Typography>
      <br />
        <Typography variant='subtitle1'>{'Add staff members here to transform your Feed into a shared inbox and:'}
        <br />{'- Collaborate and assign tickets'}
        <br />{'- Visualize conversations threads'}
        <br />{'- Improve team communication'}
        </Typography>
      </div>
  );
};

const combinedStyles = combineStyles(
  commonListTableStyles,
  styles,
  MemberStructuralStyles
);
export default withStyles(combinedStyles)(StaffListContent);
