// @flow

import React, { useState, useEffect } from 'react';

import TOSDialog from '../PublicCpts/TOSDialog';
import TOS from '../PublicCpts/TOS';
import PrivacyPolicy from '../PublicCpts/PrivacyPolicy';

const AgreeToTOCLine = () => {
  const [openTOSDialog, setOpenTOSDialog] = useState(false);
  const [openPrivacyialog, setOpenPrivacyialog] = useState(false);
  return (
    <span style={{}}>
      {'I agree to the '}<a 
        href='#' 
        onClick={() => setOpenTOSDialog(true)}
      >{'Terms & Conditions'}</a>{' and '}<a 
        href='#' 
        onClick={() => setOpenPrivacyialog(true)}
      >{'Privacy Policy'}</a>{'.'}
    <TOSDialog
      TermComponent={TOS}
      open={openTOSDialog}
      setOpen={setOpenTOSDialog}
    />
    <TOSDialog
      TermComponent={PrivacyPolicy}
      open={openPrivacyialog}
      setOpen={setOpenPrivacyialog}
    />
  </span>
  );
};

export default AgreeToTOCLine;