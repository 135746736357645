// @flow

import React, {useState} from 'react';
import debounce from 'lodash.debounce';
import classNames from 'classnames';

import { useStore } from '../../store';
import { log } from '../../utils/jsUtils';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import EmailIcon from '@material-ui/icons/Email';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';

import MessageTenantDialog from '../Tenants/MessageTenantDialog';
import { REF_CHANNELS } from '../../models/Request';
import ForwardRef from '../SharedComponents/ForwardRef';
import AppLoadingSpinner from '../SharedComponents/AppLoadingSpinner';
import UserProfileMenu from '../UserProfileMenu';
import FilterSelect from '../FilterSelect';
import FilterSearchBox from '../FilterSearchBox';
import Broadcast from './Broadcast';
import styles from './Styles/MemberHeader.Style';
import AddTaskDialog from './AddTaskDialog';
import SPBackend from '../../services/SPBackend';


type Props = {
  classes: Object,
  isDemoMode: Boolean,
  mobileOpen: bool,
  usehandleDrawerToggle: Function,
  sendBroadcast: Function
};

const HeaderMember = (props: Props) => {
  const [store, dispatch] = useStore();
  
  const [openAddTaskDialog, setOpenAddTaskDialog] = useState(false);
  const [openMessagingDialog, setOpenMessagingDialog] = useState(false);
  const [checkedState, setCheckedState] = useState(false);
  
  const { 
    filterSearchText,
    filterOptions,
    filterSelections,
    i18n,
    user,
    accountUsage
  } = store;

  const handleExportCSVClick = () => {
    dispatch({
      type: 'ACTION_SET_EXPORT_CSV_REQUESTS', 
      exportCSVRequests: true
    });
  };


  const handlePrintClick = () => {
    dispatch({
      type: 'ACTION_SET_SNACK_MSG', 
      snackMessage: {
        message: 'Generating your PDF. This will take a moment...',
        level: 'success',
        duration: 5000
      }
    });
    dispatch({
      type: 'ACTION_SET_PRINT_REQUESTS', 
      printRequests: true
    });
  };

  const handleSetSearchInputValue = debounce(value => {
    dispatch({
      type: 'ACTION_SET_FILTER_SEARCH_TEXT', 
      filterSearchText: value
    });
    dispatch({
      type: 'ACTION_SET_IS_APP_LOADING',
      isAppLoading: true
    });
  }, 300);
  const handleFilterChange = (
      filterName: 'propertyOptions' | 'staffOptions' | 'tenantOptions' | 'tagOptions' | 'unreadMessagesOnlyOptions', 
      selection: Array<?{value: String, label: String}>) => {
    log('[MemberHeader.handleFilterChange] selection',selection);
    selection = selection.filter(select => select.value !== '' && select.label !== '');
    log('[MemberHeader.handleFilterChange] selection after filter',selection);
      filterSelections[filterName] = selection;
      log('[MemberHeader.handleFilterChange] filterSelections',filterSelections);
      dispatch({
        type: 'ACTION_SET_FILTER_SELECTION',
        filterSelections: filterSelections
      });
      dispatch({
        type: 'ACTION_SET_IS_APP_LOADING',
        isAppLoading: true
      });
  };

  const handleAddTask = (newRequest, selectedUnit) => {
    log('[createTask] newRequest', newRequest);
    if (newRequest.RoomKey === ''){
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: `${i18n.t('Property')} not found. Please select the ${i18n.t('property')} from the list.`,
          level: 'error',
          duration: 8000
        }
      }); 
      return;
    }
    if (newRequest.Issue === ''){
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: 'Issue not found.',
          level: 'error',
          duration: 3000
        }
      }); 
      return;
    }

    // add summary
    let summary = `${i18n.t('Task')} created: ; Source: ${REF_CHANNELS.CONSOLE} ; Additional Info: `;
    if (newRequest.Summary !== '') {
      summary += newRequest.Summary + ';';
    } else {
      summary += 'N/A;';
    }
    // summary += `Building: ${selectedUnit.Property};`;

    const payload = {
      ...newRequest,
      Issue: newRequest.Issue,
      Summary: summary
    };
    log('[handleAddTask] payload ', payload);

    SPBackend.createRequest(payload, user)
      .then((response) => {
        log('[handleAddTask.createRequest]', response);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: `${i18n.t('Task')} created`,
            level: 'success',
            duration: 5000
          }
        });
      })
      .catch(error => {
        log('[handleAddTask.createRequest] error: ', error);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: error.response ? error.response.data : 'An error occured. Please try again',
            level: 'error',
            duration: 5000
          }
        }); 
      });
  };

  const {
    classes,
    isDemoMode,
    usehandleDrawerToggle,
    sendBroadcast
  } = props;
  
  return (
    <AppBar 
      className={classes.appBar}
      position="fixed"
    >
      <Toolbar
        classes={{
          root: classes.toolbarRoot
        }}
        disableGutters
      >
        <Grid 
          container
          direction='column'
        >
          <Grid
            className={classNames(
              classes.rowSub,
              classes.topBar
            )}
            item
          >
            <Grid
              className={classes.rowSubContainer}
              container
              justifyContent='flex-start'
            >
              <IconButton
                aria-label="Open drawer"
                className={classes.menuButton}
                onClick={usehandleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              <Grid item>
                <Typography 
                  className={classes.pageTitle}
                  variant='h3'
                >{i18n.t('Activity')}
                </Typography>
              </Grid>
              <Hidden xsDown>
                <div className={classes.filterContainer}>
                  <FilterSelect
                    className={classNames(classes.filterBox, 'first')}
                    onChangeCallback={response => handleFilterChange('tagOptions', response)}
                    options={filterOptions.tagOptions}
                    placeholder={i18n.t('Tags')}
                    position='first'
                  />
                  <FilterSelect
                    className={classes.filterBox}
                    onChangeCallback={response => handleFilterChange('tenantOptions', response)}
                    options={filterOptions.tenantOptions}
                    placeholder={i18n.t('Tenants')}
                  />
                  <FilterSelect
                    className={classes.filterBox}
                    onChangeCallback={response => handleFilterChange('staffOptions', response)}
                    options={filterOptions.staffOptions}
                    placeholder={i18n.t('Staff')}
                    position='last'
                  />
                </div>
                <div>
                  <FilterSearchBox
                    classes={{
                      root: classes.searchBox
                    }}
                    handleSetSearchInputValue={handleSetSearchInputValue}
                    searchInputValue={filterSearchText}
                  />
                  </div>
              </Hidden>
            </Grid>
            <div className={classes.grow} />
            <UserProfileMenu />
          </Grid>
          <Grid 
            className={classNames(
              classes.rowSub,
              classes.bottomBar
            )}
            item
            
          >
            <Grid
              className={classes.bottomBarContainer}
              container
            >
              {/* <div 
                className={classNames(
                  classes.toggleContainer
                )}
              >
                <Tooltip 
                  aria-label={`Add a ${i18n.t('task')}`}
                  title={`Add a ${i18n.t('task')}`} 
                >
                  <ForwardRef>
                    <Button
                      className={classes.navButton}
                      onClick={() => setOpenAddTaskDialog(true)}
                    >
                      <i className={'icon-add'} />
                      <div>{i18n.t('Task')}</div>
                    </Button>
                  </ForwardRef>
                </Tooltip>
              </div> */}
              
              <div 
                className={classNames(
                  classes.toggleContainer,
                  classes.dividerRight
                  )}
              >
                <Tooltip 
                  aria-label={'Send a Broadcast'}
                  title={'Send a Broadcast'} 
                >
                  <ForwardRef>
                  <Button
                    className={classes.navButton}
                    onClick={() => setOpenMessagingDialog(true)}
                    value='Broadcast'
                  >
                    <i className={'icon-sent'} />
                    <div>{'Broadcast'}</div>
                  </Button>
                  {
                    accountUsage
                    ? <MessageTenantDialog
                      open={openMessagingDialog}
                      setOpen={setOpenMessagingDialog}
                      showToFilters
                      triggerAction={sendBroadcast}
                      />
                    : null
                  }
                    {/* <Broadcast
                      scope='all'
                      triggerAction={sendBroadcast}
                    />*/}
                  </ForwardRef> 
                </Tooltip>
              </div>
              {/* {
                isDemoMode
                ? <div 
                    className={classNames(
                      classes.toggleContainer
                    )}
                  >
                  <Tooltip 
                    aria-label={'Assign'}
                    title={'Assign'} 
                  >
                    <ForwardRef>
                      <Button
                        className={classes.navButton}
                      >
                        <i className={'icon-assign'} />
                        <div>{'Assign'}</div>
                      </Button>
                    </ForwardRef>
                  </Tooltip>
                </div>
                : null
              }
              
              {
                isDemoMode
                ? <div 
                    className={classNames(
                      classes.toggleContainer
                    )}
                  >
                    <Tooltip 
                      aria-label={'Share'}
                      title={'Share'} 
                    >
                      <ForwardRef>
                        <Button
                          className={classes.navButton}
                        >
                          <i className={'icon-share'} />
                          <div>{'Share'}</div>
                        </Button>
                      </ForwardRef>
                    </Tooltip>
                  </div>
                : null
              } */}
              <div className={classes.grow} />
              {/* {
                isDemoMode
                ? <div 
                    className={classNames(
                      classes.toggleContainer
                    )}
                  >
                    <Tooltip 
                      aria-label={'Mark as "in Progress"'}
                      title={'Mark as "in Progress"'} 
                    >
                      <ForwardRef>
                        <Button
                          className={classes.navButton}
                        >
                          <i className={'icon-progress'} />
                          <div>{'In progress'}</div>
                        </Button>
                      </ForwardRef>
                    </Tooltip>
                  </div>
                : null
              }
              {
                isDemoMode
                ? <div 
                    className={classNames(
                      classes.toggleContainer,
                      classes.dividerRight
                    )}
                  >
                    <Tooltip 
                      aria-label={'Mark as "Done"'}
                      title={'Mark as "Done"'} 
                    >
                      <ForwardRef>
                        <Button
                          className={classes.navButton}
                        >
                          <i className={'icon-finished'} />
                          <div>{'Done'}</div>
                        </Button>
                      </ForwardRef>
                    </Tooltip>
                  </div>
                : null
              }

              {
                isDemoMode
                ? <div 
                    className={classNames(
                      classes.toggleContainer
                    )}
                  >
                  <Tooltip 
                    aria-label={'Merge'}
                    title={'Merge'} 
                  >
                    <ForwardRef>
                      <Button
                        className={classes.navButton}
                      >
                        <i className={'icon-merge'} />
                        <div>{'Merge'}</div>
                      </Button>
                    </ForwardRef>
                  </Tooltip>
                </div>
                : null
              }
              {
                isDemoMode
                ? <div 
                    className={classNames(
                      classes.toggleContainer
                    )}
                  >
                  <Tooltip 
                    aria-label={'Split'}
                    title={'Split'} 
                  >
                    <ForwardRef>
                      <Button
                        className={classes.navButton}
                      >
                        <i className={'icon-split'} />
                        <div>{'Split'}</div>
                      </Button>
                    </ForwardRef>
                  </Tooltip>
                </div>
                : null
              }

              <div className={classes.grow} />
              
              {
                isDemoMode
                ? <div 
                    className={classNames(
                      classes.toggleContainer
                    )}
                  >
                  <Tooltip 
                    aria-label={'Sort'}
                    title={'Sort'} 
                  >
                    <ForwardRef>
                      <Button
                        className={classes.navButton}
                      >
                        <i className='icon-sort' />
                        <div>{'Sort'}</div>
                      </Button>
                    </ForwardRef>
                  </Tooltip>
                </div>
                : null
              } */}
              {/* {
                isDemoMode
                ? <div 
                  className={classNames(
                    classes.toggleContainer
                  )}
                  >
                  <Tooltip 
                    aria-label={'Time'}
                    title={'Time'} 
                  >
                    <ForwardRef>
                      <Button
                        className={classes.navButton}
                      >
                        <i className='icon-calendar' />
                        <div>{'Time'}</div>
                      </Button>
                    </ForwardRef>
                  </Tooltip>
                </div>
                : null
              } */}
              <div 
                className={classNames(
                  classes.toggleContainer
                )}
              >
                <Tooltip 
                  aria-label={'Export'}
                  title={'Export'} 
                >
                  <ForwardRef>
                    <Button
                      className={classes.navButton}
                      onClick={() => handleExportCSVClick()}
                    >
                      <DownloadIcon />
                      <div>{'CSV'}</div>
                    </Button>
                  </ForwardRef>
                </Tooltip>
              </div>
              <div 
                className={classNames(
                  classes.toggleContainer
                )}
              >
                <Tooltip 
                  aria-label={'Print to PDF'}
                  title={'Print to PDF'} 
                >
                  <ForwardRef>
                    <Button
                      className={classes.navButton}
                      onClick={() => handlePrintClick()}
                    >
                      <i className={'icon-print'} />
                      <div>{'Print'}</div>
                    </Button>
                  </ForwardRef>
                </Tooltip>
              </div>
              {/* {
                isDemoMode
                ? <div 
                  className={classNames(
                    classes.toggleContainer
                  )}
                  >
                  <Tooltip 
                    aria-label={'Help'}
                    title={'Help'} 
                  >
                    <ForwardRef>
                      <Button
                        className={classes.navButton}
                      >
                        <i className='icon-help' />
                        <div>{'Help'}</div>
                      </Button>
                  </ForwardRef>
                  </Tooltip>
                </div>
                : null
              } */}
            </Grid>
          </Grid>
          <AppLoadingSpinner />
        </Grid>
      </Toolbar>
      <AddTaskDialog
        open={openAddTaskDialog}
        setOpen={setOpenAddTaskDialog}
        triggerAction={handleAddTask}
      />
    </AppBar>
  );
};

export default withStyles(styles)(HeaderMember);
