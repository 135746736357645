// @flow

import React, { useState } from 'react';
import classNames from 'classnames';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';

import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import HeaderTopBar from '../HeaderTopBar';
import SPBackend from '../../services/SPBackend';
import AppLoadingSpinner from '../SharedComponents/AppLoadingSpinner';

import { withStyles } from '@material-ui/core/styles';
import styles from '../Feed/Styles/MemberHeader.Style';

type Props = {
  classes: Object,
  mobileOpen: bool, 
  usehandleDrawerToggle: Function,
  setProperties: Function
};


const BotSettingsHeader = (props: Props) => {
  const {
    classes,
    usehandleDrawerToggle
  } = props;

  const [store] = useStore();
  const {
    i18n
  } = store;

  return (
    <AppBar 
      className={classes.appBar}
      position="fixed"
    >
      <Toolbar
        className={classes.toolbar}
        disableGutters
      >
        <Grid 
          container
          direction='column'
        >
          <HeaderTopBar
            usehandleDrawerToggle={usehandleDrawerToggle}
          >
            <Typography 
              className={classes.pageTitle}
              variant='h3'
            >{i18n.t('Neo the bot')}</Typography>
          </HeaderTopBar>
          <Grid
            className={classNames(
              classes.rowSub,
              classes.bottomBar
            )}
            item
          >
            <Grid container>
              {/* <div 
                className={classNames(
                  classes.toggleContainer,
                  classes.dividerRight
                )}
              >
                <Tooltip 
                  aria-label={`Add new ${i18n.t('property')}`}
                  title={`Add new ${i18n.t('property')}`} 
                >
                  <Button
                    className={classes.navButton}
                  >
                    <i className={'icon-add'} />
                    <div>{i18n.t('Property')}</div>
                  </Button>
                </Tooltip>
              </div> */}
              <div className={classes.grow} />
            </Grid>
          </Grid>
          <AppLoadingSpinner />
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(BotSettingsHeader);
