// @flow

import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CreditCardForm from '../SharedComponents/CreditCardForm';
import SPBackend from '../../services/SPBackend';
import StripeElementWrapper from './StripeElementWrapper';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/ConfirmTopupPurchaseDialog.Style';
import Typography from '@material-ui/core/Typography';

import type { Plan } from '../../services/SPBackend';

type Props = {
  classes: Object,
  setOpen: Function,
  setSelectedPlan: Function,
  isActionLoading: Boolean,
  open: boolean,
  selectedPlan: Plan,
  triggerAction: Function,
  defaultPaymentMethod: Object
};

// eslint-disable-next-line react/no-multi-comp
const ConfirmTopupPurchaseDialog = (props: Props) => {
  const {
    classes,
    selectedPlan,
    setSelectedPlan,
    setOpen,
    open,
    triggerAction,
    isActionLoading,
    defaultPaymentMethod
  } = props;
  const [store] = useStore();
  const {
    user
  } = store;
  

  log('[confirmtopup render] defaultPaymentMethod: ', defaultPaymentMethod);
  const [newDefaultPM, setNewDefaultPM] = useState(null);

  const onPaymentMethodSelected = (pm) => {
    log('[ConfirmTopupPurchaseDialog.onPaymentMethodSelected] pm: ', pm);
    setNewDefaultPM(pm);
  };

  return (<>
    {
      selectedPlan
      ? <Dialog aria-labelledby='form-confirm-topup'
          onClose={() => setOpen(false)}
          open={open}
        >
            <DialogTitle className={classes.dialogHeader}>{'Checkout'}</DialogTitle>
            <DialogContent className={classNames(classes.grow, classes.flexContainer)}>
              <div className={classes.itemHeader}>Item</div>
              <div className={classes.itemList}>
                <div className={classes.itemRow}>
                  <div className={classes.itemDesc}>
                    {`${Number(selectedPlan.UsageLimit).toLocaleString()} Credits Recharge`}
                  </div>
                  <div className={classes.itemPrice}>
                    ${selectedPlan.Price}
                  </div>
                </div>
              </div>
              <br />
              {
                defaultPaymentMethod
                ? <Typography
                    className={classes.captionText}
                    variant='body1'
                  >Your credit card ending in {defaultPaymentMethod.card.last4} will be charged.</Typography>
                : !newDefaultPM
                  ? [
                    <Typography 
                      className={classes.captionText}
                      variant='body1'
                    ><b>No payment method on file yet. Enter your card details:</b></Typography>,
                    <CreditCardForm 
                      onPaymentMethodSelected={onPaymentMethodSelected}
                    />
                  ]
                  : <Typography
                      className={classes.captionText}
                      variant='body1'
                    >Your credit card ending in {newDefaultPM.card.last4} will be charged.</Typography>
              }
              
              <br />
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                onClick={(event) => {
                  event.preventDefault();
                  setSelectedPlan(null);
                  setOpen(false);
                }}
                type='button'
              >
                {'Cancel'}
              </Button>
              
              <Button
                color='primary'
                disabled={isActionLoading || (
                  !defaultPaymentMethod && !newDefaultPM
                )}
                onClick={triggerAction}
                type='submit'
                variant='contained'
              >
                {`Pay $${selectedPlan.Price}`}
              </Button>
            </DialogActions>
        </Dialog>
      : null
    }
    </>
  );
};

export default withStyles(styles)(ConfirmTopupPurchaseDialog);
