// @flow

import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import ReactCodeInput from '../../utils/react-code-input';
import classNames from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import { redirectBasedOnAccountStatus } from '../../utils/pathUtils';
import useTimer from '../../hooks/useTimer';
import { useStore } from '../../store';
import AppLoadingSpinner from '../SharedComponents/AppLoadingSpinner';
import { log, formatPhoneNumberForFrontend } from '../../utils/jsUtils';
import BasicHeader from '../Billing/BasicHeader';
import SPBackend from '../../services/SPBackend';
import { formatPhoneNumberForBackend } from '../../utils/jsUtils';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/VerifyPhoneHome.Style';

const VerifyPhoneHome = (props: Props) => {
  const { classes } = props;
  const history = useHistory();
  const { handleSubmit, setValue, control } = useForm();

  const [registrationError, setRegistrationError] = useState('');
  const [sendingCode, setSendingCode] = useState(false);
  const [userPhone, setUserPhone] = useState('');
  const [isVerifyCodeLoading, setIsVerifyCodeLoading] = useState(false);
  const [isReadyToSendNewCode, setIsReadyToSendNewCode] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [codeValues, setCodeValues] = useState(['', '', '', '']);
  const [sendNewCodeTimer, setSendNewCodeTimer] = useState(0);
  const [timeLeftBeforeReadyToSendAgain, setTimeLeftBeforeReadyToSendAgain] = useState(0);
  const [store, dispatch] = useStore();
  const {
    user,
    loggedUser
  } = store;

  const formEl = useRef();

  const [timeLeft, setTimeLeft] = useTimer((ticker) => {
    setTimeLeftBeforeReadyToSendAgain(ticker);
  }, () => {
    console.log('setIsReadyToSendNewCode(true);');
    setIsReadyToSendNewCode(true);
  }, sendNewCodeTimer);
  
  useEffect(() => {
    if (loggedUser) {
      log('[useEffect] loggedUser: ', loggedUser);
      setUserPhone(formatPhoneNumberForFrontend(loggedUser.Phone));
      setValue('phone', formatPhoneNumberForFrontend(loggedUser.Phone));
    }
  }, [loggedUser]);

  const onFieldChange = () => {
    setRegistrationError('');
  };

  const onCodeChange = (c) => {
    log('[onCodeChange] c: ', c);
    const values = Array(4).fill('');
    const split = c.split('');
    split.forEach((item, i) => {
      const cursor = i;
      if (cursor < 4) {
        values[cursor] = item;
      }
    });
    setCodeValues(values);

  };

  const handleVerifyPhone = (registration) => {
    setSendingCode(true);
    setSendNewCodeTimer(60);
    setIsReadyToSendNewCode(false);
    log('[handleVerifyPhone] registration: ', registration);
    const phoneToVerify = typeof registration.phone !== 'undefined' ? registration.phone : userPhone;
    log('[handleVerifyPhone] phoneToVerify: ', phoneToVerify);
    const p = formatPhoneNumberForBackend(phoneToVerify);
    log('[handleVerifyPhone] p: ', p);
    if (p !== null) {
      setUserPhone(phoneToVerify);
      setCodeValues(['', '', '', '']);
      log('[handleVerifyPhone] formatPhoneNumberForBackend(phone): ', p);
      log('[handleVerifyPhone] formatPhoneNumberIntl(p): ', formatPhoneNumberIntl(phoneToVerify));
      SPBackend.sendCodeToVerifyNb(user, p)
        .then((response) => {
          log('[handleCaptcha] response -: ', response.data);
          setIsCodeSent(true);
          setSendingCode(false);
        })
        .catch((err) => {
          setSendingCode(false);
          log('[handleCaptcha] verifyCaptcha error -: ', err);
        });
      }
  };

  const onCodeComplete = (code) => {
    log('[onCodeComplete] code: ', code);
    setIsVerifyCodeLoading(true);
    // TODO: set timer and show error if code isn't verified after 10s.
    SPBackend.verifyPhoneNbCode(user, formatPhoneNumberForBackend(userPhone), code)
      .then((response) => {
        log('[verifyPhoneNbCode] response: ', response);
        // set error msg in state to display
        // redirect to next step?
        // update store to show that user phone is now verified
        const {
          user,
          success,
          message
        } = response.data;
        setIsVerifyCodeLoading(false);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: message,
            level: success,
            duration: 5000
          }
        });
        if (success && user) {
          dispatch({
            type: 'ACTION_SET_LOGGED_USER',
            user: user
          });
          // redirect
          redirectBasedOnAccountStatus(history, {
            ...store,
            user
          });
        }
      })
      .catch((err) => {
        log('[handleCaptcha] verifyCaptcha error -: ', err);
        // set error msg in state to display
        setIsVerifyCodeLoading(false);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: err.message,
            level: 'error',
            duration: 5000
          }
        });
      });
  };

  return (
    <Grid className={classes.container}
          container
    >
      <Helmet>
        <title>Neo | Verify Phone</title>
      </Helmet>
      <BasicHeader title="Verify your phone" />
      <AppLoadingSpinner />
      <form
        onSubmit={handleSubmit(handleVerifyPhone)}
        ref={formEl}
        style={{width: '100%'}}
      >
          {
            registrationError !== ''
            ? <Typography
                className={classes.formErrorResponse}
                variant={'body1'}
              >{registrationError}</Typography>
            : null
          }
        <Paper classes={{
          root: classes.formPaper
          }}
                elevation={2}
                square
        >
          <Grid
                className={classes.contentCtr}
                item
                xs={12}
          >
            <Typography variant='h3'>
              Verify your personal number
            </Typography>
            <Typography variant='body1'>
              We will send a verification code to your phone number
            </Typography>
            {
              isCodeSent
              ? <div className={classes.readonlyPhoneCtr}>
                <Typography 
                  className={classes.readonlyPhone}
                  variant='h4'
                >
                  {formatPhoneNumberIntl(userPhone)}
                  <IconButton 
                    aria-label='Search'
                    className={classes.iconButton}
                    onClick={e => {
                      e.stopPropagation();
                      setIsCodeSent(false);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Typography>
              </div>
              : <Controller
                  control={control} 
                  name='phone'
                  render={({ onChange, value }) => (
                    <PhoneInput 
                      className={classNames(
                        classes.phoneInputCtr,
                        classes.newInputForm,
                        'outlineInput')}
                      defaultCountry='US'
                      onChange={(e) => {
                        onChange(e);
                        onFieldChange();
                      }}
                      placeholder='Your phone number' 
                      value={value} 
                    />
                  )}
                  rules={{ required: true }}
                />
            }
            
            {
              !isCodeSent
              ? <Button
                  className={classes.signupBtn}
                  color='primary'
                  disabled={sendingCode}
                  type='submit'
                  variant='contained'
                >
                  {'Send Code'}
              </Button>
              : null
            }

            {
              isCodeSent
              ? <>
                <ReactCodeInput
                  className={classes.codeInput}
                  fields={4}
                  loading={isVerifyCodeLoading}
                  onChange={onCodeChange}
                  onComplete={onCodeComplete}
                  type='number'
                  values={codeValues}
                />
                <a alt='resend'
                  className={classNames(
                    !isReadyToSendNewCode ? classes.disabled : null
                  )}
                  href='#'
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    isReadyToSendNewCode && handleSubmit(handleVerifyPhone)();
                  }}
                  type='submit'
                >
                  <Typography variant='body1'>Resend Code{ timeLeftBeforeReadyToSendAgain > 0 ? ` ( ${timeLeftBeforeReadyToSendAgain} )` : ''}</Typography>
                </a>
              </>
              : null
            }  
          
          </Grid>
        </Paper>
      </form>
    </Grid>
  );
};

export default withStyles(styles)(VerifyPhoneHome);