// @flow

import * as R from 'ramda';

/**
 * @param model Object: the model to parse
 * @param paramsPath Array<string> path to get within account
 * @param defaultVal val to return if nothing found within account for that path.
 */
const getValAtPath = (model: Object, paramsPath: Array<string>, defaultVal: string) => {
  try {
    const res = R.path(paramsPath, model);
    if (typeof res !== 'undefined') {
      return res;
    }
    return defaultVal;
  } catch (e) {
    console.log('[getAccountSettings] error: ', e);
    return defaultVal;
  }
};

export default {
  getValAtPath
};
