// @flow

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { useStore } from '../../store';

import SPBackend from '../../services/SPBackend';
import { log, formatPhoneNumberForBackend } from '../../utils/jsUtils';
import SimpleMsgDialog from '../SharedComponents/SimpleMsgDialog';
import AppLoadingSpinner from '../SharedComponents/AppLoadingSpinner';

import BasicHeader from '../Billing/BasicHeader.js';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/TwilioNumberHome.Style';

type Props = {
  classes: Object,
  loadingModuke: Object
};

const TwilioNumberHome = (props: Props) => {
  
  const [store, dispatch] = useStore();
  const {
    user,
    accountSettings,
    accountId,
    loadingModule
  } = store;
  const history = useHistory();
  const { classes } = props;
  const [phoneNumbers, setPhoneNumbers] =  useState([]);
  const [openConfirmBuyNumber, setOpenConfirmBuyNumber] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [hover,setHover] = useState(null);
  const [numberSentToConfirm, setNumberSentToConfirm] = useState(false);
  const [textValue, setTextValue] =  useState('');
  const [countryValue, setCountryValue] =  useState('US');

  const handleHoverToggle = (id) => { 
    setHover(id);
  };
  const handleHoverToggleOff = () => { 
    setHover(null);
  };

  useEffect(() => {
    dispatch({
      type: 'ACTION_SET_IS_LOADING_MODULE',
      module: 'phoneNumbers',
      loading: true
    });
    if (phoneNumbers.length === 0 && user !== null) {
      handleNumberSearch('', 'US');
    }
  }, [user]);

  const handleNumberSearch = (param:string, country: string) => {
    if (user !== null) {
      const searchPayload = {
        searchBy: param,
        country: country
      };
      log('phoneNumberSearch', searchPayload);
      SPBackend.fetchPhoneNumbers(user, searchPayload)
      .then((response) => {
        log('phoneNumbers fetched', response.data);
        setPhoneNumbers(response.data);
        dispatch({
          type: 'ACTION_SET_IS_LOADING_MODULE',
          module: 'phoneNumbers',
          loading: false
        });
      })
      .catch(error => {
        log('[fetchPhoneNumbers] fetchPhoneNumbers error.response: ', error.response);
      });
    }
  };

  const handlePickNumber = (phoneNumberObject: Object) => {
    log('pick phone number', phoneNumberObject);
    const number = phoneNumberObject.phoneNumber;
    const buyNumberPayload = {
      phoneNumber: formatPhoneNumberForBackend(number),
      accountId,
      twilioAccountSID: accountSettings.Twilio.SubaccountSID
    };
    setNumberSentToConfirm(true);
    dispatch({
      type: 'ACTION_SET_DEFAULT_BUILDING_PHONE', 
      Phone: number
    });
    SPBackend.buyPhoneNumber(user, buyNumberPayload)
    .then((response) => {
      log('[buyPhoneNumber] phoneNumber bought ', response.data);
      history.push('/member');
    })
    .catch(error => {
      log('[buyPhoneNumber] buyPhoneNumber error.response: ', error.response);
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: 'Sorry this number has been taken! Please refresh this screen and try picking a new number',
          level: 'error',
          duration: 3000
        }
      });
      dispatch({
        type: 'ACTION_SET_DEFAULT_BUILDING_PHONE', 
        Phone: ''
      });
    });
  };
  
  return (
      <Grid className={classes.contentCtr}
            container
      >
      <Helmet>
        <title>Neo | Pick your number</title>
      </Helmet>
      <BasicHeader title={'Pick Your Free Business Phone Number'}/>
      <AppLoadingSpinner />
      {
        accountSettings && accountSettings.Twilio && accountSettings.Twilio.SubaccountSID !== '' && accountSettings.Twilio.SubaccountSID !== null
        ? 
          <Grid
              className={classes.contentCtr}
              item
              xs={12}
          >
          <div className={classes.welcomeText}>
          <span>{'Last step!'}</span>
          <Typography variant='h3'>{'Pick your number'}</Typography>
          </div>
          <Paper classnames={classes.formPaper} 
                  elevation={2}
                  square
          >
          <div className={classes.inputCtr}>
            <FormControl 
              className={classes.countryDropdown}
            >
              <InputLabel id="country">{'Country'}</InputLabel>
              <Select
                id="country"
                labelId="country"
                name='InviteChannel'
                onChange={(e) => {
                  setCountryValue(e.target.value);
                  handleNumberSearch(textValue, e.target.value);
                  }}
                value={countryValue}
              >
                <MenuItem 
                  value='US'
                >United States</MenuItem>
                <MenuItem 
                  value='CA'
                >Canada</MenuItem>
              </Select>
            </FormControl>
            <TextField className={classes.localitySearchBox}
                        id="locality"
                        label="Search by Area Code or State (e.g. '917', 'NY')"
                        onChange={e => {
                          setTextValue(e.target.value);
                          handleNumberSearch(e.target.value, countryValue);
                          }}
                        placeholder="Search Numbers by Area Code or State (e.g. '917', 'NY')"
                        value={textValue}
            />
          </div>
          <div>
          <TableContainer className={classes.table}>
            <Table aria-label="simple table"
                    
                    stickyHeader
            >
              <TableHead>
              {
                phoneNumbers && phoneNumbers.length > 0
                ?
                <TableRow>
                  <TableCell align='left'
                              className={classes.headerCell}                             
                  ><b>{'Number'}</b></TableCell>
                  <TableCell align='left'
                              className={classes.headerCell}                            
                  ><b>{'Locality'}</b></TableCell>
                  <TableCell align='left'
                              className={classes.headerCell} 
                  />
                </TableRow>
                : null
              }
              </TableHead>
                <TableBody>
                { phoneNumbers && phoneNumbers.length > 0 && !loadingModule.phoneNumbers
                  ?
                    phoneNumbers.map((number, index) => (
                    <TableRow key={number.friendlyName}
                              onMouseEnter={()=> handleHoverToggle(index)}
                              onMouseLeave={handleHoverToggleOff}
                              style={hover === index ? {background: '#d3d3d3'}: {}}
                    >
                      <TableCell align='left' 
                                  className={classes.tableCell}
                      >{number.friendlyName}</TableCell>
                      <TableCell align='left'
                                  className={classes.tableCell}
                      >{number.locality}{number.locality ? ', ' : ''}{number.region}</TableCell>
                      <TableCell align='right' 
                                className={classes.tableCell}
                      >
                        <Button color="primary"
                                onClick={(e) => {
                                        e.stopPropagation();
                                        setOpenConfirmBuyNumber(true);
                                        setPhoneNumber(number);
                                        log('[TwilioNumberContent] buying number ', number);
                                      }}
                                variant="contained"
                        > 
                        {'PICK'}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                  : <TableRow>
                    <TableCell className={classes.tableCtr}>
                    <div >
                      <p>
                        {'No phone numbers found for your search.'}
                      </p>
                      
                      <Button color="primary"
                              href="#text-buttons"
                              onClick={() => { 
                                handleNumberSearch('', 'US');
                                setTextValue('');
                              }}
                      >
                        {'Clear search'}
                      </Button>
          
                    </div>
                   </TableCell>
                  </TableRow>
                }

              </TableBody>
            </Table>
          </TableContainer>
          </div>
          <SimpleMsgDialog 
                className={classes.dialogBox}
                dialogActions={
                    <div>
                        <Button
                          className={classes.actionButtons}
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenConfirmBuyNumber(false);
                          }}
                          variant='outlined'
                        >{'GO BACK'}</Button>
                        <Button
                          className={classes.actionButtons}
                          color="primary"
                          disabled={numberSentToConfirm}
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePickNumber(phoneNumber);
                            }}
                          variant='contained'

                        >{'CONFIRM'}</Button>
                    </div>
                  } 
                dialogContent={
                        <div>
                          {'This will be your business number:'}
                          <br />
                          <br />
                            <div style = {{ fontSize: '2rem'}}>
                              <b>{phoneNumber.friendlyName}</b>
                            </div>
                        </div>
                      }
                dialogTitle={'Are you sure?'}
                open={openConfirmBuyNumber}
          />
          </Paper>
          </Grid>
        : <div className={classes.contentCtr}>
            <p>
              {'Fetching account details...'}
            </p>
          </div>
      }
      </Grid>  
  );
};

export default withStyles(styles)(TwilioNumberHome);