import { log, isObjectEmpty } from '../utils/jsUtils';
import * as R from 'ramda';

/**
 * 
 * @param {*} messageProps supports only 1 attachment at a time for now
 */
const calculateCreditsForMsg = (messageProps: {message: string, attachment: ?Object}) => {
  if (typeof messageProps !== 'undefined' && messageProps !== null) {
    let creditsNb = 0;
    const text = messageProps.message || '';
    // console.log('[calculateCreditsForMsg] messageProps: ', messageProps);
    const AttachmentNb = isObjectEmpty(messageProps.attachment) ? 0 : 1;
    // console.log('[calculateCreditsForMsg] AttachmentNb: ', AttachmentNb);
    const textCreditsNb = Math.floor(text.length / process.env.REACT_APP_CHARS_NB_PER_CREDIT) + 1;
    const attchCreditsNb = AttachmentNb * process.env.REACT_APP_CREDITS_PER_ATTCH;
    // console.log('[calculateCreditsForMsg] textCreditsNb, attchCreditsNb: ', textCreditsNb, attchCreditsNb);
    creditsNb = textCreditsNb + attchCreditsNb;
    // console.log('[calculateCreditsForMsg] Total credits to log: ', creditsNb);
    return creditsNb;
  }
  return 0;
};

type SubmitSendMessageProps = {
  message: string,
  attachments: Array<Object>,
  setErrorMsg: {msg: string, function: 'upgrade'|'recharge'},
  setIsSending: Function,
  toTenantsIds: Array<string>,
  setOpenDialog: Function,
  triggerAction: Function,
  emptyForm: Function,
  accountUsage: Object,
  dispatch: Function
}
const submitSendMessage = (props: SubmitSendMessageProps) => {
  const {
    message,
    attachments,
    setErrorMsg,
    setIsSending,
    toTenantsIds,
    setOpenDialog,
    triggerAction,
    emptyForm,
    billing,
    accountUsage,
    dispatch
  } = props;
  log('[submitSendMessage] props: ', props);
  // FIXME: only supports 1 attachment.
  const [attachment] = attachments;

  setIsSending(true);
  setErrorMsg({
      function: '',
      msg: ''
  });

  if (typeof toTenantsIds === 'undefined' || toTenantsIds === null || message === '') {
    setErrorMsg({
      function: '',
      msg: 'Don\'t forget the message :)'
    });
    setIsSending(false);
    return;
  }
  // calc nb of credits required
  const creditsNeeded = calculateCreditsForMsg({
    message,
    attachment
  });
  log('[sendPM] creditsNeeded: ', creditsNeeded);
  log('[sendPM][PM] billing: ', billing);
  // verify that credit balance is sufficient
  if (typeof accountUsage === 'undefined') {
    dispatch({
      type: 'ACTION_SET_SNACK_MSG', 
      snackMessage: {
        message: 'Your account is still being setup, refresh this page in a few minutes.',
        level: 'error',
        duration: 3000
      }
    });
  } else if (accountUsage && accountUsage.TotalCreditsBalance < creditsNeeded) {
    setErrorMsg({
      function: billing && billing.subscriptionStatus === 'active' ? 'recharge' : 'upgrade',
      msg: `You'll need ${(creditsNeeded & toTenantsIds.length) - accountUsage.TotalCreditsBalance} additional credit(s) to send this message.`
    });
  } else if (billing.subscriptionStatus !== 'active' && billing.subscriptionStatus !== 'trialing') {
    // invalid subscription
    // TODO: verify that subscription is not expired.
    log('[MessageTenantDialog][PM] invalid subsr...');
    setErrorMsg({
      function: 'upgrade',
      msg: 'You\'ll need to activate your account to start sending messages.'
    });
    // TODO: no valid subscription? Show upgrade popup
    
    return;
  } else {
    // proceed to sending the msg.
    triggerAction(
      toTenantsIds,
      {
        message,
        attachment
      },
      () => {
        log('[triggerAction] done!');
        emptyForm();
        if (typeof setOpenDialog !== 'undefined') {
          setOpenDialog(false);
        }
        setIsSending(false);
      },
      (errorMsg) => {
        setIsSending(false);
        setErrorMsg({
          function: '',
          msg: errorMsg
        });
      }
    );
  }
};

export {
  calculateCreditsForMsg,
  submitSendMessage
};