// @flow

import React from 'react';
import classNames from 'classnames';
import red from '@material-ui/core/colors/red';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TagRenderer from '../SharedComponents/TagRenderer';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/ConfirmRemoveTagDialog.Style';
import type { Tag } from '../../services/SPBackend';

type Props = {
  classes: Object,
  setOpen: Function,
  open: boolean,
  actionConfirm: Function,
  tag: Tag
}

const ConfirmRemoveTagDialog = (props: Props) => {
  const {
    classes,
    setOpen,
    open,
    actionConfirm,
    tag
  } = props;
  const [store] = useStore();
  const {
    i18n
  } = store;

  return (
    <div>
    {
      (tag !== null && typeof tag !== 'undefined')
      ? <Dialog
          aria-labelledby='form-add-property'
          onClose={() => setOpen(false)}
          open={open}
        >
          <DialogTitle id='form-dialog-title'>
          {'HEADS UP!'}
          </DialogTitle>
          <DialogContent className={classNames(classes.grow, classes.flexContainer)}>
            <DialogContentText>
              {'You are about to remove this tag from your account.'}<br />
              {'Customer profiles will also be updated to remove this tag.'}
            </DialogContentText>
              <div className={classes.tagCtr}>
                <TagRenderer
                  readonly
                  tagsIds={[tag._id]}
                />
              </div>
              {'Are you sure?'}
          </DialogContent>
          <DialogActions>
            <Button 
              color='primary'
              onClick={() => setOpen(false)}
              type='button'
            >
              {'Cancel'}
              </Button>
            <Button 
              color='primary'
              onClick={() => { 
                actionConfirm(tag); 
                setOpen(false); 
                }
              }
              variant='contained'
            >
              {'Remove'}
              </Button>
          </DialogActions>
        </Dialog>
      : null
    }
    </div>
  );
};

export default withStyles(styles)(ConfirmRemoveTagDialog);