// @flow

import React, { useState, useEffect, useRef } from 'react';
import { useStore } from '../../store';
import { log } from '../../utils/jsUtils';

import * as R from 'ramda';
import classNames from 'classnames';
import invert from 'invert-color';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SaveIcon from '@material-ui/icons/Save';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { useFocus } from '../../utils/globalHooks';
import { getTag } from '../../models/Request';
import SPBackend from '../../services/SPBackend';
import TagColorPicker from '../SharedComponents/TagColorPicker';

import { withStyles } from '@material-ui/core/styles';
import combineStyles from '../../utils/stylesUtils';
import styles from './Styles/TagCustomersPicker.Style';
import commonFormElementsStyles from '../Styles/CommonFormElements.Style';

import type { Task, Tag } from '../../services/SPBackend';

type Props = {
  callbackOnHideTagsPicker: Function,
  callbackOnShowTagsPicker: Function,
  classes: Object,
  task: Task,
  tagActionCallback: Function
}

type NewTagInputProps = {
  classes: Object,
  handleNewTagName: Function,
  handleColorPickedForNewTag: Function,
  newTag: Tag,
  handleSetTag: Function
}
// eslint-disable-next-line react/no-multi-comp
const NewTagInput = (props: NewTagInputProps) => {
  const {
    classes,
    handleNewTagName,
    handleColorPickedForNewTag,
    newTag,
    handleSetTag
  } = props;

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [inputRef, setInputFocus] = useFocus();
  
  const handleKeyPress = (e) => {
    e.stopPropagation();
    log('[TagCustomersPicker] handleKeyPress');
    // log('[NewTagInput] keypress...');
    if (e.key === 'Enter') {
      // log('[NewTagInput] saving new tag: ', newTag);
      handleSetTag(e, newTag);
    }
  };
  
  const handleColorPicked = (color, event) => {
    // log('[TagCustomersPicker] handleColorPicked');
    handleColorPickedForNewTag(color.hex);
    setDisplayColorPicker(false);
    setTimeout(() => {
      setInputFocus();
    }, 100);
  };
  
  useEffect(() => {
    // log('[TagCustomersPicker] useEffect[]');
    setTimeout(() => {
      setInputFocus();
    }, 100);
  }, []);

  // log('[TagCustomersPicker][render] props: ', props);

  return (<div>
    <OutlinedInput
      autoFocus
      classes={{
        root: classes.inlineRowTextRoot,
        input: classes.inlineRowTextInput
      }}
      endAdornment={<div>
        {
          newTag.Name !== ''
          ? <IconButton
              aria-label="save"
              className={classes.saveIcon}
              color="primary"
              component="span"
              onClick={(e) => handleSetTag(e, newTag)}
            >
              <SaveIcon />
            </IconButton>
          : null
        }</div>
      }
      id='newTagName'
      inputRef={inputRef}
      key={'newtag-picker'}
      name='newTagName'
      onChange={handleNewTagName(newTag)}
      onKeyDown={e => {log('keydown...'); e.stopPropagation();}}
      onKeyPress={handleKeyPress}
      placeholder='New tag name'
      startAdornment={
        <InputAdornment
          className={classes.colorPickerIcon}
          position="start"
        >
          <TagColorPicker 
            color={newTag.Color}
            displayColorPicker={displayColorPicker}
            handleColorPicked={handleColorPicked}
            setDisplayColorPicker={setDisplayColorPicker}
          />
        </InputAdornment>
      }
      type='text'
      value={newTag.Name}
    />
  </div>
  );
};

// eslint-disable-next-line react/no-multi-comp
const TagCustomersPicker = (props: Props) => {
  const { 
    classes,
    anchorEl,
    callbackOnShowTagsPicker,
    callbackOnHideTagsPicker,
    open,
    setOpen,
    tagActionCallback
  } = props;
  const MENU_ITEM_HEIGHT = 48; //

  const [store, dispatch] = useStore();
  const {
    tags,
    user
  } = store;

  const [persistHover, setPersistHover] = useState(false);
  const [newTag, setNewTag] = useState({
    Name: '',
    Color: '#CCCCCC'
  });

  useEffect(() => {
    setOpen(open);
    if (open) {
      if (typeof callbackOnShowTagsPicker !== 'undefined') {
        callbackOnShowTagsPicker();
      }
    } else if (typeof callbackOnHideTagsPicker !== 'undefined') {
      callbackOnHideTagsPicker();
    }
    log('[TagCustomersPicker] useEffect.. open: ', open ? 'true' : 'false');
  }, [open]);

  const toggleTagsMenu = () => {
    log('[toggleTagsMenu]...');
    setOpen(!open);
  };

  /**
   * 
   * @param {T} task
   * @param {event} e
   * @param {Tag} tag
   * @param {bool} isNewTag => If true, we update the tag list in store with new tag
   */
  const handleSetTag = (e, tag, isNewTag=false) => {
    e.stopPropagation();
    log('[handleSetTag] e: ', e);
    log('[handleSetTag] tag: ', tag);
    dispatch({
      type: 'ACTION_SET_IS_APP_LOADING',
      isAppLoading: true
    });
    toggleTagsMenu(e);
    SPBackend.setTag({
      Name: tag.Name,
      Color: tag.Color
    }, user)
      .then(response => {
        const { data } = response;
        const { tag: persistedTag } = data;
        dispatch({
          type: 'ACTION_ADD_TAG', 
          tag: persistedTag
        });
        if (typeof tagActionCallback !== 'undefined') {
          tagActionCallback(persistedTag);
        }
        dispatch({
          type: 'ACTION_SET_IS_APP_LOADING',
          isAppLoading: false
        });
      })
      .catch(e => {
        log('[handlehandleSetTag] e', e);
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: e.message,
            level: 'error',
            duration: 2000
          }
        });
        dispatch({
          type: 'ACTION_SET_IS_APP_LOADING',
          isAppLoading: false
        });
      });
  };

  const handleNewTagName = (newTag) => (e) => {
    log('[handleNewTagName]...');
    setNewTag({...newTag, Name: e.target.value});
  };

  const handleColorPickedForNewTag = (color) => {
    log('[handleColorPickedForNewTag] newTag: ', newTag);
    setNewTag({
      ...newTag,
      Color: color
    });
  };

  // log('[TagCustomersPicker] anchorEl: ', anchorEl);
  // log('[TagCustomersPicker] props: ', props);
  
  const Rows = (tags) => {
    return (
        tags.map((tag, tagIdx) => (
          <MenuItem
            classes={{
              root: classes.subListItem
            }}
            dense
            key={`tag-${tagIdx}`}
            onClick={(e) => handleSetTag(e, tag)}
            selected={false}
            style={{
              color: invert(tag.Color, { black: '#080808', white: '#fafafa' }),
              background: tag.Color
            }}
          >{tag.Name}</MenuItem>
        ))
    );
  };

  return (<React.Fragment>
    {
      !open
      ? null
      : 
        <Menu
          anchorEl={anchorEl}
          classes={{
            paper: classes.menuRoot,
            list: classes.menuList
          }}
          disableAutoFocusItem
          elevation={0}
          id="submenu-tag"
          keepMounted
          key={`tag-picker`}
          MenuListProps={{
            classes: {
              root: classes.subMenuListRoot
            }
          }}
          onClick={(e) => e.stopPropagation()}
          onClose={toggleTagsMenu}
          open={open}
          PaperProps={{
            style: {
              maxHeight: MENU_ITEM_HEIGHT * 4.5
            }
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <MenuItem 
            classes={{
              root: classes.subListItemAddNew
            }}
            key={'mi-tag-picker'}
            onClick={(e) => e.stopPropagation()}
            selected={false}
          >
            <FormControl
              className={classNames(classes.newInputForm)}
              key={'form-tag-picker'}
              variant="outlined"
            >
              <NewTagInput
                classes={classes}
                handleColorPickedForNewTag={handleColorPickedForNewTag}
                handleNewTagName={handleNewTagName}
                handleSetTag={handleSetTag}
                newTag={newTag}
              />
            </FormControl>
          </MenuItem>
          {Rows(tags)}
          
        </Menu>
    }
    </React.Fragment>
  );
};

const combinedStyles = combineStyles(
  styles,
  commonFormElementsStyles
);

export default withStyles(combinedStyles)(TagCustomersPicker);
