// @flow

import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import classNames from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import { log } from '../../utils/jsUtils';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/AddStaffDialog.Style';

type Props = {
  classes: Object,
  setOpen: Function,
  open: boolean,
  triggerAction: Function
};

// eslint-disable-next-line react/no-multi-comp
const AddStaff = (props: Props) => {
  const { classes, setOpen, open, triggerAction } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { register, unregister, handleSubmit, errors, clearErrors, watch, setValue } = useForm({
    FirstName: '',
    LastName: '',
    Phone: '',
    Email: '',
    inviteChannel: 'sms',
    IsAdmin: false,
    IsManager: false,
    IsOwner: false
  }); // initialise the hook
  const inviteChannels = watch('InviteChannel', 'sms');
  const phone = watch('phone', '');
  log('InviteChannel, errors: ', inviteChannels, errors);

  useEffect(() => {
    register({
      name: 'InviteChannel',
      value: 'sms'
    }, { required: true });
    
    return () => unregister('InviteChannel'); // unregister input after component unmount
  }, [register]);

  useEffect(() => {
    log('[useEffect] inviteChannels, errors: ', inviteChannels, errors);
    if (inviteChannels === 'email') {
      clearErrors('phone');
      register({ name: 'Email' }, { required: true });
      register({ name: 'Phone' }, { required: false });
    } else {
      clearErrors('email');
      register({ name: 'Email' }, { required: false });
      register({ name: 'Phone' }, { required: true });
    }

    log('[useEffect] inviteChannels, errors: ', inviteChannels);
  }, [inviteChannels]);

  return (
    <Dialog
      aria-labelledby='form-staff'
      onClose={() => setOpen(false)}
      open={open}
    >
      <form
        autoComplete='off'
        className={classes.dialogFormCtr}
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(
            triggerAction(
              () => setIsLoading(true),
              () => setIsLoading(false),
              () => setIsLoading(false)
            )
          )(e);
        }}
        // e => {
        //   e.preventDefault();
        //   if (triggerAction(staff)) {
        //     setOpen(false);
        //   }
        // }}
      >
        <DialogTitle id='form-dialog-title'>{'Invite new staff member'}</DialogTitle>
        <DialogContent className={classNames(classes.grow, classes.flexContainer)}>
          <div id='createStaff'>
            <TextField
              autoFocus
              classes={{
                root: classes.secondInput
              }}
              fullWidth
              id='firstName'
              inputRef={register({ required: true })}
              label='First Name'
              margin='none'
              name='FirstName'
              // onChange={e =>
              //   setStaff({
              //     ...staff,
              //     FirstName: e.target.value
              //   })
              // }
              type='text'
              // value={staff.FirstName}
            />
            <div className={classes.error}>{ errors.FirstName && 'First name is required.' }</div>
            <TextField
              classes={{
                root: classes.secondInput
              }}
              fullWidth
              id='lastName'
              inputRef={register({ required: true })}
              label='Last Name'
              margin='none'
              name='LastName'
              // onChange={e =>
              //   setStaff({
              //     ...staff,
              //     LastName: e.target.value
              //   })
              // }
              type='text'
              // value={staff.LastName}
            />
            <div className={classes.error}>{ errors.LastName && 'Last name is required.' }</div>
            <Grid
              className={classes.channelFormControlCtr}
              container
              direction='row'
            >
              <Grid
                item
                xs={4}
              >
                <FormControl className={classes.formControl}>
                  <InputLabel id="invite-channel-label">{'Send invite by:'}</InputLabel>
                  <Select
                    id="inviteChannel"
                    labelId="invite-channel-label"
                    name='InviteChannel'
                    onChange={(e) => setValue('InviteChannel', e.target.value)}
                    value={inviteChannels}
                  >
                    <MenuItem 
                      value='sms'
                    >SMS</MenuItem>
                    <MenuItem 
                      value='email'
                    >Email</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={8}
              >
              {
                inviteChannels === 'email'
                ? <TextField
                    classes={{
                      root: classes.secondInput
                    }}
                    fullWidth
                    id='email'
                    label='Email'
                    margin='none'
                    name='Email'
                    onChange={(e) => setValue('Email', typeof e.target !== 'undefined' ? e.target.value : '')}
                    type='email'
                  />
                : null
              }
              {
                inviteChannels === 'sms'
                ? <PhoneInput
                    className={classNames(classes.phoneInputCtr, 'defaultInput')}
                    defaultCountry='US'
                    name='Phone'
                    onChange={(val) => {
                      log('[phoneInput] val: ', val);
                      clearErrors('Phone');
                      setValue('Phone', val);
                    }}
                    placeholder="Phone"
                    value={formatPhoneNumberIntl(phone)}
                  />
                : null
              }
              <div className={classes.error}>{ errors.Phone && 'Phone is required. ' }</div>
              <div className={classes.error}>{ errors.Email && 'Email is required. ' }</div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item
xs={12}
              >
                  <FormHelperText>
                    {inviteChannels === 'email' ? 'An' : 'A'}
                    <b>{inviteChannels === 'email' ? ' Email' : ' SMS'}</b>
                    {' invitation to create an account is sent to the new staff member.'}
                  </FormHelperText>
              </Grid>
            </Grid>
            
          </div>
        </DialogContent>
        <DialogActions>
          <Button 
            color='primary'
            onClick={() => setOpen(false)}
          >
            {'Cancel'}
          </Button>
          <Button 
            color='primary'
            disabled={isLoading}
            type='submit'
            variant='contained'
          >
            {'Add Staff'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(AddStaff);
