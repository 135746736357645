// @flow

const styles = (theme: Object) => ({
  cardCtr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: 600,
    borderRadius: 0
  },
  textFieldCtr: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    border: `2px solid ${theme.palette.primary.lighter}`
  },
  textField: {
    marginTop: theme.spacing(1)
  },
  stepTitle: {
    marginBottom: theme.spacing(1)
  },
  chip: {
    backgroundColor: theme.palette.custom.neoYellow.main,
    color: theme.palette.custom.neoViolet.main,
    fontWeight: 700,
    
    marginRight: theme.spacing(0.5)
  },
  submitBtn: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  knownScriptCtr: {
    marginTop: theme.spacing(4)
  }
});

export default styles;