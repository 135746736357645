/* eslint-disable react/no-multi-comp */
// @flow
import React, { useState } from 'react';
import * as R from 'ramda';
import classNames from 'classnames';
import { log } from '../../utils/jsUtils';
import Testimonial1 from '../../assets/testimonial-eitan-katz.png';
import Testimonial2 from '../../assets/testimonial-ilan-sadon.png';
import Testimonial3 from '../../assets/testimonial-maribeth1.png';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { Typography, Hidden } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import CheckIcon from '@material-ui/icons/Check';

import { makeStyles } from '@material-ui/core/styles';
import { styles } from './ContentfulPricingSection.Style';

type Props = {
  selectPlan: Function,
  pricingTable: {
    enableMonthYearSwitch: boolean,
    showOnlyMobilePlans: boolean,
    plans: Array<{
      id: string,
      title: string,
      ctaLabel: string,
      subTitle: String,
      monthly: string,
      yearly: string,
      price: string, // used if !enableMonthYearSwitch
      linkMonthly: string,
      linkYearly: string,
      link: string, // used if !enableMonthYearSwitch
      planId: string
    }>,
    features: Array<{
      label: string,
      plans: Array<string>
    }>,
    plansMobile: Array<{
      planId: string,
      features: Array<{
        label: string,
        description: string
      }>
    }>
  }
}

const ContentfulPricingSection = (props: Props) => {
  const {
    pricingTable,
    selectPlan,
    section
  } = props;
  log('[ContentfulPricingSection] render section: ', section);
  const useStyles = makeStyles(styles({
    plansNb: pricingTable.plans.length
  }));
  const classes = useStyles();
  const [isPeriodYearly, setIsPeriodYearly] = useState(true);


  return (
    <>
      <Typography className={classes.h1}
        variant='h1'
      >Pick a plan</Typography>
      <Typography className={classes.h3}
        variant='h3'
      >Starting at less than $1/day.</Typography>
      <img src={Testimonial1}
        width='350'
      />
    {
      pricingTable
      ? <Grid
        className={classes.container}
        container
        direction='column'
        >
        {/* switch monthly / yearly price */}
        {
          pricingTable.enableMonthYearSwitch
          ? <Grid
              className={classes.yearlyToggle}
              item
              xs={12}
            >
              {/*
                If user logged in, then enrich this screen with suggestions for this user.
                On select a plan, redirect to paymentForm page with the selected plan in the state?
              */}
              <Grid alignItems="center"
component="label"
container
spacing={1}
              >
                <Grid item>{'Billed Monthly'}</Grid>
                <Grid item>
                  <Switch
                    checked={isPeriodYearly}
                    color='primary'
                    name='isPeriodYearly'
                    onChange={(e) => setIsPeriodYearly(e.target.checked)}
                  />
                </Grid>
                <Grid item>{'Billed Yearly'}</Grid>
              </Grid>
            </Grid>
          : null
        }
  


        <Grid
          item
          xs={12}
        >
        {
          !pricingTable.showOnlyMobilePlans
          ? <Paper
              className={classes.gridPaper}
              elevation={4}
            >
              {/* Desktop view or comparison grid */}
              <Hidden only={['xs']}>
                  <Grid container
direction='row'
                  >
                    <Grid
                      item
                      xs={
                        12 -
                        pricingTable.plans.length *
                          Math.floor(9 / pricingTable.plans.length)
                      }
                    >
                      <Grid className={classes.featuresList}
container
                      >
                        <Grid className={classes.featureHeader}
item
                        />
                        {pricingTable.features.map((feature, featureIndex) => (
                          <Grid
                            className={classNames(
                              classes.gridCell,
                              classes.featureDesc,
                              featureIndex ? null : classes.emphasisDescr,
                              pricingTable.features.length === featureIndex + 1
                                ? 'bottom'
                                : null
                            )}
                            item
                            key={`feature-${featureIndex}`}
                          >
                            {feature.label}
                          </Grid>
                        ))}
                      </Grid>
                  </Grid>
                  {pricingTable.plans.map((plan, planIndex) => {
                    const planDef = R.find(R.propEq('id', plan.id))(pricingTable.plans);
                    log('planDef: ', planDef, plan, pricingTable.plans);
                    return (
                      <Grid
                        item
                        key={`plan-${planIndex}`}
                        xs={Math.floor(9 / pricingTable.plans.length)}
                      >
                        <Grid className={classes.featuresList}
                          container
                        >
                          <Grid className={classes.featureHeader}
                            item
                          >
                            <Typography
                              className={classes.planTitle}
                              variant='h2'
                            >
                              {plan.title}
                            </Typography>
                            {!pricingTable.enableMonthYearSwitch ? (
                              <Typography
                                className={classNames(
                                  classes.planPrice,
                                  plan.yearly === -1
                                    ? classes.planPricePAYG
                                    : null
                                )}
                                variant='h3'
                              >
                                {plan.yearly === -1
                                  ? 'Pay-as-you-go'
                                  : `$${plan.yearly}`}
                              </Typography>
                            ) : isPeriodYearly ? (
                              <div className={'discountedPrice'}>
                                <Typography
                                  className={classNames(
                                    classes.planPrice,
                                    plan.yearly === -1
                                      ? classes.planPricePAYG
                                      : null
                                  )}
                                  variant='h3'
                                >
                                  {plan.yearly === -1
                                    ? 'Pay-as-you-go'
                                    : `$${plan.yearly}/mo`}
                                </Typography>
                                {plan.monthly !== -1 ? (
                                  <Typography
                                    className={classes.striked}
                                    variant='h4'
                                  >
                                    {`$${plan.monthly}`}/mo
                                  </Typography>
                                ) : (
                                  <Typography
                                    className={classes.striked}
                                    variant='h4'
                                  >
                                    &nbsp;
                                  </Typography>
                                )}
                              </div>
                            ) : (
                              <div className={'discountedPrice'}>
                                <Typography
                                  className={classNames(
                                    classes.planPrice,
                                    plan.monthly === -1
                                      ? classes.planPricePAYG
                                      : null
                                  )}
                                  variant='h3'
                                >
                                  {plan.monthly === -1
                                    ? 'Pay-as-you-go'
                                    : `$${plan.monthly}/mo`}
                                </Typography>
                                <Typography
                                  className={classes.hiddenSpace}
                                  variant='h4'
                                >
                                  -
                                </Typography>
                              </div>
                            )}
                              <Button
                                className={classes.topBuy}
                                color='primary'
                                onClick={() => selectPlan(
                                  isPeriodYearly
                                  ? process.env.REACT_APP_ENVF === 'env.production' ? planDef.yearlyPlanId : planDef.yearlyTestPlanId
                                  : process.env.REACT_APP_ENVF === 'env.production' ? planDef.monthlyPlanId : planDef.monthlyTestPlanId
                                )}
                                variant='contained'
                              >
                                {'Get Started'}
                              </Button>
                          </Grid>
                          {pricingTable.features.map(
                            (feature, featureIndex) => (
                              <Grid
                                className={classNames(
                                  classes.gridCell,
                                  classes.featureItem,
                                  featureIndex ? null : classes.emphasis,
                                  pricingTable.features.length ===
                                    featureIndex + 1
                                    ? 'bottom'
                                    : null
                                )}
                                item
                                key={`feature-plan${planIndex}-${featureIndex}`}
                              >
                                {!feature.plans ? null : feature.plans[
                                    planIndex
                                  ] === 'checked' ? (
                                  <FontAwesomeIcon
                                    className={classes.checkIcon}
                                    icon={faCheck}
                                  />
                                ) : (
                                  <pre>{feature.plans[planIndex]}</pre>
                                )}
                              </Grid>
                            )
                          )}
                        </Grid>
                      </Grid>
                    );
                    })}
                  </Grid>
                </Hidden>


              {/* Mobile view */}
              <Hidden only={['sm', 'md', 'lg', 'xl']}>
                {
                  pricingTable.plansMobile.map((plan, planIndex) => {
                    const planDef = R.find(R.propEq('id', plan.planId))(pricingTable.plans);
                    return (
                      <div key={planIndex}>{
                        planDef
                        ? <Grid
                            className={classes.featuresList}
                            container
                            key={`mobile-plan-${planIndex}`}
                          >
                            <Grid className={classes.featureHeader}
item
                            >
                              <Typography variant='h2'>
                                {planDef.title}
                              </Typography>
                              {
                                isPeriodYearly ?
                                <div className={'discountedPrice'}>
                                  <Typography variant='h3'>{`$${planDef.yearly}`}/mo</Typography>
                                  <Typography className={classes.striked}
variant='h4'
                                  >{`$${planDef.monthly}`}/mo</Typography>
                                </div>
                                : 
                                <div>
                                  <Typography variant='h3'>{`$${planDef.monthly}`}/mo</Typography>
                                  <Typography className={classes.hidden}
variant='h4'
                                  >-</Typography>
                                </div>
                              }
                              {/* <a href={isPeriodYearly ? planDef.linkYearly : planDef.linkMonthly}> */}
                                <Button
                                  className={classes.topBuy}
                                  color='primary'
                                  onClick={() => selectPlan(
                                    isPeriodYearly
                                    ? process.env.REACT_APP_ENVF === 'env.production' ? planDef.yearlyPlanId : planDef.yearlyTestPlanId
                                    : process.env.REACT_APP_ENVF === 'env.production' ? planDef.monthlyPlanId : planDef.monthlyTestPlanId
                                  )}
                                  variant='contained'
                                >
                                {
                                  planDef.ctaLabel
                                  ? planDef.ctaLabel
                                  : 'Get Started'
                                }
                                </Button>
                                <Typography className={classes.ctaCaption}>7-day free trial</Typography>
                              {/* </a> */}
                            </Grid>
                            {
                              plan.features.map((feature, featureIndex) => (
                                <Grid
                                  className={classNames(
                                    classes.gridCell,
                                    featureIndex ? classes.featureDesc : classes.topFeatureDesc,
                                    plan.features.length === featureIndex + 1 ? 'bottom' : null
                                  )}
                                  item
                                  key={`mobile-plan${planIndex}-feature${featureIndex}`}
                                >
                                  {feature.label}
                                </Grid>
                              ))
                            }
                          </Grid>
                        : null
                      }</div>
                    );
                  })
                }
              </Hidden>
            </Paper>
            : null
          }

          {/* New mobile only mode */}
          {
            pricingTable.showOnlyMobilePlans
            ? <div className={classes.mobileOnlyPricingGridContainer}>
            {
              pricingTable.plansMobile.map((plan, planIndex) => {
                const planDef = R.find(R.propEq('id', plan.planId))(pricingTable.plans);
                return (
                  <Paper
                    className={classes.mobileOnlyGridPaper}
                    elevation={4}
                    key={planIndex}
                  >
                  {
                    planDef
                    ? <Grid
                        className={classes.featuresList}
                        container
                        key={`mobile-plan-${planIndex}`}
                      >
                        <Grid className={classes.mobileOnlyFeatureHeader}
item
                        >
                          <Typography variant='h2'>
                            {planDef.title}
                          </Typography>
                          {
                            planDef.subTitle
                            ? <Typography variant='h3'>
                                {planDef.subTitle}
                              </Typography>
                            : null
                          }
                          
                          {/* <a href={!pricingTable.enableMonthYearSwitch ? planDef.link : isPeriodYearly ? planDef.linkYearly : planDef.linkMonthly}> */}
                            <Button
                              className={classes.topBuy}
                              color='primary'
                              onClick={() => selectPlan(
                                isPeriodYearly
                                ? process.env.REACT_APP_ENVF === 'env.production' ? planDef.yearlyPlanId : planDef.yearlyTestPlanId
                                : process.env.REACT_APP_ENVF === 'env.production' ? planDef.monthlyPlanId : planDef.monthlyTestPlanId
                              )}
                              variant='outlined'
                            >
                              {
                                planDef.ctaLabel
                                ? planDef.ctaLabel
                                : 'Get Started'
                              }
                            </Button>
                            <Typography className={classes.ctaCaption}>7-day free trial</Typography>
                          {/* </a> */}
                        </Grid>
                        {
                          plan.features.map((feature, featureIndex) => (
                            <Grid
                              className={classNames(
                                classes.mobileOnlyGridCell,
                                featureIndex ? classes.featureDesc : classes.topFeatureDesc,
                                plan.features.length === featureIndex + 1 ? 'bottom' : null
                              )}
                              item
                              key={`mobile-plan${planIndex}-feature${featureIndex}`}
                            >
                              <CheckCircleOutlineIcon />
                              <Typography
                                className={classes.mobileOnlyFeature}
                                variant='body1'
                              >
                                {feature.label}
                              </Typography>
                            </Grid>
                          ))
                        }
                      </Grid>
                    : null
                  }
                  </Paper>
                );
              })
            }
            </div>
            : null
          }
        </Grid>

        {
          /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(window.navigator.userAgent) ?
          <div className={classes.bottomText}>
            <p>*Texts include SMS, images and broadcasts</p>
            <p>Need a bigger plan? Text us! <a href="sms:+19174515515">(917) 451-5515</a></p>
          </div> : 
          <div className={classes.bottomText}>
            <p>*Texts include SMS, images and broadcasts</p>
            <p>Need a bigger plan? Text us! (917) 451-5515</p>
          </div>
        }

        <Grid
        className={classes.container}
        container
        direction='column'
        >
          <Grid xs={12}>
            <Typography className={classes.h5}
              variant='h5'
            >Not sure what you need?</Typography>
            <Typography className={classes.smallh5}
  variant='h5'
            >You can switch plans at any time.</Typography>
            <Typography className={classes.h5}
              variant='h5'
            >Need a bigger plan?</Typography>
            <Typography className={classes.smallh5}
  variant='h5'
            >
            You can recharge at any time.</Typography>
          </Grid>
          <Grid className={classes.marginTop}
xs={12}
          >
            <img className={classes.testimonial2}
              src={Testimonial2}
              width='350'
            />
          </Grid>
        </Grid>

        <Grid
          className={classes.rechargesCtr}
          container
          direction='row'
          spacing={4}
        >
          <Grid className={classes.rechargesTitleCtr}
item
xs={12}
          >
            <Typography className={classes.h2}
variant='h2'>Recharges</Typography>
            <Typography variant='body1'>Valid 1 year with all plans</Typography>
          </Grid>
          <Grid className={classes.creditItemCtr}
item
sm={6}
xs={12}
          >
            <div className={classes.itemBodyCtr}>
              <div className={classes.itemBodyLeft}>
                <Typography className={classes.topupCredits}
variant='body1'
                >
                  500 Credits
                </Typography>
                <Typography className={classes.topupCaption}
variant='body1'
                >
                  $ 0.06 per Credit
                </Typography>
              </div>
              <div className={classes.itemBodyRight}>
                <Typography className={classes.topupPrice}
variant='body1'
                >
                  $30
                </Typography>
              </div>
            </div>
            <div className={classes.itemFooter}>&nbsp;</div>
          </Grid>
          <Grid className={classes.creditItemCtr}
item
sm={6}
xs={12}
          >
            <div className={classes.itemBodyCtr}>
              <div className={classes.itemBodyLeft}>
                <Typography className={classes.topupCredits}
variant='body1'
                >
                  1000 Credits
                </Typography>
                <Typography className={classes.topupCaption}
variant='body1'
                >
                  $ 0.05 per Credit
                </Typography>
              </div>
              <div className={classes.itemBodyRight}>
                <Typography className={classes.topupPrice}
variant='body1'
                >
                  $50
                </Typography>
              </div>
            </div>
            <div className={classes.itemFooter}>&nbsp;</div>
          </Grid>
          <Grid className={classes.creditItemCtr}
item
sm={6}
xs={12}
          >
            <div className={classes.itemBodyCtr}>
              <div className={classes.itemBodyLeft}>
                <Typography className={classes.topupCredits}
variant='body1'
                >
                  5000 Credits
                </Typography>
                <Typography className={classes.topupCaption}
variant='body1'
                >
                  $ 0.045 per Credit
                </Typography>
              </div>
              <div className={classes.itemBodyRight}>
                <Typography className={classes.topupPrice}
variant='body1'
                >
                  $225
                </Typography>
              </div>
            </div>
            <div className={classes.itemFooter}>&nbsp;</div>
          </Grid>
          <Grid className={classes.creditItemCtr}
item
sm={6}
xs={12}
          >
            <div className={classes.itemBodyCtr}>
              <div className={classes.itemBodyLeft}>
                <Typography className={classes.topupCredits}
variant='body1'
                >
                  10k Credits
                </Typography>
                <Typography className={classes.topupCaption}
variant='body1'
                >
                  $ 0.04 per Credit
                </Typography>
              </div>
              <div className={classes.itemBodyRight}>
                <Typography className={classes.topupPrice}
variant='body1'
                >
                  $400
                </Typography>
              </div>
            </div>
            <div className={classes.itemFooter}>&nbsp;</div>
          </Grid>
          <Grid className={classes.creditItemCtr}
item
sm={6}
xs={12}
          >
            <div className={classes.itemBodyCtr}>
              <div className={classes.itemBodyLeft}>
                <Typography className={classes.topupCredits}
variant='body1'
                >
                  25k Credits
                </Typography>
                <Typography className={classes.topupCaption}
variant='body1'
                >
                  $ 0.035 per Credit
                </Typography>
              </div>
              <div className={classes.itemBodyRight}>
                <Typography className={classes.topupPrice}
variant='body1'
                >
                  $875
                </Typography>
              </div>
            </div>
            <div className={classes.itemFooter}>&nbsp;</div>
          </Grid>
          <Grid className={classes.creditItemCtr}
item
sm={6}
xs={12}
          >
            <div className={classes.itemBodyCtr}>
              <div className={classes.itemBodyLeft}>
                <Typography className={classes.topupCredits}
variant='body1'
                >
                  50k Credits
                </Typography>
                <Typography className={classes.topupCaption}
variant='body1'
                >
                  $ 0.03 per Credit
                </Typography>
              </div>
              <div className={classes.itemBodyRight}>
                <Typography className={classes.topupPrice}
variant='body1'
                >
                  $1,500
                </Typography>
              </div>
            </div>
            <div className={classes.itemFooter}>&nbsp;</div>
          </Grid>
          <Grid className={classes.creditItemCtr}
item
sm={6}
xs={12}
          >
            <div className={classes.itemBodyCtr}>
              <div className={classes.itemBodyLeft}>
                <Typography className={classes.topupCredits}
variant='body1'
                >
                  100k Credits
                </Typography>
                <Typography className={classes.topupCaption}
variant='body1'
                >
                  $ 0.025 per Credit
                </Typography>
              </div>
              <div className={classes.itemBodyRight}>
                <Typography className={classes.topupPrice}
variant='body1'
                >
                  $2,500
                </Typography>
              </div>
            </div>
            <div className={classes.itemFooter}>&nbsp;</div>
          </Grid>
          <Grid className={classes.creditItemCtr}
item
sm={6}
xs={12}
          >
            <div className={classes.itemBodyCtr}>
              <div className={classes.itemBodyLeft}>
                <Typography className={classes.topupCredits}
variant='body1'
                >
                  500k Credits
                </Typography>
                <Typography className={classes.topupCaption}
variant='body1'
                >
                  $ 0.02 per Credit
                </Typography>
              </div>
              <div className={classes.itemBodyRight}>
                <Typography className={classes.topupPrice}
variant='body1'
                >
                  $10,000
                </Typography>
              </div>
            </div>
            <div className={classes.itemFooter}>&nbsp;</div>
          </Grid>
          <Grid className={classes.creditItemCtr}
item
sm={6}
xs={12}
          >
            <div className={classes.itemBodyCtr}>
              <div className={classes.itemBodyLeft}>
                <Typography className={classes.topupCredits}
variant='body1'
                >
                  1M Credits
                </Typography>
                <Typography className={classes.topupCaption}
variant='body1'
                >
                  $ 0.015 per Credit
                </Typography>
              </div>
              <div className={classes.itemBodyRight}>
                <Typography className={classes.topupPrice}
variant='body1'
                >
                  $15,000
                </Typography>
              </div>
            </div>
            <div className={classes.itemFooter}>&nbsp;</div>
          </Grid>
          <Grid className={classes.creditItemCtr}
item
sm={6}
xs={12}
          >
            <div className={classes.itemBodyCtr}>
              <div className={classes.itemBodyLeft}>
                <Typography className={classes.topupCredits}
variant='body1'
                >
                  5M Credits
                </Typography>
                <Typography className={classes.topupCaption}
variant='body1'
                >
                  $ 0.01 per Credit
                </Typography>
              </div>
              <div className={classes.itemBodyRight}>
                <Typography className={classes.topupPrice}
variant='body1'
                >
                  $50,000
                </Typography>
              </div>
            </div>
            <div className={classes.itemFooter}>&nbsp;</div>
          </Grid>

          <Grid
            className={classNames(
              classes.newCenteredSectionCtr,
              classes.topMarginSpacing4,
              classes.bottomMarginSpacing2
            )}
            item
            xs={12}
          >
            <Grid container
spacing={1}
            >
              <Grid item
md={4}
sm={12}
xs={12}
              >
                <Typography className={classes.h5}
variant='h5'
                >
                  No hidden costs
                </Typography>
                <Typography className={classes.smallBody1}
variant='body1'
                >
                  That's right! All prices include taxes and fees.
                </Typography>
              </Grid>
              <Grid item
md={4}
sm={12}
xs={12}
              >
                <Typography className={classes.h5}
variant='h5'
                >
                  Simple pricing
                </Typography>
                <Typography className={classes.smallBody1}
variant='body1'
                >
                  1 credit = 1 SMS up to 80 characters. <br />5 credits = 1
                  image / MMS.
                </Typography>
              </Grid>
              <Grid item
md={4}
sm={12}
xs={12}
              >
                <Typography className={classes.h5}
variant='h5'
                >
                  Free undelivered
                </Typography>
                <Typography className={classes.smallBody1}
variant='body1'
                >
                  Only pay for your delivered messages.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
      </Grid>
      <Grid className={classes.marginTop}
        xs={12}
      >
        <img className={classes.testimonial2}
          src={Testimonial3}
          width='350'
        />
      </Grid>
      <Grid className={classNames(classes.marginTop, classes.marginBottom)}
        xs={12}
      >
        <Typography variant='h3'>
          Still have questions? <br />Text "Q" to {
              /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
              ? <a href={'sms:+19174515515&body=Question%20from%20askneo.io%20console%3A%20'}>{'+1 917 451 5515'}</a>
              : <span>{'+1 (917) 451 5515'}</span>
            }
        </Typography>
      </Grid>
    </Grid>
    : null
    }</>
  );
};

export default ContentfulPricingSection;
