// @flow

import React from 'react';
import { createClient, Provider } from 'urql';

const contentfulClient = createClient({
  url: `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}`,
  fetchOptions: () => {
    return {
      headers: { authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}`}
    };
  }
});

type Props = {
  children: Object
}
const ContentfulProvider = (props: Props) => {
  const { children } = props;
  return (
    <Provider value={contentfulClient}>
      {children}
    </Provider>
  );
};

export default ContentfulProvider ;