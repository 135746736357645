// @flow

import { useState } from 'react';
import { useStore } from '../../store';
import { log } from '../../utils/jsUtils';
import { auth } from '../../services/firebase';
import { sendPasswordResetEmail, signInWithEmailAndPassword, getIdToken } from 'firebase/auth';

const useUserAuth = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [store, dispatch] = useStore();
  const { loggedIn } = store;

  const verifyAccountSetup = () => {
    /**
     * 1. account has a plan? no: /plans, on select plan: /checkout?planId=xxxx
     * 2. account has paid for this plan? no: back to step 1.
     * 3. account has at least one building? no: /setup-property
     * 4. else: /member
     */
    // if account.billing.planId not set, then redirect to plan selection page
    // if account.billing.
  };

  const authenticate = (e: ?Object, _email = '', _password = '') => {
    if (e) {
      e.preventDefault();
    }
    if (_email !== '' && _password !== '') {
      setEmail(_email);
      setPassword(_password);
    } else {
      _email = email;
      _password = password;
    }
    log('[authenticate] email, password: ', _email, _password);
    const lcEmail = _email.toLowerCase();
    log('[authenticate] useAuthenticate...', lcEmail, _password);
    log('[authenticate] useAuthenticate... auth: ', auth);
    if (typeof auth !== 'undefined') {
      signInWithEmailAndPassword(auth, lcEmail, _password)
      .then((resp) => {
        log('[authenticate] successful firebase login: ', resp);
        const { currentUser } = auth;
        log('[authenticate] currentUser: ', currentUser);
        getIdToken(currentUser, true)
          .then(function(idToken) {
            if (process.env.REACT_APP_ENV !== 'production') {
              log('[authenticate] new idToken: ', idToken);
            }
            window.localStorage.setItem('loggedIn', true);
            window.localStorage.setItem('email', lcEmail);
            window.localStorage.setItem('token', idToken);
            dispatch({
              type: 'ACTION_LOGIN', 
              email: lcEmail,
              token: idToken
            });
          });
        })
      .catch(function(error) {
        if (error.code === 'auth/user-not-found') {
          dispatch({
            type: 'ACTION_SET_SNACK_MSG', 
            snackMessage: {
              message: 'User not found. Please contact us to sign up.',
              level: 'error',
              duration: 5000
            }
          });
        } else if (error.code === 'auth/wrong-password') {
          dispatch({
            type: 'ACTION_SET_SNACK_MSG', 
            snackMessage: {
              message: 'Incorrect password. Click \'Forgot Password\' to reset it.',
              level: 'error',
              duration: 5000
            }
          });
        }
        log('[authenticate] [UserSession.setPersistence] error: ', error);
      });
    }
  };

  const handleResetPwd = (e: ?Object) => {
    if (e) {
      e.preventDefault();
    }
    log('[handleResetPwd] before:', email);
    sendPasswordResetEmail(auth, email).then((a) => {
      log('Password reset email sent: ', a);
    })
    // auth.sendPasswordResetEmail(email)
    .then(resp => {
      log('[handleResetPwd] auth.passwordReset done: ', resp);
      alert('Email sent!\nLook out for an email from noreply@askneo.io with your reset instructions.');
      // TODO: alert 'watch out for an email from noreply@askneo.io'
    })
    .catch(err => {
      log('[handleResetPwd] auth.passwordReset error: ', err);
      
    });
  };

  return { authenticate, handleResetPwd, email, setEmail, password, setPassword, loggedIn };
};

export {
  useUserAuth
};
