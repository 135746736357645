// @flow

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useParams, useLocation } from 'react-router-dom';
import { useStore } from '../../store';
import { log } from '../../utils/jsUtils';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Logo from '../../assets/askneo-favicon.png';
import { useUserAuth } from '../Auth/UserSession';
import styles from './Styles/Login.Style';

type Props = {
  classes: Object,
};

const Login = (props: Props) => {
  
  const { classes } = props;
  let { reset } = useParams();
  const location = useLocation();

  const [userAgent, setUserAgent] = useState('');
  
  const [openReset, setOpenReset] = useState(reset === 'reset-password' ? true : false);

  const {
    authenticate,
    handleResetPwd,
    email,
    setEmail,
    password, 
    setPassword,
    loggedIn
  } = useUserAuth();


  useEffect(() => {
    const ua = window.navigator.userAgent.toLowerCase();
    setUserAgent(ua);
    // safari = /safari/.test( userAgent ),
    // ios = /iphone|ipod|ipad/.test( userAgent );
    // alert(`[login] ua: ${ua}`);

  }, []);

   // default redirect after successful login
   const { from } = location.state || { from: { pathname: '/member' } };

  return (
    <Grid className={classes.contentCtr}
container
    >
      <Helmet>
        <title>Neo | Login</title>
      </Helmet>
      {
        !loggedIn
          ? <div>
            <Grid className={classes.headerLogo}
              item
              xs={12}
            ><a href="/"><img height={40}
src={Logo}
                         /></a></Grid>
            <Grid item
              xs={12}
            >
              <Typography className={classes.formTitle}
                variant={'h1'}
              >{'Login'}</Typography>
              <form onSubmit={authenticate}>
                <input
                  autoComplete="email"
                  className={classNames(classes.inputForm, classes.formElt)}
                  id="email"
                  onChange={event => setEmail(event.target.value)}
                  placeholder={'Email'}
                  type="text"
                  value={email}
                />
                <input
                  autoComplete="new-password"
                  className={classNames(classes.inputForm, classes.formElt)}
                  id="password"
                  onChange={event => setPassword(event.target.value)}
                  placeholder={'Password'}
                  type="password"
                  value={password}
                />

                <Button
                  className={classes.signin}
                  color="primary"
                  type="submit"
                  variant='contained'
                >
                  {'Sign in'}
                </Button>
              </form>
            </Grid>
            <Grid
              className={classes.helpLink}
              item
              xs={12}
            >
              <a href='#'
onClick={() => setOpenReset(true)}
              >
                {'Forgot password? Reset'}
              </a>
            </Grid>
            <Grid
              className={classes.helpLink}
              item
              xs={12}
            >
              {
                // !(is regular safari browser)
                !(/AppleWebKit(.*Safari)/i.test(userAgent)) 
                ? null
                : 'No account? Text "NEO" to (917) 451-5515 to request access'
              }
            </Grid>
          </div>
          : <Redirect push
to={from}
            />
      }
      <Dialog
          aria-labelledby='form-dialog-title'
          onClose={() => setOpenReset(false)}
          open={openReset}
      >
      <form
        autoComplete='off'
        noValidate
        onSubmit={handleResetPwd}
      >
          <DialogTitle id='form-dialog-title'>{'Reset your password'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {'You\'ll receive an email with instuctions to reset your password.'}
            </DialogContentText>
            
            <TextField
              autoFocus
              fullWidth
              id='resetEmail'
              label='Email'
              margin='dense'
              onChange={event => setEmail(event.target.value)}
              type='email'
              value={email}
            />
          </DialogContent>
          <DialogActions>
            <Button color='primary'
              onClick={() => setOpenReset(false)}
            >
              {'Cancel'}
              </Button>
            <Button 
              color='primary'
              onClick={e => {handleResetPwd(e); setOpenReset(false); }}
              variant='contained'
            >
              {'Reset'}
              </Button>
          </DialogActions>
      </form>
        </Dialog>
    </Grid>
    
    // <Grid 
    //   className={classes.container} 
    //   item
    //   xs={12}
    // >
    // {
    //   !loggedIn
    //   ? <div>
    //       <h1>{'Login'}</h1>
    //       <div>
    //         <input
    //           id="email"
    //           onChange={event => setEmail(event.target.value)}
    //           type="text"
    //           value={email}
    //         />
    //         <input
    //           id="password"
    //           onChange={event => setPassword(event.target.value)}
    //           type="password"
    //           value={password}
    //         />
    //         <button onClick={authenticate}>{'Login'}</button>
    //       </div>
    //     </div>
    //   : <Redirect to={'/member'} />
    // }
    // </Grid>
  );
};

export default withStyles(styles)(Login);