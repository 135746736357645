// @flow

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';
import SPBackend from '../../services/SPBackend';

import ReferralsHeader from './ReferralsHeader';
import ReferralsContent from './ReferralsContent';
import MemberDrawer from '../MemberDrawer';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/ReferralsHome.Style';

type Props = {
  classes: Object
};

const ReferralsHome = (props: Props) => {
  const {
    classes
  } = props;
  
  const [store, dispatch] = useStore();
  const { 
    user,
    i18n
  } = store;

  const [mobileOpen, setMobileOpen] = useState(false);

  const usehandleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Neo | Share</title>
      </Helmet>
      <ReferralsHeader 
        usehandleDrawerToggle={usehandleDrawerToggle}
      />
      
      <MemberDrawer 
        mobileOpen={mobileOpen} 
        usehandleDrawerToggle={usehandleDrawerToggle}
      />

      <ReferralsContent />
    </div>
  );
};

export default withStyles(styles)(ReferralsHome);

