// @flow

import React, { useState } from 'react';
import classNames from 'classnames';
import { log, formatPhoneNumberForBackend } from '../../utils/jsUtils';
import { useStore } from '../../store';

import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import HeaderTopBar from '../HeaderTopBar';
import SPBackend from '../../services/SPBackend';
import AppLoadingSpinner from '../SharedComponents/AppLoadingSpinner';

import { withStyles } from '@material-ui/core/styles';
import styles from '../Feed/Styles/MemberHeader.Style';

import AddStaffDialog from './AddStaffDialog';
import type { User } from '../../services/SPBackend';

type Props = {
  classes: Object,
  mobileOpen: bool, 
  usehandleDrawerToggle: Function,
  setStaffList: Function,
  refreshStaffList: Function,
};


const StaffListHeader = (props: Props) => {
  log('[StaffListHeader] props: ', props);
  const {
    classes,
    refreshStaffList,
    usehandleDrawerToggle
  } = props;
  const [openAddStaffDialog, setOpenAddStaffDialog] = useState(false);

  const [store, dispatch] = useStore();
  const {
    user,
    i18n
  } = store;

  /**
   * 
   * @param {*} staff 
   * @param {*} inviteChannel 
   * @param {*} data input in form
   * @returns false if the action has failed: don't close modal
   */
  const handleAddStaff = (prereq: Function, successCallback: Function, failureCallback: Function) => (data) => {
    if (typeof prereq !== 'undefined') {
      log('[handleAddStaff] running prereq...');
      prereq();
    }
    log('[handleAddStaff] data: ', data);

    const payload = {
      ...data,
      IsAdmin: false,
      IsManager: false,
      IsOwner: false
    };
    if (data.InviteChannel === 'sms') {
      const formattedNb = formatPhoneNumberForBackend(data.Phone);
      if (!formattedNb) {
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: 'Invalid phone number.',
            level: 'error',
            duration: 3000
          }
        }); 
        return false;
      }
      payload['Phone'] = formattedNb;
      delete payload.Email;
    } else {
      delete payload.Phone;
    }

    SPBackend.createStaff(payload, user)
      .then((response) => {
        const { data } = response;
        log('[handleAddStaff.createStaff] data: ', data);
        const { message } = data;
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: message,
            level: 'success',
            duration: 1000000
          }
        });
        if (typeof successCallback !== 'undefined') {
          successCallback();
        }
        setOpenAddStaffDialog(false);
        refreshStaffList();
      })
      .catch(error => {
        log('[handleAddStaff.create] error: ', error);
        if (typeof failureCallback !== 'undefined') {
          failureCallback();
        }
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: error.response ? error.response.data : 'An error occured. Please try again',
            level: 'error',
            duration: 5000
          }
        }); 
      });
    return true;
  };


  return (
    <AppBar 
      className={classes.appBar}
      position="fixed"
    >
      <Toolbar
        className={classes.toolbar}
        disableGutters
      >
        <Grid 
          container
          direction='column'
        >
          <HeaderTopBar
            usehandleDrawerToggle={usehandleDrawerToggle}
          >
            <Typography 
              className={classes.pageTitle}
              variant='h3'>{i18n.t('Staff')}</Typography>
          </HeaderTopBar>
          <Grid
            className={classNames(
              classes.rowSub,
              classes.bottomBar
            )}
            item
          >
            <Grid container>
              <div 
                className={classNames(
                  classes.toggleContainer,
                  classes.dividerRight
                )}
              >
                <Tooltip 
                  aria-label={`Add new ${i18n.t('staff')}`}
                  title={`Add new ${i18n.t('staff')}`} 
                >
                  <Button
                    className={classes.navButton}
                    onClick={() => setOpenAddStaffDialog(true)}
                  >
                    <i className={'icon-add'} />
                    <div>{i18n.t('Staff')}</div>
                  </Button>
                </Tooltip>
              </div>

              <div className={classes.grow} />
            </Grid>
          </Grid>
          <AppLoadingSpinner />
        </Grid>
      </Toolbar>
      <AddStaffDialog
        open={openAddStaffDialog}
        setOpen={setOpenAddStaffDialog}
        triggerAction={handleAddStaff} />
    </AppBar>
  );
};

export default withStyles(styles)(StaffListHeader);
