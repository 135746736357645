// @flow

import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import queryString from 'query-string';
import * as R from 'ramda';
import { useForm, Controller } from 'react-hook-form';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import Recaptcha from 'react-recaptcha';
import { useLocation } from 'react-router-dom';


import { useStore } from '../../store';
import { log, isObjectEmpty, REGEX_PATTERNS } from '../../utils/jsUtils';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';


import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import Logo from '../../assets/askneo-favicon.png';
import TOSDialog from './TOSDialog';
import AgreeToTOCLine from '../SharedComponents/AgreeToTOCLine';
import { useUserAuth } from '../Auth/UserSession';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/Signup.Style';
import SPBackend from '../../services/SPBackend';

type Props = {
  classes: Object
};

const handleCaptchaLoaded = () => {
  log('[Captcha][handleCaptchaLoaded] captcha loaded');
};
// eslint-disable-next-line react/no-multi-comp
const Signup = (props: Props) => {
  
  const { register, handleSubmit, errors, control } = useForm();
  const { classes, injectedStyle } = props;
  const location = useLocation();

  const [isVerified, setIsVerified] = useState(false);
  
  const {
    authenticate,
    handleResetPwd,
    email,
    setEmail,
    password, 
    setPassword,
    loggedIn
  } = useUserAuth();

  const [store, dispatch] = useStore();
  const { billing } = store;
  const { planId } = billing;

  // look for the plan id in url
  const parsed = queryString.parse(location.search);
  const { planid: paramPlanId, referralCode } = parsed;

  useEffect(() => {
    // upon first visit (if no refCode in local storage), log visit to backend
    if (typeof referralCode !== 'undefined' && referralCode !== null && referralCode.length > 0) {
      log('[render] param referralCode: ', referralCode);
      const storageRefCode = window.localStorage.getItem('neoRefCode');
      log('[render] local storage neoRefCode: ', JSON.stringify(storageRefCode), typeof storageRefCode);
      if (storageRefCode === null) {
        log('[render] first visit with refCode');
        window.localStorage.setItem('neoRefCode', referralCode);
        // TODO: call Backend to increment/log visit
        SPBackend.logReferralLinkVisit(referralCode)
        .then((response) => {
          log('[logReferralLinkVisit] response: ', response);
        })
        .catch((e) => {
          log('[logReferralLinkVisit] error: ', e);
        });
      }
    }
    // store refCode in local storage
    // upon stripe invoice paid: increment reward
    // TODO: store referralCode in localStorage. Clear it after successful signup
  }, []);

  useEffect(() => {
    log('[Signup] parsed, paramPlanId: ', parsed, paramPlanId);
    if (paramPlanId && paramPlanId !== '') {
      dispatch({
        type: 'ACTION_SET_BILLING_PLANID',
        planId: paramPlanId
      });
    }
  }, [paramPlanId, planId]);

  const [submitBtnLabel, setSubmitBtnLabel] = useState('Create your askneo.io account');
  const [registrationError, setRegistrationError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  
  const onFieldChange = () => {
    setRegistrationError('');
  };

  const [registrationLoading, setRegistrationLoading] = useState(false);
  const [openTOSDialog, setOpenTOSDialog] = useState(false);

  const handleCaptcha = (captchaResponse) => {
    if ( captchaResponse ){
      log('[Captcha][handleCaptcha] captcha response', captchaResponse);
      const captchaUserResponsePayload = {
        response: captchaResponse
      };
      SPBackend.verifyCaptcha(captchaUserResponsePayload)
        .then((response) => {
          log('[handleCaptcha] verifyCaptcha response -: ', response.data);
          if (response.data.success) {
            setIsVerified(true);
          } else {
            log('[handleCaptcha] verifyCaptcha response success false- ', response);
          }
        })
        .catch((err) => {
          log('[handleCaptcha] verifyCaptcha error -: ', err);
        });
    }
  };

  const createAccount = (registration) => {
    log('[createAccount] registration: ', registration);
    // e.preventDefault();
    log('[Captcha][createAccount] captcha isVerified', isVerified);
    if ( isVerified ){
      log('[createAccount] registration: ', registration);
      setRegistrationLoading(true);
      const storageRefCode = window.localStorage.getItem('neoRefCode');
      SPBackend.registerNewAccount({
        ...registration,
        referralCode: storageRefCode || ''
      })
        .then(async(response) => {
          window.localStorage.removeItem('neoRefCode');
          log('[createAccount] registerNewAccount success - registration: ', registration);
          setSubmitBtnLabel(response.data.message);
          authenticate();
          log('[createAccount] account created!', response);
        })
        .catch((error) => {
          const { response } = error;
          setRegistrationError(response.data);
          log('[createAccount] error: ', response);
          setRegistrationLoading(false);
        });
    } else {
      alert('please select captcha!');
    }  
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid
      className={classes.contentCtr}
      container
    >
      <Helmet>
        <title>Neo | Signup</title>
      </Helmet>
      <Grid
        className={classes.headerLogo}
        item
        xs={12}
      ><a href="/">
        <img
          height={40}
          src={Logo}
        /></a></Grid>
      <Grid item
        xs={12}
      >
        <Typography
          className={classes.formTitle}
          variant={'h4'}
        >{'Create your askneo.io account now'}</Typography>
        <form onSubmit={handleSubmit(createAccount)}>
          {
            registrationError !== ''
            ? <Typography
                className={classes.formErrorResponse}
                variant={'body1'}
              >{registrationError}</Typography>
            : null
          }

          <FormControl
            className={classNames(classes.newInputForm)}
            variant='outlined'
          >
            <InputLabel htmlFor='firstName'>{'First Name'}</InputLabel>
            <OutlinedInput
              className={classNames(classes.newFormElt)}
              inputRef={register({ required: true })}
              label='Outlined'
              name='firstName'
              onChange={onFieldChange}
              type='text'
              variant='outlined'
            />
          </FormControl>
          <div className={classes.errorLabel}>
            { errors.firstName && 'First name is required.' }
          </div>
          <FormControl
            className={classNames(classes.newInputForm)}
            variant='outlined'
          >
            <InputLabel htmlFor='lastName'>{'Last Name'}</InputLabel>
            <OutlinedInput
              className={classNames(classes.newFormElt)}
              inputRef={register({ required: true })}
              name='lastName'
              onChange={onFieldChange}
              type='text'
            />
          </FormControl>
          <div className={classes.errorLabel}>{ errors.lastName && 'Last name is required.' }</div>
          
          <FormControl
            className={classNames(classes.newInputForm)}
            variant='outlined'
          >
            <InputLabel htmlFor='email'>{'Email'}</InputLabel>
            <OutlinedInput
              autoComplete='email'
              className={classNames(classes.newFormElt)}
              inputRef={register({ required: true, pattern: REGEX_PATTERNS.EMAIL })}
              name='email'
              onChange={event => {
                setEmail(event.target.value);
                onFieldChange();
              }}
              type='email'
            />
          </FormControl>
          <div className={classes.errorLabel}>
            { errors.email && 'Valid Email is required.' }
          </div>

          <FormControl
            className={classNames(classes.newInputForm)}
            variant='outlined'
            
          >
            <InputLabel htmlFor='password'>{'Password'}</InputLabel>
            <OutlinedInput
              autoComplete='new-password'
              className={classNames(classes.newFormElt)}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={e => e.preventDefault()}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              inputRef={register({ required: true, minLength: 6 })}
              name='password'
              onChange={event => {
                setPassword(event.target.value);
                onFieldChange();
              }}
              type={showPassword ? 'text' : 'password'}
            />
          </FormControl>
          <div className={classes.errorLabel}>
            { errors.password && errors.password.type === 'required' && 'Password is required.' }
            { errors.password && errors.password.type === 'minLength' && 'Password length must be at least 6 digits or characters.' }
          </div>

          <Controller
            control={control} 
            name='phone'
            render={({ onChange, value }) => (
              <PhoneInput 
                className={classNames(classes.phoneInputCtr, classes.newInputForm, 'outlineInput')}
                defaultCountry='US'
                onChange={(e) => {
                  onChange(e);
                  onFieldChange();
                }}
                placeholder='Mobile Phone number' 
                value={value} 
              />
            )}
            rules={{ required: true }}
          />
          <div className={classes.errorLabel}>{ errors.phone && 'Mobile number is required.' }</div>

          <FormControl
            className={classNames(classes.newInputForm)}
            variant="outlined"
          >
            <InputLabel htmlFor="companyName">{'Company Name'}</InputLabel>
            <OutlinedInput
              className={classNames(classes.newFormElt)}
              id='companyName'
              inputRef={register({ required: true })}
              name='companyName'
              onChange={onFieldChange}
              type='text'
            />
          </FormControl>
          <div className={classes.errorLabel}>
            { errors.companyName && 'Company name is required.' }
          </div>

          <FormControl
            className={classNames(classes.newInputForm)}
            variant="outlined"
          >
            <InputLabel htmlFor="companyUrl">{'Company Website'}</InputLabel>
            <OutlinedInput
              className={classNames(classes.newFormElt)}
              id='companyUrl'
              inputRef={register({ required: true })}
              name='companyUrl'
              onChange={onFieldChange}
              type='text'
            />
          </FormControl>
          <div className={classes.errorLabel}>
            { errors.companyUrl && 'Company website is required.' }
          </div>

          <Controller
            control={control} 
            defaultValue={false}
            name='agreed'
            render={({ onChange, value }) => (
              <FormControlLabel
                className={classNames(classes.checkboxCtr)}
                control={
                  <Checkbox
                    className={classNames(classes.checkbox)}
                    color='primary'
                    onChange={() => {
                      onChange(!value);
                      onFieldChange();
                    }}
                    value={value}
                  />
                }
                label={AgreeToTOCLine()}
              />
            )}
            rules={{ required: true }}
          />
          
          <div className={classes.errorLabel}>
          { errors.agreed && 'Agreement to Terms & Conditions and Privacy Policy is required.' }
          </div>

          <div className={classes.captchaCtr}>
            <Recaptcha
              onloadCallback={handleCaptchaLoaded} // callback after captcha is loaded
              render="explicit"
              sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
              verifyCallback={handleCaptcha} // callback after captcha has verified user
            />
          </div>
          {
            registrationError !== ''
            ? <Typography
                className={classes.formSubmitBtnErrorResponse}
                variant={'body1'}
              >{registrationError}</Typography>
            : null
          }
          <Button
            className={classes.signupBtn}
            color='primary'
            disabled={registrationLoading || !isObjectEmpty(errors) || !isVerified}
            type='submit'
            variant='contained'
          >
            {submitBtnLabel}
          </Button>
        </form>
      </Grid>
      <Grid
        className={classes.helpLink}
        item
        xs={12}
      >
        <a href='/login'>{'Already registered? Sign in'}</a>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Signup);