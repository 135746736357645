// @flow

const CONTENT_WIDTH = 700;
const WIDTH_WITH_MARGINS = CONTENT_WIDTH+8*4;

const styles = (theme: Object) => ({
  toolbar: theme.layout.customProps.toolbar,
  tableContainer: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      padding: theme.spacing(1)
    }
  },
  refPageCtr: {
    marginTop: theme.spacing(4)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%'
    }
  },
  outlinedLinkUrl: {
    borderRadius: 0,
    minWidth: WIDTH_WITH_MARGINS,
    margin: `${theme.spacing(1)}px 0px 0px 0px`,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%'
    }
  },
  kpiCtr: {
    display: 'flex',
    marginBottom: theme.spacing(5)
  },
  kpiDiv: {
    textAlign: 'center',
    width: CONTENT_WIDTH / 3,
    borderRadius: 0,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5)
    }
  },
  kpiCaption: {
    color: 'hsl(0, 0%, 0%)',
    textTransform: 'uppercase',
    paddingTop: theme.spacing(2)
  },
  kpiNb: {
    lineHeight: 1,
    paddingBottom: theme.spacing(2)
  },
  linkLegend: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4)
  },
  referralLanguageTextForm: {
    display: 'flex',
    alignItems: 'flex-start',
    '& button': {
      position: 'relative',
      right: 50,
      top: theme.spacing(2)
    }
  },
  referralLanguageTextField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
    width: WIDTH_WITH_MARGINS,
    '& div': {
      borderRadius: 0
    },
    [theme.breakpoints.down('xs')]: {
      width: CONTENT_WIDTH+8*2
    }
  },
  blockquoteCtr: theme.layout.customProps.blockquote(WIDTH_WITH_MARGINS)
});

export default styles;
