// @flow

const styles = (theme: Object) => ({
  headerLogo: {
    marginTop: 30,
    marginBottom: 30,
    textAlign: 'center'
  },
  contentCtr: {
    position: 'relative',
    maxWidth: 540,
    paddingTop: 30,
    paddingLeft: 20,
    paddingRight: 20,
    margin: '0 auto',
    textAlign: 'left',
    zIndex: 0
  },
  formTitle: {
    textAlign: 'center'
  },
  inputForm: {
    backgroundColor: 'white',
    borderRadius: 0,
    padding: `${theme.spacing(2)}px 30px`,
    minWidth: '100%',
    textAlign: 'left',
    margin: 0,
    fontSize: '1rem',
    '&::placeholder': {
      color: '#a3c2d9'
    }
  },
  formElt: {
    borderTop: 0,
    borderRight: '1px #B4CEE1 solid',
    borderLeft: '1px #B4CEE1 solid',
    borderBottom: '1px #B4CEE1 solid',
    outline: 0,
    '&:first-child': {
      borderTop: '1px #B4CEE1 solid'
    }
  },
  signin: {
    width: '100%',
    borderRadius: 0,
    fontWeight: 600,
    padding: `${theme.spacing(1.5)}px 30px`
  },
  helpLink: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    textDecoration: 'underline',
    fontSize: '0.8rem'
  }
});
export default styles;