// @flow

import React, { useState } from 'react';
import classNames from 'classnames';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';

import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import AddPropertyIcon from '@material-ui/icons/AddLocation';
import AddUnitIcon from '@material-ui/icons/AddLocation';
import AddTenantIcon from '@material-ui/icons/GroupAdd';
import AddStaffIcon from '@material-ui/icons/GroupAdd';

import AddUnit from './AddUnitDialog';
import HeaderTopBar from '../HeaderTopBar';
import SPBackend from '../../services/SPBackend';

import { withStyles } from '@material-ui/core/styles';
import styles from '../Feed/Styles/MemberHeader.Style';

import type { Building, Unit } from '../../services/SPBackend';

type Props = {
  classes: Object,
  mobileOpen: bool, 
  property: Building,
  usehandleDrawerToggle: Function,
  setUnitsAndTenants: Function
};

const PropertyHeader = (props: Props) => {
  log('[PropertyHeader] props: ', props);
  const {
    classes,
    property,
    setUnitsAndTenants
  } = props;
  const [openAddUnitDialog, setOpenAddUnitDialog] = useState(false);

  const [store, dispatch] = useStore();
  const {
    user,
    accountSettings,
    i18n
  } = store;

  const handleAddUnit = (unit: Unit) => {
    const { Floor, Apt } = unit;
    log('[handleAddUnit] unit: ', unit);
    const newUnit = {
      Floor,
      Apt,
      PropertyKey: property.PropertyKey
    };
    SPBackend.createUnit(newUnit, user)
      .then((response) => {
        const {data: newUnit} = response;
        log('[createUnit] res: ', newUnit);
        dispatch({
          type: 'ACTION_SET_ACCOUNT_SETTINGS',
          accountSettings: {
            ...accountSettings,
            SetupRequired: {
              ...accountSettings.SetupRequired,
              Units: false
            }
          }
        });
        setUnitsAndTenants(prevState => {
          return [
            {
              unit: newUnit,
              tenants: []
            },
            ...prevState
          ];
        });
      })
      .catch(error => {
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: error.response ? error.response.data : 'An error occured. Please try again',
            level: 'error',
            duration: 3000
          }
        });
        log('[handleAddUnit.createUnit] error: ', error);
      });
  };

  return (
    <AppBar 
      className={classes.appBar}
      position="fixed"
    >
      <Toolbar
        className={classes.toolbar}
        disableGutters
      >
        <Grid 
          container
          direction='column'
        >
          <HeaderTopBar>
            <Typography 
              className={classes.pageTitle}
              variant='h3'
            >
              <a href='/properties'>{i18n.t('Properties')}</a>
              {` > ${property && property.Property ? property.Property : ''}`}
            </Typography>
          </HeaderTopBar>
          <Grid
            className={classNames(
              classes.rowSub,
              classes.bottomBar
            )}
            item
          >
            <Grid container>
              <div 
                className={classNames(
                  classes.toggleContainer
                )}
                >
                <Tooltip 
                  aria-label={`Add new ${i18n.t('unit')}`}
                  title={`Add new ${i18n.t('unit')}`} 
                >
                  <Button
                    className={classes.navButton}
                    onClick={() => setOpenAddUnitDialog(true)}
                  >
                    <i className={'icon-add'} />
                    <div>{i18n.t('Unit')}</div>
                  </Button>
                </Tooltip>
              </div>
              <div className={classes.grow} />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <AddUnit
        open={openAddUnitDialog}
        setOpen={setOpenAddUnitDialog}
        triggerAction={handleAddUnit} 

      />
    </AppBar>
  );
};

export default withStyles(styles)(PropertyHeader);
