// @flow

import React, { useState } from 'react';
import classNames from 'classnames';
import { log, formatPhoneNumberForBackend, validateEmail } from '../../utils/jsUtils';
import modelUtils from '../../utils/modelUtils';
import { useStore } from '../../store';
import * as R from 'ramda';

import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import GroupIcon from '@material-ui/icons/LocalOffer';
import PublishIcon from '@material-ui/icons/Publish';

// import AddProperty from './AddPropertyDialog';
import FilterSearchBox from '../FilterSearchBox';
import HeaderTopBar from '../HeaderTopBar';
import SPBackend, { NOTIFICATION_CHANNELS } from '../../services/SPBackend';
import AddTagDialog from './AddTagDialog';
import AppLoadingSpinner from '../SharedComponents/AppLoadingSpinner';
import TagCustomersPicker from '../Feed/TagCustomersPicker';

import { withStyles } from '@material-ui/core/styles';
import styles from '../Feed/Styles/MemberHeader.Style';

import type { Tag } from '../../services/SPBackend';

type Props = {
  classes: Object,
  mobileOpen: bool,
  usehandleDrawerToggle: Function,
  tags: Array<Tag>
};


const TagsHeader = (props: Props) => {
  const {
    classes,
    usehandleDrawerToggle,
    tags
  } = props;
  const [openAddTenantDialog, setOpenAddTenantDialog] = useState(false);
  const [openTagPicker, setOpenTagPicker] = useState(false);
  const [openAddTagDialog, setOpenAddTagDialog] = useState(false);

  const [store, dispatch] = useStore();
  const {
    user,
    accountSettings,
    i18n
  } = store;

  const handleAddTag = (tag: Tag, callbackSuccess: Function, callbackFailure: Function) => {
    log('[handleAddTag] tag, user: ', tag, user);

    SPBackend.setTag({
      Name: tag.Name,
      Color: tag.Color
    }, user)
      .then(response => {
        const { data } = response;
        const { tag } = data;
        log('[setTag] response: ', data);
        dispatch({
          type: 'ACTION_ADD_TAG', 
          tag: tag
        });
        if (typeof callbackSuccess !== 'undefined') {
          callbackSuccess();
        }
        dispatch({
          type: 'ACTION_SET_IS_APP_LOADING',
          isAppLoading: false
        });
      })
      .catch(e => {
        log('[handlehandleSetTag] e', e);
        if (typeof callbackFailure !== 'undefined') {
          callbackFailure();
        }
        dispatch({
          type: 'ACTION_SET_SNACK_MSG', 
          snackMessage: {
            message: e.message,
            level: 'error',
            duration: 2000
          }
        });
        dispatch({
          type: 'ACTION_SET_IS_APP_LOADING',
          isAppLoading: false
        });
      });

  };

  return (
    <AppBar 
      className={classes.appBar}
      position="fixed"
    >
      <Toolbar
        className={classes.toolbar}
        disableGutters
      >
        <Grid 
          container
          direction='column'
        >
          <HeaderTopBar
            usehandleDrawerToggle={usehandleDrawerToggle}
          >
            <Typography 
              className={classes.pageTitle}
              variant='h3'
            >{i18n.t('Tags')}</Typography>
          </HeaderTopBar>
          <Grid
            className={classNames(
              classes.rowSub,
              classes.bottomBar
            )}
            item
          >
            <div 
              className={classNames(
                classes.toggleContainer
              )}
            >
              <Tooltip 
                aria-label={`Add new ${i18n.t('tag')}`}
                title={`Add new ${i18n.t('tag')}`} 
              >
                <Button
                  className={classes.navButton}
                  onClick={() => setOpenAddTagDialog(true)}
                >
                  <i className={'icon-add'} />
                  <div>{`Add ${i18n.t('Tag')}`}</div>
                </Button>
              </Tooltip>
            </div>
          </Grid>
          <AppLoadingSpinner />
        </Grid>
      </Toolbar>
      <AddTagDialog
        open={openAddTagDialog}
        setOpen={setOpenAddTagDialog}
        triggerAction={handleAddTag}
      />
    </AppBar>
  );
};

export default withStyles(styles)(TagsHeader);
