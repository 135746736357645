// @flow

import React, { useEffect } from 'react';
import { StoreProvider } from '../store';
import ShowSnackMsg from './ShowSnackMsg';
import {Helmet} from 'react-helmet';

import CssBaseline from '@material-ui/core/CssBaseline';
// import HeaderPublic from "./HeaderPublic";
import SquarePlanApp from './SquarePlanApp';
import { useWindowEvent } from '../utils/globalHooks';
import { log } from '../utils/jsUtils';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../theme';

const App = () => {
  // Initial state
  const initialLoggedInState = () =>
    window.localStorage.getItem('loggedIn') === 'true' ? true : false;
  const initialState = {
    loggedIn: initialLoggedInState(),
    redirectToReferrer: false,
    email: '',
    token: ''
  };
  log('[App] initialState: ', initialState);
  log('process.env.REACT_APP_ENVF: ', process.env.REACT_APP_ENVF);


  useEffect(() => {
    setCorrectViewportHeight();
  });
  const setCorrectViewportHeight = () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document 
    if (document.documentElement) {
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  };
  
  // Set 
  useWindowEvent('resize', setCorrectViewportHeight);
    
  return (
    <StoreProvider initialValue={initialState}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ShowSnackMsg />
        <Helmet>
            <meta charSet="utf-8" />
            <meta content="Askneo.io"
                  name="apple-mobile-web-app-title"
            />
            <meta content="Askneo.io"
                  name="application-name"
            />
            <link href="../assets/apple-icon-57x57.png"
                  rel="apple-touch-icon"
                  sizes="57x57"
            />
            <link href="../assets/apple-icon-60x60.png"
                  rel="apple-touch-icon"
                  sizes="60x60"
            />
            <link href="../assets/apple-icon-72x72.png"
                  rel="apple-touch-icon"
                  sizes="72x72"
            />
            <link href="../assets/apple-icon-76x76.png"
                  rel="apple-touch-icon"
                  sizes="76x76"
            />
            <link href="../assets/apple-icon-114x114.png"
                  rel="apple-touch-icon"
                  sizes="114x114"
            />
            <link href="../assets/apple-icon-120x120.png"
                  rel="apple-touch-icon"
                  sizes="120x120"
            />
            <link href="../assets/apple-icon-144x144.png"
                  rel="apple-touch-icon"
                  sizes="144x144"
            />
            <link href="../assets/apple-icon-152x152.png"
                  rel="apple-touch-icon"
                  sizes="152x152"
            />
            <link href="../assets/apple-icon-180x180.png"
                  rel="apple-touch-icon"
                  sizes="180x180"
            />
            <link href="../assets/android-icon-192x192.png"
                  rel="icon"
                  sizes="192x192"
                  type="image/png"
            />
            <link href="../assets/favicon-32x32.png"
                  rel="icon"
                  sizes="32x32"
                  type="image/png"
            />
            <link href="../assets/favicon-96x96.png"
                  rel="icon"
                  sizes="96x96"
                  type="image/png"
            />
            <link href="../assets/favicon-16x16.png"
                  rel="icon"
                  sizes="16x16"
                  type="image/png"
            />
            <link href="../assets/manifest.json"
                  rel="manifest"
            />
            <meta content="#ffffff"
                  name="msapplication-TileColor"
            />
            <meta content="/ms-icon-144x144.png"
                  name="msapplication-TileImage"
            />
            <meta content="#ffffff"
                  name="theme-color"
            />
        </Helmet>
        <SquarePlanApp />
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;
