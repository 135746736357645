// @flow

const styles = (theme: Object) => ({
  processLegend: {
    textAlign: 'center'
  },
  dialogPaperCtr: {
    minHeight: 480
  },
  dialogContentCtr: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    position: 'relative'
  },
  arrowIcon:{
    position: 'relative',
    fontSize: 52,
    opacity:  '25%'
  },
  textField: {
    '& label.MuiInputLabel-shrink': {
      transform: 'translate(24px, -5px) scale(0.75)'
    }
  },
  textFieldCaptionRight: {
    textAlign: 'right',
    color: theme.palette.primary.light,
    fontSize: '0.8rem'
  },
  iconButton:{
    float: 'left'
  },
  errorLabel: {
    marginLeft: theme.spacing(1),
    color: '#f44336 !important'
  },
  actionsCtr: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px 24px 0px 24px',
    paddingTop: 0
  },
  ctaCtr: {
    display: 'flex'
  },
  smallMarginLeft: {
    marginLeft: theme.spacing(1)
  },
  actionAttachImgBtn: {
    // backgroundColor: theme.palette.primary.lighter
  }
});
  
  export default styles;