// @flow

import headerStyles from './MemberHeader.Style';

const styles = (theme: Object) => ({
  ...headerStyles(theme),
  flexContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  relative: {
    position: 'relative'
  },
  navButton: {
    height: theme.layout.customProps.toolbar.height,
    textTransform: 'capitalize',
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 ${theme.spacing(1)}px`,
    borderRadius: 0
  },
  dialogFormCtr: {
    // height: '80vh',
    maxHeight: 400,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 300
    }
  },
  grow: {
    flexGrow: 1
  },
  filterContainer: {
    marginTop: theme.spacing(1),
    border: `1px solid ${
      theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)'
    }`,
    display: 'flex',
    borderRadius: theme.shape.borderRadius
  },
  actionsCtr: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px 24px 0px 24px',
    paddingTop: 0
  },
  attachedImg: {
    objectFit: 'contain'
  }
});

export default styles;