// @flow

const styles = (theme: Object) => ({
  root: {
    minHeight: '100vh',
    display: 'flex'
  }
});

export default styles;
