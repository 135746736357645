// @flow

import { createTheme } from '@material-ui/core/styles';
import lightblue from '@material-ui/core/colors/lightBlue';
import blue from '@material-ui/core/colors/blue';
import teal from '@material-ui/core/colors/teal';
import orange from '@material-ui/core/colors/orange';
import blueGrey from '@material-ui/core/colors/blueGrey';
import deepPurple from '@material-ui/core/colors/deepPurple';
import grey from '@material-ui/core/colors/grey';

import AuthorLio from './assets/author-lio-slama.jpeg';

type Theme = Object

const NB_50 = 50;
const NB_100 = 100;
const NB_200 = 200;
const NB_300 = 300;
const NB_400 = 400;
const NB_500 = 500;
const NB_600 = 600;
const NB_700 = 700;
const NB_800 = 800;
const drawerWidth = 100; // theme.spacing(7)
const headerHeight = 70;

const statusColors = {
  pending: {
    main: '#f44336'
  },
  current: {
    main: '#ffc107'
  },
  finished: {
    main: '#8bc34a'
  },
  info: {
    main: '#2196f3' // lightblue[NB_600]
  },
  error: {
    main: '#FF0000'
  }
};
const theme:Theme = createTheme({
  palette: {
    background: {
      paper: '#fff',
      default: '#fafafa'
    },
    primary: {
      lighter: '#A6B3F5', // '#bbdefb',
      light: '#7E8EE9', // '#64b5f6',
      main: '#5c6bc0', // '#2196f3',
      dark: '#4A4DA7', // '#1b77c0',
      contrastText: '#fff'
    },
    secondary: {
      light: teal[NB_300],
      main: '#ffeb3a',
      dark: teal[NB_700]
    },
    text: {
      main: '#000000'
    },
    error: {
      main: '#f44336'
    },
    custom: {
      disabledText: 'hsl(0, 0%, 40%)',
      backgroundTextInfo: '#5b5b5b',
      neoYellow: {
        light: '#FFEF64',
        main: '#FFEB3B',
        dark: '#EDDA37'
      },
      neoViolet: {
        main: '#5c6bc0',
        dark: '#4A4DA7'
      }
    },
    messageDeliveryStatuses: {
      sending: '#B2B2B2',
      delivered: '#2196f3', // 009210',
      failed: '#ff5858'
    },
    status: statusColors,
    tags: {
      maintenance: {
        main: '#6B9592'
      },
      rent: {
        main: '#425C5A'
      },
      complaint: {
        main: '#FFAB60'
      },
      moving: {
        main: '#FFB472'
      },
      other: {
        main: '#A2BFBD'
      }
    },
    border: {
      color: 'hsl(0,0%,80%)'
    }
  },
  props: {
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
      border: '1px solid rgba(0, 0, 0, 0.23)'
    },
    statusIconsColors: {
      '&.info': {
        color: statusColors.info.main
      },
      '&.pending': {
        color: statusColors.pending.main
      },
      '&.current': {
        color: statusColors.current.main
      },
      '&.finished': {
        color: statusColors.finished.main
      }
    }
  },
  typography: {
    fontFamily: [
      'Product Sans'
    ]
  },
  layout: {
    responsiveScreenHeight: 'var(--vh, 1vh) * 100',
    listTable: {
      rowHeight: 54
    },
    customProps: {
      drawer: {
        width: drawerWidth,
        backgroundColor: blueGrey[NB_50]
      },
      header: {
        height: headerHeight
      },
      gray: '#838383'
    },
    content: {
      flexGrow: 1
    },
    debug: {
      public: {
        background: 'green'
      },
      member: {
        background: '#E0EBF5'
      }
    }
  }
});

theme.layout.customProps.blockquote = (WIDTH) => ({
  position: 'relative',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  width: WIDTH,
  fontSize: '1rem',
  '& blockquote': {
    marginInlineStart: 20,
    marginInlineEnd: 20
  },
  // lineHeight: '2rem',
  // paddingLeft: theme.spacing(1),
  fontStyle: 'italic',
  backgroundColor: theme.palette.custom.neoYellow.main,
  borderRadius: theme.spacing(2),
  '& div.author': {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'right',
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '2.5rem',
    marginTop: '10px',
    fontStyle: 'normal',
    // textTransform: 'uppercase'
    '& .authorLio': {
      position: 'relative',
      display: 'inline-block',
      marginRight: theme.spacing(2),
      right: 0,
      width: theme.spacing(6),
      height: theme.spacing(6),
      borderRadius: theme.spacing(6),
      backgroundImage: `url(${AuthorLio})`,
      backgroundSize: 'cover'
    }
  },
  [theme.breakpoints.down('xs')]: {
    maxWidth: `calc(100vw - ${theme.spacing(2)}px)`
  }
});

theme.overrides = {
  ...theme.overrides,
  MuiPopover: {
    root: {
      borderRadius: 0
    },
    paper: {
      borderRadius: 0
    }
  },
  MuiTableCell: {
    body: {
      fontSize: '0.9rem'
    }
  },
  MuiButton: {
    containedSecondary: {
      backgroundColor: theme.palette.custom.neoYellow.main,
      color: 'black',
      '&:hover': {
        backgroundColor: theme.palette.custom.neoYellow.dark
      }
    }
  },
  MuiToggleButton: {
    root: {
      lineHeight: 1.5
    }
  },
  MuiInputBase: {
    root: {
      fontSize: '0.9rem'
    }
  },
  MuiOutlinedInput: {
    root: {
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#428bc0'
      }
    },
    input: {
      fontSize: '1rem',
      '&::placeholder': {
        color: '#2a84c6'
      }
    }
  },
  MuiChip: {
    root: {
      height: 20,
      color: 'white'
    }
  },
  MuiFab: {
    secondary: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      // root: {
        // color: theme.palette.custom.neoViolet.main,
        backgroundColor: '#FAFAFA',
        '&:hover': {
          backgroundColor: '#EAEAEA'
        }
      // }
    }
  },
  MuiDialogTitle: {
    root: {
      '& *': {
        fontWeight: 600,
        fontSize: '1.4rem'
      }
    }
  }
};


theme.typography = {
  ...theme.typography,
  root: {
    fontSize: 11
  },
  h1: {
    fontSize: '4rem',
    fontWeight: 500,
    lineHeight: 1.6
  },
  h4: {
    fontSize: '1.6rem',
    fontWeight: 400
  },
  body1: {
    fontSize: '0.8125rem',
    fontWeight: 400,
    lineHeight: 1.5
  },
  body2: {
    fontSize: '0.8125rem',
    fontWeight: 400,
    lineHeight: 1.5
  },
  fontFamily: [
    'Product Sans'
  ],
  pre: {
    fontFamily: ['Product Sans', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap'
  }
};

theme.typography.h3 = {
  fontSize: '1.8rem',
  fontWeight: 600,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.3rem'
  }
};
theme.overrides.MuiDialog = {
  paper: {
    width: 600,
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      width: '90vw'
    }
  },
  paperWidthSm: {
    maxWidth: 600,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90vw'
    }
  }
};
theme.overrides.MuiDialogActions = {
  root: {
    margin: `0px ${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(3)}px`,
    [theme.breakpoints.down('sm')]: {
      margin: `0px ${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(3)}px`
    }
  }
};
theme.overrides.MuiDialogContent = {
  root: {
    padding: `0 ${theme.spacing(3)}px`
  }
};
theme.overrides.MuiPaper = {
  rounded: {
    borderRadius: 24,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 12
    }
  }
};
theme.shape = {
  borderRadius: 24,
  [theme.breakpoints.down('sm')]: {
    borderRadius: 12
  }
};
theme.layout.customProps.toolbar = {
  height: headerHeight,
  minHeight: headerHeight * 2,
  [theme.breakpoints.down('sm')]: {
    minHeight: headerHeight * 2 + theme.spacing(1)
  }
};
theme.layout.customProps.toolbarHalf = {
  height: headerHeight,
  minHeight: headerHeight,
  [theme.breakpoints.down('sm')]: {
    minHeight: headerHeight + theme.spacing(1)
  }
};
theme.squareplan = {
  window: {
    width: {
      width: 1000,
      maxWidth: 1000,
      [theme.breakpoints.only('xs')]: {
        width: `calc(100vw - ${theme.spacing(10)}px)`
      },
      [theme.breakpoints.only('sm')]: {
        width: `calc(100vw - ${theme.spacing(16)}px)`
      },
      [theme.breakpoints.up('md')]: {
        width: `calc(100vw - ${theme.spacing(36)}px)`
      }
    },
    height: {
      height: 600,
      maxheight: 600,
      [theme.breakpoints.only('xs')]: {
        maxheight: '100%'
      },
      [theme.breakpoints.only('sm')]: {
        maxheight: '100%'
      },
      [theme.breakpoints.up('md')]: {
        maxheight: '100%'
      }
    }
  }
  
};
export default theme;