// @flow

import React, { useState, useEffect } from 'react';
import { useStore } from '../../store';
// import classNames from 'classnames';
import { log } from '../../utils/jsUtils';
import Downshift from 'downshift';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import SPBackend from '../../services/SPBackend';
import type { Tenant } from '../../services/SPBackend';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/AutocompleteProperty.Style';

type Props = {
  setUnit: Function,
  classes: Object,
  inputTenant: Tenant
}

const renderInput = _inputProps => {
  const {
    InputProps,
    classes,
    openMenu,
    ...other
  } = _inputProps;
  return (
    <TextField
      InputProps={{
        // inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput
        },
        ...InputProps
      }}
      onFocus={openMenu}
      {...other}
    />
  );
};

// eslint-disable-next-line react/no-multi-comp
const SuggestedList = (props) => {
  const {
    inputValue,
    user,
    getItemProps
  } = props;
  log('[SuggestedList] inputValue: ', inputValue);
  const value = inputValue.trim().toLowerCase();
  const [units, setUnits] = useState([]);

  // log('[SuggestedList] itemProps: ', itemProps);
  useEffect(() => {
    if (user !== null) {
      log('[SPBackend.findUnits]...');
      SPBackend.findUnits(value, user)
        .then(response => {
          log('[findUnits] response: ', response);
          const { data } = response;
          setUnits(data);
      })
      .catch(err => {
        log('sentry error: ', err);
      });
    } else {
      setUnits([]);
    }
  }, [inputValue]);

  return (
    units.map((unit) => {
      return (
        <MenuItem
          {...getItemProps({item: unit})}
          component='div'
          key={unit._id}
          // selected={isHighlighted}
        >
          {`${unit.Property}, ${unit.Apt}`}
        </MenuItem>
      );
    })
  );
};

// eslint-disable-next-line react/no-multi-comp
const AutocompleteProperty = (props: Props) => {
  const {
    classes,
    setUnit
  } = props;
  const [store] = useStore();
  const {
    user
  } = store;

  return (
    <Downshift 
      id='findUnit'
      itemToString={item => item ? `${item.Property}, ${item.Apt}` : ''}
      onSelect={(unit) => {
        if (typeof unit !== 'undefined') {
          setUnit(unit);
        }
      }}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        inputValue,
        isOpen,
        openMenu
      }) => (
        <div className={classes.container}>
          {renderInput({
            ref: 'test',
            fullWidth: true,
            classes,
            InputProps: getInputProps({
              placeholder: 'Search a unit'
            }),
            openMenu
          })}
          <div {...getMenuProps()}>
            {isOpen ? (
              <Paper
                className={classes.paper}
                square
              >
                <SuggestedList
                  getItemProps={getItemProps}
                  inputValue={inputValue}
                  user={user}
                />
              </Paper>
            ): null}
          </div>
        </div>
      )}
    </Downshift>
  );
};

export default withStyles(styles)(AutocompleteProperty);
