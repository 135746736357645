// @flow

const TABLE_MIN_WIDTH = 800;
const rowShadowAtRest = '0px 2px 8px -1px rgba(0,0,0,0.2)';
const ROW_SPACING = 2.5; // 

const styles = (theme: Object) => ({
  expandableCtrCell: {
    width: '100%',
    padding: `${theme.spacing(2)}px 0px 0px 0px`,
    '&:last-child': {
      paddingRight: 0
    }
  },
  tableContent: {
    flexGrow: 1,
    width: '100%',
    minWidth: TABLE_MIN_WIDTH
  },
  table: {
    tableLayout: 'fixed',
    borderCollapse: 'separate',
    borderSpacing: 0,
    padding: 0,
    width: '100%',
    display: 'block',
    whiteSpace: 'nowrap',
    '& thead, & tbody': {
      display: 'block'
    },
    '& tr:first-child': {
      borderTop: 'none'
    }
  },
  tableRowCtr: {
    display: 'flex',
    flexGrow: 1,
    borderBottom: `1px solid hsl(0, 0%, 90%)`
  },
  simpleTableHeadRow: {
    display: 'flex',
    position: 'relative',
    height: 30,
    '& th': {
      fontSize: '0.9rem',
      fontWeight: 600,
      padding: `0px ${theme.spacing(3)}px`,
      borderBottom: 0
    }
  },
  simpleTableRow: {
    zIndex: 2,
    margin: 0,
    position: 'relative',
    '& td, & td:first-child, & td:last-child': {
      borderRadius: 0
    },
    '&.expanded': {
      height: 'auto'
    },
    '&.collapsed': {
      transform:'scaleY(0)',
      display: 'none'
    },
    '&:hover td': {
      backgroundColor: 'hsl(0, 0%, 99%)'
    },
    '&:hover td.actionCell': {
      display: 'flex'
    },
    '& td.actionCell': {
      display: 'none'
    },
    '&:hover td.hideOnRowHover': {
      display: 'none'
    }
  },
  collapseTableContainer: {
    width: '100%',
    '& table tr:last-child': {
      borderBottom: 0
    }
  },
  noPadding: {
    padding: '0 0 0 0',
    '&:last-child': {
      paddingRight: 0
    },
    '&:first-child': {
      paddingLeft: 0
    }
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  textOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  hoverOpacity: {
    opacity: 0.2,
    '&.hover': {
      opacity: 1
    }
  },
  requestCheckbox: {
    marginLeft: 0,
    height: 10
  },
  cell: {
    display: 'flex',
    padding: `14px ${theme.spacing(3)}px`,
    flex: '0 0 auto',
    lineHeight: theme.typography.body2.lineHeight,
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    border: 'none',
    borderTop: 0, // theme.props.MuiButtonBase.outlined.border,
    borderBottom: 0, // theme.props.MuiButtonBase.outlined.border,
    background: 'white',
    zIndex: 0,
    '&:first-child': {
      borderLeft: 0, // theme.props.MuiButtonBase.outlined.border,
      borderTopLeftRadius: theme.shape.borderRadius, 
      borderBottomLeftRadius: theme.shape.borderRadius
    },
    '&.lastChild': {
      borderRight: 0, // theme.props.MuiButtonBase.outlined.border,
      borderBottomRightRadius: theme.shape.borderRadius, 
      borderTopRightRadius: theme.shape.borderRadius
    }
  },
  rowExpanded: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    zIndex: 0,
    '&.hover': {
      boxShadow: rowShadowAtRest
    },
    '& td, & td:first-child, & td:last-child, &.hover td, &.hover td:first-child, &.hover td:last-child': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
      // backgroundColor: 'hsl(0, 0%, 99%)'
    },
    '& .lastChild': {
      borderBottomRightRadius: 0
    }
  },
  rowCollapsed: {

  },
  expandedTableRow: {
    width: 'auto',
    display: 'flex',
    zIndex: 1,
    transition: 'transform 0.3s ease-out',
    transition: 'height 0.3s ease-out',
    transform:'scaleY(1)',
    transformOrigin:'top',
    marginTop: -theme.spacing(1 * ROW_SPACING),
    background: 'white',
    marginLeft: 0,
    marginRight: 0,
    boxShadow: '0px 2px 8px -1px rgba(0,0,0,0.2)',
    marginBottom: 0,
    borderBottom: 0,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    '& td': {
      borderBottom: 0
    },
    '&.expanded': {
      height: 'auto',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2)
    },
    '&.collapsed': {
      transform:'scaleY(0)',
      display: 'none'
    }

  },
  rowActionBtn: {
    borderRadius: 0,
    height: theme.layout.listTable.rowHeight,
    width: theme.layout.listTable.rowHeight,
    minWidth: theme.layout.listTable.rowHeight,
    '& button': {
      margin: theme.spacing(2)
    }
  }
});

export default styles;