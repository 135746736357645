// @flow

const styles = (theme: Object) => ({
  inlineRowTextCtr: {
    width: '100%'
  },
  inlineRowTextRoot: {
    borderRadius: 0,
    width: 'calc(100% - 10px)',
    marginLeft: 5,
    height: '100%',
    paddingRight: 6,
    paddingLeft: 4,
    backgroundColor: 'white',
    minHeight: theme.spacing(4)
  },
  inlineRowTextInput: {
    fontSize: '0.9rem',
    padding: 3,
    backgroundColor: 'white'
  }
});

export default styles;