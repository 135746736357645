// @flow
/**
 * Contains Request-specific util methods
 */

import type { User } from '../services/SPBackend';

const ROLE_NAMES = {
  OWNER: 'Owner',
  ADMIN: 'Admin',
  MANAGER: 'Manager',
  STAFF: 'Staff'
};

const ROLE_POWER = {
  Owner: 3,
  Admin: 2,
  Manager: 1,
  Staff: 0
};

const getRolePower = (user: User) => {
  if (typeof user === 'undefined' || user === null) {
    return 0;
  }
  if (user.IsOwner) {
    return 3;
  } else if (user.IsAdmin) {
    return 2;
  } else if (user.IsManager) {
    return 1;
  }
  return 0;
};

const getUserRole = (user: User) => {
  // console.log('[getUserRole] user: ', user);
  if (typeof user === 'undefined' || user === null) {
    return ROLE_NAMES.STAFF;
  }
  if (user.IsOwner) {
    return ROLE_NAMES.OWNER;
  } else if (user.IsAdmin) {
    return ROLE_NAMES.ADMIN;
  } else if (user.IsManager) {
    return ROLE_NAMES.MANAGER;
  }
  return ROLE_NAMES.STAFF;
};

export {
  getUserRole,
  getRolePower,
  ROLE_POWER,
  ROLE_NAMES
};
