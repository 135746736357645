// @flow
import { CHECKOUT_PAPER } from './PlansHome.Style';

const STRIPE_FORM_WIDTH = 450;
const styles = (theme: Object) => ({
  contentCtr: {
    flexGrow: 1,
    marginTop: 50,
    textAlign: 'center',
    maxWidth: 700,
    margin: '0 auto',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '95vw',
      marginTop: theme.spacing(3),
      padding: `${theme.spacing(4)}px 0px`
    }
  },
  stripeCtr: {
    display: 'flex',
    flexDirection: 'column',
    width: STRIPE_FORM_WIDTH,
    '& iframe.$("ElementsApp")': {
      background: 'green'
    },
    '& .ElementsApp .InputElement': {
      backgroundColor: 'white',
      borderRadius: 0,
      padding: `${theme.spacing(2)}px 30px`,
      minWidth: '100%',
      textAlign: 'left',
      margin: 0,
      fontSize: '1rem',
      '&::placeholder': {
        color: '#a3c2d9'
      }
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '84vw'
    }
  },
  formPaper: {
    ...CHECKOUT_PAPER,
    width: STRIPE_FORM_WIDTH + theme.spacing(3) * 3,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(4),
    '& p, & h2, & h3': {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5)
    },
    '& h2': {
      color: theme.palette.primary.main,
      fontSize: '2rem',
      paddingBottom: 0
    },
    '& h3': {
      // fontSize: '1.2rem',
      fontWeight: 600
    },
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90vw',
      minWidth: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  totalCtr: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1)
  },
  totalLabel: {
    fontSize: '1.3rem',
    fontWeight: 600
  },
  totalCost: {
    fontSize: '1.3rem',
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  totalCaption: {
    fontWeight: 500
    // color: theme.layout.customProps.gray
  },
  formTitle: {
    color: theme.layout.customProps.gray
    // marginBottom: theme.spacing(2)
  },
  formLabel: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(1.5),
    marginBottom: -theme.spacing(0.5)
  },
  errorLabel: {
    marginLeft: theme.spacing(1),
    color: '#f44336 !important'
  },
  checkoutInputForm: {
    '& input': {
      padding: '18.5px 14px',
      fontSize: '1em'
    },
    '& .MuiOutlinedInput-input::placeholder': {
      color: 'black'
    },
    '& .MuiInputBase-input::-webkit-input-placeholder': {
      opacity: 0.75
    }
  },
  striked: {
    textDecoration: 'line-through',
    fontWeight: 600,
    fontSize: '0.9rem',
    paddingRight: 8,
    color: theme.layout.customProps.gray
  },
  expandableLabel: {
    cursor: 'pointer',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2)
  },
  inlineIcon: {
    position: 'relative',
    top: 8
  }
});

export default styles;