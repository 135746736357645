// @flow
import red from '@material-ui/core/colors/red';

const CONTENT_WIDTH = 700;
const WIDTH_WITH_MARGINS = CONTENT_WIDTH+8*4;

const styles = (theme: Object) => ({
  container: theme.layout.content,
  debug: theme.layout.debug.member,
  margin: {
    margin: theme.spacing(1)
  },
  toolbar: theme.layout.customProps.toolbar,
  icon: {
    fontSize: '1rem'
  },
  iconSpinner: {
    width: '100%',
    padding: 10,
    display: 'block'
  },
  welcomeMessage:{
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(2),
    '& > *': {
      paddingLeft: theme.spacing(1),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
      fontSize: '1rem'
    }
  },
  paginateFooter: {
    marginTop: theme.spacing(1),
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: `0px ${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(0.5)}px`
    }
  },
  // move these styles into a new "paginateFooter" component.
  pageNumber: {
    textAlign: 'center',
    flex: '1 1 auto'
  },
  pageNext: {
    textAlign: 'right'
  },
  pagePrev: {
    textAlign: 'left'
  },
  fab: {
    margin: theme.spacing(0.5)
  },
  assigneeChip: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  mobileAssigneeChip: {
    marginLeft: theme.spacing(1)
  },
  taskNameTooltip: {
    padding: theme.spacing(1.5),
    fontSize: '0.9rem',
    maxWidth: 500
  },
  inlineRowTextReadonly: {
    marginTop: 6
  },
  phoneNb: {
    fontWeight: 800,
    color: '#5b5b5b',
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  phoneNbMobile: {
    marginTop: theme.spacing(1),
    fontWeight: 800,
    color: '#5b5b5b',
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem'
    }
  },
  filterButton: {
    // border: `1px solid ${theme.palette.border.color}`,
    paddingLeft: theme.spacing(0.5),
    position: 'relative',
    top: 20,
    [theme.breakpoints.down('xs')]: {
      top: 4
    }
  },
  filterArea: {
    backgroundColor: 'blue'
  },
  pageInfoCtr: {
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0
    }
  },
  noResultsCtr:{
    display: 'flex',
    height: '100%',
    position: 'relative'
  },
  infoText:{
    color: theme.palette.custom.backgroundTextInfo,
    position: 'absolute',
    right: '45%',
    top: '30%',
    textAlign: 'center'
  },
  searchIcon: {
    fontSize: 70
  },
  pageInfoCtrNoResults: {
    position: 'absolute',
    right: 0,
    marginBottom: theme.spacing(1),
    display: 'flex'
  },
  messageHeader: {
    fontWeight: 700
  },
  messagePreview: {
    fontWeight: 500
  },
  blockquoteCtr: {
    ...theme.layout.customProps.blockquote(WIDTH_WITH_MARGINS),
    marginTop: theme.spacing(3),
    fontStyle: 'normal'
  },
  errorCtr: {
    marginBottom: theme.spacing(1)
  },
  editIcon: {
    marginLeft: theme.spacing(0.5),
    fontSize: '1.25rem',
    marginTop: theme.spacing(0.4),
    color: '#bcbcbc'
  },
  editTenantCtr: {
    position: 'relative',
    left: -theme.spacing(1),
    padding: `0px ${theme.spacing(1)}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      '& svg': {
        color: `${theme.palette.primary.main} !important`
      },
      backgroundColor: 'hsl(0,0%,95%)',
      cursor: 'pointer'
    }
  },
  editableName: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    top: -2
  },
  mobileIssueDesc: {

  }
});

export default styles;