// @flow

import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { log } from '../../utils/jsUtils';
import { useStore } from '../../store';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CreditCardForm from '../SharedComponents/CreditCardForm';
import SPBackend from '../../services/SPBackend';
import StripeElementWrapper from './StripeElementWrapper';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/ConfirmTopupPurchaseDialog.Style';
import Typography from '@material-ui/core/Typography';

import type { Plan } from '../../services/SPBackend';

type Props = {
  classes: Object,
  setOpen: Function,
  isActionLoading: Boolean,
  open: boolean,
  triggerAction: Function,
  onPaymentMethodSelected: Function,
  defaultPaymentMethod: Object
};

// eslint-disable-next-line react/no-multi-comp
const UpdatePaymentMethodDialog = (props: Props) => {
  const {
    classes,
    setOpen,
    open,
    triggerAction, // onPaymentMethodSelected
    isActionLoading,
    defaultPaymentMethod
  } = props;
  const [store] = useStore();
  const {
    user
  } = store;
  

  log('[UpdatePaymentMethodDialog render] defaultPaymentMethod: ', defaultPaymentMethod);
  const [newDefaultPM, setNewDefaultPM] = useState(null);

  const onPaymentMethodSelected = (pm) => {
    log('[UpdatePaymentMethodDialog.onPaymentMethodSelected] pm: ', pm);
    setNewDefaultPM(pm);
    triggerAction(pm);
    setOpen(false);
  };

  const onCancelClicked = () => {
    setOpen(false);
  };

  return (
    <Dialog aria-labelledby='form-confirm-topup'
      onClose={() => setOpen(false)}
      open={open}
    >
      <DialogTitle className={classes.dialogHeader}>{'New payment method'}</DialogTitle>
      <DialogContent className={classNames(classes.grow, classes.flexContainer)}>
        {
          !newDefaultPM
          ? <CreditCardForm 
              onCancelClicked={onCancelClicked}
              onPaymentMethodSelected={onPaymentMethodSelected}
            />
          : <Typography
                className={classes.captionText}
                variant='body1'
            >Your credit card ending in {newDefaultPM.card.last4} will be used.</Typography>
        }
        
        <br />
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(UpdatePaymentMethodDialog);
