// @flow

import React, {useState} from 'react';
import { useStore } from '../../store';

import { log } from '../../utils/jsUtils';
import classNames from 'classnames';
import { handleAssignTasks } from '../SharedComponents/feedUtils';
import * as R from 'ramda';

import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';

import FeedTable from '../SharedComponents/FeedTable';
import AssignTasksDialog from '../Feed/AssignTasksDialog';
import { translateStatusToHuman, REF_STATUSES } from '../../models/Request';
import SPBackend from '../../services/SPBackend';
import TOS from './TOS';
import styles from './Styles/TOSDialog.Style';
// TODO: Consider putting this file in master Styles folder.
// import FeedTableStyles from '../Feed/Styles/CommonFeedTable.Style'; 

type Props = {
  classes: Object,
  open: boolean,
  setOpen: Function,
  slug: string,
  updateKPIs: Function,
  TermComponent: Object
};

const TOSModal = (props: Props) => {
  const {
    classes,
    open, 
    setOpen,
    TermComponent
  } = props;

  return (
    <Dialog
      aria-labelledby='terms'
      classes={{
        paperWidthSm: classes.dialogPaperWidthSm
      }}
      onClose={() => setOpen(false)}
      open={open}
    >
      <TermComponent />
    </Dialog>
  );  
};

export default withStyles(styles)(TOSModal);