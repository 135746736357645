// @flow

import React, { useState } from 'react';
import classNames from 'classnames';
import { log } from '../utils/jsUtils';

import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import MenuIcon from '@material-ui/icons/Menu';

import UserProfileMenu from './UserProfileMenu';

import { withStyles } from '@material-ui/core/styles';
import styles from './Feed/Styles/MemberHeader.Style';

type Props = {
  classes: Object,
  mobileOpen: bool, 
  isOnboading?: bool, // user is still onboarding, limit options.
  usehandleDrawerToggle: Function,
  children: Object,
  coloring?: 'light'
};

const HeaderTopBar = (props: Props) => {
  const {
    classes,
    children,
    usehandleDrawerToggle,
    isOnboading,
    coloring
  } = props;
  
  return (
    <Grid 
      className={classNames(
        classes.rowSub,
        classes.topBar,
        coloring
      )}
      item
    >
    {
      usehandleDrawerToggle
      ? <Grid
        className={classes.rowSubContainer}
        container
        justifyContent='flex-start'
        >
        <IconButton
          aria-label="Open drawer"
          className={classes.menuButton}
          color="inherit"
          onClick={usehandleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
      </Grid>
      : null
    }
       
      <Grid item>
        {children}
      </Grid>  
      <div className={classes.grow} />
      <UserProfileMenu 
        coloring={coloring}
        isOnboading={isOnboading} 
      />         

    </Grid>
  );

};

export default withStyles(styles)(HeaderTopBar);
