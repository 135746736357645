/* eslint-disable react/no-multi-comp */
// @flow

import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { log, numberWithCommas } from '../../utils/jsUtils';
import { useStore } from '../../store';
import { useQuery } from 'urql';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import {Elements, CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import StripeElementWrapper from './StripeElementWrapper';

import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

import ContentfulProvider from '../../services/contentful';
import ContentfulPickPlanPage from './ContentfulPickPlanPage';
import ContentfuRenderPlanDesc from './ContentfuRenderPlanDesc';
import CreditCardForm from '../SharedComponents/CreditCardForm';
import SPBackend from '../../services/SPBackend';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/RechargeAccountDialog.Style';
import combineStyles from '../../utils/stylesUtils';
import signupStyles from '../PublicCpts/Styles/Signup.Style';
import type { Plan } from '../../services/SPBackend';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const RechargesPicker = (props) => {
  const { classes, selectPlan } = props;
  const [store, dispatch] = useStore();
  const {
    user
  } = store;
  const [topupPlans, setTopupPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    SPBackend.getMembersTopupPlans(user)
    .then(response => {
      log('[getMembersTopupPlans] response: ', response.data);
      const { plans } = response.data;
      setTopupPlans(plans);
    })
    .catch(err => {
      setLoading(false);
      dispatch({
        type: 'ACTION_SET_SNACK_MSG', 
        snackMessage: {
          message: err.message,
          level: 'error',
          duration: 5000
        }
      });
    });
  }, []);

  return (
      <Grid className={classes.contentCtr}
        item
        xs={12}
      >
        <Grid
          className={classes.rechargesCtr}
          container
          direction='row'
          spacing={4}
        >
          <Grid className={classes.rechargesTitleCtr}
            item
            xs={12}
          >
            <Typography className={classes.h2}
              variant='h2'
            >Recharges</Typography>
            <Typography variant='body1'>Just recharge at least once every 12 months to keep your account active</Typography>
          </Grid>
          {
            topupPlans && topupPlans.map((topup: Plan, topupIdx) => (
            <Grid className={classes.creditItemCtr}
                  item
                  key={`recharge-${topupIdx}`}
                  xs={12}
            >
              <div className={classes.itemBodyCtr}>
                <div className={classes.itemBodyLeft}>
                  <Typography className={classes.topupCredits}
                              variant='body1'
                  >
                    {numberWithCommas(topup.UsageLimit)} Credits
                  </Typography>
                  <Typography className={classes.topupCaption}
                              variant='body1'
                  >
                    ${(topup.Price * 1000 / topup.UsageLimit) / 1000} per Credit
                  </Typography>
                </div>
                <div className={classes.itemBodyRight}
                  onClick={() => selectPlan(topup)}
                >
                  <Typography className={classNames(classes.topupPrice, classes.clickable)}
                              variant='body1'
                  >
                    ${numberWithCommas(topup.Price)}
                  </Typography>
                </div>
              </div>
              <div className={classes.itemFooter}>&nbsp;</div>
            </Grid>
            ))
          }


          <Grid
            className={classNames(
              classes.newCenteredSectionCtr,
              classes.topMarginSpacing4,
              classes.bottomMarginSpacing2
            )}
            item
            xs={12}
          >
            <Grid container
              spacing={1}
            >
              <Grid item
                md={4}
                sm={12}
                xs={12}
              >
                <Typography className={classes.h5}
                  variant='h5'
                >
                  No hidden costs
                </Typography>
                <Typography className={classes.smallBody1}
                  variant='body1'
                >
                  That's right! All prices include taxes and fees.
                </Typography>
              </Grid>
              <Grid item
                md={4}
                sm={12}
                xs={12}
              >
                <Typography className={classes.h5}
                  variant='h5'
                >
                  Simple pricing
                </Typography>
                <Typography className={classes.smallBody1}
                  variant='body1'
                >
                  1 credit = 1 SMS up to 80 characters. <br />5 credits = 1
                  image / MMS.
                </Typography>
              </Grid>
              <Grid item
                md={4}
                sm={12}
                xs={12}
              >
                <Typography className={classes.h5}
                  variant='h5'
                >
                  Free undelivered
                </Typography>
                <Typography className={classes.smallBody1}
                  variant='body1'
                >
                  Only pay for your delivered messages.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
      </Grid>
    </Grid>
  );
};




const ckForm = (props) => {
  const {
    isLoading,
    selectedPlan,
    setOpenDialog,
    coupon,
    paymentMethods,
    defaultPaymentMethod,
    showAddNewCardForm,
    checkoutFlow,
    setCheckoutFlow,
    classes
  } = props;
  log('[ckForm] classes: ', classes);

  const [paymentLoading, setPaymentLoading] = useState(false);
  const [cardholderName, setCardholderName] = useState('');
  const [chargeError, setChargeError] = useState('');
  const [selectedPMId, setSelectedPMId] = useState('');
  const [store, dispatch] = useStore();
  
  const {
    user,
    loggedUserFullName,
    defaultBuilding
  } = store;

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async(ev) => {
    try {
      ev.preventDefault();
      setPaymentLoading(true);
      log('submit payment!', ev);

      log('[handleSubmit] selectedPlan: ', selectedPlan);
      if (!selectedPlan || typeof selectedPlan === 'undefined') {
        setChargeError('Please choose a plan first.');
        throw Error('Please choose a plan first.');
      }
      
      let selectedPM = null;
      if (selectedPMId !== '') {
        selectedPM = {
          id: selectedPMId
        };
      } else {

        // CREATE NEW PM

        // https://stripe.com/docs/stripe-js/reference#elements-get-element
        const cardElement = elements.getElement('card');
        // cardElement.mount('#card-elt-id');
        log('[handleSubmit] elements(cardNumber): ', cardElement);
        let pmResult = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: cardholderName
          }
        });
        log('[handleSubmit] after createPaymentMethod. ');
        const { paymentMethod, error: pmError } = pmResult;
        if (pmError) {
          log('Stripe paymentMethod error: ', pmError);
          if (pmError.code === 'parameter_invalid_empty') {
            throw Error('All fields are required.');
          }
          throw Error(pmError.message);
        }
        try {
          const pmResponse = await SPBackend.updatePaymentMethod(paymentMethod, user);
          log('[handleSubmit] pmResponse: ', pmResponse);
        } catch (error) {
            log('[handleSubmit] charge error.response: ', error.response);
            setPaymentLoading(false);
            const { data } = error.response;
            setChargeError(data);
        }
        
        log('resulting pmResult: ', paymentMethod);
        selectedPM = paymentMethod;
      }

      // USE SELECTED PM
      
      // Omit trialEnd param, so trial expires now.
      const paymentPayload = {
        paymentMethod: selectedPM,
        planId: selectedPlan._id
      };
      if (typeof coupon !== 'undefined' && coupon !== null) {
        paymentPayload.coupon = coupon.id;
      }
      SPBackend.purchaseTopupPlan(user, selectedPlan._id, paymentPayload)
        .then((response) => {
          setChargeError('');
          log('[purchaseTopupPlan] response: ', response);
          SPBackend.getAccount(user)
          .then(response => {
            log('[getAccount] response: ', response);
            const { data } = response;
            const { account, billing } = data;
            dispatch({
              type: 'ACTION_LOADED_ACCOUNT',
              payload: {
                billing
              }
            });
            dispatch({
              type: 'ACTION_SET_ACCOUNT_SETTINGS', 
              accountSettings: account.Settings
            });
            dispatch({
              type: 'ACTION_SET_ACCOUNT_USAGE',
              accountUsage: account.Usage
            });
            dispatch({
              type: 'ACTION_SET_BILLING',
              billing
            });
            setPaymentLoading(false);
            setCheckoutFlow((prev) => ({
              ...prev,
              currentStep: 2
            }));
            log('[chargePlan] updated checkoutflow');
          });
        })
        .catch(error => {
          log('[purchaseTopupPlan] charge error.response: ', error.response);
          setPaymentLoading(false);
          const { data } = error.response;
          setChargeError(data);
        });
    } catch (e) {
      log('[handleSubmit] e.name: ', e.name);
      if (e.name === 'IntegrationError') {
        setChargeError('Missing card information');
      } else {
        log('[handleSubmit] error caught: ', e.name, e);
        setChargeError(e.message);
      }
      setPaymentLoading(false);
    }
  };

  log('[ckForm] selectedPlan: ', selectedPlan);

  useEffect(() => {
    if (defaultPaymentMethod !== null) {
      setSelectedPMId(defaultPaymentMethod.id);
    }
  }, [defaultPaymentMethod]);

  const handleSelectPaymentMethod = (pmId) => {
    log('[handleSelectPaymentMethod] pmId: ', pmId);
    setSelectedPMId(pmId);
  };

  return (
    <Grid container>
      <form className={classes.pmFormCtr}
onSubmit={handleSubmit}
      >
        <Typography className={classNames(classes.spaceTop, classes.spaceTop)}
variant='body1'
        ><b>Payment method</b></Typography>
        {
          isLoading
          ? <Typography variant='body1'>Loading...</Typography>
          : null
        }
      {
        !showAddNewCardForm
        ? <List>
            <RadioGroup aria-label="gender"
              classes={{root: classes.pmRadioGroup}}
              color='primary'
              name="selectPaymentMethod"
              onChange={(e) => handleSelectPaymentMethod(e.target.value)}
              value={selectedPMId}
            >
      
              {
                paymentMethods.map((pm, i) => {
                  return (
                    <ListItem classes={{root: classes.pmListItem}}
                      key={`pm-${i}`}
                    >
                      <ListItemAvatar>
                        <FormControlLabel
                          control={<Radio color='primary'/>}
                          // onChange={handleSelectPaymentMethod}
                          value={pm.id}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        onClick={() => handleSelectPaymentMethod(pm.id)}
                        primary={`${pm.card.brand.toUpperCase()} (${pm.card.last4}) - Expires ${pm.card.exp_month}/${pm.card.exp_year}`}
                        secondary={defaultPaymentMethod && pm.id === defaultPaymentMethod.id ?'(Default)':''}
                      />

                    {/* <div key={`pm-${i}`}>
                      <span className={classes.capitalize}>{pm.card.brand}</span> ({pm.card.last4}) - Expires {pm.card.exp_month}/{pm.card.exp_year}&nbsp;&nbsp;
                    {defaultPaymentMethod && pm.id === defaultPaymentMethod.id ? <Chip classes={{root: classes.defaultChip}}
                      label='default' */}
                    </ListItem>
                  );
                })
              }
            </RadioGroup>
          </List>
        : null
      }
      {
        showAddNewCardForm
        ? <><Grid item
            xs={12}
            >
              <Typography className={classes.formLabel}
      variant='body1'
              >{'Cardholder full name'}</Typography>
              <FormControl
                className={classNames(classes.newInputForm)}
                variant="outlined"
              >
                <OutlinedInput
                  className={classNames(classes.newFormElt, classes.checkoutInputForm)}
                  id='companyName'
                  margin='dense'
                  name='companyName'
                  onChange={event => setCardholderName(event.target.value)}
                  placeholder='Cardholder full name'
                  type='text'
                  value={cardholderName}
                />
              </FormControl>
            </Grid>
            <Grid item
              xs={12}
            >
              <Typography className={classes.formLabel}
      variant='body1'
              >{'Card information'}</Typography>
              <StripeElementWrapper component={CardElement}
      id='card-elt-id'
      label=''
              />
            </Grid> 
          </>
        : null
      }
      {
        chargeError !== ''
        ? <Grid
            className={classes.errorLabel}
            item
            xs={12}
          >{chargeError}</Grid>
        : null
      }

        <div className={classes.reviewItemCtr}>
          <Typography variant='body1'><b>Payment total</b></Typography>
          <Typography variant='body1'><b>${numberWithCommas(checkoutFlow.planDef.Price)}</b></Typography>
        </div> 
        <Button
          color='primary'
          disabled={paymentLoading || (!showAddNewCardForm && (!selectedPlan || selectedPlan._id === ''))}
          style={{marginTop: 16}}
          type='submit'
          variant='contained'
        >{selectedPlan && selectedPlan._id ? 'Purchase' : 'Loading'}</Button>
      </form>
    </Grid>
  );
};

const combinedStyles = combineStyles(
  signupStyles,
  styles
);
const CheckoutForm = withStyles(combinedStyles)(ckForm);

const CheckoutPlan = (props) => {
  // console.log('[CheckoutPlan] props: ', props);
  const {
    classes,
    setCheckoutFlow,
    checkoutFlow,
    setOpenDialog
  } = props;

  const [store, dispatch] = useStore();
  const {
    user
  } = store;

  const emptyPlanState = {
    Price: 0,
    Privacy: '',
    StripePlanId: '',
    TrialLimit: 0,
    Type: 'topup',
    UsageLimit: 0,
    _id: ''
  };
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
  const [showAddNewCardForm, setShowAddNewCardForm] = useState(false);
  const [planInfo, setPlanInfo] = useState(emptyPlanState);
  const [isLoadingPM, setIsLoadingPM] = useState(false);

  useEffect(() => {
    log('[useEffect.checkoutFlow] planInfo: ', planInfo);
    setPlanInfo(checkoutFlow.planDef);
    setIsLoadingPM(true);
    // Check if user already has a payment method
    SPBackend.getPaymentMethods(user)
      .then(response => {
        log('[getPaymentMethods] response: ', response);
        const {
          paymentMethods,
          defaultPaymentMethod
        } = response.data;
        setPaymentMethods(paymentMethods);
        setDefaultPaymentMethod(defaultPaymentMethod);
        if (typeof paymentMethods !== 'undefined' && paymentMethods !== null && paymentMethods.length > 0) {
          setShowAddNewCardForm(false);
        } else {
          setShowAddNewCardForm(true);
        }
        setIsLoadingPM(false);
      })
      .catch(e => {
        log('[getPaymentMethods] error: ', e);
        setShowAddNewCardForm(false);
        setIsLoadingPM(false);
      });
  }, [checkoutFlow]);
  
  return (
    <Grid className={classes.contentCtr}
      item
      xs={12}
    >
      <div className={classes.checkoutColCtr}>
        <div className={classes.checkoutContentHeader}>
          <Typography variant='h3'>Finalize & confirm</Typography>
        </div>
        <div className={classes.checkoutLineCtr}>
          <div className={classes.checkoutColCtr}>
            <div className={classNames(classes.reviewSectionCtr)}>
              <Typography className={classes.spaceTop}
                variant='body1'
              ><b>Review details</b></Typography>
              <div className={classNames(classes.reviewItemCtr, classes.reviewItem, classes.pmListItem)}>
                <Typography variant='body1'>{numberWithCommas(checkoutFlow.planDef.UsageLimit)} credits recharge</Typography>
                <Typography className={classNames(classes.bottomText, 'price')}
                            variant='body1'
                >${numberWithCommas(checkoutFlow.planDef.Price)}</Typography>
              </div>
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  // coupon={coupon} // FIXME
                  // history={history}
                  checkoutFlow={checkoutFlow}
                  defaultPaymentMethod={defaultPaymentMethod}
                  isLoading={isLoadingPM}
                  paymentMethods={paymentMethods}
                  selectedPlan={checkoutFlow.planDef}
                  setCheckoutFlow={setCheckoutFlow}
                  setOpenDialog={setOpenDialog}
                  showAddNewCardForm={showAddNewCardForm}
                />
              </Elements>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
};

type Props = {
  classes: Object,
  setOpen: Function,
  open: boolean,
  triggerAction: Function
};

// eslint-disable-next-line react/no-multi-comp
const RechargeAccountDialog = (props: Props) => {
  const emptyCheckoutFlowState = {
    currentStep: 0, // 0: pick a plan; 1: payment; 2: success!
    selectedPlanId: null
  };
  const {
    classes,
    setOpen,
    open,
    triggerAction
  } = props;
  const [store] = useStore();
  const {
    user
  } = store;
  
  const [checkoutFlow, setCheckoutFlow] = useState(emptyCheckoutFlowState);

  const handleSelectPlan = (topupPlan) => {
    log('[handleSelectPlan] topupPlan: ', topupPlan);
    setCheckoutFlow((prev) => {
      return {
        ...prev,
        currentStep: prev.currentStep + 1,
        selectedPlanId: topupPlan.StripePlanId, // stripe plan id
        planDef: topupPlan
      };
    });
  };

  const emptyCheckoutFlow = () => {
    setTimeout(() => {
      setCheckoutFlow(emptyCheckoutFlowState);
    }, 300);
  };
  

  // log('[RechargeAccountDialog]render checkoutFlow: ', checkoutFlow);
  return (
    <Dialog aria-labelledby='form-confirm-topup'
      classes={{paper: classes.dialogCtr}}
      onClose={() => {setOpen(false); emptyCheckoutFlow();}}
      open={open || false}
    >
      <DialogContent className={classNames(classes.grow, classes.flexContainer)}>
      {
        checkoutFlow.currentStep === 0
        ? <ContentfulProvider>
            <RechargesPicker
              selectPlan={handleSelectPlan}
              {...props}
            />
          </ContentfulProvider>
        : null
      }
      {
        checkoutFlow.currentStep === 1
        ? <CheckoutPlan
          {...props}
          checkoutFlow={checkoutFlow}
          setCheckoutFlow={setCheckoutFlow}
          setOpenDialog={setOpen}
          />
        : null
      }
      {
        checkoutFlow.currentStep === 2
        ? <Grid className={classes.contentCtr}
            item
            xs={12}
          >
            <div className={classes.checkoutColCtr}>
              <div className={classes.checkoutContentHeader}>
                <Typography variant='h3'>Success! You're ready to text 🤗</Typography>
              </div>
              <div className={classes.checkoutColCtr}>
                <Typography className={classes.captionText}
                  variant='body1'
                >Your invoice is available in the Billing section.</Typography>
                <div className={classes.spaceTop} />
                <Button
                  color='primary'
                  onClick={() => {setOpen(false); emptyCheckoutFlow();}}
                  style={{marginTop: 16}}
                  variant='contained'
                >Got it</Button>
                <div className={classes.spaceTop} />
              </div>
            </div>
          </Grid>
        : null
      }
      
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(RechargeAccountDialog);
