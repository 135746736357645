// @flow

import React, {useEffect } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/SupportDialog.Style';

type Props = {
  classes: Object,
  setOpen: Function,
  open: boolean
};

const Accordion = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0
      },
      '&:before': {
        display: 'none'
      },
      '&$expanded': {
        margin: 'auto'
      }
    },
    expanded: {}
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56
      }
    },
    content: {
      '&$expanded': {
        margin: '12px 0'
      }
    },
    expanded: {}
  })(MuiAccordionSummary);
  
  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2)
    }
  }))(MuiAccordionDetails);

// eslint-disable-next-line react/no-multi-comp
const DisplaySupport = (props: Props) => {
  const {
    classes,
    setOpen,
    open
  } = props;

  const [expanded, setExpanded] = React.useState('panel1');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
      // TODO
  });

  return (
    <Dialog 
      aria-labelledby='form-add-unit'
      onClose={() => setOpen(false)}
      open={open}
    >
        <DialogContent
          classes={{
            root: classes.dialogCtr
          }}
        >
            <Typography variant="h6">
                {'We\'re here to help'}
            </Typography>
            <Typography variant="subtitle1">
                <br />{'- Questions? Text us: '}
                {
                  /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
                  ? <a href={'sms:+19174515515&body=Question%20from%20askneo.io%20console%3A%20'}>{'+1 917 451 5515'}</a>
                  : <span>{'+1 (917) 451 5515'}</span>
                }
            </Typography>
            <Typography variant="subtitle1">{'- Excited about SMS automation? '}
              <a href="https://bit.ly/39c6mGo"
                  rel="noopener noreferrer"
                  target="_blank"
              > {'https://bit.ly/39c6mGo'}
              <OpenInNewIcon style={{ fontSize: 20, top: 5, marginLeft: 5 }}/></a>
              </Typography>
            <Typography variant="subtitle1">{'- Book a meeting with us at '}
              <a href="https://calendly.com/lio-slama/lio-slama-ceo-askneo-io"
                  rel="noopener noreferrer"
                  target="_blank"
              > {'https://calendly.com/lio-slama/lio-slama-ceo-askneo-io.'}
              <OpenInNewIcon style={{ fontSize: 20, top: 5, marginLeft: 5 }}/></a>
              </Typography>
        </DialogContent>
        <DialogActions />
    </Dialog>
  );
};

export default withStyles(styles)(DisplaySupport);
