// @flow
// @deprecated

import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';
import BasicHeader from './BasicHeader';
import {Elements, CardElement} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import StripeElementWrapper from './StripeElementWrapper';
import { useStore } from '../../store';
import { log } from '../../utils/jsUtils';
import SPBackend from '../../services/SPBackend';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Logo from '../../assets/askneo-favicon.png';
import { useUserAuth } from '../Auth/UserSession';
import styles from './Styles/CheckoutHome.Style';
import combineStyles from '../../utils/stylesUtils';
import signupStyles from '../PublicCpts/Styles/Signup.Style';

type Props = {
  classes: Object
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const ckForm = (props) => {
  const { stripe, elements, planinfo, 
  coupon, classes } = props;
  const history = useHistory();

  const [paymentLoading, setPaymentLoading] = useState(false);
  const [cardholderName, setCardholderName] = useState('');
  const [chargeError, setChargeError] = useState('');
  const [store, dispatch] = useStore();
  const {
    user,
    userFullName,
    billing,
    defaultBuilding
  } = store;
  const { planId } = billing;

  const handleSubmit = async(ev) => {
    try {
      ev.preventDefault();
      setPaymentLoading(true);
      log('submit payment!', ev);
      // https://stripe.com/docs/stripe-js/reference#elements-get-element
      const cardElement = elements.getElement('card');
      // cardElement.mount('#card-elt-id');
      log('[handleSubmit] elements(cardNumber): ', cardElement);
      let pmResult = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: cardholderName
        }
      });
      log('[handleSubmit] after createPaymentMethod. ');
      const { paymentMethod, error: pmError } = pmResult;
      if (pmError) {
        log('Stripe paymentMethod error: ', pmError);
        if (pmError.code === 'parameter_invalid_empty') {
          throw Error('All fields are required.');
        }
        throw Error(pmError.message);
      }
      if (!planId || typeof planId === 'undefined') {
        setChargeError('Please choose a plan first.');
        throw Error('Please choose a plan first.');
      }
      log('resulting pmResult: ', paymentMethod);
      
      // build trial end period
      const trialObj = {};
      if (planinfo.trial_period_days && planinfo.trial_period_days > 0) {
        trialObj.trialEnd = moment().add(planinfo.trial_period_days, 'days').unix();
      }
        
      const paymentPayload = {
        paymentMethod,
        planId,
        ...trialObj
      };
      if (typeof coupon !== 'undefined' && coupon !== null) {
        paymentPayload.coupon = coupon.id;
      }
      SPBackend.chargePlan(paymentPayload, user)
        .then((response) => {
          setChargeError('');
          log('charge response: ', response);
          SPBackend.getAccount(user)
          .then(response => {
            log('[getAccount] response: ', response);
            const { data } = response;
            const { user, account, billing } = data;
            dispatch({
              type: 'ACTION_LOADED_ACCOUNT',
              payload: {
                billing
              }
            });
            if ( defaultBuilding && (typeof defaultBuilding.Phone === 'undefined' || defaultBuilding.Phone === null || defaultBuilding.Phone === '')) {
              log('redirecting to phone number picker');
              history.push('/pickYourPhoneNumber');
            } else {
              log('redirecting to member');
              history.push('/member'); // if phone number, go directly to member
            }
            setPaymentLoading(false);
          });
        })
        .catch(error => {
          log('[handleSubmit] charge error.response: ', error.response);
          setPaymentLoading(false);
          const { data } = error.response;
          setChargeError(data);
        });
      } catch (e) {
        log('[handleSubmit] e.name: ', e.name);
        if (e.name === 'IntegrationError') {
          setChargeError('Missing card information');
        } else {
          log('[handleSubmit] error caught: ', e.name, e);
          setChargeError(e.message);
        }
        setPaymentLoading(false);
      }
  };

  log('planinfo: ', planinfo);

  return (
    <form onSubmit={handleSubmit}>
    <Helmet>
      <title>Neo | Checkout</title>
    </Helmet>
    <Grid container>
      <Grid item
xs={12}
      >
        <Typography className={classes.formLabel}
variant='body1'
        >{'Cardholder full name'}</Typography>
        <FormControl
          className={classNames(classes.newInputForm)}
          variant="outlined"
        >
          <OutlinedInput
            className={classNames(classes.newFormElt, classes.checkoutInputForm)}
            id='companyName'
            margin='dense'
            name='companyName'
            onChange={event => setCardholderName(event.target.value)}
            placeholder='Cardholder full name'
            type='text'
            value={cardholderName}
          />
        </FormControl>
      </Grid>
      <Grid item
xs={12}
      >
        <Typography className={classes.formLabel}
variant='body1'
        >{'Card information'}</Typography>
        <StripeElementWrapper component={CardElement}
id='card-elt-id'
label=''
        />
      </Grid>
      {
        planinfo.trial_period_days > 0
        ? <Grid item
xs={12}
          >
            <Typography className={classes.infoLabel}
variant='body1'
            >
            {`Your ${planinfo.trial_period_days}-day free trial lasts until ${moment().add(planinfo.trial_period_days, 'days').format('MMMM D, YYYY [at] hA')}. If you don't want to continue using askneo.io, just cancel before the trial ends and you won't be charged.`}
            </Typography>
            <Typography variant='body1'>
            <b>{'Questions? Text us: (917) 451-5515'}</b>
            </Typography>
          </Grid>
        : null
      }
      
      {
        chargeError !== ''
        ? <Grid
            className={classes.errorLabel}
            item
            xs={12}
          >{chargeError}</Grid>
        : null
      }
      <Button
        color='primary'
        disabled={planinfo.product === '' || paymentLoading}
        style={{marginTop: 16}}
        type='submit'
        variant='contained'
      >{planinfo ? 'Subscribe' : 'Loading'}</Button>
    </Grid>
    </form>
  );
};

const combinedStyles = combineStyles(
  signupStyles,
  styles
);
const CheckoutForm = withStyles(combinedStyles)(ckForm);

// eslint-disable-next-line react/no-multi-comp
const Checkout = (props: Props) => {  
  const { classes } = props;
  const location = useLocation();
  const history = useHistory();

  const [planInfo, setPlanInfo] = useState({
    nickname: '',
    active: true,
    amount: 0,
    currency: 'usd',
    interval: 'month',
    interval_count: 0,
    product: ''
  });
  const [coupon, setCoupon] = useState('');
  const [couponId, setCouponId] = useState('');
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [productInfo, setProductInfo] = useState({
    name: ''
  });
  const [showCoupon, setShowCoupon] = useState(false);
  const [isCouponLoading, setIsCouponLoading] = useState('false');
  const [store, dispatch] = useStore();
  const { billing, user, defaultBuilding } = store;
  const { planId } = billing;

  // look for the plan id in url
  const parsed = queryString.parse(location.search);
  const { planid: paramPlanId } = parsed;
  
  useEffect(() => {
    log('[Checkout] parsed, paramPlanId, planId, user: ', parsed, paramPlanId, user);
    if ( paramPlanId && paramPlanId !== '') {
      dispatch({
        type: 'ACTION_SET_BILLING_PLANID',
        planId: paramPlanId
      });
      if (user) {
        SPBackend.getPlanInfo(paramPlanId, user)
          .then(response => {
            log('plan info: ', response.data);
            const { plan, product } = response.data;
            setPlanInfo(plan);
            setProductInfo(product);
          })
          .catch(err => {
            dispatch({
              type: 'ACTION_SET_SNACK_MSG', 
              snackMessage: {
                message: err.message,
                level: 'error',
                duration: 5000
              }
            });
          });
        }
    } else {
      log('[Checkout] parsed paramPlanId empty: ', paramPlanId);
      history.push('/plans');
      // TODO: alternative to redirect: simply show a dropdown with plan choice.
    }
  }, [paramPlanId]);

  const getCouponCode = (e) => {
    e.preventDefault();
    if (user) {
      log('[getCouponCode] fetching couponId: ', couponId);
      SPBackend.getCouponInfo(couponId, user)
        .then(response => {
          log('[getCouponCode] coupon response: ', response.data);
          const { success, message, coupon } = response.data;
          if (success === 'error') {
            dispatch({
              type: 'ACTION_SET_SNACK_MSG', 
              snackMessage: {
                message: message,
                level: 'error',
                duration: 5000
              }
            });
          }
          if (coupon && !coupon.valid) {
            dispatch({
              type: 'ACTION_SET_SNACK_MSG', 
              snackMessage: {
                message: 'Invalid coupon',
                level: 'error',
                duration: 5000
              }
            });
          }
          setCoupon(coupon);
        })
        .catch(err => {
          log('[getCouponCode] err: ', err);
          dispatch({
            type: 'ACTION_SET_SNACK_MSG', 
            snackMessage: {
              message: err.message,
              level: 'error',
              duration: 5000
            }
          });
        });
    }
  };

  // TODO: if planId exists in store, use it.
  // TODO: if planid sent in param, update store with it
  // if planid absent from store && param, redirect to /plans
  // if planId exists, round trip to database (hard coded for now) 
  
  const chargeFreePlan = () => {
    log('[chargeFreePlan] planId: ', planId);
    log('[chargeFreePlan] planInfo: ', planInfo);
    setPaymentLoading(true);
    // build trial end period
    const trialObj = {};
    if (planInfo.trial_period_days && planInfo.trial_period_days > 0) {
      trialObj.trialEnd = moment().add(planInfo.trial_period_days, 'days').unix();
    }
    const params = {
      paymentMethod: {},
      planId,
      ...trialObj
    };
    log('[chargeFreePlan] params: ', params);
    SPBackend.chargePlan(params, user)
    .then((response) => {
      // setChargeError('');
      log('[chargeFreePlan] charge response: ', response);
      SPBackend.getAccount(user)
      .then(response => {
        log('[chargeFreePlan][chargePlan] response: ', response);
        const { data } = response;
        const { user, account, billing } = data;
        dispatch({
          type: 'ACTION_LOADED_ACCOUNT',
          payload: {
            billing
          }
        });
        if ( defaultBuilding && (typeof defaultBuilding.Phone === 'undefined' || defaultBuilding.Phone === null || defaultBuilding.Phone === '')) {
          log('redirecting to phone number picker');
          history.push('/pickYourPhoneNumber');
        } else {
          log('redirecting to member');
          history.push('/member'); // if phone number, go directly to member
        }
        // setPaymentLoading(false);
      });
    })
    .catch(error => {
      log('[chargeFreePlan][chargePlan] charge error: ', error);
      // setPaymentLoading(false);
      // const { data } = error.response;
      setPaymentLoading(false);
      // setChargeError(data);
    });
  };

  return (
    <Grid className={classes.contentCtr}
container
    >
      <BasicHeader title='Checkout' />
      {
        planInfo
        && productInfo
        && productInfo.name !== ''
        && planInfo.nickname !== ''
        ? <Grid
              className={classes.contentCtr}
              item
              xs={12}
          >
              <a href="/"><img height={40}
                                src={Logo}
                          /></a>
              <Typography
                variant={'h3'}
              >{'Start your account'}</Typography>
              <Typography
                variant={'body1'}
              >{'Step 3 of 3'}</Typography>
              <Paper className={classes.formPaper}>
                {/* <Typography
                  className={classes.formTitle}
                  variant={'h3'}
                >{`Askneo.io Membership`}</Typography> */}
                <div className={classes.totalCtr}>
                  <div className={classes.totalLeft}>
                    <Typography className={classes.totalLabel} 
                                variant='h3'
                    >
                      {'Subscription askneo.io'}
                    </Typography>
                    <Typography className={classes.totalCaption}
                                variant='body1'          
                    >
                      {
                        planInfo.amount > 0
                        ? `Billed ${planInfo.interval === 'day' ? 'dai' : planInfo.interval}ly`
                        : null
                      }
                    </Typography>
                  </div>
                  <div className={classes.totalRight}>
                    <Typography className={classes.totalCost}
                                variant='h3'
                    >
                        {
                          coupon && coupon.valid
                          ? <>
                            <span className={classes.striked}>{`$${planInfo.amount / 100}`}</span>
                            <span>{`$${(planInfo.amount - (planInfo.amount * coupon.percent_off / 100)) / 100}`}</span>
                          </>
                          : planInfo.amount > 0
                            ? `$${planInfo.amount / 100}`
                            : 'Free'
                        }
                    </Typography>
                  </div>
                </div>
                {
                  planInfo && planInfo.amount > 0
                  ? <div className={classes.stripeCtr}>
                      <Grid className={classes.totalCtr}
                            container
                      >
                        <Grid item
                              xs={12}      
                        >
                          <Typography
                            className={classNames(classes.formLabel, classes.expandableLabel)}
                            onClick={() => setShowCoupon(!showCoupon)}
                            variant='body1' 
                          >
                            {'Got a coupon code?'} 
                          </Typography>
                          {
                            showCoupon
                            ? <form onSubmit={getCouponCode}>
                                <FormControl
                                  className={classNames(classes.newInputForm)}
                                  variant="outlined"
                                >
                                  <OutlinedInput
                                    className={classNames(classes.newFormElt, classes.checkoutInputForm)}
                                    id='coupon'
                                    margin='dense'
                                    name='coupon'
                                    onChange={event => {
                                      setCoupon(null);
                                      setCouponId(event.target.value);
                                    }}
                                    placeholder='Enter coupon code here'
                                    type='text'
                                    value={couponId}
                                  />
                                </FormControl>
                                {
                                  coupon && coupon.valid
                                  ? <Button
                                      color='primary'
                                      disabled
                                      style={{marginBottom: 16}}
                                      type='submit'
                                      variant='contained'
                                    >{'Coupon Applied'}</Button>
                                  : <Button
                                    color='primary'
                                    disabled={!isCouponLoading}
                                    style={{marginBottom: 16}}
                                    type='submit'
                                    variant='contained'
                                    >{isCouponLoading ? 'Apply Coupon' : 'Loading'}</Button>
                                }
                              </form>
                            : null
                          }
                          {
                            planInfo && planInfo.trial_period_days > 0
                              ? <div>
                                    <Typography
                                      style={{ paddingTop:'10px' }} 
                                      variant='body1'
                                    >
                                      <b>{`Your ${planInfo.trial_period_days} days free trial is almost ready!`}</b>
                                    </Typography>
                                    <Typography variant='body1'>
                                      <b>{ ' • Cancel anytime during your free trial.'}</b>
                                    </Typography>
                                    <Typography
                                      variant='body1' 
                                    >
                                    <b>{'• You won\'t be charged until you\'re done with your trial.'}</b>
                                    </Typography>
                                </div>
                              : null
                          }
                        </Grid>
                      </Grid>
                      <Elements stripe={stripePromise}>
                        <CheckoutForm
                          coupon={coupon}
                          history={history}
                          planinfo={planInfo}
                        />
                      </Elements>
                    </div>
                  : <Button
                      color='primary'
                      disabled={paymentLoading}
                      onClick={chargeFreePlan}
                      style={{marginTop: 16}}
                      type='submit'
                      variant='contained'
                    >{'Subscribe'}</Button>
                }
              </Paper>
            </Grid>
            /* Free Plan */
        : 
        <Grid
              className={classes.contentCtr}
              item
              xs={12}
        >
        {'Loading your plan...'}
        </Grid>
      }
    </Grid>
  );
};

export default withStyles(combinedStyles)(Checkout);
