// @flow

import React, { useState } from 'react';

import AnalyticsHeader from './AnalyticsHeader';
import AnalyticsContent from './AnalyticsContent';
import MemberDrawer from '../MemberDrawer';

import { withStyles } from '@material-ui/core/styles';
import styles from '../Properties/Styles/PropertiesHome.Style';

type Props = {
  classes: Object
};

const PropertiesHome = (props: Props) => {
  const { classes } = props;

  const [mobileOpen, setMobileOpen] = useState(false);

  const usehandleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <AnalyticsHeader
        mobileOpen={mobileOpen}
        usehandleDrawerToggle={usehandleDrawerToggle}
      />
      <MemberDrawer
        mobileOpen={mobileOpen}
        usehandleDrawerToggle={usehandleDrawerToggle}
      />
      <AnalyticsContent />
    </div>
  );
};

export default withStyles(styles)(PropertiesHome);
