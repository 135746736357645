// @flow

import React, {useState, useEffect} from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useStore } from '../store';
import { log } from '../utils/jsUtils';
import classNames from 'classnames';
import useInterval from '../hooks/useInterval';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import DisplaySupport from './SupportDialog';
import SPBackend from '../services/SPBackend';
import UpgradePlanDialog from './Billing/UpgradePlanDialog';
import RechargeAccountDialog from './Billing/RechargeAccountDialog';

import { ROLE_POWER } from '../models/User';

import { withStyles } from '@material-ui/core/styles';
import styles from './Styles/MemberDrawer.Style';


type Props = {
  classes: Object,
  theme: Object,
  container: Object,
  mobileOpen: bool, 
  usehandleDrawerToggle: Function
};


const MemberDrawer = (props: Props) => {
  const [store, dispatch] = useStore();
  const [openSupportDialog, setOpenSupportDialog] = useState(false);
  const [planInfo, setPlanInfo] = useState(null);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  // const [openUpgradePlanDialog, setOpenUpgradePlanDialog] = useState(null);
  // refresh the credits balance
  const [refresh, setRefresh] = useState(1);
  
  const history = useHistory();

  const {
    classes,
    mobileOpen,
    usehandleDrawerToggle
  } = props;
  
  const {
    user,
    billing,
    userRole,
    accountUsage,
    i18n,
    openPopups,
    isBillingLive,
    refreshPlanInfoIndex
  } = store;

  useEffect(() => {
    // console.log('[MemberDrawer.getPlanInfo]... user, billing: ', user, billing);
    if (user && billing && isBillingLive) {
      log('[MemberDrawer.getPlanInfo] billing, user: ', billing, user);
      if (typeof billing !== 'undefined'
      && typeof billing.subscriptionId !== 'undefined'
      && billing.subscriptionId !== null) {
        log('[MemberDrawer.getPlanInfo] refresh: ', refresh);
        SPBackend.getPlanInfo(billing.subscriptionId, user)
        .then(response => {
          log('[MemberDrawer.getPlanInfo] response: ', response.data);
          const { plan, product, discount, subscription, account } = response.data;
          setPlanInfo(plan);
          // setProductInfo(product);
          setSubscriptionInfo(subscription);
          dispatch({
            type: 'ACTION_SET_ACCOUNT_USAGE', 
            accountUsage: account.Usage
          });
          dispatch({
            type: 'ACTION_SET_BILLING', 
            billing: {
              customerId: account.Billing ? account.Billing.CustomerId : null,
              subscriptionId: subscription.id,
              subscriptionStatus: subscription.status
            }
          });
        })
        .catch(err => {
          log('drawer getPlanInfo err: ', err.message);
        });
      }
    }
  }, [user, refreshPlanInfoIndex, isBillingLive]);

  const setOpenUpgradePlanDialog = (open) => {
    dispatch({
      type: 'ACTION_SET_UPGRADE_POPUP',
      open
    });
  };

  const setOpenRechargeAccountDialog = (open) => {
    dispatch({
      type: 'ACTION_SET_RECHARGE_POPUP',
      open
    });
  };

  useEffect(() => {
    // console.log('[MemberDrawer.getPlanInfo] render - billing: ', billing);
    // const delay = refresh === 1 ? 1000 : 25000;
    if (user) {
      let refreshCnt = 0;

      const setupRefreshInterval = (delay) => {
        log('[delay][setupRefreshInterval] delay, refreshCnt: ', delay, refreshCnt);
        log('[delay][setupRefreshInterval] billing: ', billing);
        const interval = setInterval(() => {
          log('[delay] refreshCnt: ', refreshCnt);
          log('[delay] interval - billing: ', billing);
          if (history.location.pathname === '/neo') {
            // refresh billing once only
            if (refreshCnt === 0) {
              refreshCnt = refreshCnt + 1;
              dispatch({
                type: 'ACTION_REFRESH_PLAN_INFO',
                count: refreshCnt
              });
              // setRefresh((prev) => prev + 1);
              
            }
            log('stop refreshing for that page');
            clearInterval(interval);
          } else if (typeof billing !== 'undefined' && billing.autorefresh !== false) {
            log('[delay] refreshing credits...', refresh);
            refreshCnt = refreshCnt + 1;
            dispatch({
              type: 'ACTION_REFRESH_PLAN_INFO',
              count: refreshCnt
            });
            // setRefresh((prev) => prev + 1);
            // refreshCnt = refreshCnt + 1;
          }
          if (refreshCnt > 1 && history.location.pathname !== '/neo') {
            log('[delay] clearing interval - starting a new one: ', refreshCnt);
            clearInterval(interval);
            setupRefreshInterval(10000); // after 1st load, refresh credits only every 10s
          }
        }, delay);
      }; 
      setupRefreshInterval(5000);
    }
  }, [user]);

  const handleUpgradePlanDialogAction = (actionName: string, val) => {
    alert(`[handleUpgradePlanDialogAction] action! ${actionName}`);
  };

  const handleRechargeAccountDialogAction = (actionName: string, val) => {
    alert(`[handleRechargeAccountDialogAction] action! ${actionName}`);
  };

  const drawer = (
    <div className={classes.root}>
      
      <List
        className={classNames(classes.toolbar, classes.toolbarHeight)}
        disablePadding
      >
        <ListItem
          classes={{
            root: classNames(
              classes.listItem,
              classes.upgradeItem
            )
          }}
        >
        {
          subscriptionInfo
          ? <>
            {
              billing && billing.subscriptionStatus === 'active'
              && planInfo && planInfo.id !== process.env.REACT_APP_ACCOUNT_DEFAULT_SIGNUP_PLAN
              ? <Button
                  className={classes.rechargeBtn}
                  color='primary'
                  onClick={() => setOpenRechargeAccountDialog(true)}
                  variant='contained'
                >{'Recharge'}</Button>
              : <Button
                  className={classes.upgradeBtn}
                  color='secondary'
                  onClick={() => setOpenRechargeAccountDialog(true)} // setOpenUpgradePlanDialog(true)}
                  variant='contained'
                >{'Upgrade'}</Button>
            }
            {
              billing.subscriptionStatus === 'trialing'
              ? moment.unix(subscriptionInfo.current_period_end).diff(moment(), 'days') > 0
                ? <Typography variant='body1'>{`${moment.unix(subscriptionInfo.current_period_end).diff(moment(), 'days') + 1} days trial remaining`}</Typography>
                : <Typography variant='body1'>{'Last trial day'}</Typography>
              : null
            }
            {
              billing.subscriptionStatus !== 'trialing'
              && planInfo && planInfo.id === process.env.REACT_APP_ACCOUNT_DEFAULT_SIGNUP_PLAN
              ? <Typography variant='body1'>{'Trial expired'}</Typography>
              : <Typography variant='body1'>{
              !accountUsage || !accountUsage.CurrentPeriod
                ? 0
                : Number.isNaN(Number(accountUsage.TotalCreditsBalance)) ? 0 : Number(accountUsage.TotalCreditsBalance).toLocaleString()} credits left</Typography>
            }
            
          </>: null
        }
          
        </ListItem>
      </List>
      
      <List
        className={classes.grow}
        disablePadding
      >
        <Link 
          className={classes.menuLink}
          to='/member'
        >
            <ListItem
              button
              classes={{
                root: classNames(
                  classes.listItem,
                  typeof history.location !== 'undefined'
                  && typeof history.location.pathname !== 'undefined'
                  && history.location.pathname.includes('member') ? classes.selected : null
                )
              }}
              key={i18n.t('Activity')}
            >
              <ListItemText
                classes={{
                  root: classes.itemTextCtr
                }}
                primary={i18n.t('Activity')}
              />
            </ListItem>
        </Link>

        {/* {
          ROLE_POWER[userRole] > 0
          ? <Link 
              className={classes.menuLink}
              to={'/properties'}
            >
                <ListItem
                  button
                  classes={{
                    root: classNames(
                      classes.listItem,
                      typeof history.location !== 'undefined'
                      && typeof history.location.pathname !== 'undefined'
                      && history.location.pathname.includes('properties') ? classes.selected : null
                    )
                  }}
                  key={i18n.t('Properties') : 'Properties'}
                >
                  <ListItemText
                    classes={{
                      root: classes.itemTextCtr
                    }}
                    primary={i18n.t('Properties') : 'Properties'}
                  />
                  <Badge 
                    badgeContent={statusAlerts.Properties.length}
                    classes={{ anchorOriginTopRightRectangle: classes.anchorTopRight}}
                    color="error"
                  />
                </ListItem>
            </Link>
          : null
        } */}

        {
          ROLE_POWER[userRole] > 0
          ? <Link 
              className={classes.menuLink}
              to={'/customers'}
            >
                <ListItem
                  button
                  classes={{
                    root: classNames(
                      classes.listItem,
                      typeof history.location !== 'undefined'
                      && typeof history.location.pathname !== 'undefined'
                      && history.location.pathname.includes('customers') ? classes.selected : null
                    )
                  }}
                  key={i18n.t('Tenants')}
                >
                  <ListItemText
                    classes={{
                      root: classes.itemTextCtr
                    }}
                    primary={i18n.t('Tenants')}
                  />
                </ListItem>
            </Link>
          : null
        }
        
        {
          ROLE_POWER[userRole] > 0
          ? <Link 
              className={classes.menuLink}
              to={'/staff'}
            >
                <ListItem
                  button
                  classes={{
                    root: classNames(
                      classes.listItem,
                      typeof history.location !== 'undefined'
                      && typeof history.location.pathname !== 'undefined'
                      && history.location.pathname.includes('staff') ? classes.selected : null
                    )
                  }}
                  key={i18n.t('Staff')}
                >
                <ListItemText
                  classes={{
                    root: classes.itemTextCtr
                  }}
                  primary={i18n.t('Staff')}
                />
                </ListItem>
            </Link>
          : null
        }
        <Link 
          className={classes.menuLink}
          to='/neo'
        >
            <ListItem
              button
              classes={{
                root: classNames(
                  classes.listItem,
                  typeof history.location !== 'undefined'
                  && typeof history.location.pathname !== 'undefined'
                  && history.location.pathname.includes('neo') ? classes.selected : null
                )
              }}
              key={i18n.t('Neo the Bot')}
            >
              <ListItemText
                classes={{
                  root: classes.itemTextCtr
                }}
                primary={i18n.t('Neo the Bot')}
              />
            </ListItem>
        </Link>
        <Link 
          className={classes.menuLink}
          to='/tags'
        >
            <ListItem
              button
              classes={{
                root: classNames(
                  classes.listItem,
                  typeof history.location !== 'undefined'
                  && typeof history.location.pathname !== 'undefined'
                  && history.location.pathname.includes('tags') ? classes.selected : null
                )
              }}
              key={i18n.t('Tags')}
            >
              <ListItemText
                classes={{
                  root: classes.itemTextCtr
                }}
                primary={i18n.t('Tags')}
              />
            </ListItem>
        </Link>
        <Link 
          className={classes.menuLink}
          to='/integrations'
        >
            <ListItem
              button
              classes={{
                root: classNames(
                  classes.listItem,
                  typeof history.location !== 'undefined'
                  && typeof history.location.pathname !== 'undefined'
                  && history.location.pathname.includes('integrations') ? classes.selected : null
                )
              }}
              key={i18n.t('Integrations')}
            >
              <ListItemText
                classes={{
                  root: classes.itemTextCtr
                }}
                primary={i18n.t('Integrations')}
              />
            </ListItem>
        </Link>

        {
          ROLE_POWER[userRole] > 0
          ? 
          <Link 
            className={classes.menuLink}
            to='/billing'
          >
              <ListItem
                button
                classes={{
                  root: classNames(
                    classes.listItem,
                    typeof history.location !== 'undefined'
                    && typeof history.location.pathname !== 'undefined'
                    && history.location.pathname.includes('billing') ? classes.selected : null
                  )
                }}
                key={i18n.t('Billing')}
              >
                <ListItemText
                  classes={{
                    root: classes.itemTextCtr
                  }}
                  primary={i18n.t('Billing')}
                />
              </ListItem>
          </Link>
          : null
        }

        <Link 
          className={classes.menuLink}
          to='/share'
        >
            <ListItem
              button
              classes={{
                root: classNames(
                  classes.listItem,
                  typeof history.location !== 'undefined'
                  && typeof history.location.pathname !== 'undefined'
                  && history.location.pathname.includes('share') ? classes.selected : null
                )
              }}
              key={i18n.t('Share')}
            >
              <ListItemText
                classes={{
                  root: classes.itemTextCtr
                }}
                primary={i18n.t('Share')}
              />
            </ListItem>
        </Link>
        {/* <Link 
          className={classes.menuLink}
          to='/club'
        >
            <ListItem
              button
              classes={{
                root: classNames(
                  classes.listItem,
                  typeof history.location !== 'undefined'
                  && typeof history.location.pathname !== 'undefined'
                  && history.location.pathname.includes('club') ? classes.selected : null
                )
              }}
              key={i18n.t('Neo Club')}
            >
              <ListItemText
                classes={{
                  root: classes.itemTextCtr
                }}
                primary={i18n.t('Neo Club')}
              />
            </ListItem>
        </Link> */}
        {/* <Menu
          anchorEl={showSubMenuPortfolioMgt}
          classes={{
            paper: classes.menuRoot
          }}
          disableAutoFocusItem
          id="menu-portfolio-mgt"
          // eslint-disable-next-line react/jsx-sort-props
          MenuListProps={{
            classes: {
              root: classes.subMenuListRoot
            }
          }}
          onClose={() => setShowSubMenuPortfolioMgt(null)}
          open={Boolean(showSubMenuPortfolioMgt)}
          
        >
          <Link 
            className={classes.menuLink}
            to={'/neo'}
          >
            <MenuItem className={classes.menuItem}
            onClick={() => {
                dispatch({
                  type: 'ACTION_SET_TAB_VALUE', 
                  tabValue: 0
                });
                setShowSubMenuPortfolioMgt(null);
              } 
            }
                      selected={false}
            >{'Conversation Scripts'}
            </MenuItem>
          </Link>
          <Link 
            className={classes.menuLink}
            to={'/neo'}
          >
            <MenuItem className={classes.menuItem}
              onClick={() => { 
                dispatch({
                  type: 'ACTION_SET_TAB_VALUE', 
                  tabValue: 1
                });
                // goToNeo(1);
                setShowSubMenuPortfolioMgt(null);
            }}
                      selected={false}
            >{'SMS Keyword Triggers'}</MenuItem>
          </Link>
        </Menu> */}
        {/* <Link 
            className={classes.menuLink}
            to={'/neo'}
        >
          <MenuItem className={classes.menuItem}
                    onClick={() => setShowSubMenuPortfolioMgt(null)} 
                    selected={false}
          >{'Neo the bot'}
          </MenuItem>
        </Link> */}
      </List>
      
      <List disablePadding>
          <Tooltip
            aria-label={'Get support information'}
            title={'Get support information'}
          >
            <ListItem
              button
              className={classes.listItem, classes.listItemSub, classes.flexContainer}
              key={'Support'}
            >
              <p
                className={classes.menuLink, classes.flexItem1}
               onClick={() => { setOpenSupportDialog(true); }}
              >{'Support'}</p>
            </ListItem>
          </Tooltip>
      </List>
      <DisplaySupport
            open={openSupportDialog}
            setOpen={setOpenSupportDialog}
      />
    </div>
  );

  return (
    <nav className={classes.drawer}>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden 
        smUp
      >
        <Drawer
          anchor={'left'}
          classes={{
            paper: classes.drawerPaper
          }}
          // container={props.container}
          onClose={usehandleDrawerToggle}
          open={mobileOpen}
          variant="temporary"
        > 
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden
        implementation="css"
        xsDown
      >
        <Drawer
          classes={{
            paper: classes.drawerPaper
          }}
          variant="permanent"
        >
          {drawer}
        </Drawer>
      </Hidden>
      <UpgradePlanDialog
        open={openPopups.upgradePlan}
        setOpen={setOpenUpgradePlanDialog}
        triggerAction={handleUpgradePlanDialogAction}
      />
      <RechargeAccountDialog
        open={openPopups.rechargeAccount}
        setOpen={setOpenRechargeAccountDialog}
        triggerAction={handleRechargeAccountDialogAction}
      />
    </nav>    
  );
};

export default withStyles(styles)(MemberDrawer);