// @flow

import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import { log } from '../utils/jsUtils';

import TableRow from '@material-ui/core/TableRow';
import combineStyles from '../utils/stylesUtils';
import { withStyles } from '@material-ui/core/styles';
import listTableStyles from './Styles/CommonListTable.Style';

type Props = {
  classes: Object,
  children: Object,
  rowClasses: Object,
  readonly: boolean,
  handleRowClick: Function
}

const ListFeedRow = (props: Props) => {
  const {
    classes,
    children,
    rowClasses,
    readonly,
    handleRowClick
  } = props;
  const [hover, setHover] = useState(false);

  const handleRowMouseEnter = (e) => {
    e.stopPropagation();
    if (!readonly) {
      setHover(true);
    }
  };
  
  const handleRowMouseLeave = (e) => {
    e.stopPropagation();
    // log('[ListTableRow] onmouseleave');
    if (!readonly) {
      setHover(false);
    }
  };

  return (
    <TableRow
      className={classNames(
        rowClasses,
        classes.feedTableRow,
        hover ? 'hover' : null,
        !readonly ? classes.cursorPointer : null
      )}
      onClick={!readonly ? handleRowClick : null}
      onMouseEnter={handleRowMouseEnter}
      onMouseLeave={handleRowMouseLeave}
    >
      {children}
    </TableRow>
  );
};


const combinedStyles = combineStyles(
  listTableStyles
);

export default withStyles(combinedStyles)(ListFeedRow);