// @flow

const COL_MAIN_WIDTH = 200;
const COL_OTHER_WIDTH = 200;
const COL_FLOOR_WIDTH = 100;
const COL_ACTION_WIDTH = 140;

const styles = (theme: Object) => ({
  colAction: {
    width: COL_ACTION_WIDTH
  },
  contentTitle: {
    marginTop: 40
  },
  mainCell: {
    flex: '1 1 auto',
    flexBasis: COL_MAIN_WIDTH
  },
  otherCell: {
    flex: '0 0 auto',
    minWidth: COL_OTHER_WIDTH,
    maxWidth: COL_OTHER_WIDTH
  },
  floorCell: {
    flex: '0 0 auto',
    minWidth: COL_FLOOR_WIDTH,
    maxWidth: COL_FLOOR_WIDTH
  },
  actionCell: {
    flex: '0 0 auto',
    minWidth: theme.shape.borderRadius,
    paddingRight: 0,
    paddingLeft: 0,
    width: 'auto'
  },
  customTableStyles: {
    '& .underActionCell': {
      flex: '0 0 auto',
      minWidth: COL_ACTION_WIDTH,
      maxWidth: COL_ACTION_WIDTH,
      paddingRight: 0,
      paddingLeft: 0,
      width: 'auto'
    },
    '& .hover .underActionCell.twoBtns': {
      flex: '1 1 auto',
      minWidth: 0,
      maxWidth: COL_ACTION_WIDTH - (theme.shape.borderRadius * 2 * 2),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 'auto'
    }
  },
  resultsCount: {
    color: '#a1a1a1',
    fontSize: '0.8rem',
    marginBottom: '1rem',
    paddingLeft: theme.spacing(1)
  }
});

export default styles;